import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '@models/index';
import { SlugPipe } from '@pipes/slug.pipe';
import { collapseAnimation } from 'angular-animations';

@Component({
  selector: 'app-customize-ingredient-group-scroll',
  templateUrl: './customize-ingredient-group-scroll.component.html',
  styleUrls: ['customize-ingredient-group-scroll.component.scss'],
  animations: [
    collapseAnimation()
  ]
})
export class CustomizeIngredientGroupScrollComponent implements OnInit {
  @Input() product: Product | null = null;
  @Input() ingredientList: string[] = [];
  pathURL: string = '';
  constructor(private router: Router, private slugPipe: SlugPipe) {}
  ngOnInit() {
    this.pathURL = this.router.url.split('#')[0];
  }

  scroll(id: string) {
    const el = document.getElementById(this.slugPipe.transform(id));
    if (el) {
      // window.scrollTo({top: el.offsetTop, behavior: 'smooth'})
      // console.log(el.offsetTop)
      // console.log(el.scrollTop)
      // console.log(el.)
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}
