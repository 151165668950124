<ng-container *ngIf="location.isavailable; else unavailable">
  <div class="card w-100">
    <div class="card-header bg-light">{{ location.name }}</div>
    <div class="card-body bg-light">
      <div>{{ location.streetaddress }}</div>
      <div>{{ location.city }}, {{ location.state }} {{ location.zip }}</div>
      <ng-container *ngIf="location.iscurrentlyopen; else closed">
        <span class="badge badge-pill badge-success w-fit-content"> Open </span>
      </ng-container>

      <ng-template #closed>
        <span class="badge badge-pill badge-danger w-fit-content">
          Closed
        </span>
      </ng-template>
    </div>
    <div
      class="card-footer d-flex flex-row flex-nowrap justify-content-between bg-light">
      <app-button
        classes="btn btn-outline-danger"
        [isLoading]="removeLoading"
        (buttonClick)="removeFavorite(location.vendorid)">
        Remove
      </app-button>
      <app-button
        classes="btn btn-primary"
        [isLoading]="startOrderLoading"
        (buttonClick)="startOrder(location.vendorid)"
        >Start Order
      </app-button>
    </div>
  </div>
</ng-container>

<ng-template #unavailable>
  <div class="card w-100">
    <div class="card-header bg-light">{{ location.name }}</div>
    <div class="card-body bg-light">
      <div>{{ location.streetaddress }}</div>
      <div>{{ location.city }}, {{ location.state }} {{ location.zip }}</div>
      <div class="d-flex flex-row gap-2 pt-1">
        <ng-container *ngIf="location.iscurrentlyopen; else closed">
          <span class="badge badge-pill badge-success w-fit-content">
            Open
          </span>
        </ng-container>

        <ng-template #closed>
          <span class="badge badge-pill badge-danger w-fit-content">
            Closed
          </span>
        </ng-template>

        <span class="badge badge-pill badge-warning w-fit-content">
          Ordering Unavailable
        </span>
      </div>
    </div>
    <div
      class="card-footer d-flex flex-row flex-nowrap justify-content-between bg-light">
      <app-button
        classes="btn btn-outline-danger"
        [isLoading]="removeLoading"
        (buttonClick)="removeFavorite(location.vendorid)">
        Remove
      </app-button>
      <app-button classes="btn btn-primary" [disabled]="true">
        Unavailable
      </app-button>
    </div>
  </div>
</ng-template>
