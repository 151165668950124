import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import {
  FavoriteLocation,
  GlobalStateModel,
  Restaurant,
  User,
} from '@models/index';
import { TextToOrderModalComponent } from '@modules/locations/components';
import { LocationsService } from '@modules/locations/services';
import { Select, Store } from '@ngxs/store';
import { FavoriteLocationPipe } from '@pipes/favorite-location.pipe';
import { HandoffMode } from '@server/vendor/olo/interfaces';
import { DeviceDetectionService } from '@services/device-detection/device-detection.service';
import { SetMenu } from '@store/actions/menu.actions';
import { SetHandoffMode, StartOrder } from '@store/actions/order.actions';
import {
  DeleteFavoriteLocation,
  SaveFavoriteLocation,
} from '@store/actions/user.actions';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'app-selected-location',
  templateUrl: './selected-location.component.html',
  styleUrls: ['selected-location.component.scss'],
  providers: [FavoriteLocationPipe],
})
export class SelectedLocationComponent implements OnChanges, OnInit {
  @Select((state: GlobalStateModel) => state.user.user)
  user$!: Observable<User>;
  @Select((state: GlobalStateModel) => state.user.favoriteLocations)
  favoriteLocations$!: Observable<FavoriteLocation[]>;

  @Input() selectedLocation?: Restaurant;
  @Input() individualLocation = false;

  @Output() locationClose = new EventEmitter<void>();

  startOrderLoading = false;

  groupOrder: FormControl = new FormControl(false);

  closingTime?: Date;

  constructor(
    private store: Store,
    private router: Router,
    private favoriteLocationPipe: FavoriteLocationPipe,
    private deviceDetector: DeviceDetectionService,
    private modal: BsModalService,
    private locations: LocationsService,
  ) {}

  ngOnInit() {
    if (this.selectedLocation && this.selectedLocation?.calendars) {
      this.closingTime = moment(
        this.selectedLocation?.calendars.find((c) => c.type === 'business')
          ?.ranges[0].end,
        'YYYYMMDD HH:mm',
      )
        .utcOffset(this.selectedLocation!.utcoffset, true)
        .toDate();
    }
  }

  ngOnChanges() {
    this.closingTime = undefined;
    if (this.selectedLocation && this.selectedLocation?.calendars) {
      this.closingTime = moment(
        this.selectedLocation?.calendars.find((c) => c.type === 'business')
          ?.ranges[0].end,
        'YYYYMMDD HH:mm',
      )
        .utcOffset(this.selectedLocation!.utcoffset, true)
        .toDate();
    }
  }

  startOrder(locationID: number) {
    this.startOrderLoading = true;
    this.locations.startOrder(locationID).subscribe(() => {
      this.locations
        .finishAndGoToMenu(this.groupOrder.value)
        .subscribe(() => (this.startOrderLoading = false));
    });
  }

  toggleFavoriteLocation(location: Restaurant) {
    this.favoriteLocations$.pipe(take(1)).subscribe((faveLocations) => {
      if (this.favoriteLocationPipe.transform(location, faveLocations)) {
        this.deleteFavoriteLocation(location.id);
      } else {
        this.saveFavoriteLocation(location.id);
      }
    });
  }

  openTextToOrder(): void {
    if (this.deviceDetector.isMobile()) {
      window.open(
        `sms:+17377272831?&body=${encodeURIComponent(
          'Hey Freebirds, I want...',
        )}`,
        '_blank',
      );
    } else {
      this.modal.show(TextToOrderModalComponent, {
        animated: true,
        keyboard: true,
      });
    }
  }

  private saveFavoriteLocation(locationID: number) {
    this.store.dispatch(new SaveFavoriteLocation(locationID));
  }

  private deleteFavoriteLocation(locationID: number) {
    this.store.dispatch(new DeleteFavoriteLocation(locationID));
  }
}
