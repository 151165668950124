import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Containers */
import * as orderContainers from './containers';
/* Guards */
import * as orderGuards from './guards';
/* Module */
import { OrderModule } from './order.module';

/* Routes */
export const ROUTES: Routes = [
  {
    path: 'order',
    canActivate: [],
    children: [
      {
        path: 'customize/:categoryID',
        canActivate: [],
        component: orderContainers.CustomizeComponent,
      },

      {
        path: 'categories',
        canActivate: [],
        component: orderContainers.CategoriesComponent,
      },
      {
        path: 'group-order',
        canActivate: [orderGuards.GroupOrderGuard],
        component: orderContainers.GroupOrderComponent,
      },
      {
        path: '',
        redirectTo: '/404',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [OrderModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class OrderRoutingModule {}
