import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProductAllergenTagsPipe } from '@pipes/product-allergen-tags.pipe';

import { AccountActivityToNgxTimelineEventsPipe } from './account-activity-to-ngx-timeline-events.pipe';
import { AllergenTagsToNameArrayPipe } from './allergen-tags-to-name-array.pipe';
import { BlurhashToDataUrlPipe } from './blurhash-to-data-url.pipe';
import { CanEditPipe } from './can-edit.pipe';
import { CartQuantityPipe } from './cart-quantity.pipe';
import { ChoicesListPipe } from './choices-list.pipe';
import { CmsAssetPipe } from './cms-asset.pipe';
import { CssUrlPipe } from './css-url.pipe';
import { DedupeAddressesPipe } from './dedupe-addresses.pipe';
import { FaqFilterPipe } from './faq-filter.pipe';
import { FavoriteLocationPipe } from './favorite-location.pipe';
import { FeaturedAdsFilterPipe } from './featured-ads-filter.pipe';
import { FormattedCalendarPipe } from './formatted-calendar.pipe';
import { GetFormControlFromParentPipe } from './get-form-control-from-parent.pipe';
import { HandoffModeNamePipe } from './handoff-mode-name.pipe';
import { HasRewardsPipe } from './has-rewards.pipe';
import { HideViewMenuPipe } from './hide-view-menu.pipe';
import { HrefTelPipe } from './href-tel.pipe';
import { IngredientAllergenTagsPipe } from './ingredient-allergen-tags.pipe';
import { IngredientGroupCompletionPipe } from './ingredient-group-completion.pipe';
import { IsChildIngredientPipe } from './is-child-ingredient.pipe';
import { IsPlatformBrowserPipe } from './is-platform-browser.pipe';
import { IsRewardAppliedPipe } from './is-reward-applied.pipe';
import { JumbotronTimeFilterPipe } from './jumbotron-time-filter.pipe';
import { MapsLinkPipe } from './maps-link.pipe';
import { NutritionInfoCalculationPipe } from './nutrition-info-calculation.pipe';
import { NutritionInfoGraphCalculatorPipe } from './nutrition-info-graph-calculator.pipe';
import { NutritionPreferenceCheckPipe } from './nutrition-preference-check.pipe';
import { OloDatePipe } from './olo-date.pipe';
import { OrderRecipientPipe } from './order-recipient.pipe';
import { ProdABTestingSectionFilterPipe } from './prod-ab-testing-section-filter.pipe';
import { ProductCustomizationCompletionPipe } from './product-customization-completion.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { SavedProductChoicesPipe } from './saved-product-choices.pipe';
import { SelectedAllergenTagPipe } from './selected-allergen-tag.pipe';
import { SelectedIngredientPipe } from './selected-ingredient.pipe';
import { SelectedOptionsTotalPipe } from './selected-options-total.pipe';
import { SideSaucesFilterPipe } from './side-sauces-filter.pipe';
import { SlugPipe } from './slug.pipe';
import { StringJoinPipe } from './string-join.pipe';
import { SubcategoryPipe } from './subcategory.pipe';
import { SubcategoryNamePipe } from './subcategory-name.pipe';
import { SuggestedProductFilterPipe } from './suggested-product-filter.pipe';
import { TextColorPipe } from './text-color.pipe';
import { TruncatePipe } from './truncate.pipe';
import { UpsellSortPipe } from './upsell-sort.pipe';
import { UpsellSubcategoriesPipe } from './upsell-subcategories.pipe';

@NgModule({
  declarations: [
    SanitizeHtmlPipe,
    OloDatePipe,
    ChoicesListPipe,
    HandoffModeNamePipe,
    FavoriteLocationPipe,
    CmsAssetPipe,
    HrefTelPipe,
    SelectedIngredientPipe,
    GetFormControlFromParentPipe,
    CssUrlPipe,
    FaqFilterPipe,
    SlugPipe,
    IsChildIngredientPipe,
    HasRewardsPipe,
    AccountActivityToNgxTimelineEventsPipe,
    IsRewardAppliedPipe,
    HideViewMenuPipe,
    DedupeAddressesPipe,
    TruncatePipe,
    SubcategoryPipe,
    UpsellSubcategoriesPipe,
    SelectedOptionsTotalPipe,
    SavedProductChoicesPipe,
    NutritionPreferenceCheckPipe,
    NutritionInfoCalculationPipe,
    NutritionInfoGraphCalculatorPipe,
    ProductCustomizationCompletionPipe,
    StringJoinPipe,
    IngredientGroupCompletionPipe,
    OrderRecipientPipe,
    IsPlatformBrowserPipe,
    FormattedCalendarPipe,
    MapsLinkPipe,
    CartQuantityPipe,
    SubcategoryNamePipe,
    SideSaucesFilterPipe,
    SuggestedProductFilterPipe,
    UpsellSortPipe,
    JumbotronTimeFilterPipe,
    ProdABTestingSectionFilterPipe,
    BlurhashToDataUrlPipe,
    FeaturedAdsFilterPipe,
    CanEditPipe,
    SelectedAllergenTagPipe,
    TextColorPipe,
    IngredientAllergenTagsPipe,
    ProductAllergenTagsPipe,
    AllergenTagsToNameArrayPipe,
  ],
  providers: [
    SanitizeHtmlPipe,
    OloDatePipe,
    ChoicesListPipe,
    HandoffModeNamePipe,
    FavoriteLocationPipe,
    CmsAssetPipe,
    HrefTelPipe,
    SelectedIngredientPipe,
    GetFormControlFromParentPipe,
    CssUrlPipe,
    FaqFilterPipe,
    SlugPipe,
    IsChildIngredientPipe,
    HasRewardsPipe,
    AccountActivityToNgxTimelineEventsPipe,
    IsRewardAppliedPipe,
    HideViewMenuPipe,
    DedupeAddressesPipe,
    TruncatePipe,
    SubcategoryPipe,
    UpsellSubcategoriesPipe,
    SelectedOptionsTotalPipe,
    SavedProductChoicesPipe,
    NutritionPreferenceCheckPipe,
    ProductCustomizationCompletionPipe,
    StringJoinPipe,
    IngredientGroupCompletionPipe,
    OrderRecipientPipe,
    IsPlatformBrowserPipe,
    FormattedCalendarPipe,
    MapsLinkPipe,
    CartQuantityPipe,
    CurrencyPipe,
    JumbotronTimeFilterPipe,
    ProdABTestingSectionFilterPipe,
    BlurhashToDataUrlPipe,
    FeaturedAdsFilterPipe,
  ],
  imports: [CommonModule],
  exports: [
    SanitizeHtmlPipe,
    OloDatePipe,
    ChoicesListPipe,
    HandoffModeNamePipe,
    FavoriteLocationPipe,
    FavoriteLocationPipe,
    CmsAssetPipe,
    HrefTelPipe,
    SelectedIngredientPipe,
    GetFormControlFromParentPipe,
    CssUrlPipe,
    FaqFilterPipe,
    SlugPipe,
    IsChildIngredientPipe,
    HasRewardsPipe,
    AccountActivityToNgxTimelineEventsPipe,
    IsRewardAppliedPipe,
    HideViewMenuPipe,
    DedupeAddressesPipe,
    TruncatePipe,
    SubcategoryPipe,
    UpsellSubcategoriesPipe,
    SelectedOptionsTotalPipe,
    SavedProductChoicesPipe,
    NutritionPreferenceCheckPipe,
    NutritionInfoCalculationPipe,
    NutritionInfoGraphCalculatorPipe,
    ProductCustomizationCompletionPipe,
    StringJoinPipe,
    IngredientGroupCompletionPipe,
    OrderRecipientPipe,
    IsPlatformBrowserPipe,
    FormattedCalendarPipe,
    MapsLinkPipe,
    CartQuantityPipe,
    SubcategoryNamePipe,
    SideSaucesFilterPipe,
    SuggestedProductFilterPipe,
    UpsellSortPipe,
    JumbotronTimeFilterPipe,
    ProdABTestingSectionFilterPipe,
    BlurhashToDataUrlPipe,
    FeaturedAdsFilterPipe,
    CanEditPipe,
    SelectedAllergenTagPipe,
    TextColorPipe,
    IngredientAllergenTagsPipe,
    ProductAllergenTagsPipe,
    AllergenTagsToNameArrayPipe,
  ],
})
export class PipesModule {}
