<ng-container [ngSwitch]="productCardType">
  <ng-container *ngSwitchCase="'upsell'">
    <button
      type="button"
      class="product-card product-card-shell w-100 h-100 p-0 position-relative"
      [style.background-color]="
        product.content?.background_color ?? 'transparent'
      ">
      <!--      Removed from button to remove accordion styling-->
      <!--      [ngClass]="status ? 'product-card-shell-selected' : 'product-card-shell'"-->
      <small
        *ngIf="product | cartQuantity as productQuant"
        class="position-absolute top-0 end-0 translate-middle-y badge rounded-pill bg-secondary"
        >{{ productQuant }} in cart</small
      >
      <div
        class="d-flex flex-row flex-nowrap w-100"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseBasic"
        (click)="addToCart($event)">
        <!--        Removed from div above to remove accordion expanding-->
        <!--        (click)="showSuccess(); isCollapsed = !isCollapsed"-->
        <div class="position-relative">
          <div class="checkmark-container ingredient">
            <div
              class="checkmark-overlay"
              [ngClass]="!isCollapsed ? 'd-flex' : 'd-none'">
              <div class="checkmark-animation">
                <img
                  class="hat-checkmark"
                  alt="checkmark"
                  src="assets/images/location-specific_illustrations/Icons/fb-hat-orange.svg" />
              </div>
            </div>
          </div>
          <img
            [src]="product.content?.image | cmsAsset"
            class="card-img-top product product-image"
            [alt]="product.name" />
        </div>

        <div
          class="card-body align-items-center w-100 d-flex flex-row flex-nowrap text-center justify-content-between gap-2 p-2">
          <div class="d-flex flex-column text-left">
            <ng-container
              *ngIf="product.content.badge_text && product.content.badge_color">
              <app-badge [product]="product"></app-badge>
            </ng-container>
            <h6 class="product-title w-100">{{ product.name }}</h6>
            <ng-container
              *ngIf="
                product.content.description &&
                  product.content.description !== '';
                else providerDescription
              ">
              <small class="w-100">
                {{ product.content.description | slice : 0 : 20 }}
              </small>
            </ng-container>
            <ng-template #providerDescription>
              <ng-container *ngIf="product.description">
                <small class="w-100">
                  {{ product.description | slice : 0 : 20 }}
                </small>
              </ng-container>
            </ng-template>
            <ng-container *ngIf="product.cost">
              <small class="product-cost">{{ product.cost | currency }}</small>
            </ng-container>
          </div>
          <ng-container *ngIf="selectedAllergenTags$ | async as allergenTags">
            <ng-container *ngIf="allergenTags.length">
              <div class="d-flex flex-column justify-content-center gap-1 py-2">
                <ng-container
                  *ngFor="let category of allergenCategories$ | async">
                  <ng-container
                    *ngIf="category | nutritionPreferenceCheck : allergenTags">
                    <ng-container
                      *ngIf="
                        category
                          | nutritionPreferenceCheck
                            : (product.content.allergen_tags
                                | productAllergenTags : allergenTags)
                      ">
                      <app-filter-icon
                        [allergenCategory]="category"
                        [itemAllergenTags]="
                          product.content.allergen_tags
                            | productAllergenTags : (allergenTags$ | async)!
                        "></app-filter-icon>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'product'">
    <button
      type="button"
      class="product-card product-card-shell w-100 h-100 p-0"
      [disabled]="
        isEditMode && product.chainproductid !== selectedProduct?.chainproductid
      "
      [class.product-card-shell-disabled]="
        isEditMode && product.chainproductid !== selectedProduct?.chainproductid
      "
      [ngClass]="status ? 'product-card-shell-selected' : 'product-card-shell'"
      [style.background-color]="
        product.content?.background_color ?? 'transparent'
      ">
      <div
        class="d-flex flex-row flex-nowrap w-100"
        [class.border-bottom-success]="!isCollapsed"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseBasic"
        (click)="showSuccess(); isCollapsed = !isCollapsed">
        <div class="position-relative">
          <div class="checkmark-container ingredient">
            <div
              class="checkmark-overlay"
              [ngClass]="!isCollapsed ? 'd-flex' : 'd-none'">
              <div class="checkmark-animation">
                <img
                  class="hat-checkmark"
                  alt="checkmark"
                  src="assets/images/location-specific_illustrations/Icons/fb-hat-orange.svg" />
              </div>
            </div>
          </div>
          <img
            [src]="product.content?.image | cmsAsset"
            class="card-img-top product product-image"
            [alt]="product.name" />
        </div>

        <div
          class="card-body align-items-center w-100 d-flex flex-row flex-nowrap text-center justify-content-between gap-2 py-2 px-3">
          <div class="d-flex flex-column text-left">
            <ng-container
              *ngIf="product.content.badge_text && product.content.badge_color">
              <app-badge [product]="product"></app-badge>
            </ng-container>
            <h6 class="product-title w-100">{{ product.name }}</h6>
            <ng-container
              *ngIf="
                product.content.description &&
                  product.content.description !== '';
                else providerDescription
              ">
              <small class="w-100">
                {{ product.content.description | slice : 0 : 20 }}
              </small>
            </ng-container>
            <ng-template #providerDescription>
              <ng-container *ngIf="product.description">
                <small class="w-100">
                  {{ product.description | slice : 0 : 20 }}
                </small>
              </ng-container>
            </ng-template>
            <ng-container *ngIf="product.cost">
              <small class="product-cost">{{ product.cost | currency }}</small>
            </ng-container>
          </div>
          <ng-container *ngIf="selectedAllergenTags$ | async as allergenTags">
            <!--            There are selected allergens selected-->
            <ng-container *ngIf="allergenTags.length">
              <div class="d-flex flex-column justify-content-center gap-1 py-2">
                <ng-container
                  *ngFor="let category of allergenCategories$ | async">
                  <!--                  There are allergens selected that are in this group-->
                  <ng-container
                    *ngIf="category | nutritionPreferenceCheck : allergenTags">
                    <!--                    There are allergens that are assigned to this product that are also selected-->
                    <ng-container
                      *ngIf="
                        category
                          | nutritionPreferenceCheck
                            : (product.content.allergen_tags
                                | productAllergenTags : allergenTags)
                      ">
                      <app-filter-icon
                        [allergenCategory]="category"
                        [itemAllergenTags]="
                          product.content.allergen_tags
                            | productAllergenTags : (allergenTags$ | async)!
                        "></app-filter-icon>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div
        id="collapseBasic"
        [collapse]="isCollapsed"
        [isAnimated]="true"
        class="w-100">
        <ng-container
          *ngIf="product.optiongroups && product.optiongroups.length === 1">
          <div class="edit-shell d-flex flex-nowrap w-100 p-2">
            <div class="edit-group btn-group w-100">
              <ng-container
                *ngFor="let option of product!.optiongroups![0]!.options"
                ><label
                  class="grow-1 basis-0 btn btn-outline-secondary text-capitalize btn-sm"
                  [(ngModel)]="option.name"
                  [btnRadio]="option.name"
                  tabindex="0"
                  role="button"
                  >{{ option.name }}
                  <ng-container *ngIf="option.cost">{{
                    option.cost | currency
                  }}</ng-container>
                </label></ng-container
              >
            </div>
          </div>
        </ng-container>
        <div class="d-flex flex-nowrap w-100 p-2 rounded-bottom">
          <div class="d-flex flex-nowrap gap-3 w-100">
            <div class="d-flex flex-nowrap border border-primary incrementer">
              <button
                class="minus-button btn btn-sm btn-primary d-flex align-items-center justify-content-center"
                (click)="minus($event)"
                [class.disabled]="inputnumber === 1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  fill="currentColor"
                  class="bi bi-dash"
                  viewBox="0 0 16 16">
                  <path
                    d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                </svg>
              </button>

              <input
                type="text"
                class="form-control border-0 rounded-0 text-center p-0"
                [(ngModel)]="inputnumber" />

              <button
                class="plus-button btn btn-sm btn-primary d-flex align-items-center justify-content-center"
                (click)="plus($event)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  fill="currentColor"
                  class="bi bi-plus"
                  viewBox="0 0 16 16">
                  <path
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </button>
            </div>
            <app-button
              class="w-100"
              classes="btn btn-sm btn-primary border border-primary w-100 text-nowrap"
              [isLoading]="addLoading"
              (buttonClick)="addToCart($event)">
              Add to Cart
            </app-button>
          </div>
        </div>
      </div>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'size'">
    <button
      type="button"
      class="product-card product-card-shell flex-row flex-sm-column flex-nowrap flex-sm-wrap rounded w-100 h-100 p-0 align-items-center"
      (click)="productSelected()"
      [class.is-prebuilt]="product.content.is_prebuilt"
      [disabled]="
        isEditMode && product.chainproductid !== selectedProduct?.chainproductid
      "
      [class.product-card-shell-disabled]="
        isEditMode && product.chainproductid !== selectedProduct?.chainproductid
      "
      [ngClass]="
        isSelected ? 'product-card-shell-selected' : 'product-card-shell'
      "
      [style.background-color]="product.content?.background_color ?? '#ffffff'">
      <div class="rounded position-relative">
        <div class="checkmark-container ingredient">
          <div
            class="checkmark-overlay"
            [ngClass]="isSelected ? 'd-flex' : 'd-none'">
            <div class="checkmark-animation">
              <img
                class="hat-checkmark"
                alt="checkmark"
                src="assets/images/location-specific_illustrations/Icons/fb-hat-orange.svg" />
            </div>
          </div>
        </div>
        <img
          [src]="product.content?.image | cmsAsset"
          class="card-img-top rounded"
          [alt]="product.name" />
      </div>
      <div
        class="card-body w-100 d-flex flex-column justify-content-start align-items-start text-start gap-1 h-10 p-3">
        <ng-container
          *ngIf="product.content.badge_text && product.content.badge_color">
          <app-badge [product]="product"></app-badge>
        </ng-container>
        <h5 class="product-title w-100">{{ product.name }}</h5>
        <ng-container
          *ngIf="
            product.content.description && product.content.description !== '';
            else providerDescription
          ">
          <small>
            {{ product.content.description }}
          </small>
        </ng-container>
        <ng-template #providerDescription>
          <ng-container *ngIf="product.description">
            <small>
              {{ product.description }}
            </small>
          </ng-container>
        </ng-template>
        <ng-container *ngIf="product.cost">
          <small class="product-cost w-100">{{
            product.cost | currency
          }}</small>
        </ng-container>
      </div>

      <ng-container *ngIf="product.content.is_prebuilt">
        <ng-container *ngIf="order$ | async as order; else showStartOrder">
          <ng-container *ngIf="orderLocation$ | async as orderLocation">
            <div
              class="px-3 pb-3 w-100 d-flex flex-column flex-md-row flex-wrap gap-3">
              <button
                (click)="
                  isFeatured ? routeToCustomize(product) : productSelected()
                "
                class="btn btn-sm btn-outline-secondary grow-1 basis-0 text-nowrap">
                Customize
              </button>
              <button
                class="btn btn-sm btn-primary grow-1 basis-0 text-nowrap"
                (click)="openQuickAddModal($event, product)">
                Add to Bag
              </button>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #showStartOrder>
          <ng-container *ngIf="isFeatured">
            <div class="px-3 w-100">
              <button
                type="button"
                routerLink="/start-order"
                class="btn btn-primary btn-sm mb-3 w-100">
                Start Order
              </button>
            </div>
          </ng-container>
        </ng-template>
      </ng-container>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'saved'">
    <button
      type="button"
      class="product-card product-card-shell w-100 p-0"
      [ngClass]="status ? 'product-card-shell-selected' : 'product-card-shell'">
      <div
        class="d-flex flex-row flex-nowrap w-100"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseBasic"
        (click)="showSuccess(); isCollapsed = !isCollapsed">
        <div class="position-relative">
          <img
            [src]="savedProduct.content?.image | cmsAsset"
            class="card-img-top product product-image"
            [alt]="savedProduct.name" />
        </div>

        <div
          class="card-body align-items-center w-100 d-flex flex-row flex-nowrap text-center justify-content-center gap-2 py-3 py-sm-2 px-3">
          <h5 class="product-title w-100">{{ savedProduct.name }}</h5>
        </div>
      </div>

      <div
        id="collapseBasic"
        [collapse]="isCollapsed"
        [isAnimated]="true"
        class="w-100">
        <div
          class="d-flex flex-column flex-nowrap bg-light w-100 p-2 rounded-bottom gap-3">
          <div class="d-flex flex-nowrap gap-3 w-100">
            <app-button
              class="w-100"
              classes="btn btn-primary w-100 text-nowrap"
              [isLoading]="addLoading"
              (buttonClick)="addSavedProductToCart($event)">
              Add to Cart
            </app-button>
          </div>
          <div class="d-flex flex-nowrap gap-3 w-100 align-items-end">
            <div class="flex-grow-1 text-black text-left">
              <ng-container
                *ngFor="
                  let choice of savedProduct.product.choices
                    | savedProductChoices;
                  last as last
                ">
                <small class="text-black"
                  >{{ choice.name }}
                  <ng-container *ngIf="choice.deleted"
                    >&nbsp;(No Longer Available)
                  </ng-container>
                  <ng-container *ngIf="!choice.deleted && !choice.available"
                    >&nbsp;(Temporarily Unavailable)
                  </ng-container>
                  <ng-container *ngIf="!last">,&nbsp;</ng-container>
                </small>
              </ng-container>
            </div>
            <button
              class="btn btn-sm btn-primary d-flex align-items-center justify-content-center"
              (click)="deleteSavedProduct($event)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-trash3"
                viewBox="0 0 16 16">
                <path
                  d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'suggested'">
    <div
      class="card border-light w-100 h-100 justify-content-between"
      [style.background-color]="
        product.content?.background_color ?? 'transparent'
      ">
      <div class="d-flex flex-column w-100">
        <div class="d-flex flex-row flex-lg-column w-100">
          <img
            class="suggested-product-image"
            [alt]="product.name"
            [src]="product.content?.image | cmsAsset : 'product'" />
          <div class="card-body d-flex flex-column grow-1 basis-0">
            <ng-container
              *ngIf="product.content.badge_text && product.content.badge_color">
              <app-badge [product]="product"></app-badge>
            </ng-container>
            <h6 class="card-title">{{ product.name }}</h6>
            <ng-container
              *ngIf="
                product.content.description &&
                  product.content.description !== '';
                else providerDescription
              ">
              <small>
                {{ product.content.description }}
              </small>
            </ng-container>
            <ng-template #providerDescription>
              <ng-container *ngIf="product.description">
                <small>
                  {{ product.description }}
                </small>
              </ng-container>
            </ng-template>
            <ng-container *ngIf="product.cost">
              <small class="product-cost">{{ product.cost | currency }}</small>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex flex-nowrap flex-lg-column flex-row gap-3 w-100">
          <div class="d-flex flex-nowrap w-fit-content align-self-center">
            <button
              class="minus-button btn btn-sm btn-primary d-flex align-items-center justify-content-center w-100"
              [class.disabled]="inputnumber === 1"
              (click)="minus($event)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                fill="currentColor"
                class="bi bi-dash"
                viewBox="0 0 16 16">
                <path
                  d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
            </button>

            <input
              type="text"
              class="form-control border border-primary rounded-0 text-center"
              [(ngModel)]="inputnumber" />

            <button
              class="plus-button btn btn-sm btn-primary d-flex align-items-center justify-content-center w-100"
              (click)="plus($event)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                fill="currentColor"
                class="bi bi-plus"
                viewBox="0 0 16 16">
                <path
                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </button>
          </div>
          <app-button
            class="w-100"
            classes="btn btn-sm btn-primary w-100 text-nowrap"
            [isLoading]="addLoading"
            (buttonClick)="addToCart($event)">
            Add to Cart
          </app-button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
