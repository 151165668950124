import { Pipe, PipeTransform } from '@angular/core';
import { AllergenTag, Ingredient, Product } from '@models/index';

@Pipe({
  name: 'productAllergenTags',
})
export class ProductAllergenTagsPipe implements PipeTransform {
  transform(
    itemTags: Product['content']['allergen_tags'],
    allTags: AllergenTag[],
  ): AllergenTag[] {
    const tags: AllergenTag[] = [];
    itemTags.forEach((tag) => {
      const foundTag = allTags.find(
        (allTag) => allTag.id === tag.allergen_tags_id,
      );
      if (foundTag) {
        tags.push(foundTag);
      }
    });
    return tags;
  }
}
