import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { ProgressbarType } from 'ngx-bootstrap/progressbar';

interface IStack {
  type: ProgressbarType;
  label: string;
  value: number;
  max: number;
}

@Component({
  selector: 'app-static',
  templateUrl: './static.component.html',
  styleUrls: ['static.component.scss'],
})
export class StaticComponent implements OnInit {
  title = 'freebirds-v2';

  checkModel: {
    left?: boolean;
    middle?: boolean;
    right?: boolean;
  } = {
    left: false,
    middle: true,
    right: false,
  };
  radioModel = 'Middle';
  myForm?: FormGroup;

  currentPage = 4;
  page?: number;

  dismissible = true;
  defaultAlerts: any[] = [
    {
      type: 'success',
      msg: `You successfully read this important alert message.`,
    },
    {
      type: 'info',
      msg: `This alert needs your attention, but it's not super important.`,
    },
    {
      type: 'danger',
      msg: `Better check yourself, you're not looking too good.`,
    },
  ];
  alerts = this.defaultAlerts;

  type?: string;
  stacked: IStack[] = [];

  constructor(private formBuilder: FormBuilder) {
    this.randomStacked();
  }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      left: false,
      middle: true,
      right: false,
    });
  }

  pageChanged(event: PageChangedEvent): void {
    this.page = event.page;
  }

  reset(): void {
    this.alerts = this.defaultAlerts;
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
  }

  randomStacked(): void {
    const types = ['success', 'info', 'warning', 'danger'];

    this.stacked = [];
    const n = Math.floor(Math.random() * 4 + 1);
    for (let i = 0; i < n; i++) {
      const index = Math.floor(Math.random() * 4);
      const value = Math.floor(Math.random() * 27 + 3);
      this.stacked.push(<IStack>{
        value,
        type: types[index],
        label: value + ' %',
      });
    }
  }
}
