import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DeliveryAddressConfirmModalComponent } from '@common/components';
import {
  AvailableReward,
  AvailableTime,
  GlobalStateModel,
  Order,
  PaymentMethod,
  Restaurant,
  SavedAddress,
  SavedCard,
  TipSettings,
  User,
} from '@models/index';
import { SuggestedProductsModalComponent } from '@modules/checkout/components';
import { CheckoutService } from '@modules/checkout/services';
import { Select, Store } from '@ngxs/store';
import { SubmitBasketDto } from '@server/order/dto';
import { BillingMethod, HandoffMode } from '@server/vendor/olo/interfaces';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { CloseCart } from '@store/actions/app.actions';
import { CreateAccount, SetSavedCards } from '@store/actions/user.actions';
import { collapseAnimation } from 'angular-animations';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import {
  forkJoin,
  iif,
  map,
  merge,
  mergeMap,
  mergeWith,
  Observable,
  of,
  switchMap,
  take,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['checkout.component.scss'],
  animations: [collapseAnimation()],
})
export class CheckoutComponent implements OnInit, AfterViewInit {
  @Select((state: GlobalStateModel) => state.order.order)
  order$!: Observable<Order>;
  @Select((state: GlobalStateModel) => state.locations.orderLocation)
  orderLocation$!: Observable<Restaurant>;
  @Select((state: GlobalStateModel) => state.order.availableOrderTimes)
  availableTimes$!: Observable<AvailableTime[]>;
  @Select((state: GlobalStateModel) => state.user.user)
  user$!: Observable<User>;
  @Select((state: GlobalStateModel) => state.order.availableRewards)
  availableRewards$!: Observable<AvailableReward[]>;
  @Select((state: GlobalStateModel) => state.user.savedCards)
  savedCards$!: Observable<SavedCard[]>;
  @Select((state: GlobalStateModel) => state.order.groupOrder)
  groupOrder$!: Observable<Order>;
  @Select((state: GlobalStateModel) => state.content.tipSettings)
  tipSettings$!: Observable<TipSettings>;

  @Input() parentForm!: FormGroup;

  curbsideForm: FormGroup = new FormGroup({
    make: new FormControl(null, [Validators.required]),
    model: new FormControl(null, [Validators.required]),
    color: new FormControl(null, [Validators.required]),
  });

  userInfoForm: FormGroup = new FormGroup({
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null, [Validators.required]),
  });

  createCheck = new FormControl(false, []);
  createAccountForm: FormGroup = new FormGroup({
    password: new FormControl(null, []),
    passwordConfirm: new FormControl(null, []),
    birthday: new FormControl(null, []),
    emailOptIn: new FormControl(true, []),
    smsOptIn: new FormControl(true, []),
    terms: new FormControl(false, [Validators.requiredTrue]),
  });

  cardPaymentForm: FormGroup = new FormGroup({
    ccNumber: new FormControl(null, [Validators.required]),
    ccExp: new FormControl(null, [Validators.required]),
    ccCVV: new FormControl(null, [Validators.required]),
    ccZip: new FormControl(null, [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
    ]),
    saveOnFile: new FormControl(false, []),
  });

  availableDays: Date[] = [];

  handoffs = HandoffMode;

  selectedSavedCard?: SavedCard;

  bsModalRef?: BsModalRef;

  submitLoading: boolean = false;

  errorMessage: string | null = null;

  ccMask = '0000-0000-0000-0000';
  amexMask = '0000-000000-000009';

  currentCCMask = this.amexMask;

  lockHandoffSwitch = false;

  maxDate = moment().startOf('day').subtract(13, 'years').toDate();

  private createdNewAccount = false;

  constructor(
    private store: Store,
    private checkout: CheckoutService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: BsModalService,
    private analytics: AnalyticsService,
  ) {}
  ngOnInit() {
    this.store.dispatch(new CloseCart());
    this.orderLocation$.subscribe((location) => {
      if (location) {
        this.availableDays = Array.from(
          { length: location.advanceorderdays },
          (_, i) => moment().add(i, 'days').toDate(),
        );
      }
    });
    this.ccNumber.valueChanges.subscribe((value: string | null) => {
      if (value && value.length > 15) {
        this.currentCCMask = this.ccMask;
      } else {
        this.currentCCMask = this.amexMask;
      }
    });

    this.user$
      .pipe(
        withLatestFrom(this.order$),
        map(([user, order]) => order),
      )
      .subscribe((order) => {
        this.store.dispatch(new SetSavedCards(order.id));
      });

    this.createCheck.valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.createAccountPassword.setValidators([
          Validators.required,
          Validators.minLength(8),
        ]);
        this.createAccountConfirmPassword.setValidators([
          Validators.required,
          Validators.minLength(8),
        ]);
      } else {
        this.createAccountPassword.clearValidators();
        this.createAccountConfirmPassword.clearValidators();
      }
      this.createAccountForm.updateValueAndValidity();
    });
  }

  ngAfterViewInit() {
    this.checkout.checkoutPageLoad().subscribe(() => {});
    this.store
      .select((state: GlobalStateModel) => state.menu.upsellProducts)
      .pipe(
        filter(
          (upsellProducts) => !!upsellProducts && upsellProducts.length > 0,
        ),
        take(1),
      )
      .subscribe((upsellProducts) => {
        if (upsellProducts && upsellProducts.length > 0) {
          this.openSuggestedProductsModal();
        }
      });
  }

  onDateSelection(event: Event) {
    this.checkout
      // @ts-ignore
      .setAvailableTimes(new Date(event.target['value']))
      .subscribe(() => {});
  }

  onTimeSelection(event: Event) {
    this.checkout
      // @ts-ignore
      .setAdvancedOrderTime(new Date(event.target['value']))
      .subscribe(() => {});
  }

  setTimeToASAP() {
    this.checkout.setTimeToASAP().subscribe(() => {});
  }

  changeHandoff(handoff: HandoffMode) {
    this.lockHandoffSwitch = true;
    this.checkout.setHandoffMode(handoff).subscribe({
      next: () => (this.lockHandoffSwitch = false),
      error: (err) => {
        this.lockHandoffSwitch = false;
        this.toastr.error(err.error.message, 'Error');
      },
    });
  }

  rewardTrackBy(index: number, item: AvailableReward): string {
    return `${index}${item.rewardid}`;
  }

  openSuggestedProductsModal() {
    this.bsModalRef = this.modalService.show(SuggestedProductsModalComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true,
    });
    this.bsModalRef?.onHide?.pipe(take(1)).subscribe(() => {
      this.checkout.validateOrder().subscribe({
        error: (err) => {
          this.submitLoading = false;
          this.toastr.error(err.error?.message ?? err.message, 'Error');
        },
      });
    });
  }

  submitOrder() {
    this.submitLoading = true;
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        switchMap((order) => {
          // Validate Order
          return this.checkout.validateOrder().pipe(
            switchMap(() => {
              // Gather User Info
              // Apply Curbside Fields if needed
              return this.gatherUserInfo().pipe(
                mergeMap((userInfo) =>
                  iif(
                    () => order!.deliverymode === HandoffMode.CURBSIDE,
                    this.applyCurbsideFields(userInfo),
                    of(userInfo),
                  ),
                ),
                switchMap((finishedUserInfo) => {
                  // Gather Card Data
                  return this.gatherCardData().pipe(
                    switchMap((paymentData) => {
                      // Map Submit Call
                      const mappedCall = this.finishSubmit(
                        finishedUserInfo,
                        paymentData,
                      );
                      this.analytics.logAddPaymentInfo(order!);
                      return this.checkout.submitOrder(
                        mappedCall.billingAccounts,
                        mappedCall.authToken,
                        mappedCall.user,
                        mappedCall.guestOptIn,
                        this.createdNewAccount,
                      );
                    }),
                  );
                }),
              );
            }),
          );
        }),
      )
      .subscribe({
        next: () => {
          this.submitLoading = false;
          this.store
            .selectOnce((state: GlobalStateModel) => state.order.previousOrder)
            .subscribe((prevOrder) => {
              this.router.navigate(['order', 'confirmation', prevOrder!.id]);
            });
        },
        error: (err) => {
          this.submitLoading = false;
          this.toastr.error(err.error?.message ?? err.message, 'Error');
          this.analytics.logCheckoutError();
        },
      });
    // Submit
  }

  private gatherUserInfo(): Observable<Partial<SubmitBasketDto>> {
    if (this.createCheck.value) {
      if (
        !this.createAccountPassword.value ||
        !this.createAccountConfirmPassword.value ||
        this.userInfoForm.invalid ||
        this.createAccountForm.invalid
      ) {
        this.createAccountForm.markAllAsTouched();
        this.userInfoForm.markAllAsTouched();
        this.createAccountForm.updateValueAndValidity();
        this.userInfoForm.updateValueAndValidity();
        throw new Error(
          this.createAccountTerms.invalid
            ? 'You must agree to the terms and conditions to create an account'
            : 'Please fill out all information',
        );
      } else {
        return this.store
          .dispatch(
            new CreateAccount(
              this.firstName.value,
              this.lastName.value,
              this.email.value,
              this.phone.value,
              this.createAccountEmailOptIn.value,
              this.createAccountSNSOptIn.value,
              this.createAccountBirthday.value,
              this.createAccountPassword.value,
              this.createAccountConfirmPassword.value,
              this.createAccountTerms.value,
            ),
          )
          .pipe(
            switchMap(() => {
              this.createdNewAccount = true;
              this.createCheck.setValue(false);
              return this.gatherUserInfo();
            }),
          );
      }
    }
    return this.store
      .selectOnce((state: GlobalStateModel) => state.user)
      .pipe(
        map((userState) => {
          if (userState.user) {
            return <Partial<SubmitBasketDto>>{
              authToken: userState.tokens!.tokens.ordering.token,
            };
          } else {
            if (this.userInfoForm.invalid) {
              this.userInfoForm.markAllAsTouched();
              throw new Error('Please fill out all information');
            }
            return <Partial<SubmitBasketDto>>{
              user: {
                firstname: this.firstName.value!,
                lastname: this.lastName.value!,
                emailaddress: this.email.value!,
                phonenumber: this.phone.value!,
              },
            };
          }
        }),
      );
  }

  private applyCurbsideFields(
    userInfo: Partial<SubmitBasketDto>,
  ): Observable<Partial<SubmitBasketDto>> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        switchMap((order) => {
          if (this.curbsideForm.invalid) {
            this.curbsideForm.markAllAsTouched();
            return throwError(() => new Error('Please fill out curbside info'));
          }
          const makeCustomField = order!.customfields.find((field) =>
            field.label.toLowerCase().includes('make'),
          )!;
          const modelCustomField = order!.customfields.find((field) =>
            field.label.toLowerCase().includes('model'),
          )!;
          const colorCustomField = order!.customfields.find((field) =>
            field.label.toLowerCase().includes('color'),
          )!;
          return forkJoin({
            make: this.checkout.setCustomField(
              makeCustomField.id,
              this.make.value,
            ),
            model: this.checkout.setCustomField(
              modelCustomField.id,
              this.model.value,
            ),
            color: this.checkout.setCustomField(
              colorCustomField.id,
              this.color.value,
            ),
          }).pipe(map(() => userInfo));
        }),
      );
  }

  private gatherCardData(): Observable<PaymentMethod> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        map((order) => {
          if (order!.total === 0) {
            return <PaymentMethod>{
              billingmethod: BillingMethod.CASH,
            };
          }
          if (this.selectedSavedCard) {
            return <PaymentMethod>{
              billingmethod: BillingMethod.BILLING_ACCOUNT,
              amount: order!.total,
              tipportion: order!.tip,
              billingaccountid: this.selectedSavedCard.accountid,
            };
          } else {
            if (this.cardPaymentForm.invalid) {
              this.cardPaymentForm.markAllAsTouched();
              throw new Error('Please fill out payment info');
            }
            return <PaymentMethod>{
              billingmethod: BillingMethod.CREDIT_CARD,
              amount: order!.total,
              tipportion: order!.tip,
              cardnumber: this.ccNumber.value,
              expirymonth: Number(this.ccExp.value.toString().slice(0, 2)),
              expiryyear: 2000 + Number(this.ccExp.value.toString().slice(2)),
              cvv: this.ccCVV.value,
              zip: this.ccZip.value,
              saveonfile: String(this.saveOnFile.value),
            };
          }
        }),
      );
  }

  addressSelect(address: SavedAddress) {
    this.errorMessage = null;
    // eslint-disable-next-line max-len
    // this.store.dispatch(new FindDeliveryLocation(HandoffMode.DISPATCH, address.building, address.streetaddress, address.city, address.zipcode))
    this.modalService.show(DeliveryAddressConfirmModalComponent, {
      initialState: {
        address,
        isCheckout: true,
      },
    });
  }

  editDeliveryAddress(order: Order) {
    const address: SavedAddress = {
      id: 0,
      building: order.deliveryaddress.building,
      streetaddress: order.deliveryaddress.streetaddress,
      city: order.deliveryaddress.city,
      zipcode: order.deliveryaddress.zipcode,
      phonenumber: '',
      specialinstructions: order.deliveryaddress.specialinstructions,
      isdefault: false,
    };
    this.addressSelect(address);
  }

  private finishSubmit(
    userData: Partial<SubmitBasketDto>,
    paymentMethod: PaymentMethod,
  ): SubmitBasketDto {
    return {
      ...userData,
      billingAccounts: [paymentMethod],
    };
  }

  // User Form
  get firstName(): AbstractControl {
    return this.userInfoForm.get('firstName')!;
  }
  get lastName(): AbstractControl {
    return this.userInfoForm.get('lastName')!;
  }
  get email(): AbstractControl {
    return this.userInfoForm.get('email')!;
  }
  get phone(): AbstractControl {
    return this.userInfoForm.get('phone')!;
  }

  // Create Account Form

  get createAccountPassword(): AbstractControl {
    return this.createAccountForm.get('password')!;
  }

  get createAccountConfirmPassword(): AbstractControl {
    return this.createAccountForm.get('passwordConfirm')!;
  }

  get createAccountEmailOptIn(): AbstractControl {
    return this.createAccountForm.get('emailOptIn')!;
  }

  get createAccountSNSOptIn(): AbstractControl {
    return this.createAccountForm.get('smsOptIn')!;
  }

  get createAccountBirthday(): AbstractControl {
    return this.createAccountForm.get('birthday')!;
  }

  get createAccountTerms(): AbstractControl {
    return this.createAccountForm.get('terms')!;
  }

  // Curbside Form

  get make(): AbstractControl {
    return this.curbsideForm.get('make')!;
  }
  get model(): AbstractControl {
    return this.curbsideForm.get('model')!;
  }
  get color(): AbstractControl {
    return this.curbsideForm.get('color')!;
  }

  // Credit Card Form
  get ccNumber(): AbstractControl {
    return this.cardPaymentForm.get('ccNumber')!;
  }
  get ccExp(): AbstractControl {
    return this.cardPaymentForm.get('ccExp')!;
  }
  get ccCVV(): AbstractControl {
    return this.cardPaymentForm.get('ccCVV')!;
  }
  get ccZip(): AbstractControl {
    return this.cardPaymentForm.get('ccZip')!;
  }
  get saveOnFile(): AbstractControl {
    return this.cardPaymentForm.get('saveOnFile')!;
  }

  gotoNextField(nextElement: HTMLInputElement) {
    nextElement.focus();
  }

  public readonly HandoffMode = HandoffMode;
}
