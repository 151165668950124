import { Injectable } from '@angular/core';
import { Product } from '@models/index';
import { ContentService } from '@services/api/content.service';
import { MenuService } from '@services/api/menu.service';
import { concatMap, Observable, of } from 'rxjs';

@Injectable()
export class HomeService {
  constructor(private menu: MenuService, private content: ContentService) {}

  getProductInfo(chainID: number, locationID?: number): Observable<Product> {
    if (locationID) {
      return this.menu.getProduct(locationID, chainID, true);
    } else {
      return this.content.getContentLocations().pipe(
        concatMap((locations) => {
          const location = locations.find((l) => l.is_global_menu);
          return this.menu.getProduct(location!.vendor_id!, chainID, true);
        }),
      );
    }
  }
}
