import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  GlobalStateModel,
  GroupOrder,
  HomePage,
  Menu,
  Order,
  PastOrder,
  PointsBalance,
  Restaurant,
  Reward,
  SavedProduct,
  ScheduledAnnouncement,
  User,
} from '@models/index';
import { Select } from '@ngxs/store';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { SchemaMarkupService } from '@services/schema-markup/schema-markup.service';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  slideInRightOnEnterAnimation,
  slideOutRightOnLeaveAnimation,
} from 'angular-animations';
import { forkJoin, Observable, take, withLatestFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['home.component.scss'],
  animations: [
    slideInRightOnEnterAnimation({ duration: 300 }),
    slideOutRightOnLeaveAnimation({ duration: 300 }),
    fadeOutOnLeaveAnimation({ duration: 300 }),
    fadeInOnEnterAnimation({ duration: 300 }),
  ],
})
export class HomeComponent implements AfterViewInit, OnDestroy {
  @Select((state: GlobalStateModel) => state.order.order)
  order$!: Observable<Order>;
  @Select((state: GlobalStateModel) => state.user.user)
  user$!: Observable<User>;
  @Select((state: GlobalStateModel) => state.user.pastOrders)
  pastOrders$!: Observable<PastOrder[]>;
  @Select((state: GlobalStateModel) => state.locations.orderLocation)
  orderLocation$!: Observable<Restaurant>;
  @Select((state: GlobalStateModel) => state.menu.menu)
  menu$!: Observable<Menu>;
  @Select((state: GlobalStateModel) => state.content.homepage)
  homepage$!: Observable<HomePage>;
  @Select((state: GlobalStateModel) => state.content.homepageLayoutIndex)
  homepageLayoutIndex$!: Observable<number>;
  @Select((state: GlobalStateModel) => state.user.savedProducts)
  savedProducts$!: Observable<SavedProduct[] | null>;
  @Select((state: GlobalStateModel) => state.order.groupOrder)
  groupOrder$!: Observable<GroupOrder>;
  @Select((state: GlobalStateModel) => state.order.ownsGroupOrder)
  isGroupOrderOwner$!: Observable<boolean>;
  @Select((state: GlobalStateModel) => state.user.rewards)
  rewards$!: Observable<Reward[]>;
  @Select((state: GlobalStateModel) => state.user.pointBalance)
  pointBalance$!: Observable<PointsBalance>;

  @ViewChild('menu') menuElement!: ElementRef<HTMLElement>;
  @ViewChild('recentOrders') recentOrdersElement!: ElementRef<HTMLElement>;

  isAtTop: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private schema: SchemaMarkupService,
    private analytics: AnalyticsService,
    @Inject(PLATFORM_ID)
    private platformId: Object,
  ) {}

  ngAfterViewInit() {
    this.route.fragment.subscribe((fragment) => {
      if (fragment === 'menu') {
        setTimeout(() => {
          document.getElementById('menu')!.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 500);
      } else if (fragment === 'orders') {
        this.pastOrders$.pipe(take(1)).subscribe(() => {
          setTimeout(() => {
            document.getElementById('recentOrders')?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }, 500);
        });
      }
    });
    this.menu$
      .pipe(
        filter((m) => !!m),
        take(1),
      )
      .subscribe((menu) => {
        const schemaMarkup = document.createElement('script');
        schemaMarkup.type = 'application/ld+json';
        schemaMarkup.text = JSON.stringify(this.schema.homePageMarkup(menu));
        schemaMarkup.id = 'schemaMarkup';
        document.head.appendChild(schemaMarkup);
      });
    forkJoin({
      user: this.user$.pipe(take(1)),
      index: this.homepageLayoutIndex$.pipe(take(1)),
      homepage: this.homepage$.pipe(
        filter((h) => !!h),
        take(1),
      ),
    }).subscribe(({ user, index, homepage }) => {
      this.analytics.logHomePageView(
        homepage!.modular_layouts![index]!.item!.title!,
        user,
      );
    });
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      document.getElementById('schemaMarkup')?.remove();
    }
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    this.isAtTop = window.scrollY === 0;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
