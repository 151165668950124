import { Injectable } from '@angular/core';
import { ContentService } from '@services/api/content.service';
import { LocationsService } from '@services/api/locations.service';
import {Observable, of, throwError} from 'rxjs';

@Injectable()
export class BrochureService {
  constructor(
    private contentService: ContentService,
    private location: LocationsService,
  ) {}

  sendContactForm(
    category: 'feedback' | 'media' | 'catering' | 'spirit-night' | 'donation' | 'data-request',
    message: object,
  ) {
    return this.contentService.postContactFormSubmission(category, message);
    // The below comments can be uncommented to test successful or error responses
    // return of(true);
    // return throwError(true);
  }

  getLocations() {
    return this.location.getAllLocations(false, false);
  }
}
