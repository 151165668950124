import { Component, Input, OnInit } from '@angular/core';
import {
  GoLeaveModalComponent,
  GroupOrderLinkModalComponent,
} from '@common/components';
import { GroupOrder } from '@models/index';
import { Store } from '@ngxs/store';
import { DeviceDetectionService } from '@services/device-detection/device-detection.service';
import { CloseCart } from '@store/actions/app.actions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-go-details',
  templateUrl: './go-details.component.html',
  styleUrls: ['go-details.component.scss'],
})
export class GoDetailsComponent implements OnInit {
  @Input() groupOrder!: GroupOrder | null;
  @Input() groupOrderHost = false;

  bsModalRef?: BsModalRef;

  textToCopy?: string;

  constructor(
    private modalService: BsModalService,
    private toast: ToastrService,
    private device: DeviceDetectionService,
    private store: Store,
  ) {}
  ngOnInit() {
    this.textToCopy = `https://www.freebirds.com/order/group-order?groupOrderID=${
      this.groupOrder!.id
    }&orderID=${this.groupOrder!.basket.id}#join`;
  }

  copyText() {
    if ((window as any).ReactNativeWebView) {
      this.openGroupOrderLinkModal();
    } else {
      if (
        !navigator.canShare ||
        !navigator.canShare({ url: this.textToCopy! }) ||
        this.device.isDesktop()
      ) {
        navigator.clipboard
          .writeText(this.textToCopy!)
          .then(() => {
            this.toast.success('Link copied!');
          })
          .catch((error) => {
            this.openGroupOrderLinkModal();
          });
      } else {
        navigator
          .share({ url: this.textToCopy! })
          .then(() => {
            this.toast.success('Link shared!');
          })
          .catch(() => {
            navigator.clipboard
              .writeText(this.textToCopy!)
              .then(() => {
                this.toast.success('Link copied!');
              })
              .catch((error) => {
                this.openGroupOrderLinkModal();
              });
          });
      }
    }
  }

  openGOLeaveModal() {
    this.bsModalRef = this.modalService.show(GoLeaveModalComponent, {});
  }

  openGroupOrderLinkModal() {
    this.store.dispatch(new CloseCart());
    this.modalService.show(GroupOrderLinkModalComponent, {
      initialState: {
        textToCopy: this.textToCopy,
      },
      backdrop: 'static',
    });
  }
}
