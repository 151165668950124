<app-navbar></app-navbar>

<section>
  <h3 class="points-transaction-header py-3 bg-light position-relative">
    <div class="container position-relative">
      <button (click)="back()" type="button" class="back-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16">
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
        </svg>
        Back
      </button>
    </div>
    <div class="text-center pt-4 pb-5 position-relative">
      Points Transaction History
    </div>
  </h3>

  <div class="container">
    <ngx-timeline
      [events]="
        (accountActivity$ | async)! | accountActivityToNgxTimelineEvents
      "
      [reverseOrder]="true"></ngx-timeline>
  </div>
</section>
