import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckForDeliveryRequestDto } from '@server/location/dto/check-for-delivery-request.dto';
import {
  CheckForDeliveryResponse,
  GetAllLocationsResponse,
  GetLocationBySlugResponse,
  GetLocationResponse,
  GetLocationsNearResponse,
} from '@server/location/responses';
import { HandoffMode, TimeModeType } from '@server/vendor/olo/interfaces';
import { Moment } from 'moment';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocationsService {
  private locationsAPIPrefix = environment.apiBaseURL + '/api/locations';

  constructor(private http: HttpClient) {}

  getAllLocations(
    withCalendars: boolean = false,
    includePrivate = false,
  ): Observable<GetAllLocationsResponse> {
    const resource = `${this.locationsAPIPrefix}`;
    const queryParams: HttpParams = new HttpParams({
      fromObject: {
        withCalendars,
        includePrivate,
      },
    });
    return this.http.get<GetAllLocationsResponse>(resource, {
      params: queryParams,
    });
  }

  getNearLocations(
    latitude: number,
    longitude: number,
    radius: number,
    limit: number,
    withCalendars: boolean = false,
  ): Observable<GetLocationsNearResponse> {
    const resource = `${this.locationsAPIPrefix}/near`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        latitude,
        longitude,
        radius,
        limit,
        withCalendars,
      },
    });
    return this.http.get<GetLocationsNearResponse>(resource, {
      params,
    });
  }

  getLocationByID(
    id: number,
    withCalendars: boolean = true,
  ): Observable<GetLocationResponse> {
    const resource = `${this.locationsAPIPrefix}/${id}`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        withCalendars,
      },
    });
    return this.http.get<GetLocationResponse>(resource, { params });
  }

  getLocationBySlug(
    slug: string,
    withCalendars: boolean = true,
  ): Observable<GetLocationBySlugResponse> {
    const resource = `${this.locationsAPIPrefix}/bySlug/${slug}`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        withCalendars,
      },
    });
    return this.http.get<GetLocationResponse>(resource, { params });
  }

  checkForDelivery(
    restaurantID: number,
    handoffMode: HandoffMode.DELIVERY | HandoffMode.DISPATCH,
    timeWantedMode: TimeModeType.ASAP | TimeModeType.ADVANCE,
    street: string,
    city: string,
    zipcode: string,
    timeWantedUTC?: Moment,
  ): Observable<CheckForDeliveryResponse> {
    const resource = `${this.locationsAPIPrefix}/checkForDelivery`;
    const body: CheckForDeliveryRequestDto = {
      restaurantID,
      handoffMode,
      timeWantedMode,
      street,
      city,
      zipcode,
      timeWantedUTC: timeWantedUTC
        ? timeWantedUTC.utc().toISOString()
        : undefined,
    };
    return this.http.post<CheckForDeliveryResponse>(resource, body);
  }
}
