<div class="ingredient-pill">
  <img
    class="ingredient-pill-image"
    [src]="ingredient?.content?.image | cmsAsset"
    [alt]="ingredient.name" />
  <small class="ingredient-pill-name">{{ ingredient.name }}</small>
  <button class="remove-button p-0 d-flex" (click)="removePill()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-x-lg"
      viewBox="0 0 16 16">
      <path
        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
    </svg>
  </button>
</div>
