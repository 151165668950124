import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Message } from '@models/index';
import { ProfileService } from '@modules/profile/services';

@Component({
  selector: 'app-inbox-message',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './inbox-message.component.html',
  styleUrls: ['inbox-message.component.scss'],
})
export class InboxMessageComponent {
  @Input() message!: Message;

  isDismissing: boolean = false;

  constructor(private profile: ProfileService) {}

  dismissMessage() {
    this.isDismissing = true;
    this.profile.dismissMessage(String(this.message.message_id));
  }
}
