import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FavoriteLocation, GlobalStateModel } from '@models/index';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-favorite-locations-section',
  templateUrl: './favorite-locations-section.component.html',
  styleUrls: ['favorite-locations-section.component.scss'],
})
export class FavoriteLocationsSectionComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.user.favoriteLocations)
  favoriteLocations$!: Observable<FavoriteLocation[]>;

  constructor() {}
  ngOnInit() {}
}
