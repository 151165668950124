import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dont-go-modal',
  templateUrl: './dont-go-modal.component.html',
  styleUrls: ['dont-go-modal.component.scss'],
})
export class DontGoModalComponent implements OnInit {
  constructor(public bsModalRef: BsModalRef) {}
  ngOnInit() {}
}
