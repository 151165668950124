import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Product } from '@models/index';
import { OrderService } from '@modules/order/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-side-with-size-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './side-with-size-modal.component.html',
  styleUrls: ['side-with-size-modal.component.scss'],
})
export class SideWithSizeModalComponent implements OnInit {
  @Input() product!: Product;
  @Input() quantity!: number;

  addLoading = false;

  optionControl = new FormControl(null, []);

  constructor(
    public bsModalRef: BsModalRef,
    private orderService: OrderService,
    private toastr: ToastrService,
  ) {}
  ngOnInit() {
    if (this.product.optiongroups![0].mandatory) {
      this.optionControl.setValidators([Validators.required]);
      this.optionControl.updateValueAndValidity();
    }
    if (this.product.optiongroups![0].options.find((o) => o.isdefault)) {
      this.optionControl.setValue(
        this.product.optiongroups![0].options.find((o) => o.isdefault)!.id,
      );
    }
  }

  async onAdd() {
    this.addLoading = true;
    const selectedOption = this.product.optiongroups![0].options.find(
      (o) => o.id === this.optionControl.value,
    );
    try {
      await this.orderService
        .addToOrder(
          this.product.id,
          this.quantity,
          selectedOption ? [selectedOption] : [],
        )
        .toPromise();
      this.toastr.success(
        `${this.quantity}x ${this.product.name} added to cart`,
        'Success!',
        {
          timeOut: 2500,
        },
      );
      this.addLoading = false;
      this.bsModalRef.hide();
    } catch (error: any) {
      this.toastr.error(
        error.error?.message ? error.error.message : error.message,
        'Error',
        {
          timeOut: 2500,
        },
      );
      this.addLoading = false;
    }
  }
}
