<div class="card bg-white grow-1 h-100">
  <h2 class="card-header">Favorite Locations</h2>
  <ng-container *ngIf="favoriteLocations$ | async as favoriteLocations">
    <ng-container *ngIf="favoriteLocations.length; else noCards">
      <div class="card-body grow-1 fave-locations-grid gap-3">
        <ng-container *ngFor="let location of favoriteLocations">
          <app-fave-location-card
            [location]="location"></app-fave-location-card>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noCards>
    <div class="card-body d-flex flex-column grow-1 align-items-center gap-3">
      <div class="d-flex w-100 justify-content-center">
        <img
          class="empty-icon"
          src="assets/images/icons/Icons/fb-garlic-sad.svg"
          alt="" />
      </div>

      <div class="lead">You currently do not have any favorite locations.</div>

      <button type="button" class="btn btn-primary" routerLink="/start-order">
        Start Order
      </button>
    </div>
  </ng-template>
</div>
