import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FavoriteLocation, GlobalStateModel, Restaurant } from '@models/index';
import { Store } from '@ngxs/store';
import { FavoriteLocationPipe } from '@pipes/favorite-location.pipe';
import { GetMenuResponse } from '@server/menu/responses';
import { HandoffMode } from '@server/vendor/olo/interfaces';
import { LocationsService as LocationAPIService } from '@services/api/locations.service';
import { MenuService } from '@services/api/menu.service';
import { DeviceDetectionService } from '@services/device-detection/device-detection.service';
import { SetMenu } from '@store/actions/menu.actions';
import { SetHandoffMode, StartOrder } from '@store/actions/order.actions';
import {
  DeleteFavoriteLocation,
  SaveFavoriteLocation,
} from '@store/actions/user.actions';
import { map, Observable, of, switchMap, take } from 'rxjs';

@Injectable()
export class LocationsService {
  constructor(
    private store: Store,
    private favoriteLocationPipe: FavoriteLocationPipe,
    private location: LocationAPIService,
    private router: Router,
    private menu: MenuService,
  ) {}

  toggleFavoriteLocation(location: Restaurant): Observable<void> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.user.favoriteLocations)
      .pipe(
        switchMap((faveLocations) => {
          if (this.favoriteLocationPipe.transform(location, faveLocations!)) {
            return this.store.dispatch(new DeleteFavoriteLocation(location.id));
          } else {
            return this.store.dispatch(new SaveFavoriteLocation(location.id));
          }
        }),
      );
  }

  favoriteLocationToRestaurant(favorite: FavoriteLocation): Restaurant {
    return <Restaurant>{
      ...favorite,
      id: favorite.vendorid,
      sort: 0,
      content: favorite.content,
    };
  }

  retrieveLocationBySlug(slug: string): Observable<Restaurant> {
    return this.location.getLocationBySlug(slug, true);
  }

  retrieveLocationMenu(locationID: number): Observable<GetMenuResponse> {
    return this.menu.getMenu(locationID);
  }

  startOrder(locationID: number) {
    return this.store.dispatch(new StartOrder(locationID)).pipe(
      switchMap((state: GlobalStateModel) => {
        if (state.locations.orderLocation?.supportsdrivethru) {
          return this.store.dispatch(
            new SetHandoffMode(state.order.order!.id, HandoffMode.DRIVE_THRU),
          );
        } else {
          return this.store.dispatch(
            new SetHandoffMode(state.order.order!.id, HandoffMode.PICKUP),
          );
        }
      }),
    );
  }

  finishAndGoToMenu(groupOrder: boolean) {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        switchMap((order) => {
          return this.store
            .dispatch(new SetMenu(order!.vendorid, order!.deliverymode))
            .pipe(
              map(() => {
                if (groupOrder) {
                  this.router.navigate(['order', 'group-order'], {
                    fragment: 'create',
                  });
                } else {
                  this.router.navigate([''], { fragment: 'menu' });
                }
              }),
            );
        }),
      );
  }
}
