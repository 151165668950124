import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-rewards-perks-container',
    templateUrl: './rewards-perks-container.component.html',
    styleUrls: ['rewards-perks-container.component.scss'],
})
export class RewardsPerksContainerComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
