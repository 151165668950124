import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'getFormControlFromParent',
})
export class GetFormControlFromParentPipe implements PipeTransform {
  transform(parentGroup: FormGroup, controlName: string): AbstractControl {
    return parentGroup.get(controlName)!;
  }
}
