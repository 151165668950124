import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollSpyService } from '@common/services';
import {
  Category,
  GlobalStateModel,
  HomePage,
  Ingredient,
  IngredientGroup,
  Menu,
  Product,
  Restaurant,
  SavedProduct,
  Subcategory,
} from '@models/index';
import {
  OrderService,
  ProductCustomizationService,
} from '@modules/order/services';
import { Select, Store } from '@ngxs/store';
import { Products } from '@server/vendor/directus/interfaces/directus-collection.interface';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { MetaDataService } from '@services/meta-data/meta-data.service';
import { SetCategory, SetProduct } from '@store/actions/menu.actions';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  slideInRightOnEnterAnimation,
  slideOutRightOnLeaveAnimation,
} from 'angular-animations';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  distinctUntilKeyChanged,
  forkJoin,
  Observable,
  Subscription,
  take,
  tap,
} from 'rxjs';
import { filter } from 'rxjs/operators';

export interface OptionGroupStatus {
  optionGroupID: number;
  isMandatory: boolean;
  maxSelects: number | null;
  minSelects: number | null;
  isComplete: boolean;
  options: Ingredient[];
  optionGroup: IngredientGroup;
}

export interface OptionGroupChange {
  option: Ingredient;
  optionGroup: IngredientGroup;
}

interface AnyObject {
  [key: string]: any;
}

// interface InitialProductSelection {
//   categoryName: string;
//   productName: string;
// }

@Component({
  selector: 'app-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['customize.component.scss'],
  animations: [
    trigger('toggle', [
      state(
        'closed',
        style({
          height: 0,
          // overflow: 'hidden',
        }),
      ),
      state(
        'open',
        style({
          height: '*',
          // overflow: 'hidden',
        }),
      ),
      transition('closed => open', animate('300ms ease-in')),
      transition('open => closed', animate('300ms ease-in')),
    ]),
    slideInRightOnEnterAnimation({ duration: 300 }),
    slideOutRightOnLeaveAnimation({ duration: 300 }),
    fadeOutOnLeaveAnimation({ duration: 300 }),
    fadeInOnEnterAnimation({ duration: 300 }),
  ],
})
export class CustomizeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Select((state: GlobalStateModel) => state.menu.category)
  category$!: Observable<Category>;
  @Select((state: GlobalStateModel) => state.menu.product)
  product$!: Observable<Product>;
  @Select((state: GlobalStateModel) => state.locations.orderLocation)
  orderLocation$!: Observable<Restaurant>;
  @Select((state: GlobalStateModel) => state.menu.upsellProducts)
  upsells$!: Observable<Product[]>;
  @Select((state: GlobalStateModel) => state.user.savedProducts)
  savedProducts$!: Observable<SavedProduct[] | null>;
  @Select((state: GlobalStateModel) => state.menu.menu)
  menu$!: Observable<Menu>;
  @Select((state: GlobalStateModel) => state.content.homepage)
  homepage$!: Observable<HomePage>;

  bsModalRef?: BsModalRef;
  isVisible = true;
  ingredientList: string[] = [];
  isSavedProducts = false;
  isEditMode: boolean = false;
  basketProductID?: number;
  isAtTop: boolean = true;
  private subs: Subscription[] = [];
  currentCategoryID!: string | null;

  @Input() isIngredientGroup = true;

  constructor(
    private modalService: BsModalService,
    private location: Location,
    private store: Store,
    public route: ActivatedRoute,
    private meta: MetaDataService,
    private router: Router,
    public productCustomization: ProductCustomizationService,
    private order: OrderService,
    private analytics: AnalyticsService,
    private spy: ScrollSpyService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.subs.push(
      this.route.params.subscribe((params) => {
        this.isSavedProducts = params['categoryID'] === 'saved-products';
        if (this.isSavedProducts) {
          this.savedProducts$.subscribe((sp) => {
            if (!sp?.length) {
              this.router.navigate([''], { fragment: 'menu' });
            }
          });
        }
      }),
      forkJoin({
        orderLocation: this.orderLocation$.pipe(
          filter((ol) => ol !== null),
          take(1),
        ),
        params: this.route.params.pipe(
          filter((p) => p !== null),
          take(1),
        ),
      }).subscribe((results) => {
        if (results.params['categoryID'] && results.orderLocation) {
          this.store
            .dispatch(
              new SetCategory(
                results.orderLocation.id,
                Number(results.params['categoryID']),
              ),
            )
            .subscribe(() => {
              this.route.queryParams.subscribe((queryParams) => {
                if (queryParams['edit']) {
                  this.isEditMode = true;
                  this.order
                    .retrieveBasketItemFromBasketID(queryParams['edit'])
                    .subscribe((basketItem) => {
                      this.order
                        .getSelectedOptionsFromBasketProduct(basketItem)
                        .subscribe((selectedOptions) => {
                          this.basketProductID = basketItem.id;
                          this.spinner.show('walking-burrito');
                          setTimeout(() => {
                            this.productCustomization.clearSelections();
                            selectedOptions.forEach((option) => {
                              this.productCustomization.addToSelectedIngredients(
                                option as Ingredient,
                              );
                            });
                            setTimeout(() => {
                              if (
                                !this.areArraysOfObjectsEqual(
                                  this.productCustomization.currentlySelected,
                                  selectedOptions,
                                )
                              ) {
                                this.productCustomization.setCurrentlySelectedIngredients(
                                  selectedOptions as Ingredient[],
                                );
                              }
                            }, 500);

                            this.spinner.hide('walking-burrito');
                          }, 0);
                        });
                    });
                }
              });
            });
        }
      }),
      this.category$.subscribe((category: Category) => {
        if (category) {
          this.meta.manualUpdate({
            title: `Freebirds World Burrito | ${category.name}`,
            description: category.description
              ? category.description
              : category.name,
          });
          // if (category?.products?.length) {
          //   this.setProduct(category?.products[0].chainproductid);
          // }
        }
      }),
      this.product$
        .pipe(
          filter((p) => p !== null),
          // distinctUntilKeyChanged('chainproductid'),
        )
        .subscribe((product: Product) => {
          if (product && product.optiongroups && product.optiongroups.length) {
            this.ingredientList = [];
            this.productCustomization.clearSelections();
          }
          this.analytics.logCustomizePageView(
            product,
            this.store.selectSnapshot(
              (state: GlobalStateModel) => state.user.user,
            )!,
          );
          this.spinner.hide('walking-burrito');
        }),
      this.route.params.subscribe((params) => {
        this.currentCategoryID = params['categoryID'];
      }),
    );
  }

  ngAfterViewInit() {
    this.spy.spy({ thresholdBottom: window.innerHeight / 2 });
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  setProduct(chainProductID: number) {
    this.store
      .select((state: GlobalStateModel) => state.locations.orderLocation)
      .pipe(
        filter((ol) => ol !== null),
        take(1),
      )
      .subscribe((orderLocation) => {
        this.store
          .dispatch(new SetProduct(orderLocation!.id, chainProductID, true))
          .subscribe(() => {
            document.getElementById('ingredient-start')?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          });
      });
  }

  optionGroupTracker(index: number, group: IngredientGroup) {
    return `${index}${group.id}`;
  }

  back(): void {
    this.location.back();
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    this.isAtTop = window.pageYOffset === 0;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private areObjectsEqual(obj1: AnyObject, obj2: AnyObject): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        (areObjects && !this.areObjectsEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  }

  private isObject(object: any): object is AnyObject {
    return object != null && typeof object === 'object';
  }

  public areArraysOfObjectsEqual(
    arr1: AnyObject[],
    arr2: AnyObject[],
  ): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (!this.areObjectsEqual(arr1[i], arr2[i])) {
        return false;
      }
    }

    return true;
  }
}
