<div
  class="logos d-flex flex-row flex-nowrap py-2 py-lg-3"
  [ngClass]="section.marquee_theme!">
  <ng-container *ngFor="let i of [].constructor(20)">
    <div class="logos-slide d-flex flex-row flex-nowrap align-items-center">
      <div
        (click)="linkClick(section.marquee_title_link)"
        [class.btn-link]="section.marquee_title_link"
        [class.cursor-pointer]="section.marquee_title_link">
        <ng-container *ngIf="section.marquee_title">
          <h3>
            {{ section.marquee_title }}
          </h3>
        </ng-container>
      </div>
      <ng-container *ngIf="section.marquee_subheading">
        <small class="px-4 px-lg-5">
          {{ section.marquee_subheading }}
        </small>
      </ng-container>
    </div>
  </ng-container>
</div>
