<div class="container py-4 py-md-5 d-flex flex-column align-items-center"
     *ngIf="displayRewards.length > 0 || lockedRewards.length > 0">
  <div class="text-center w-100">
    <div class="position-relative justify-content-center d-flex">
      <h1 class="position-relative w-fit-content">
                <span
                  class="d-flex text-primary position-absolute end-0" style="transform: translate(115%, -35%)"
                  [tooltip]="howToRewards"
                  placement="auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-patch-question"
                    viewBox="0 0 16 16">
                    <path
                      d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.71 1.71 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745z" />
                    <path
                      d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                    <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" />
                  </svg>
                </span>
        My Rewards
      </h1>
    </div>
    <p class="w-100 mb-2">
      Your reward awaits – just add it to your cart!
      <br>
      Rewards are applied at checkout.
    </p>
    <div class="mb-3">
      <button class="btn btn-link" (click)="openRewardsModal()">View All Rewards</button>
    </div>
  </div>
  <div class="card-grid">
    <ng-container *ngFor="let reward of displayRewards">
      <app-reward-card
        [rewardCardStyle]="'horizontal'"
        [isUnlocked]="reward.points_required_to_redeem <= pointBalances.redeemable_points"
        [reward]="reward">
      </app-reward-card>
    </ng-container>
    <ng-container *ngFor="let reward of lockedRewards">
      <app-reward-card
        [rewardCardStyle]="'horizontal'"
        [isUnlocked]="false"
        [reward]="reward">
      </app-reward-card>
    </ng-container>
  </div>
</div>

<ng-template #howToRewards>
  <div class="d-flex gap-3 align-items-center justify-content-center flex-column p-3">
    <h5>How to Use Your Rewards:</h5>
    <div>
      To redeem a reward, please add the item related to the reward to
      your cart and then redeem the reward at checkout.
    </div>
  </div>
</ng-template>
