import { Component, Input, OnInit } from '@angular/core';
import { HomePage, PastOrder, User } from '@models/index';
import { expandOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-recent-order-preview',
  templateUrl: './recent-order-preview.component.html',
  styleUrls: ['recent-order-preview.component.scss'],
  animations: [expandOnEnterAnimation()],
})
export class RecentOrderPreviewComponent implements OnInit {
  @Input() pastOrders!: PastOrder[];
  @Input() user!: User;
  @Input() homepage!: HomePage;

  constructor() {}
  ngOnInit() {}
}
