import { Component, HostListener, OnInit } from '@angular/core';
import { GlobalStateModel } from '@models/index';
import { Select } from '@ngxs/store';
import { GetLegalResponse } from '@server/content/responses';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  slideInRightOnEnterAnimation,
  slideOutRightOnLeaveAnimation,
} from 'angular-animations';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['legal.component.scss'],
  animations: [
    slideInRightOnEnterAnimation({ duration: 300 }),
    slideOutRightOnLeaveAnimation({ duration: 300 }),
    fadeOutOnLeaveAnimation({ duration: 300 }),
    fadeInOnEnterAnimation({ duration: 300 }),
  ],
})
export class LegalComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.content.legal)
  legal$!: Observable<GetLegalResponse>;
  isAtTop: boolean = true;
  activeTab: string = 'termsAndConditions';

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        this.activeTab = fragment;
      } else {
        this.activeTab = 'termsAndConditions'; // default tab
      }
    });
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    this.isAtTop = window.pageYOffset === 0;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  navigateToSection(fragment: string | undefined): void {
    if (fragment) {
      this.activeTab = fragment;
      this.router.navigate(['/legal'], { fragment });
    }
  }
}
