<div class="d-flex flex-nowrap gap-3 pb-2">
  <h6 class="grow-1">
    {{
      order.deliverymode === HandoffMode.DISPATCH
        ? (tipSettings.delivery_section_subtitle ? tipSettings.delivery_section_subtitle : 'Tipping your driver for great service is always appreciated.')
        : (tipSettings.pickup_section_subtitle ? tipSettings.pickup_section_subtitle : "Tip us and we'll consider naming a burrito after you!")
    }}
  </h6>
  <div>{{ order.tip | currency }}</div>
</div>
<div class="flex-grow-1 d-flex flex-row flex-nowrap gap-3 w-100">
  <div class="btn-group grow-2 basis-0">
    <button
      class="btn w-100"
      [ngClass]="
        (order.tip | number : '1.2-2') ===
        (order.subtotal * 0.15 | number : '1.2-2')
          ? 'btn-primary'
          : 'btn-outline-secondary'
      "
      tabindex="0"
      role="button"
      (click)="applyTip(order.subtotal * 0.15)">
      15%
    </button>
    <button
      class="btn w-100"
      [ngClass]="
        (order.tip | number : '1.2-2') ===
        (order.subtotal * 0.2 | number : '1.2-2')
          ? 'btn-primary'
          : 'btn-outline-secondary'
      "
      tabindex="0"
      role="button"
      (click)="applyTip(order.subtotal * 0.2)">
      20%
    </button>
    <button
      class="btn w-100"
      [ngClass]="
        (order.tip | number : '1.2-2') ===
        (order.subtotal * 0.25 | number : '1.2-2')
          ? 'btn-primary'
          : 'btn-outline-secondary'
      "
      tabindex="0"
      role="button"
      (click)="applyTip(order.subtotal * 0.25)">
      25%
    </button>
  </div>
  <form [formGroup]="tipForm">
    <div class="form-floating grow-1 basis-0">
      <input
        class="form-control form-control-sm"
        placeholder="Custom Tip"
        (blur)="applyTip(customTip.value!)"
        formControlName="customTip"
        type="text"
        [dropSpecialCharacters]="false"
        mask="0*.00"
        prefix="$"
        id="tip" />
      <label class="col-form-label col-form-label-sm" for="tip"
        >Custom Tip</label
      >
    </div>
  </form>
</div>
