import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-os-texas-growth',
  templateUrl: './os-texas-growth.component.html',
  styleUrls: ['os-texas-growth.component.scss'],
})
export class OsTexasGrowthComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
