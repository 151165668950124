import { Component, HostListener, OnInit } from '@angular/core';
import { FAQ, FAQSections, GlobalStateModel } from '@models/index';
import { Select } from '@ngxs/store';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  slideInRightOnEnterAnimation,
  slideOutRightOnLeaveAnimation,
} from 'angular-animations';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-brochure',
  templateUrl: './faq.component.html',
  styleUrls: ['faq.component.scss'],
  animations: [
    slideInRightOnEnterAnimation({ duration: 300 }),
    slideOutRightOnLeaveAnimation({ duration: 300 }),
    fadeOutOnLeaveAnimation({ duration: 300 }),
    fadeInOnEnterAnimation({ duration: 300 }),
  ],
})
export class FaqComponent {
  @Select((state: GlobalStateModel) => state.content.faq)
  faq$!: Observable<FAQSections>;
  isAtTop: boolean = true;

  @HostListener('window:scroll')
  onWindowScroll() {
    this.isAtTop = window.pageYOffset === 0;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
