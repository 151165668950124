import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  AllergenCategory,
  AllergenTag,
  Category,
  Ingredient,
  Menu,
  Product,
} from '@models/index';
import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import { ContentService } from '@services/api/content.service';
import { LocationsService } from '@services/api/locations.service';
import { MenuService } from '@services/api/menu.service';
import {
  AddAllergenTag,
  AddToSelectedItems,
  ClearNutritionCategory,
  ClearNutritionPreferences,
  InitializeAllergens,
  InitializeNutritionMenu,
  RemoveAllergenTag,
  RemoveFromSelectedItems,
  SetNutritionCategory,
  SetNutritionProduct,
} from '@store/actions/nutrition.actions';
import { forkJoin, map, of, switchMap } from 'rxjs';

import { environment } from '../../environments/environment';

export interface NutritionStateModel {
  allergenCategories: AllergenCategory[];
  allergenTags: AllergenTag[];
  menu: Menu | null;
  category: Category | null;
  product: Product | null;
  currentSelections: Ingredient[];
  selectedAllergenTags: AllergenTag[];
}

@State<NutritionStateModel>({
  name: 'nutrition',
  defaults: {
    allergenCategories: [],
    allergenTags: [],
    menu: null,
    category: null,
    product: null,
    currentSelections: [],
    selectedAllergenTags: [],
  },
})
@Injectable()
export class NutritionState implements NgxsOnInit {
  private globalMenuID: number = 0;

  constructor(
    private menu: MenuService,
    private locations: LocationsService,
    private content: ContentService,
  ) {}

  ngxsOnInit(ctx: StateContext<NutritionStateModel>) {
    return ctx.dispatch([
      new InitializeNutritionMenu(),
      new InitializeAllergens(),
    ]);
  }

  @Action(InitializeNutritionMenu)
  initializeNutritionMenu(
    ctx: StateContext<NutritionStateModel>,
    action: InitializeNutritionMenu,
  ) {
    return this.locations.getAllLocations(false, false).pipe(
      switchMap((locations) => {
        const globalMenuLocation = locations.restaurants.find(
          (location) => location.content.is_global_menu,
        );
        if (globalMenuLocation) {
          this.globalMenuID = environment.useDemoVendorAsGlobal
            ? 170621
            : globalMenuLocation.id;
          return this.menu.getMenu(this.globalMenuID).pipe(
            map((menu) => {
              return ctx.patchState({
                menu,
              });
            }),
          );
        } else {
          return of();
        }
      }),
    );
  }

  @Action(InitializeAllergens)
  initializeAllergens(
    ctx: StateContext<NutritionStateModel>,
    action: InitializeAllergens,
  ) {
    return forkJoin({
      categories: this.content.getAllergenCategories(),
      tags: this.content.getAllergenTags(),
    }).pipe(
      map(({ categories, tags }) => {
        return ctx.patchState({
          allergenCategories: categories,
          allergenTags: tags,
        });
      }),
    );
  }

  @Action(AddAllergenTag)
  addAllergenTag(
    ctx: StateContext<NutritionStateModel>,
    action: AddAllergenTag,
  ) {
    return ctx.patchState({
      selectedAllergenTags: [
        ...ctx.getState().selectedAllergenTags,
        action.tag,
      ],
    });
  }

  @Action(RemoveAllergenTag)
  removeAllergenTag(
    ctx: StateContext<NutritionStateModel>,
    action: RemoveAllergenTag,
  ) {
    return ctx.patchState({
      selectedAllergenTags: ctx
        .getState()
        .selectedAllergenTags.filter((tag) => tag.id !== action.tag.id),
    });
  }

  @Action(ClearNutritionPreferences)
  clearPreferences(
    ctx: StateContext<NutritionStateModel>,
    action: ClearNutritionPreferences,
  ) {
    return ctx.patchState({
      selectedAllergenTags: [],
    });
  }

  @Action(SetNutritionCategory)
  setNutritionCategory(
    ctx: StateContext<NutritionStateModel>,
    action: SetNutritionCategory,
  ) {
    return ctx.patchState({
      category: action.category,
      product: null,
      currentSelections: [],
    });
  }

  @Action(ClearNutritionCategory)
  clearNutritionCategory(
    ctx: StateContext<NutritionStateModel>,
    action: ClearNutritionCategory,
  ) {
    return ctx.patchState({
      category: null,
      product: null,
    });
  }

  @Action(SetNutritionProduct)
  setNutritionProduct(
    ctx: StateContext<NutritionStateModel>,
    action: SetNutritionProduct,
  ) {
    return this.menu.getProduct(this.globalMenuID, action.chainProductID).pipe(
      map((product) => {
        return ctx.patchState({
          product,
          currentSelections: [],
        });
      }),
    );
  }

  @Action(AddToSelectedItems)
  addToSelectedItems(
    ctx: StateContext<NutritionStateModel>,
    action: AddToSelectedItems,
  ) {
    return ctx.patchState({
      currentSelections: [...ctx.getState().currentSelections, action.item],
    });
  }

  @Action(RemoveFromSelectedItems)
  removeFromSelectedItems(
    ctx: StateContext<NutritionStateModel>,
    action: RemoveFromSelectedItems,
  ) {
    return ctx.patchState({
      currentSelections: ctx
        .getState()
        .currentSelections.filter(
          (item) => item.chainoptionid !== action.item.chainoptionid,
        ),
    });
  }
}
