import { Component, Input, OnInit } from '@angular/core';
import { PointsBalance, Reward } from '@models/index';

@Component({
  selector: 'app-my-rewards-container',
  templateUrl: './my-rewards-container.component.html',
  styleUrls: ['my-rewards-container.component.scss'],
})
export class MyRewardsContainerComponent implements OnInit {
  @Input() rewards!: Reward[];
  @Input() pointBalances!: PointsBalance;

  constructor() {}
  ngOnInit() {}
}
