import { Pipe, PipeTransform } from '@angular/core';
import { SavedProductChoice } from '@models/index';

export interface OptionWithAvailability {
  name: string;
  available: boolean;
  deleted: boolean;
}

@Pipe({
  name: 'savedProductChoices',
})
export class SavedProductChoicesPipe implements PipeTransform {
  transform(choices: SavedProductChoice[]): OptionWithAvailability[] {
    return choices
      .map((choice) => {
        return {
          name: choice.name,
          available: choice.availability.available,
          deleted: choice.availability.isdeleted,
        };
      })
      .reverse();
  }
}
