import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textColor',
})
export class TextColorPipe implements PipeTransform {
  transform(hexColor: string): string {
    // Remove hash if it exists
    if (hexColor.charAt(0) === '#') {
      hexColor = hexColor.slice(1);
    }

    // Convert hex to RGB
    const r = parseInt(hexColor.slice(0, 2), 16);
    const g = parseInt(hexColor.slice(2, 4), 16);
    const b = parseInt(hexColor.slice(4, 6), 16);

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return black or white based on luminance
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }
}
