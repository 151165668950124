import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { StaticMenuCollectionSubgroupItem } from '@models/index';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sm-ingredient-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sm-ingredient-card.component.html',
  styleUrls: ['sm-ingredient-card.component.scss'],
})
export class SmIngredientCardComponent implements OnInit {
  @Input() collectionItem!: StaticMenuCollectionSubgroupItem;
  @ViewChild('infoModal') infoModal!: TemplateRef<any>;

  constructor(private modalService: BsModalService) {}
  ngOnInit() {}

  cardClick() {
    while (this.modalService.getModalsCount() > 0) {
      this.modalService.hide();
    }
    this.modalService.show(this.infoModal, {
      animated: true,
    });
  }
}
