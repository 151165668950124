<div class="modal-header align-items-center">
  <img
    class="h4-birds"
    src="../../../../assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="" />
  <h4 class="modal-title grow-1">Confirm Your Info</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1">
  <form class="w-100" [formGroup]="addressForm">
    <div class="d-flex flex-column gap-3 grow-1">
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          id="streetAddress"
          formControlName="streetAddress"
          placeholder="Street Address" />
        <label for="streetAddress">Street Address</label>
      </div>
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          id="building"
          formControlName="building"
          placeholder="Apt/Suite #" />
        <label for="building">Apt/Suite #</label>
      </div>
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          id="city"
          formControlName="city"
          placeholder="City" />
        <label for="city">City</label>
      </div>
      <div class="form-floating">
        <input
          class="form-control"
          type="text"
          id="zipcode"
          formControlName="zipcode"
          placeholder="Zip Code" />
        <label for="zipcode">Zip Code</label>
      </div>
      <div class="form-floating">
        <textarea
          class="form-control"
          id="specialInstructions"
          formControlName="specialInstructions"
          rows="3"></textarea>
        <label for="specialInstructions">Special Instructions</label>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="d-flex flex-nowrap gap-3 m-0">
    <button type="button" class="btn btn-secondary" (click)="editAddress()">
      Change Details
    </button>
    <app-button
      classes="btn btn-success"
      (buttonClick)="confirmAddress()"
      [isLoading]="confirmLoading">
      Confirm
    </app-button>
  </div>
</div>
