import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '@models/index';

@Pipe({
  name: 'sideSaucesFilter',
})
export class SideSaucesFilterPipe implements PipeTransform {
  transform(products: Product[]): Product[] {
    return products.filter(
      (product) =>
        !product.metadata?.find(
          (metadata) =>
            metadata.key === 'subcategories' && metadata.value === 'sidesauces',
        ),
    );
  }
}
