import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { ScrollSpyService } from '@common/services';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[spyTarget]',
})
export class SpyTargetDirective implements OnInit, OnDestroy {
  @Input() spyTarget!: string;

  private renderer: Renderer2;

  constructor(
    private el: ElementRef,
    private spyService: ScrollSpyService,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit() {
    this.renderer.setAttribute(this.htmlElement, 'id', this.spyTarget);
    this.spyService.addTarget({ name: this.spyTarget, element: this.el });
  }

  private get htmlElement() {
    return this.el.nativeElement;
  }

  ngOnDestroy() {
    this.spyService.removeTarget(this.spyTarget);
  }
}
