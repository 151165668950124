import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Restaurant } from '@models/index';
import { BrochureService } from '@modules/brochure/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  submitLoading: boolean = false;
  isComplete: boolean = false;
  contactTypeForm = new FormGroup({
    type: new FormControl(),
  });

  locations: Restaurant[] = [];

  selectedForm?: FormGroup;

  feedbackForm = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    reason: new FormControl(null),
    phone: new FormControl(null, [Validators.required]),
    location: new FormControl(null, [Validators.required]),
    date: new FormControl(null, []),
    time: new FormControl(null, []),
    subject: new FormControl(null),
    message: new FormControl(null),
  });

  mediaForm = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null, [Validators.required]),
    company: new FormControl(null, [Validators.required]),
    message: new FormControl(null, []),
  });

  cateringForm = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null, [Validators.required]),
    date: new FormControl(null, []),
    guests: new FormControl(null, []),
    location: new FormControl(null, [Validators.required]),
    message: new FormControl(null, []),
  });

  communityForm = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null, [Validators.required]),
    company: new FormControl(null, [Validators.required]),
    message: new FormControl(null, []),
  });

  constructor(
    private brochure: BrochureService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.brochure
      .getLocations()
      .subscribe((locations) => (this.locations = locations.restaurants));
    this.contactTypeForm.valueChanges.subscribe(() => {
      switch (this.contactTypeForm.get('type')?.value) {
        case 'feedback':
          this.selectedForm = this.feedbackForm;
          break;
        case 'media':
          this.selectedForm = this.mediaForm;
          break;
        case 'catering':
          this.selectedForm = this.cateringForm;
          break;
        case 'community':
          this.selectedForm = this.communityForm;
          break;
      }
    });
  }

  submitForm(
    group: FormGroup,
    category: 'feedback' | 'media' | 'catering' | 'spirit-night' | 'donation',
  ) {
    this.submitLoading = true;
    if (group.invalid) {
      group.markAllAsTouched();
      this.toastr.error('Please fill out all info');
      this.submitLoading = false;
      return;
    }
    let message = {};
    Object.keys(group.controls).forEach((key) => {
      // @ts-ignore
      message[key] = group.controls[key].value;
    });
    this.brochure.sendContactForm(category, message).subscribe({
      next: () => this.toastr.success('Message Sent', 'Success'),
      error: () => {
        this.toastr.error(
          'Message could not be sent. Please try again later',
          'Error',
        );
        this.submitLoading = false;
      },
      complete: () => {
        this.submitLoading = false;
        this.mediaForm.reset();
        this.cateringForm.reset();
        this.communityForm.reset();
        this.feedbackForm.reset();
        this.isComplete = true;
        this.scrollToTop();
      },
    });
  }

  goBackFromCompleted() {
    this.isComplete = !this.isComplete;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
