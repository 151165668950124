import { Pipe, PipeTransform } from '@angular/core';
import { Ingredient, IngredientGroup } from '@models/index';

@Pipe({
  name: 'ingredientGroupCompletion',
  pure: false,
})
export class IngredientGroupCompletionPipe implements PipeTransform {
  transform(
    ingredientGroup: IngredientGroup,
    selectedOptions: Ingredient[],
  ): boolean {
    const selectedChildren = selectedOptions.filter((option) =>
      ingredientGroup.options.find(
        (gOption) => gOption.chainoptionid === option.chainoptionid,
      ),
    );
    return !!(
      (!ingredientGroup.mandatory && !ingredientGroup.minselects) ||
      (ingredientGroup.mandatory &&
        !ingredientGroup.minselects &&
        selectedChildren.length === 1) ||
      (ingredientGroup.minselects &&
        selectedChildren.length >= Number(ingredientGroup.minselects))
    );
  }
}
