<div class="card bg-white grow-1 h-100">
  <h2 class="card-header">Account Info</h2>
  <div class="card-body h-100">
    <form
      class="w-100 d-flex flex-column flex-nowrap gap-3"
      [formGroup]="accountInfoForm">
      <h4 class="h4">User Information</h4>
      <div class="d-flex flex-column flex-md-row flex-nowrap gap-3">
        <div class="form-floating w-100">
          <input
            class="form-control"
            type="text"
            id="firstName"
            placeholder="First Name"
            formControlName="firstName" />
          <label for="firstName">First Name</label>
        </div>
        <div class="form-floating w-100">
          <input
            class="form-control"
            type="text"
            id="lastName"
            placeholder="Last Name"
            formControlName="lastName" />
          <label for="lastName">Last Name</label>
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row flex-nowrap gap-3">
        <div class="form-floating w-100">
          <input
            class="form-control"
            type="email"
            id="email"
            placeholder="Email Address"
            formControlName="emailAddress" />
          <label for="email">Email Address</label>
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row flex-nowrap gap-3">
        <div class="form-floating w-100">
          <input
            class="form-control"
            type="tel"
            id="phone"
            autocomplete="tel-national"
            mask="(000) 000-0000"
            placeholder="Phone Number"
            formControlName="phoneNumber" />
          <label for="phone">Phone Number</label>
        </div>
        <div class="form-floating w-100">
          <input
            class="form-control"
            type="text"
            id="dob"
            autocomplete="bday"
            bsDatepicker
            container="body"
            [bsConfig]="{
              dateInputFormat: 'MM/DD/YYYY',
              isAnimated: true,
              containerClass: 'theme-default',
              showWeekNumbers: false,
              adaptivePosition: true
            }"
            [maxDate]="maxDate"
            placeholder="Date of Birth"
            formControlName="dateOfBirth" />
          <label for="dob">Date of Birth</label>
        </div>
      </div>
      <h4 class="h4">Notification Preferences</h4>
      <div class="d-flex flex-column flex-nowrap gap-3">
        <div class="custom-control custom-switch">
          <input
            type="checkbox"
            class="custom-control-input"
            id="emailOpt"
            formControlName="emailOpt" />
          <label class="custom-control-label" for="emailOpt">Email</label>
        </div>
      </div>
    </form>
  </div>
  <div
    class="card-footer bg-light d-flex flex-row flex-nowrap justify-content-between align-items-center gap-3">
    <!--    <button class="btn btn-outline-secondary">Change Password</button>-->
    <!--    <li class="divider dropdown-divider"></li>-->
    <button class="btn btn-outline-secondary" (click)="logout()">
      Sign Out
    </button>
    <app-button
      [classes]="isEditing ? 'btn btn-success' : 'btn btn-primary'"
      [isLoading]="updateLoading"
      (buttonClick)="isEditing ? saveChanges() : makeChanges()">
      <ng-container *ngIf="isEditing; else notEditing"
        >Save Changes</ng-container
      >
      <ng-template #notEditing>Make Changes</ng-template>
    </app-button>
  </div>
</div>
