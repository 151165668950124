<div class="card bg-white grow-1 h-100">
  <h2 class="card-header">Order History</h2>
  <ng-container *ngIf="pastOrders$ | async as pastOrders">
    <ng-container *ngIf="pastOrders.length; else noOrders">
      <div class="card-body d-flex flex-column grow-1 align-items-center gap-3">
        <ng-container *ngFor="let order of pastOrders">
          <app-reorder-card [order]="order"></app-reorder-card>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #noOrders>
      <div class="card-body d-flex flex-column grow-1 align-items-center gap-3">
        <div class="d-flex w-100 justify-content-center">
          <img
            class="empty-icon"
            src="assets/images/icons/Icons/fb-garlic-sad.svg"
            alt="" />
        </div>

        <div class="lead">You currently do not have any previous orders.</div>

        <button type="button" class="btn btn-primary" routerLink="/start-order">
          Start Order
        </button>
      </div>
    </ng-template>
  </ng-container>
</div>
