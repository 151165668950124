<app-navbar navbarOrientation="minimal"></app-navbar>
<ng-container *ngIf="order$ | async as order">
  <ng-container *ngIf="orderLocation$ | async as location">
    <section>
      <div
        class="address-container py-4 bg-light"
        *ngIf="order.deliverymode !== handoffs.DISPATCH; else delivery">
        <div
          class="d-flex flex-column flex-nowrap justify-content-center text-center text-nowrap gap-1 position-relative">
          <h3 class="text-uppercase">
            {{ order.deliverymode | handoffModeName }}
          </h3>
          <div>{{ location.name }}</div>
          <div>{{ location.streetaddress }}</div>
          <small
            >{{ location.city }}, {{ location.state }} {{ location.zip }}</small
          >
          <div
            class="d-flex flex-md-row flex-column gap-2 justify-content-center">
            <button
              routerLink="/start-order"
              type="button"
              class="change-location-btn btn btn-link p-0">
              Change Location
            </button>
            <button
              routerLink="/start-order"
              fragment="delivery"
              type="button"
              class="change-location-btn btn btn-link p-0">
              Switch to Delivery
            </button>
          </div>
        </div>
      </div>

      <ng-template #delivery>
        <div class="address-container py-4 bg-light">
          <div
            class="d-flex flex-column flex-nowrap justify-content-center text-center text-nowrap gap-1 position-relative">
            <h3 class="text-uppercase">Delivering To</h3>
            <div>{{ order.deliveryaddress.streetaddress }}</div>
            <small *ngIf="order.deliveryaddress.building">{{
              order.deliveryaddress.building
            }}</small>
            <small
              >{{ order.deliveryaddress.city }}
              {{ order.deliveryaddress.zipcode }}</small
            >
            <div
              class="d-flex flex-row flex-wrap gap-2 align-items-center justify-content-center">
              <button
                (click)="editDeliveryAddress(order)"
                type="button"
                class="change-location-btn btn btn-link p-0">
                Edit Delivery Info
              </button>
              <div class="text-muted d-none d-sm-block">|</div>
              <button
                routerLink="/start-order"
                fragment="pickup"
                type="button"
                class="change-location-btn btn btn-link p-0">
                Switch to Pickup
              </button>
            </div>
          </div>
        </div>
      </ng-template>
      <div class="container d-flex justify-content-center py-4">
        <div class="checkout-container pb-4">
          <!-- <h1 class="display-5 text-center">Checkout</h1> -->

          <!--    Order Time-->
          <div class="checkout-content-container">
            <h3 class="h3 text-center">Order Time</h3>
            <tabset type="pills" [justified]="true" class="w-100">
              <tab
                heading="ASAP"
                (selectTab)="setTimeToASAP()"
                [disabled]="!location.iscurrentlyopen">
                <ng-container
                  *ngIf="location.iscurrentlyopen; else locationClosed">
                  <div class="mt-3 alert alert-success rounded text-center">
                    Ready:
                    <b
                      >{{
                        order.earliestreadytime | oloDate | date : 'MMMM d, y'
                      }}
                      at
                      {{
                        order.earliestreadytime | oloDate | date : 'h:mm a'
                      }}</b
                    >
                  </div>
                </ng-container>
                <ng-template #locationClosed>
                  <div class="mt-3 alert alert-danger rounded text-center">
                    The store is currently closed. Please schedule your order
                    for a future time.
                  </div>
                </ng-template>
              </tab>
              <tab heading="Scheduled">
                <!--TODO: Add collapse animation on enter-->
                <form class="w-100 order-time-form pt-3">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      id="orderDate"
                      (change)="onDateSelection($event)">
                      <option disabled selected>Select Date</option>
                      <ng-container *ngFor="let day of availableDays">
                        <option [value]="day">
                          {{ day | date : 'MM/dd' }}
                        </option>
                      </ng-container>
                    </select>
                    <label for="orderDate">Order Date</label>
                  </div>
                  <div class="form-floating">
                    <ng-container
                      *ngIf="
                        availableTimes$ | async as availableTimes;
                        else loadingTimes
                      ">
                      <select
                        class="form-select"
                        id="orderTime"
                        (change)="onTimeSelection($event)">
                        <option disabled selected>Select Time</option>
                        <ng-container *ngFor="let time of availableTimes">
                          <option [value]="time.time | oloDate">
                            {{ time.time | oloDate | date : 'hh:mm a' }}
                          </option>
                        </ng-container>
                      </select>
                    </ng-container>
                    <ng-template #loadingTimes>
                      <select class="form-select" id="orderTime" disabled>
                        <option disabled selected>
                          Getting Available Times
                        </option>
                      </select>
                    </ng-template>
                    <label for="orderTime">Order Time</label>
                  </div>
                </form>
              </tab>
            </tabset>
          </div>
          <!--    Order Type-->
          <ng-container *ngIf="orderLocation$ | async as orderLocation">
            <ng-container
              *ngIf="
                (order.deliverymode === handoffs.PICKUP &&
                  orderLocation.supportscurbside) ||
                (order.deliverymode === handoffs.CURBSIDE &&
                  orderLocation.canpickup)
              ">
              <div class="checkout-content-container">
                <h3 class="h3 text-center">Order Type</h3>
                <tabset type="pills" [justified]="true" class="w-100">
                  <tab
                    [disabled]="lockHandoffSwitch"
                    heading="Pickup"
                    [active]="order.deliverymode === handoffs.PICKUP"
                    (selectTab)="changeHandoff(handoffs.PICKUP)">
                  </tab>
                  <tab
                    [disabled]="lockHandoffSwitch"
                    heading="Curbside"
                    [active]="order.deliverymode === handoffs.CURBSIDE"
                    (selectTab)="changeHandoff(handoffs.CURBSIDE)">
                    <form
                      class="w-100 d-flex flex-column gap-3 pt-3"
                      [formGroup]="curbsideForm">
                      <div class="form-floating">
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          placeholder="Vehicle Make"
                          formControlName="make"
                          id="vehicleMake" />
                        <label
                          class="col-form-label col-form-label-sm"
                          for="vehicleMake"
                          >Vehicle Make</label
                        >
                        <ng-container *ngIf="make.invalid && make.touched">
                          <small class="text-danger"
                            >Vehicle Make Required</small
                          >
                        </ng-container>
                      </div>

                      <div class="form-floating">
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          placeholder="Vehicle Model"
                          formControlName="model"
                          id="vehicleModel" />
                        <label
                          class="col-form-label col-form-label-sm"
                          for="vehicleModel"
                          >Vehicle Model</label
                        >
                        <ng-container *ngIf="model.invalid && model.touched">
                          <small class="text-danger"
                            >Vehicle Model Required</small
                          >
                        </ng-container>
                      </div>

                      <div class="form-floating">
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          placeholder="Vehicle Color"
                          formControlName="color"
                          mask="S*"
                          id="vehicleColor" />
                        <label
                          class="col-form-label col-form-label-sm"
                          for="vehicleColor"
                          >Vehicle Color</label
                        >
                        <ng-container *ngIf="color.invalid && color.touched">
                          <small class="text-danger"
                            >Vehicle Color Required</small
                          >
                        </ng-container>
                      </div>
                    </form>
                  </tab>
                </tabset>
              </div>
            </ng-container>
          </ng-container>
          <!--    user info -->
          <div
            *ngIf="(user$ | async) === null"
            class="checkout-content-container">
            <h3 class="h3 text-center">Your Information</h3>
            <form class="w-100 user-info-form" [formGroup]="userInfoForm">
              <div class="form-floating">
                <input
                  #firstNameInput
                  (keydown.enter)="gotoNextField(lastNameInput)"
                  class="form-control form-control-sm"
                  type="text"
                  placeholder="First Name"
                  formControlName="firstName"
                  id="firstName" />
                <label class="col-form-label col-form-label-sm" for="firstName"
                  >First Name</label
                >
                <ng-container *ngIf="firstName.invalid && firstName.touched">
                  <small class="text-danger">First Name Required</small>
                </ng-container>
              </div>
              <div class="form-floating">
                <input
                  #lastNameInput
                  (keydown.enter)="gotoNextField(phoneNumberInput)"
                  class="form-control form-control-sm"
                  type="text"
                  formControlName="lastName"
                  placeholder="Last Name"
                  id="lastName" />
                <label class="col-form-label col-form-label-sm" for="lastName"
                  >Last Name</label
                >
                <ng-container *ngIf="lastName.invalid && lastName.touched">
                  <small class="text-danger">Last Name Required</small>
                </ng-container>
              </div>
              <div class="form-floating">
                <input
                  #phoneNumberInput
                  (keydown.enter)="gotoNextField(emailInput)"
                  class="form-control form-control-sm"
                  type="phone"
                  placeholder="Phone Number"
                  mask="(000)000-0000"
                  formControlName="phone"
                  [dropSpecialCharacters]="true"
                  id="phone" />
                <label class="col-form-label col-form-label-sm" for="phone"
                  >Phone Number</label
                >
                <ng-container *ngIf="phone.invalid && phone.touched">
                  <small class="text-danger">Phone Number Required</small>
                </ng-container>
              </div>
              <div class="form-floating">
                <input
                  #emailInput
                  class="form-control form-control-sm"
                  type="email"
                  formControlName="email"
                  placeholder="Email Address"
                  id="email" />
                <label class="col-form-label col-form-label-sm" for="email"
                  >Email Address</label
                >
                <ng-container *ngIf="email.invalid && email.touched">
                  <small class="text-danger" *ngIf="email.errors!['required']"
                    >Email Required</small
                  >
                  <small class="text-danger" *ngIf="email.errors!['email']"
                    >Invalid Email</small
                  >
                </ng-container>
              </div>
              <ng-container *ngIf="(user$ | async) === null">
                <div class="form-check" style="grid-column: 1/3">
                  <label class="form-check-label" for="createNewAccount">
                    <input
                      type="checkbox"
                      id="createNewAccount"
                      class="form-check-input"
                      [formControl]="createCheck" />
                    Use this information to create a new account?
                  </label>
                </div>
                <ng-container *ngTemplateOutlet="newUserSignUp"></ng-container>
              </ng-container>
            </form>
          </div>
          <ng-container *ngIf="(groupOrder$ | async) === null">
            <ng-container *ngIf="availableRewards$ | async as availableRewards">
              <ng-container *ngIf="availableRewards.length">
                <div class="checkout-content-container">
                  <div class="p-3 d-flex flex-column gap-2 bg-light rounded">
                    <div class="d-flex flex-nowrap gap-2">
                      <h5 class="grow-1">Your Rewards</h5>
                    </div>
                    <div class="d-flex flex-column gap-2">
                      <ng-container
                        *ngFor="
                          let reward of availableRewards;
                          trackBy: rewardTrackBy
                        ">
                        <app-order-reward-card
                          [availableReward]="reward"
                          [isApplied]="
                            reward
                              | isRewardApplied : order.appliedrewards ?? []
                          "></app-order-reward-card>
                      </ng-container>
                    </div>
                    <!--                  <div class="d-flex justify-content-center">-->
                    <!--                    <button-->
                    <!--                      type="button"-->
                    <!--                      class="view-all-btn btn btn-sm btn-link text-muted p-0 text-center">-->
                    <!--                      Show All-->
                    <!--                    </button>-->
                    <!--                  </div>-->
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <!--  Tip -->
          <ng-container *ngIf="order.allowstip">
            <ng-container *ngIf="tipSettings$ | async as tipSettings">
              <div class="checkout-content-container">
                <h3 class="h3 text-center">
                  {{
                    order.deliverymode === HandoffMode.DISPATCH
                      ? (tipSettings.delivery_section_title ? tipSettings.delivery_section_title : 'Add a Tip')
                      : (tipSettings.pickup_section_title ? tipSettings.pickup_section_title : 'Add a Tip')
                  }}
                </h3>
                <div>
                  <app-tip-selection
                    [order]="(order$ | async)!"
                    [tipSettings]="(tipSettings$ | async)!"></app-tip-selection>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div class="checkout-content-container">
            <h3 class="h3 text-center">Order Summary</h3>
            <div class="w-100 d-flex flex-column flex-nowrap gap-3">
              <!--              <ng-container *ngIf="order.allowstip">-->
              <!--                <div>-->
              <!--                  <app-tip-selection-->
              <!--                    [order]="(order$ | async)!"></app-tip-selection>-->
              <!--                </div>-->
              <!--              </ng-container>-->

              <div class="flex-grow-1 w-100">
                <app-coupon-code [order]="(order$ | async)!"></app-coupon-code>
              </div>
            </div>
            <div class="bg-light p-3 d-flex gap-3 flex-column rounded">
              <div class="d-flex flex-column gap-2">
                <ng-container *ngIf="groupOrder$ | async; else orderSummary">
                  <ng-container
                    *ngFor="let recipient of order.products | orderRecipient">
                    <div class="d-flex flex-nowrap gap-3">
                      <div class="line-clamp-1 grow-1">
                        {{ recipient.name }}
                      </div>
                      <div class="text-right">
                        {{ recipient.total | currency }}
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #orderSummary>
                  <ng-container *ngFor="let product of order.products">
                    <div class="d-flex flex-nowrap gap-3 align-items-baseline">
                      <div class="d-flex w-100 flex-column gap-1 grow-1">
                        <div class="line-clamp-1">
                          {{ product.name }}
                          <small class="text-right" *ngIf="product.recipient">
                            ({{ product.recipient }})
                          </small>
                        </div>
                        <small
                          class="text-muted"
                          *ngIf="product.choices?.length">
                          {{ product.choices | choicesList }}
                        </small>
                      </div>
                      <small class="text-right" *ngIf="product.totalcost">
                        {{ product.totalcost | currency }}
                      </small>
                    </div>
                  </ng-container>
                  <br />
                </ng-template>
                <div class="d-flex flex-nowrap gap-3">
                  <div class="line-clamp-1 grow-1">Subtotal</div>
                  <div class="text-right">{{ order.subtotal | currency }}</div>
                </div>
                <div class="d-flex flex-nowrap gap-3">
                  <div class="line-clamp-1 grow-1">Tax</div>
                  <div class="text-right">{{ order.salestax | currency }}</div>
                </div>
                <div class="d-flex flex-nowrap gap-3">
                  <div class="line-clamp-1 grow-1">Tip</div>
                  <div class="text-right">{{ order.tip | currency }}</div>
                </div>
                <div
                  class="d-flex flex-nowrap gap-3"
                  *ngIf="order.customerhandoffcharge">
                  <div class="line-clamp-1 grow-1">Delivery Fee</div>
                  <div class="text-right">
                    {{ order.customerhandoffcharge | currency }}
                  </div>
                </div>
                <ng-container *ngFor="let fee of order.fees">
                  <div class="d-flex flex-nowrap gap-3">
                    <div class="line-clamp-1 grow-1">{{ fee.description }}</div>
                    <div class="text-right">
                      {{ fee.amount | currency }}
                    </div>
                  </div>
                </ng-container>
                <!--                <ng-container-->
                <!--                  *ngIf="order.coupondiscount && order.coupondiscount > 0">-->
                <!--                  <div class="d-flex flex-nowrap gap-3">-->
                <!--                    <div class="line-clamp-1 grow-1">Coupon Discount</div>-->
                <!--                    <div class="text-right text-success">-->
                <!--                      -{{ order.coupondiscount }}-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </ng-container>-->
                <ng-container *ngFor="let reward of order.discounts">
                  <ng-container *ngIf="reward.amount > 0">
                    <div class="d-flex flex-nowrap gap-3">
                      <div class="line-clamp-1 grow-1">
                        Reward: {{ reward.description }}
                      </div>
                      <div class="text-right text-success">
                        -{{ reward.amount | currency }}
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <hr class="m-0" />
              <div class="d-flex flex-nowrap gap-3">
                <div class="line-clamp-1 grow-1 font-weight-bold">Total</div>
                <div class="text-right font-weight-bold">
                  {{ order.total | currency }}
                </div>
              </div>
            </div>
          </div>

          <!--Payment Types-->
          <div
            class="checkout-content-container"
            [@collapse]="order.total === 0">
            <h3 class="h3 text-center">Payment Info</h3>
            <ng-container *ngIf="savedCards$ | async as savedCards">
              <ng-container *ngIf="savedCards.length">
                <div class="btn-group" dropdown>
                  <button
                    dropdownToggle
                    type="button"
                    class="btn btn-outline-secondary dropdown-toggle"
                    aria-controls="dropdown-basic">
                    Saved Cards <span class="caret"></span>
                  </button>
                  <ul
                    id="dropdown-basic"
                    *dropdownMenu
                    class="dropdown-menu w-100 bg-light"
                    role="menu"
                    aria-labelledby="button-basic">
                    <ng-container *ngFor="let savedCard of savedCards">
                      <li
                        role="menuitem"
                        (click)="selectedSavedCard = savedCard">
                        <a
                          class="dropdown-item p-2 rounded bg-light cursor-pointer">
                          <div class="line-clamp-1">
                            {{ savedCard.cardtype }} ending in
                            {{ savedCard.cardsuffix }}
                          </div>
                          <div class="font-weight-normal line-clamp-1">
                            Exp. {{ savedCard.expiration }}
                          </div>
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="selectedSavedCard">
              <div
                class="w-100 d-flex flex-column justify-content-center align-items-center">
                <div>
                  Paying with: {{ selectedSavedCard.cardtype }} ending in
                  {{ selectedSavedCard.cardsuffix }}
                </div>
                <small
                  class="text-uppercase text-primary cursor-pointer"
                  (click)="selectedSavedCard = undefined"
                  >Add New Card</small
                >
              </div>
            </ng-container>

            <form
              class="w-100 user-info-form"
              [formGroup]="cardPaymentForm"
              [@collapse]="!!selectedSavedCard">
              <div class="form-floating">
                <input
                  #cardNumberInput
                  (keydown.enter)="gotoNextField(cardExpInput)"
                  class="form-control form-control-sm"
                  id="cardNumber"
                  formControlName="ccNumber"
                  inputmode="numeric"
                  [mask]="currentCCMask"
                  autocomplete="cc-number"
                  placeholder="Card Number" />
                <label class="col-form-label col-form-label-sm" for="cardNumber"
                  >Card Number</label
                >
                <ng-container *ngIf="ccNumber.invalid && ccNumber.touched">
                  <small class="text-danger">Card Number Required</small>
                </ng-container>
              </div>
              <div class="form-floating">
                <input
                  #cardExpInput
                  (keydown.enter)="gotoNextField(cardCVVInput)"
                  class="form-control form-control-sm"
                  id="cardExp"
                  maxlength="5"
                  formControlName="ccExp"
                  mask="00/00"
                  inputmode="numeric"
                  autocomplete="cc-exp"
                  placeholder="MM/YY" />
                <label class="col-form-label col-form-label-sm" for="cardExp"
                  >MM/YY</label
                >
                <ng-container *ngIf="ccExp.invalid && ccExp.touched">
                  <small class="text-danger">Card Expiration Required</small>
                </ng-container>
              </div>
              <div class="form-floating">
                <input
                  #cardCVVInput
                  (keydown.enter)="gotoNextField(cardZipInput)"
                  class="form-control form-control-sm"
                  id="cardCVV"
                  formControlName="ccCVV"
                  mask="0009"
                  inputmode="numeric"
                  autocomplete="cc-csc"
                  placeholder="CVV" />
                <label class="col-form-label col-form-label-sm" for="cardCVV"
                  >CVV</label
                >
                <ng-container *ngIf="ccCVV.invalid && ccCVV.touched">
                  <small class="text-danger">Card CVV Required</small>
                </ng-container>
              </div>
              <div class="form-floating">
                <input
                  #cardZipInput
                  class="form-control form-control-sm"
                  id="cardZip"
                  formControlName="ccZip"
                  mask="00000"
                  minlength="5"
                  maxlength="5"
                  inputmode="numeric"
                  placeholder="Zip Code" />
                <label class="col-form-label col-form-label-sm" for="cardZip"
                  >Zip Code</label
                >
                <ng-container *ngIf="ccZip.invalid && ccZip.touched">
                  <small class="text-danger" *ngIf="ccZip.errors!['required']"
                    >Billing Zip Code Required</small
                  >
                  <small
                    class="text-danger"
                    *ngIf="
                      ccZip.errors!['minLength'] || ccZip.errors!['maxLength']
                    "
                    >Invalid Billing Zip Code</small
                  >
                </ng-container>
              </div>
              <ng-container *ngIf="user$ | async">
                <div class="form-check">
                  <label
                    class="form-check-label d-flex align-items-center cursor-pointer">
                    <input
                      class="form-check-input cursor-pointer"
                      type="checkbox"
                      formControlName="saveOnFile" />
                    <div>Save this Card for Future Use</div>
                  </label>
                </div>
              </ng-container>
            </form>
          </div>
          <ng-container
            *ngIf="!location.iscurrentlyopen && order.timemode === 'asap'">
            <div class="mt-3 alert alert-danger rounded text-center">
              The store is currently closed. Please schedule your order for a
              future time.
            </div>
          </ng-container>
          <app-button
            *ngIf="order.subtotal < 300; else totalExceeded"
            classes="w-100 btn btn-lg btn-success"
            (buttonClick)="submitOrder()"
            [isLoading]="submitLoading">
            Place Order
          </app-button>
          <ng-template #totalExceeded>
            <div class="alert alert-danger" role="alert">
              Unfortunately, your current order exceeds the maximum amount of
              $300 and cannot be fulfilled right now. For large orders, we ask
              that you place it with our Catering Team by phone at
              <a href="tel:+18888112473">888-811-BIRD</a> (2473) or email
              <a href="mailto:cater@freebirds.com">cater@freebirds.com</a> to
              assist you better.
            </div>
          </ng-template>
        </div>
      </div>
    </section>
  </ng-container>
</ng-container>

<ng-template #newUserSignUp>
  <form
    [formGroup]="createAccountForm"
    class="w-100 user-info-form"
    style="grid-column: 1/3"
    [@collapse]="!createCheck.value">
    <div class="form-floating" style="grid-column: 1/3">
      <input
        #birthdayInput
        (keydown.enter)="gotoNextField(passwordInput)"
        class="form-control form-control-sm"
        type="text"
        formControlName="birthday"
        bsDatepicker
        container="body"
        [bsConfig]="{
          dateInputFormat: 'MM/DD/YYYY',
          isAnimated: true,
          containerClass: 'theme-default',
          showWeekNumbers: false,
          adaptivePosition: true
        }"
        [maxDate]="maxDate"
        id="birthday"
        placeholder="Birthday"
        autocomplete="bday" />
      <label
        class="col-form-label col-form-label-sm"
        for="birthday"
        id="birthday-label"
        >Birthday*</label
      >
      <ng-container
        *ngIf="createAccountBirthday.invalid && createAccountBirthday.touched">
        <small
          class="text-danger"
          *ngIf="createAccountBirthday.errors!['required']">
          Birthday Required
        </small>
      </ng-container>
    </div>

    <div class="form-floating">
      <input
        #passwordInput
        (keydown.enter)="gotoNextField(confirmPasswordInput)"
        class="form-control form-control-sm"
        type="password"
        formControlName="password"
        placeholder="Password"
        id="password" />
      <label class="col-form-label col-form-label-sm" for="password"
        >Password</label
      >
      <ng-container
        *ngIf="createAccountPassword.invalid && createAccountPassword.touched">
        <small class="text-danger">Password Required</small>
      </ng-container>
    </div>
    <div class="form-floating">
      <input
        #confirmPasswordInput
        class="form-control form-control-sm"
        type="password"
        formControlName="passwordConfirm"
        placeholder="Confirm Password"
        id="confirmPassword" />
      <label class="col-form-label col-form-label-sm" for="confirmPassword"
        >Confirm Password</label
      >
      <ng-container
        *ngIf="
          createAccountConfirmPassword.invalid &&
          createAccountConfirmPassword.touched
        ">
        <small class="text-danger">Confirm Password Required</small>
      </ng-container>
    </div>
    <div class="form-check" style="grid-column: 1/3">
      <label class="form-check-label" for="emailOpt">
        <input
          type="checkbox"
          id="emailOpt"
          class="form-check-input"
          formControlName="emailOptIn" />
        <small>Sign me up for email offers and news</small>
      </label>
    </div>
    <div class="form-check" style="grid-column: 1/3">
      <label class="form-check-label d-block" for="terms">
        <input
          type="checkbox"
          id="terms"
          class="form-check-input"
          formControlName="terms" />
        <small>
          I agree to the &nbsp;
          <a href="/legal" target="_blank" class="btn-link"
            >Terms & Conditions</a
          >
          &nbsp; and &nbsp;
          <a href="/legal" target="_blank" class="btn-link">Privacy Policy</a>.
        </small>
      </label>
    </div>
  </form>
</ng-template>
