<div class="bg-dark" [@collapse]="!product">
  <div class="categories-scroll container" id="ingredient-group-scroll">
    <div class="categories-list">
      <ng-container *ngIf="product; else loadingTemplate">
        <a
          [id]="product.name | slug"
          class="category-title"
          (click)="scroll(product.name)"
          >{{ product.name }}</a
        >
        <ng-container *ngFor="let ingredientGroup of ingredientList">
          <a
            [id]="'scroll_' + (ingredientGroup | slug)"
            class="category-title cursor-pointer"
            [spyOn]="ingredientGroup | slug"
            (click)="scroll(ingredientGroup)"
            activeClass="active-scroll"
            >{{ ingredientGroup }}
          </a>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <ng-template #loadingTemplate>
    <div class="btn-loader"><div></div></div>
    <a class="category-title">Loading</a>
  </ng-template>
</div>
