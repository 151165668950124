import { Component, HostListener, OnInit } from '@angular/core';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  slideInRightOnEnterAnimation,
  slideOutRightOnLeaveAnimation,
} from 'angular-animations';

@Component({
  selector: 'app-our-story',
  templateUrl: './our-story.component.html',
  styleUrls: ['our-story.component.scss'],
  animations: [
    slideInRightOnEnterAnimation({ duration: 300 }),
    slideOutRightOnLeaveAnimation({ duration: 300 }),
    fadeOutOnLeaveAnimation({ duration: 300 }),
    fadeInOnEnterAnimation({ duration: 300 }),
  ],
})
export class OurStoryComponent implements OnInit {
  constructor() {}
  ngOnInit() {}

  isAtTop: boolean = true;

  @HostListener('window:scroll')
  onWindowScroll() {
    this.isAtTop = window.pageYOffset === 0;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
