import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(input: string, length: number = 50) {
    if (input.length > length) {
      return input.substring(0, length - 3).concat('...');
    } else {
      return input;
    }

    if (input) {
      let txt = input;
      if (input && input.length > length) {
        txt = input.substring(0, length) + '…';
      }
      return txt;
    }
    return input;
  }
}
