<div class="container py-5 d-flex flex-column gap-5">
  <h1 class="text-center">Enjoy The Perks</h1>
  <div class="perks-grid">
    <app-individual-perk-card
      [perkTitle]="'Free Chips &amp; Dip'"
      [perkDescription]="
        'Join now to receive a free chips &amp; dip welcome offer'
      "
      [perkImage]="
        '../../../../assets/images/ingredient_illustrations/Icons/fb-nachos.svg'
      "></app-individual-perk-card>
    <app-individual-perk-card
      [perkTitle]="'$1 = 1 Point'"
      [perkDescription]="'Earn on every order and spend how you like'"
      [perkImage]="
        '../../../../assets/images/icons/Icons/fb-perks.svg'
      "></app-individual-perk-card>
    <app-individual-perk-card
      [perkTitle]="'Free Birthday Burrito'"
      [perkDescription]="
        'Celebrate with freebies on your birthday &amp; half birthday'
      "
      [perkImage]="
        '../../../../assets/images/icons/Icons/fb-present-primary.svg'
      "></app-individual-perk-card>
    <app-individual-perk-card
      [perkTitle]="'Kids Eat Free'"
      [perkDescription]="'Every Sunday with a purchase of an adult entree'"
      [perkImage]="
        '../../../../assets/images/ingredient_illustrations/Icons/fb-dancing.svg'
      "></app-individual-perk-card>
    <app-individual-perk-card
      [perkTitle]="'Exclusive Offers'"
      [perkDescription]="'Easy access to surprise offers and deals'"
      [perkImage]="
        '../../../../assets/images/icons/Icons/fb-mail.svg'
      "></app-individual-perk-card>
    <app-individual-perk-card
      [perkTitle]="'Skip The Line'"
      [perkDescription]="'Enjoy a faster checkout by ordering ahead in the app'"
      [perkImage]="
        '../../../../assets/images/icons/Icons/fb-walking-burrito.svg'
      "></app-individual-perk-card>
    <app-individual-perk-card
      [perkTitle]="'Refer A Friend'"
      [perkDescription]="'Earn 10 points after their first purchase'"
      [perkImage]="
        '../../../../assets/images/icons/Icons/fb-hand-primary.svg'
      "></app-individual-perk-card>
    <app-individual-perk-card
      [perkTitle]="'Bonus Challenges'"
      [perkDescription]="'Complete challenges to get free food even faster'"
      [perkImage]="
        '../../../../assets/images/ingredient_illustrations/Icons/fb-avocado-running.svg'
      "></app-individual-perk-card>
  </div>
</div>
