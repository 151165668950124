<ng-container *ngIf="order; else nextOrder">
  <div class="card bg-light text-dark h-100">
    <div class="card-header">
      <div class="card-title d-flex flex-nowrap">
        <div class="grow-1">{{ order.vendorname }}</div>
        <div class="text-right">
          {{ order.timeplaced | oloDate | date : 'MM/dd/YYYY' }}
        </div>
      </div>
    </div>
    <div class="card-body d-flex flex-column gap-3">
      <div class="d-flex flex-column gap-2 grow-1">
        <ng-container *ngFor="let product of order.products; index as index">
          <ng-container *ngIf="index < 1">
            <h6>{{ product.name }}</h6>
            <small class="card-text"
              >{{ product.choices | choicesList }}
            </small>
          </ng-container>
        </ng-container>
      </div>
      <div class="d-flex flex-nowrap gap-3 justify-content-between">
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="btn btn-sm btn-link p-0"
            (click)="openReorderModal()">
            View Order
          </button>
        </div>
        <app-button
          classes="btn btn-secondary"
          [isLoading]="reorderLoading"
          (buttonClick)="reorder()">
          Reorder
        </app-button>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #nextOrder>
  <div class="card h-100 grow-1">
    <div
      class="card-body text-center d-flex flex-column align-items-center justify-content-center gap-3">
      <h5 class="card-title text-muted">Your Next Order Goes Here</h5>
      <img
        class="next-order-icon"
        src="assets/images/ingredient_illustrations/Icons/fb-armadillo.svg"
        alt="" />
      <p class="card-text text-muted m-0 d-flex flex-column gap-2">
        We save your recent orders so that you can order your favorites again.
        <button
          type="button"
          class="btn btn-sm btn-link p-0"
          routerLink="/start-order">
          Start Order
        </button>
      </p>
    </div>
  </div>
</ng-template>
