import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class RewardsService {
  constructor() {}

  getRewards$(): Observable<{}> {
    return of({});
  }
}
