/* eslint-disable simple-import-sort/imports */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

/* Modules */
import { AppCommonModule } from '@common/app-common.module';
// import { NavigationModule } from '@modules/navigation/navigation.module';
import { PipesModule } from '../../pipes/pipes.module';

/* Components */
import * as brochureComponents from './components';

/* Containers */
import * as brochureContainers from './containers';

/* Guards */
import * as brochureGuards from './guards';

/* Services */
import * as brochureServices from './services';

/* Directives */
import * as brochureDirectives from './directives';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxMasonryModule } from 'ngx-masonry';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AppCommonModule,
    AccordionModule,
    PipesModule,
    BsDatepickerModule,
    TimepickerModule,
    NgxMasonryModule,
    TabsModule,
    NgxMaskModule,
    // NavigationModule,
  ],
  providers: [...brochureServices.services, ...brochureGuards.guards],
  declarations: [
    ...brochureComponents.components,
    ...brochureContainers.containers,
    ...brochureDirectives.directives,
  ],
  exports: [...brochureContainers.containers, ...brochureComponents.components],
})
export class BrochureModule {}
