import {
  FacebookLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { AsyncPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalStateModel } from '@models/index';
import { Store } from '@ngxs/store';
import { UserService } from '@services/api/user.service';
import {
  CreateAccount,
  Login,
  LoginWithCode,
  LoginWithFacebook,
} from '@store/actions/user.actions';
import { from, Observable, switchMap } from 'rxjs';

@Injectable()
export class AuthService {
  constructor(
    private user: UserService,
    private store: Store,
    private socialAuth: SocialAuthService,
    private route: ActivatedRoute,
    private async: AsyncPipe,
  ) {}

  createAccount(
    firstName: string,
    lastName: string,
    email: string,
    emailOptIn: boolean,
    phoneNumber: string,
    smsOptIn: boolean,
    birthday: string,
    password: string,
    passwordConfirmation: string,
    termsAndConditions: boolean,
  ): Observable<void> {
    return this.store.dispatch(
      new CreateAccount(
        firstName,
        lastName,
        email,
        phoneNumber,
        emailOptIn,
        smsOptIn,
        birthday,
        password,
        passwordConfirmation,
        termsAndConditions,
      ),
    );
  }

  login(email: string, password: string): Observable<void> {
    return this.store.dispatch(new Login(email, password));
  }

  loginWithFacebook(): Observable<void> {
    return from(this.socialAuth.signIn(FacebookLoginProvider.PROVIDER_ID)).pipe(
      switchMap((user) => {
        return this.store.dispatch(new LoginWithFacebook(user.authToken));
      }),
    );
  }

  loginWithCode(code: string): Observable<void> {
    return this.store.dispatch(new LoginWithCode(code));
  }

  forgotPassword(email: string) {
    return this.user.forgotPassword(email);
  }

  shouldRouteBackToGroupOrder(): boolean {
    const groupOrder = this.store.selectSnapshot(
      (state: GlobalStateModel) => state.order.groupOrder,
    );
    const fragment = this.async.transform(this.route.fragment);
    return !groupOrder && fragment === 'group-order';
  }
}
