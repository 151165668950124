<div #container class="p-sidebar" [@collapseHorizontally]="!visible">
  <div class="p-sidebar-header p-3">
    <div class="d-flex flex-nowrap align-items-center gap-2">
      <img
        class="h4-birds"
        src="assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
        alt="" />
      <h4 *ngIf="user; else noUser" class="line-clamp-1">
        Hi, {{ user.first_name }}!
      </h4>
      <ng-template #noUser>
        <h4 class="line-clamp-1">Freebirds</h4>
      </ng-template>
    </div>
    <div
      class="d-flex cursor-pointer"
      (click)="close($event)"
      (keydown.enter)="close($event)"
      *ngIf="showCloseIcon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        class="bi bi-x-lg"
        viewBox="0 0 16 16">
        <path
          d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
      </svg>
    </div>
  </div>

  <div class="d-flex flex-column h-100">
    <ng-container *ngIf="!((groupOrder$ | async) && ((isGroupOrderOwner$ | async) === false))">
      <div class="p-3">
        <div
          class="p-3 your-info-bg d-flex flex-column gap-2 bg-white text-center">
          <ng-container *ngIf="user; else noUserMenu">
            <div class="your-info-content d-flex flex-column rounded">
              <!-- <h4 class="px-3 py-2">Your Info</h4> -->
              <button
                type="button"
                class="menu-links-info"
                routerLink="/profile"
                fragment="orderHistory">
                Order History
              </button>
              <button
                type="button"
                class="menu-links-info"
                routerLink="/profile"
                fragment="paymentMethods">
                Payment Methods
              </button>
              <button
                type="button"
                class="menu-links-info"
                routerLink="/profile"
                fragment="faveLocations">
                Favorite Locations
              </button>
              <ng-container *ngIf="isFullExperience$ | async">
                <button
                  type="button"
                  class="menu-links-info"
                  routerLink="/profile"
                  fragment="inbox">
                  Inbox
                </button>
                <button
                  type="button"
                  class="menu-links-info"
                  routerLink="/profile"
                  fragment="accountInfo">
                  Account Info
                </button>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #noUserMenu>
            <h5 class="">Join Now or Sign In to Earn Rewards</h5>
            <div class="d-flex flex-column gap-2">
              <button
                routerLink="/user/create-an-account"
                type="button"
                class="btn btn-primary text-nowrap grow-1 basis-0">
                Join Now
              </button>
              <button
                routerLink="/user/sign-in"
                type="button"
                class="btn btn-outline-secondary text-nowrap grow-1 basis-0">
                Sign In
              </button>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <div class="d-flex flex-column grow-1">
      <button type="button" class="menu-links" routerLink="/">Home</button>

      <button type="button" class="menu-links" routerLink="/" fragment="menu">
        Order
      </button>

      <a
        class="menu-links text-decoration-none"
        href="https://catering.freebirds.com"
        target="_blank"
        >Catering</a
      >

      <button type="button" class="menu-links" routerLink="/our-menu">
        Menu
      </button>

      <ng-container *ngIf="user; else noUserRewards">
        <button
          type="button"
          class="menu-links"
          routerLink="/rewards"
          [routerLinkActive]="['active']">
          Rewards
        </button>
      </ng-container>

      <ng-template #noUserRewards>
        <button
          type="button"
          class="menu-links"
          routerLink="/rewards"
          [routerLinkActive]="['active']">
          Rewards
        </button>
      </ng-template>

      <!-- <button type="button" class="menu-links">Our Story</button> -->

      <a
        class="menu-links text-decoration-none"
        routerLink="/nutrition-calculator"
        >Nutrition</a
      >

      <a
        class="menu-links text-decoration-none"
        routerLink="/locations"
      >Locations</a
      >

      <a
        href="https://careers.freebirds.com/"
        target="_blank"
        class="menu-links text-decoration-none">
        Careers
      </a>
    </div>
  </div>
</div>
