<div class="d-flex flex-column gap-4">
  <div class="d-flex flex-column gap-3 align-items-center">
    <img
      class="go-icon"
      src="/assets/images/ingredient_illustrations/Icons/fb-dancing.svg"
      alt="icon of a pepper and tomato dancing to music"/>
    <h1 class="text-center">Start A Group Order</h1>
    <h3 class="text-center">Burritos Taste Better With Friends</h3>
  </div>

  <div class="d-flex flex-column gap-4 p-3 bg-light rounded">
    <div class="d-flex flex-column gap-2">
      <h3>How Group Ordering Works</h3>
      <ol class="m-0">
        <li>
          What time should your friends order by? Set an order deadline then
          start the order.
        </li>
        <li>
          Send the shareable link to your friends. Start adding your items to
          the bag.
        </li>
        <li>
          Once everyone has added their order, checkout! The host of the group
          must pay for the order. (Pro tip: You get all the points!)
        </li>
      </ol>
      <small class="m-0 fst-italic">
        <b>Important Note:</b> The maximum order cost is $300 dollars. For
        orders larger than $300 please place a
        <a
          href="https://catering.freebirds.com/index.cfm?fuseaction=order&action=menu-categories&channel-id=2"
        >catering</a
        >
        order. In addition, larger orders may take longer to prepare for your
        group.
      </small>

<!--      <ng-container *ngIf="order$ | async as order">-->
<!--        <ng-container *ngIf="order.products.length > 0">-->
<!--          <div class="alert alert-warning">-->
<!--            Creating a group order will cancel your current order and create a new one. Any items that you have in your current cart will be-->
<!--            removed.-->
<!--          </div>-->
<!--        </ng-container>-->
<!--      </ng-container>-->
    </div>

    <form [formGroup]="groupOrderForm">
      <div class="d-flex flex-column gap-4">
        <div class="d-flex flex-column gap-3">
          <div class="d-flex flex-column">
            <div class="badge badge-warning w-fit-content mb-2">Required</div>
            <div class="form-floating">
              <select
                class="form-control"
                id="goDeadline"
                required
                formControlName="deadline">
                <option selected disabled>Choose a Deadline Time</option>
                <ng-container *ngFor="let deadline of availableDeadlineTimes">
                  <option [value]="deadline">
                    {{ deadline | date: 'shortTime' }}
                  </option>
                </ng-container>
              </select>
              <label for="goDeadline"> Group Order Deadline* </label>
            </div>

            <small>
              Others must add their food by the time of the deadline.
            </small>
          </div>

          <!--        show this after the user has tried clicking start order without selecting a deadline-->
          <ng-container *ngIf="deadline.touched && deadline.value === null">
            <div
              class="alert alert-danger mb-2 d-flex align-items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-exclamation-triangle"
                viewBox="0 0 16 16">
                <path
                  d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                <path
                  d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
              </svg>
              You must set a group order deadline to start your order.
            </div>
          </ng-container>

          <div class="d-flex flex-column">
            <div class="badge badge-dark w-fit-content mb-2">Optional</div>
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                placeholder="Send a Note with Your Invite (Optional)"
                formControlName="note"
                id="goNote"/>
              <label class="col-form-label" for="goNote">
                Send a Note with Your Invite
              </label>
            </div>
          </div>

          <div
            class="d-flex flex-row flex-nowrap gap-3 w-100 justify-content-end">
            <app-button
              type="button"
              classes="btn btn-outline-secondary w-fit-content"
            >Cancel
            </app-button>
            <app-button
              type="button"
              classes="btn btn-primary w-fit-content"
              (buttonClick)="startClick()"
            >Start Order
            </app-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
