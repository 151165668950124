import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-os-monster-sizes',
  templateUrl: './os-monster-sizes.component.html',
  styleUrls: ['os-monster-sizes.component.scss'],
})
export class OsMonsterSizesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
