import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { StaticMenuCollectionSubgroup } from '@models/index';

@Component({
  selector: 'app-sm-ingredient-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sm-ingredient-group.component.html',
  styleUrls: ['sm-ingredient-group.component.scss'],
})
export class SmIngredientGroupComponent implements OnInit {
  @Input() collectionSubgroup!: StaticMenuCollectionSubgroup;
  @Input() first!: boolean;
  @Input() last!: boolean;
  constructor() {}
  ngOnInit() {}
}
