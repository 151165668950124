import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
/* Modules */
import { AppCommonModule } from '@common/app-common.module';
import { PipesModule } from '@pipes/pipes.module';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';

// import { NavigationModule } from '@modules/navigation/navigation.module';
/* Components */
import * as orderComponents from './components';
/* Containers */
import * as orderContainers from './containers';
/* Guards */
import * as orderGuards from './guards';
/* Services */
import * as orderServices from './services';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AppCommonModule,
    CollapseModule.forRoot(),
    ButtonsModule.forRoot(),
    PipesModule,
    PopoverModule,
    NgxMapboxGLModule,
  ],
  providers: [...orderServices.services, ...orderGuards.guards],
  declarations: [...orderContainers.containers, ...orderComponents.components],
  exports: [...orderContainers.containers, ...orderComponents.components],
})
export class OrderModule {}
