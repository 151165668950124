import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddSavedProductChoice, PaymentMethod, Referral } from '@models/index';
import {
  CreateGroupOrderDto,
  SetTipDto,
  UpdateGroupOrderDto,
} from '@server/order/dto';
import { StartOrderRequestDto } from '@server/order/dto';
import { AddToOrderDto } from '@server/order/dto';
import { AddToOrderByChainIdDto } from '@server/order/dto/add-to-order-by-chain-id.dto';
import { RemoveFromOrderDto } from '@server/order/dto/remove-from-order.dto';
import { SetCouponCodeDto } from '@server/order/dto/set-coupon-code.dto';
import { SetCustomFieldDto } from '@server/order/dto/set-custom-field.dto';
import { SetDeliveryAddressDto } from '@server/order/dto/set-delivery-address.dto';
import { SetHandoffModeDto } from '@server/order/dto/set-handoff-mode.dto';
import { SetRewardDto } from '@server/order/dto/set-reward.dto';
import { SetTimeWantedDto } from '@server/order/dto/set-time-wanted.dto';
import { StartOrderFromPastOrderDto } from '@server/order/dto/start-order-from-past-order.dto';
import { SubmitBasketDto } from '@server/order/dto/submit-basket.dto';
import { TransferBasketDto } from '@server/order/dto/transfer-basket.dto';
import { UpdateOrderItemDto } from '@server/order/dto/update-order-item.dto';
import {
  AddToOrderByChainIDResponse,
  AddToOrderResponse,
  CreateGroupOrderResponse,
  DeleteRewardResponse,
  GetAvailableRewardsResponse,
  GetAvailableTimesResponse,
  GetDeliveryStatusResponse,
  GetGroupOrderResponse,
  GetOrderResponse,
  GetOrderStatusResponse,
  RemoveCouponResponse,
  RemoveFromOrderResponse,
  SetCouponResponse,
  SetCustomFieldResponse,
  SetDeliveryAddressResponse,
  SetHandoffModeResponse,
  SetRewardResponse,
  SetTimeWantedResponse,
  SetTimeWantedToASAPResponse,
  SetTipResponse,
  StartOrderFromPreviousOrderResponse,
  StartOrderResponse,
  SubmitBasketResponse,
  TransferOrderResponse,
  UpdateGroupOrderResponse,
  UpdateOrderItemResponse,
  ValidateOrderResponse,
} from '@server/order/responses';
import {
  BasketBillingAccount,
  HandoffMode,
  ReceivingUser,
} from '@server/vendor/olo/interfaces';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { SetReferralsDto } from '@server/order/dto/set-referrals.dto';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private orderAPIPrefix = environment.apiBaseURL + '/api/order';

  constructor(private http: HttpClient) {}

  getOrder(basketID: string): Observable<GetOrderResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}`;
    return this.http.get<GetOrderResponse>(resource);
  }

  startOrder(
    locationID: number,
    userID?: string,
  ): Observable<StartOrderResponse> {
    const resource = `${this.orderAPIPrefix}`;
    const body: StartOrderRequestDto = {
      locationID,
      userID,
    };
    return this.http.post<StartOrderResponse>(resource, body);
  }

  addReferrals(
    basketID: string,
    referrals: Referral[],
  ){
    const resource = `${this.orderAPIPrefix}/${basketID}/referrals`;
    const body: SetReferralsDto = {
      referrals,
    };
    return this.http.put<void>(resource, body);
  }

  transferOrder(
    basketID: string,
    locationID: number,
  ): Observable<TransferOrderResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/transfer`;
    const body: TransferBasketDto = {
      vendorID: locationID,
    };
    return this.http.put<TransferOrderResponse>(resource, body);
  }

  validateOrder(
    basketID: string,
    checkUpsell: boolean = false,
  ): Observable<ValidateOrderResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/calculate`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        checkUpsell,
      },
    });
    return this.http.get<ValidateOrderResponse>(resource, { params });
  }

  addToOrder(
    basketID: string,
    productID: number,
    quantity: number,
    options: string[],
    specialInstructions?: string,
    recipient?: string,
  ): Observable<AddToOrderResponse> {
    // switch (true) {
    //   case !!recipient && !specialInstructions:
    //     specialInstructions = recipient;
    //     break;
    //   case !!recipient && !!specialInstructions:
    //     specialInstructions = `${recipient}: ${specialInstructions}`;
    //     break;
    // }
    const resource = `${this.orderAPIPrefix}/${basketID}/products/add`;
    const body: AddToOrderDto = {
      productID,
      quantity,
      options,
      specialInstructions,
      recipient,
    };
    return this.http.post<AddToOrderResponse>(resource, body);
  }

  addToOrderByChainID(
    basketID: string,
    chainProductID: number,
    quantity: number,
    choices: AddSavedProductChoice[],
    specialInstructions?: string,
    recipient?: string,
  ): Observable<AddToOrderByChainIDResponse> {
    // switch (true) {
    //   case !!recipient && !specialInstructions:
    //     specialInstructions = recipient;
    //     break;
    //   case !!recipient && !!specialInstructions:
    //     specialInstructions = `${recipient}: ${specialInstructions}`;
    //     break;
    // }
    const resource = `${this.orderAPIPrefix}/${basketID}/products/add/byChainID`;
    const body: AddToOrderByChainIdDto = {
      chainProductID,
      quantity,
      choices,
      specialInstructions,
      recipient,
    };
    return this.http.post<AddToOrderByChainIDResponse>(resource, body);
  }

  removeFromOrder(
    basketID: string,
    basketProductID: number,
  ): Observable<RemoveFromOrderResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/products/remove`;
    const body: RemoveFromOrderDto = {
      basketProductID,
    };
    return this.http.post<RemoveFromOrderResponse>(resource, body);
  }

  updateOrderItem(
    basketID: string,
    basketProductID: number,
    productID: number,
    quantity: number,
    options: string[],
    specialInstructions?: string,
    recipient?: string,
  ): Observable<UpdateOrderItemResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/products/${basketProductID}`;
    const body: UpdateOrderItemDto = {
      productID,
      quantity,
      options,
      specialInstructions,
      recipient,
    };
    return this.http.put<UpdateOrderItemResponse>(resource, body);
  }

  setTimeWanted(
    basketID: string,
    isManualFire: boolean = false,
    year?: number,
    month?: number,
    day?: number,
    hour?: number,
    minute?: number,
  ): Observable<SetTimeWantedResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/timeWanted`;
    const body: SetTimeWantedDto = {
      isManualFire,
      year,
      month,
      day,
      hour,
      minute,
    };
    return this.http.put<SetTimeWantedResponse>(resource, body);
  }

  setTimeWantedToASAP(
    basketID: string,
  ): Observable<SetTimeWantedToASAPResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/timeWanted/asap`;
    return this.http.delete<SetTimeWantedToASAPResponse>(resource);
  }

  setHandoffMode(
    basketID: string,
    handoffMode: HandoffMode,
  ): Observable<SetHandoffModeResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/handoffMode`;
    const body: SetHandoffModeDto = {
      handoffMode,
    };
    return this.http.put<SetHandoffModeResponse>(resource, body);
  }

  setCustomField(
    basketID: string,
    customFieldID: number,
    value: string,
  ): Observable<SetCustomFieldResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/customFields`;
    const body: SetCustomFieldDto = {
      customFieldID,
      value,
    };
    return this.http.put<SetCustomFieldResponse>(resource, body);
  }

  setDeliveryAddress(
    basketID: string,
    handoffMode: HandoffMode.DELIVERY | HandoffMode.DISPATCH,
    addressID?: number,
    building?: string,
    streetAddress?: string,
    city?: string,
    zipCode?: string,
    specialInstructions?: string,
    isDefault?: boolean,
  ): Observable<SetDeliveryAddressResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/deliveryAddress`;
    const body: SetDeliveryAddressDto = {
      handoffMode,
      addressID,
      building,
      streetAddress,
      city,
      zipCode,
      specialInstructions,
      isDefault,
    };
    return this.http.put<SetDeliveryAddressResponse>(resource, body);
  }

  setTip(basketID: string, amount: number): Observable<SetTipResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/tip`;
    const body: SetTipDto = {
      amount,
    };
    return this.http.put<SetTipResponse>(resource, body);
  }

  submitBasket(
    basketID: string,
    billingAccounts: PaymentMethod[],
    authToken?: string,
    user?: ReceivingUser,
    guestOptIn?: boolean,
  ): Observable<SubmitBasketResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/submit`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        token: localStorage.getItem('rwg_token') || '',
      },
    });

    const body: SubmitBasketDto = {
      billingAccounts,
      authToken,
      user,
      guestOptIn,
    };
    return this.http.post<SubmitBasketResponse>(resource, body, {params});
  }

  setCoupon(basketID: string, code: string): Observable<SetCouponResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/coupon`;
    const body: SetCouponCodeDto = {
      code,
    };
    return this.http.put<SetCouponResponse>(resource, body);
  }

  removeCoupon(basketID: string): Observable<RemoveCouponResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/coupon`;
    return this.http.delete<RemoveCouponResponse>(resource);
  }

  getOrderStatus(orderID: string): Observable<GetOrderStatusResponse> {
    const resource = `${this.orderAPIPrefix}/${orderID}/status`;
    return this.http.get<GetOrderStatusResponse>(resource);
  }

  getDeliveryStatus(orderID: string): Observable<GetDeliveryStatusResponse> {
    const resource = `${this.orderAPIPrefix}/${orderID}/deliveryStatus`;
    return this.http.get<GetDeliveryStatusResponse>(resource);
  }

  createOrderFromPastOrder(
    orderID: string,
  ): Observable<StartOrderFromPreviousOrderResponse> {
    const resource = `${this.orderAPIPrefix}/fromPastOrder`;
    const body: StartOrderFromPastOrderDto = {
      id: orderID,
    };
    return this.http.post<StartOrderFromPreviousOrderResponse>(resource, body);
  }

  getAvailableOrderTimes(
    basketID: string,
    startTime: string,
    endTime: string,
  ): Observable<GetAvailableTimesResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/availableTimes`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        startTime,
        endTime,
      },
    });
    return this.http.get<GetAvailableTimesResponse>(resource, { params });
  }

  getAvailableRewards(
    basketID: string,
    orderingToken: string,
  ): Observable<GetAvailableRewardsResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/availableRewards`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        accessToken: orderingToken,
      },
    });
    return this.http.get<GetAvailableRewardsResponse>(resource, { params });
  }

  setReward(
    basketID: string,
    rewardReference: string,
    orderingToken: string,
  ): Observable<SetRewardResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/reward`;
    const body: SetRewardDto = {
      rewardReference,
    };
    const params: HttpParams = new HttpParams({
      fromObject: {
        accessToken: orderingToken,
      },
    });
    return this.http.put<SetRewardResponse>(resource, body, { params });
  }

  removeReward(
    basketID: string,
    rewardID: number,
  ): Observable<DeleteRewardResponse> {
    const resource = `${this.orderAPIPrefix}/${basketID}/reward`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        rewardID,
      },
    });
    return this.http.delete<DeleteRewardResponse>(resource, { params });
  }

  getGroupOrder(
    groupOrderID: string,
    orderingToken?: string,
    basketID?: string,
  ): Observable<GetGroupOrderResponse> {
    const resource = `${this.orderAPIPrefix}/groupOrders/${groupOrderID}`;
    let params: HttpParams = new HttpParams();
    if (orderingToken) {
      params = params.append('accessToken', orderingToken);
    }
    if (basketID) {
      params = params.append('basketID', basketID);
    }
    return this.http.get<GetGroupOrderResponse>(resource, { params });
  }

  createGroupOrder(
    orderingToken: string,
    restaurantID: number,
    deadline: Date,
    basketID: string,
    note?: string,
  ): Observable<CreateGroupOrderResponse> {
    const resource = `${this.orderAPIPrefix}/groupOrders`;
    const body: CreateGroupOrderDto = {
      accessToken: orderingToken,
      restaurantID,
      deadline: moment(deadline).format('YYYYMMDD HH:mm'),
      basketID,
      note,
    };
    return this.http.post<CreateGroupOrderResponse>(resource, body);
  }

  updateGroupOrder(
    groupOrderID: string,
    orderingToken: string,
    deadline: Date,
    note?: string,
  ): Observable<UpdateGroupOrderResponse> {
    const resource = `${this.orderAPIPrefix}/groupOrders/${groupOrderID}`;
    const body: UpdateGroupOrderDto = {
      accessToken: orderingToken,
      groupOrderID,
      deadline: moment(deadline).format('YYYYMMDD HH:mm'),
      note,
    };
    return this.http.put<UpdateGroupOrderResponse>(resource, body);
  }
}
