<app-navbar></app-navbar>
<section class="container d-flex justify-content-center">
  <div class="thin-container-column nav-page-height py-5">
    <h1 class="display-4 text-center">Contact Us</h1>

    <ng-container *ngIf="!isComplete else completedForm">
      <tabset [justified]="true" type="pills" class="d-none d-lg-block">
        <tab heading="Feedback">
          <ng-container *ngTemplateOutlet="feedback"></ng-container>
        </tab>
        <tab heading="Media Inquiries">
          <ng-container *ngTemplateOutlet="mediaInquiries"></ng-container>
        </tab>
        <tab heading="Catering">
          <ng-container *ngTemplateOutlet="catering"></ng-container>
        </tab>
        <!--      <tab heading="Community">-->
        <!--        <ng-container *ngTemplateOutlet="community"></ng-container>-->
        <!--      </tab>-->
      </tabset>

      <tabset [justified]="true" type="pills" [vertical]="true" class="d-lg-none">
        <tab heading="Feedback">
          <ng-container *ngTemplateOutlet="feedback"></ng-container>
        </tab>
        <tab heading="Media Inquiries">
          <ng-container *ngTemplateOutlet="mediaInquiries"></ng-container>
        </tab>
        <tab heading="Catering">
          <ng-container *ngTemplateOutlet="catering"></ng-container>
        </tab>
        <!--      <tab heading="Community">-->
        <!--        <ng-container *ngTemplateOutlet="community"></ng-container>-->
        <!--      </tab>-->
      </tabset>
    </ng-container>

    <ng-template #completedForm>
      <h2 class="h2 text-center">Contact Form Submitted</h2>
      <div class="text-center">
        Thank you for your contact form submission! Click the <span class="font-weight-bold">Done</span> button below to submit another form, or <span><a [routerLink]="'/'" class="font-weight-bold">click
        here to return home</a></span>.
      </div>
      <button
        type="button"
        class="btn btn-primary"
        (click)="goBackFromCompleted()" [disabled]="submitLoading">
        Done
      </button>
    </ng-template>
  </div>
</section>
<app-footer></app-footer>

<ng-template #feedback>
  <div class="d-flex flex-column align-items-center gap-3 pt-4">
    <h2 class="h2 text-center">Feedback</h2>
    <h4 class="h4 text-center">
      CALL US: <a href="tel://5122917411">(512)291-7411</a>
    </h4>
    <iframe
      class="feedback"
      src="https://feedback.inmoment.com/websurvey/2/begin?gateway=FreebirdsCustomerFeedback"></iframe>

    <!--          <div class="text-center">-->
    <!--            Want to send us a message? Kindly complete the following form to-->
    <!--            submit your inquiry and our manager will contact you as soon as-->
    <!--            possible.-->
    <!--          </div>-->
    <!--          <small-->
    <!--            ><i-->
    <!--              >*Have App questions or feedback? Please contact us by opening the-->
    <!--              App and selecting the ‘More’ tab and then ‘Need Help?”*</i-->
    <!--            ></small-->
    <!--          >-->
    <!--          <form-->
    <!--            [formGroup]="feedbackForm"-->
    <!--            style="display: grid; gap: 1rem"-->
    <!--            class="w-100">-->
    <!--            <div class="form-floating">-->
    <!--              <input-->
    <!--                id="name"-->
    <!--                formControlName="name"-->
    <!--                class="form-control"-->
    <!--                placeholder="Full Name" />-->
    <!--              <label for="name">Full Name</label>-->
    <!--            </div>-->
    <!--            <div class="form-floating">-->
    <!--              <input-->
    <!--                id="email"-->
    <!--                formControlName="email"-->
    <!--                class="form-control"-->
    <!--                placeholder="Email Address"-->
    <!--                type="email" />-->
    <!--              <label for="email">Email Address</label>-->
    <!--            </div>-->
    <!--            <div class="form-floating">-->
    <!--              <input-->
    <!--                id="reason"-->
    <!--                formControlName="reason"-->
    <!--                class="form-control"-->
    <!--                placeholder="Reason for Contact" />-->
    <!--              <label for="reason">Reason for Contact</label>-->
    <!--            </div>-->
    <!--            <div class="form-floating">-->
    <!--              <input-->
    <!--                id="phone"-->
    <!--                formControlName="phone"-->
    <!--                class="form-control"-->
    <!--                placeholder="Phone Number"-->
    <!--                type="tel" />-->
    <!--              <label for="phone">Phone Number</label>-->
    <!--            </div>-->
    <!--            <div class="form-floating">-->
    <!--              <select-->
    <!--                class="form-control"-->
    <!--                formControlName="location"-->
    <!--                id="location">-->
    <!--                <option selected disabled>Select a Location</option>-->
    <!--                <ng-container *ngFor="let location of locations">-->
    <!--                  <option>{{ location.name }}</option>-->
    <!--                </ng-container>-->
    <!--              </select>-->
    <!--              <label for="location">Location</label>-->
    <!--            </div>-->
    <!--            <div class="form-row">-->
    <!--              <div class="form-floating col-6">-->
    <!--                <input-->
    <!--                  class="form-control"-->
    <!--                  formControlName="date"-->
    <!--                  id="date"-->
    <!--                  type="date"-->
    <!--                  placeholder="Date of Visit" />-->
    <!--                <label for="date">Date of Visit</label>-->
    <!--              </div>-->
    <!--              <div class="form-floating col-6">-->
    <!--                <input-->
    <!--                  class="form-control"-->
    <!--                  formControlName="time"-->
    <!--                  id="time"-->
    <!--                  type="time"-->
    <!--                  placeholder="Time of Visit" />-->
    <!--                <label for="date">Time of Visit</label>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--            <div class="form-floating">-->
    <!--              <input-->
    <!--                id="subject"-->
    <!--                formControlName="subject"-->
    <!--                class="form-control"-->
    <!--                placeholder="Subject"-->
    <!--                type="text" />-->
    <!--              <label for="subject">Subject</label>-->
    <!--            </div>-->
    <!--            <div class="form-floating">-->
    <!--              <textarea-->
    <!--                id="message"-->
    <!--                formControlName="message"-->
    <!--                class="form-control"-->
    <!--                placeholder="Message"></textarea>-->
    <!--              <label for="message">Message</label>-->
    <!--            </div>-->
    <!--            <button-->
    <!--              type="button"-->
    <!--              class="btn btn-primary"-->
    <!--              (click)="submitForm(feedbackForm, 'feedback')">-->
    <!--              Submit-->
    <!--            </button>-->
    <!--          </form>-->
    <!--        </div>-->
    <!--      </tab>-->
    <!--      <tab heading="Media Inquiries">-->
    <!--        <div class="d-flex flex-column align-items-center pt-4">-->
    <!--          <h2 class="h2 text-center">Media Inquiries</h2>-->
    <!--          <p class="text-center">-->
    <!--            Have a story idea or media inquiry? Please contact our public-->
    <!--            relations and media department by completing the form below.-->
    <!--          </p>-->
    <!--          <form-->
    <!--            [formGroup]="mediaForm"-->
    <!--            style="display: grid; gap: 1rem"-->
    <!--            class="w-100">-->
    <!--            <div class="form-floating">-->
    <!--              <input-->
    <!--                id="name"-->
    <!--                formControlName="name"-->
    <!--                class="form-control"-->
    <!--                placeholder="Full Name" />-->
    <!--              <label for="name">Full Name</label>-->
    <!--            </div>-->
    <!--            <div class="form-floating">-->
    <!--              <input-->
    <!--                id="email"-->
    <!--                formControlName="email"-->
    <!--                class="form-control"-->
    <!--                placeholder="Email Address"-->
    <!--                type="email" />-->
    <!--              <label for="email">Email Address</label>-->
    <!--            </div>-->
    <!--            <div class="form-floating">-->
    <!--              <input-->
    <!--                id="phone"-->
    <!--                formControlName="phone"-->
    <!--                class="form-control"-->
    <!--                placeholder="Phone Number"-->
    <!--                type="tel" />-->
    <!--              <label for="phone">Phone Number</label>-->
    <!--            </div>-->
    <!--            <div class="form-floating">-->
    <!--              <input-->
    <!--                id="company"-->
    <!--                formControlName="company"-->
    <!--                class="form-control"-->
    <!--                placeholder="Company or Organization"-->
    <!--                type="text" />-->
    <!--              <label for="company">Company or Organization</label>-->
    <!--            </div>-->
    <!--            <div class="form-floating">-->
    <!--              <textarea-->
    <!--                id="message"-->
    <!--                formControlName="message"-->
    <!--                class="form-control"-->
    <!--                placeholder="Message"></textarea>-->
    <!--              <label for="message">Message</label>-->
    <!--            </div>-->
    <!--            <button-->
    <!--              type="button"-->
    <!--              class="btn btn-primary"-->
    <!--              (click)="submitForm(mediaForm, 'media')">-->
    <!--              Submit-->
    <!--            </button>-->
    <!--          </form>-->
  </div>
</ng-template>

<ng-template #mediaInquiries>
  <div class="d-flex flex-column align-items-center pt-4">
    <h2 class="h2 text-center">Media Inquiries</h2>
    <p class="text-center">
      Have a story idea or media inquiry? Please contact our public relations
      and media department by completing the form below.
    </p>
    <form
      [formGroup]="mediaForm"
      style="display: grid; gap: 1rem"
      class="w-100">
      <div class="form-floating">
        <input
          id="name"
          formControlName="name"
          class="form-control"
          placeholder="Full Name" [disabled]="submitLoading"/>
        <label for="name">Full Name</label>
      </div>
      <div class="form-floating">
        <input
          id="email"
          formControlName="email"
          class="form-control"
          placeholder="Email Address"
          type="email" [disabled]="submitLoading"/>
        <label for="email">Email Address</label>
      </div>
      <div class="form-floating">
        <input
          id="phone"
          formControlName="phone"
          class="form-control"
          placeholder="Phone Number"
          type="tel" [disabled]="submitLoading"/>
        <label for="phone">Phone Number</label>
      </div>
      <div class="form-floating">
        <input
          id="company"
          formControlName="company"
          class="form-control"
          placeholder="Company or Organization"
          type="text" [disabled]="submitLoading"/>
        <label for="company">Company or Organization</label>
      </div>
      <div class="form-floating">
        <textarea
          id="message"
          formControlName="message"
          class="form-control"
          placeholder="Message" [disabled]="submitLoading"></textarea>
        <label for="message">Message</label>
      </div>
      <button
        type="button"
        class="btn btn-primary text-center d-flex justify-content-center"
        (click)="submitForm(mediaForm, 'media')" [disabled]="submitLoading">
        <ng-container *ngIf="!submitLoading">Submit</ng-container>
        <ng-container *ngIf="submitLoading"><div class="btn-loader"><div></div></div></ng-container>
      </button>
    </form>
  </div>
</ng-template>

<ng-template #catering>
  <div class="d-flex flex-column align-items-center pt-4">
    <h2 class="h2 text-center">Catering</h2>
    <!--    <p class="text-center">-->
    <!--      Feel free to call or email us for 1-to-1 service from one of our seasoned-->
    <!--      pros, or submit the completed form below and we'll contact you shortly.-->
    <!--    </p>-->
    <p class="text-center">
      Feel free to call or email us for 1-to-1 service from one of our seasoned
      pros.
    </p>
    <h4 class="h4 mb-3 text-center">
      CALL US: <a href="tel://8888112473">(888)811-BIRD</a> | EMAIL:
      <a href="mailto://cater@freebirds.com">CATER@FREEBIRDS.COM</a>
    </h4>
    <form
      [formGroup]="cateringForm"
      style="display: grid; gap: 1rem"
      class="w-100">
      <div class="form-floating">
        <input
          id="name"
          formControlName="name"
          class="form-control"
          placeholder="Full Name" [disabled]="submitLoading"/>
        <label for="name">Full Name</label>
      </div>
      <div class="form-floating">
        <input
          id="email"
          formControlName="email"
          class="form-control"
          placeholder="Email Address"
          type="email" [disabled]="submitLoading"/>
        <label for="email">Email Address</label>
      </div>
      <div class="form-floating">
        <input
          id="phone"
          formControlName="phone"
          class="form-control"
          placeholder="Phone Number"
          type="tel" [disabled]="submitLoading"/>
        <label for="phone">Phone Number</label>
      </div>
      <div class="form-floating">
        <input
          id="guests"
          formControlName="guests"
          class="form-control"
          placeholder="Number of Guests"
          type="number" [disabled]="submitLoading"/>
        <label for="guests">Number of Guests</label>
      </div>
      <div class="form-floating">
        <input
          class="form-control"
          formControlName="date"
          id="date"
          type="date"
          placeholder="Date of Visit" [disabled]="submitLoading"/>
        <label for="date">Date of Visit</label>
      </div>
      <div class="form-floating">
        <select class="form-control" formControlName="location" id="location" [disabled]="submitLoading">
          <option selected disabled>Select a Location</option>
          <ng-container *ngFor="let location of locations">
            <option>{{ location.name }}</option>
          </ng-container>
        </select>
        <label for="location">Location</label>
      </div>
      <div class="form-floating">
        <textarea
          id="message"
          formControlName="message"
          class="form-control"
          placeholder="Message" [disabled]="submitLoading"></textarea>
        <label for="message">Message</label>
      </div>
      <button
        type="button"
        class="btn btn-primary text-center d-flex justify-content-center"
        (click)="submitForm(cateringForm, 'catering')" [disabled]="submitLoading">
        <ng-container *ngIf="!submitLoading">Submit</ng-container>
        <ng-container *ngIf="submitLoading"><div class="btn-loader"><div></div></div></ng-container>
      </button>
    </form>
  </div>
</ng-template>

<!--<ng-template #community>-->
<!--  <div class="d-flex flex-column align-items-center pt-4">-->
<!--    <h2 class="h2 text-center">Community</h2>-->
<!--    <p class="text-center">-->
<!--      Have a fundraiser or community event? Please contact our public relations-->
<!--      department by completing the form below.-->
<!--    </p>-->
<!--    <form-->
<!--      [formGroup]="communityForm"-->
<!--      style="display: grid; gap: 1rem"-->
<!--      class="w-100">-->
<!--      <div class="form-floating">-->
<!--        <input-->
<!--          id="name"-->
<!--          formControlName="name"-->
<!--          class="form-control"-->
<!--          placeholder="Full Name" />-->
<!--        <label for="name">Full Name</label>-->
<!--      </div>-->
<!--      <div class="form-floating">-->
<!--        <input-->
<!--          id="email"-->
<!--          formControlName="email"-->
<!--          class="form-control"-->
<!--          placeholder="Email Address"-->
<!--          type="email" />-->
<!--        <label for="email">Email Address</label>-->
<!--      </div>-->
<!--      <div class="form-floating">-->
<!--        <input-->
<!--          id="phone"-->
<!--          formControlName="phone"-->
<!--          class="form-control"-->
<!--          placeholder="Phone Number"-->
<!--          type="tel" />-->
<!--        <label for="phone">Phone Number</label>-->
<!--      </div>-->
<!--      <div class="form-floating">-->
<!--        <input-->
<!--          id="company"-->
<!--          formControlName="company"-->
<!--          class="form-control"-->
<!--          placeholder="Company or Organization"-->
<!--          type="text" />-->
<!--        <label for="company">Company or Organization</label>-->
<!--      </div>-->
<!--      <div class="form-floating">-->
<!--        <textarea-->
<!--          id="message"-->
<!--          formControlName="message"-->
<!--          class="form-control"-->
<!--          placeholder="Message"></textarea>-->
<!--        <label for="message">Message</label>-->
<!--      </div>-->
<!--      &lt;!&ndash;      <button&ndash;&gt;-->
<!--      &lt;!&ndash;        type="button"&ndash;&gt;-->
<!--      &lt;!&ndash;        class="btn btn-primary"&ndash;&gt;-->
<!--      &lt;!&ndash;&lt;!&ndash;        (click)="submitForm(communityForm, 'community')"&ndash;&gt;&ndash;&gt;-->
<!--      &lt;!&ndash;      >&ndash;&gt;-->
<!--      &lt;!&ndash;        Submit&ndash;&gt;-->
<!--      &lt;!&ndash;      </button>&ndash;&gt;-->
<!--    </form>-->
<!--  </div>-->
<!--</ng-template>-->
