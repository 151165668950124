import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-go-inactive-join-modal',
  templateUrl: './go-inactive-join-modal.component.html',
  styleUrls: ['go-inactive-join-modal.component.scss'],
})
export class GoInactiveJoinModalComponent implements OnInit {
  constructor(public bsModalRef: BsModalRef, private router: Router) {}
  ngOnInit() {}
}
