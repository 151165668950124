<div class="modal-header align-items-center rounded-top">
  <img
    class="h4-birds"
    src="../../../../assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="" />
  <h4 class="modal-title grow-1">Nutrition Preferences</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1">
  <h6>
    Select from the list of tags below to indicate any items according to your
    diet choices.
  </h6>

  <div class="filter-categories">
    <ng-container *ngFor="let category of allergenCategories$ | async">
      <div class="filter-shell">
        <div class="filter-heading">
          <div
            class="filter-icon"
            [style.background-color]="category.category_color">
            {{ category.category_abbreviation }}
          </div>

          <div class="filter-title" [style.color]="category.category_color">
            {{ category.category_name }}
          </div>
        </div>
        <div class="filter-pills">
          <ng-container *ngFor="let tag of allergenTags$ | async">
            <ng-container *ngIf="tag.allergen_category === category.id">
              <ng-container
                *ngIf="
                  !(
                    tag | selectedAllergenTag : (selectedAllergenTags$ | async)!
                  );
                  else selected
                ">
                <button
                  class="btn"
                  [style.border-color]="category.category_color"
                  [style.color]="category.category_color"
                  (click)="selectAllergenTag(tag)">
                  {{ tag.allergen_tag_name }}
                </button>
              </ng-container>
              <ng-template #selected>
                <button
                  class="btn"
                  [style.background-color]="category.category_color"
                  [style.color]="category.category_color! | textColor"
                  (click)="deselectAllergenTag(tag)">
                  {{ tag.allergen_tag_name }}
                </button>
              </ng-template>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="modal-footer rounded-bottom">
  <div class="d-flex justify-content-end w-100">
    <app-button
      [classes]="'btn btn-secondary'"
      (buttonClick)="bsModalRef.hide()"
      >Apply</app-button
    >
  </div>
</div>
