import { Pipe, PipeTransform } from '@angular/core';
import { AllergenTag } from '@models/index';

@Pipe({
  name: 'selectedAllergenTag',
})
export class SelectedAllergenTagPipe implements PipeTransform {
  transform(tag: AllergenTag, selectedTags: AllergenTag[]): boolean {
    return selectedTags.some((selectedTag) => selectedTag.id === tag.id);
  }
}
