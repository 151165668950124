<app-navbar></app-navbar>
<section class="container d-flex justify-content-center">
  <div class="thin-container-column nav-page-height py-5">
    <h1 class="display-4 text-center">Privacy</h1>
    <div class="d-flex flex-column align-items-center">
      <ng-container *ngIf="title">
        <h2 class="h2 text-center">{{ title }}</h2>
      </ng-container>
      <ng-container *ngIf="content">
        <div [innerHTML]="content | sanitizeHtml"></div>
      </ng-container>
      <form
        [formGroup]="dataRequestForm"
        style="display: grid; gap: 1rem"
        class="w-100">
        <div class="form-floating">
          <select
            class="form-control"
            formControlName="type"
            id="type"
            [disabled]="submitLoading"
            [class.is-invalid]="requestType.invalid && requestType.touched">
            <option selected disabled [value]="null">Select an option</option>
            <ng-container *ngFor="let type of requestTypes">
              <option [value]="type.value">{{ type.label }}</option>
            </ng-container>
          </select>
          <label for="type"
            >Request Type<ng-container
              *ngTemplateOutlet="required"></ng-container
          ></label>
        </div>
        <div class="form-floating">
          <input
            #firstNameInput
            (keydown.enter)="gotoNextField(lastNameInput)"
            id="firstName"
            formControlName="firstName"
            class="form-control"
            [class.is-invalid]="firstName.invalid && firstName.touched"
            placeholder="First Name"
            enterkeyhint="next"
            autocomplete="given-name"
            [disabled]="submitLoading" />
          <label for="firstName"
            >First Name<ng-container *ngTemplateOutlet="required"></ng-container
          ></label>
        </div>
        <div class="form-floating">
          <input
            #lastNameInput
            (keydown.enter)="gotoNextField(emailInput)"
            id="lastName"
            formControlName="lastName"
            class="form-control"
            [class.is-invalid]="lastName.invalid && lastName.touched"
            placeholder="Last Name"
            enterkeyhint="next"
            autocomplete="family-name"
            [disabled]="submitLoading" />
          <label for="lastName"
            >Last Name<ng-container *ngTemplateOutlet="required"></ng-container
          ></label>
        </div>
        <div class="form-floating">
          <input
            #emailInput
            (keydown.enter)="gotoNextField(phoneInput)"
            id="email"
            formControlName="email"
            class="form-control"
            [class.is-invalid]="email.invalid && email.touched"
            placeholder="Email Address"
            type="email"
            enterkeyhint="next"
            autocomplete="email"
            [disabled]="submitLoading" />
          <label for="email"
            >Email Address<ng-container
              *ngTemplateOutlet="required"></ng-container
          ></label>
        </div>
        <div class="form-floating">
          <input
            #phoneInput
            (keydown.enter)="gotoNextField(cardInput)"
            mask="(000) 000-0000"
            id="phone"
            formControlName="phone"
            class="form-control"
            [class.is-invalid]="phone.invalid && phone.touched"
            placeholder="Phone Number"
            type="tel"
            enterkeyhint="next"
            autocomplete="tel-national"
            [disabled]="submitLoading" />
          <label for="phone"
            >Phone Number<ng-container
              *ngTemplateOutlet="required"></ng-container
          ></label>
        </div>
        <div class="form-floating">
          <input
            #cardInput
            id="last4"
            formControlName="last4"
            class="form-control"
            [class.is-invalid]="last4.invalid && last4.touched"
            placeholder="if associated in-store orders desired in this request"
            type="text"
            inputmode="numeric"
            maxlength="4"
            mask="0000"
            [disabled]="submitLoading"
          />

          <label for="last4"
            >Last 4 Digits of Credit/Debit/Gift Card - if associated in-store
            orders desired in this request</label
          >
        </div>
        <div class="form-floating">
          <select
            class="form-control"
            formControlName="userType"
            id="userType"
            [disabled]="submitLoading"
            [class.is-invalid]="userType.invalid && userType.touched">
            <option selected disabled [value]="null">Select an option</option>
            <ng-container *ngFor="let type of userTypes">
              <option [value]="type.value">{{ type.label }}</option>
            </ng-container>
          </select>
          <label for="userType"
            >I am a(n)...<ng-container
              *ngTemplateOutlet="required"></ng-container
          ></label>
        </div>
        <div class="form-floating">
          <input
            #address1Input
            (keydown.enter)="gotoNextField(address2Input)"
            id="address1"
            formControlName="address1"
            class="form-control"
            [class.is-invalid]="address1.invalid && address1.touched"
            placeholder="Street Address"
            type="text"
            enterkeyhint="next"
            autocomplete="address-line1"
            [disabled]="submitLoading" />
          <label for="address1"
            >Street Address<ng-container
              *ngTemplateOutlet="required"></ng-container
          ></label>
        </div>
        <div class="form-floating">
          <input
            #address2Input
            (keydown.enter)="gotoNextField(cityInput)"
            id="address2"
            formControlName="address2"
            class="form-control"
            [class.is-invalid]="address2.invalid && address2.touched"
            placeholder="Suite/Apt #"
            type="text"
            enterkeyhint="next"
            autocomplete="address-line2"
            [disabled]="submitLoading" />
          <label for="address2">Suite/Apt #</label>
        </div>
        <div class="form-floating">
          <input
            #cityInput
            id="city"
            formControlName="city"
            class="form-control"
            [class.is-invalid]="city.invalid && city.touched"
            placeholder="City"
            type="text"
            autocomplete="address-level2"
            [disabled]="submitLoading" />
          <label for="city"
            >City<ng-container *ngTemplateOutlet="required"></ng-container
          ></label>
        </div>
        <div class="form-floating">
          <select
            class="form-control"
            formControlName="state"
            id="state"
            [disabled]="submitLoading"
            [class.is-invalid]="state.invalid && state.touched">
            <option selected disabled [value]="null">Select a state</option>
            <option value="TX">Texas</option>
            <option disabled>-------</option>
            <ng-container *ngFor="let state of usStatesAndTerritories">
              <option [value]="state.abbreviation">{{ state.name }}</option>
            </ng-container>
          </select>
          <label for="state"
            >State<ng-container *ngTemplateOutlet="required"></ng-container
          ></label>
        </div>
        <div class="form-floating">
          <select
            class="form-control"
            formControlName="country"
            id="country"
            [disabled]="submitLoading"
            [class.is-invalid]="country.invalid && country.touched">
            <option selected disabled [value]="null">Select a country</option>
            <option value="US">United States</option>
            <option disabled>-------</option>
            <ng-container *ngFor="let country of countries">
              <option [value]="country.abbreviation">{{ country.name }}</option>
            </ng-container>
          </select>
          <label for="country"
            >Country<ng-container *ngTemplateOutlet="required"></ng-container
          ></label>
        </div>
        <div class="form-floating">
          <input
            id="zip"
            formControlName="zip"
            class="form-control"
            [class.is-invalid]="zip.invalid && zip.touched"
            placeholder="Zip Code"
            type="text"
            enterkeyhint="done"
            autocomplete="postal-code"
            [disabled]="submitLoading" />
          <label for="zip"
            >Zip Code<ng-container *ngTemplateOutlet="required"></ng-container
          ></label>
        </div>
        <div class="mb-3">
          <label class="">
            This information will be used to process your request and for no
            other purpose. Freebirds will maintain a record of your request
            pursuant to applicable law.
          </label>
        </div>
        <div class="form-check align-items-start">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="authorizedAgent"
            id="authorizedAgent" />
          <label class="form-check-label" for="authorizedAgent">
            I hereby declare under penalty of perjury that I am an authorized agent making this request. I acknowledge that I will be required to provide documentation to verify my status as an authorized agent.
          </label>
        </div>
        <div class="form-check align-items-start">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="emailAcknowledge"
            id="emailAcknowledge" />
          <label class="form-check-label" for="emailAcknowledge">
            I acknowledge that upon submitting this request, I will receive a verification email to confirm my request. I must respond to this email for my request to be processed.
          </label>
        </div>
        <button
          type="button"
          class="btn btn-primary text-center d-flex justify-content-center mt-3"
          (click)="submitForm(dataRequestForm, 'data-request')"
          [disabled]="submitLoading || dataRequestForm.invalid">
          <ng-container *ngIf="!submitLoading">Submit</ng-container>
          <ng-container *ngIf="submitLoading"
            ><div class="btn-loader"><div></div></div
          ></ng-container>
        </button>
      </form>
    </div>
  </div>
</section>
<app-footer></app-footer>
<ng-template #required>
  <span class="text-danger">*</span>
</ng-template>
