<div class="d-flex flex-column gap-4">
  <div class="d-flex flex-column gap-3 align-items-center">
    <img
      class="go-icon"
      src="/assets/images/ingredient_illustrations/Icons/fb-dancing.svg"
      alt="icon of a pepper and tomato dancing to music" />
    <h1 class="text-center">Update Group Order</h1>

    <button
      type="button"
      class="d-flex d-md-none btn btn-outline-danger btn-sm w-fit-content"
      (click)="openGOCancelModal()">
      Cancel Group Order
    </button>
  </div>

  <div class="d-flex flex-column gap-4 p-3 bg-light rounded">
    <div class="d-flex flex-column gap-2">
      <h3>Group Order Information</h3>

      <small class="m-0 fst-italic">
        <b>Important Note:</b> The maximum order cost is $300 dollars. For
        orders larger than $300 please place a
        <a
          href="https://catering.freebirds.com/index.cfm?fuseaction=order&action=menu-categories&channel-id=2"
          >catering</a
        >
        order. In addition, larger orders may take longer to prepare for your
        group.
      </small>
    </div>
    <form [formGroup]="groupOrderForm">
      <div class="d-flex flex-column gap-4">
        <div class="d-flex flex-column gap-3">
          <div class="d-flex flex-column">
            <div class="form-floating">
              <select
                class="form-control"
                id="goDeadline"
                required
                formControlName="deadline">
                <option selected disabled>Update Your Deadline Time</option>
                <ng-container *ngFor="let deadline of availableDeadlineTimes">
                  <option [value]="deadline">
                    {{ deadline | date: 'shortTime' }}
                  </option>
                </ng-container>
              </select>
              <label for="goDeadline"> Update Your Deadline Time* </label>
            </div>

            <small>
              Others must add their food by the time of the deadline.
            </small>
          </div>

          <div class="d-flex flex-column">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                placeholder="Update Your Group Order Note"
                formControlName="note"
                id="goNote" />
              <label class="col-form-label" for="goNote">
                Update Your Group Order Note
              </label>
            </div>
          </div>

          <div class="d-flex flex-row flex-wrap gap-3 w-100">
            <button
              type="button"
              class="d-none d-md-flex justify-content-center align-items-center btn btn-outline-danger w-fit-content"
              (click)="openGOCancelModal()">
              Cancel Group Order
            </button>
            <div
              class="d-flex flex-row flex-nowrap gap-3 justify-content-end grow-1">
              <button
                type="button"
                routerLink="/"
                fragment="menu"
                class="btn btn-outline-secondary w-fit-content">
                Back
              </button>
              <button
                type="button"
                class="btn btn-primary w-fit-content"
                (click)="updateClick()">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
