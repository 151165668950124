import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Restaurant } from '@models/index';
import { HttpQueryParams } from '@server/utility';
import { DeviceDetectionService } from '@services/device-detection/device-detection.service';

@Pipe({
  name: 'mapsLink',
})
export class MapsLinkPipe implements PipeTransform {
  constructor(private deviceDetector: DeviceDetectionService) {}
  transform(restaurant: Restaurant): SafeUrl {
    const params = new HttpQueryParams();

    params.append('q', `Freebirds - ${restaurant.name}`);
    params.append('ll', `${restaurant.latitude},${restaurant.longitude}`);
    params.append('z', '15');
    if (this.deviceDetector.os_version === 'iOS') {
      return 'https://maps.apple.com/' + params.toQueryString();
    } else {
      return 'https://maps.google.com/' + params.toQueryString();
    }
  }
}
