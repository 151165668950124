<alert
  [type]="announcement.announcement_color"
  [dismissible]="announcement.closeable"
  (onClose)="onClose()"
  (click)="announcementClicked($event)"
  [class.cursor-pointer]="announcement.announcement_link"
  [title]="announcement.message_text"
>
  <div class="announcement font-weight-bold text-left text-md-center flex-grow-1">
    {{ announcement.message_text }}
  </div>
</alert>
