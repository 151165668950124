import { HandoffMode } from '@server/vendor/olo/interfaces';

export class GetNearLocations {
  static readonly type = '[Locations] Get Near Locations';

  constructor(
    public longitude: number,
    public latitude: number,
    public radius: number = 25,
    public limit = 10,
    public withCalendars?: boolean,
    public searchRan?: boolean,
  ) {}
}

export class SetAllLocations {
  static readonly type = '[Locations] Set All Locations';

  constructor(
    public withCalendars?: boolean,
    public includePrivate?: boolean,
  ) {}
}

export class FindDeliveryLocation {
  static readonly type = '[Locations] Find Delivery Location';

  constructor(
    public handoffMode: HandoffMode.DELIVERY | HandoffMode.DISPATCH,
    public fullAddress: string,
  ) {}
}

export class SetOrderLocation {
  static readonly type = '[Locations] Set Order Location';
  constructor(public locationID: number) {}
}

export class ClearOrderLocation {
  static readonly type = '[Locations] Clear Order Location';
  constructor() {}
}

export class SetPreviousOrderLocation {
  static readonly type = '[Locations] Set Previous Order Location';
  constructor(public locationID: number) {}
}

export class ClearPreviousOrderLocation {
  static readonly type = '[Locations] Clear Previous Order Location';
  constructor() {}
}

export class ClearMapLocations {
  static readonly type = '[Locations] Clear Map Locations';
  constructor() {}
}
