import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { LayoutSection } from '@models/index';
import { FeaturetteSections } from '@server/vendor/directus/interfaces/directus-collection.interface';
import {Router} from "@angular/router";

@Component({
  selector: 'app-block-featurettes',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './block-featurettes.component.html',
  styleUrls: ['block-featurettes.component.scss'],
})
export class BlockFeaturettesComponent implements OnInit {
  @Input() section!: LayoutSection;
  constructor(private router: Router) {}
  ngOnInit() {}

  linkClick(link?: string) {
    if (!link) return;
    if (link.includes('http')) {
      // External link: Open in a new tab
      window.open(link, '_blank');
    } else {
      // Internal link: Use Angular's router
      this.router.navigateByUrl(link);
    }
  }

}
