<div *ngIf="isRedemption; else earned">
  <div class="d-flex p-3 flex-nowrap gap-3 border rounded">
    <div>
      <img
        class="redemption-icon"
        src="/assets/images/icons/Icons/fb-perks-solid.svg"
        alt="" />
    </div>
    <div class="d-flex grow-1">
      <div class="d-flex flex-column">
        <h5>Reward Title</h5>
        <div class="text-muted">00/00/0000</div>
      </div>
    </div>
    <div class="text-right text-danger">-175 Points</div>
  </div>
</div>

<ng-template #earned>
  <div class="d-flex p-3 flex-nowrap gap-3 border rounded">
    <div>
      <img
        class="earned-icon"
        src="/assets/images/icons/Icons/fb-present.svg"
        alt="" />
    </div>
    <div class="d-flex grow-1">
      <div class="d-flex flex-column">
        <h5>Reward Title</h5>
        <div class="text-muted">00/00/0000</div>
      </div>
    </div>
    <div class="text-right text-success">+175 Points</div>
  </div>
</ng-template>
