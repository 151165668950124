import { Component, Input, OnInit } from '@angular/core';
import { FeaturedAd, HomePage } from '@models/index';

@Component({
  selector: 'app-ad-card-container',
  templateUrl: './ad-card-container.component.html',
  styleUrls: ['ad-card-container.component.scss'],
})
export class AdCardContainerComponent implements OnInit {
  @Input() featuredAds!: FeaturedAd[];

  constructor() {}
  ngOnInit() {}
}
