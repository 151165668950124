<app-navbar></app-navbar>
<div class="order-type-outer">
  <div class="container d-flex justify-content-center nav-page-height">
    <div
      class="order-type-shell d-flex flex-column align-items-center justify-content-center w-100 py-4 gap-4 position-relative">
      <!-- heading -->
      <h1
        class="w-100 text-center font-filmotype pt-md-3"
        *ngIf="!!!(route.fragment | async)">
        Start Your Order
      </h1>
      <!-- handoff choice -->
      <div class="w-100">
        <tabset type="pills" #orderTypeTabs>
          <tab
            class="py-3"
            heading="Pickup"
            (selectTab)="pickupSelected()"
            id="pickup">
            <div class="card d-flex flex-column">
              <div class="card-header">
                <h5 class="card-title">Find Your Nearest Location</h5>
              </div>
              <div class="card-body d-flex flex-column gap-3 p-3">
                <ng-container
                  *ngIf="favoriteLocations$ | async as favoriteLocations">
                  <ng-container *ngIf="favoriteLocations.length > 0">
                    <div class="btn-group" dropdown>
                      <button
                        dropdownToggle
                        type="button"
                        class="btn btn-outline-secondary dropdown-toggle"
                        aria-controls="dropdown-basic">
                        Favorite Locations <span class="caret"></span>
                      </button>
                      <ul
                        id="dropdown-basic"
                        *dropdownMenu
                        class="dropdown-menu w-100 bg-light"
                        role="menu"
                        aria-labelledby="button-basic">
                        <ng-container
                          *ngFor="let location of favoriteLocations">
                          <ng-container
                            *ngIf="location.isavailable; else unavailable">
                            <li
                              role="menuitem"
                              (click)="
                                startOrderFromFavorite(location.vendorid)
                              ">
                              <a
                                class="dropdown-item p-2 rounded bg-light cursor-pointer">
                                <div class="line-clamp-1">
                                  {{ location.name }}
                                </div>
                                <small class="dropdown-address line-clamp-1">{{
                                  location.streetaddress
                                }}</small>
                                <small class="dropdown-address line-clamp-1"
                                  >{{ location.city }}, {{ location.state }}
                                  {{ location.zip }}</small
                                >
                                <ng-container
                                  *ngIf="location.iscurrentlyopen; else closed">
                                  <span
                                    class="badge badge-pill badge-success w-fit-content text-capitalize"
                                    >Open</span
                                  >
                                </ng-container>

                                <ng-template #closed>
                                  <span
                                    class="badge badge-pill badge-danger w-fit-content text-capitalize"
                                    >Closed</span
                                  >
                                </ng-template>
                              </a>
                            </li>
                          </ng-container>
                          <ng-template #unavailable>
                            <li role="menuitem">
                              <a
                                class="dropdown-item p-2 rounded bg-light unavailable">
                                <div class="line-clamp-1">
                                  {{ location.name }}
                                </div>
                                <small class="dropdown-address line-clamp-1">
                                  {{ location.streetaddress }}
                                </small>
                                <small class="dropdown-address line-clamp-1">
                                  {{ location.city }}, {{ location.state }}
                                  {{ location.zip }}
                                </small>
                                <span
                                  class="badge badge-pill badge-warning w-fit-content text-capitalize"
                                  >Ordering Unavailable</span
                                >
                              </a>
                            </li>
                          </ng-template>
                        </ng-container>
                      </ul>
                    </div>
                  </ng-container>
                </ng-container>
                <div class="input-group flex-nowrap">
                  <div class="form-floating grow-1">
                    <div
                      class="w-100 form-control form-left"
                      placeholder="Enter City, State or ZIP"
                      id="mapboxlocationinput"
                      aria-label="Enter your city, state or ZIP"></div>
                  </div>
                  <button
                    routerLink="/locations"
                    class="btn btn-secondary d-flex align-items-center form-btn-right">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-search"
                      viewBox="0 0 16 16">
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </button>
                </div>
                <div class="d-flex flex-column align-items-center gap-2">
                  <div class="location-text text-center text-muted">
                    Enter your address above to find the nearest Freebirds to
                    you.
                  </div>
                  <div
                    class="d-flex flex-column align-items-center gap-2"
                    [@collapse]="!geolocationAllowed">
                    <small class="w-100 text-center text-muted"> Or </small>
                    <app-button
                      classes="btn btn-secondary w-100"
                      (buttonClick)="searchByGeolocation()"
                      [isLoading]="geolocationLoading">
                      Use Your Current Location
                    </app-button>
                  </div>
                  <ng-container *ngIf="errorMessage">
                    <div class="alert alert-danger w-50 mt-4 text-center">
                      {{ errorMessage }}
                    </div>
                  </ng-container>
                  <img
                    class="location-empty-icon"
                    src="assets/images/ingredient_illustrations/Squares/fb-avocado-square-white.svg"
                    alt="" />
                </div>
              </div>
            </div>
          </tab>
          <tab
            class="py-3"
            heading="Delivery"
            (selectTab)="deliverySelected()"
            id="delivery">
            <div class="card d-flex flex-column">
              <div class="card-header">
                <h5 class="card-title">Enter Your Delivery Address</h5>
              </div>
              <div class="card-body d-flex flex-column gap-3 p-3">
                <ng-container *ngIf="user$ | async">
                  <ng-container
                    *ngIf="
                      savedAddresses$
                        | async
                        | dedupeAddresses as savedAddresses
                    ">
                    <ng-container *ngIf="savedAddresses.length">
                      <div class="btn-group" dropdown>
                        <button
                          dropdownToggle
                          type="button"
                          class="btn btn-outline-secondary dropdown-toggle"
                          aria-controls="dropdown-basic">
                          Previous Addresses <span class="caret"></span>
                        </button>
                        <ul
                          id="dropdown-basic"
                          *dropdownMenu
                          class="dropdown-menu w-100 bg-light overflow-auto"
                          role="menu"
                          aria-labelledby="button-basic">
                          <ng-container *ngFor="let address of savedAddresses">
                            <li role="menuitem">
                              <a
                                class="dropdown-item p-2 rounded bg-light cursor-pointer"
                                (click)="addressSelect(address)">
                                <div class="line-clamp-1">
                                  {{ address.streetaddress }}
                                </div>
                                <small
                                  class="dropdown-address line-clamp-1"
                                  *ngIf="address.building"
                                  >{{ address.building }}</small
                                >
                                <small class="dropdown-address line-clamp-1"
                                  >{{ address.city }},
                                  {{ address.zipcode }}</small
                                >
                              </a>
                            </li>
                          </ng-container>
                        </ul>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <div class="input-group flex-nowrap">
                  <div class="form-floating grow-1">
                    <div class="form-floating grow-1">
                      <div
                        class="w-100 form-control form-left"
                        placeholder="Enter City, State or ZIP"
                        id="mapboxdeliveryinput"
                        aria-label="Enter your city, state or ZIP"></div>
                    </div>
                  </div>
                  <button
                    class="btn btn-secondary d-flex align-items-center form-btn-right">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="ei bi-search"
                      viewBox="0 0 16 16">
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </button>
                </div>
                <div class="alert alert-info text-center w-100 d-flex flex-column align-items-center justify-content-center">
                  <span>Delivery Hours: 11:00 AM - 9:00 PM</span>
                  <small>
                    *Delivery hours may vary per restaurant.
                  </small>
                </div>
                <div class="d-flex flex-column align-items-center gap-2">
                  <div class="location-text text-center text-muted">
                    Enter your delivery address above to search for a Freebirds
                    location to deliver to you.
                  </div>
                  <img
                    class="location-empty-icon"
                    src="assets/images/ingredient_illustrations/Squares/fb-avocado-running-square-white.svg"
                    alt="" />
                </div>
              </div>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>
</div>
