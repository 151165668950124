import { Pipe, PipeTransform } from '@angular/core';
import { BasketProduct } from '@server/vendor/olo/interfaces';

interface RecipientSort {
  name: string;
  products: BasketProduct[];
  total: number;
}
@Pipe({
  name: 'orderRecipient',
})
export class OrderRecipientPipe implements PipeTransform {
  transform(products: BasketProduct[]): RecipientSort[] {
    let recipients: RecipientSort[] = [];
    products.forEach((product) => {
      const recipient = recipients.find((r) => r.name === product.recipient);
      if (recipient) {
        recipient.products.push(product);
        recipient.total += product.totalcost;
      } else {
        recipients.push({
          name: product.recipient,
          products: [product],
          total: product.totalcost,
        });
      }
    });
    return recipients.sort((a, b) => a.name.localeCompare(b.name));
  }
}
