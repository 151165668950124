import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@pipes/pipes.module';
import { CarouselModule } from 'ngx-bootstrap/carousel';

/* Modules */
import { AppCommonModule } from '../app-common/app-common.module';
// import { NavigationModule } from '@modules/navigation/navigation.module';
/* Components */
import * as homeComponents from './components';
/* Containers */
import * as homeContainers from './containers';
/* Guards */
import * as homeGuards from './guards';
/* Services */
import * as homeServices from './services';
import { RewardsModule } from '@modules/rewards/rewards.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AppCommonModule,
    PipesModule,
    CarouselModule,
    RewardsModule,
    TooltipModule,
    // NavigationModule,
  ],
  providers: [...homeServices.services, ...homeGuards.guards],
  declarations: [...homeContainers.containers, ...homeComponents.components],
  exports: [...homeContainers.containers, ...homeComponents.components],
})
export class HomeModule {}
