<div class="p-3 product-card" (click)="cardClick()">
  <div
    class="product-image"
    [style.background-image]="
      featuredItem.item?.image ?? '' | cmsAsset | cssURL
    "></div>
  <div>
    <h2 class="font-mpi-gothic">{{ featuredItem.item?.name }}</h2>
    <div
      class="badge badge-pill badge-primary tag mb-1"
      *ngIf="featuredItem.item?.badge">
      {{ featuredItem.item!.badge | uppercase }}
    </div>
    <p *ngIf="featuredItem.item?.short_description">
      {{ featuredItem.item!.short_description }}
    </p>
  </div>
</div>

<ng-template #infoModal>
  <app-sm-info-modal
    [name]="featuredItem.item!.name!"
    [badge]="featuredItem.item?.badge!"
    [description]="
      featuredItem.item?.long_description ??
      featuredItem.item!.short_description!
    "
    [image]="featuredItem.item!.image!"></app-sm-info-modal>
</ng-template>
