/* eslint-disable simple-import-sort/imports */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';

/* Modules */
import { AppCommonModule } from '../app-common/app-common.module';
// import { NavigationModule } from '@modules/navigation/navigation.module';

/* Components */
import * as profileComponents from './components';

/* Containers */
import * as profileContainers from './containers';

/* Guards */
import * as profileGuards from './guards';

/* Services */
import * as profileServices from './services';
import { NgxCaptchaModule } from 'ngx-captcha';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PipesModule } from '@pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AppCommonModule,
    CollapseModule.forRoot(),
    NgxCaptchaModule,
    BsDatepickerModule,
    PipesModule,
  ],
  providers: [...profileServices.services, ...profileGuards.guards],
  declarations: [
    ...profileContainers.containers,
    ...profileComponents.components,
  ],
  exports: [...profileContainers.containers, ...profileComponents.components],
})
export class ProfileModule {}
