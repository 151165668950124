import { HandoffMode } from '@server/vendor/olo/interfaces';

export class InitializeMenu {
  static readonly type = '[Menu] Initialize Menu';
  constructor() {}
}

export class SetMenu {
  static readonly type = '[Menu] Set Menu';

  constructor(public locationID: number, public handoffMode?: HandoffMode) {}
}

export class SetCategory {
  static readonly type = '[Menu] Set Category';

  constructor(
    public readonly locationID: number,
    public readonly categoryID: number,
  ) {}
}

export class SetProduct {
  static readonly type = '[Menu] Set Product';

  constructor(
    public readonly locationID: number,
    public readonly chainProductID: number,
    public readonly withModifiers?: boolean,
  ) {}
}

export class ClearProduct {
  static readonly type = '[Menu] Clear Product';
  constructor() {}
}

export class SetUpsells {
  static readonly type = '[Menu] Set Upsells';

  constructor(public locationID: number, public handoffMode?: HandoffMode) {}
}

export class SetFeaturedItems {
  static readonly type = '[Menu] Set Featured Items';

  constructor() {}
}
