export class OpenCart {
  static readonly type = '[App] Open Cart';
}

export class CloseCart {
  static readonly type = '[App] Close Cart';
}

export class OpenMenu {
  static readonly type = '[App] Open Menu';
}

export class CloseMenu {
  static readonly type = '[App] Close Menu';
}

export class ToggleMenu {
  static readonly type = '[App] Toggle Menu';
}

export class SetFullExperience {
  static readonly type = '[App] Set Full Experience';
  constructor(public isFullExperience: boolean) {}
}

export class SetInitialLoadComplete {
  static readonly type = '[App] Set Initial Load Complete';
  constructor() {}
}
