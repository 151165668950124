import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-group-order-link-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './group-order-link-modal.component.html',
  styleUrls: ['group-order-link-modal.component.scss'],
})
export class GroupOrderLinkModalComponent {
  @Input() textToCopy?: string;

  constructor(public bsModalRef: BsModalRef) {}
}
