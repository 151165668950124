<button
  type="button"
  class="grow-1 p-0 w-100 h-100"
  [ngClass]="
    isSelected ? 'ingredient-card-shell-selected' : 'ingredient-card-shell'
  "
  [style.background-color]="ingredient.content?.background_color ?? '#ffffff'">
  <div
    class="grow-1 d-flex flex-nowrap align-items-center gap-2"
    [class.border-bottom-success]="
      ingredient!.children && ingredient!.modifiers.length === 1 && isSelected
    "
    [attr.aria-expanded]="isSelected"
    aria-controls="collapseBasic"
    (click)="
      isSelected
        ? ingredientRemove(ingredient, $event)
        : ingredientSelect(ingredient, $event)
    ">
    <div class="position-relative">
      <div class="checkmark-container ingredient">
        <div
          class="checkmark-overlay"
          [ngClass]="isSelected ? 'd-flex' : 'd-none'">
          <div class="checkmark-animation">
            <img
              class="hat-checkmark"
              alt="checkmark"
              src="/assets/images/location-specific_illustrations/Icons/fb-hat-orange.svg" />
          </div>
        </div>
      </div>
      <img
        class="ingredient-image"
        [src]="ingredient.content?.image | cmsAsset"
        [alt]="ingredient.name" />
    </div>
    <div class="ingredient-info grow-1 d-flex flex-nowrap gap-2 h-100 pr-2">
      <div
        *ngIf="!isFlagged; else badgeContainer"
        class="grow-1 d-flex flex-column flex-wrap align-items-start justify-content-center text-start">
        <ng-container
          *ngIf="
            ingredient.content.badge_text && ingredient.content.badge_color
          ">
          <app-badge [ingredient]="ingredient"></app-badge>
        </ng-container>
        <h6 class="ingredient-title w-100">
          {{ ingredient!.name }}
        </h6>
        <ng-container
          *ngIf="
            ingredient.content.description &&
            ingredient.content.description !== ''
          ">
          <small class="w-100 text-start ingredient-description">
            {{ ingredient.content.description | slice : 0 : 20 }}
          </small>
        </ng-container>
        <ng-container *ngIf="!isSpicy; else spicyPeppers">
          <small class="ingredient-price d-flex gap-2 w-100">
            <ng-container *ngIf="ingredient!.cost">
              {{ ingredient!.cost | currency }}
            </ng-container>
          </small>
        </ng-container>
      </div>
      <ng-container *ngIf="selectedAllergenTags$ | async as allergenTags">
        <ng-container *ngIf="allergenTags.length">
          <div class="d-flex flex-column justify-content-center gap-1 py-2">
            <ng-container *ngFor="let category of allergenCategories$ | async">
              <ng-container
                *ngIf="category | nutritionPreferenceCheck : allergenTags">
                <ng-container
                  *ngIf="
                    category
                      | nutritionPreferenceCheck
                        : (ingredient.content.allergen_tags
                            | ingredientAllergenTags : allergenTags)
                  ">
                  <app-filter-icon
                    [allergenCategory]="category"
                    [itemAllergenTags]="
                      ingredient.content.allergen_tags
                        | ingredientAllergenTags : (allergenTags$ | async)!
                    "></app-filter-icon>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <ng-container
    *ngIf="
      ingredient!.children && ingredient!.modifiers.length === 1 && isSelected
    ">
    <app-ingredient-submodifier-group
      class="w-100"
      [isEditMode]="isEditMode"
      [ingredientGroup]="ingredient.modifiers[0]"
      [selectedIngredients]="selectedIngredients"
      [isSelected]="isSelected"></app-ingredient-submodifier-group>
  </ng-container>
</button>

<ng-template #spicyPeppers>
  <small class="ingredient-price d-flex gap-2 w-100 font-weight-bold">
    <div class="">
      <img
        class="heat-icon"
        src="assets/images/icons/Icons/fb-pepper.svg"
        alt="" />
      <img
        class="heat-icon"
        src="assets/images/icons/Icons/fb-pepper.svg"
        alt="" />
      <img
        class="heat-icon"
        src="assets/images/icons/Icons/fb-pepper.svg"
        alt="" />
    </div>
    <ng-container *ngIf="ingredient!.cost">{{
      ingredient!.cost | currency
    }}</ng-container>
  </small>
</ng-template>

<ng-template #badgeContainer>
  <div
    class="grow-1 d-flex flex-row flex-wrap align-items-center align-content-center">
    <div class="ingredient-title d-flex w-100">{{ ingredient!.name }}</div>
    <div class="d-flex flex-row flex-nowrap align-items-center gap-2">
      <small class="ingredient-price d-flex w-100 font-weight-bold">
        {{ ingredient!.cost | currency }}
      </small>
      <div class="ml-auto">
        <span class="badge rounded-pill bg-primary text-black">Limited</span>
      </div>
    </div>
  </div>
</ng-template>

<!--<ng-template #upsellContainer>-->
<!--  &lt;!&ndash; <button type="button" class="ingredient-card-shell-upsell grow-1 p-0 w-100" [ngClass]="-->
<!--      status-->
<!--        ? 'ingredient-card-shell-upsell-selected'-->
<!--        : 'ingredient-card-shell-upsell'-->
<!--    ">-->
<!--    <div class="grow-1 d-flex flex-nowrap align-items-center gap-2" (click)="isCollapsed = !isCollapsed"-->
<!--      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic" (click)="showSuccess()">-->
<!--      <div class="">-->
<!--        <img class="ingredient-image" src="/assets/images/ingredients/ingredient-brisket.png" alt="" />-->
<!--      </div>-->
<!--      <div class="ingredient-info grow-1 d-flex flex-nowrap gap-2 h-100 pr-2">-->
<!--        <div class="grow-1 d-flex flex-row flex-wrap align-items-center align-content-center">-->
<!--          <h6 class="ingredient-title d-flex w-100">Ingredient Name</h6>-->
<!--          <small class="ingredient-price d-flex w-100 font-weight-bold">-->
<!--            $13.00&nbsp;<span class="extra-text" [ngClass]="extra ? 'd-flex' : 'd-none'">- Extra (+$4.00)</span>-->
<!--          </small>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="checkmark-overlay" [ngClass]="status ? 'd-flex' : 'd-none'">-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check-circle-fill"-->
<!--        viewBox="0 0 16 16">-->
<!--        <path-->
<!--          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />-->
<!--      </svg>-->
<!--    </div>-->

<!--    <div id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true" class="w-100">-->
<!--      <div class="d-flex flex-nowrap bg-light w-100 p-2 rounded-bottom">-->
<!--        <button class="minus-button btn btn-primary d-flex align-items-center justify-content-center w-100"-->
<!--          (click)="minus()">-->
<!--          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-dash"-->
<!--            viewBox="0 0 16 16">-->
<!--            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />-->
<!--          </svg>-->
<!--        </button>-->

<!--        <input type="text" class="form-control border border-primary rounded-0 text-center" [(ngModel)]="inputnumber" />-->

<!--        <button class="plus-button btn btn-primary d-flex align-items-center justify-content-center w-100"-->
<!--          (click)="plus()">-->
<!--          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-plus"-->
<!--            viewBox="0 0 16 16">-->
<!--            <path-->
<!--              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />-->
<!--          </svg>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </button> &ndash;&gt;-->

<!--  <button-->
<!--    routerLink="/order/customize"-->
<!--    type="button"-->
<!--    class="category-card ingredient-card-shell-upsell border-0 bg-white rounded w-100 p-0 pt-3">-->
<!--    <img-->
<!--      [src]="ingredient.content.image | cmsAsset"-->
<!--      class="card-img-top rounded"-->
<!--      [alt]="ingredient.name" />-->
<!--    <div-->
<!--      class="card-body w-100 d-flex flex-row flex-wrap text-center justify-content-center gap-2">-->
<!--      <h5 class="category-title w-100">Category Title</h5>-->
<!--      &lt;!&ndash; <button type="button" class="btn btn-outline-secondary w-100">Add</button> &ndash;&gt;-->
<!--    </div>-->
<!--  </button>-->
<!--</ng-template>-->
