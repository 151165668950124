import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { PageBlock } from '@models/index';

@Component({
  selector: 'app-block-headline',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './block-headline.component.html',
  styleUrls: ['block-headline.component.scss'],
})
export class BlockHeadlineComponent {
  @Input() pageBlock!: PageBlock;
}
