import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { GlobalStateModel } from '@models/index';
import { Store } from '@ngxs/store';
import { map, Observable, take } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomPageGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.store
      .select((state: GlobalStateModel) => state.content.customPages)
      .pipe(
        filter((customPages) => customPages !== null),
        take(1),
        map((customPages) => {
          const customPage = customPages!.find(
            (customPage) => customPage.slug === route.params['customPageID'],
          );
          if (
            customPage &&
            customPage?.page_blocks?.length &&
            customPage.page_blocks.length > 0
          ) {
            if (
              window.location.hostname.includes('freebirds.com') &&
              customPage.status !== 'published'
            ) {
              this.router.navigateByUrl('/404');
              return false;
            }
            return true;
          } else {
            this.router.navigateByUrl('/404');
            return false;
          }
        }),
      );
  }
}
