import { CouponCodeComponent } from './coupon-code/coupon-code.component';
import { SuggestedProductsModalComponent } from './suggested-products-modal/suggested-products-modal.component';
import { TipSelectionComponent } from './tip-selection/tip-selection.component';

export const components = [
  CouponCodeComponent,
  TipSelectionComponent,
  SuggestedProductsModalComponent,
];

export * from './coupon-code/coupon-code.component';
export * from './suggested-products-modal/suggested-products-modal.component';
export * from './tip-selection/tip-selection.component';
