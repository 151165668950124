import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OrderTypeSelectionComponent } from './components';
/* Containers */
import * as locationsContainers from './containers';
import { LocationsComponent } from './containers';
/* Guards */
import * as locationsGuards from './guards';
/* Module */
import { LocationsModule } from './locations.module';

/* Routes */
export const ROUTES: Routes = [
  {
    path: 'locations',
    canActivate: [],
    component: locationsContainers.LocationsComponent,
  },
  {
    path: 'locations/:slug',
    component: locationsContainers.LocationDetailsComponent,
    canActivate: [],
  },
  {
    path: 'start-order',
    canActivate: [],
    component: OrderTypeSelectionComponent,
  },
];

@NgModule({
  imports: [LocationsModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class LocationsRoutingModule {}
