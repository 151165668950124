<div class="modal-header rounded-top align-items-center">
  <img
    class="h4-birds"
    src="assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="freebirds birds icon"/>
  <ng-container *ngIf="product.name">
    <h4 class="modal-title grow-1">{{ product.name }}</h4>
  </ng-container>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1 d-flex flex-column gap-3">
  <ng-container *ngFor="let optionGroup of product.optiongroups">
    <!-- choose a size -->
    <div>
      <ng-container *ngIf="optionGroup.description">
        <h5 class="mb-3">{{ optionGroup.description }}</h5>
      </ng-container>
      <div class="d-flex flex-row gap-3">
        <ng-container *ngFor="let option of optionGroup.options">
          <button
            type="button"
            class="grow-1 basis-0 d-flex flex-column align-items-center text-center size-button"
            [class.selected]="option | selectedIngredient : selectedIngredients"
            (click)="ingredientSelect(option)">
            <div class="position-relative">
              <div class="checkmark-container ingredient">
                <div
                  class="checkmark-overlay"
                  [ngClass]="
                    (option | selectedIngredient : selectedIngredients)
                      ? 'd-flex'
                      : 'd-none'
                  ">
                  <div class="checkmark-animation">
                    <img
                      class="hat-checkmark"
                      alt="checkmark"
                      src="assets/images/location-specific_illustrations/Icons/fb-hat-orange.svg"/>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="option.content.image">
                <img
                class="size-image"
                [src]="option.content.image | cmsAsset"
                [alt]="option.name"/>
              </ng-container>
            </div>
            <ng-container *ngIf="option.content.badge_text && option.content.badge_color">
              <app-badge [ingredient]="option"></app-badge>
            </ng-container>
            <h6 class="size-name">{{ option.name }}</h6>
            <ng-container *ngIf="option.content.description">
              <small>{{option.content?.description}}</small>
            </ng-container>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <!-- upsell product -->
  <ng-container *ngIf="upsellProduct">
    <div>
      <h5 class="mb-3">Add {{ upsellProduct.name }}?</h5>
      <div class="d-flex flex-row gap-3">
        <div
          class="edit-group btn-group flex-grow-1 w-100"
          btnRadioGroup
          [(ngModel)]="addUpsell">
          <label
            class="btn btn-outline-secondary text-capitalize btn-sm grow-1 basis-0"
            btnRadio="0"
          >No</label
          >
          <label
            class="btn btn-outline-secondary text-capitalize btn-sm grow-1 basis-0"
            btnRadio="1"
          >Yes +{{ upsellProduct.cost | currency }}</label
          >
          <!--          <button-->
          <!--            class="btn btn-secondary text-capitalize btn-sm grow-1 basis-0"-->
          <!--            tabindex="0"-->
          <!--            role="button">-->
          <!--            -->
          <!--          </button>-->
          <!--          <button-->
          <!--            class="btn btn-outline-secondary text-capitalize btn-sm grow-1 basis-0"-->
          <!--            tabindex="0"-->
          <!--            role="button">-->
          <!--            -->
          <!--          </button>-->
        </div>
      </div>
    </div>
  </ng-container>
  <form [formGroup]="mealNameForm" class="w-100">
    <div class="d-flex flex-column gap-3 grow-1">
      <div class="form-floating">
        <input
          autofocus
          class="form-control w-100"
          type="text"
          name="name"
          id="name"
          formControlName="name"
          [maxlength]="32"
          placeholder="Name"/>
        <label for="name" id="name-label">Enter First Name</label>
        <ng-container *ngIf="name.touched && name.invalid">
          <small class="text-danger" *ngIf="name!.errors!['required'!]!"
          >Name Required</small
          >
        </ng-container>
      </div>
    </div>
  </form>

  <h6 class="mt-auto">
    Total:
    {{
    selectedIngredients
      | selectedOptionsTotal : (addUpsell === '1' ? upsellProduct : undefined)
      | currency
    }}
  </h6>
</div>
<div class="modal-footer rounded-bottom">
  <div class="d-flex flex-nowrap gap-3 m-0 w-100">
    <div class="d-flex flex-nowrap border border-primary incrementer">
      <button
        class="minus-button btn btn-sm btn-primary d-flex align-items-center justify-content-center"
        (click)="minus($event)"
        [class.disabled]="inputnumber === 1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          fill="currentColor"
          class="bi bi-dash"
          viewBox="0 0 16 16">
          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
        </svg>
      </button>

      <input
        type="text"
        class="form-control border-0 rounded-0 text-center p-0"
        [(ngModel)]="inputnumber"/>

      <button
        class="plus-button btn btn-sm btn-primary d-flex align-items-center justify-content-center"
        (click)="plus($event)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          fill="currentColor"
          class="bi bi-plus"
          viewBox="0 0 16 16">
          <path
            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>
      </button>
    </div>
    <app-button
      classes="btn btn-primary w-100"
      class="w-100"
      [disabled]="addingToBag"
      (buttonClick)="addToBag()"
      [isLoading]="addingToBag">
      Add to Bag
    </app-button>
  </div>
</div>
