import { Pipe, PipeTransform } from '@angular/core';
import { HandoffMode } from '@server/vendor/olo/interfaces';

@Pipe({
  name: 'handoffModeName',
})
export class HandoffModeNamePipe implements PipeTransform {
  transform(handoffMode: HandoffMode, short: boolean = false): string {
    switch (handoffMode) {
      case HandoffMode.PICKUP:
        return 'Pickup';
      case HandoffMode.CURBSIDE:
        return 'Curbside';
      case HandoffMode.DISPATCH:
        return 'Delivery';
      case HandoffMode.DELIVERY:
        return 'Delivery';
      case HandoffMode.DINE_IN:
        return 'Dine In';
      case HandoffMode.DRIVE_THRU:
        return short ? 'Pickup Lane' : 'Mobile Pickup Lane';
    }
  }
}
