<div class="card bg-white grow-1 h-100">
  <h2 class="card-header">Payment Methods</h2>
  <ng-container *ngIf="savedCards$ | async as savedCards">
    <ng-container *ngIf="savedCards.length; else noCards">
      <div class="card-body w-100 card-grid">
        <ng-container *ngFor="let card of savedCards">
          <app-saved-payment-method-card
            [card]="card"></app-saved-payment-method-card>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #noCards>
      <div class="card-body d-flex flex-column grow-1 align-items-center gap-3">
        <div class="d-flex w-100 justify-content-center">
          <img
            class="empty-icon"
            src="assets/images/icons/Icons/fb-garlic-sad.svg"
            alt="" />
        </div>

        <div class="lead">You currently do not have any saved cards.</div>

        <button type="button" class="btn btn-primary" routerLink="/start-order">
          Start Order
        </button>
      </div>
    </ng-template>
  </ng-container>
</div>
