import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QuickAddModalComponent } from '@common/components';
import { AppCommonService } from '@common/services';
import {
  AllergenCategory,
  AllergenTag,
  Contains,
  GlobalStateModel,
  Lifestyle,
  Order,
  PlantBased,
  Product,
  Restaurant,
  SavedProduct,
} from '@models/index';
import { SideWithSizeModalComponent } from '@modules/order/components';
import { OrderService } from '@modules/order/services';
import { Select } from '@ngxs/store';
import { collapseAnimation } from 'angular-animations';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['product-card.component.scss'],
  animations: [collapseAnimation()],
})
export class ProductCardComponent {
  containsPreferences$!: Observable<Contains[]>;
  @Select((state: GlobalStateModel) => state.order.order)
  order$!: Observable<Order>;
  @Select((state: GlobalStateModel) => state.locations.orderLocation)
  orderLocation$!: Observable<Restaurant>;
  @Select((state: GlobalStateModel) => state.nutrition.allergenCategories)
  allergenCategories$!: Observable<AllergenCategory[]>;
  @Select((state: GlobalStateModel) => state.nutrition.allergenTags)
  allergenTags$!: Observable<AllergenTag[]>;
  @Select((state: GlobalStateModel) => state.nutrition.selectedAllergenTags)
  selectedAllergenTags$!: Observable<AllergenTag[]>;

  title = 'angularbootstrap';
  inputnumber = 1;

  status: boolean = false;

  isCollapsed = true;
  radioModel = 'Left';
  @Input() product!: Product;
  @Input() savedProduct!: SavedProduct;
  @Input() selectedProduct?: Product;

  @Input() isSelected: boolean = false;

  @Input() productCardType:
    | 'upsell'
    | 'product'
    | 'size'
    | 'saved'
    | 'suggested' = 'product';

  @Input() isEditMode: boolean = false;
  @Input() isFeatured: boolean = false;

  @Output() productSelect = new EventEmitter<void>();

  addLoading: boolean = false;

  constructor(
    private toastr: ToastrService,
    private orderService: OrderService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private appCommon: AppCommonService,
  ) {}

  showSuccess() {
    this.status = !this.status;
  }

  productSelected() {
    if (
      !(
        this.isEditMode &&
        this.product.chainproductid !== this.selectedProduct?.chainproductid
      )
    ) {
      this.productSelect.emit();
    }
  }

  plus(event: Event) {
    event.stopPropagation();
    this.inputnumber = this.inputnumber + 1;
  }

  minus(event: Event) {
    event.stopPropagation();
    if (this.inputnumber > 1) {
      this.inputnumber = this.inputnumber - 1;
    } else {
      this.showSuccess();
    }
  }

  async addToCart(event: Event) {
    event.stopPropagation();
    let product = this.product;
    if (
      this.productCardType === 'product' ||
      this.productCardType === 'upsell'
    ) {
      if (!Object.hasOwn(this.product, 'optiongroups')) {
        const productRes = await this.orderService
          .getProductWithOptionGroups(this.product.chainproductid)
          .toPromise();
        if (productRes) {
          product = productRes;
        }
      }
    }
    if (
      (this.productCardType === 'product' ||
        this.productCardType === 'upsell') &&
      !!product!.optiongroups?.length
    ) {
      this.modalService.show(SideWithSizeModalComponent, {
        initialState: {
          product: product,
          quantity: this.inputnumber,
        },
      });
    } else {
      this.addLoading = true;
      this.orderService
        .addToOrder(this.product.id, this.inputnumber, [])
        .subscribe({
          next: () => {
            this.addLoading = false;
            this.toastr.success(
              `${this.inputnumber}x ${this.product.name} added to cart`,
              'Success!',
              {
                timeOut: 2500,
              },
            );
            this.status = !this.status;
            this.isCollapsed = true;
            this.inputnumber = 1;
          },
          error: (error) => {
            this.addLoading = false;
            this.toastr.error(
              error.error?.message ? error.error.message : error.message,
              'Error',
              {
                timeOut: 2500,
              },
            );
          },
        });
    }
  }

  addSavedProductToCart(event: Event) {
    event.stopPropagation();
    this.addLoading = true;
    this.orderService
      .addToOrderByChainID(
        Number(this.savedProduct.product.chainproductid),
        this.inputnumber,
        this.savedProduct.product.choices.map((choice) => ({
          ...choice,
          chainchoiceid: Number(choice.chainchoiceid),
          customfields: [],
          choices: [],
        })),
        this.savedProduct.product.specialinstructions,
        this.savedProduct.product.recipient,
      )
      .subscribe({
        next: () => {
          this.addLoading = false;
          this.toastr.success(
            `${this.inputnumber}x ${this.savedProduct.name} added to cart`,
            'Success!',
            {
              timeOut: 2500,
            },
          );
          this.status = !this.status;
          this.isCollapsed = true;
          this.inputnumber = 1;
        },
        error: (error) => {
          console.log(error);
          this.addLoading = false;

          this.toastr.error(
            error?.error?.message ? error.error.message : error.message,
            'Error',
            {
              timeOut: 2500,
            },
          );
        },
      });
  }

  deleteSavedProduct(event: Event) {
    event.stopPropagation();
    this.orderService
      .deleteSavedProduct(this.savedProduct.id)
      .subscribe(() => {});
  }

  openQuickAddModal(event: Event, product: Product) {
    event.stopPropagation();
    this.appCommon
      .getProduct(product.chainproductid)
      .subscribe((fullProduct) => {
        this.bsModalRef = this.modalService.show(QuickAddModalComponent, {
          initialState: {
            product: fullProduct,
          },
        });
      });
  }

  routeToCustomize(product: Product) {
    const category = this.appCommon.getProductCategory(product.id)!;
    this.appCommon.navigateToProduct(category, product, true);
  }
}
