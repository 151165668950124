import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseDeliveryAddress } from '@server/vendor/olo/interfaces';
import { RetrieveDirectionsResponse } from '@services/mapbox/models/directions-response.model';
import { GeocodingResponse } from '@services/mapbox/models/geocoding-response.model';
import { LngLat } from 'mapbox-gl';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Cacheable } from 'ts-cacheable';

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  private baseUrl = 'https://api.mapbox.com';
  private geocodeEndpoint = 'search/geocode/v6';
  private directionsEndpoint = 'directions/v5/mapbox';

  constructor(private http: HttpClient) {}

  private getMapboxToken() {
    return environment.mapboxToken;
  }

  @Cacheable()
  geocode(address: ResponseDeliveryAddress): Observable<GeocodingResponse> {
    const resource = `${this.baseUrl}/${this.geocodeEndpoint}/forward`;
    const queryParams = new HttpParams({
      fromObject: {
        address_line1: address.streetaddress,
        place: address.city,
        postcode: address.zipcode,
        country: 'US',
        access_token: this.getMapboxToken(),
      },
    });
    return this.http.get<GeocodingResponse>(resource, { params: queryParams });
  }

  getDirections(
    origin: LngLat,
    destination: LngLat,
  ): Observable<RetrieveDirectionsResponse> {
    const resource = `${this.baseUrl}/${this.directionsEndpoint}/driving/${origin.lng},${origin.lat};${destination.lng},${destination.lat}`;
    const queryParams = new HttpParams({
      fromObject: {
        access_token: this.getMapboxToken(),
        alternatives: false,
        geometries: 'geojson',
        overview: 'full',
        steps: false,
        notifications: 'none',
      },
    });
    return this.http.get<RetrieveDirectionsResponse>(resource, {
      params: queryParams,
    });
  }
}
