import { Pipe, PipeTransform } from '@angular/core';
import { NutritionInfo } from '@modules/order/models';
import { ChartConfiguration } from 'chart.js';

@Pipe({
  name: 'nutritionInfoGraphCalculator',
})
export class NutritionInfoGraphCalculatorPipe implements PipeTransform {
  transform(
    info: NutritionInfo,
  ): ChartConfiguration<'doughnut'>['data']['datasets'] {
    return [
      {
        data: [info.totalFat, info.protein, info.carbohydrates],
        backgroundColor: ['#4b9959', '#4188dc', '#d72323'],
        hoverBackgroundColor: ['#4b9959', '#4188dc', '#d72323'],
        hoverBorderColor: ['#4b9959', '#4188dc', '#d72323'],
      },
    ];
  }
}
