import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FAQ, FAQSectionsQuestions } from '@models/index';

@Component({
  selector: 'app-question-group',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './question-group.component.html',
  styleUrls: ['question-group.component.scss'],
})
export class QuestionGroupComponent {
  @Input() questions: FAQSectionsQuestions[] = [];
}
