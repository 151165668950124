<div class="flex-grow-1 w-100 d-flex flex-column">
  <!-- Search -->
  <app-search-bar
    [isLocationsPage]="true"
    style="position: sticky; top: 0; left: 0; z-index: 2; width: 100%"
    [geolocationAllowed]="geolocationAllowed"
    (geolocationClick)="geolocationClick.emit()"
    (searchRan)="searchRan.emit()"></app-search-bar>

  <!-- Favorite Locations -->
  <ng-container *ngIf="favoriteLocations && favoriteLocations.length > 0">
    <h4 class="favorite-header" [@fadeInUpOnEnter]>Favorite Locations</h4>
    <ng-container *ngFor="let favorite of favoriteLocations; trackBy: favoriteLocationTrackBy">
      <app-location-card
        [favoriteLocation]="favorite"
        [favoriteLocations]="favoriteLocations"
        (locationSelected)="selectLocation($event)"
        [@fadeInUpOnEnter]></app-location-card>
    </ng-container>
  </ng-container>

  <!-- Nearest Locations -->
  <ng-container *ngIf="!isLoading; else loading">
    <ng-container *ngIf="mapLocations; else noLocations">
      <div class="location-list-view" [@fadeInUpOnEnter]>
        <div class="d-flex flex-row align-items-start flex-grow-1 p-3">
          <div class="d-flex flex-column gap-1 flex-grow-1">
            <h4 class="flex-grow-1" [@fadeInUpOnEnter]>Nearest Locations</h4>
            <small class="d-flex flex-row gap-2">
              <div class="font-weight-bold">Filters:</div>
              <ng-container *ngIf="showCateringOnly">
                <div class="badge badge-info badge-pill d-flex cursor-pointer align-items-center" (click)="toggleCateringOnly()">Catering</div>
              </ng-container>
              <ng-container *ngIf="showMobilePickupOnly">
                <div class="badge badge-dark badge-pill d-flex cursor-pointer align-items-center" (click)="toggleMobilePickupOnly()">Mobile Pickup Lane</div>
              </ng-container>
              <ng-container *ngIf="!showCateringOnly && !showMobilePickupOnly">
                <div>None</div>
              </ng-container>
            </small>
          </div>
          <div class="btn-group" dropdown [insideClick]="true">
            <button type="button" id="button-animated" dropdownToggle aria-controls="dropdown-animated" class="btn btn-outline-secondary rounded-circle d-flex align-items-center justify-content-center p-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filter" viewBox="0 0 16 16">
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
              </svg>
            </button>
            <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-filters" role="menu" aria-labelledby="button-animated">
              <h6 class="mb-1">Filters</h6>
              <li role="menuitem" class="d-flex flex-row mb-1 cursor-pointer">
                <div class="custom-control custom-checkbox d-flex flex-grow-1 cursor-pointer">
                  <input [(ngModel)]="showCateringOnly" (click)="toggleCateringOnly()" type="checkbox" class="custom-control-input cursor-pointer" id="showCatering"/>
                  <label class="custom-control-label w-100 text-nowrap cursor-pointer" for="showCatering">Catering</label>
                </div>
              </li>
              <li role="menuitem" class="d-flex flex-row cursor-pointer">
                <div class="custom-control custom-checkbox d-flex flex-grow-1 cursor-pointer">
                  <input [(ngModel)]="showMobilePickupOnly" (click)="toggleMobilePickupOnly()" type="checkbox" class="custom-control-input cursor-pointer" id="showMobilePickup"/>
                  <label class="custom-control-label w-100 text-nowrap cursor-pointer" for="showMobilePickup">Mobile Pickup Lane</label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <ng-container *ngFor="let location of mapLocations; trackBy: mapLocationTrackBy">
          <app-location-card
            *ngIf="(!showCateringOnly || location.content.supports_catering) && (!showMobilePickupOnly || location.supportsdrivethru)"
            [location]="location"
            [favoriteLocations]="favoriteLocations"
            (locationSelected)="selectLocation($event)"
            [@fadeInUpOnEnter]></app-location-card>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #noLocations>
      <section class="h-100 cart-body d-flex align-items-center justify-content-center w-100 flex-grow-1 text-nowrap">
        <div class="d-flex flex-column gap-3 text-center">
          <h3>No Locations Nearby</h3>
          <div class="d-flex justify-content-center">
            <img class="w-50" src="/assets/images/ingredient_illustrations/Icons/fb-sleeping-pepper.svg" alt="" />
          </div>
          <div>Search for a different location.</div>
        </div>
      </section>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <section class="h-100 cart-body d-flex align-items-center justify-content-center w-100 flex-grow-1 text-nowrap">
      <div class="d-flex flex-column gap-3 text-center">
        <h3>Loading...</h3>
        <div class="d-flex justify-content-center">
          <img class="w-50" src="assets/images/icons/Icons/freebirds-walking-burrito-filled.svg" alt="Freebirds walking burrito icon"/>
        </div>
        <div>Hang tight while we find your Freebirds.</div>
      </div>
    </section>
  </ng-template>
</div>
