import { Pipe, PipeTransform } from '@angular/core';
import { AllergenTag } from '@models/index';

@Pipe({
  name: 'allergenTagsToNameArray',
  pure: false,
})
export class AllergenTagsToNameArrayPipe implements PipeTransform {
  transform(tags: AllergenTag[]): string[] {
    return tags.map((tag) => tag.allergen_tag_name!);
  }
}
