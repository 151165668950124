import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Order, TipSettings } from '@models/index';
import { CheckoutService } from '@modules/checkout/services';
import { HandoffMode } from '@server/vendor/olo/interfaces';

@Component({
  selector: 'app-tip-selection',
  templateUrl: './tip-selection.component.html',
  styleUrls: ['tip-selection.component.scss'],
})
export class TipSelectionComponent implements OnInit, OnChanges {
  @Input() order!: Order;
  @Input() tipSettings!: TipSettings;

  tipForm: FormGroup = new FormGroup({
    customTip: new FormControl(0, []),
  });

  constructor(private checkout: CheckoutService) {}
  ngOnInit() {
    this.updateTip(this.order.tip);
    if (this.order.tip === 0) {
      this.applyTip(
        this.order.subtotal *
          (this.order.deliverymode === HandoffMode.DISPATCH
            ? this.tipSettings?.default_delivery_tip ?? 0.2
            : this.tipSettings?.default_pickup_tip ?? 0.2),
      );
    }
  }

  ngOnChanges() {
    this.updateTip(this.order.tip);
  }

  applyTip(amount: number | string) {
    this.checkout.applyTip(
      this.order.id,
      typeof amount === 'string'
        ? Number(amount.substring(amount.indexOf('$') + 1))
        : amount,
    );
  }

  private updateTip(amount: number) {
    this.tipForm.setValue({
      customTip: amount,
    });
  }

  get customTip(): AbstractControl {
    return this.tipForm.get('customTip')!;
  }

  public readonly HandoffMode = HandoffMode;
}
