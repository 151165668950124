import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GroupOrder } from '@models/index';
import { GoCancelModalComponent } from '@modules/order/components';
import { OloDatePipe } from '@pipes/olo-date.pipe';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-go-edit',
  templateUrl: './go-edit.component.html',
  styleUrls: ['go-edit.component.scss'],
})
export class GoEditComponent implements OnInit {
  @Input() groupOrder!: GroupOrder;
  @Input() availableDeadlineTimes: Date[] = [];

  @Output() updateClicked = new EventEmitter<{
    deadline: Date;
    note?: string;
  }>();
  bsModalRef?: BsModalRef;

  groupOrderForm: FormGroup = new FormGroup({
    deadline: new FormControl(null, [Validators.required]),
    note: new FormControl(null),
  });

  constructor(
    private modalService: BsModalService,
    private oloDatePipe: OloDatePipe,
  ) {}
  ngOnInit() {
    this.groupOrderForm.setValue({
      deadline: this.oloDatePipe.transform(this.groupOrder.deadline),
      note: this.groupOrder.note,
    });
    console.log(this.groupOrderForm);
  }

  openGOCancelModal() {
    this.bsModalRef = this.modalService.show(GoCancelModalComponent, {});
  }

  updateClick() {
    this.groupOrderForm.markAllAsTouched();
    if (this.groupOrderForm.valid) {
      this.updateClicked.emit({
        deadline: this.deadline.value,
        note: this.note.value,
      });
    }
  }

  get deadline(): AbstractControl {
    return this.groupOrderForm.get('deadline')!;
  }

  get note(): AbstractControl {
    return this.groupOrderForm.get('note')!;
  }
}
