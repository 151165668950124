<div class="add-shell d-flex">
  <div class="d-flex flex-column w-100">
    <div class="ingredients-title d-flex p-3 w-100">
      <h5>Ingredients</h5>
    </div>
    <div
      class="ingredients-added-shell grow-1 d-flex flex-row flex-wrap align-content-start gap-2 p-3">
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
      <app-ingredient-pill></app-ingredient-pill>
    </div>
    <div class="add-cta p-3 bg-light">
      <button
        type="button"
        class="btn btn-primary w-100"
        (click)="openMealNameModal()">
        Add to Bag
      </button>
    </div>
  </div>
</div>
