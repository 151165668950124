import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalStateModel, GroupOrder } from '@models/index';
import { Select, Store } from '@ngxs/store';
import { LeaveGroupOrder } from '@store/actions/order.actions';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-go-leave-modal',
  templateUrl: './go-leave-modal.component.html',
  styleUrls: ['go-leave-modal.component.scss'],
})
export class GoLeaveModalComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.order.groupOrder)
  groupOrder$!: Observable<GroupOrder>;
  leaveLoading = false;
  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private store: Store,
  ) {}
  ngOnInit() {}

  leaveGroupOrder() {
    this.leaveLoading = true;
    this.store.dispatch(new LeaveGroupOrder()).subscribe(() => {
      this.leaveLoading = false;
      this.bsModalRef.hide();
      this.router.navigate(['/']);
    });
  }
}
