import { Pipe, PipeTransform } from '@angular/core';
import { Jumbotron, JumbotronLayout } from '@models/index';
import * as moment from 'moment';

@Pipe({
  name: 'jumbotronTimeFilter',
})
export class JumbotronTimeFilterPipe implements PipeTransform {
  transform(
    jumbotrons: Array<Jumbotron | undefined> | undefined,
  ): JumbotronLayout[] {
    if (!jumbotrons) {
      return [];
    }
    return (
      jumbotrons.filter((jumbotron) => jumbotron !== undefined) as Jumbotron[]
    )
      .filter((jumbotron) => {
        // check is after start time and before end time
        const now = moment();
        if (jumbotron.item!.start_time && jumbotron.item!.end_time) {
          const startTime = moment(jumbotron.item!.start_time, 'HH:mm:ss');
          const endTime = moment(jumbotron.item!.end_time, 'HH:mm:ss');
          return now.isAfter(startTime) && now.isBefore(endTime);
        } else {
          return true;
        }
      })
      .filter((jumbotron) =>
        jumbotron!.item!.days_shown!.includes(
          // @ts-ignore
          moment().format('dddd').toLowerCase(),
        ),
      )
      .map((i) => i!.item) as JumbotronLayout[];
  }
}
