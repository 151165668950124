  <div class="p-3" [ngClass]="isLocationsPage ? 'search-bar-locations' : 'search-bar'">
    <div class="input-group flex-nowrap">
      <div class="form-floating grow-1">
        <div
          #searchBar
          class="w-100 form-control form-left"
          id="locationSearchForm"
          aria-label="Enter your city, state or ZIP"></div>
        <!--      <label for="locationSearchForm">Enter your city, state or ZIP</label>-->
      </div>
      <button
        routerLink="/locations"
        class="btn btn-secondary d-flex align-items-center form-btn-right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-search"
          viewBox="0 0 16 16">
          <path
            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
        </svg>
      </button>
    </div>
    <div [@collapse]="!geolocationAllowed">
      <button class="btn btn-sm px-0 py-0 mt-2" (click)="geolocationClick.emit()"><img src="assets/images/icons/Icons/fb-geo-icon.svg" alt="Geolocate">&nbsp;Find My Location
      </button>
    </div>
  </div>

<!--<ng-template #isStartOrdersPage>-->
<!--  <div class="search-bar p-3">-->
<!--    <div class="input-group flex-nowrap">-->
<!--      <div class="form-floating grow-1">-->
<!--        <div-->
<!--          #searchBar-->
<!--          class="w-100 form-control form-left"-->
<!--          id="locationSearchForm"-->
<!--          aria-label="Enter your city, state or ZIP"></div>-->
<!--        &lt;!&ndash;      <label for="locationSearchForm">Enter your city, state or ZIP</label>&ndash;&gt;-->
<!--      </div>-->
<!--      <button-->
<!--        routerLink="/locations"-->
<!--        class="btn btn-secondary d-flex align-items-center form-btn-right">-->
<!--        <svg-->
<!--          xmlns="http://www.w3.org/2000/svg"-->
<!--          width="16"-->
<!--          height="16"-->
<!--          fill="currentColor"-->
<!--          class="bi bi-search"-->
<!--          viewBox="0 0 16 16">-->
<!--          <path-->
<!--            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>-->
<!--        </svg>-->
<!--      </button>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->
