import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['button.component.scss'],
})
export class ButtonComponent {
  /**
   * List of classes to be applied to the button.
   *
   * IMPORTANT!!! The classes sent must be global. Component style sheets are scoped and therefore cannot be used in other components
   */
  @Input() classes: string = 'btn btn-primary';
  @Input() isLoading: boolean = false;
  @Input() disabled: boolean = false;

  @Output() buttonClick = new EventEmitter<any>();
}
