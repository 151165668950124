<div>
  <app-navbar
    navbarOrientation="minimal"
    [minimalBGColor]="
      currentCategoryID === 'saved-products'
        ? (homepage$ | async)?.my_favorites_background_color
        : (category$ | async)?.content?.background_color
    "
    [showAnnouncement]="false"></app-navbar>
  <ng-container *ngIf="!isEditMode">
    <app-categories-list-bar
      [menu]="(menu$ | async)!"
      [category]="(category$ | async)!"
      [menuLocation]="(orderLocation$ | async)!"
      [savedProducts]="savedProducts$ | async"
      [currentCategoryID]="currentCategoryID"></app-categories-list-bar>
  </ng-container>
  <div class="body-container" [@toggle]="isVisible ? 'open' : 'closed'">
    <app-customize-product-header
      [isSavedProduct]="isSavedProducts"
      [category]="(category$ | async)!"
      [selectedIngredients]="
        (productCustomization.currentlySelected$ | async)!
      "></app-customize-product-header>
    <ng-container
      *ngIf="
        (category$ | async)?.content?.display_as_product && !isSavedProducts
      ">
      <div class="sticky-container">
        <app-customize-ingredient-group-scroll
          [product]="product$ | async"
          [ingredientList]="ingredientList">
        </app-customize-ingredient-group-scroll>
      </div>
    </ng-container>

    <div class="d-flex flex-row flex-nowrap">
      <div class="customize-shell">
        <div class="d-flex flex-column w-100">
          <div
            class="group-container-shell container px-0 px-md-3 d-flex flex-column pt-0 pt-sm-3 pb-5">
            <ng-container *ngIf="!isSavedProducts; else savedProducts">
              <app-nutrition-preferences></app-nutrition-preferences>
              <ng-container
                *ngIf="
                  (category$ | async)?.content?.display_as_product;
                  else nonProduct
                ">
                <ng-container
                  *ngTemplateOutlet="customizeProducts"></ng-container>
              </ng-container>
              <ng-template #nonProduct>
                <ng-container
                  *ngTemplateOutlet="directToCartItems"></ng-container>
              </ng-template>
            </ng-container>
            <ng-template #savedProducts>
              <ng-container
                *ngTemplateOutlet="savedProductsContainer"></ng-container>
            </ng-template>
            <!--          <div class="d-flex flex-column gap-3 pt-3 pt-sm-0">-->
            <!--            <app-product-group-container-->
            <!--              [productGroupType]="'upsell'"></app-product-group-container>-->
            <!--          </div>-->
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="product$ | async as product">
      <ng-container
        *ngIf="
          product
            | productCustomizationCompletion
              : (productCustomization.currentlySelected$
                  | async)! as productCompletion
        ">
        <ng-container
          *ngIf="
            productCustomization.currentlySelected$ | async as currentSelections
          ">
          <app-customize-add-cta
            *ngIf="(category$ | async)?.content?.display_as_product"
            [product]="product"
            [incompleteSections]="productCompletion.incompleteSections"
            [options]="currentSelections"
            [isEditMode]="isEditMode"
            [basketProductID]="basketProductID!"></app-customize-add-cta>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <ng-template #customizeProducts>
    <ng-container *ngIf="category$ | async as category">
      <app-product-group-container
        [category]="category"
        [products]="category.products"
        [selectedProduct]="(product$ | async)!"
        [isEditMode]="isEditMode"
        [productGroupType]="
          category.content.display_as_product ? 'size' : 'product'
        "
        (productSelect)="
          setProduct($event.chainproductid)
        "></app-product-group-container>
      <div id="ingredient-start"></div>
      <ng-container *ngIf="product$ | async as product">
        <ng-container
          *ngFor="
            let optionGroup of product.optiongroups;
            let i = index;
            trackBy: optionGroupTracker
          ">
          <app-ingredient-group-container
            ingredientGroupType="customizable"
            [selectedIngredients]="
              (productCustomization.currentlySelected$ | async)!
            "
            [ingredientGroup]="optionGroup"
            [isEditMode]="isEditMode"
            [ingredientList]="ingredientList"></app-ingredient-group-container>
        </ng-container>
        <ng-container *ngIf="upsells$ | async as upsells">
          <div
            *ngIf="upsells.length"
            class="w-100 d-flex flex-column gap-3 pt-3">
            <ng-container
              *ngFor="
                let subcategory of upsells
                  | upsellSubcategories : category.content.is_entree_category
              ">
              <app-product-group-container
                [category]="category"
                [products]="subcategory.products"
                [subcategoryName]="subcategory.name | subcategoryName"
                productGroupType="upsell"
                (productSelect)="
                  setProduct($event.chainproductid)
                "></app-product-group-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #directToCartItems>
    <ng-container *ngIf="category$ | async as category">
      <ng-container *ngIf="category | subcategory as subcategories">
        <ng-container *ngIf="subcategories.length; else noSubcategories">
          <ng-container *ngFor="let subcategory of subcategories">
            <app-product-group-container
              [category]="category"
              [products]="subcategory.products"
              [subcategoryName]="subcategory.name | subcategoryName"
              [productGroupType]="
                category.content.display_as_product ? 'size' : 'product'
              "
              (productSelect)="
                setProduct($event.chainproductid)
              "></app-product-group-container>
          </ng-container>
        </ng-container>
        <ng-template #noSubcategories>
          <app-product-group-container
            [category]="category"
            [products]="category.products"
            [productGroupType]="
              category.content.display_as_product ? 'size' : 'product'
            "
            (productSelect)="
              setProduct($event.chainproductid)
            "></app-product-group-container>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-template>
</div>

<ng-template #savedProductsContainer>
  <app-product-group-container
    productGroupType="saved"
    [savedProducts]="(savedProducts$ | async)!"></app-product-group-container>
</ng-template>

<button
  *ngIf="!isAtTop"
  (click)="scrollToTop()"
  class="scroll-to-top-button"
  [class.scroll-button-position]="
    !(category$ | async)?.content?.display_as_product
  "
  [@slideInRightOnEnter]
  [@slideOutRightOnLeave]
  [@fadeOutOnLeave]
  [@fadeInOnEnter]>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-arrow-up"
    viewBox="0 0 16 16">
    <path
      fill-rule="evenodd"
      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
  </svg>
</button>
