import { Injectable } from '@angular/core';
import {
  Category,
  Ingredient,
  IngredientGroup,
  Product,
  Restaurant,
} from '@models/index';
import { CmsAssetPipe } from '@pipes/cms-asset.pipe';
import { GetMenuResponse } from '@server/menu/responses';
import {
  FoodEstablishment,
  Menu,
  MenuItem,
  MenuSection,
  NutritionInformation,
  Organization,
  Restaurant as SchemaRestaurant,
} from 'schema-dts';

@Injectable({
  providedIn: 'root',
})
export class SchemaMarkupService {
  constructor(private cmsAssetPipe: CmsAssetPipe) {}

  restaurantToSchemaRestaurant(
    restaurant: Restaurant,
    menu: GetMenuResponse,
  ): SchemaRestaurant {
    return <SchemaRestaurant>{
      '@context': 'http://schema.org',
      '@type': 'Restaurant',
      name: restaurant.name,
      image: [
        'https://www.freebirds.com/assets/images/logos/Freebirds_Logos_Digital_PrimaryLogo_Carbon_RGB.svg',
      ],
      priceRange: '$',
      telephone: restaurant.telephone,
      url: `https://www.freebirds.com/locations/${restaurant.slug}`,
      hasMenu: this.menuToSchemaMenu(menu),
      servesCuisine: 'Mexican',
      acceptsReservations: 'false',
      address: {
        '@type': 'PostalAddress',
        streetAddress: restaurant.streetaddress,
        addressLocality: restaurant.city,
        addressRegion: restaurant.state,
        postalCode: restaurant.zip,
        addressCountry: 'US',
        contactType: 'general',
      },
      openingHoursSpecification: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ],
          opens: '10:30',
          closes: '21:30',
        },
      ],
    };
  }

  menuToSchemaMenu(menu: GetMenuResponse): Menu {
    return <Menu>{
      '@type': 'Menu',
      hasMenuSection: menu.categories
        ? menu.categories.map((category) =>
            this.categoryToMenuSection(category as Category),
          )
        : undefined,
    };
  }

  homePageMarkup(menu: GetMenuResponse): FoodEstablishment {
    return <FoodEstablishment>{
      '@context': 'http://schema.org',
      '@type': 'FoodEstablishment',
      name: 'Freebirds World Burrito',
      image: [
        'https://www.freebirds.com/assets/images/logos/Freebirds_Logos_Digital_PrimaryLogo_Carbon_RGB.svg',
      ],
      priceRange: '$',
      servesCuisine: 'Mexican',
      acceptsReservations: 'false',
      currenciesAccepted: 'USD',
      hasMenu: this.menuToSchemaMenu(menu),
    };
  }

  private categoryToMenuSection(category: Category): MenuSection {
    return <MenuSection>{
      '@type': 'MenuSection',
      name: category.name,
      description: category.description,
      image: category.content.image
        ? this.cmsAssetPipe.transform(category.content.image)
        : undefined,
      hasMenuItem: category.products
        ? category.products.map((product) =>
            this.productToMenuItem(product as Product),
          )
        : undefined,
    };
  }

  private productToMenuItem(product: Product): MenuItem {
    return <MenuItem>{
      '@type': 'MenuItem',
      name: product.name,
      description: product.description,
      image: product.content.image
        ? this.cmsAssetPipe.transform(product.content.image)
        : undefined,
      menuAddOn: product.optiongroups
        ? product.optiongroups.map((modifierGroup) =>
            this.ingredientGroupToMenuSection(modifierGroup as IngredientGroup),
          )
        : undefined,
      nutrition: <NutritionInformation>{
        '@type': 'NutritionInformation',
        calories:
          product.content.calories !== null
            ? `${product.content.calories} calories`
            : undefined,
        carbohydrateContent:
          product.content.carbohydrates !== null
            ? `${product.content.carbohydrates} grams`
            : undefined,
        cholesterolContent:
          product.content.cholesterol !== null
            ? `${product.content.cholesterol} milligrams`
            : undefined,
        fatContent:
          product.content.total_fat !== null
            ? `${product.content.total_fat} grams`
            : undefined,
        transFatContent:
          product.content.trans_fat !== null
            ? `${product.content.trans_fat} grams`
            : undefined,
        saturatedFatContent:
          product.content.saturated_fat !== null
            ? `${product.content.saturated_fat} grams`
            : undefined,
        sodiumContent:
          product.content.sodium !== null
            ? `${product.content.sodium} milligrams`
            : undefined,
        sugarContent:
          product.content.sugars !== null
            ? `${product.content.sugars} grams`
            : undefined,
        proteinContent:
          product.content.protein !== null
            ? `${product.content.protein} grams`
            : undefined,
        fiberContent:
          product.content.dietary_fiber !== null
            ? `${product.content.dietary_fiber} grams`
            : undefined,
      },
    };
  }

  private ingredientGroupToMenuSection(
    ingredientGroup: IngredientGroup,
  ): MenuSection {
    return <MenuSection>{
      '@type': 'MenuSection',
      name: ingredientGroup.description,
      image: undefined,
      hasMenuItem: ingredientGroup.options
        ? ingredientGroup.options.map((ingredient) =>
            this.ingredientToMenuItem(ingredient as Ingredient),
          )
        : undefined,
    };
  }

  private ingredientToMenuItem(ingredient: Ingredient): MenuItem {
    return <MenuItem>{
      '@type': 'MenuItem',
      name: ingredient.name,
      image: ingredient.content.image
        ? this.cmsAssetPipe.transform(ingredient.content.image)
        : undefined,
      menuAddOn: ingredient.modifiers
        ? ingredient.modifiers.map((modifierGroup) =>
            this.ingredientGroupToMenuSection(modifierGroup as IngredientGroup),
          )
        : undefined,
      nutrition: <NutritionInformation>{
        '@type': 'NutritionInformation',
        calories:
          ingredient.content.calories !== null
            ? `${ingredient.content.calories} calories`
            : undefined,
        carbohydrateContent:
          ingredient.content.carbohydrates !== null
            ? `${ingredient.content.carbohydrates} grams`
            : undefined,
        cholesterolContent:
          ingredient.content.cholesterol !== null
            ? `${ingredient.content.cholesterol} milligrams`
            : undefined,
        fatContent:
          ingredient.content.total_fat !== null
            ? `${ingredient.content.total_fat} grams`
            : undefined,
        transFatContent:
          ingredient.content.trans_fat !== null
            ? `${ingredient.content.trans_fat} grams`
            : undefined,
        saturatedFatContent:
          ingredient.content.saturated_fat !== null
            ? `${ingredient.content.saturated_fat} grams`
            : undefined,
        sodiumContent:
          ingredient.content.sodium !== null
            ? `${ingredient.content.sodium} milligrams`
            : undefined,
        sugarContent:
          ingredient.content.sugars !== null
            ? `${ingredient.content.sugars} grams`
            : undefined,
        proteinContent:
          ingredient.content.protein !== null
            ? `${ingredient.content.protein} grams`
            : undefined,
        fiberContent:
          ingredient.content.dietary_fiber !== null
            ? `${ingredient.content.dietary_fiber} grams`
            : undefined,
      },
    };
  }
}
