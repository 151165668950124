<div class="py-5 container position-relative">
  <img
    class="faq-icon"
    src="../../../../assets/images/ingredient_illustrations/Icons/fb-lemon.svg" />
  <div
    class="d-flex flex-column gap-3 w-100 align-items-center justify-content-center">
    <h2 class="text-center">Still Have Questions?</h2>
    <app-button
      classes="btn btn-primary btn-lg w-100"
      style="min-width: 200px"
      routerLink="/faq">
      FAQ
    </app-button>
  </div>
</div>
