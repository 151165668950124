export enum EpsilonPromoTypes {
  HOMEPAGE = 1,
  PRODUCT_DETAIL = 5,
  CART = 6,
  SEARCH_RESULTS = 7,
  STORE_LOCATOR = 10,
  REWARDS_PAGE = 11,
  GIFT_CARD_PAGE = 12,
  NUTRITION_PAGE = 13,
  ACCOUNT_SIGNUP = 941,
  ACCOUNT_CENTER_PAGES = 942,
  TRANSACTION = 100,
  LOYALTY_JOIN_NOW = 101,
}
