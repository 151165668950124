import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ActivatedRouteSnapshot,
  RouterModule,
  RouterStateSnapshot,
} from '@angular/router';
import { AppShellComponent } from '@app/app-shell/app-shell.component';
import { AppCommonModule } from '@common/app-common.module';
import { CustomPageComponent } from '@common/containers';
import { CustomPageGuard } from '@common/guards/custom-page.guard';
import { CustomPageResolver } from '@common/resolvers/custom-page.resolver';
import { BrochureRoutingModule } from '@modules/brochure/brochure-routing.module';
import { StyleGuideRoutingModule } from '@modules/style-guide/style-guide-routing.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { InitializeMenu } from '@store/actions/menu.actions';
import { AppState } from '@store/state/app.state';
import { ContentState } from '@store/state/content.state';
import { LocationState } from '@store/state/locations.state';
import { MenuState } from '@store/state/menu.state';
import { NutritionState } from '@store/state/nutrition.state';
import { OrderState } from '@store/state/order.state';
import { UserState } from '@store/state/user.state';
import { NgChartsModule } from 'ng2-charts';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar';
import { SegmentModule } from 'ngx-segment-analytics';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { Observable } from 'rxjs';
// routing modules
import { AuthRoutingModule } from 'src/modules/auth/auth-routing.module';
import { CheckoutRoutingModule } from 'src/modules/checkout/checkout-routing.module';
import { HomeRoutingModule } from 'src/modules/home/home-routing.module';
import { LocationsRoutingModule } from 'src/modules/locations/locations-routing.module';
import { OrderRoutingModule } from 'src/modules/order/order-routing.module';
import { ProfileRoutingModule } from 'src/modules/profile/profile-routing.module';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

function initializeAppFactory(store: Store): () => Observable<any> {
  return () => store.dispatch(new InitializeMenu());
}

@NgModule({
  declarations: [AppComponent, AppShellComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StyleGuideRoutingModule,
    HomeRoutingModule,
    LocationsRoutingModule,
    ButtonsModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    CheckoutRoutingModule,
    OrderRoutingModule,
    ProfileRoutingModule,
    BrochureRoutingModule,
    CheckoutRoutingModule,
    NgChartsModule,
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    NgxsModule.forRoot(
      [
        AppState,
        LocationState,
        UserState,
        MenuState,
        ContentState,
        OrderState,
        NutritionState,
      ],
      {
        developmentMode: !environment.production,
      },
    ),
    AppCommonModule,
    CarouselModule,
    NgxsReduxDevtoolsPluginModule.forRoot({
      name: 'Freebirds State | NGXS',
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: [UserState, OrderState, MenuState],
      storage: StorageOption.LocalStorage,
    }),
    NgxSpinnerModule,
    // This Router Module must stay at the bottom or all routes imported after it will go to 404
    RouterModule.forChild([
      { path: 'shell', component: AppShellComponent },
      {
        path: 'careers',
        component: AppShellComponent,
        resolve: {
          url: 'externalUrlRedirectResolver',
        },
        data: {
          externalUrl: 'https://careers.freebirds.com',
        },
      },
      {
        path: ':customPageID',
        component: CustomPageComponent,
        resolve: {
          pageData: CustomPageResolver,
        },
        canActivate: [CustomPageGuard],
      },
    ]),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    LoadingBarRouterModule,
    LoadingBarModule,
    ToastrModule.forRoot({
      preventDuplicates: false,
      easing: 'ease-in-out',
      progressBar: true,
    }),
    SocialLoginModule,
    // NgxHotjarModule.forRoot('2967146', 6),
    // NgxHotjarRouterModule,
    // GoogleTagManagerModule.forRoot({
    //   id: 'GTM-MN3W3FN',
    // }),
    // SegmentModule.forRoot({
    //   apiKey: 'dQPJw8KvquvnkqXIlb0IQD726gLPc0XD',
    //   debug: false,
    //   loadOnInitialization: true,
    // }),
  ],
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        if (typeof window !== 'undefined') {
          window.location.href = (route.data as any).externalUrl;
        }
      },
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: <SocialAuthServiceConfig>{
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('730758447059477'),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      },
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeAppFactory,
    //   deps: [Store],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
