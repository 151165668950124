<section [ngClass]="pageBlock.headline_theme!">
  <div class="container d-flex flex-column py-4 py-md-5 gap-3 text-center">
    <ng-container *ngIf="pageBlock.headline_heading"><h1 class="display-5 headline-heading">
      {{ pageBlock.headline_heading }}
    </h1></ng-container>
    <ng-container *ngIf="pageBlock.headline_description">
      <h2>
        {{ pageBlock.headline_description }}
      </h2>
    </ng-container>
  </div>
</section>
