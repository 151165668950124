import {
  Ingredient,
  NewSavedProduct,
  Product,
  SavedCard,
  SavedProduct,
} from '@models/index';

export class InitializeUser {
  static readonly type = '[User] Initialize User';

  constructor() {}
}

export class CreateAccount {
  static readonly type = '[User] Create Account';

  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public phoneNumber: string,
    public emailOptIn: boolean,
    public smsOptIn: boolean,
    public birthday: string,
    public password: string,
    public passwordConfirmation: string,
    public termsAndConditions: boolean,
  ) {}
}

export class Login {
  static readonly type = '[User] Login';

  constructor(public email: string, public password: string) {}
}

export class LoginWithFacebook {
  static readonly type = '[User] Login with Facebook';

  constructor(public accessToken: string) {}
}

export class LoginWithCode {
  static readonly type = '[User] Login with Code';

  constructor(public accessToken: string) {}
}

export class Logout {
  static readonly type = '[User] Logout';

  constructor() {}
}

export class UpdateUserInfo {
  static readonly type = '[User] Update User Info';

  constructor(
    public firstName: string,
    public lastName: string,
    public email: string,
    public birthday: string,
    public emailOpt: boolean,
    public smsOpt: boolean,
  ) {}
}

export class SetSavedCards {
  static readonly type = '[User] Set Saved Cards';

  constructor(public basketID?: string) {}
}

export class SetFavoriteLocations {
  static readonly type = '[User] Set Favorite Locations';

  constructor() {}
}

export class SaveFavoriteLocation {
  static readonly type = '[User] Save Favorite Location';

  constructor(public locationID: number) {}
}

export class DeleteFavoriteLocation {
  static readonly type = '[User] Delete Favorite Location';

  constructor(public locationID: number) {}
}

export class DeleteSavedCard {
  static readonly type = '[User] Delete Saved Card';

  constructor(public card: SavedCard) {}
}

export class SetDefaultSavedCard {
  static readonly type = '[User] Set Default Saved Card';

  constructor(public card: SavedCard) {}
}

export class RefreshMessages {
  static readonly type = '[User] Refresh Messages';

  constructor() {}
}

export class DismissMessage {
  static readonly type = '[User] Dismiss Message';

  constructor(public messageID: string) {}
}

export class SetSavedProducts {
  static readonly type = '[User] Set Saved Products';

  constructor() {}
}

export class CreateSavedProduct {
  static readonly type = '[User] Create Saved Product';

  constructor(
    public name: string,
    public product: Product,
    public options: Ingredient[],
    public quantity: number,
    public specialInstructions?: string,
    public recipientName?: string,
  ) {}
}

export class DeleteSavedProduct {
  static readonly type = '[User] Delete Saved Product';

  constructor(public savedProductID: string) {}
}

export class ClearUser {
  static readonly type = '[User] Clear User';

  constructor() {}
}
