<app-navbar></app-navbar>

<!-- <section class="nav-page-height-fixed confirmation-bg position-relative"> -->
<!-- <div class="container d-flex flex-row flex-nowrap gap-4 py-4 h-100">

    <div class="drop-shadow">
      <div class="receipt p-4 d-flex flex-column flex-nowrap align-items-center">
        <img src="assets/images/logos/Freebirds_Logos_Digital_PrimaryLogo_Carbon_RGB.svg" class="w-50" />
        <div class="w-100 d-flex flex-row flex-nowrap justify-content-between pt-3">
          <div>Order#: 65498132106519654</div>
          <div>07/07/2022</div>
        </div>
        <div class="w-100 d-flex flex-row flex-nowrap justify-content-between">
          <div>Pick Up</div>
          <div>1:30 PM</div>
        </div>
        <div>Order</div>
        <div class="w-100 d-flex flex-row flex-nowrap justify-content-between">
          <div>Freebird</div>
          <div>15.95</div>
        </div>
        <div class="d-flex flex-column w-100 ps-2 align-items-stretch">
          <div>Steak</div>
          <div>Cilantro Lime Rice</div>
          <div>Black Beans</div>
          <div>No Cheese</div>
          <div>Sour Cream</div>
          <div>Pico de Gallo</div>
          <div>Death Sauce</div>
        </div>
        <div class="w-100 d-flex flex-row flex-nowrap justify-content-between">
          <div>Large Chips and Queso</div>
          <div>7.95</div>
        </div>
        <div class="w-100 d-flex flex-row flex-nowrap justify-content-between">
          <div>Freebird</div>
          <div>15.95</div>
        </div>
        <div class="d-flex flex-column w-100 ps-2 align-items-stretch">
          <div>Steak</div>
          <div>Cilantro Lime Rice</div>
          <div>Black Beans</div>
          <div>No Cheese</div>
          <div>Sour Cream</div>
          <div>Pico de Gallo</div>
          <div>Death Sauce</div>
        </div>
        <div class="w-100 d-flex flex-row flex-nowrap justify-content-between">
          <div>Large Chips and Queso</div>
          <div>7.95</div>
        </div>
        <div class="w-100 d-flex flex-row flex-nowrap justify-content-between">
          <div>Freebird</div>
          <div>15.95</div>
        </div>
        <div class="d-flex flex-column w-100 ps-2 align-items-stretch">
          <div>Steak</div>
          <div>Cilantro Lime Rice</div>
          <div>Black Beans</div>
          <div>No Cheese</div>
          <div>Sour Cream</div>
          <div>Pico de Gallo</div>
          <div>Death Sauce</div>
        </div>
        <div class="w-100 d-flex flex-row flex-nowrap justify-content-between">
          <div>Large Chips and Queso</div>
          <div>7.95</div>
        </div>
        <div class="w-100 d-flex flex-row flex-nowrap justify-content-between pt-3">
          <div>Subtotal</div>
          <div>23.90</div>
        </div>
        <div class="w-100 d-flex flex-row flex-nowrap justify-content-between">
          <div>Tax</div>
          <div>1.80</div>
        </div>
        <div class="w-100 d-flex flex-row flex-nowrap justify-content-between pt-3 font-weight-bold">
          <div>Total</div>
          <div>25.70</div>
        </div>
      </div>
    </div>

    <div class="flex-grow-1 h-100 drop-shadow position-sticky" style="top: 0;">
      <div class="card w-100 h-100">
        <div class="card-body d-flex flex-column flex-column flex-nowrap align-items-center">
          <h3 class="h3">Order Placed</h3>
          <div>Your pickup order will be ready today at 1:30 PM</div>
          <h2 class="h2 w-100">Alliance Town Center (AT)</h2>
          <div class="w-100">9501 Sage Meadow Trail</div>
          <div class="w-100">Fort Worth, TX 76177</div>
          <div class="h-100 w-100 dummy-map mt-4"></div>
        </div>
        <div class="card-footer">
          <div class="d-flex flex-row flex-nowrap justify-content-between">
            <a href="#">Directions</a>
            <a href="#">Call Location</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<ng-container *ngIf="prevOrder$ | async as prevOrder">
  <section class="bg-info py-5 confirmation-bg nav-page-height">
    <div
      class="container text-center pb-5 d-flex flex-column gap-3 align-items-center position-relative">
      <img
        class="confirmation-icon"
        src="/assets/images/ingredient_illustrations/Icons/fb-dancing.svg"
        alt="" />
      <h1>Thank You for Your Order</h1>
    </div>
    <div class="container d-flex justify-content-center position-relative">
      <div class="confirmation-container d-flex flex-column">
        <div class="grow-2 basis-0">
          <div class="d-flex flex-column gap-3">
            <ng-container
              *ngIf="prevOrder.deliverymode !== 'dispatch'; else delivery">
              <div
                class="p-3 bg-white rounded text-center d-flex flex-column gap-1">
                <div>
                  Ready for {{ prevOrder.deliverymode | handoffModeName }} at:
                </div>
                <h2>{{ prevOrder.readytime | oloDate | date: 'h:mm a' }}</h2>
                <div>
                  {{ prevOrder.readytime | oloDate | date: 'M/d/YYYY' }}
                </div>
              </div>
              <div class="p-3 bg-white rounded d-flex flex-column gap-3">
                <div class="text-center d-flex flex-column gap-1">
                  <div>
                    {{ prevOrder.deliverymode | handoffModeName }} From:
                  </div>
                  <h4>{{ prevOrder.vendorname }}</h4>
                  <ng-container
                    *ngIf="prevOrderLocation$ | async as prevOrderLocation">
                    <div>
                      <small class="line-clamp-1">{{
                        prevOrderLocation.streetaddress
                      }}</small>
                      <small class="line-clamp-1"
                        >{{ prevOrderLocation.city }},
                        {{ prevOrderLocation.city }}
                        {{ prevOrderLocation.zip }}</small
                      >
                    </div>
                    <a
                      href="{{ prevOrderLocation.telephone | hrefTel }}"
                      class="btn btn-link p-0"
                      >{{ prevOrderLocation.telephone }}</a
                    >
                  </ng-container>
                </div>
                <ng-container
                  *ngIf="prevOrderLocation$ | async as prevOrderLocation">
                  <div class="d-flex flex-column gap-3">
                    <div class="d-flex flex-nowrap justify-content-center">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        (click)="getDirections(prevOrderLocation)">
                        Get Directions
                      </button>
                    </div>
                    <div class="w-100" style="height: 350px">
                      <mgl-map
                        [center]="{
                          lng: prevOrderLocation.longitude,
                          lat: prevOrderLocation.latitude
                        }"
                        class="h-100"
                        [style]="
                          'mapbox://styles/nicholasnorris/cl5sk5oaw000i16o46q444z3k'
                        "
                        [zoom]="[15]">
                        <mgl-marker
                          [lngLat]="{
                            lng: prevOrderLocation.longitude,
                            lat: prevOrderLocation.latitude
                          }"
                          anchor="center">
                          <img
                            class="location-pin"
                            src="assets/images/icons/Icons/fb-locations-pin-open-orange.svg"
                            alt="{{ prevOrderLocation.name }}" />
                        </mgl-marker>
                      </mgl-map>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <div
              class="receipt px-3 py-4 bg-white text-center d-flex flex-column gap-3">
              <h4>Order Summary</h4>
              <ng-container *ngFor="let product of prevOrder.products">
                <div
                  class="ordered-by text-left d-flex flex-column gap-2 grow-1">
                  <div>
                    <div
                      class="d-flex flex-row flex-nowrap gap-3 align-items-center">
                      <h6 class="grow-1">{{ product.name }}</h6>
                      <div>{{ product.totalcost | currency }}</div>
                    </div>
                    <ng-container *ngIf="product.choices.length">
                      <small class="card-text">
                        {{ product.choices | choicesList }}
                      </small>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="product.specialinstructions">
                    <div
                      class="d-flex flex-row flex-nowrap gap-3 align-items-center">
                      <small
                        class="font-weight-bold text-muted text-uppercase grow-1"
                        >{{ product.specialinstructions }}</small
                      >
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>

            <div class="d-flex justify-content-center">
              <button
                routerLink="/"
                type="button"
                class="btn btn-primary btn-lg">
                Return Home
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="grow-3 basis-0">
        <div>
          asdf
        </div>
      </div> -->
    </div>
  </section>

  <ng-template #delivery>
    <div class="p-3 bg-white rounded text-center d-flex flex-column gap-1">
      <ng-container *ngIf="deliveryStatus$ | async as status">
      <div>{{status.deliveryStatus === 'Delivered' ? 'Delivered' : 'Arrives'}} at:</div>


        <ng-container *ngIf="status.estimatedDeliveryTime else orderReadyTime">
          <h2>{{ status.estimatedDeliveryTime | date: 'h:mm a' }}</h2>
          <div>{{ status.estimatedDeliveryTime | date: 'M/d/YYYY' }}</div>
        </ng-container>
        <ng-template #orderReadyTime>
          <h2>{{ prevOrder.readytime | oloDate | date: 'h:mm a' }}</h2>
          <div>{{ prevOrder.readytime | oloDate | date: 'M/d/YYYY' }}</div>
        </ng-template>
        <div class="d-flex flex-nowrap pt-3">
          <div class="w-25">
            <div
              class="track active d-flex align-items-center justify-content-center">
              <div
                class="icon-shell active rounded-circle p-2 d-flex position-relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-bag-check"
                  viewBox="0 0 16 16">
                  <path
                    fill-rule="evenodd"
                    d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                  <path
                    d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                </svg>
              </div>
            </div>
            <div>Order Received</div>
          </div>
          <div class="w-25">
            <div
              class="track d-flex align-items-center justify-content-center"
              [class.active]="
                status.deliveryStatus === 'DeliveryInProgress' ||
                status.deliveryStatus === 'Delivered'
              ">
              <div
                class="icon-shell current rounded-circle p-2 d-flex position-relative"
                [class.active]="
                  status.deliveryStatus === 'DeliveryInProgress' ||
                  status.deliveryStatus === 'Delivered'
                ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-person"
                  viewBox="0 0 16 16">
                  <path
                    d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                </svg>
              </div>
            </div>
            <div>Order Picked Up</div>
          </div>
          <div class="w-25">
            <div
              class="track d-flex align-items-center justify-content-center"
              [class.active]="
                status.deliveryStatus === 'DeliveryInProgress' ||
                status.deliveryStatus === 'Delivered'
              ">
              <div
                class="icon-shell rounded-circle p-2 d-flex position-relative"
                [class.active]="
                  status.deliveryStatus === 'DeliveryInProgress' ||
                  status.deliveryStatus === 'Delivered'
                ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-truck"
                  viewBox="0 0 16 16">
                  <path
                    d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                </svg>
              </div>
            </div>
            <div>Out for Delivery</div>
          </div>
          <div class="w-25">
            <div
              class="track d-flex align-items-center justify-content-center"
              [class.active]="status.deliveryStatus === 'Delivered'">
              <div
                class="icon-shell rounded-circle p-2 d-flex position-relative"
                [class.active]="status.deliveryStatus === 'Delivered'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-check-circle"
                  viewBox="0 0 16 16">
                  <path
                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
            </div>
            <div>Order Delivered</div>
          </div>
        </div>
        <div
          *ngIf="
            status.deliveryService ||
            status.driverName ||
            status.driverPhoneNumber
          ">
          <h4 class="pt-2">Delivery Info</h4>
          <div *ngIf="status.deliveryService">{{ status.deliveryService }}</div>
          <div *ngIf="status.driverName">{{ status.driverName }}</div>
          <a
            *ngIf="status.driverPhoneNumber"
            [href]="status.driverPhoneNumber | hrefTel"
            >{{ status.driverPhoneNumber }}</a
          >
        </div>
        <ng-container *ngIf="status.latitude && status.longitude && status.deliveryStatus === 'DeliveryInProgress'">
          <app-delivery-map style="height: 350px; width: 100%" [order]="prevOrder" [deliveryStatus]="status"></app-delivery-map>
        </ng-container>
        <ng-container *ngIf="status.deliveryStatus !== 'Delivered'">
          <div class="w-100 text-left">
            <app-button
              classes="btn btn-outline-secondary btn-sm d-flex flex-row flex-nowrap gap-2"
              (buttonClick)="refreshDeliveryStatus(prevOrder.id)">
              <div>Refresh Status</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-clockwise"
                [class.spin]="refreshLoading"
                viewBox="0 0 16 16">
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                <path
                  d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
              </svg>
            </app-button>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="p-3 bg-white rounded d-flex flex-column gap-3">
      <div class="text-center d-flex flex-column gap-1">
        <div>Delivering To:</div>
        <h4>{{ prevOrder.deliveryaddress!.streetaddress }}</h4>
        <div>
          <ng-container *ngIf="prevOrder.deliveryaddress!.building"
            ><small class="line-clamp-1">{{
              prevOrder.deliveryaddress!.building
            }}</small></ng-container
          >
          <small class="line-clamp-1"
            >{{ prevOrder.deliveryaddress!.city }}
            {{ prevOrder.deliveryaddress!.zipcode }}</small
          >
        </div>

        <div class="d-flex flex-column align-items-center">
          <div class="font-weight-bold">Questions About Your Order?</div>
          <ng-container *ngIf="prevOrderLocation$ | async as prevOrderLocation">
            <a
              href="{{ prevOrderLocation.telephone | hrefTel }}"
              class="btn btn-link p-0"
              >{{ prevOrderLocation.telephone }}</a
            >
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
