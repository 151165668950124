import { Injectable } from '@angular/core';
import {
  AvailableReward,
  AvailableTime,
  DeliveryStatus,
  GlobalStateModel,
  GroupOrder,
  Order,
  PastOrder,
  Referral,
} from '@models/index';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { HandoffModeNamePipe } from '@pipes/handoff-mode-name.pipe';
import { OloDatePipe } from '@pipes/olo-date.pipe';
import { AddToOrderByChainIdDto } from '@server/order/dto/add-to-order-by-chain-id.dto';
import { HandoffMode } from '@server/vendor/olo/interfaces';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { MenuService } from '@services/api/menu.service';
import { OrderService } from '@services/api/order.service';
import { CloseCart, SetFullExperience } from '@store/actions/app.actions';
import {
  ClearOrderLocation,
  ClearPreviousOrderLocation,
  SetOrderLocation,
} from '@store/actions/locations.actions';
import { InitializeMenu, SetMenu } from '@store/actions/menu.actions';
import {
  AddReferralToBasket,
  AddToOrder,
  AddToOrderByChainID,
  ApplyReward,
  CancelGroupOrder,
  ClearOrder,
  CompleteGroupOrder,
  GetCurrentGroupOrder,
  GetCurrentOrder,
  JoinGroupOrder,
  LeaveGroupOrder,
  RemoveCoupon,
  RemoveFromOrder,
  RemoveReward,
  SetAvailableOrderTimes,
  SetAvailableRewards,
  SetCoupon,
  SetCustomField,
  SetDeliveryAddress,
  SetDeliveryStatus,
  SetHandoffMode,
  SetPreviousOrder,
  SetTimeWanted,
  SetTimeWantedToASAP,
  SetTip,
  StageNutritionProduct,
  StartGroupOrder,
  StartOrder,
  StartOrderFromPastOrder,
  SubmitBasket,
  TransferOrder,
  UpdateGroupOrder,
  UpdateOrderItem,
  ValidateOrder,
} from '@store/actions/order.actions';
import { ClearUser } from '@store/actions/user.actions';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  concatMap,
  from,
  last,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throwError,
  toArray,
  withLatestFrom,
} from 'rxjs';
import { filter } from 'rxjs/operators';

export interface OrderStateModel {
  order: Order | null;
  previousOrder: PastOrder | null;
  deliveryStatus: DeliveryStatus | null;
  availableOrderTimes: AvailableTime[] | null;
  availableRewards: AvailableReward[] | null;
  stagedNutritionProduct: AddToOrderByChainIdDto | null;
  groupOrder: GroupOrder | null;
  ownsGroupOrder: boolean;
  groupOrderName: string | null;
}

@State<OrderStateModel>({
  name: 'order',
  defaults: {
    order: null,
    previousOrder: null,
    deliveryStatus: null,
    availableOrderTimes: null,
    availableRewards: null,
    stagedNutritionProduct: null,
    groupOrder: null,
    ownsGroupOrder: false,
    groupOrderName: null,
  },
})
@Injectable()
export class OrderState implements NgxsOnInit {
  constructor(
    private order: OrderService,
    private oloDatePipe: OloDatePipe,
    private store: Store,
    private analytics: AnalyticsService,
    private toast: ToastrService,
    private menu: MenuService,
    private handoffNamePipe: HandoffModeNamePipe,
  ) {}

  ngxsOnInit(ctx: StateContext<OrderStateModel>) {
    this.store.select((state: GlobalStateModel) => state.order.order).subscribe((order) => {
      if (order && order.products.length && order.products.some((p) => Object.keys(p).includes('productid'))) {
        this.order.getOrder(ctx.getState().order!.id).subscribe((order) => {
          ctx.patchState({
            order,
          });
        });
        }
    });
    if (
      ctx.getState().order &&
      moment(
        this.oloDatePipe.transform(ctx.getState().order!.earliestreadytime),
      ).isSameOrAfter(moment(), 'day')
    ) {
      ctx.dispatch(new SetOrderLocation(ctx.getState().order!.vendorid));
      ctx.dispatch(
        new SetMenu(
          ctx.getState().order!.vendorid,
          ctx.getState().order!.deliverymode,
        ),
      );
      this.order.getOrder(ctx.getState().order!.id).subscribe((order) => {
        ctx.patchState({
          order,
        });
      });
    } else {
      ctx.patchState({
        order: null,
      });
      ctx.dispatch(new InitializeMenu());
    }
    if (ctx.getState().groupOrder) {
      this.order
        .getGroupOrder(
          ctx.getState().groupOrder!.id,
          undefined,
          ctx.getState().groupOrder!.basket.id,
        )
        .pipe(
          map((groupOrder) => {
            if (
              moment().isAfter(
                moment(this.oloDatePipe.transform(groupOrder!.deadline)),
              ) ||
              !groupOrder!.isopen
            ) {
              return ctx.patchState({
                order: null,
                groupOrder: null,
                ownsGroupOrder: false,
                groupOrderName: null,
              });
            }
            return;
          }),
          catchError((err) => {
            return of(
              ctx.patchState({
                order: null,
                groupOrder: null,
                ownsGroupOrder: false,
                groupOrderName: null,
              }),
            );
          }),
        )
        .subscribe(() => {});
    }
  }

  @Action(StartOrder)
  startOrder(ctx: StateContext<OrderStateModel>, action: StartOrder) {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.user.tokens)
      .pipe(
        switchMap((tokens) => {
          if (ctx.getState().order && !action.skipTransfer) {
            return ctx.dispatch(
              new TransferOrder(ctx.getState().order!.id, action.locationID),
            );
          }
          if (ctx.getState().order && action.skipTransfer) {
            this.toast.warning(
              'Some items in your order could not be transferred to your new location.',
            );
          }
          return this.order
            .startOrder(action.locationID, tokens?.tokens.ordering.token)
            .pipe(
              map((order) => {
                ctx.dispatch(new SetOrderLocation(action.locationID));
                ctx.dispatch(new AddReferralToBasket());
                if (tokens?.tokens.ordering.token) {
                  ctx.dispatch(
                    new SetAvailableRewards(
                      order.id,
                      tokens.tokens.ordering.token,
                    ),
                  );
                }

                if (ctx.getState().stagedNutritionProduct) {
                  this.store
                    .select((state: GlobalStateModel) => state.order.order)
                    .pipe(
                      filter((o) => o !== null),
                      take(1),
                    )
                    .subscribe((order) =>
                      ctx
                        .dispatch(
                          new AddToOrderByChainID(
                            order!.id,
                            ctx.getState().stagedNutritionProduct!.chainProductID,
                            ctx.getState().stagedNutritionProduct!.quantity,
                            ctx.getState().stagedNutritionProduct!.choices,
                            ctx.getState().stagedNutritionProduct!.specialInstructions,
                            ctx.getState().stagedNutritionProduct!.recipient,
                          ),
                        )
                        .subscribe(() =>
                          this.toast.success(
                            `Your item was added to your order`,
                          ),
                        ),
                    );
                }
                return ctx.patchState({
                  order,
                  previousOrder: null,
                  availableOrderTimes: null,
                  availableRewards: null,
                  deliveryStatus: null,
                  groupOrder: null,
                  ownsGroupOrder: false,
                  groupOrderName: null,
                });
              }),
            );
        }),
      );
  }

  @Action(AddReferralToBasket)
  addReferralToBasket(ctx: StateContext<OrderStateModel>) {
    const referrals: Referral[] = [];
    if (localStorage.getItem('rwg_token')) {
      referrals.push({
        source: 'rwg_token',
        token: localStorage.getItem('rwg_token')!,
      });
      localStorage.removeItem('rwg_token');
    }
    if (referrals.length) {
      return this.store
        .select((state: GlobalStateModel) => state.order.order)
        .pipe(
          filter((o) => !!o),
          take(1),
          switchMap((order) => {
            return this.order.addReferrals(order!.id, referrals);
          }),
        );
    }
    return of(null);
  }

  @Action(ClearOrder)
  clearOrder(ctx: StateContext<OrderStateModel>, _action: ClearOrder) {
    ctx.dispatch([
      new ClearOrderLocation(),
      new ClearPreviousOrderLocation(),
      new InitializeMenu(),
    ]);
    ctx.patchState({
      order: null,
      previousOrder: null,
      groupOrder: null,
      ownsGroupOrder: false,
      groupOrderName: null,
    });
  }

  @Action(GetCurrentOrder)
  getCurrentOrder(ctx: StateContext<OrderStateModel>, action: GetCurrentOrder) {
    return this.order.getOrder(action.basketID).pipe(
      map((order) => {
        return ctx.patchState({
          order,
        });
      }),
    );
  }

  @Action(TransferOrder)
  transferOrder(ctx: StateContext<OrderStateModel>, action: TransferOrder) {
    return this.order.transferOrder(action.basketID, action.locationID).pipe(
      map((transferRes) => {
        ctx.dispatch(new SetOrderLocation(action.locationID));
        return ctx.patchState({
          order: transferRes.basket,
        });
      }),
      catchError(() => {
        return this.order
          .setHandoffMode(action.basketID, HandoffMode.PICKUP)
          .pipe(
            switchMap((order) => {
              return this.order
                .transferOrder(action.basketID, action.locationID)
                .pipe(
                  map((transferRes) => {
                    ctx.dispatch(new SetOrderLocation(action.locationID));
                    return ctx.patchState({
                      order: transferRes.basket,
                    });
                  }),
                );
            }),
            catchError(() =>
              ctx.dispatch(new StartOrder(action.locationID, true)),
            ),
          );
      }),
    );
  }

  @Action(ValidateOrder)
  validateOrder(ctx: StateContext<OrderStateModel>, action: ValidateOrder) {
    return this.order.getOrder(action.basketID).pipe(
      mergeMap((order) => {
        return this.checkIfTooManySaucesOnSide(order).pipe(
          mergeMap((canAdd) => {
            return this.order.validateOrder(action.basketID).pipe(
              switchMap((validatedOrder) => {
                return ctx.dispatch(new GetCurrentOrder(validatedOrder.id));
              }),
            );
          }),
        );
      }),
    );
  }

  @Action(AddToOrder)
  addToOrder(
    ctx: StateContext<OrderStateModel>,
    action: AddToOrder,
  ): Observable<OrderStateModel> {
    return this.checkIfCanAddItemToOrder(
      ctx.getState().order!,
      action.productID,
    ).pipe(
      mergeMap((canAdd) => {
        return this.order
          .addToOrder(
            action.basketID,
            action.productID,
            action.quantity,
            action.options,
            action.specialInstructions,
            ctx.getState().groupOrderName
              ? ctx.getState().groupOrderName!
              : action.recipient,
          )
          .pipe(
            switchMap((order) => {
              return this.store
                .selectOnce((state: GlobalStateModel) => state.menu.category)
                .pipe(
                  withLatestFrom(
                    this.store.select(
                      (state: GlobalStateModel) =>
                        state.locations.orderLocation,
                    ),
                  ),
                  map(([category, location]) => {
                    this.analytics.logAddToCart(
                      [order.products[order.products.length - 1]],
                      category!,
                      location!,
                    );
                    return ctx.patchState({
                      order,
                    });
                  }),
                );
            }),
          );
      }),
    );
  }

  @Action(AddToOrderByChainID)
  addToOrderByChainID(
    ctx: StateContext<OrderStateModel>,
    action: AddToOrderByChainID,
  ) {
    return this.order
      .addToOrderByChainID(
        action.basketID,
        action.chainProductID,
        action.quantity,
        action.choices,
        action.specialInstructions,
        ctx.getState().groupOrderName
          ? ctx.getState().groupOrderName!
          : action.recipient,
      )
      .pipe(
        map((order) => {
          return ctx.patchState({
            order,
            stagedNutritionProduct: null,
          });
        }),
      );
  }

  @Action(RemoveFromOrder)
  removeFromOrder(
    ctx: StateContext<OrderStateModel>,
    action: RemoveFromOrder,
  ): Observable<OrderStateModel> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.menu.menu)
      .pipe(
        withLatestFrom(
          this.store.select(
            (state: GlobalStateModel) => state.locations.orderLocation,
          ),
        ),
        switchMap(([menu, location]) => {
          this.analytics.logRemoveFromCart(
            ctx
              .getState()
              .order!.products.find(
                (product) => product.id === action.basketProductID,
              )!,
            menu!.categories,
            location!,
          );
          return this.order
            .removeFromOrder(action.basketID, action.basketProductID)
            .pipe(
              map((order) => {
                return ctx.patchState({
                  order,
                });
              }),
            );
        }),
      );
  }

  @Action(UpdateOrderItem)
  updateOrderItem(
    ctx: StateContext<OrderStateModel>,
    action: UpdateOrderItem,
  ): Observable<OrderStateModel> {
    return this.order
      .updateOrderItem(
        action.basketID,
        action.basketProductID,
        action.productID,
        action.quantity,
        action.options,
        action.specialInstructions,
        ctx.getState().groupOrderName
          ? ctx.getState().groupOrderName!
          : action.recipient,
      )
      .pipe(
        map((order) => {
          return ctx.patchState({
            order,
          });
        }),
      );
  }

  @Action(SetTimeWanted)
  setTimeWanted(
    ctx: StateContext<OrderStateModel>,
    action: SetTimeWanted,
  ): Observable<OrderStateModel> {
    const timeWanted = moment(action.timeWanted);
    return this.order
      .setTimeWanted(
        action.basketID,
        false,
        timeWanted.year(),
        timeWanted.month() + 1,
        Number(timeWanted.format('DD')),
        timeWanted.hour(),
        timeWanted.minute(),
      )
      .pipe(
        map((order) => {
          return ctx.patchState({
            order,
          });
        }),
      );
  }

  @Action(SetTimeWantedToASAP)
  setTimeWantedToASAP(
    ctx: StateContext<OrderStateModel>,
    action: SetTimeWantedToASAP,
  ): Observable<OrderStateModel> {
    return this.order.setTimeWantedToASAP(action.basketID).pipe(
      map((order) => {
        return ctx.patchState({
          order,
        });
      }),
    );
  }

  @Action(SetHandoffMode)
  setHandoffMode(
    ctx: StateContext<OrderStateModel>,
    action: SetHandoffMode,
  ): Observable<OrderStateModel> {
    return this.removeUnavailableItemsFromOrder(
      ctx.getState().order!,
      action.handoffMode,
    ).pipe(
      switchMap((_) => {
        return this.order
          .setHandoffMode(action.basketID, action.handoffMode)
          .pipe(
            map((order) => {
              return ctx.patchState({
                order,
              });
            }),
          );
      }),
    );
  }

  @Action(SetCustomField)
  setCustomField(
    ctx: StateContext<OrderStateModel>,
    action: SetCustomField,
  ): Observable<OrderStateModel> {
    return this.order
      .setCustomField(action.basketID, action.customFieldID, action.value)
      .pipe(
        map((order) => {
          return ctx.patchState({
            order,
          });
        }),
      );
  }

  @Action(SetDeliveryAddress)
  setDeliveryAddress(
    ctx: StateContext<OrderStateModel>,
    action: SetDeliveryAddress,
  ): Observable<OrderStateModel> {
    return this.removeUnavailableItemsFromOrder(
      ctx.getState().order!,
      action.handoffMode,
    ).pipe(
      switchMap((_) => {
        return this.order
          .setDeliveryAddress(
            action.basketID,
            action.handoffMode,
            action.addressID,
            action.building,
            action.streetAddress,
            action.city,
            action.zipCode,
            action.specialInstructions,
            action.isDefault,
          )
          .pipe(
            map((order) => {
              return ctx.patchState({
                order,
              });
            }),
          );
      }),
    );
  }

  @Action(SetTip)
  setTip(
    ctx: StateContext<OrderStateModel>,
    action: SetTip,
  ): Observable<OrderStateModel> {
    return this.order.setTip(action.basketID, action.amount).pipe(
      map((order) => {
        return ctx.patchState({
          order,
        });
      }),
    );
  }

  @Action(SubmitBasket)
  submitBasket(
    ctx: StateContext<OrderStateModel>,
    action: SubmitBasket,
  ): Observable<OrderStateModel> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.menu.menu)
      .pipe(
        switchMap((menu) => {
          return this.order
            .submitBasket(
              action.basketID,
              action.billingAccounts,
              action.authToken,
              action.user,
              action.guestOptIn,
            )
            .pipe(
              map((order) => {
                this.analytics.logPurchase(
                  ctx.getState().order!,
                  menu!.categories,
                  order,
                );
                if (action.createdNewAccount) {
                  this.analytics.logLoyaltyJoinNowAction(
                    this.store.selectSnapshot(
                      (state: GlobalStateModel) => state.user.user,
                    )!,
                    order,
                  );
                }
                localStorage.removeItem('rwg_token');
                return ctx.patchState({
                  order: null,
                  previousOrder: order,
                  availableRewards: null,
                  availableOrderTimes: null,
                  deliveryStatus: null,
                });
              }),
            );
        }),
      );
  }

  @Action(SetCoupon)
  setCoupon(
    ctx: StateContext<OrderStateModel>,
    action: SetCoupon,
  ): Observable<OrderStateModel> {
    return this.order.setCoupon(action.basketID, action.code).pipe(
      map((order) => {
        return ctx.patchState({
          order,
        });
      }),
    );
  }

  @Action(RemoveCoupon)
  removeCoupon(
    ctx: StateContext<OrderStateModel>,
    action: RemoveCoupon,
  ): Observable<OrderStateModel> {
    return this.order.removeCoupon(action.basketID).pipe(
      map((order) => {
        return ctx.patchState({
          order,
        });
      }),
    );
  }

  @Action(SetPreviousOrder)
  setPreviousOrder(
    ctx: StateContext<OrderStateModel>,
    action: SetPreviousOrder,
  ): Observable<OrderStateModel> {
    return this.order.getOrderStatus(action.orderID).pipe(
      map((order) => {
        return ctx.patchState({
          previousOrder: order,
        });
      }),
    );
  }

  @Action(SetDeliveryStatus)
  setDeliveryStatus(
    ctx: StateContext<OrderStateModel>,
    action: SetDeliveryStatus,
  ): Observable<OrderStateModel> {
    return this.order.getDeliveryStatus(action.orderID).pipe(
      map((deliveryStatus) => {
        return ctx.patchState({
          deliveryStatus: deliveryStatus,
        });
      }),
    );
  }

  @Action(StartOrderFromPastOrder)
  startOrderFromPastOrder(
    ctx: StateContext<OrderStateModel>,
    action: StartOrderFromPastOrder,
  ) {
    return this.order.createOrderFromPastOrder(action.pastOrder.id).pipe(
      switchMap((order) => {
        ctx.patchState({
          order,
        });
        return ctx.dispatch(new SetOrderLocation(order.vendorid));
      }),
    );
  }

  @Action(SetAvailableOrderTimes)
  setAvailableOrderTimes(
    ctx: StateContext<OrderStateModel>,
    action: SetAvailableOrderTimes,
  ) {
    ctx.patchState({
      availableOrderTimes: null,
    });
    const startTime = moment(action.date).isSame(moment(), 'day')
      ? moment().add(10, 'minutes')
      : moment(action.date).startOf('day');
    return this.order
      .getAvailableOrderTimes(
        action.basketID,
        startTime.format('X'),
        moment(action.date).endOf('day').format('X'),
      )
      .pipe(
        map((times) => {
          return ctx.patchState({
            availableOrderTimes: times.times,
          });
        }),
      );
  }

  @Action(SetAvailableRewards)
  setAvailableRewards(
    ctx: StateContext<OrderStateModel>,
    action: SetAvailableRewards,
  ) {
    return this.order
      .getAvailableRewards(action.basketID, action.orderingToken)
      .pipe(
        withLatestFrom(
          this.store.select(
            (state: GlobalStateModel) => state.locations.orderLocation,
          ),
        ),
        map(([rewards, location]) => {
          this.analytics.logPromotionListView(rewards.rewards, location!);
          return ctx.patchState({
            availableRewards: rewards.rewards,
          });
        }),
      );
  }

  @Action(ApplyReward)
  applyReward(ctx: StateContext<OrderStateModel>, action: ApplyReward) {
    return this.order
      .setReward(action.basketID, action.rewardReference, action.orderingToken)
      .pipe(
        withLatestFrom(
          this.store.select(
            (state: GlobalStateModel) => state.locations.orderLocation,
          ),
        ),
        switchMap(([order, location]) => {
          this.analytics.logPromotionClick(
            order!.appliedrewards[0],
            0,
            location!,
          );
          ctx.patchState({
            order,
          });
          return ctx.dispatch(new ValidateOrder(order.id)).pipe(
            catchError((error) => {
              if (ctx.getState().order?.appliedrewards?.length) {
                ctx.dispatch(
                  new RemoveReward(
                    order.id,
                    ctx.getState().order!.appliedrewards[0].rewardid!,
                  ),
                );
              }
              return throwError(error);
            }),
          );
        }),
      );
  }

  @Action(RemoveReward)
  removeReward(ctx: StateContext<OrderStateModel>, action: RemoveReward) {
    return this.order.removeReward(action.basketID, action.rewardID).pipe(
      map((order) => {
        return ctx.patchState({
          order,
        });
      }),
    );
  }

  @Action(StageNutritionProduct)
  stageNutritionProduct(
    ctx: StateContext<OrderStateModel>,
    action: StageNutritionProduct,
  ) {
    if (ctx.getState().order) {
      return ctx.dispatch(
        new AddToOrderByChainID(
          ctx.getState().order!.id,
          action.chainProductID,
          action.quantity,
          action.choices,
          action.specialInstructions,
          action.recipient,
        ),
      );
    } else {
      return ctx.patchState({
        stagedNutritionProduct: {
          chainProductID: action.chainProductID,
          quantity: action.quantity,
          choices: action.choices,
          specialInstructions: action.specialInstructions,
          recipient: action.recipient,
        },
      });
    }
  }

  @Action(StartGroupOrder)
  startGroupOrder(ctx: StateContext<OrderStateModel>, action: StartGroupOrder) {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.user.tokens)
      .pipe(
        switchMap((tokens) => {
          return this.order
            .createGroupOrder(
              tokens!.tokens.ordering.token,
              ctx.getState().order!.vendorid,
              action.deadline,
              ctx.getState().order!.id,
              action.note,
            )
            .pipe(
              switchMap((order) => {
                return this.order.getOrder(order.basket.id).pipe(
                  map((basket) => {
                    const user = this.store.selectSnapshot(
                      (state: GlobalStateModel) => state.user.user,
                    );
                    return ctx.patchState({
                      order: basket,
                      groupOrder: order,
                      ownsGroupOrder: true,
                      groupOrderName: `${user!.first_name} ${
                        user!.last_name[0]
                      }`,
                    });
                  }),
                );
              }),
            );
        }),
      );
  }

  @Action(GetCurrentGroupOrder)
  getCurrentGroupOrder(
    ctx: StateContext<OrderStateModel>,
    action: GetCurrentGroupOrder,
  ) {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.user.tokens)
      .pipe(
        switchMap((tokens) => {
          return this.order
            .getGroupOrder(
              action.groupOrderID,
              tokens?.tokens.ordering.token,
              tokens?.tokens.ordering.token
                ? undefined
                : ctx.getState().order?.id
                ? ctx.getState().order!.id
                : action.basketID,
            )
            .pipe(
              map((groupOrder) => {
                ctx.dispatch(new SetOrderLocation(groupOrder.basket.vendorid));
                return ctx.patchState({
                  groupOrder,
                  order: groupOrder.basket,
                });
              }),
            );
        }),
      );
  }

  @Action(UpdateGroupOrder)
  updateGroupOrder(
    ctx: StateContext<OrderStateModel>,
    action: UpdateGroupOrder,
  ) {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.user.tokens)
      .pipe(
        switchMap((tokens) => {
          return this.order
            .updateGroupOrder(
              action.groupOrderID,
              tokens!.tokens.ordering.token,
              action.deadline,
              action.note,
            )
            .pipe(
              map((groupOrder) => {
                return ctx.patchState({
                  groupOrder,
                  order: groupOrder.basket,
                });
              }),
            );
        }),
      );
  }

  @Action(JoinGroupOrder)
  joinGroupOrder(ctx: StateContext<OrderStateModel>, action: JoinGroupOrder) {
    ctx.dispatch(new SetFullExperience(false));
    ctx.dispatch(new ClearUser());
    return ctx.patchState({
      groupOrderName: action.name,
    });
  }

  @Action(LeaveGroupOrder)
  leaveGroupOrder(ctx: StateContext<OrderStateModel>, action: LeaveGroupOrder) {
    ctx.dispatch(new CloseCart());
    return this.order.getOrder(ctx.getState().order!.id).pipe(
      switchMap((order) => {
        return from(order.products)
          .pipe(
            filter(
              (product) =>
                !!product.recipient &&
                !!ctx.getState().groupOrderName &&
                product.recipient === ctx.getState().groupOrderName,
            ),
            concatMap((product) => {
              return this.order.removeFromOrder(order.id, product.id);
            }),
            toArray(),
          )
          .pipe(
            switchMap((_) => {
              return this.order.getOrder(order.id).pipe(
                map((order) => {
                  return ctx.patchState({
                    order: null,
                    groupOrderName: null,
                    groupOrder: null,
                    ownsGroupOrder: false,
                  });
                }),
              );
            }),
          );
      }),
    );
  }

  @Action(CancelGroupOrder)
  cancelGroupOrder(
    ctx: StateContext<OrderStateModel>,
    action: CancelGroupOrder,
  ) {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.user.tokens)
      .pipe(
        switchMap((tokens) => {
          return this.order.getOrder(ctx.getState().order!.id).pipe(
            switchMap((order) => {
              return from(order.products)
                .pipe(
                  mergeMap((product) => {
                    return this.order.removeFromOrder(order.id, product.id);
                  }),
                  toArray(),
                )
                .pipe(
                  switchMap((_) => {
                    return this.order
                      .updateGroupOrder(
                        ctx.getState().groupOrder!.id,
                        tokens!.tokens.ordering.token,
                        moment().add(1, 'minute').toDate(),
                        'Cancelled',
                      )
                      .pipe(
                        map((_) => {
                          return ctx.patchState({
                            groupOrder: null,
                            groupOrderName: null,
                            ownsGroupOrder: false,
                            order: null,
                          });
                        }),
                      );
                  }),
                );
            }),
          );
        }),
      );
  }

  @Action(CompleteGroupOrder)
  completeGroupOrder(
    ctx: StateContext<OrderStateModel>,
    action: CompleteGroupOrder,
  ) {
    return ctx.dispatch(new ClearOrder());
  }

  private removeUnavailableItemsFromOrder(
    order: Order,
    handoff: HandoffMode,
  ): Observable<Order> {
    if (order?.products?.length) {
      return from(order.products)
        .pipe(
          mergeMap((product) => {
            // get product from menu
            // check if new handoff is in unavailable handoffs
            // if so, remove from order
            return this.menu.getProductByMenuID(
              order.vendorid,
              product.productId,
              false,
            );
          }),
          filter((product) =>
            product.unavailablehandoffmodes.includes(handoff),
          ),
          toArray(),
        )
        .pipe(
          switchMap((products) => {
            if (products.length) {
              return from(products).pipe(
                mergeMap((product) => {
                  return this.order
                    .removeFromOrder(
                      order.id,
                      order.products.find((p) => p.productId === product.id)!
                        .id,
                    )
                    .pipe(
                      tap(() =>
                        this.toast.warning(
                          `${
                            product.name
                          } is not available for ${this.handoffNamePipe.transform(
                            handoff,
                          )}. It has been removed from your order.`,
                        ),
                      ),
                    );
                }),
                last(),
                switchMap(() => this.order.getOrder(order.id)),
              );
            } else {
              return of(order);
            }
          }),
        );
    } else {
      return of(order);
    }
  }

  private checkIfCanAddItemToOrder(
    order: Order,
    productID: number,
  ): Observable<boolean> {
    return this.menu.getProductByMenuID(order.vendorid, productID, false).pipe(
      mergeMap((product) => {
        if (
          product.metadata?.find((key) => key.key === 'subcategories')
            ?.value === 'sidesauces'
        ) {
          return this.menu.getMenu(order.vendorid, order.deliverymode).pipe(
            mergeMap((menu) => {
              const entreeCategories = menu.categories.filter(
                (category) => category.content.is_entree_category,
              );
              const numberOfEntreesInOrder = order.products.filter((product) =>
                entreeCategories.find((category) =>
                  category.products.find((p) => p.id === product.productId),
                ),
              ).length;
              const sidesauceProductsInMenu = menu.categories
                .find((category) =>
                  category.products.find(
                    (p) =>
                      p.metadata?.find((key) => key.key === 'subcategories')
                        ?.value === 'sidesauces',
                  ),
                )
                ?.products?.filter(
                  (p) =>
                    p.metadata?.find((key) => key.key === 'subcategories')
                      ?.value === 'sidesauces',
                );
              let sidesauceCumulativeQuantity = 0;
              order.products
                .filter((product) =>
                  sidesauceProductsInMenu?.find(
                    (p) => p.id === product.productId,
                  ),
                )
                .forEach((product) => {
                  sidesauceCumulativeQuantity += product.quantity;
                });
              if (
                sidesauceCumulativeQuantity >=
                (numberOfEntreesInOrder + 1) * 3
              ) {
                return throwError(
                  () =>
                    new Error(
                      'Max 3 side sauces per entrée. Please remove extra sauces or add another entrée.',
                    ),
                );
              } else {
                return of(true);
              }
            }),
          );
        }
        return of(true);
      }),
    );
  }

  private checkIfTooManySaucesOnSide(order: Order) {
    return this.menu.getMenu(order.vendorid, order.deliverymode).pipe(
      mergeMap((menu) => {
        const entreeCategories = menu.categories.filter(
          (category) => category.content.is_entree_category,
        );
        const numberOfEntreesInOrder = order.products.filter((product) =>
          entreeCategories.find((category) =>
            category.products.find((p) => p.id === product.productId),
          ),
        ).length;
        const sidesauceProductsInMenu = menu.categories
          .find((category) =>
            category.products.find(
              (p) =>
                p.metadata?.find((key) => key.key === 'subcategories')
                  ?.value === 'sidesauces',
            ),
          )
          ?.products?.filter(
            (p) =>
              p.metadata?.find((key) => key.key === 'subcategories')?.value ===
              'sidesauces',
          );
        let sidesauceCumulativeQuantity = 0;
        order.products
          .filter((product) =>
            sidesauceProductsInMenu?.find((p) => p.id === product.productId),
          )
          .forEach((product) => {
            sidesauceCumulativeQuantity += product.quantity;
          });
        console.log(numberOfEntreesInOrder, sidesauceCumulativeQuantity);
        if (sidesauceCumulativeQuantity > numberOfEntreesInOrder * 3) {
          return throwError(
            () =>
              new Error(
                'Max 3 side sauces per entrée. Please remove extra sauces or add another entrée.',
              ),
          );
        } else {
          return of(true);
        }
      }),
    );
  }
}
