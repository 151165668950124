import { Pipe, PipeTransform } from '@angular/core';
import { AllergenCategory, AllergenTag } from '@models/index';

@Pipe({
  name: 'nutritionPreferenceCheck',
})
export class NutritionPreferenceCheckPipe implements PipeTransform {
  transform(category: AllergenCategory, selectedTags: AllergenTag[]): boolean {
    return selectedTags.some((tag) => tag.allergen_category === category.id);
  }
}
