<div class="modal-header rounded-top align-items-center">
  <img
    class="h4-birds"
    src="assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="" />
  <h4 class="modal-title grow-1">Cancel Group Order?</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1">
  Are you sure you want to cancel your group order? You and your guest's items
  will be removed from the cart and your order will be restarted.
</div>
<div class="modal-footer rounded-bottom">
  <div class="d-flex flex-nowrap gap-3 m-0">
    <app-button classes="btn btn-outline-secondary" (click)="bsModalRef.hide()">
      Close
    </app-button>
    <app-button
      [isLoading]="cancelLoading"
      classes="btn btn-danger"
      (buttonClick)="cancelOrder()">
      Cancel Group
    </app-button>
  </div>
</div>
