<div class="ingredient-group p-3" [class.column-top-margin]="!last">
  <div class="text-center pb-2 text-section">
    <h3 class="section-title text-primary">
      {{ collectionSubgroup.item?.name }}
    </h3>

    <p class="section-description" *ngIf="collectionSubgroup.item?.subtitle">
      {{ collectionSubgroup.item!.subtitle! }}
    </p>
  </div>

  <div class="d-flex flex-column gap-2">
    <ng-container *ngFor="let collectionItem of collectionSubgroup.item?.items">
      <app-sm-ingredient-card
        [collectionItem]="collectionItem"
        *ngIf="collectionItem"></app-sm-ingredient-card>
    </ng-container>
  </div>
</div>
