<div class="growth-container pt-0 pt-md-5">
  <div class="foreground">
    <div class="container">
      <img
        animateOnScroll
        animationName="animate__animated animate__fadeInDown animate__slower animate__delay-1s"
        alt="Coming to a Texas town near you"
        class="growth-title hide-on-init"
        src="/assets/images/our-story/texas-growth-title.svg" />
    </div>
  </div>

  <div class="midground">
    <img
      alt="Front of a Freebirds Restaurant"
      animateOnScroll
      animationName="animate__animated animate__fadeInUp animate__slow"
      class="fb-building hide-on-init"
      src="/assets/images/our-story/freebirds-location-min.png" />
  </div>

  <div class="background">
    <video
      class="sky-video"
      poster="/assets/images/our-story/growth-video-still-min.jpg"
      muted
      loop
      playsinline
      autoplay
      preload="auto">
      <source
        src="/assets/images/our-story/growth-video-min.webm"
        type="video/webm" />
      <source
        src="/assets/images/our-story/growth-video-min.mp4"
        type="video/mp4" />
      <source src="/assets/images/our-story/growth-video-min.gif" />
    </video>
  </div>
</div>

<div class="growth-bottom">
  <div class="container py-5">
    <h1 class="bottom-title">We Are Growing In Texas!</h1>
    <p class="bottom-copy">
      We have 60+ locations open today with more on the way because of you.
      Follow us on social to see the new locations we’re opening around Austin,
      Houston, Dallas and San Antonio. If you want a Freebirds in your
      neighborhood, just slide into our DMs.
    </p>
    <div class="w-fit-content">
      <a
        class="join-tribe-btn"
        href="https://careers.freebirds.com/"
        target="_blank">
        <app-button
          class="d-flex align-items-center"
          [classes]="'btn btn-primary'">
          Join Our Tribe
        </app-button>
      </a>
    </div>
  </div>
</div>
