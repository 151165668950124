import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Category, GlobalStateModel, HomePage, Ingredient, IngredientGroup } from '@models/index';
import { OptionGroupChange } from '@modules/order/containers';
import { ProductCustomizationService } from '@modules/order/services';
import { Option, OptionGroup } from '@server/vendor/olo/interfaces';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-customize-product-header',
  templateUrl: './customize-product-header.component.html',
  styleUrls: ['customize-product-header.component.scss'],
})
export class CustomizeProductHeaderComponent {
  @Select((state: GlobalStateModel) => state.content.homepage)
  homepage$!: Observable<HomePage>;

  @Input() category!: Category;
  @Input() selectedIngredients!: Ingredient[];
  @Input() isSavedProduct = false;

  constructor(private productCustomization: ProductCustomizationService) {}
  onOptionRemoved(option: Ingredient) {
    this.productCustomization.removeFromSelectedIngredients(option);
    if (option.children) {
      option.modifiers.forEach((modGroup) =>
        modGroup.options.forEach((mod) =>
          this.onOptionRemoved(mod as Ingredient),
        ),
      );
    }
  }
}
