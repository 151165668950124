import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GroupOrder } from '@models/index';

@Component({
  selector: 'app-go-join',
  templateUrl: './go-join.component.html',
  styleUrls: ['go-join.component.scss'],
})
export class GoJoinComponent implements OnInit {
  @Input() groupOrder!: GroupOrder;
  @Output() joinClicked = new EventEmitter<string>();

  joinGroupOrderForm: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
  });
  constructor() {}
  ngOnInit() {}

  joinClick() {
    this.joinGroupOrderForm.markAllAsTouched();
    if (this.joinGroupOrderForm.valid) {
      this.joinClicked.emit(this.name.value);
    }
  }

  get name(): FormControl {
    return this.joinGroupOrderForm.get('name')! as FormControl;
  }
}
