import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AllergenCategory,
  AllergenTag,
  Contains,
  GlobalStateModel,
  Lifestyle,
  PlantBased,
} from '@models/index';
import { Select, Store } from '@ngxs/store';
import {
  AddAllergenTag,
  RemoveAllergenTag,
} from '@store/actions/nutrition.actions';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-nutrition-preferences-modal',
  templateUrl: './nutrition-preferences-modal.component.html',
  styleUrls: ['nutrition-preferences-modal.component.scss'],
})
export class NutritionPreferencesModalComponent implements OnInit, OnDestroy {
  @Select((state: GlobalStateModel) => state.nutrition.allergenCategories)
  allergenCategories$!: Observable<AllergenCategory[]>;
  @Select((state: GlobalStateModel) => state.nutrition.allergenTags)
  allergenTags$!: Observable<AllergenTag[]>;
  @Select((state: GlobalStateModel) => state.nutrition.selectedAllergenTags)
  selectedAllergenTags$!: Observable<AllergenTag[]>;

  private subs: Subscription[] = [];
  constructor(public bsModalRef: BsModalRef, private store: Store) {}
  ngOnInit() {}

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  selectAllergenTag(tag: AllergenTag) {
    this.store.dispatch(new AddAllergenTag(tag));
  }

  deselectAllergenTag(tag: AllergenTag) {
    this.store.dispatch(new RemoveAllergenTag(tag));
  }
}
