<ng-container *ngIf="homepage$ | async as homepage">
  <!-- navbar -->
  <app-navbar></app-navbar>

  <ng-container
    *ngFor=" let section of (homepage.modular_layouts?.[(homepageLayoutIndex$ | async)!]!.item!.sections)! | prodABTestingSectionFilter">
    <ng-container [ngSwitch]="section!.item!.section_type!">
      <ng-container *ngSwitchCase="'jumbotron'">
        <app-jumbotron
          [jumbotronLayouts]="
            homepage.jumbotron_layouts | jumbotronTimeFilter
          "></app-jumbotron>
      </ng-container>

      <ng-container *ngSwitchCase="'recent_order_preview'">
        <ng-container *ngIf="(groupOrder$ | async) === null">
          <app-recent-order-preview
            [pastOrders]="(pastOrders$ | async)!"
            [user]="(user$ | async)!"
            [homepage]="homepage"></app-recent-order-preview>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'rewards_preview'">
        <ng-container *ngIf="(rewards$ | async) as rewards">
          <ng-container *ngIf="rewards.length > 0">
            <app-rewards-preview
              [rewards]="rewards"
              [pointBalances]="(pointBalance$ | async)!">
            </app-rewards-preview>
          </ng-container>
        </ng-container>
      </ng-container>


      <ng-container *ngSwitchCase="'claims_to_fame'">
        <app-featured-products
          [section]="section!.item!"></app-featured-products>
      </ng-container>

      <ng-container *ngSwitchCase="'start_order'">
        <app-start-order
          [groupOrder]="groupOrder$ | async"
          [isGroupOrderOwner]="(isGroupOrderOwner$ | async)!"
          [order]="(order$ | async)!"
          [orderLocation]="(orderLocation$ | async)!"
          [user]="(user$ | async)!"></app-start-order>
      </ng-container>

      <ng-container *ngSwitchCase="'menu'">
        <app-categories-container
          [savedProducts]="savedProducts$ | async"
          [menu]="(menu$ | async)!"
          [orderLocation]="(orderLocation$ | async)!">
        </app-categories-container>
      </ng-container>

      <ng-container *ngSwitchCase="'featured'">
        <app-ad-card-container
          [featuredAds]="
            homepage.featured_ads | featuredAdsFilter
          "></app-ad-card-container>
      </ng-container>

      <ng-container *ngSwitchCase="'marquee'">
        <app-block-marquee [section]="section!.item!"></app-block-marquee>
      </ng-container>

      <ng-container *ngSwitchCase="'highlight'">
        <app-block-highlight [section]="section!.item!"></app-block-highlight>
      </ng-container>

      <ng-container *ngSwitchCase="'featurette'">
        <app-block-featurettes
          [section]="section!.item!"></app-block-featurettes>
      </ng-container>

      <ng-container *ngSwitchCase="'wysiwyg'">
        <div
          class="w-100"
          [innerHTML]="section!.item!.wysiwyg_content | sanitizeHtml"></div>
      </ng-container>

      <ng-container *ngSwitchCase="'code'">
        <div
          class="w-100"
          [innerHTML]="section!.item!.page_code | sanitizeHtml"></div>
      </ng-container>

      <ng-container *ngSwitchCase="'paragraph'">
        <app-block-paragraph [pageBlock]="section!.item!"></app-block-paragraph>
      </ng-container>

      <ng-container *ngSwitchCase="'headline'">
        <app-block-headline [pageBlock]="section!.item!"></app-block-headline>
      </ng-container>
    </ng-container>
  </ng-container>

  <app-footer [footerStyle]="'full'"></app-footer>
</ng-container>

<button
  *ngIf="!isAtTop"
  (click)="scrollToTop()"
  class="scroll-to-top-button"
  [@slideInRightOnEnter]
  [@slideOutRightOnLeave]
  [@fadeOutOnLeave]
  [@fadeInOnEnter]>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-arrow-up"
    viewBox="0 0 16 16">
    <path
      fill-rule="evenodd"
      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
  </svg>
</button>
