<app-navbar navbarOrientation="minimal"></app-navbar>

<div class="nav-page-height-fixed">
  <section class="d-flex h-100 unsubscribe-shell">
    <div
      class="container d-flex align-items-center justify-content-center position-relative">
      <div class="max-container d-flex flex-column gap-3">
        <div
          class="container text-center pb-4 d-flex flex-column gap-3 align-items-center position-relative">
          <img
            class="unsubscribe-icon"
            src="/assets/images/icons/Icons/fb-garlic-sad.svg"
            alt="" />
          <h1>We're Sad to See You Go</h1>
        </div>
        <div
          class="bg-light rounded d-flex flex-column gap-3 justify-content-center p-3">
          <div class="d-flex flex-column gap-2 w-100">
            <h3>Unsubscribe</h3>
            <form [formGroup]="captchaForm">
              Your email "{{ this.email }}" will be unsubscribed from all
              marketing emails.
              <ng-container *ngIf="platformId | isPlatformBrowser">
                <ngx-invisible-recaptcha
                  #captchaElem
                  formControlName="captcha"
                  siteKey="6LeLdw4bAAAAAHMk_xyHnOe5xr01pGo-ANFMzJYb"
                  (success)="captchaSuccess($event)"
                  (expire)="captchaExpired()"
                  (ready)="captchaReady()"
                  (load)="captchaReload()"></ngx-invisible-recaptcha>
              </ng-container>
            </form>
          </div>
          <div
            class="d-flex flex-row flex-nowrap gap-3 w-100 justify-content-end">
            <app-button classes="btn btn-outline-secondary" routerLink="/">
              Cancel
            </app-button>
            <app-button
              classes="btn btn-primary"
              [disabled]="captchaForm.invalid"
              (buttonClick)="unsubscribe()">
              Unsubscribe
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
