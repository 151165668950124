import { Component, OnInit } from '@angular/core';
import { Reward } from '@models/index';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reward-details-modal',
  templateUrl: './reward-details-modal.component.html',
  styleUrls: ['reward-details-modal.component.scss'],
})
export class RewardDetailsModalComponent implements OnInit {
  closeBtnName?: string;
  reward!: Reward;

  constructor(public bsModalRef: BsModalRef) {}
  ngOnInit() {}
}
