<div class="d-flex flex-column gap-4">
  <div class="d-flex flex-column gap-3 align-items-center">
    <img
      class="go-icon"
      src="assets/images/ingredient_illustrations/Icons/fb-dancing.svg"
      alt="icon of a pepper and tomato dancing to music" />
    <h1 class="text-center">Sign In or Join Now to Start a Group Order</h1>
    <div class="alert alert-info text-center">
      Group orders require an account. Please sign in or join now so that you
      can create a group order to share with others! You'll also unlock other
      benefits such as earning points and rewards.
    </div>
  </div>

  <div class="d-flex flex-column gap-4 px-3 py-4 bg-light rounded">
    <div class="d-flex flex-column gap-4 align-items-center">
      <ng-container *ngIf="signIn; else joinNow">
        <div class="d-flex flex-column w-100 gap-5">
          <div class="d-flex flex-column gap-3">
            <h3 class="text-center">Sign In</h3>
            <app-sign-in-form
              [parentForm]="loginForm"
              [loginLoading]="loginLoading"
              [facebookLoading]="facebookLoginLoading"
              [errorMessage]="errorMessage"
              [showHeading]="false"
              (loginClick)="login()"
              (loginFacebookClick)="loginWithFacebook()"></app-sign-in-form>
          </div>

          <div class="d-flex flex-column w-100 align-items-center gap-3">
            <div class="d-flex flex-column gap-1 w-100 align-items-center">
              <h5 class="auth-switch-page-heading w-75 text-center">
                Don't Have an Account?
              </h5>
              <div>Join today to start earning rewards.</div>
            </div>
            <button
              (click)="signIn = false"
              type="button"
              class="btn btn-outline-secondary">
              Join Now
            </button>
          </div>
        </div>
      </ng-container>

      <ng-template #joinNow>
        <div class="d-flex flex-column w-100 gap-5">
          <div class="d-flex flex-column gap-3">
            <h3 class="text-center">Join Now</h3>

            <app-create-account-form
              [parentForm]="createAccountFrom"
              [errorMessage]="errorMessage"
              [createLoading]="createLoading"
              [showHeading]="false"
              [createTitle]="'Sign Up For Free Chips & Dip'"
              (createClicked)="onCreateAccount()">
            </app-create-account-form>
          </div>

          <div class="d-flex flex-column w-100 align-items-center gap-3">
            <div class="d-flex flex-column gap-1 w-100 align-items-center">
              <h5 class="auth-switch-page-heading w-75 text-center">
                Have an Account?
              </h5>
              <div>Sign In to Redeem Your Rewards.</div>
            </div>
            <button
              (click)="signIn = true"
              type="button"
              class="btn btn-outline-secondary">
              Sign In
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
