import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Containers */
import * as homeContainers from './containers';
/* Guards */
import * as homeGuards from './guards';
/* Module */
import { HomeModule } from './home.module';

/* Routes */
export const ROUTES: Routes = [
  {
    path: '',
    canActivate: [],
    component: homeContainers.HomeComponent,
  },
];

@NgModule({
  imports: [HomeModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
