<!-- navbar -->
<app-navbar></app-navbar>

<section class="container d-flex justify-content-center">
  <div class="thin-container-column nav-page-height py-5">
    <h1 class="display-4 text-center">FAQ</h1>
    <ng-container *ngIf="faq$ | async as faqSections">
      <!--  Desktop -->
      <tabset
        [justified]="true"
        [vertical]="faqSections.length > 4"
        type="pills"
        class="d-none d-lg-block">
        <ng-container *ngFor="let section of faqSections">
          <tab [heading]="section.title">
            <ng-container *ngIf="section.questions !== undefined">
              <app-question-group
                [questions]="
                  section.questions! | faqFilter
                "></app-question-group>
            </ng-container>
          </tab>
        </ng-container>
      </tabset>
      <!--  Mobile  -->
      <tabset
        [justified]="true"
        type="pills"
        [vertical]="true"
        class="d-block d-lg-none">
        <ng-container *ngFor="let section of faqSections">
          <tab [heading]="section.title">
            <ng-container *ngIf="section.questions !== undefined">
              <app-question-group
                [questions]="
                  section.questions! | faqFilter
                "></app-question-group>
            </ng-container>
          </tab>
        </ng-container>
      </tabset>
    </ng-container>
  </div>
</section>

<app-footer></app-footer>

<button
  *ngIf="!isAtTop"
  (click)="scrollToTop()"
  class="scroll-to-top-button"
  [@slideInRightOnEnter]
  [@slideOutRightOnLeave]
  [@fadeOutOnLeave]
  [@fadeInOnEnter]>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-arrow-up"
    viewBox="0 0 16 16">
    <path
      fill-rule="evenodd"
      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
  </svg>
</button>
