<div class="modal-header rounded-top align-items-center">
  <img
    class="h4-birds"
    src="../../../../assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="freebirds birds icon" />
  <h4 class="modal-title grow-1">Leave Group Order?</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1" *ngIf="groupOrder$ | async as groupOrder">
  Are you sure you want to leave "{{ groupOrder.ownername }}'s" group order?
  Your items will be removed from the cart.
</div>
<div class="modal-footer rounded-bottom">
  <div class="d-flex flex-nowrap gap-3 m-0">
    <app-button
      [classes]="'btn btn-outline-secondary'"
      (click)="bsModalRef.hide()">
      Cancel
    </app-button>
    <app-button
      classes="btn btn-danger"
      [isLoading]="leaveLoading"
      (buttonClick)="leaveGroupOrder()">
      Leave Group
    </app-button>
  </div>
</div>
