<ng-container *ngIf="!!!(initialLoadComplete$ | async)">
  <app-app-shell></app-app-shell>
</ng-container>
<ngx-loading-bar color="#ff7500"></ngx-loading-bar>

<router-outlet></router-outlet>

<ng-container *ngIf="cartOpen$ | async">
  <div class="cart-backdrop" (click)="closeCart()"></div>
</ng-container>
<app-cart class="position-fixed top-0 end-0 h-100"></app-cart>
<ngx-spinner
  [fullScreen]="true"
  name="walking-burrito"
  bdColor="rgba(50, 49, 49, 0.8)"
  template="<img style='height: 25vh; width: auto;' src='/assets/images/icons/Icons/freebirds-walking-burrito-filled.svg'>"
  type="custom"></ngx-spinner>
