import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CancelGroupOrder, ClearOrder } from '@store/actions/order.actions';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Component({
  selector: 'app-go-cancel-modal',
  templateUrl: './go-cancel-modal.component.html',
  styleUrls: ['go-cancel-modal.component.scss'],
})
export class GoCancelModalComponent implements OnInit {
  cancelLoading = false;
  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private store: Store,
  ) {}
  ngOnInit() {}

  cancelOrder() {
    this.cancelLoading = true;
    this.store.dispatch(new CancelGroupOrder()).subscribe(() => {
      this.store.dispatch(new ClearOrder()).subscribe(() => {
        fromPromise(this.router.navigate(['/'])).subscribe(() => {
          this.bsModalRef.hide();
          this.cancelLoading = false;
        });
      });
    });
  }
}
