import { Injectable } from '@angular/core';
import { Restaurant } from '@models/index';
import { Action, State, StateContext } from '@ngxs/store';
import {
  CalendarType,
  HandoffMode,
  TimeModeType,
} from '@server/vendor/olo/interfaces';
import { LocationsService } from '@services/api/locations.service';
import {
  Endpoint,
  GeocoderService,
  PlaceType,
} from '@services/utility/geocoder.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { from, map, mergeMap, switchMap, toArray } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  ClearMapLocations,
  ClearOrderLocation,
  ClearPreviousOrderLocation,
  FindDeliveryLocation,
  GetNearLocations,
  SetAllLocations,
  SetOrderLocation,
  SetPreviousOrderLocation,
} from '../actions/locations.actions';

export interface LocationStateModel {
  mapLocations: Restaurant[] | null;
  searchRan: boolean | null;
  orderLocation: Restaurant | null;
  previousOrderLocation: Restaurant | null;
  closestDeliveryLocation: Restaurant | null;
}

@State<LocationStateModel>({
  name: 'locations',
  defaults: {
    mapLocations: null,
    searchRan: null,
    orderLocation: null,
    previousOrderLocation: null,
    closestDeliveryLocation: null,
  },
})
@Injectable()
export class LocationState {
  constructor(
    private locations: LocationsService,
    private geocoder: GeocoderService,
  ) {}

  @Action(SetAllLocations)
  setAllLocations(
    ctx: StateContext<LocationStateModel>,
    action: SetAllLocations,
  ) {
    return this.locations
      .getAllLocations(action.withCalendars, action.includePrivate)
      .pipe(
        map((response) => {
          return ctx.patchState({
            mapLocations: response.restaurants,
          });
        }),
      );
  }

  @Action(GetNearLocations)
  getNearLocations(
    ctx: StateContext<LocationStateModel>,
    action: GetNearLocations,
  ) {
    return this.locations
      .getNearLocations(
        action.latitude,
        action.longitude,
        action.radius,
        action.limit,
        action.withCalendars,
      )
      .pipe(
        map((response) => {
          return ctx.patchState({
            mapLocations: response.restaurants,
            searchRan: action.searchRan,
          });
        }),
      );
  }

  @Action(FindDeliveryLocation)
  findDeliveryLocation(
    ctx: StateContext<LocationStateModel>,
    action: FindDeliveryLocation,
  ) {
    // Erase previous location
    ctx.patchState({
      closestDeliveryLocation: null,
    });
    // Get Coordinates from Address
    return this.geocoder
      .forwardGeocoding(
        Endpoint.PLACES,
        action.fullAddress,
        ['us'],
        [PlaceType.ADDRESS],
      )
      .pipe(
        switchMap((result) => {
          // Get Nearest Locations
          return this.locations
            .getNearLocations(
              result.features[0].center[1],
              result.features[0].center[0],
              10,
              10,
              false,
            )
            .pipe(
              switchMap((restaurants) => {
                // Break up array into separate observable emissions
                return from(restaurants.restaurants)
                  .pipe(
                    // Iterate over values in order to maintain sort
                    mergeMap((restaurant) => {
                      // Check if location can deliver
                      return this.locations
                        .checkForDelivery(
                          restaurant.id,
                          action.handoffMode,
                          restaurant.iscurrentlyopen &&
                            moment().isAfter(
                              this.getTodaysDeliveryStart(
                                restaurant,
                                action.handoffMode,
                              ),
                            )
                            ? TimeModeType.ASAP
                            : TimeModeType.ADVANCE,
                          `${result.features[0].address} ${result.features[0].text}`,
                          result.features[0].context.find((context) =>
                            context.id.includes('place'),
                          )!.text,
                          result.features[0].context.find((context) =>
                            context.id.includes('postcode'),
                          )!.text,
                          restaurant.iscurrentlyopen &&
                            moment().isAfter(
                              this.getTodaysDeliveryStart(
                                restaurant,
                                action.handoffMode,
                              ),
                            )
                            ? undefined
                            : this.calculateAdvanceTimeWanted(
                                restaurant,
                                action.handoffMode,
                              ),
                        )
                        .pipe(
                          // Return location if it can deliver, otherwise return null
                          map((deliveryResult) => {
                            if (deliveryResult.candeliver) {
                              return restaurant;
                            } else {
                              return null;
                            }
                          }),
                        );
                    }),
                    // Filter out null values
                    filter((r: Restaurant | null) => r !== null),
                    // Combine emitted values back into array
                    toArray(),
                    map((restaurants) =>
                      restaurants.sort((a, b) => a!.sort - b!.sort),
                    ),
                  )
                  .pipe(
                    // Update state with closest location that can deliver
                    map((restaurantCanDeliver) => {
                      if (restaurantCanDeliver[0]) {
                        return ctx.patchState({
                          closestDeliveryLocation: restaurantCanDeliver[0],
                        });
                      } else {
                        throw new Error(
                          'No restaurants can currently deliver to you.',
                        );
                      }
                    }),
                  );
              }),
            );
        }),
      );
  }

  @Action(SetOrderLocation)
  setOrderLocation(
    ctx: StateContext<LocationStateModel>,
    action: SetOrderLocation,
  ) {
    return this.locations.getLocationByID(action.locationID, true).pipe(
      map((location) => {
        return ctx.patchState({
          orderLocation: location,
        });
      }),
    );
  }

  @Action(ClearOrderLocation)
  clearOrderLocation(
    ctx: StateContext<LocationStateModel>,
    action: ClearOrderLocation,
  ) {
    return ctx.patchState({
      orderLocation: null,
    });
  }

  @Action(SetPreviousOrderLocation)
  setPreviousOrderLocation(
    ctx: StateContext<LocationStateModel>,
    action: SetPreviousOrderLocation,
  ) {
    return this.locations.getLocationByID(action.locationID, false).pipe(
      map((location) => {
        return ctx.patchState({
          previousOrderLocation: location,
        });
      }),
    );
  }

  @Action(ClearPreviousOrderLocation)
  clearPreviousOrderLocation(
    ctx: StateContext<LocationStateModel>,
    action: ClearPreviousOrderLocation,
  ) {
    return ctx.patchState({
      previousOrderLocation: null,
    });
  }

  @Action(ClearMapLocations)
  clearMapLocations(
    ctx: StateContext<LocationStateModel>,
    action: ClearMapLocations,
  ) {
    return ctx.patchState({
      mapLocations: null,
    });
  }

  private calculateAdvanceTimeWanted(
    restaurant: Restaurant,
    handoff: HandoffMode,
  ): Moment {
    const deliveryCalendar =
      handoff === HandoffMode.DISPATCH
        ? restaurant.calendars.find(
            (calendar) => calendar.type === CalendarType.DISPATCH,
          )
        : restaurant.calendars.find(
            (calendar) => calendar.type === CalendarType.DELIVERY,
          );
    switch (true) {
      case moment().isBefore(
        moment(deliveryCalendar!.ranges[0].start, 'YYYYMMDD hh:mm').utcOffset(
          restaurant.utcoffset,
          true,
        ),
      ):
        return moment(
          moment(deliveryCalendar!.ranges[0].start, 'YYYYMMDD hh:mm').utcOffset(
            restaurant.utcoffset,
            true,
          ),
        ).add(1, 'hour');
      case moment().isBefore(
        moment(deliveryCalendar!.ranges[1].start, 'YYYYMMDD hh:mm').utcOffset(
          restaurant.utcoffset,
          true,
        ),
      ):
        return moment(
          moment(deliveryCalendar!.ranges[1].start, 'YYYYMMDD hh:mm').utcOffset(
            restaurant.utcoffset,
            true,
          ),
        ).add(1, 'hour');
      default:
        return moment();
    }
  }

  private getTodaysDeliveryStart(
    restaurant: Restaurant,
    handoff: HandoffMode,
  ): Moment {
    const deliveryCalendar =
      handoff === HandoffMode.DISPATCH
        ? restaurant.calendars.find(
            (calendar) => calendar.type === CalendarType.DISPATCH,
          )
        : restaurant.calendars.find(
            (calendar) => calendar.type === CalendarType.DELIVERY,
          );
    if (!deliveryCalendar) {
      return moment();
    }
    return moment(
      deliveryCalendar!.ranges[0].start,
      'YYYYMMDD hh:mm',
    ).utcOffset(restaurant.utcoffset, true);
  }
}
