import { Component, Input, OnInit } from '@angular/core';
import { Restaurant } from '@models/index';

@Component({
  selector: 'app-restaurant-details-accordion',
  templateUrl: './restaurant-details-accordion.component.html',
  styleUrls: ['restaurant-details-accordion.component.scss'],
})
export class RestaurantDetailsAccordionComponent implements OnInit {
  @Input() restaurant!: Restaurant;

  isCollapsed = true;
  constructor() {}
  ngOnInit() {}
}
