<ng-container *ngIf="category">
  <div class="badge" [ngClass]="category.content.badge_color">
    {{ category.content?.badge_text }}
    <ng-container *ngIf="category.content?.badge_animation">
      <div class="shimmer"></div>
      <div class="shimmer-2"></div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="product">
  <small
    class="badge mb-1 w-fit-content"
    [ngClass]="product.content.badge_color">
    {{ product.content?.badge_text }}
    <ng-container *ngIf="product.content?.badge_animation">
      <div class="shimmer"></div>
      <div class="shimmer-2"></div>
    </ng-container>
  </small>
</ng-container>

<ng-container *ngIf="ingredient">
  <small class="badge mb-1 w-fit-content" [ngClass]="ingredient.content.badge_color">
    {{ ingredient.content?.badge_text }}
    <ng-container *ngIf="ingredient.content?.badge_animation">
      <div class="shimmer"></div>
      <div class="shimmer-2"></div>
    </ng-container>
  </small>
</ng-container>
