import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-guest-rewards-container',
    templateUrl: './guest-rewards-container.component.html',
    styleUrls: ['guest-rewards-container.component.scss'],
})
export class GuestRewardsContainerComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
