import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AllergenCategory,
  AllergenTag,
  ContentLocation,
  ScheduledAnnouncement,
  TipSettings,
} from '@models/index';
import { PostContactSubmissionDto } from '@server/content/dto/post-contact-submission.dto';
import {
  GetCustomPageResponse,
  GetFAQResponse,
  GetFAQSectionsResponse,
  GetHomepageResponse,
  GetLegalResponse,
  GetMetadataResponse,
  GetScheduledAnnouncementsResponse,
  GetStaticMenuResponse,
} from '@server/content/responses';
import * as moment from 'moment';
import { map, Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private contentAPIPrefix = environment.apiBaseURL + '/api/content';

  constructor(private http: HttpClient) {}

  getCustomPages(): Observable<GetCustomPageResponse> {
    const resource = `${this.contentAPIPrefix}/customPages`;
    return this.http.get<GetCustomPageResponse>(resource);
  }

  getHomepage(): Observable<GetHomepageResponse> {
    const resource = `${this.contentAPIPrefix}/homepage`;
    return this.http.get<GetHomepageResponse>(resource);
  }

  getFAQ(): Observable<GetFAQResponse> {
    const resource = `${this.contentAPIPrefix}/faq`;
    return this.http.get<GetFAQResponse>(resource);
  }

  getFAQSections(): Observable<GetFAQSectionsResponse> {
    const resource = `${this.contentAPIPrefix}/faqSections`;
    return this.http.get<GetFAQSectionsResponse>(resource);
  }

  getLegal(): Observable<GetLegalResponse> {
    const resource = `${this.contentAPIPrefix}/legal`;
    return this.http.get<GetLegalResponse>(resource);
  }

  getMetadata(): Observable<GetMetadataResponse> {
    const resource = `${this.contentAPIPrefix}/metadata`;
    return this.http.get<GetMetadataResponse>(resource);
  }

  getStaticMenu(): Observable<GetStaticMenuResponse> {
    const resource = `${this.contentAPIPrefix}/staticMenu`;
    return this.http.get<GetStaticMenuResponse>(resource);
  }

  getScheduledAnnouncements(): Observable<ScheduledAnnouncement[]> {
    const resource = `${this.contentAPIPrefix}/scheduledAnnouncements`;
    return this.http.get<GetScheduledAnnouncementsResponse>(resource).pipe(
      map((res) => {
        return res
          ? res.map((announcement) => {
              return <ScheduledAnnouncement>{
                announcement_color: announcement.announcement_color,
                announcement_link: announcement.announcement_link,
                closeable: announcement.closeable,
                id: announcement.id,
                date_created: moment(
                  announcement.date_created ?? undefined,
                ).toDate(),
                date_updated: moment(
                  announcement.date_updated ?? undefined,
                ).toDate(),
                message_text: announcement.message_text,
                open_in_new_tab: announcement.open_in_new_tab,
                schedule_type: announcement.schedule_type,
                scheduled_day: moment(
                  announcement.scheduled_day ?? undefined,
                ).toDate(),
                scheduled_end_date: moment(
                  announcement.scheduled_end_date ?? undefined,
                ).toDate(),
                scheduled_recurring_days: JSON.parse(
                  announcement.scheduled_recurring_days!,
                ),
                scheduled_start_date: moment(
                  announcement.scheduled_start_date ?? undefined,
                ).toDate(),
                sort: announcement.sort,
                status: announcement.status,
              };
            })
          : [];
      }),
    );
  }

  getContentLocations(): Observable<ContentLocation[]> {
    const resource = `${this.contentAPIPrefix}/locations`;
    return this.http.get<ContentLocation[]>(resource);
  }

  postContactFormSubmission(
    category: 'feedback' | 'media' | 'catering' | 'spirit-night' | 'donation' | 'data-request',
    message: object,
  ) {
    const resource = `${this.contentAPIPrefix}/contact`;
    const body: PostContactSubmissionDto = {
      category,
      message,
    };
    return this.http.post(resource, body);
  }

  verifyToken(token: string): Observable<{ success: boolean }> {
    const resource = `${this.contentAPIPrefix}/verify`;
    const body = { token };
    return this.http.post<{ success: boolean }>(resource, body);
  }

  getTipSettings(): Observable<TipSettings> {
    const resource = `${this.contentAPIPrefix}/tipSettings`;
    return this.http.get<TipSettings>(resource);
  }

  getAllergenCategories(): Observable<AllergenCategory[]> {
    const resource = `${this.contentAPIPrefix}/allergenCategories`;
    return this.http.get<AllergenCategory[]>(resource);
  }

  getAllergenTags(): Observable<AllergenTag[]> {
    const resource = `${this.contentAPIPrefix}/allergenTags`;
    return this.http.get<AllergenTag[]>(resource);
  }
}
