<ng-container *ngIf="!isSavedProducts; else savedProducts">
  <button
    (click)="onClick(category.id)"
    type="button"
    class="category-card border-0 h-100 w-100 p-0 py-1 py-md-0"
    [@fadeInOnEnter]="true" [style.background-color]="category.content?.card_background_color ?? 'transparent'">
    <img
      [src]="
        (category?.content?.image ? category.content?.image : undefined)
          | cmsAsset : 'category-image'
      "
      class="card-img-top"
      [alt]="category.name" />
    <div
      class="card-body pb-4 w-100 d-flex flex-row flex-wrap text-center align-items-start justify-content-start justify-content-sm-center gap-2 position-relative">
      <ng-container
        *ngIf="category.content?.badge_text && category.content?.badge_color">
        <app-badge [category]="category"></app-badge>
      </ng-container>
      <h3 class="category-title w-100 position-relative">
        {{ category.name }}
        <h5 class="order-arrow">Order</h5>
      </h3>
    </div>
  </button>
</ng-container>

<ng-template #savedProducts>
  <ng-container *ngIf="homepage$ | async as homepage">
  <button
    (click)="onClick(0)"
    type="button"
    class="category-card border-0 w-100 p-0 h-100"
    [@fadeInOnEnter]="true" [style.background-color]="homepage?.my_favorites_card_background_color ?? 'transparent'">
    <img
      src="assets/images/categories/saved-favorites-option-2.png"
      class="card-img-top"
      style="object-fit: contain"
      alt="My Favorites" />
    <div
      class="card-body w-100 d-flex flex-row flex-wrap text-center align-items-start justify-content-center gap-2 position-relative">

      <h3 class="category-title w-100 position-relative">
        My Favorites
        <h5 class="order-arrow">Order</h5>
      </h3>
    </div>
  </button>
  </ng-container>
</ng-template>
