import { Pipe, PipeTransform } from '@angular/core';
import { Category, IngredientGroup, Product, Subcategory } from '@models/index';
import { Products } from '@server/vendor/directus/interfaces/directus-collection.interface';

@Pipe({
  name: 'subcategory',
})
export class SubcategoryPipe implements PipeTransform {
  transform(category: Category): Subcategory[] {
    const subcategories: Subcategory[] = [];
    category.products.forEach((product) => {
      if (
        product.metadata &&
        product.metadata.find((md) => md.key === 'subcategories') &&
        !(
          product.metadata.find((md) => md.key === 'hideInCategory')?.value ===
          'true'
        )
      ) {
        const subcategory = subcategories.find(
          (sub) =>
            sub.name ===
            product.metadata.find((md) => md.key === 'subcategories')!.value,
        );
        if (subcategory) {
          const index = subcategories.indexOf(subcategory);
          subcategory.products.push(
            <
              Product & { sort: number; content: Products } & {
                optiongroups?: IngredientGroup[];
              }
            >product,
          );
          subcategories[index] = subcategory;
        } else {
          subcategories.push(<Subcategory>{
            name: product.metadata.find((md) => md.key === 'subcategories')!
              .value,
            products: [product],
          });
        }
      }
    });
    return subcategories;
  }
}
