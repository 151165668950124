import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Ingredient, IngredientGroup } from '@models/index';
import {
  OptionGroupChange,
  OptionGroupStatus,
} from '@modules/order/containers';
import { ProductCustomizationService } from '@modules/order/services';
import { SelectedIngredientPipe } from '@pipes/selected-ingredient.pipe';

@Component({
  selector: 'app-ingredient-submodifier-group',
  templateUrl: './ingredient-submodifier-group.component.html',
  styleUrls: ['ingredient-submodifier-group.component.scss'],
})
export class IngredientSubmodifierGroupComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() isUpsell = false;
  @Input() ingredientGroup!: IngredientGroup;
  @Input() selectedIngredients!: Ingredient[];
  @Input() isSelected: boolean = false;
  @Input() isEditMode: boolean = false;

  localCurrentlySelected: Ingredient[] = [];

  observer?: IntersectionObserver;

  stepPosition: number | null = null;

  constructor(
    private productCustomization: ProductCustomizationService,
    private selectedIngredientPipe: SelectedIngredientPipe,
  ) {}

  ngOnInit() {
    this.ingredientGroup.options.forEach((option) => {
      if (
        this.selectedIngredients.find(
          (ingredient) => ingredient.chainoptionid === option.chainoptionid,
        )
      ) {
        this.localCurrentlySelected.push(option as Ingredient);
      }
    });
  }
  ngAfterViewInit() {
    this.ingredientGroup.options.forEach((option) => {
      if (
        option.isdefault &&
        !this.localCurrentlySelected.length &&
        !this.isEditMode
      ) {
        this.selectIngredient(option as Ingredient);
      }
    });
  }

  ngOnDestroy() {
    this.ingredientGroup.options.forEach((option) =>
      this.removeIngredient(option as Ingredient),
    );
  }

  submodifierClick(ingredient: Ingredient, event: Event) {
    event.stopPropagation();
    if (
      this.selectedIngredientPipe.transform(
        ingredient,
        this.selectedIngredients,
      )
    ) {
      this.removeIngredient(ingredient);
    } else {
      this.selectIngredient(ingredient);
    }
  }

  private selectIngredient(ingredient: Ingredient) {
    const max: number =
      !this.ingredientGroup!.maxselects && !this.ingredientGroup!.mandatory
        ? this.ingredientGroup!.options?.length
        : this.ingredientGroup!.maxselects
        ? Number(this.ingredientGroup!.maxselects)
        : 1;
    if (this.localCurrentlySelected.length >= max) {
      this.removeIngredient(this.localCurrentlySelected.shift()!);
    }
    this.productCustomization.addToSelectedIngredients(ingredient);
    this.localCurrentlySelected.push(ingredient);
  }

  private removeIngredient(ingredient: Ingredient) {
    this.productCustomization.removeFromSelectedIngredients(ingredient);
    this.localCurrentlySelected = this.localCurrentlySelected.filter(
      (selectedIngredient) =>
        selectedIngredient.chainoptionid !== ingredient.chainoptionid,
    );
  }
}
