import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-os-taste-of-texas',
  templateUrl: './os-taste-of-texas.component.html',
  styleUrls: ['os-taste-of-texas.component.scss'],
})
export class OsTasteOfTexasComponent implements OnInit {
  parallax1 = {
    delay: 0.5,
    overflow: false,
  };

  parallax2 = {
    delay: 1,
    scale: 1.5,
    transition: 'cubic-bezier(0,0,0,1)',
    overflow: false,
  };

  constructor() {}
  ngOnInit() {}
}
