import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {GlobalStateModel, LayoutSection} from '@models/index';
import {Select} from '@ngxs/store';
import {GetHomepageResponse} from '@server/content/responses';
import {Observable} from 'rxjs';
import {Router} from "@angular/router";

@Component({
  selector: 'app-block-marquee',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './block-marquee.component.html',
  styleUrls: ['block-marquee.component.scss'],
})
export class BlockMarqueeComponent {
  @Input() section!: LayoutSection;

  constructor(private router: Router) {
  }

  linkClick(link?: string) {
    if (!link) return;
    if (link.includes('http')) {
      // External link: Open in a new tab
      window.open(link, '_blank');
    } else {
      // Internal link: Use Angular's router
      this.router.navigateByUrl(link);
    }
  }
}
