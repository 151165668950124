import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { GlobalStateModel } from '@models/index';
import { Store } from '@ngxs/store';
import { map, Observable, of, switchMap } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class GroupOrderGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.user.user)
      .pipe(
        switchMap((user) => {
          if (
            !user &&
            route.fragment !== 'join' &&
            route.fragment !== 'guest'
          ) {
            this.router.navigate(['order', 'group-order'], {
              fragment: 'guest',
            });
            return of(false);
          }
          if (route.fragment === 'update') {
            return this.store
              .selectOnce((state: GlobalStateModel) => state.order.groupOrder)
              .pipe(
                switchMap((groupOrder) => {
                  if (groupOrder) {
                    return of(true);
                  } else {
                    return fromPromise(
                      this.router.navigate(['order', 'group-order'], {
                        fragment: 'create',
                      }),
                    ).pipe(map(() => false));
                  }
                }),
              );
          }
          return of(true);
        }),
      );
  }
}
