export class HttpQueryParams {
  public readonly params: Record<string, string | number | boolean> = {};

  /**
   * Utility class for simplification of working with query parameters before they get appended to a URL
   * @param initialParams an object containing a set of key value pairs used to set the initial query parameters
   */
  constructor(initialParams: Record<string, string | number | boolean> = {}) {
    Object.keys(initialParams).forEach((key) =>
      this.append(key, initialParams[key]),
    );
    // if (initialParams) {
    //   this.params = initialParams
    // } else {
    //   this.params = {};
    // }
  }

  /**
   * Adds a query param to the current
   * @param key
   * @param value
   */
  append(key: string, value: string | number | boolean): void {
    this.params[key] = value;
  }

  /**
   * Removes the specified key if it exists
   * @param key
   */
  remove(key: string): void {
    if (this.params[key]) {
      delete this.params[key];
    }
  }

  /**
   * Retrieve the current keys
   * @return Returns an array of key names
   */
  keys(): string[] {
    return Object.keys(this.params);
  }

  /**
   * Retrieve the current value for the given key
   * @param key
   * @return Returns value for given key. If key does not exist, returns undefined.
   */
  getValue(key: string): string | number | boolean | undefined {
    return this.params[key] ? this.params[key] : undefined;
  }

  /**
   * Returns the formatted query parameter string to be attached to the URL
   * @example ?key1=value1&key2=value2...
   */
  toQueryString(): string {
    const queryParams: string[] = [];
    Object.keys(this.params).forEach((key) => {
      queryParams.push(`${key}=${this.params[key]}`);
    });
    return queryParams.length > 0 ? '?'.concat(queryParams.join('&')) : '';
  }

  toString() {
    return this.params.toString();
  }
}
