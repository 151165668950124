/* eslint-disable simple-import-sort/imports */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Module */
import { ProfileModule } from './profile.module';

/* Containers */
import * as profileContainers from './containers';

/* Guards */
import * as profileGuards from './guards';

/* Routes */
export const ROUTES: Routes = [
  {
    path: 'profile',
    canActivate: [profileGuards.ProfileGuard],
    component: profileContainers.ProfileDashboardComponent,
  },
  {
    path: 'unsubscribe',
    component: profileContainers.UnsubscribeComponent,
  },
];

@NgModule({
  imports: [ProfileModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
