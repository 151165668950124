<ng-container *ngIf="menu">
  <ng-container *ngIf="homepage$ | async as homepage">
  <div [style.background-color]="currentCategoryID === 'saved-products' ? homepage?.my_favorites_background_color : category?.content?.background_color">
    <div class="container-lg">
      <div
        class="d-flex flex-row gap-2 pt-2 pt-lg-4 pb-2 scroll-switcher"
        style="overflow-x: auto">
        <ng-container *ngIf="savedProducts && savedProducts.length">
          <button
            (click)="onClick(0)"
            type="button"
            [class.btn-dark]="currentCategoryID === 'saved-products'"
            [class.btn-outline-dark]="currentCategoryID !== 'saved-products'"
            class="btn btn-sm d-flex align-items-center first">
            <small class="category-title w-100 text-nowrap"
              >My Favorites</small
            >
          </button>
        </ng-container>
        <ng-container
          *ngFor="
            let category of menu.categories;
            trackBy: renderTrackBy;
            last as last;
            first as first
          ">
          <button
            (click)="onClick(category.id)"
            type="button"
            class="btn btn-sm d-flex align-items-center"
            [class.btn-dark]="category.id.toString() === currentCategoryID"
            [class.btn-outline-dark]="
              category.id.toString() !== currentCategoryID
            "
            [class.first]="
              first &&
              (!savedProducts || (savedProducts && !savedProducts.length))
            "
            [class.last]="last">
            <small class="category-title w-100 text-nowrap">{{
              category.name
            }}</small>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  </ng-container>
</ng-container>
