import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GlobalStateModel } from '@models/index';
import { Store } from '@ngxs/store';
import { map, Observable, of } from 'rxjs';

@Injectable()
export class ProfileGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.user.user)
      .pipe(
        map((user) => {
          if (user) {
            return true;
          } else {
            this.router.navigate(['user', 'sign-in']);
            return false;
          }
        }),
      );
  }
}
