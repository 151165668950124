import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GlobalStateModel, Product, Restaurant, User } from '@models/index';
import { Select } from '@ngxs/store';
import { collapseAnimation } from 'angular-animations';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { first, forkJoin, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-meal-name-modal',
  templateUrl: './meal-name-modal.component.html',
  styleUrls: ['meal-name-modal.component.scss'],
  animations: [collapseAnimation()],
})
export class MealNameModalComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.locations.orderLocation)
  orderLocation$!: Observable<Restaurant>;

  @Select((state: GlobalStateModel) => state.user.user)
  user$!: Observable<User>;

  @Select((state: GlobalStateModel) => state.order.groupOrderName)
  groupOrderName$!: Observable<string | null>;

  @ViewChild('nameInput') nameInput!: ElementRef<HTMLInputElement>;

  @Output() finishClicked = new EventEmitter<{
    name: string;
    specialInstructions: string;
    saveAsFavorite: boolean;
    favoriteName: string;
  }>();

  @Input() nameEditOnly = false;
  @Input() initialName?: string;

  mealNameForm: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    instructions: new FormControl(null, []),
    saveAsFavorite: new FormControl(false, []),
    favoriteName: new FormControl(null, []),
  });

  @Input() product!: Product;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    setTimeout(() => {
      this.nameInput.nativeElement.focus({ preventScroll: true });
      if ('virtualKeyboard' in navigator) {
        (navigator as any).virtualKeyboard.show();
      }
    }, 100);
    forkJoin([
      this.user$.pipe(first()),
      this.groupOrderName$.pipe(first()),
    ]).subscribe(([user, groupOrderName]) => {
      if (!this.nameEditOnly) {
        if (groupOrderName && !user) {
          this.mealNameForm.patchValue({
            name: groupOrderName,
          });
          this.name.disable();
        } else {
          if (user) {
            this.mealNameForm.patchValue({
              name:
                groupOrderName ?? `${user.first_name} ${user.last_name[0]}.`,
              favoriteName: `${user.first_name}'s ${this.product.name}`,
            });
          }
          if (groupOrderName) {
            this.name.disable();
          }
        }
      } else {
        if (this.initialName) {
          this.mealNameForm.patchValue({
            name: this.initialName,
          });
        }
      }
    });
    // this.user$
    //   .pipe(
    //     first(),
    //     filter((u) => u !== null),
    //   )
    //   .subscribe((user) => {
    //     this.groupOrderName$.subscribe((groupOrderName) => {
    //       this.mealNameForm.patchValue({
    //         name: groupOrderName ?? `${user.first_name} ${user.last_name[0]}.`,
    //         favoriteName: `${user.first_name}'s ${this.product.name}`,
    //       });
    //     });
    //   });
  }

  finish() {
    if (this.mealNameForm.invalid) {
      this.mealNameForm.markAllAsTouched();
      return;
    }
    this.finishClicked.emit({
      name: this.name.value,
      specialInstructions: this.instructions.value,
      saveAsFavorite: this.saveAsFavorite.value,
      favoriteName: this.favoriteName.value,
    });
    this.bsModalRef.hide();
  }

  get name(): AbstractControl {
    return this.mealNameForm.get('name')!;
  }
  get instructions(): AbstractControl {
    return this.mealNameForm.get('instructions')!;
  }

  get saveAsFavorite(): AbstractControl {
    return this.mealNameForm.get('saveAsFavorite')!;
  }

  get favoriteName(): AbstractControl {
    return this.mealNameForm.get('favoriteName')!;
  }
}
