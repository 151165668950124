import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-individual-perk-card',
    templateUrl: './individual-perk-card.component.html',
    styleUrls: ['individual-perk-card.component.scss'],
})
export class IndividualPerkCardComponent implements OnInit {

  @Input() perkImage:  string = '';
  @Input() perkTitle:  string = '';
  @Input() perkDescription:  string = '';

  constructor() {}
    ngOnInit() {}
}
