import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-rewards-faq-bar',
    templateUrl: './rewards-faq-bar.component.html',
    styleUrls: ['rewards-faq-bar.component.scss'],
})
export class RewardsFaqBarComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
