import { Component, Input, OnInit } from '@angular/core';
import { MealNameModalComponent } from '@common/components/meal-name-modal/meal-name-modal.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-added-ingredients-container',
  templateUrl: './added-ingredients-container.component.html',
  styleUrls: ['added-ingredients-container.component.scss'],
})
export class AddedIngredientsContainerComponent {
  bsModalRef?: BsModalRef;

  constructor(private modalService: BsModalService) {}
  openMealNameModal() {
    const initialState: ModalOptions = {
      initialState: {
        list: [
          'Open a modal with component',
          'Pass your data',
          'Do something else',
          '...',
        ],
        title: 'Modal with component',
      },
    };
    this.bsModalRef = this.modalService.show(
      MealNameModalComponent,
      initialState,
    );
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
