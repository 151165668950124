import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FavoriteLocation, GlobalStateModel, Restaurant } from '@models/index';
import { Select } from '@ngxs/store';
import { FavoriteLocationPipe } from '@pipes/favorite-location.pipe';
import {
  fadeInOnEnterAnimation,
  fadeInUpOnEnterAnimation,
} from 'angular-animations';
import * as mapboxgl from 'mapbox-gl';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['location-list.component.scss'],
  animations: [fadeInUpOnEnterAnimation()],
})
export class LocationListComponent {
  @Input() mapLocations!: Restaurant[];
  @Input() favoriteLocations!: FavoriteLocation[];
  @Input() isLoading: boolean = false;
  @Input() geolocationAllowed: boolean = false;

  @Output() locationSelected = new EventEmitter<Restaurant>();
  @Output() geolocationClick = new EventEmitter<void>();
  @Output() searchRan = new EventEmitter<void>();

  showCateringOnly = false;
  showMobilePickupOnly = false;

  toggleCateringOnly() {
    this.showCateringOnly = !this.showCateringOnly;
  }
  toggleMobilePickupOnly() {
    this.showMobilePickupOnly = !this.showMobilePickupOnly;
  }

  selectLocation(location: Restaurant): void {
    this.locationSelected.emit(location);
  }

  favoriteLocationTrackBy(index: number, location: FavoriteLocation): string {
    return `${index}${location.vendorid}`;
  }

  mapLocationTrackBy(index: number, location: Restaurant): string {
    return `${index}${location.id}`;
  }
}
