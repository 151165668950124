import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FavoriteLocation,
  GlobalStateModel,
  Restaurant,
  User,
} from '@models/index';
import { LocationsService } from '@modules/locations/services';
import { Select, Store } from '@ngxs/store';
import { FavoriteLocationPipe } from '@pipes/favorite-location.pipe';
import { Locations } from '@server/vendor/directus/interfaces/directus-collection.interface';
import { fadeInUpOnEnterAnimation } from 'angular-animations';
import * as mapboxgl from 'mapbox-gl';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['location-card.component.scss'],
})
export class LocationCardComponent {
  @Input() location?: Restaurant;
  @Input() favoriteLocation?: FavoriteLocation;
  @Input() favoriteLocations!: FavoriteLocation[];
  @Input() user?: User;
  @Output() locationSelected = new EventEmitter<Restaurant>();

  constructor(private locations: LocationsService) {
    if (this.location && this.favoriteLocation) {
      throw new Error('cannot use both location and favorite location');
    }
  }

  selectLocation(location: Restaurant | FavoriteLocation): void {
    if ('vendorid' in location) {
      this.locationSelected.emit(
        this.locations.favoriteLocationToRestaurant(location),
      );
    } else {
      this.locationSelected.emit(location);
    }
  }

  toggleFavoriteLocation(location: Restaurant) {
    this.locations.toggleFavoriteLocation(location).subscribe(() => {});
  }
}
