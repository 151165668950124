<div class="parallax">
  <div class="foreground">
    <img
      alt="Burrito ingredients in bowls and op top of a cutting board"
      style="z-index: 2"
      animateOnScroll
      animationName="animate__fadeInUp animate__animated animate__slower"
      class="taste-ingredients-img container hide-on-init"
      src="/assets/images/our-story/taste-of-texas-foreground-min.png" />
  </div>

  <div
    class="py-5 d-flex flex-column gap-4 position-relative container midground">
    <h1
      class="taste-heading os-heading-copy hide-on-init"
      animateOnScroll
      animationName="animate__animated animate__fadeInLeft animate__slow">
      A Taste of Texas<br />
      in Every Bite
    </h1>

    <p
      class="taste-body os-body-copy text-card-sky-blue hide-on-init"
      animateOnScroll
      animationName="animate__animated animate__fadeInLeft animate__slow">
      Inspiration, preparation, ingredients, culture, and flavors, all drawn
      from Texas culinary heritage. Marinated and grilled to perfection.
      <b>You won’t find it anywhere else.</b>
    </p>
  </div>

  <div class="background h-100">
    <!--    <img-->
    <!--      class="background background-img"-->
    <!--      src="/assets/images/our-story/wood-back.jpg" />-->
  </div>
</div>
