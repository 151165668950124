import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AllergenCategory,
  AllergenTag,
  Contains,
  GlobalStateModel,
  Ingredient,
  IngredientGroup,
  Lifestyle,
  PlantBased,
} from '@models/index';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ingredient-card',
  templateUrl: './ingredient-card.component.html',
  styleUrls: ['ingredient-card.component.scss'],
})
export class IngredientCardComponent implements AfterViewInit, OnDestroy {
  @Select((state: GlobalStateModel) => state.nutrition.allergenCategories)
  allergenCategories$!: Observable<AllergenCategory[]>;
  @Select((state: GlobalStateModel) => state.nutrition.allergenTags)
  allergenTags$!: Observable<AllergenTag[]>;
  @Select((state: GlobalStateModel) => state.nutrition.selectedAllergenTags)
  selectedAllergenTags$!: Observable<AllergenTag[]>;
  inputnumber = 1;
  isCollapsed = true;

  @Input() isSelected: boolean = false;
  @Input() isFlagged = false;
  @Input() isSpicy = false;
  @Input() ingredient!: Ingredient;
  @Input() selectedIngredients!: Ingredient[];
  @Input() isEditMode: boolean = false;
  @Output() ingredientSelected = new EventEmitter<Ingredient>();
  @Output() ingredientRemoved = new EventEmitter<Ingredient>();

  ngAfterViewInit() {
    // setTimeout(() => {
    if (this.ingredient.isdefault && !this.isEditMode) {
      this.ingredientSelected.emit(this.ingredient);
    }
    // }, 500);
  }

  ngOnDestroy() {
    this.ingredientRemoved.emit(this.ingredient);
  }

  plus() {
    this.inputnumber = this.inputnumber + 1;
  }

  minus() {
    if (this.inputnumber != 0) {
      this.inputnumber = this.inputnumber - 1;
    }
  }
  ingredientSelect(ingredient: Ingredient, event: Event) {
    this.ingredientSelected.emit(ingredient);
  }

  ingredientRemove(ingredient: Ingredient, event: Event) {
    this.ingredientRemoved.emit(ingredient);
  }
}
