<div class="bg-light">
  <div class="container d-flex flex-row justify-content-center gap-3 pt-5">
    <div
      class="col-3 d-none d-lg-flex align-items-end justify-content-start p-0">
      <img
        src="https://cms.freebirds.dineengine.com/assets/185d4ab4-5fad-406b-8d49-000d80be98d6"
        class="side-images"
        alt="Freebirds Burrito" />
    </div>
    <div class="d-flex flex-column gap-4 col-12 col-lg-6 px-3 pb-5">
      <h1>How It Works</h1>
      <div class="d-flex flex-column gap-2">
        <h3 class="text-primary">In Restaurant</h3>
        <p class="m-0">
          Open your app at the register and scan the QR code to redeem. Forgot
          to use it at checkout? No problem, scan your receipt to earn points!
        </p>
      </div>
      <div class="d-flex flex-column gap-2">
        <h3 class="text-primary">Ordering Online</h3>
        <p class="m-0">
          Sign in to start your order online or in the mobile app. Start by
          redeeming available offers immediately or tap on an eligible reward at
          checkout. Track your points and collect rewards!
        </p>
      </div>
    </div>
    <div class="col-3 d-none d-lg-flex align-items-end justify-content-end p-0">
      <img
        src="https://cms.freebirds.dineengine.com/assets/b3f91936-6877-4073-8c68-e6a6b4717650"
        class="side-images"
        alt="Person holding phone with Freebirds Mobile App" />
    </div>
  </div>
</div>
