import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { GlobalStateModel } from '@models/index';
import { Store } from '@ngxs/store';
import { InitializeStaticMenu } from '@store/actions/content.actions';
import { map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StaticMenuResolver implements Resolve<boolean> {
  constructor(private store: Store) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.content.staticMenu)
      .pipe(
        switchMap((staticMenu) => {
          if (staticMenu) {
            return of(true);
          } else {
            return this.store
              .dispatch(new InitializeStaticMenu())
              .pipe(map(() => true));
          }
        }),
      );
  }
}
