import { Pipe, PipeTransform } from '@angular/core';
import { Ingredient, Product } from '@models/index';

@Pipe({
  name: 'selectedOptionsTotal',
  pure: false,
})
export class SelectedOptionsTotalPipe implements PipeTransform {
  transform(
    options: Partial<Ingredient>[],
    product?: Product,
    upsell?: Product,
  ): number {
    let total = 0;
    options.forEach((option) => {
      total += option.cost!;
    });
    if (product) {
      total += product.cost;
    }
    if (upsell) {
      total += upsell.cost;
    }
    return total;
  }
}
