import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Ingredient, IngredientGroup, Product } from '@models/index';
import { ProductCustomizationService } from '@modules/order/services';
import { SlugPipe } from '@pipes/slug.pipe';

@Component({
  selector: 'app-ingredient-group-container',
  templateUrl: './ingredient-group-container.component.html',
  styleUrls: ['ingredient-group-container.component.scss'],
})
export class IngredientGroupContainerComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() isUpsell = false;
  @Input() ingredientGroup!: IngredientGroup;
  @Input() ingredientGroupType: 'customizable' | 'upsell' | 'nonCustomizable' =
    'customizable';
  @Input() selectedIngredients!: Ingredient[];
  @Input() selectedProduct?: Product;
  @Input() ingredientList!: string[];
  @Input() isEditMode: boolean = false;

  localCurrentlySelected: Ingredient[] = [];

  observer?: IntersectionObserver;

  constructor(
    private slugPipe: SlugPipe,
    private productCustomization: ProductCustomizationService,
  ) {}

  ngOnInit() {
    this.ingredientList.push(this.ingredientGroup.description);
    this.ingredientGroup.options.forEach((option) => {
      if (
        this.selectedIngredients.find(
          (ingredient) => ingredient.chainoptionid === option.chainoptionid,
        )
      ) {
        this.localCurrentlySelected.push(option as Ingredient);
      }
      if (option.isdefault && !this.isEditMode) {
        this.selectIngredient(option as Ingredient);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // if there is an item in localCurrentlySelected that is not in selectedIngredients, remove it
    this.localCurrentlySelected = this.localCurrentlySelected.filter(
      (selectedIngredient) =>
        this.selectedIngredients.find(
          (ingredient) =>
            ingredient.chainoptionid === selectedIngredient.chainoptionid,
        ),
    );
    // if there is an item in selectedIngredients that is not in localCurrentlySelected, add it if it is in the current group
    this.selectedIngredients.forEach((selectedIngredient) => {
      if (
        !this.localCurrentlySelected.find(
          (ingredient) =>
            ingredient.chainoptionid === selectedIngredient.chainoptionid,
        ) &&
        this.ingredientGroup.options.find(
          (option) => option.chainoptionid === selectedIngredient.chainoptionid,
        )
      ) {
        this.localCurrentlySelected.push(selectedIngredient as Ingredient);
      }
    });
  }

  ngAfterViewInit() {
    const options: IntersectionObserverInit = {
      threshold: 1.0,
      rootMargin: '-25% 0%',
    };

    // const callback = (
    //   entries: IntersectionObserverEntry[],
    //   observer: IntersectionObserver,
    // ) => {
    //   if (
    //     entries &&
    //     entries.length &&
    //     entries[0].isIntersecting &&
    //     entries[0].intersectionRatio === 1
    //   ) {
    //     setTimeout(() => {
    //       const els = document.getElementsByClassName('active-scroll');
    //       // @ts-ignore
    //       for (let el of els[Symbol.iterator]()) {
    //         el.classList.remove('active-scroll');
    //       }
    //       const el = document.getElementById(
    //         'scroll_'.concat(
    //           this.slugPipe.transform(this.ingredientGroup.description),
    //         ),
    //       );
    //       if (el) {
    //         el.classList.add('active-scroll');
    //         const scrollItem = el;
    //         const subnavScroll = document.getElementById('category-scroll');
    //         const left = scrollItem.offsetLeft;
    //         const itemWidth = scrollItem.offsetWidth;
    //         const screenwidth = document.documentElement.clientWidth;
    //         const scrollTo = left - screenwidth / 2 + itemWidth / 2;
    //
    //         if (subnavScroll) {
    //           subnavScroll.scrollTo({
    //             top: 0,
    //             left: scrollTo,
    //             behavior: 'smooth',
    //           });
    //         }
    //       }
    //     }, 300);
    //   }
    // };
    // this.observer = new IntersectionObserver(callback, options);
    // const shell = document.getElementById(
    //   'shell_'.concat(
    //     this.slugPipe.transform(this.ingredientGroup.description),
    //   ),
    // );
    // if (shell) {
    //   this.observer.observe(shell);
    // }
  }

  ngOnDestroy() {
    this.observer?.disconnect();
    this.ingredientList.splice(
      this.ingredientList.indexOf(this.ingredientGroup.description),
    );
  }

  // onOptionSelected(option: Ingredient) {
  //   if (this.ingredientGroup.maxselects === null) {
  //     this.localCurrentlySelected = [];
  //   }
  //   this.localCurrentlySelected.push(option);
  //   this.localCurrentlySelected.slice();
  //   this.optionSelected.emit({
  //     option: option,
  //     optionGroup: this.ingredientGroup,
  //   });
  // }
  //
  // onOptionRemoved(option: Ingredient) {
  //   this.localCurrentlySelected = this.localCurrentlySelected.filter(
  //     (selected) => selected.id !== option.id,
  //   );
  //   this.optionRemoved.emit({
  //     option: option,
  //     optionGroup: this.ingredientGroup,
  //   });
  // }
  //
  // childOptionSelected(optionChange: OptionGroupChange) {
  //   this.optionSelected.emit(optionChange);
  // }
  //
  // childOptionRemoved(optionChange: OptionGroupChange) {
  //   this.optionRemoved.emit(optionChange);
  // }

  selectIngredient(ingredient: Ingredient) {
    const max: number =
      !this.ingredientGroup!.maxselects && !this.ingredientGroup!.mandatory
        ? this.ingredientGroup!.options?.length
        : this.ingredientGroup!.maxselects
        ? Number(this.ingredientGroup!.maxselects)
        : 1;
    if (this.localCurrentlySelected.length >= max) {
      this.removeIngredient(this.localCurrentlySelected.shift()!);
    }
    this.productCustomization.addToSelectedIngredients(ingredient);
    this.localCurrentlySelected.push(ingredient);
  }

  removeIngredient(ingredient: Ingredient) {
    this.productCustomization.removeFromSelectedIngredients(ingredient);
    this.localCurrentlySelected = this.localCurrentlySelected.filter(
      (selectedIngredient) =>
        selectedIngredient.chainoptionid !== ingredient.chainoptionid,
    );
  }

  optionTrackByFn(index: number, option: Ingredient) {
    return `${index}${option.chainoptionid}`;
  }
}
