<ng-container *ngIf="featuredAds.length">
  <div class="promotion-section bg-info">
    <div
      class="container promotion-container d-flex flex-column gap-3 justify-content-center py-5">
      <h1 class="w-100 text-center font-filmotype">featured</h1>

      <div class="promo-flex pb-0 pb-md-3">
        <ng-container *ngFor="let ad of featuredAds!; index as index">
          <ng-container *ngIf="index < 3">
            <app-ad-card [adCard]="ad!.item!"></app-ad-card>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
