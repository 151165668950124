import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalStateModel } from '@models/index';
import { AuthService } from '@modules/auth/services';
import { Store } from '@ngxs/store';
import { AnalyticsService } from '@services/analytics/analytics.service';
import * as moment from 'moment';

@Component({
  selector: 'app-go-guest',
  templateUrl: './go-guest.component.html',
  styleUrls: ['go-guest.component.scss'],
  providers: [AuthService],
})
export class GoGuestComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.email, Validators.required]),
    password: new FormControl(null, [Validators.required]),
  });
  loginLoading = false;
  facebookLoginLoading = false;
  errorMessage = '';
  signIn = true;
  createLoading: boolean = false;
  createAccountFrom = new FormGroup({
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    emailOptIn: new FormControl(true, []),
    phoneNumber: new FormControl(null, [Validators.required]),
    smsOptIn: new FormControl(true, []),
    birthday: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required]),
    passwordConfirmation: new FormControl(null, [Validators.required]),
    termsAndConditions: new FormControl(false, [Validators.requiredTrue]),
  });

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private store: Store,
  ) {}

  ngOnInit() {}

  login() {
    this.loginLoading = true;
    if (this.loginForm.valid) {
      this.auth.login(this.email!.value, this.password!.value).subscribe({
        next: () => {
          this.loginLoading = false;
          this.router.navigate(['order', 'group-order'], {
            fragment: 'create',
          });
        },
        error: (error) => {
          this.loginLoading = false;
          this.errorMessage =
            error?.error?.message ??
            error.message ??
            'Email or Password Incorrect';
        },
      });
    } else {
      this.loginForm.markAllAsTouched();
      this.loginLoading = false;
    }
  }

  loginWithFacebook() {
    this.facebookLoginLoading = true;
    this.auth.loginWithFacebook().subscribe({
      next: () => {
        this.facebookLoginLoading = false;
        this.router.navigate([''], { fragment: 'orders' });
      },
      error: (err) => {
        this.facebookLoginLoading = false;
        this.errorMessage = err;
      },
    });
  }

  get email(): AbstractControl {
    return this.loginForm.get('email')!;
  }

  get password(): AbstractControl {
    return this.loginForm.get('password')!;
  }

  onCreateAccount() {
    this.createLoading = true;
    if (this.createAccountFrom.valid) {
      this.auth
        .createAccount(
          this.firstName.value,
          this.lastName.value,
          this.createEmail.value,
          this.emailOptIn.value,
          this.phoneNumber.value,
          this.smsOptIn.value,
          moment(this.birthday.value).format('YYYY-MM-DD'),
          this.createPassword.value,
          this.passwordConfirmation.value,
          this.termsAndConditions.value,
        )
        .subscribe({
          next: () => {
            this.createLoading = false;
            this.analytics.logAccountSignupPageView(
              this.store.selectSnapshot(
                (state: GlobalStateModel) => state.user.user,
              )!,
            );
            this.router.navigate(['order', 'group-order'], {
              fragment: 'create',
            });
          },
          error: (error) => {
            this.createLoading = false;
            this.errorMessage =
              error?.error?.message ??
              error.message ??
              'Email or Password Incorrect';
          },
        });
    } else {
      this.createAccountFrom.markAllAsTouched();
      this.createLoading = false;
    }
  }

  private get firstName(): AbstractControl {
    return this.createAccountFrom.get('firstName')!;
  }

  private get lastName(): AbstractControl {
    return this.createAccountFrom.get('lastName')!;
  }

  private get createEmail(): AbstractControl {
    return this.createAccountFrom.get('email')!;
  }

  private get emailOptIn(): AbstractControl {
    return this.createAccountFrom.get('emailOptIn')!;
  }

  private get phoneNumber(): AbstractControl {
    return this.createAccountFrom.get('phoneNumber')!;
  }

  private get smsOptIn(): AbstractControl {
    return this.createAccountFrom.get('smsOptIn')!;
  }

  private get birthday(): AbstractControl {
    return this.createAccountFrom.get('birthday')!;
  }

  private get createPassword(): AbstractControl {
    return this.createAccountFrom.get('password')!;
  }

  private get passwordConfirmation(): AbstractControl {
    return this.createAccountFrom.get('passwordConfirmation')!;
  }

  private get termsAndConditions(): AbstractControl {
    return this.createAccountFrom.get('termsAndConditions')!;
  }
}
