import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hrefTel',
})
export class HrefTelPipe implements PipeTransform {
  transform(phoneNumber: string): string {
    return `tel:${phoneNumber.replace(RegExp('^[0-9]*$'), '')}`;
  }
}
