import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sm-info-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sm-info-modal.component.html',
  styleUrls: ['sm-info-modal.component.scss'],
})
export class SmInfoModalComponent implements OnInit {
  @Input() name!: string;
  @Input() badge?: string;
  @Input() description!: string;
  @Input() image!: string;
  constructor(private modalService: BsModalService) {}

  ngOnInit() {}

  closeModal() {
    this.modalService.hide();
  }
}
