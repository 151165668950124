import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-os-hospitality',
  templateUrl: './os-hospitality.component.html',
  styleUrls: ['os-hospitality.component.scss'],
})
export class OsHospitalityComponent implements OnInit {
  parallaxLeft = {
    scale: 1.2,
    delay: 1.6,
    overflow: false,
  };

  parallaxRight = {
    scale: 1.2,
    delay: 1.6,
    overflow: false,
    // orientation: 'up',
  };
  constructor() {}
  ngOnInit() {}
}
