<div
  class="container w-100 d-flex flex-column align-items-center gap-2 py-4 py-md-5"
  id="menu">
  <ng-container *ngIf="!(order && order.products.length > 0)">
    <ng-container *ngIf="order">
      <h1
        routerLink="/"
        fragment="menu"
        class="text-center font-filmotype btn-link text-dark cursor-pointer">
        Start Your Order
      </h1>
    </ng-container>

    <ng-container *ngIf="!order">
      <h1
        routerLink="/start-order"
        class="text-center font-filmotype btn-link text-dark cursor-pointer mt-2">
        Start Your Order
      </h1>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="orderLocation && order">
    <div class="d-flex flex-column flex-nowrap align-items-center">
      <div class="text-center text-uppercase">
        {{ order.deliverymode | handoffModeName }} From
      </div>
      <h4 class="text-center">{{ orderLocation.name }}</h4>
      <ng-container *ngIf="!(groupOrder && !isGroupOrderOwner)">
        <div
          class="text-center d-flex flex-row flex-wrap justify-content-center gap-2">
          <button
            type="button"
            class="btn btn-link btn-sm p-0"
            routerLink="/start-order">
            Change Location
          </button>
          <ng-container *ngIf="orderLocation.supportsgrouporders">
            <!--                <ng-container *ngIf="user">-->
            <div class="text-muted">|</div>
            <button
              type="button"
              class="btn btn-link btn-sm p-0"
              routerLink="/order/group-order"
              [fragment]="groupOrder ? 'update' : 'create'">
              <ng-container *ngIf="groupOrder">Edit</ng-container>
              <ng-container *ngIf="!groupOrder">Start</ng-container>
              Group Order
            </button>
            <!--                </ng-container>-->
          </ng-container>
          <ng-container *ngIf="!order.deliveryaddress">
            <div class="text-muted d-none d-sm-block">|</div>
            <button
              routerLink="/start-order"
              fragment="delivery"
              type="button"
              class="btn btn-link btn-sm p-0">
              Switch to Delivery
            </button>
          </ng-container>
          <ng-container *ngIf="order.deliveryaddress">
            <div class="text-muted d-none d-sm-block">|</div>
            <button
              (click)="editDeliveryAddress(order)"
              type="button"
              class="btn btn-link btn-sm p-0">
              Edit Delivery Info
            </button>

            <div class="text-muted">|</div>
            <button
              routerLink="/start-order"
              fragment="pickup"
              type="button"
              class="btn btn-link btn-sm p-0">
              Switch to Pickup
            </button>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
