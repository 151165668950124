import { Pipe, PipeTransform } from '@angular/core';
import * as Case from 'case';

@Pipe({
  name: 'stringJoin',
})
export class StringJoinPipe implements PipeTransform {
  transform(array: string[], joinString: string = ', '): string {
    array = array.map((item) => Case.capital(item.trim()));
    return array.join(joinString);
  }
}
