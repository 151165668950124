<div class="bg-white">
  <div class="container">
    <div class="d-flex flex-column flex-lg-row grow-1">
      <div class="d-flex grow-3 basis-0 align-items-start">
        <img src="../../../../assets/images/backgrounds/guest-rewards-hero.jpg" class="gr-jumbotron-image">
      </div>
      <div class="d-flex grow-2 basis-0 justify-content-center">
        <div class="auth-shell w-100 d-flex flex-column gap-5 py-4 py-md-5 align-items-center">
          <app-create-account-form
            [parentForm]="createAccountFrom"
            [errorMessage]="errorMessage"
            [createLoading]="createLoading"
            [createTitle]="'Sign Up For Free Chips & Dip'"
            (createClicked)="onCreateAccount()">
          </app-create-account-form>
          <div class="d-flex flex-column w-100 align-items-center gap-3">
            <div class="d-flex flex-column gap-1 w-100 align-items-center">
              <h5 class="auth-switch-page-heading w-75 text-center">
                Have an Account?
              </h5>
              <div>Sign In to Redeem Your Rewards.</div>
            </div>
            <button
              routerLink="/user/sign-in"
              [preserveFragment]="true"
              type="button"
              class="btn btn-outline-secondary">
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
