<ng-container *ngIf="!user">
  <div class="bg-dark text-light">
    <div class="container py-3 gap-3 d-flex flex-column flex-md-row">
      <div
        class="d-flex gap-3 grow-1 flex-column flex-md-row justify-content-center justify-content-md-start align-items-center">
        <img
          class="perks-icon d-none d-md-block"
          src="/assets/images/icons/Icons/fb-perks-inverse.svg"
          alt="freebirds perks icon" />
        <h4 class="text-left d-none d-md-block">
          {{homepage?.loyalty_sign_up_message || 'Sign Up and Earn Awesome Rewards'}}
        </h4>
        <h5 class="text-center d-block d-md-none">
          {{homepage?.loyalty_sign_up_message || 'Sign Up and Earn Awesome Rewards'}}
        </h5>
      </div>
      <div
        class="d-flex gap-3 shrink-1 flex-nowrap align-items-center justify-content-center justify-content-md-end">
        <div>
          <button
            routerLink="/user/create-an-account"
            type="button"
            class="btn btn-primary text-nowrap">
            Sign Up
          </button>
        </div>
        <div class="vertical-divider"></div>
        <div>
          <button
            routerLink="/user/sign-in"
            type="button"
            class="btn btn-outline-light text-nowrap">
            Sign In
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="user && pastOrders.length">
  <div class="container py-4 gap-3 d-flex flex-column" [@expandOnEnter]="true">
    <div id="recentOrders" class="navbar-anchor"></div>
    <h4 class="text-center">Recent Orders</h4>
    <div class="reorder-card-container">
      <app-reorder-card [order]="pastOrders[0] || null"></app-reorder-card>
      <app-reorder-card
        [order]="pastOrders[1] || null"
        class="d-none d-md-block"></app-reorder-card>
      <app-reorder-card
        [order]="pastOrders[2] || null"
        class="d-none d-md-block"></app-reorder-card>
    </div>
  </div>
</ng-container>
