<!-- open -->
<ng-container *ngIf="isAvailable">
  <button type="button" class="location-pin border-0 p-0 bg-transparent">
    <img
      class="location-pin-image cursor-pointer"
      src="assets/images/icons/Icons/fb-locations-pin-open-orange.svg"
      alt="open location pin" />
  </button>
</ng-container>

<!-- closed -->
<ng-container *ngIf="!isAvailable">
  <button type="button" class="location-pin border-0 p-0 bg-transparent">
    <img
      class="location-pin-image"
      src="assets/images/icons/Icons/fb-location-pin-closed-orange.svg"
      alt="closed location pin" />
  </button>
</ng-container>
