<div class="modal-header align-items-center rounded-top">
  <img
    class="h4-birds"
    src="../../../../assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt=""/>
  <h4 class="modal-title grow-1">Please don't go!</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1">
  <div class="d-flex flex-column gap-3">
    <div class="d-flex justify-content-center">
      <img
        class="w-25"
        src="/assets/images/icons/Icons/fb-garlic-sad.svg"
        alt=""/>
    </div>
    <div>
      If you leave now, you will lose your group order! Are you sure you want to
      leave?
    </div>
    <small>To leave, try closing your browser tab again.</small>
  </div>
</div>

<div class="modal-footer">
  <div class="d-flex flex-row">
    <button type="button" class="btn btn-success" aria-label="Continue" (click)="bsModalRef.hide()">
      Stay
    </button>
  </div>
</div>

<!--<div class="modal-footer rounded-bottom">-->
<!--  <div class="d-flex justify-content-end w-100">-->
<!--    <app-button-->
<!--      [classes]="'btn btn-secondary'"-->
<!--      (buttonClick)="bsModalRef.hide()"-->
<!--    >Apply</app-button-->
<!--    >-->
<!--  </div>-->
<!--</div>-->
