import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '@models/index';
import { BasketProduct } from '@server/vendor/olo/interfaces';

@Pipe({
  name: 'suggestedProductFilter',
})
export class SuggestedProductFilterPipe implements PipeTransform {
  transform(products: Product[], basketProducts: BasketProduct[]): Product[] {
    return products.filter((product) => {
      return !basketProducts.find((basketProduct) => {
        return basketProduct.productId === product.id;
      });
    });
  }
}
