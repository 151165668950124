import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewSavedProduct } from '@models/index';
import {
  CreateAccountDto,
  ForgotPasswordDto,
  LoginDto,
  LoginWithFacebookDto,
  LoginWithTokenDto,
  UpdateUserInfoDto,
} from '@server/user/dto';
import { CreateSavedProductDto } from '@server/user/dto/create-saved-product.dto';
import { SaveFavoriteLocationDto } from '@server/user/dto/save-favorite-location.dto';
import { UnsubscribeDto } from '@server/user/dto/unsubscribe.dto';
import {
  CreateAccountResponse,
  CreateSavedProductResponse,
  DeleteFavoriteLocationResponse,
  ForgotPasswordResponse,
  GetFavoriteLocationsResponse,
  GetPastOrdersResponse,
  GetSavedAddressesResponse,
  GetSavedCardsResponse,
  GetSavedProductsResponse,
  GetUserInfoResponse,
  LoginResponse,
  LoginWithFacebookResponse,
  LoginWithTokenResponse,
  LogoutResponse,
  SaveFavoriteLocationResponse,
  UnsubscribeResponse,
  UpdateUserInfoResponse,
} from '@server/user/responses';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private usersAPIPrefix = environment.apiBaseURL + '/api/user';

  constructor(private http: HttpClient) {}

  login(
    email: string,
    password: string,
    basketID?: string,
  ): Observable<LoginResponse> {
    const resource = `${this.usersAPIPrefix}/login`;
    const body: LoginDto = {
      email,
      password,
    };
    let params: HttpParams = new HttpParams();
    if (basketID) {
      params = new HttpParams({
        fromObject: {
          basketID,
        },
      });
    }
    return this.http.post<LoginResponse>(resource, body, { params });
  }

  logout(accessToken: string): Observable<LogoutResponse> {
    const resource = `${this.usersAPIPrefix}/${accessToken}`;
    return this.http.delete<LogoutResponse>(resource);
  }

  loginWithToken(
    token: string,
    basketID?: string,
  ): Observable<LoginWithTokenResponse> {
    const resource = `${this.usersAPIPrefix}/login/byToken`;
    const body: LoginWithTokenDto = {
      token,
    };
    let params: HttpParams = new HttpParams();
    if (basketID) {
      params = new HttpParams({
        fromObject: {
          basketID,
        },
      });
    }
    return this.http.post<LoginWithTokenResponse>(resource, body, { params });
  }

  loginWithFacebook(
    accessToken: string,
    basketID?: string,
  ): Observable<LoginWithFacebookResponse> {
    const resource = `${this.usersAPIPrefix}/login/byFacebook`;
    const body: LoginWithFacebookDto = {
      accessToken,
    };
    let params: HttpParams = new HttpParams();
    if (basketID) {
      params = new HttpParams({
        fromObject: {
          basketID,
        },
      });
    }
    return this.http.post<LoginWithFacebookResponse>(resource, body, {
      params,
    });
  }

  forgotPassword(email: string): Observable<ForgotPasswordResponse> {
    const resource = `${this.usersAPIPrefix}/forgotPassword`;
    const body: ForgotPasswordDto = {
      email,
    };
    return this.http.post<ForgotPasswordResponse>(resource, body);
  }

  getUserInfo(accessToken: string): Observable<GetUserInfoResponse> {
    const resource = `${this.usersAPIPrefix}/${accessToken}`;
    return this.http.get<GetUserInfoResponse>(resource);
  }

  updateUserInfo(
    accessToken: string,
    body: UpdateUserInfoDto,
  ): Observable<UpdateUserInfoResponse> {
    const resource = `${this.usersAPIPrefix}/${accessToken}`;
    return this.http.put<UpdateUserInfoResponse>(resource, body);
  }

  createAccount(
    body: CreateAccountDto,
    basketID?: string,
  ): Observable<CreateAccountResponse> {
    const resource = `${this.usersAPIPrefix}`;
    let params: HttpParams = new HttpParams();
    if (basketID) {
      params = new HttpParams({
        fromObject: {
          basketID,
        },
      });
    }
    return this.http.post<CreateAccountResponse>(resource, body, { params });
  }

  getPastOrders(accessToken: string): Observable<GetPastOrdersResponse> {
    const resource = `${this.usersAPIPrefix}/${accessToken}/pastOrders`;
    return this.http.get<GetPastOrdersResponse>(resource);
  }

  getSavedCards(
    orderingAccessToken: string,
    basketID?: string,
  ): Observable<GetSavedCardsResponse> {
    const resource = `${this.usersAPIPrefix}/${orderingAccessToken}/savedCards`;
    let params: HttpParams = new HttpParams();
    if (basketID) {
      params = new HttpParams({
        fromObject: {
          basketID,
        },
      });
    }
    return this.http.get<GetSavedCardsResponse>(resource, { params });
  }

  getFavoriteLocations(
    orderingAccessToken: string,
  ): Observable<GetFavoriteLocationsResponse> {
    const resource = `${this.usersAPIPrefix}/${orderingAccessToken}/favoriteLocations`;
    return this.http.get<GetFavoriteLocationsResponse>(resource);
  }

  saveFavoriteLocation(
    orderingAccessToken: string,
    locationID: number,
  ): Observable<SaveFavoriteLocationResponse> {
    const resource = `${this.usersAPIPrefix}/${orderingAccessToken}/favoriteLocations`;
    const body: SaveFavoriteLocationDto = {
      locationID,
    };
    return this.http.post<SaveFavoriteLocationResponse>(resource, body);
  }

  deleteFavoriteLocation(
    orderingAccessToken: string,
    locationID: number,
  ): Observable<DeleteFavoriteLocationResponse> {
    const resource = `${this.usersAPIPrefix}/${orderingAccessToken}/favoriteLocations/${locationID}`;
    return this.http.delete<DeleteFavoriteLocationResponse>(resource);
  }

  getSavedAddresses(
    orderingAccessToken: string,
  ): Observable<GetSavedAddressesResponse> {
    const resource = `${this.usersAPIPrefix}/${orderingAccessToken}/savedAddresses`;
    return this.http.get<GetSavedAddressesResponse>(resource);
  }

  deleteSavedCard(orderingAccessToken: string, savedCardID: number) {
    const resource = `${this.usersAPIPrefix}/${orderingAccessToken}/savedCards/${savedCardID}`;
    return this.http.delete(resource);
  }

  setDefaultSavedCard(orderingAccessToken: string, savedCardID: number) {
    const resource = `${this.usersAPIPrefix}/${orderingAccessToken}/savedCards/${savedCardID}`;
    return this.http.put(resource, {});
  }

  getSavedProducts(orderingAccessToken: string) {
    const resource = `${this.usersAPIPrefix}/${orderingAccessToken}/savedProducts`;
    return this.http.get<GetSavedProductsResponse>(resource);
  }

  createSavedProducts(
    orderingAccessToken: string,
    name: string,
    product: NewSavedProduct,
  ) {
    const resource = `${this.usersAPIPrefix}/${orderingAccessToken}/savedProducts`;
    const body: CreateSavedProductDto = {
      name,
      product,
    };
    return this.http.post<CreateSavedProductResponse>(resource, body);
  }

  deleteSavedProducts(orderingAccessToken: string, savedProductID: string) {
    const resource = `${this.usersAPIPrefix}/${orderingAccessToken}/savedProducts/${savedProductID}`;
    return this.http.delete<void>(resource);
  }

  unsubscribeFromEmails(userID: string, email: string) {
    const resource = `${this.usersAPIPrefix}/unsubscribe/${userID}`;
    const body: UnsubscribeDto = {
      email,
    };
    return this.http.put<UnsubscribeResponse>(resource, body);
  }
}
