<button
  [class]="classes"
  (click)="buttonClick.emit($event)"
  [disabled]="disabled">
  <ng-container *ngIf="isLoading; else content">
    <div class="btn-loader"><div></div></div>
  </ng-container>
  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</button>
