import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Category, Product, SavedProduct } from '@models/index';

@Component({
  selector: 'app-product-group-container',
  templateUrl: './product-group-container.component.html',
  styleUrls: ['product-group-container.component.scss'],
})
export class ProductGroupContainerComponent {
  @Input() category!: Category;
  @Input() products!: Product[];
  @Input() savedProducts!: SavedProduct[];
  @Input() selectedProduct!: Product;
  @Input() productGroupType: 'upsell' | 'product' | 'size' | 'saved' =
    'product';
  @Input() subcategoryName?: string;
  @Input() isEditMode: boolean = false;

  @Output() productSelect = new EventEmitter<Product>();

  @ViewChild('menuGrid') menuGrid!: ElementRef<HTMLDivElement>;

  productTrackBy(index: number, item: Product): string {
    return `${index}${item.id}`;
  }

  scrollLeft() {
    if (this.menuGrid && this.menuGrid.nativeElement) {
      this.menuGrid.nativeElement.scroll({
        left:
          this.menuGrid.nativeElement.scrollLeft -
          this.menuGrid.nativeElement.scrollWidth / this.products.length,
        behavior: 'smooth',
      });
    }
  }

  scrollRight() {
    if (this.menuGrid && this.menuGrid.nativeElement) {
      this.menuGrid.nativeElement.scroll({
        left:
          this.menuGrid.nativeElement.scrollLeft +
          this.menuGrid.nativeElement.scrollWidth / this.products.length,
        behavior: 'smooth',
      });
    }
  }

  // buttonRight() {
  //   document.getElementById('product-list').!scrollLeft += 20;
  // };

  // buttonLeft() {
  //   document.getElementById('product-list').!scrollLeft -= 20;
  // };
}
