import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  DeliveryStatus,
  GlobalStateModel,
  PastOrder,
  Restaurant,
} from '@models/index';
import { MessageHandlerData } from '@modules/checkout/models/message-handler-data.model';
import { Select, Store } from '@ngxs/store';
import { HandoffMode } from '@server/vendor/olo/interfaces';
import { CloseCart } from '@store/actions/app.actions';
import {
  ClearOrderLocation,
  ClearPreviousOrderLocation,
  SetPreviousOrderLocation,
} from '@store/actions/locations.actions';
import { InitializeMenu } from '@store/actions/menu.actions';
import {
  SetDeliveryStatus,
  SetPreviousOrder,
} from '@store/actions/order.actions';
import { interval, Observable, of, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy, AfterViewInit {
  @Select((state: GlobalStateModel) => state.order.previousOrder)
  prevOrder$!: Observable<PastOrder>;
  @Select((state: GlobalStateModel) => state.locations.previousOrderLocation)
  prevOrderLocation$!: Observable<Restaurant>;
  @Select((state: GlobalStateModel) => state.order.deliveryStatus)
  deliveryStatus$!: Observable<DeliveryStatus>;

  refreshLoading: boolean = false;
  refreshSubscription?: Subscription;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.prevOrder$.subscribe((order) => {
      if (order && order.deliverymode === HandoffMode.DISPATCH) {
        this.store.dispatch(new SetDeliveryStatus(order.id));
      }
    });

    this.store.dispatch(new CloseCart());

    this.route.params.subscribe((params) => {
      if (params['orderID']) {
        this.store
          .dispatch(new SetPreviousOrder(params['orderID']))
          .subscribe(() => {
            const prevOrder = this.store.selectSnapshot(
              (state: GlobalStateModel) => state.order.previousOrder,
            );
            this.store.dispatch(
              new SetPreviousOrderLocation(prevOrder!.vendorid),
            );

            const orderData: MessageHandlerData = {
              body: {
                storeNumber: prevOrder!.vendorextref,
                orderID: prevOrder!.oloid,
                orderData: prevOrder!,
                vehicleInformation: {
                  make: prevOrder!.customfields.find(
                    (cf) => cf.label.toLowerCase() === 'make',
                  )?.value
                    ? prevOrder!.customfields.find(
                        (cf) => cf.label.toLowerCase() === 'make',
                      )!.value
                    : null,
                  model: prevOrder!.customfields.find(
                    (cf) => cf.label.toLowerCase() === 'model',
                  )?.value
                    ? prevOrder!.customfields.find(
                        (cf) => cf.label.toLowerCase() === 'model',
                      )!.value
                    : null,
                  color: prevOrder!.customfields.find(
                    (cf) => cf.label.toLowerCase() === 'color',
                  )?.value
                    ? prevOrder!.customfields.find(
                        (cf) => cf.label.toLowerCase() === 'color',
                      )!.value
                    : null,
                },
              },
            };

            if (
              (window as any).ReactNativeWebView &&
              !!(window as any).ReactNativeWebView.postMessage
            ) {
              (window as any).ReactNativeWebView.postMessage(
                JSON.stringify(orderData),
              );
            }
          });
      }
    });
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      this.refreshSubscription = interval(15000)
        .pipe(
          switchMap(() => {
            const prevOrder = this.store.selectSnapshot(
              (state: GlobalStateModel) => state.order.previousOrder,
            );
            if (prevOrder && prevOrder.deliverymode === HandoffMode.DISPATCH) {
              return this.store.dispatch(new SetDeliveryStatus(prevOrder.id));
            } else {
              return of();
            }
          }),
        )
        .subscribe(() => {
          this.ngZone.run(() => {
            this.cdr.detectChanges();
          });
        });
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new ClearOrderLocation());
    this.store.dispatch(new ClearPreviousOrderLocation());
    this.store.dispatch(new InitializeMenu());
    this.refreshSubscription?.unsubscribe();
  }

  getDirections(restaurant: Restaurant): void {
    window.open(
      `https://www.google.com/maps?saddr=My+Location&daddr=${restaurant.latitude},${restaurant.longitude}`,
    );
  }

  refreshDeliveryStatus(orderID: string): void {
    this.refreshLoading = true;
    this.store.dispatch(new SetDeliveryStatus(orderID)).subscribe({
      complete: () => (this.refreshLoading = false),
    });
  }
}
