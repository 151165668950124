<ng-container [ngSwitch]="ingredientGroupType">
  <div
    [id]="'shell_' + (ingredientGroup.description | slug)"
    *ngSwitchCase="'customizable'"
    class="ingredient-group-shell d-flex flex-column">
    <div
      class="d-flex flex-column"
      [spyTarget]="ingredientGroup.description | slug"
      [id]="ingredientGroup.description | slug">
      <div
        class="ingredient-group-title w-100 d-flex flex-row flex-nowrap pt-4 pb-3 pb-sm-0 px-3 px-md-0 rounded">
        <h4 class="">{{ ingredientGroup.description }}</h4>
        <div class="ml-auto d-flex flex-row gap-3 align-items-center px-2">
          <div
            class="badge rounded-pill bg-warning text-black ml-auto d-flex align-items-center px-2">
            {{
              !ingredientGroup?.maxselects && !ingredientGroup?.mandatory
                ? 'Max ' + ingredientGroup?.options?.length
                : ingredientGroup?.maxselects
                ? 'Select ' + ingredientGroup?.maxselects
                : 'Select 1'
            }}
          </div>
          <ng-container *ngIf="ingredientGroup.mandatory; else optional">
            <ng-container
              *ngIf="
                ingredientGroup
                  | ingredientGroupCompletion : selectedIngredients;
                else incomplete
              ">
              <div
                class="badge rounded-pill bg-success text-black ml-auto d-flex align-items-center px-2">
                Completed
              </div>
            </ng-container>
            <ng-template #incomplete>
              <div
                class="badge rounded-pill bg-danger text-black ml-auto d-flex align-items-center px-2">
                Required
              </div>
            </ng-template>
          </ng-container>
          <ng-template #optional>
            <div
              class="badge rounded-pill bg-info text-black ml-auto d-flex align-items-center px-2">
              Optional
            </div>
          </ng-template>
        </div>
      </div>
      <div class="menu-grid">
        <ng-container *ngIf="ingredientGroup && ingredientGroup.options">
          <ng-container
            *ngFor="
              let option of ingredientGroup?.options;
              trackBy: optionTrackByFn
            ">
            <app-ingredient-card
              [ingredient]="option!"
              [isSelected]="option | selectedIngredient : selectedIngredients"
              [selectedIngredients]="selectedIngredients"
              (ingredientSelected)="selectIngredient($event)"
              (ingredientRemoved)="removeIngredient($event)"
              [isEditMode]="isEditMode"></app-ingredient-card>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <!--  <div *ngSwitchCase="'upsell'">-->
  <!--    <div-->
  <!--      class="d-flex flex-column mx-sm-0 pt-3 bg-info border border-info ingredient-group-shell-upsell">-->
  <!--      <div-->
  <!--        class="ingredient-group-title-upsell w-100 d-flex flex-row flex-nowrap px-3">-->
  <!--        <h4 class="">{{ ingredientGroup.description }}</h4>-->
  <!--      </div>-->
  <!--      <div class="menu-grid-upsell">-->
  <!--        <div class="ingredient-list pb-3 pt-3">-->
  <!--          <app-ingredient-card [isUpsell]="true"></app-ingredient-card>-->
  <!--          <app-ingredient-card [isUpsell]="true"></app-ingredient-card>-->
  <!--          <app-ingredient-card [isUpsell]="true"></app-ingredient-card>-->
  <!--          <app-ingredient-card [isUpsell]="true"></app-ingredient-card>-->
  <!--          <app-ingredient-card [isUpsell]="true"></app-ingredient-card>-->
  <!--          <app-ingredient-card [isUpsell]="true"></app-ingredient-card>-->
  <!--          <app-ingredient-card [isUpsell]="true"></app-ingredient-card>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <div
    *ngSwitchCase="'nonCustomizable'"
    class="ingredient-group-shell d-flex flex-column">
    <div
      class="ingredient-group-title w-100 d-flex flex-row flex-nowrap pt-4 pb-3 pb-sm-0 px-3 px-md-0 rounded">
      <h4 class="">Ingredient Group</h4>
      <div
        class="badge rounded-pill bg-warning text-black ml-auto d-flex align-items-center px-2">
        Select 1
      </div>
    </div>
    <div class="menu-grid">
      <app-ingredient-card></app-ingredient-card>
      <app-ingredient-card></app-ingredient-card>
      <app-ingredient-card [isSpicy]="true"></app-ingredient-card>
      <app-ingredient-card [isFlagged]="true"></app-ingredient-card>
      <app-ingredient-card></app-ingredient-card>
      <app-ingredient-card></app-ingredient-card>
      <app-ingredient-card [isFlagged]="true"></app-ingredient-card>
      <app-ingredient-card></app-ingredient-card>
    </div>
  </div>
</ng-container>

<!--<ng-container *ngFor="let selectedIngredient of fauxSelected">-->
<!--  <ng-container-->
<!--    *ngIf="-->
<!--      selectedIngredient &&-->
<!--      selectedIngredient!.children &&-->
<!--      selectedIngredient!.modifiers!.length > 1-->
<!--    ">-->
<!--    <ng-container *ngFor="let modifierGroup of selectedIngredient.modifiers">-->
<!--      <app-ingredient-group-container-->
<!--        [ingredientGroup]="modifierGroup"-->
<!--        [ingredientList]="ingredientList"-->
<!--        [stepList]="stepList"-->
<!--        [currentlySelected]="currentlySelected"-->
<!--        (optionSelected)="childOptionSelected($event)"-->
<!--        (optionRemoved)="childOptionRemoved($event)"-->
<!--        [selectedIngredients]="selectedIngredients"-->
<!--      ></app-ingredient-group-container>-->
<!--    </ng-container>-->
<!--  </ng-container>-->
<!--</ng-container>-->

<ng-container *ngFor="let selectedIngredient of localCurrentlySelected">
  <ng-container
    *ngIf="
      selectedIngredient &&
      selectedIngredient!.children &&
      selectedIngredient!.modifiers!.length > 1 &&
      (selectedIngredient | isChildIngredient : ingredientGroup.options)
    ">
    <ng-container *ngFor="let modifierGroup of selectedIngredient.modifiers">
      <app-ingredient-group-container
        [ingredientGroup]="modifierGroup"
        [ingredientList]="ingredientList"
        [isEditMode]="isEditMode"
        [selectedIngredients]="
          selectedIngredients
        "></app-ingredient-group-container>
    </ng-container>
  </ng-container>
</ng-container>
