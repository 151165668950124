export class InitializeContent {
  static readonly type = '[Content] Initialize Content';
  constructor() {}
}

export class InitializeAnnouncementBar {
  static readonly type = '[Content] Initialize Announcement Bar';
  constructor() {}
}

export class CloseAnnouncementBar {
  static readonly type = '[Content] Close Announcement Bar';
  constructor() {}
}

export class InitializeStaticMenu {
  static readonly type = '[Content] Initialize Static Menu';
  constructor() {}
}

export class InitializeTipSettings {
  static readonly type = '[Content] Initialize Tip Settings';
  constructor() {}
}

export class InitializeCustomPages {
  static readonly type = '[Content] Initialize Custom Pages';
  constructor() {}
}
