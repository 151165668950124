import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-text-to-order-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './text-to-order-modal.component.html',
  styleUrls: ['text-to-order-modal.component.scss'],
})
export class TextToOrderModalComponent {
  constructor(public bsModalRef: BsModalRef) {}
}
