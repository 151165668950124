import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DeliveryStatus, Order, PastOrder } from '@models/index';
import { MapboxService } from '@services/mapbox/mapbox.service';
import { GeoJSON, LineString } from 'geojson';
import { LngLat, LngLatBounds } from 'mapbox-gl';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-delivery-map',
  templateUrl: './delivery-map.component.html',
  styleUrls: ['delivery-map.component.scss'],
})
export class DeliveryMapComponent implements OnInit, OnChanges {
  @Input() order!: PastOrder;
  @Input() deliveryStatus!: DeliveryStatus;

  deliveryAddressCoordinates?: LngLat;

  bounds = new LngLatBounds();
  fitBoundsOptions = {
    padding: 100,
  };
  //
  // testDeliveryAddress = {
  //   id: 1,
  //   building: '',
  //   streetaddress: '2718 Quarry Lake Drive',
  //   city: 'Columbus',
  //   zipcode: '43204',
  //   phonenumber: '614-123-4567',
  //   specialinstructions: 'Leave at the door',
  //   isdefault: false,
  // };
  //
  // testDeliveryStatus: DeliveryStatus = {
  //   contactInfo: '',
  //   deliveryService: 'Uber',
  //   deliveryStatus: 'DeliveryInProgress',
  //   driverName: 'Melissa F.',
  //   driverPhoneNumber: '+18302051954',
  //   estimatedDeliveryTime: '2024-06-10T19:40:39',
  //   estimatedPickupTime: '2024-06-10T19:31:31',
  //   latitude: 30.01944,
  //   longitude: -97.84542,
  // };

  map!: mapboxgl.Map;

  routeGeoJson?: LineString;

  constructor(private mapbox: MapboxService) {}

  async ngOnInit() {
    await this.setDeliveryAddressCoordinates();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.deliveryAddressCoordinates) {
      this.setDeliveryAddressCoordinates();
    } else {
      this.getRoute();
    }
  }

  async setDeliveryAddressCoordinates() {
    if (this.order.deliveryaddress) {
      const geocodeRes = await this.mapbox
        .geocode(this.order.deliveryaddress)
        .toPromise();
      if (geocodeRes?.features.length) {
        this.deliveryAddressCoordinates = new LngLat(
          geocodeRes.features[0].geometry.coordinates[0],
          geocodeRes.features[0].geometry.coordinates[1],
        );
        this.bounds.extend(this.deliveryAddressCoordinates);
        await this.getRoute();
      }
    }
  }

  async getRoute() {
    if (
      this.deliveryAddressCoordinates &&
      this.deliveryStatus.latitude &&
      this.deliveryStatus.longitude
    ) {
      this.bounds.extend(
        new LngLat(
          this.deliveryStatus.longitude!,
          this.deliveryStatus.latitude!,
        ),
      );
      const directionsRes = await this.mapbox
        .getDirections(
          new LngLat(
            this.deliveryStatus.longitude,
            this.deliveryStatus.latitude,
          ),
          this.deliveryAddressCoordinates,
        )
        .toPromise();
      if (directionsRes && directionsRes.routes.length) {
        this.routeGeoJson = directionsRes.routes[0].geometry;
      }
    }
  }
}
