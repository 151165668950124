import { Pipe, PipeTransform } from '@angular/core';
import { FavoriteLocation, Restaurant } from '@models/index';

@Pipe({
  name: 'favoriteLocation',
})
export class FavoriteLocationPipe implements PipeTransform {
  transform(
    currentLocation: Restaurant,
    favoriteLocations: FavoriteLocation[],
  ): boolean {
    if (favoriteLocations) {
      return !!favoriteLocations.find(
        (location) => location.vendorid === currentLocation.id,
      );
    } else {
      return false;
    }
  }
}
