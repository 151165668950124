import { Pipe, PipeTransform } from '@angular/core';
import { ChildLayoutSection } from '@models/index';

@Pipe({
  name: 'prodABTestingSectionFilter',
})
export class ProdABTestingSectionFilterPipe implements PipeTransform {
  transform(
    sections: Array<ChildLayoutSection | undefined>,
  ): ChildLayoutSection[] {
    return document.location.hostname.includes('freebirds.com')
      ? (sections.filter(
          (section) =>
            section !== undefined && section.item?.status !== 'draft',
        ) as ChildLayoutSection[])
      : (sections.filter(
          (section) => section !== undefined,
        ) as ChildLayoutSection[]);
  }
}
