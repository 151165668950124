<div class="promo-card h-100" style="">
  <img
    class="rounded-top"
    width="100%"
    [src]="adCard.image | cmsAsset : 'category-image'"
    [alt]="adCard.title" />
  <div class="h-100">
    <div class="blog-bar" [style.background-color]="adCard.color"></div>
    <div class="d-flex flex-column p-3 gap-2 h-100">
      <h4>{{ adCard.title }}</h4>
      <div class="blog-description grow-1">
        {{ adCard.body }}
      </div>
      <div
        class="blog-description"
        [style.color]="adCard.color"
        *ngIf="adCard.subheading">
        {{ adCard.subheading }}
      </div>
      <button
        (click)="featuredButtonClick()"
        type="button"
        class="btn btn-outline-secondary">
        {{ adCard.cta_text || 'Start Order' }}
      </button>
    </div>
  </div>
</div>
