/* eslint-disable simple-import-sort/imports */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Module */
import { CheckoutModule } from './checkout.module';

/* Containers */
import * as checkoutContainers from './containers';

/* Guards */
import * as checkoutGuards from './guards';

/* Routes */
export const ROUTES: Routes = [
  {
    path: 'order',
    canActivate: [],
    children: [
      {
        path: 'checkout',
        canActivate: [],
        component: checkoutContainers.CheckoutComponent,
      },

      {
        path: 'confirmation/:orderID',
        canActivate: [],
        component: checkoutContainers.ConfirmationComponent,
      },
      {
        path: '',
        redirectTo: '/404',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [CheckoutModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class CheckoutRoutingModule {}
