<form class="w-100" [formGroup]="couponForm">
  <div class="input-group flex-nowrap">
    <div class="form-floating grow-1">
      <input
        type="text"
        class="form-control form-control-sm form-left"
        placeholder="Coupon Code"
        id="couponCode"
        aria-label="Enter your coupon code"
        formControlName="couponCode" />
      <label class="col-form-label col-form-label-sm" for="couponCode"
        >Coupon Code</label
      >
    </div>
    <app-button
      classes="btn btn-secondary d-flex align-items-center form-btn-right"
      (buttonClick)="order.coupon?.couponcode ? removeCoupon() : applyCoupon()"
      [isLoading]="applyLoading">
      <ng-container *ngIf="!order.coupon?.couponcode; else remove"
        >Apply</ng-container
      >
      <ng-template #remove>Remove</ng-template>
    </app-button>
  </div>
</form>
