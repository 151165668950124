import { isPlatformBrowser } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPlatformBrowser',
})
export class IsPlatformBrowserPipe implements PipeTransform {
  transform(platformID: Object): boolean {
    return isPlatformBrowser(platformID);
  }
}
