import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '../environments/environment';

@Pipe({
  name: 'cmsAsset',
})
export class CmsAssetPipe implements PipeTransform {
  transform(assetID: string | undefined, key: string = 'webp'): string {
    if (assetID) {
      if (Object.hasOwn(assetID as unknown as object, 'id')) {
        // @ts-ignore
        assetID = assetID.id;
      }
      return `${environment.cmsAssetURL}${assetID}?key=${key}`;
    } else {
      return '/assets/images/logos/fb-default-ingredient.svg';
    }
  }
}
