import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalStateModel, GroupOrder } from '@models/index';
import { GoInactiveJoinModalComponent } from '@modules/order/components';
import { OrderService } from '@modules/order/services';
import { Select, Store } from '@ngxs/store';
import { OloDatePipe } from '@pipes/olo-date.pipe';
import { OpenCart } from '@store/actions/app.actions';
import { ClearOrder } from '@store/actions/order.actions';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-group-order',
  templateUrl: './group-order.component.html',
  styleUrls: ['group-order.component.scss'],
})
export class GroupOrderComponent implements OnInit, OnDestroy {
  @Select((state: GlobalStateModel) => state.order.groupOrder)
  groupOrder$!: Observable<GroupOrder>;

  section: 'create' | 'join' | 'update' | 'guest' = 'create';

  availableDeadlineTimes: Date[] = [];

  private groupOrderID?: string;
  private orderID?: string;

  private subs: Subscription[] = [];
  constructor(
    private readonly route: ActivatedRoute,
    private readonly order: OrderService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly toast: ToastrService,
    private oloDatePipe: OloDatePipe,
    private modal: BsModalService,
  ) {}
  ngOnInit() {
    this.subs.push(
      this.route.fragment.subscribe((fragment) => {
        switch (fragment) {
          case 'create':
            this.section = 'create';
            this.getAvailableDeadlineTimes();
            break;
          case 'join':
            this.section = 'join';
            this.route.queryParams.subscribe((params) => {
              if (params['groupOrderID'] && params['orderID']) {
                this.store.dispatch(new ClearOrder()).subscribe(() => {
                  this.order
                    .setGroupOrder(params['groupOrderID'], params['orderID'])
                    .subscribe(() => {
                      const groupOrder = this.store.selectSnapshot(
                        (state: GlobalStateModel) => state.order.groupOrder,
                      );
                      this.getAvailableDeadlineTimes();
                      if (
                        (groupOrder &&
                          !this.order.isGroupOrderOpen(groupOrder)) ||
                        !groupOrder
                      ) {
                        const modalRef = this.modal.show(
                          GoInactiveJoinModalComponent,
                          {
                            keyboard: false,
                            backdrop: 'static',
                            animated: true,
                          },
                        );
                        modalRef.onHide!.subscribe(() => {
                          this.store.dispatch(new ClearOrder());
                        });
                      }
                    });
                });
              } else {
                this.router
                  .navigate(['/'])
                  .then(() => this.toast.error('Invalid link'));
              }
            });
            break;
          case 'update':
            this.section = 'update';
            this.getAvailableDeadlineTimes();
            break;
          case 'guest':
            this.section = 'guest';
            break;
          default:
            this.section = 'create';
            this.getAvailableDeadlineTimes();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  startGroupOrder(event: { deadline: Date; note?: string }) {
    this.order.startGroupOrder(event.deadline, event.note).subscribe(() => {
      this.router
        .navigate(['/'], { fragment: 'menu' })
        .then(() => this.store.dispatch(new OpenCart()));
    });
  }

  updateGroupOrder(event: { deadline: Date; note?: string }) {
    this.order.updateGroupOrder(event.deadline, event.note).subscribe(() => {
      this.router
        .navigate(['/'], { fragment: 'menu' })
        .then(() => this.store.dispatch(new OpenCart()));
    });
  }

  joinGroupOrder(name: string) {
    this.order.joinGroupOrder(name).subscribe(() => {
      this.router
        .navigate(['/'], { fragment: 'menu' })
        .then(() => this.store.dispatch(new OpenCart()));
    });
  }

  private getAvailableDeadlineTimes() {
    this.order.getAvailableGroupOrderDeadlineTimes().subscribe((times) => {
      this.availableDeadlineTimes = times.times.map((time) =>
        this.oloDatePipe.transform(time.time),
      );
    });
  }
}
