import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { GlobalStateModel, GroupOrder, User } from '@models/index';
import { Select } from '@ngxs/store';
import { collapseHorizontallyAnimation } from 'angular-animations';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-hamburger-menu',
  templateUrl: './hamburger-menu.component.html',
  styleUrls: ['hamburger-menu.component.scss'],
  animations: [collapseHorizontallyAnimation()],
})
export class HamburgerMenuComponent {
  @Select((state: GlobalStateModel) => state.app.isFullExperience)
  isFullExperience$!: Observable<boolean>;
  @Select((state: GlobalStateModel) => state.order.groupOrder)
  groupOrder$!: Observable<GroupOrder>;
  @Select((state: GlobalStateModel) => state.order.ownsGroupOrder)
  isGroupOrderOwner$!: Observable<boolean>;
  @Output() hideMenu: EventEmitter<any> = new EventEmitter();

  @Input() header = '';
  @Input() isUser = false;
  @Input() user!: User;

  @Input() visible: boolean = false;

  @Input() showCloseIcon: boolean = true;

  constructor() {}

  hide() {
    this.hideMenu.emit({});
  }

  close(event: Event) {
    this.hide();
    event.preventDefault();
  }
}
