import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '@models/index';
import { GlobalStateModel } from '@models/index';
import { ProfileService } from '@modules/profile/services';
import { Select, Store } from '@ngxs/store';
import { Logout } from '@store/actions/user.actions';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-account-info-section',
  templateUrl: './account-info-section.component.html',
  styleUrls: ['account-info-section.component.scss'],
})
export class AccountInfoSectionComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.user.user)
  user$!: Observable<User>;

  accountInfoForm: FormGroup = new FormGroup({
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    emailAddress: new FormControl(null, [
      Validators.required,
      Validators.email,
    ]),
    phoneNumber: new FormControl(null, [Validators.required]),
    dateOfBirth: new FormControl(null, [Validators.required]),
    emailOpt: new FormControl(null, [Validators.required]),
    smsOpt: new FormControl(null, [Validators.required]),
  });

  isEditing: boolean = false;
  updateLoading: boolean = false;
  maxDate = moment().startOf('day').subtract(13, 'years').toDate();

  constructor(private profile: ProfileService, private router: Router) {}
  ngOnInit() {
    this.accountInfoForm.disable();
    this.user$.pipe(filter((u) => u !== null)).subscribe((user) => {
      this.accountInfoForm.setValue({
        firstName: user?.first_name,
        lastName: user?.last_name,
        emailAddress: user?.email,
        phoneNumber: user?.phone,
        dateOfBirth: user.birthday
          ? moment(user?.birthday, 'YYYY-MM-DD').toDate()
          : null,
        emailOpt: user?.marketing_email_subscription,
        smsOpt: user?.sms_subscription,
      });
    });
  }

  makeChanges() {
    this.isEditing = true;
    this.accountInfoForm.enable();
    if (this.dateOfBirth.value !== null) {
      this.dateOfBirth.disable();
    }
    this.emailAddress.disable();
    this.phoneNumber.disable();
  }

  saveChanges() {
    this.updateLoading = true;
    this.accountInfoForm.disable();
    this.profile
      .updateAccountInfo(
        this.firstName.value,
        this.lastName.value,
        this.emailAddress.value,
        moment(this.dateOfBirth.value).format('YYYY-MM-DD'),
        this.emailOpt.value,
        this.smsOpt.value,
      )
      .subscribe({
        next: () => {
          this.updateLoading = false;
          this.isEditing = false;
        },
        error: () => {
          this.updateLoading = false;
          this.isEditing = false;
        },
      });
  }

  logout(): void {
    this.profile.logout().subscribe(() => this.router.navigate(['']));
  }

  get firstName(): AbstractControl {
    return this.accountInfoForm.get('firstName')!;
  }
  get lastName(): AbstractControl {
    return this.accountInfoForm.get('lastName')!;
  }
  get emailAddress(): AbstractControl {
    return this.accountInfoForm.get('emailAddress')!;
  }
  get phoneNumber(): AbstractControl {
    return this.accountInfoForm.get('phoneNumber')!;
  }
  get dateOfBirth(): AbstractControl {
    return this.accountInfoForm.get('dateOfBirth')!;
  }
  get emailOpt(): AbstractControl {
    return this.accountInfoForm.get('emailOpt')!;
  }
  get smsOpt(): AbstractControl {
    return this.accountInfoForm.get('smsOpt')!;
  }
}
