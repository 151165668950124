<div class="modal-header rounded-top align-items-center">
  <img
    class="h4-birds"
    src="assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="" />
  <h4 class="modal-title grow-1">We think you forgot something...</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="close()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1 h-100 d-flex flex-column gap-3">
  <div
    class="d-lg-flex gap-2 ml-auto d-none"
    *ngIf="menuGrid.scrollWidth > menuGrid.offsetWidth">
    <button type="button" class="arrow-button" (click)="scrollLeft()">
      <div class="d-flex bg-white rounded-circle text-dark">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          class="bi bi-arrow-left-circle"
          viewBox="0 0 16 16">
          <path
            fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
        </svg>
      </div>
    </button>

    <button type="button" class="arrow-button" (click)="scrollRight()">
      <div class="d-flex bg-white rounded-circle text-dark">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          class="bi bi-arrow-right-circle"
          viewBox="0 0 16 16">
          <path
            fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
        </svg>
      </div>
    </button>
  </div>
  <div
    class="d-flex flex-lg-row flex-column gap-3 flex-nowrap overflow-auto pb-3"
    #menuGrid>
    <ng-container *ngIf="upsellProducts$ | async as products">
      <ng-container
        *ngFor="
          let product of products
            | sideSaucesFilter
            | suggestedProductFilter : (order$ | async)!.products
            | upsellSort;
          index as index;
          trackBy: trackBySubcategoryProductName
        ">
        <app-product-card
          [product]="product"
          productCardType="suggested"
          class="grow-1 basis-0"></app-product-card>
      </ng-container>
    </ng-container>
  </div>
</div>
<div class="modal-footer rounded-bottom">
  <div class="d-flex flex-nowrap gap-3 m-0">
    <button type="button" class="btn btn-success" (click)="close()">
      Continue to Checkout
    </button>
  </div>
</div>
