import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { ContentService } from '@services/api/content.service';
import { LoyaltyService } from '@services/api/loyalty.service';
import { MenuService } from '@services/api/menu.service';
import { OrderService } from '@services/api/order.service';
import { UserService } from '@services/api/user.service';
import { DeviceDetectionService } from '@services/device-detection/device-detection.service';
import { MapboxService } from '@services/mapbox/mapbox.service';
import { MetaDataService } from '@services/meta-data/meta-data.service';
import { SchemaMarkupService } from '@services/schema-markup/schema-markup.service';
import { GeocoderService } from '@services/utility/geocoder.service';

import { LocationsService } from './api/locations.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    ContentService,
    LocationsService,
    LoyaltyService,
    MenuService,
    OrderService,
    UserService,
    GeocoderService,
    AnalyticsService,
    MetaDataService,
    DeviceDetectionService,
    SchemaMarkupService,
    MapboxService,
  ],
})
export class ServicesModule {}
