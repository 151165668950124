import { Pipe, PipeTransform } from '@angular/core';
import { GlobalStateModel, Product } from '@models/index';
import { Store } from '@ngxs/store';

@Pipe({
  name: 'cartQuantity',
  pure: false,
})
export class CartQuantityPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(product: Product): number {
    const order = this.store.selectSnapshot(
      (state: GlobalStateModel) => state.order.order,
    );
    if (order) {
      return order.products
        .filter((item) => item.productId === product.id)
        .reduce((acc, item) => acc + item.quantity, 0);
    }
    return 0;
  }
}
