import { Pipe, PipeTransform } from '@angular/core';
import { Ingredient } from '@models/index';

@Pipe({
  name: 'selectedIngredient',
  pure: false,
})
export class SelectedIngredientPipe implements PipeTransform {
  transform(
    ingredient: Partial<Ingredient>,
    selectedIngredients: Partial<Ingredient>[],
  ): boolean {
    return !!selectedIngredients.find(
      (selectedIngredient) =>
        String(selectedIngredient.chainoptionid) ===
        String(ingredient.chainoptionid),
    );
  }
}
