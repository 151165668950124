<div class="w-100 pb-4" style="height: 350px">
  <ng-container *ngIf="bounds && !bounds.isEmpty()">
    <mgl-map
      class="h-100"
      (mapLoad)="map = $event"
      [style]="'mapbox://styles/freebirdswb/cl9lp2hry002216mrj1j1fmfb'"
      [bounds]="bounds"
      [fitBoundsOptions]="fitBoundsOptions">
      <ng-container *ngIf="routeGeoJson">
        <mgl-geojson-source id="route" [data]="routeGeoJson">
          <!-- Ensure the source has the correct data -->
        </mgl-geojson-source>
        <mgl-layer
          id="routeLayer"
          type="line"
          source="route"
          [layout]="{
            'line-join': 'round',
            'line-cap': 'round'
          }"
          [paint]="{
            'line-color': '#323131',
            'line-width': 4
          }"></mgl-layer>
      </ng-container>
      <ng-container *ngIf="deliveryStatus.longitude && deliveryStatus.latitude">
        <mgl-marker
          [lngLat]="[deliveryStatus.longitude, deliveryStatus.latitude]"
          anchor="bottom">
          <img
            src="assets/images/icons/SVG/fb-delivery-driver-icon.svg"
            class="driver-pin" />
        </mgl-marker>
      </ng-container>
      <ng-container *ngIf="deliveryAddressCoordinates">
        <mgl-marker anchor="bottom" [lngLat]="deliveryAddressCoordinates">
          <img
            src="assets/images/icons/Icons/fb-location-pin-open.svg"
            class="destination-pin" />
        </mgl-marker>
      </ng-container>
    </mgl-map>
  </ng-container>
</div>
