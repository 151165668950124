import { CommunityComponent } from './community/community.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
import { LegalComponent } from './legal/legal.component';
import { OurStoryComponent } from './our-story/our-story.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { StaticMenuComponent } from './static-menu/static-menu.component';
import { DataRequestComponent } from './data-request/data-request.component';

export const containers = [FaqComponent, PageNotFoundComponent, LegalComponent, ContactUsComponent, OurStoryComponent, StaticMenuComponent, CommunityComponent, DataRequestComponent];

export * from './community/community.component';
export * from './contact-us/contact-us.component';
export * from './faq/faq.component';
export * from './legal/legal.component';
export * from './our-story/our-story.component';
export * from './page-not-found/page-not-found.component';
export * from './static-menu/static-menu.component';
export * from './data-request/data-request.component';
