import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { DontGoModalComponent } from '@common/components';
import { GlobalStateModel } from '@models/index';
import { Select, Store } from '@ngxs/store';
import { DeviceDetectionService } from '@services/device-detection/device-detection.service';
import { MetaDataService } from '@services/meta-data/meta-data.service';
import { CloseCart, CloseMenu } from '@store/actions/app.actions';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as CookieConsent from 'vanilla-cookieconsent';

function gtag(...args: any[]) {
  (window as any).dataLayer.push(args);
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @Select((state: GlobalStateModel) => state.app.cartOpen)
  cartOpen$!: Observable<boolean>;
  @Select((state: GlobalStateModel) => state.app.initialLoadComplete)
  initialLoadComplete$!: Observable<boolean>;

  title = 'freebirds-v2';

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private store: Store,
    @Inject(PLATFORM_ID)
    private platformId: Object,
    private meta: MetaDataService,
    private modal: BsModalService, // private deviceDetectorService: DeviceDetectionService,
    private route: ActivatedRoute,
  ) {
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart || event instanceof NavigationEnd,
        ),
      )
      .subscribe((event) => {
        switch (true) {
          case event instanceof NavigationStart:
            this.spinner.show('walking-burrito');
            this.store.dispatch(new CloseMenu());
            this.store.dispatch(new CloseCart());
            break;
          case event instanceof NavigationEnd:
            setTimeout(() => this.spinner.hide('walking-burrito'));
            break;
        }
      });
    this.cartOpen$.subscribe((cartOpen) => {
      if (isPlatformBrowser(this.platformId)) {
        document.body.style.overflow = cartOpen ? 'hidden' : 'auto';
      }
    });
    this.store
      .select((state: GlobalStateModel) => state.order.groupOrder)
      .subscribe((groupOrder) => {
        if (groupOrder && !sessionStorage.getItem('showDontGoModal')) {
          window.onbeforeunload = (event) => {
            event.preventDefault();
            if (this.modal.getModalsCount()) {
              this.modal.hide();
              setTimeout(() => {
                this.modal.show(DontGoModalComponent);
                sessionStorage.setItem('showDontGoModal', String(true));
                window.onbeforeunload = null;
              }, 300);
            } else {
              this.modal.show(DontGoModalComponent);
              sessionStorage.setItem('showDontGoModal', String(true));
              window.onbeforeunload = null;
            }
            return (event.returnValue = 'test');
          };
        } else {
          window.onbeforeunload = null;
        }
      });
    // if (this.deviceDetectorService.isMobile()) {
    //   addEventListener('beforeunload', (event) => {
    //     event.preventDefault();
    //     if (
    //       this.store.selectSnapshot(
    //         (state: GlobalStateModel) => state.order.groupOrder,
    //       )
    //     ) {
    //       if (this.modal.getModalsCount()) {
    //         this.modal.hide();
    //         setTimeout(() => {
    //           this.modal.show(DontGoModalComponent);
    //         }, 300);
    //       } else {
    //         this.modal.show(DontGoModalComponent);
    //       }
    //     }
    //     return (event.returnValue = 'test');
    //   });
    // }
    this.route.queryParams.subscribe((params) => {
      if (params['rwg_token']) {
        localStorage.setItem('rwg_token', params['rwg_token']);
      }
    });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      CookieConsent.run({
        onFirstConsent: ({ cookie }) => {},

        onConsent: ({ cookie }) => {
          if (cookie.categories.includes('analytics')) {
            gtag('consent', 'update', {
              ad_storage: 'granted',
              ad_user_data: 'granted',
              ad_personalization: 'granted',
              analytics_storage: 'granted',
            });
          } else {
            gtag('consent', 'update', {
              ad_storage: 'denied',
              ad_user_data: 'denied',
              ad_personalization: 'denied',
              analytics_storage: 'denied',
            });
          }
        },

        onChange: ({ changedCategories, changedServices }) => {},

        onModalReady: ({ modalName }) => {},

        onModalShow: ({ modalName }) => {},

        onModalHide: ({ modalName }) => {},
        guiOptions: {
          consentModal: {
            layout: 'box inline',
            position: 'bottom right',
          },
        },
        categories: {
          necessary: {
            enabled: true, // this category is enabled by default
            readOnly: true, // this category cannot be disabled
          },
          analytics: {
            enabled: true, // this category is enabled by default
            autoClear: {
              reloadPage: true,
              cookies: [
                {
                  name: /^_ga/, // regex: match all cookies starting with '_ga'
                },
                {
                  name: '_gid', // string: exact cookie name
                },
                {
                  name: /^_gcl/, // regex: match all cookies starting with '_gcl'
                },
                {
                  name: /^_fbp/,
                },
                {
                  name: /^dtm/,
                },
                {
                  name: /^Dotomi/,
                },
                {
                  name: /^_att/,
                },
                {
                  name: /^__att/,
                },
              ],
            },
          },
        },

        language: {
          default: 'en',
          translations: {
            en: {
              consentModal: {
                title: 'We Value Your Privacy',
                description:
                  'To give you the best possible experience on our site, we use cookies to provide social media features and analyze our traffic. By clicking "Accept all," you consent to our use of cookies. For more information or to change your preferences, click "Manage Individual Preferences."',
                acceptAllBtn: 'Accept all'.toUpperCase(),
                acceptNecessaryBtn: 'Reject all'.toUpperCase(),
                showPreferencesBtn:
                  'Manage Individual preferences'.toUpperCase(),
                // closeIconLabel: 'Reject all and close modal',
                footer: `
                    <a href="/legal#privacy" target="_blank">Privacy Policy</a>
                `,
              },
              preferencesModal: {
                title: 'Manage cookie preferences',
                acceptAllBtn: 'Accept all'.toUpperCase(),
                acceptNecessaryBtn: 'Reject all'.toUpperCase(),
                savePreferencesBtn: 'Accept current selection'.toUpperCase(),
                closeIconLabel: 'Close modal',
                serviceCounterLabel: 'Service|Services',
                sections: [
                  {
                    title: 'Your Privacy Choices',
                    description: `Your privacy is important to us. Here, you can customize your cookie preferences. You can review and change your choices at any time. To reject specific cookies, toggle the options below, or use the "Reject all" button to deny consent to all optional cookies. Your settings will be saved once you confirm your choices.`,
                  },
                  {
                    title: 'Strictly Necessary',
                    description:
                      'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                    linkedCategory: 'necessary',
                  },
                  {
                    title: 'Performance and Analytics',
                    description:
                      'These cookies collect anonymized data about how you use our website to help us improve your experience.',
                    linkedCategory: 'analytics',
                  },
                  {
                    title: 'More information',
                    description:
                      'For any queries in relation to our policy on cookies and your choices, please <a href="/legal/data-request" target="_blank">contact us</a>',
                  },
                ],
              },
            },
          },
        },
      });
    }
  }

  @HostListener('window:mouseout', ['$event'])
  mouseOutHandler(event: MouseEvent) {
    if (isPlatformBrowser(this.platformId)) {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        if (
          this.store.selectSnapshot(
            (state: GlobalStateModel) => state.order.groupOrder,
          ) &&
          !sessionStorage.getItem('showDontGoModal')
        ) {
          if (this.modal.getModalsCount()) {
            this.modal.hide();
            setTimeout(() => {
              this.modal.show(DontGoModalComponent);
              sessionStorage.setItem('showDontGoModal', String(true));
            }, 300);
          } else {
            this.modal.show(DontGoModalComponent);
            sessionStorage.setItem('showDontGoModal', String(true));
          }
        }
      }
    }
  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler(event: Event) {
  //   event.preventDefault()
  //   console.log(event)
  //   if (this.store.selectSnapshot((state: GlobalStateModel) => state.order.groupOrder)) {
  //     return event.returnValue = true;
  //   }
  //   return event.returnValue = false;
  //
  // }

  closeCart() {
    this.store.dispatch(new CloseCart());
  }
}
