import { Pipe, PipeTransform } from '@angular/core';
import { Calendars } from '@models/index';
import { OloDatePipe } from '@pipes/olo-date.pipe';
import * as moment from 'moment';

class FormattedCalendar {
  days: number;
  startDay: string;
  endDay: string;
  separator: string;
  hours: string;
  constructor(
    start: string,
    hours: string,
    end?: string,
    separator?: string,
    days?: number,
  ) {
    this.startDay = start;
    this.hours = hours;
    this.endDay = end || '';
    this.separator = separator || '';
    this.days = days || 1;
  }
}

@Pipe({
  name: 'formattedCalendar',
})
export class FormattedCalendarPipe implements PipeTransform {
  constructor(private oloDate: OloDatePipe) {}

  transform(
    calendars: Calendars,
    type: 'business' | 'dispatch' | 'curbside',
    utcOffset: number,
  ): FormattedCalendar[] {
    const result: FormattedCalendar[] = [];
    const selectedCalendar = calendars.find((c) => c.type === type);
    if (
      selectedCalendar &&
      selectedCalendar.ranges &&
      selectedCalendar.ranges.length
    ) {
      // Make FormattedCalendar, loop through ranges
      // If range start and end are the same as current FormattedCalendar, update endDay
      let range = selectedCalendar.ranges[0];
      const ranges = selectedCalendar.ranges.slice();
      const start = moment(this.oloDate.transform(range.start, utcOffset));
      const end = moment(this.oloDate.transform(range.end, utcOffset));
      let startDate = start;
      let endDate = end;

      let daysToOrderAhead = 0;
      let midNightTime = moment().endOf('day');
      const now = moment();
      if (start.isBefore(now) && end.isAfter(now)) {
        if (end.isBefore(now)) {
          daysToOrderAhead = 1;
        }
      }
      if (end.isBefore(now) && midNightTime.isAfter(now)) {
        daysToOrderAhead = 1;
      }
      // if (this.location.utcoffset > 0 || this.location.utcoffset < 0) {
      //   const offset = -1 * this.location.utcoffset;
      //   startDate = moment(
      //     new Date(
      //       startDate.get('year'),
      //       startDate.get('month'),
      //       startDate.get('date') + daysToOrderAhead,
      //       startDate.get('hours'),
      //       startDate.get('minutes')
      //     )
      //   ).add(offset, 'hours');
      //   endDate = moment(
      //     new Date(
      //       endDate.get('year'),
      //       endDate.get('month'),
      //       endDate.get('date') + daysToOrderAhead,
      //       endDate.get('hours'),
      //       endDate.get('minutes')
      //     )
      //   ).add(offset, 'hours');
      // }

      let newFC = new FormattedCalendar(
        'Today',
        [start.format('h:mm A'), end.format('h:mm A')].join(' - '),
      );
      for (let i = 1; i < 7; i++) {
        const oldRange = range;
        range = selectedCalendar.ranges[i];
        const newHours = [
          moment(this.oloDate.transform(range.start, utcOffset)).format(
            'h:mm A',
          ),
          moment(this.oloDate.transform(range.end, utcOffset)).format('h:mm A'),
        ].join(' - ');
        // If it's the same time, update newFC, otherwise push newFC and then reassign newFC
        if (newHours === newFC.hours) {
          newFC.endDay = moment(range.weekday, 'ddd').format('dddd');
          newFC.days++;
          newFC.separator = newFC.days === 2 ? '&' : '-';
        } else {
          result.push(newFC);
          newFC = new FormattedCalendar(
            moment(range.weekday, 'ddd').format('dddd'),
            newHours,
          );
        }
      }
      result.push(newFC);
    }
    if (result.length === 1) {
      result[0].startDay = 'Everyday';
      result[0].endDay = '';
    }
    return result;
  }
}
