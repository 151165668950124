import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { PointsBalance, Reward } from '@models/index';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { RewardsPreviewModalComponent } from '../rewards-preview-modal/rewards-preview-modal.component';

@Component({
  selector: 'app-rewards-preview',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './rewards-preview.component.html',
  styleUrls: ['./rewards-preview.component.scss'],
})
export class RewardsPreviewComponent implements OnInit {
  @Input() rewards!: Reward[];
  @Input() pointBalances!: PointsBalance;

  displayRewards: Reward[] = [];
  lockedRewards: Reward[] = [];

  constructor(private modalService: BsModalService) {}

  ngOnInit() {
    this.updateDisplayedRewards();
  }

  private updateDisplayedRewards() {
    const unlockedRewards = this.rewards.filter(
      (reward) =>
        reward.points_required_to_redeem <=
        this.pointBalances.redeemable_points,
    );

    if (unlockedRewards.length > 0) {
      if (unlockedRewards.length >= 3) {
        this.displayRewards = unlockedRewards.slice(0, 3);
        this.lockedRewards = [];
      } else {
        this.displayRewards = unlockedRewards;
        this.lockedRewards = this.rewards
          .filter(
            (reward) =>
              reward.points_required_to_redeem >
              this.pointBalances.redeemable_points,
          )
          .slice(0, 3 - unlockedRewards.length);
      }
    } else {
      this.displayRewards = [];
      this.lockedRewards = [];
    }
  }

  openRewardsModal() {
    const initialState = {
      rewards: this.rewards,
      pointBalances: this.pointBalances,
    };
    this.modalService.show(RewardsPreviewModalComponent, { initialState });
  }
}
