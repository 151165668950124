<div class="bg-light">
  <div class="container py-4">
    <div class="d-flex flex-column flex-lg-row gap-3 align-items-center justify-content-center text-center">
      <h3 class="d-flex grow-1 text-lg-start">
        Texas-sized Rewards Are Even Bigger In The App
      </h3>
      <div class="d-flex gap-3 flex-column flex-sm-row">
        <a
          href="https://apps.apple.com/us/app/freebirds-restaurant/id1079328303"
          class="d-block"
        ><img
          class="h-auto"
          src="/assets/images/icons/Icons/appstore.svg"
          width="160"
          role="img"
          aria-label="Apple app store"
          alt="Apple app store"
        /></a>
        <a
          href="https://play.google.com/store/apps/details?id=com.punchh.freebirds&hl=en_US&gl=US"
          class="d-block"
        ><img
          class="h-auto"
          src="/assets/images/icons/Icons/googleplay.svg"
          width="160"
          role="img"
          aria-label="Google play store"
          alt="Google play store"
        /></a>
      </div>
    </div>
  </div>
</div>
