<div class="modal-header rounded-top align-items-center">
  <img
    class="h4-birds"
    src="assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="" />
  <h4 class="modal-title grow-1">Cancel Group Order?</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="closeClick()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1">
  You currently have an open group order. Changing locations will cancel your
  group order. Are you sure you want to continue?
</div>
<div class="modal-footer rounded-bottom">
  <div class="d-flex flex-nowrap gap-3 m-0">
    <app-button classes="btn btn-outline-secondary" (click)="closeClick()">
      Go Back
    </app-button>
    <app-button
      [isLoading]="cancelLoading"
      classes="btn btn-danger"
      (buttonClick)="cancelOrder()">
      Cancel Order
    </app-button>
  </div>
</div>
