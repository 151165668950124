import { Component, OnInit } from '@angular/core';
import { NutritionPreferencesModalComponent } from '@common/components';
import {
  AllergenCategory,
  AllergenTag,
  Contains,
  GlobalStateModel,
  Lifestyle,
  PlantBased,
} from '@models/index';
import { Select, Store } from '@ngxs/store';
import { RemoveAllergenTag } from '@store/actions/nutrition.actions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-nutrition-preferences',
  templateUrl: './nutrition-preferences.component.html',
  styleUrls: ['nutrition-preferences.component.scss'],
})
export class NutritionPreferencesComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.nutrition.allergenCategories)
  allergenCategories$!: Observable<AllergenCategory[]>;
  @Select((state: GlobalStateModel) => state.nutrition.allergenTags)
  allergenTags$!: Observable<AllergenTag[]>;
  @Select((state: GlobalStateModel) => state.nutrition.selectedAllergenTags)
  selectedAllergenTags$!: Observable<AllergenTag[]>;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private store: Store,
  ) {}
  ngOnInit() {}

  openNutritionPreferencesModal() {
    this.bsModalRef = this.modalService.show(
      NutritionPreferencesModalComponent,
    );
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  removeTag(tag: AllergenTag) {
    this.store.dispatch(new RemoveAllergenTag(tag));
  }
}
