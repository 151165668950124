import { Component, HostListener, OnInit } from '@angular/core';
import { GlobalStateModel, PointsBalance, Reward, User } from '@models/index';
import { Select, Store } from '@ngxs/store';
import { AnalyticsService } from '@services/analytics/analytics.service';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  slideInRightOnEnterAnimation,
  slideOutRightOnLeaveAnimation,
} from 'angular-animations';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-rewards-dashboard',
  templateUrl: './rewards-dashboard.component.html',
  styleUrls: ['rewards-dashboard.component.scss'],
  animations: [
    slideInRightOnEnterAnimation({ duration: 300 }),
    slideOutRightOnLeaveAnimation({ duration: 300 }),
    fadeOutOnLeaveAnimation({ duration: 300 }),
    fadeInOnEnterAnimation({ duration: 300 }),
  ],
})
export class RewardsDashboardComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.user.pointBalance)
  pointBalance$!: Observable<PointsBalance>;
  @Select((state: GlobalStateModel) => state.user.user)
  user$!: Observable<User>;
  @Select((state: GlobalStateModel) => state.user.rewards)
  rewards$!: Observable<Reward[]>;
  isAtTop: boolean = true;

  constructor(
    private analyticsService: AnalyticsService,
    private store: Store,
  ) {}
  ngOnInit() {
    this.analyticsService.logRewardsPageView(
      this.store.selectSnapshot((state: GlobalStateModel) => state.user.user)!,
    );
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    this.isAtTop = window.pageYOffset === 0;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
