/* eslint-disable simple-import-sort/imports */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Module */
import { BrochureModule } from './brochure.module';

/* Containers */
import * as brochureContainers from './containers';

/* Guards */
import * as brochureGuards from './guards';

/* Resolvers */

import * as brochureResolvers from './resolvers';

/* Routes */
export const ROUTES: Routes = [
  {
    path: 'faq',
    canActivate: [],
    component: brochureContainers.FaqComponent,
  },
  {
    path: '404',
    canActivate: [],
    component: brochureContainers.PageNotFoundComponent,
  },
  {
    path: 'legal',
    canActivate: [],
    component: brochureContainers.LegalComponent,
  },
  {
    path: 'legal/data-request',
    canActivate: [],
    component: brochureContainers.DataRequestComponent,
  },
  {
    path: 'contact-us',
    canActivate: [],
    component: brochureContainers.ContactUsComponent,
  },
  {
    path: 'our-story',
    canActivate: [],
    component: brochureContainers.OurStoryComponent,
  },
  {
    path: 'our-menu',
    canActivate: [],
    component: brochureContainers.StaticMenuComponent,
    resolve: {
      staticMenu: brochureResolvers.StaticMenuResolver,
    },
  },
  {
    path: 'community',
    canActivate: [],
    component: brochureContainers.CommunityComponent,
  },
];

@NgModule({
  imports: [BrochureModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class BrochureRoutingModule {}
