import { Pipe, PipeTransform } from '@angular/core';
import { AvailableReward } from '@models/index';

@Pipe({
  name: 'isRewardApplied',
})
export class IsRewardAppliedPipe implements PipeTransform {
  transform(
    reward: AvailableReward,
    appliedRewards: AvailableReward[] = [],
  ): boolean {
    return !!appliedRewards.find(
      (applied) => applied.rewardid === reward.rewardid,
    );
  }
}
