import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalStateModel, MetaData } from '@models/index';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface ManualMetaData {
  title?: string;
  description?: string;
  keywords?: string[];
}

@Injectable()
export class MetaDataService {
  @Select((state: GlobalStateModel) => state.content.metadata)
  metadata$!: Observable<MetaData[]>;

  pages: MetaData[] = [];

  constructor(
    private router: Router,
    private meta: Meta,
    private title: Title,
    private location: Location,
  ) {
    this.metadata$.pipe(filter((m) => m !== null)).subscribe((metadata) => {
      this.pages = metadata;
      this.loadSpecificPage('/');
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.loadSpecificPage(event.url);
        }
      });
    });
  }

  loadSpecificPage(route: string) {
    if (this.pages.find((page) => page.page_name === route)) {
      this.updatePage(this.pages.find((page) => page!.page_name === route)!);
    } else {
      this.updatePage(this.pages.find((page) => page.page_name === '/')!);
    }
  }

  manualUpdate(tagInfo: ManualMetaData) {
    if (tagInfo.title) {
      this.updateTitle(tagInfo.title);
    }
    if (tagInfo.description) {
      this.updateDescription(tagInfo.description);
    }
    if (tagInfo.keywords) {
      this.updateKeywords(tagInfo.keywords);
    }
    this.checkAndUpdate({ property: 'og:url', content: this.location.path() });
  }

  private updatePage(page: MetaData) {
    this.updateTitle(page.title!);
    this.updateDescription(page.description!);
    // @ts-ignore
    this.updateKeywords(page.keywords!);
    this.checkAndUpdate({ property: 'og:url', content: this.location.path() });
  }

  private updateTitle(title: string): void {
    this.title.setTitle(title);
    this.checkAndUpdate({ property: 'og:title', content: title });
    this.checkAndUpdate({ name: 'twitter:title', content: title });
  }

  private updateDescription(description: string): void {
    this.checkAndUpdate({ name: 'description', content: description });
    this.checkAndUpdate({ property: 'og:description', content: description });
    this.checkAndUpdate({ name: 'twitter:description', content: description });
  }

  private updateKeywords(keywords: string[]): void {
    this.checkAndUpdate({ name: 'keywords', content: keywords.join(', ') });
  }

  /**
   * @ignore
   * @param tag
   */
  public checkAndUpdate(tag: MetaDefinition) {
    if (
      this.meta.getTag(
        tag.name ? `name='${tag.name}'` : `property='${tag.property}'`,
      )
    ) {
      this.meta.updateTag(
        tag,
        tag.name ? `name='${tag.name}'` : `property='${tag.property}'`,
      );
    } else {
      this.meta.addTag(tag);
    }
  }
}
