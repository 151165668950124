<app-navbar></app-navbar>

<ng-container *ngIf="staticMenu$ | async as staticMenu">

  <ng-container *ngIf="((staticMenu.featured_sections?.length || 0) + (staticMenu.collection_sections?.length || 0)) > 1">
    <div class="bg-dark z-jumbotron-content" [class.category-navigation-announcement]="(scheduledAnnouncement$ | async)"
         [class.category-navigation]="(scheduledAnnouncement$ | async) === null">
      <div class="categories-scroll">
        <div class="categories-list category-navigation container">
          <ng-container *ngFor="let featuredButton of staticMenu.featured_sections">
            <ng-container *ngIf="featuredButton?.item && featuredButton!.item!.name">
              <a class="category-title" (click)="scrollToSection(featuredButton!.item!.name)" [spyOn]="featuredButton!.item!.name | slug" activeClass="active-scroll">
                {{ featuredButton!.item!.name }}
              </a>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let collectionButton of staticMenu.collection_sections">
            <ng-container *ngIf="collectionButton?.item && collectionButton!.item!.name">
              <a class="category-title" (click)="scrollToSection(collectionButton!.item!.name)" [spyOn]="collectionButton!.item!.name | slug" activeClass="active-scroll">
                {{ collectionButton!.item!.name }}
              </a>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <div
    class="position-relative"
    style="
      background-image: url('/assets/images/backgrounds/alabaster-paper-texture-repeat-small.jpg');
      background-color: #ffffff;
      overflow: hidden;
    ">
    <img
      alt=""
      src="/assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
      class="birds-background"/>
    <div class="header position-relative">
      <div class="py-5">
        <h1 class="display-3 position-relative pt-5 text-center">Our Menu</h1>
        <div class="d-flex gap-3 position-relative justify-content-center pb-5">
          <button class="btn btn-primary" routerLink="/start-order">
            Order Now
          </button>
          <ng-container *ngIf="homepage$ | async as homepage">
            <a
              class="btn btn-primary"
              *ngIf="homepage.static_menu_pdf"
              [href]="homepage.static_menu_pdf['id'] | cmsAsset"
              target="_blank">
              Download Menu
            </a>
          </ng-container>
        </div>
      </div>
    </div>



    <div class="container pb-5 menu">
      <ng-container
        *ngFor="let featuredSection of staticMenu.featured_sections">
        <app-sm-products-section
          [featuredGroup]="featuredSection"
          *ngIf="featuredSection && featuredSection.item && featuredSection.item.name" [id]="featuredSection.item.name | slug" [spyTarget]="featuredSection.item.name | slug"></app-sm-products-section>
      </ng-container>
      <ng-container
        *ngFor="let collectionSection of staticMenu.collection_sections">
        <app-sm-ingredients-section
          [collectionGroup]="collectionSection"
          *ngIf="collectionSection && collectionSection.item && collectionSection.item.name" [id]="collectionSection.item.name | slug" [spyTarget]="collectionSection.item.name | slug"></app-sm-ingredients-section>
      </ng-container>
    </div>
  </div>
</ng-container>

<app-footer></app-footer>

<button
  *ngIf="!isAtTop"
  (click)="scrollToTop()"
  class="scroll-to-top-button"
  [@slideInRightOnEnter]
  [@slideOutRightOnLeave]
  [@fadeOutOnLeave]
  [@fadeInOnEnter]>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-arrow-up"
    viewBox="0 0 16 16">
    <path
      fill-rule="evenodd"
      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
  </svg>
</button>
