import { Pipe, PipeTransform } from '@angular/core';
import { SavedAddress } from '@models/index';
import { SlugPipe } from '@pipes/slug.pipe';

@Pipe({
  name: 'dedupeAddresses',
})
export class DedupeAddressesPipe implements PipeTransform {
  constructor(private slugPipe: SlugPipe) {}

  transform(addresses: SavedAddress[] | null): SavedAddress[] {
    if (!addresses) {
      return [];
    }
    const addressSlugs: string[] = [];
    const finalAddresses: SavedAddress[] = [];
    addresses.forEach((address) => {
      if (
        !addressSlugs.includes(this.slugPipe.transform(address.streetaddress))
      ) {
        addressSlugs.push(this.slugPipe.transform(address.streetaddress));
        finalAddresses.push(address);
      }
    });
    return finalAddresses;
  }
}
