import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Order, PastOrder } from '@models/index';
import { OrderService } from '@modules/order/services';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { ReorderModalComponent } from '../reorder-modal/reorder-modal.component';

@Component({
  selector: 'app-reorder-card',
  templateUrl: './reorder-card.component.html',
  styleUrls: ['reorder-card.component.scss'],
})
export class ReorderCardComponent {
  bsModalRef?: BsModalRef;
  reorderLoading: boolean = false;

  @Input() order!: PastOrder | null;

  constructor(
    private modalService: BsModalService,
    private orderService: OrderService,
    private router: Router,
    private toastr: ToastrService,
  ) {}
  openReorderModal() {
    this.bsModalRef = this.modalService.show(ReorderModalComponent, {
      initialState: {
        order: this.order!,
      },
    });
  }

  reorder(): void {
    this.reorderLoading = true;
    this.orderService.startOrderFromPastOrder(this.order!).subscribe(
      () => {
        this.router.navigate(['order', 'checkout']).then(() => {
          this.reorderLoading = false;
        });
      },
      (error) => {
        this.reorderLoading = false;
        this.toastr.error(error.message, 'Error');
      },
    );
  }
}
