<div class="container">
  <!-- Typography -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <h1 class="w-100">Typography</h1>

    <!-- Headings -->
    <div class="d-flex flex-column flex-md-row gap-3">
      <div class="d-flex flex-row flex-wrap grow-1 basis-0">
        <div class="d-flex flex-column gap-2">
          <h1 class="display-1">D1</h1>
          <h1 class="display-2">D2</h1>
          <h1 class="display-3">D3</h1>
          <h1 class="display-4">D4</h1>
          <h1>Heading 1</h1>
          <h2>Heading 2</h2>
          <h3>Heading 3</h3>
          <h4>Heading 4</h4>
          <h5>Heading 5</h5>
          <h6>Heading 6</h6>
          <h3>
            Heading
            <small class="text-muted">with muted text</small>
          </h3>
          <p class="lead">
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor.
          </p>
        </div>
      </div>
      <div class="d-flex flex-row flex-wrap grow-1 basis-0">
        <div class="d-flex flex-column gap-2">
          <h2>Example body text</h2>
          <p>
            Nullam quis risus eget <a href="#">urna mollis ornare</a> vel eu
            leo. Cum sociis natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula.
          </p>
          <p>
            <small
              >This line of text is meant to be treated as fine print.</small
            >
          </p>
          <p>The following is <strong>rendered as bold text</strong>.</p>
          <p>The following is <em>rendered as italicized text</em>.</p>
          <p>
            An abbreviation of the word attribute is
            <abbr title="attribute">attr</abbr>.
          </p>
        </div>
      </div>
      <div class="d-flex flex-row flex-wrap grow-1 basis-0">
        <div class="d-flex flex-column gap-2">
          <h2>Emphasis classes</h2>
          <p class="text-muted">
            Fusce dapibus, tellus ac cursus commodo, tortor mauris nibh.
          </p>
          <p class="text-primary">
            Nullam id dolor id nibh ultricies vehicula ut id elit.
          </p>
          <p class="text-secondary">
            Pellentesque ornare sem lacinia quam venenatis vestibulum.
          </p>
          <p class="text-warning">
            Etiam porta sem malesuada magna mollis euismod.
          </p>
          <p class="text-danger">
            Donec ullamcorper nulla non metus auctor fringilla.
          </p>
          <p class="text-success">
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </p>
          <p class="text-info">
            Maecenas sed diam eget risus varius blandit sit amet non magna.
          </p>
        </div>
      </div>
    </div>

    <!-- Blockquotes -->
    <div class="d-flex flex-column gap-3">
      <div class="w-100">
        <h5 id="type-blockquotes">Blockquotes</h5>
      </div>
      <div class="d-flex flex-column flex-md-row gap-3">
        <div class="d-flex flex-row flex-wrap grow-1 basis-0">
          <div class="bs-component">
            <blockquote class="blockquote">
              <p class="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                posuere erat a ante.
              </p>
              <footer class="blockquote-footer">
                Someone famous in <cite title="Source Title">Source Title</cite>
              </footer>
            </blockquote>
          </div>
        </div>
        <div class="d-flex flex-row flex-wrap grow-1 basis-0">
          <div class="bs-component">
            <blockquote class="blockquote text-center">
              <p class="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                posuere erat a ante.
              </p>
              <footer class="blockquote-footer">
                Someone famous in <cite title="Source Title">Source Title</cite>
              </footer>
            </blockquote>
          </div>
        </div>
        <div class="d-flex flex-row flex-wrap grow-1 basis-0">
          <div class="bs-component">
            <blockquote class="blockquote text-right">
              <p class="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                posuere erat a ante.
              </p>
              <footer class="blockquote-footer">
                Someone famous in <cite title="Source Title">Source Title</cite>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Buttons -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <h1 class="w-100">Buttons</h1>

    <!-- Standard Buttons -->
    <div class="w-100 d-flex flex-column gap-3">
      <h5 class="w-100">Standard Buttons</h5>
      <div class="w-100 d-flex flex-column flex-md-row gap-2">
        <button type="button" class="btn btn-primary">Primary</button>
        <button type="button" class="btn btn-secondary">Secondary</button>
        <button type="button" class="btn btn-success">Success</button>
        <button type="button" class="btn btn-info">Info</button>
        <button type="button" class="btn btn-warning">Warning</button>
        <button type="button" class="btn btn-danger">Danger</button>
        <button type="button" class="btn btn-link">Link</button>
      </div>

      <h5 class="w-100">Disabled Buttons</h5>
      <div class="w-100 d-flex flex-column flex-md-row gap-2">
        <button type="button" class="btn btn-primary disabled">Primary</button>
        <button type="button" class="btn btn-secondary disabled">
          Secondary
        </button>
        <button type="button" class="btn btn-success disabled">Success</button>
        <button type="button" class="btn btn-info disabled">Info</button>
        <button type="button" class="btn btn-warning disabled">Warning</button>
        <button type="button" class="btn btn-danger disabled">Danger</button>
        <button type="button" class="btn btn-link disabled">Link</button>
      </div>

      <h5 class="w-100">Outline Buttons</h5>
      <div class="w-100 d-flex flex-column flex-md-row gap-2">
        <button type="button" class="btn btn-outline-primary">Primary</button>
        <button type="button" class="btn btn-outline-secondary">
          Secondary
        </button>
        <button type="button" class="btn btn-outline-success">Success</button>
        <button type="button" class="btn btn-outline-info">Info</button>
        <button type="button" class="btn btn-outline-warning">Warning</button>
        <button type="button" class="btn btn-outline-danger">Danger</button>
      </div>
    </div>

    <!-- Dropdown Buttons -->
    <div class="w-100 d-flex flex-column gap-3">
      <h5 class="w-100">Dropdown Buttons</h5>
      <div class="d-flex flex-row flex-column flex-md-row flex-wrap gap-2">
        <div class="btn-group" dropdown>
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn btn-primary dropdown-toggle"
            aria-controls="dropdown-basic">
            Button dropdown <span class="caret"></span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu"
            role="menu"
            aria-labelledby="button-basic">
            <li role="menuitem">
              <a class="dropdown-item" href="#">Action</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Another action</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Something else here</a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Separated link</a>
            </li>
          </ul>
        </div>

        <div class="btn-group" dropdown>
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn btn-secondary dropdown-toggle"
            aria-controls="dropdown-basic">
            Button dropdown <span class="caret"></span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu"
            role="menu"
            aria-labelledby="button-basic">
            <li role="menuitem">
              <a class="dropdown-item" href="#">Action</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Another action</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Something else here</a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Separated link</a>
            </li>
          </ul>
        </div>

        <div class="btn-group" dropdown>
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn btn-success dropdown-toggle"
            aria-controls="dropdown-basic">
            Button dropdown <span class="caret"></span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu"
            role="menu"
            aria-labelledby="button-basic">
            <li role="menuitem">
              <a class="dropdown-item" href="#">Action</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Another action</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Something else here</a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Separated link</a>
            </li>
          </ul>
        </div>

        <div class="btn-group" dropdown>
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn btn-info dropdown-toggle"
            aria-controls="dropdown-basic">
            Button dropdown <span class="caret"></span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu"
            role="menu"
            aria-labelledby="button-basic">
            <li role="menuitem">
              <a class="dropdown-item" href="#">Action</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Another action</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Something else here</a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Separated link</a>
            </li>
          </ul>
        </div>

        <div class="btn-group" dropdown>
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn btn-danger dropdown-toggle"
            aria-controls="dropdown-basic">
            Button dropdown <span class="caret"></span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu"
            role="menu"
            aria-labelledby="button-basic">
            <li role="menuitem">
              <a class="dropdown-item" href="#">Action</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Another action</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Something else here</a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Separated link</a>
            </li>
          </ul>
        </div>

        <div class="btn-group" dropdown>
          <button
            id="button-basic"
            dropdownToggle
            type="button"
            class="btn btn-link dropdown-toggle"
            aria-controls="dropdown-basic">
            Button dropdown <span class="caret"></span>
          </button>
          <ul
            id="dropdown-basic"
            *dropdownMenu
            class="dropdown-menu"
            role="menu"
            aria-labelledby="button-basic">
            <li role="menuitem">
              <a class="dropdown-item" href="#">Action</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Another action</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Something else here</a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" href="#">Separated link</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Button Sizes -->
    <div class="w-100 d-flex flex-column gap-3">
      <h5 class="w-100">Button Sizes</h5>
      <div class="d-flex flex-column flex-md-row gap-2">
        <!-- Do not include the div tag is surrounding the button tag when copying -->
        <div>
          <button type="button" class="btn btn-primary btn-lg">
            Large button
          </button>
        </div>
        <div>
          <button type="button" class="btn btn-primary">Default button</button>
        </div>
        <div>
          <button type="button" class="btn btn-primary btn-sm">
            Small button
          </button>
        </div>
      </div>
    </div>

    <!-- Checkbox Buttons -->
    <div class="w-100 d-flex flex-column gap-3">
      <h5 class="w-100">Checkbox Buttons</h5>

      <!-- Do not include the div tag is surrounding the button group when copying -->
      <div>
        <div class="btn-group">
          <label
            class="btn btn-primary"
            [(ngModel)]="checkModel.left"
            btnCheckbox
            tabindex="0"
            role="button"
            >Left</label
          >
          <label
            class="btn btn-primary"
            [(ngModel)]="checkModel.middle"
            btnCheckbox
            tabindex="0"
            role="button"
            >Middle</label
          >
          <label
            class="btn btn-primary"
            [(ngModel)]="checkModel.right"
            btnCheckbox
            tabindex="0"
            role="button"
            >Right</label
          >
        </div>
      </div>
    </div>

    <!-- Radio Buttons -->
    <div class="w-100 d-flex flex-column gap-3">
      <h5 class="w-100">Radio Buttons</h5>

      <!-- Do not include the div tag is surrounding the button group when copying -->
      <div>
        <div class="btn-group">
          <label
            class="btn btn-primary"
            [(ngModel)]="radioModel"
            btnRadio="Left"
            tabindex="0"
            role="button"
            >Left</label
          >
          <label
            class="btn btn-primary"
            [(ngModel)]="radioModel"
            btnRadio="Middle"
            tabindex="0"
            role="button"
            >Middle</label
          >
          <label
            class="btn btn-primary"
            [(ngModel)]="radioModel"
            btnRadio="Right"
            tabindex="0"
            role="button"
            >Right</label
          >
        </div>
      </div>
    </div>

    <!-- Button Groups  -->
    <div class="w-100 d-flex flex-column gap-3">
      <h5 class="w-100">Button Groups</h5>

      <!-- Do not include the div tag is surrounding the button group when copying -->
      <div>
        <div class="btn-group" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-secondary">Left</button>
          <button type="button" class="btn btn-secondary">Middle</button>
          <button type="button" class="btn btn-secondary">Right</button>
        </div>
      </div>

      <div>
        <div
          class="btn-toolbar"
          role="toolbar"
          aria-label="Toolbar with button groups">
          <div class="btn-group mr-2" role="group" aria-label="First group">
            <button type="button" class="btn btn-secondary">1</button>
            <button type="button" class="btn btn-secondary">2</button>
            <button type="button" class="btn btn-secondary">3</button>
            <button type="button" class="btn btn-secondary">4</button>
          </div>
          <div class="btn-group mr-2" role="group" aria-label="Second group">
            <button type="button" class="btn btn-secondary">5</button>
            <button type="button" class="btn btn-secondary">6</button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Navbar -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <h1 class="w-100">Navbars</h1>

    <app-navbar></app-navbar>
    <app-navbar navbarOrientation="dark"></app-navbar>
    <app-navbar navbarOrientation="minimal"></app-navbar>
  </section>

  <!-- Forms -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <h1 class="w-100">Forms</h1>

    <div class="d-flex flex-column flex-md-row gap-3">
      <div class="grow-1 basis-0">
        <div class="bs-component">
          <form>
            <fieldset class="d-flex flex-column gap-3">
              <legend>Legend</legend>

              <div class="form-floating">
                <input
                  class="form-control"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required />
                <label for="name" id="name-label"> Name </label>
              </div>

              <div class="form-floating">
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email" />
                <label for="exampleInputEmail1">Email address</label>
                <small id="emailHelp" class="form-text text-muted"
                  >We'll never share your email with anyone else.</small
                >
              </div>

              <div class="form-floating">
                <input
                  class="form-control"
                  type="tel"
                  name="phone number"
                  id="phonenumber"
                  placeholder="Phone Number"
                  required />
                <label for="phone-number" id="phone-number-label"
                  >Phone Number*</label
                >
              </div>

              <div class="form-floating">
                <input
                  type="password"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password" />
                <label for="exampleInputPassword1">Password</label>
              </div>

              <div class="form-floating">
                <select class="form-control" id="exampleSelect1">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
                <label for="exampleSelect1">Example select</label>
              </div>

              <div class="form-floating">
                <textarea
                  class="form-control"
                  id="exampleTextarea"
                  rows="3"></textarea>
                <label for="exampleTextarea">Example textarea</label>
              </div>

              <div class="form-group">
                <input
                  type="file"
                  class="form-control-file"
                  id="exampleInputFile"
                  aria-describedby="fileHelp" />
                <label for="exampleInputFile">File input</label>
                <small id="fileHelp" class="form-text text-muted"
                  >This is some placeholder block-level help text for the above
                  input. It's a bit lighter and easily wraps to a new
                  line.</small
                >
              </div>

              <fieldset class="form-group">
                <legend>Radio buttons</legend>
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="optionsRadios"
                      id="optionsRadios1"
                      value="option1"
                      checked />
                    Option one is this and that&mdash;be sure to include why
                    it's great
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="optionsRadios"
                      id="optionsRadios2"
                      value="option2" />
                    Option two can be something else and selecting it will
                    deselect option one
                  </label>
                </div>
                <div class="form-check disabled">
                  <label class="form-check-label">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="optionsRadios"
                      id="optionsRadios3"
                      value="option3"
                      disabled />
                    Option three is disabled
                  </label>
                </div>
              </fieldset>

              <fieldset class="form-group">
                <legend>Checkboxes</legend>
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      checked />
                    Option one is this and that&mdash;be sure to include why
                    it's great
                  </label>
                </div>
                <div class="form-check disabled">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      disabled />
                    Option two is disabled
                  </label>
                </div>
              </fieldset>
              <button type="submit" class="btn btn-primary">Submit</button>
            </fieldset>
          </form>
        </div>
      </div>
      <div class="grow-1 basis-0">
        <form class="bs-component d-flex flex-column gap-3">
          <div class="form-floating">
            <fieldset disabled>
              <input
                class="form-control"
                id="disabledInput"
                type="text"
                placeholder="Disabled input here..."
                aria-label="disabled input"
                disabled="" />
            </fieldset>
          </div>

          <div class="form-floating">
            <fieldset>
              <input
                class="form-control"
                id="readOnlyInput"
                type="text"
                placeholder="Readonly input here…"
                aria-label="read-only input"
                readonly />
            </fieldset>
          </div>

          <div class="form-floating has-success">
            <input
              type="text"
              value="correct value"
              class="form-control is-valid"
              id="inputValid" />
            <label class="form-control-label" for="inputSuccess1"
              >Valid input</label
            >
            <div class="valid-feedback">Success! You've done it.</div>
          </div>

          <div class="form-floating has-danger">
            <input
              type="text"
              value="wrong value"
              class="form-control is-invalid"
              id="inputInvalid" />
            <label class="form-control-label" for="inputDanger1"
              >Invalid input</label
            >
            <div class="invalid-feedback">
              Sorry, that username's taken. Try another?
            </div>
          </div>

          <div class="form-floating">
            <input
              class="form-control form-control-lg"
              type="text"
              placeholder=".form-control-lg"
              id="inputLarge" />
            <label class="col-form-label col-form-label-lg" for="inputLarge"
              >Large input</label
            >
          </div>

          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              placeholder="Default input"
              id="inputDefault" />
            <label class="col-form-label" for="inputDefault"
              >Default input</label
            >
          </div>

          <div class="form-floating">
            <input
              class="form-control form-control-sm"
              type="text"
              placeholder=".form-control-sm"
              id="inputSmall" />
            <label class="col-form-label col-form-label-sm" for="inputSmall"
              >Small input</label
            >
          </div>

          <div class="input-group flex-nowrap">
            <div class="form-floating grow-1">
              <input
                type="text"
                class="form-control form-left"
                placeholder="Enter City, State or ZIP"
                id="locationSearchForm"
                aria-label="Enter your city, state or ZIP" />
              <label for="locationSearchForm"
                >Enter your city, state or ZIP</label
              >
            </div>
            <button
              class="btn btn-secondary d-flex align-items-center form-btn-right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
          </div>

          <div class="input-group flex-nowrap">
            <button
              class="btn btn-secondary d-flex align-items-center form-btn-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
            <div class="form-floating grow-1">
              <input
                type="text"
                class="form-control form-right"
                placeholder="Enter City, State or ZIP"
                id="locationSearchForm"
                aria-label="Enter your city, state or ZIP" />
              <label for="locationSearchForm"
                >Enter your city, state or ZIP</label
              >
            </div>
          </div>

          <div class="input-group flex-nowrap">
            <button
              class="btn btn-outline-secondary d-flex align-items-center form-btn-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-cursor"
                viewBox="0 0 16 16">
                <path
                  d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52 2.25 8.184z" />
              </svg>
            </button>
            <div class="form-floating grow-1">
              <input
                type="text"
                class="form-control rounded-0"
                placeholder="Enter City, State or ZIP"
                id="locationSearchForm"
                aria-label="Enter your city, state or ZIP" />
              <label for="locationSearchForm"
                >Enter your city, state or ZIP</label
              >
            </div>
            <button
              class="btn btn-secondary d-flex align-items-center form-btn-right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16">
                <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
          </div>
        </form>

        <div class="bs-component">
          <fieldset>
            <legend>Custom forms</legend>
            <div class="form-group">
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio1"
                  name="customRadio"
                  class="custom-control-input"
                  checked />
                <label class="custom-control-label" for="customRadio1"
                  >Toggle this custom radio</label
                >
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio2"
                  name="customRadio"
                  class="custom-control-input" />
                <label class="custom-control-label" for="customRadio2"
                  >Or toggle this other custom radio</label
                >
              </div>
              <div class="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio3"
                  name="customRadio"
                  class="custom-control-input"
                  disabled />
                <label class="custom-control-label" for="customRadio3"
                  >Disabled custom radio</label
                >
              </div>
            </div>

            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck1"
                  checked />
                <label class="custom-control-label" for="customCheck1"
                  >Check this custom checkbox</label
                >
              </div>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheck2"
                  disabled />
                <label class="custom-control-label" for="customCheck2"
                  >Disabled custom checkbox</label
                >
              </div>
            </div>

            <div class="form-group">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customSwitch1"
                  checked />
                <label class="custom-control-label" for="customSwitch1"
                  >Toggle this switch element</label
                >
              </div>
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  disabled
                  id="customSwitch2" />
                <label class="custom-control-label" for="customSwitch2"
                  >Disabled switch element</label
                >
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </section>

  <!-- Containers -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <div class="w-100 d-flex flex-row">
      <h1 class="w-100">Containers</h1>
    </div>

    <!--    <app-jumbotron class="w-100"></app-jumbotron>-->

    <!--    <app-jumbotron-->
    <!--      class="w-100"></app-jumbotron>-->

    <!--    <app-jumbotron-->
    <!--      class="w-100"></app-jumbotron>-->
  </section>

  <!-- Nav Tabs -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <h1 class="w-100">Nav Tabs</h1>

    <div class="w-100 d-flex flex-column flex-md-row gap-3">
      <div class="d-flex flex-row flex-wrap grow-1 gap-3">
        <h5 class="w-100">Tabs</h5>
        <div class="w-100">
          <tabset>
            <tab class="p-3" id="tab1">
              <ng-template tabHeading>
                <div class="font-weight-bold">Tab 1</div>
              </ng-template>
              Basic content 1
            </tab>
            <tab class="p-3">
              <ng-template tabHeading>
                <div class="font-weight-bold">Tab 2</div>
              </ng-template>
              Basic content 2
            </tab>
            <tab class="p-3">
              <ng-template tabHeading>
                <div class="font-weight-bold">Tab 3</div>
              </ng-template>
              Basic content 3
            </tab>
          </tabset>
        </div>
      </div>

      <div class="d-flex flex-row flex-wrap grow-1 gap-3">
        <h5 class="w-100">Pills</h5>
        <div class="w-100">
          <tabset type="pills">
            <tab class="p-3" heading="Pills 1">Pills content 1</tab>
            <tab class="p-3" heading="Pills 2">Pills content 2</tab>
          </tabset>
        </div>
      </div>
    </div>

    <div class="w-100 d-flex flex-column gap-3">
      <div class="d-flex flex-row flex-wrap grow-1 gap-3">
        <h5 class="w-100">Breadcrumbs</h5>
        <div class="w-100 d-flex flex-column flex-wrap">
          <ol class="breadcrumb">
            <li class="breadcrumb-item active">Home</li>
          </ol>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Library</li>
          </ol>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Library</a></li>
            <li class="breadcrumb-item active">Data</li>
          </ol>
        </div>
      </div>

      <div class="d-flex flex-row flex-wrap grow-1 gap-3">
        <h5 class="w-100">Pagination</h5>

        <div class="w-100 d-flex flex-column flex-wrap">
          <pagination
            [totalItems]="66"
            [(ngModel)]="currentPage"
            (pageChanged)="pageChanged($event)"></pagination>
        </div>
      </div>
    </div>
  </section>

  <!-- Alerts -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <div class="w-100 d-flex flex-row">
      <h1 class="w-100">Alerts</h1>
    </div>

    <div class="w-100 d-flex flex-column flex-md-row gap-3">
      <alert type="success">
        <h4 class="alert-heading">Well done!</h4>
        <p>
          Aww yeah, you successfully read this important alert message. This
          example text is going to run a bit longer so that you can see how
          spacing within an alert works with this kind of content.
        </p>
        <p class="mb-0">
          Whenever you need to, be sure to use margin utilities to keep things
          nice and tidy.
        </p>
      </alert>
    </div>
    <div class="w-100 d-flex flex-column gap-3">
      <div class="d-flex flex-column flex-md-row gap-3">
        <alert class="grow-1 basis-0" type="success">
          <strong>Well done!</strong> You successfully read this important alert
          message.
        </alert>
        <alert class="grow-1 basis-0" type="info">
          <strong>Heads up!</strong> This alert needs your attention, but it's
          not super important.
        </alert>
      </div>
      <div class="d-flex flex-column flex-md-row gap-3">
        <alert class="grow-1 basis-0" type="warning">
          <strong>Warning!</strong> Better check yourself, you're not looking
          too good.
        </alert>
        <alert class="grow-1 basis-0" type="danger">
          <strong>Oh snap!</strong> Change a few things up and try submitting
          again.
        </alert>
      </div>
    </div>

    <div class="w-100 d-flex flex-column gap-3">
      <div *ngFor="let alert of alerts">
        <alert
          [type]="alert.type"
          [dismissible]="dismissible"
          (onClosed)="onClosed(alert)"
          >{{ alert.msg }}</alert
        >
      </div>
    </div>
  </section>

  <!-- Badges -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <h1 class="w-100">Badges</h1>
    <div class="w-100 d-flex flex-column gap-3">
      <div class="d-flex flex-wrap gap-2">
        <span class="badge badge-primary">Primary</span>
        <span class="badge badge-secondary">Secondary</span>
        <span class="badge badge-success">Success</span>
        <span class="badge badge-danger">Danger</span>
        <span class="badge badge-warning">Warning</span>
        <span class="badge badge-info">Info</span>
        <span class="badge badge-light">Light</span>
        <span class="badge badge-dark">Dark</span>
      </div>
      <div class="d-flex flex-wrap gap-2">
        <span class="badge badge-pill badge-primary">Primary</span>
        <span class="badge badge-pill badge-secondary">Secondary</span>
        <span class="badge badge-pill badge-success">Success</span>
        <span class="badge badge-pill badge-danger">Danger</span>
        <span class="badge badge-pill badge-warning">Warning</span>
        <span class="badge badge-pill badge-info">Info</span>
        <span class="badge badge-pill badge-light">Light</span>
        <span class="badge badge-pill badge-dark">Dark</span>
      </div>
    </div>
  </section>

  <!-- Progress -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <div class="w-100 d-flex flex-row">
      <h1 class="w-100">Progress Bars</h1>
    </div>

    <div class="w-100 d-flex flex-column flex-md-row flex-wrap gap-3">
      <h5 class="w-100" id="progress-basic">Basic</h5>
      <div class="w-100 d-flex flex-column gap-3">
        <progressbar [value]="55"></progressbar>
      </div>

      <div class="w-100 d-flex flex-column gap-3">
        <h5 class="w-100" id="progress-alternatives">
          Contextual alternatives
        </h5>
        <div class="w-100 d-flex flex-column gap-3">
          <progressbar [value]="55" type="success"></progressbar>
          <progressbar [value]="55" type="danger"></progressbar>
          <progressbar [value]="55" type="warning"></progressbar>
          <progressbar [value]="55" type="info"></progressbar>
        </div>
      </div>

      <div class="w-100 d-flex flex-column gap-3">
        <h5 class="w-100" id="progress-alternatives">Stacked</h5>
        <div class="w-100 d-flex flex-column gap-3">
          <progressbar
            class="progress"
            [value]="stacked"
            [max]="100"></progressbar>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            (click)="randomStacked()">
            Randomize
          </button>
        </div>
      </div>

      <div class="w-100 d-flex flex-column gap-3">
        <h5 class="w-100" id="progress-alternatives">Striped</h5>
        <div class="w-100 d-flex flex-column gap-3">
          <progressbar
            [value]="55"
            type="success"
            [striped]="true"></progressbar>
          <progressbar
            [value]="55"
            type="danger"
            [striped]="true"></progressbar>
          <progressbar
            [value]="55"
            type="warning"
            [striped]="true"></progressbar>
          <progressbar [value]="55" type="info" [striped]="true"></progressbar>
        </div>
      </div>

      <div class="w-100 d-flex flex-column gap-3">
        <h5 class="w-100" id="progress-alternatives">Animated</h5>
        <div class="w-100 d-flex flex-column gap-3">
          <progressbar
            [value]="55"
            [striped]="true"
            [animate]="true"></progressbar>
        </div>
      </div>
    </div>
  </section>

  <!-- List Groups -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <div class="w-100 d-flex flex-row">
      <h1 class="w-100">List Groups</h1>
    </div>

    <div class="d-flex flex-column flex-md-row flex-wrap gap-3">
      <h5 class="w-100">Badges</h5>
      <div class="grow-1 basis-0">
        <div class="bs-component">
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-center">
              Cras justo odio
              <span class="badge badge-primary badge-pill">14</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center">
              Dapibus ac facilisis in
              <span class="badge badge-primary badge-pill">2</span>
            </li>
            <li
              class="list-group-item d-flex justify-content-between align-items-center">
              Morbi leo risus
              <span class="badge badge-primary badge-pill">1</span>
            </li>
          </ul>
        </div>
      </div>

      <h5 class="w-100">Links</h5>
      <div class="grow-1 basis-0">
        <div class="bs-component">
          <div class="list-group">
            <a href="#" class="list-group-item list-group-item-action active">
              Cras justo odio
            </a>
            <a href="#" class="list-group-item list-group-item-action"
              >Dapibus ac facilisis in
            </a>
            <a href="#" class="list-group-item list-group-item-action disabled"
              >Morbi leo risus
            </a>
          </div>
        </div>
      </div>

      <h5 class="w-100">List Cards with Headings</h5>
      <div class="grow-1 basis-0">
        <div class="bs-component">
          <div class="list-group">
            <a
              href="#"
              class="list-group-item list-group-item-action flex-column align-items-start active">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">List group item heading</h5>
                <small>3 days ago</small>
              </div>
              <p class="mb-1">
                Donec id elit non mi porta gravida at eget metus. Maecenas sed
                diam eget risus varius blandit.
              </p>
              <small>Donec id elit non mi porta.</small>
            </a>
            <a
              href="#"
              class="list-group-item list-group-item-action flex-column align-items-start">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">List group item heading</h5>
                <small class="text-muted">3 days ago</small>
              </div>
              <p class="mb-1">
                Donec id elit non mi porta gravida at eget metus. Maecenas sed
                diam eget risus varius blandit.
              </p>
              <small class="text-muted">Donec id elit non mi porta.</small>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Cards -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <div class="w-100 d-flex flex-row">
      <h1 class="w-100">Cards</h1>
    </div>

    <div class="d-flex flex-column flex-md-row flex-wrap gap-3">
      <div class="d-flex flex-column gap-3">
        <h5 class="w-100">Colored Cards</h5>
        <div class="card text-white bg-primary" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Primary card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card text-white bg-secondary" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Secondary card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card text-white bg-success" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Success card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card text-white bg-danger" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Danger card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card text-white bg-warning" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Warning card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card text-white bg-info" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Info card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card bg-light" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Light card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card text-white bg-dark" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Dark card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column gap-3">
        <h5 class="w-100">Outlined Cards</h5>
        <div class="card border-primary" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Primary card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card border-secondary" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Secondary card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card border-success" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Success card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card border-danger" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Danger card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card border-warning" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Warning card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card border-info" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Info card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card border-light" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Light card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
        <div class="card border-dark" style="max-width: 20rem">
          <div class="card-header">Header</div>
          <div class="card-body">
            <h4 class="card-title">Dark card title</h4>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column gap-3">
        <h5 class="w-100">Additional Cards</h5>
        <div class="card">
          <h3 class="card-header">Card header</h3>
          <div class="card-body">
            <h5 class="card-title">Special title treatment</h5>
            <h6 class="card-subtitle text-muted">Support card subtitle</h6>
          </div>
          <img
            style="height: 200px; width: 100%; display: block"
            src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22318%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20318%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_158bd1d28ef%20text%20%7B%20fill%3Argba(255%2C255%2C255%2C.75)%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_158bd1d28ef%22%3E%3Crect%20width%3D%22318%22%20height%3D%22180%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22129.359375%22%20y%3D%2297.35%22%3EImage%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
            alt="Card image" />
          <div class="card-body">
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">Cras justo odio</li>
            <li class="list-group-item">Dapibus ac facilisis in</li>
            <li class="list-group-item">Vestibulum at eros</li>
          </ul>
          <div class="card-body">
            <a href="#" class="card-link">Card link</a>
            <a href="#" class="card-link">Another link</a>
          </div>
          <div class="card-footer text-muted">2 days ago</div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Card title</h4>
            <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
            <a href="#" class="card-link">Card link</a>
            <a href="#" class="card-link">Another link</a>
          </div>
        </div>

        <div class="menu-grid">
          <button type="button" class="category-card border-0 w-100">
            <img
              src="/assets/images/categories/fb-burrito-demo.png"
              class="card-img-top"
              alt="..." />
            <div
              class="card-body w-100 d-flex flex-row flex-wrap text-center justify-content-center gap-2">
              <h3 class="category-title w-100">Burritos</h3>
            </div>
          </button>

          <button type="button" class="category-card border-0 w-100">
            <img
              src="/assets/images/categories/fb-burrito-demo.png"
              class="card-img-top"
              alt="..." />
            <div
              class="card-body w-100 d-flex flex-row flex-wrap text-center justify-content-center gap-2">
              <h3 class="category-title w-100">Bowls</h3>
            </div>
          </button>

          <button type="button" class="category-card border-0 w-100">
            <img
              src="/assets/images/categories/fb-burrito-demo.png"
              class="card-img-top"
              alt="..." />
            <div
              class="card-body w-100 d-flex flex-row flex-wrap text-center justify-content-center gap-2">
              <h3 class="category-title w-100">Salads</h3>
            </div>
          </button>

          <button type="button" class="category-card border-0 w-100">
            <img
              src="/assets/images/categories/fb-burrito-demo.png"
              class="card-img-top"
              alt="..." />
            <div
              class="card-body w-100 d-flex flex-row flex-wrap text-center justify-content-center gap-2">
              <h3 class="category-title w-100">Nachos</h3>
            </div>
          </button>
        </div>
      </div>
    </div>
  </section>

  <!-- Dialogs -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <div class="w-100 d-flex flex-row">
      <h1 class="w-100">Dialogs</h1>
    </div>

    <div class="d-flex flex-column flex-md-row flex-wrap gap-3">
      <h5 class="w-100">Modals</h5>

      <!-- Modal Large Button -->
      <div>
        <button class="btn btn-primary" (click)="lgModal.show()">
          Large modal
        </button>
      </div>
      <!-- Modal Large -->
      <div
        bsModal
        #lgModal="bs-modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="dialog-sizes-name1">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 id="dialog-sizes-name1" class="modal-title pull-left">
                Large modal
              </h4>
              <button
                type="button"
                class="btn-close close pull-right"
                (click)="lgModal.hide()"
                aria-label="Close">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
              </button>
            </div>
            <div class="modal-body">Modal content goes here...</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-default"
                (click)="lgModal.hide()"
                aria-label="Close">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Small Button -->
      <div>
        <button type="button" class="btn btn-primary" (click)="smModal.show()">
          Small modal
        </button>
      </div>
      <!-- Modal Small -->
      <div
        bsModal
        #smModal="bs-modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="dialog-sizes-name2">
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h4 id="dialog-sizes-name2" class="modal-title pull-left">
                Small modal
              </h4>
              <button
                type="button"
                class="btn-close close pull-right"
                aria-label="Close"
                (click)="smModal.hide()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
              </button>
            </div>
            <div class="modal-body">Modal content goes here...</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-default"
                aria-label="Close"
                (click)="smModal.hide()">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column flex-md-row flex-wrap gap-3">
      <h5 class="w-100">Tooltips</h5>
      <div class="w-100 d-flex flex-row flex-wrap gap-2">
        <button
          type="button"
          class="btn btn-default btn-secondary"
          tooltip="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          placement="top">
          Tooltip on top
        </button>

        <button
          type="button"
          class="btn btn-default btn-secondary"
          tooltip="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          placement="right">
          Tooltip on right
        </button>

        <button
          type="button"
          class="btn btn-default btn-secondary"
          tooltip="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          placement="auto">
          Tooltip auto
        </button>

        <button
          type="button"
          class="btn btn-default btn-secondary"
          tooltip="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          placement="left">
          Tooltip on left
        </button>

        <button
          type="button"
          class="btn btn-default btn-secondary"
          tooltip="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          placement="bottom">
          Tooltip on bottom
        </button>
      </div>
    </div>

    <div class="d-flex flex-column flex-md-row flex-wrap gap-3">
      <h5 class="w-100">Popovers</h5>
      <div class="w-100 d-flex flex-row flex-wrap gap-2">
        <button
          type="button"
          class="btn btn-default btn-secondary"
          [outsideClick]="true"
          popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          popoverTitle="Popover on top"
          placement="top">
          Popover on top
        </button>

        <button
          type="button"
          class="btn btn-default btn-secondary"
          [outsideClick]="true"
          popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          popoverTitle="Popover on right"
          placement="right">
          Popover on right
        </button>

        <button
          type="button"
          class="btn btn-default btn-secondary"
          [outsideClick]="true"
          popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          popoverTitle="Popover auto"
          placement="auto">
          Popover auto
        </button>

        <button
          type="button"
          class="btn btn-default btn-secondary"
          [outsideClick]="true"
          popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          popoverTitle="Popover on left"
          placement="left">
          Popover on left
        </button>

        <button
          type="button"
          class="btn btn-default btn-secondary"
          [outsideClick]="true"
          popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          popoverTitle="Popover on bottom"
          placement="bottom">
          Popover on bottom
        </button>
      </div>
    </div>
  </section>

  <!-- Tables -->
  <section class="d-flex flex-wrap gap-3 py-4">
    <h1 class="w-100">Tables</h1>

    <div class="w-100 d-flex flex-column gap-3">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Type</th>
            <th scope="col">Column heading</th>
            <th scope="col">Column heading</th>
            <th scope="col">Column heading</th>
          </tr>
        </thead>
        <tbody>
          <tr class="table-active">
            <th scope="d-flex flex-row">Active</th>
            <td>Column content</td>
            <td>Column content</td>
            <td>Column content</td>
          </tr>
          <tr>
            <th scope="d-flex flex-row">Default</th>
            <td>Column content</td>
            <td>Column content</td>
            <td>Column content</td>
          </tr>
          <tr class="table-primary">
            <th scope="d-flex flex-row">Primary</th>
            <td>Column content</td>
            <td>Column content</td>
            <td>Column content</td>
          </tr>
          <tr class="table-secondary">
            <th scope="d-flex flex-row">Secondary</th>
            <td>Column content</td>
            <td>Column content</td>
            <td>Column content</td>
          </tr>
          <tr class="table-success">
            <th scope="d-flex flex-d-flex flex-row">Success</th>
            <td>Column content</td>
            <td>Column content</td>
            <td>Column content</td>
          </tr>
          <tr class="table-danger">
            <th scope="d-flex flex-row">Danger</th>
            <td>Column content</td>
            <td>Column content</td>
            <td>Column content</td>
          </tr>
          <tr class="table-warning">
            <th scope="d-flex flex-row">Warning</th>
            <td>Column content</td>
            <td>Column content</td>
            <td>Column content</td>
          </tr>
          <tr class="table-info">
            <th scope="d-flex flex-row">Info</th>
            <td>Column content</td>
            <td>Column content</td>
            <td>Column content</td>
          </tr>
          <tr class="table-light">
            <th scope="d-flex flex-row">Light</th>
            <td>Column content</td>
            <td>Column content</td>
            <td>Column content</td>
          </tr>
          <tr class="table-dark">
            <th scope="d-flex flex-row">Dark</th>
            <td>Column content</td>
            <td>Column content</td>
            <td>Column content</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</div>
