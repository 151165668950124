<ng-container [ngSwitch]="productGroupType">
  <div *ngSwitchCase="'upsell'">
    <div
      class="d-flex flex-column mx-sm-0 gap-3 px-0 py-3 px-sm-3 bg-light border product-group rounded">
      <div class="product-group-title-upsell w-100 d-flex flex-row flex-nowrap">
        <div class="d-flex flex-column">
          <h4 class="px-3 px-sm-0">{{ subcategoryName }}</h4>
          <small>
            *Selected {{ subcategoryName }} will be added to your bag
            immediately.
            <ng-container *ngIf="subcategoryName === 'sauce on side'"
              >Choose up to 3.</ng-container
            >
          </small>
        </div>
        <div
          class="d-flex gap-2 ml-auto"
          *ngIf="menuGrid.scrollWidth > menuGrid.offsetWidth">
          <button type="button" class="arrow-button" (click)="scrollLeft()">
            <div class="d-flex bg-white rounded-circle text-dark">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-arrow-left-circle"
                viewBox="0 0 16 16">
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
              </svg>
            </div>
          </button>

          <button type="button" class="arrow-button" (click)="scrollRight()">
            <div class="d-flex bg-white rounded-circle text-dark">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-arrow-right-circle"
                viewBox="0 0 16 16">
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
              </svg>
            </div>
          </button>
        </div>
      </div>
      <div class="menu-grid" #menuGrid>
        <ng-container *ngFor="let product of products; trackBy: productTrackBy">
          <app-product-card
            [productCardType]="'upsell'"
            [product]="product"></app-product-card>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'product'" class="product-group-shell d-flex flex-column">
    <div
      class="product-group-title w-100 d-flex flex-row flex-nowrap pt-4 pb-3 pb-sm-0 px-3 px-md-0 rounded">
      <h4>{{ subcategoryName ?? category.name }}</h4>
    </div>
    <div class="menu-grid">
      <ng-container *ngFor="let product of products">
        <app-product-card
          [selectedProduct]="selectedProduct"
          [isEditMode]="isEditMode"
          [productCardType]="'product'"
          [product]="product"
          (productSelect)="productSelect.emit(product)"></app-product-card>
      </ng-container>
    </div>
  </div>

  <div *ngSwitchCase="'size'" class="product-group-shell d-flex flex-column">
    <div
      class="product-group-title w-100 d-flex flex-row flex-nowrap pt-4 pb-3 pb-sm-0 px-3 px-md-0 rounded justify-content-between align-items-center">
      <h4>{{ category.content.name }}</h4>
      <div class="d-flex flex-row flex-nowrap gap-3">
        <div
          class="badge rounded-pill bg-warning text-black d-flex align-items-center px-2">
          Select 1
        </div>
        <div
          *ngIf="selectedProduct; else required"
          class="badge rounded-pill bg-success text-black ml-auto d-flex align-items-center px-2">
          Complete
        </div>
        <ng-template #required>
          <div
            class="badge rounded-pill bg-danger text-black ml-auto d-flex align-items-center px-2">
            Required
          </div>
        </ng-template>
      </div>
    </div>
    <div class="menu-grid">
      <ng-container *ngFor="let product of products">
        <app-product-card
          [selectedProduct]="selectedProduct"
          [isEditMode]="isEditMode"
          [productCardType]="'size'"
          [product]="product"
          [isSelected]="
            product?.chainproductid === selectedProduct?.chainproductid
          "
          (productSelect)="productSelect.emit(product)"></app-product-card>
      </ng-container>
    </div>
  </div>

  <div *ngSwitchCase="'saved'" class="product-group-shell d-flex flex-column">
    <div
      class="product-group-title w-100 d-flex flex-row flex-nowrap pt-4 pb-3 pb-sm-0 px-3 px-md-0 rounded"></div>
    <div class="menu-grid">
      <ng-container *ngFor="let product of savedProducts">
        <app-product-card
          [productCardType]="'saved'"
          [savedProduct]="product"></app-product-card>
      </ng-container>
    </div>
  </div>
</ng-container>
