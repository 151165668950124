<button type="button" class="ingredient-card w-100" (click)="cardClick()">
  <div class="text-center">
    <h5>{{ collectionItem.item?.name }}</h5>
    <!--    <p class="m-0">-->
    <!--      Grilled chicken - marinated in adobo-->
    <!--    </p>-->
  </div>
</button>

<ng-template #infoModal>
  <app-sm-info-modal
    [name]="collectionItem.item!.name!"
    [description]="
      collectionItem.item?.long_description ??
      collectionItem.item!.short_description!
    "
    [badge]="collectionItem.item?.badge!"
    [image]="collectionItem.item?.image!"></app-sm-info-modal>
</ng-template>
