<div class="modal-header rounded-top align-items-center">
  <img
    class="h4-birds"
    src="assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="" />
  <h4 class="modal-title grow-1">All Rewards</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="onClose()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1 h-100 d-flex flex-column gap-3">
  <div class="alert-info alert rounded">
    <div class="d-flex flex-nowrap gap-3 align-items-center">
      <img
        class="alert-perks-icon d-none d-md-flex"
        src="/assets/images/icons/Icons/fb-perks-solid.svg"
        alt="" />
      <div class="d-flex flex-column">
        <h5>How to Use Your Rewards:</h5>
        To redeem a reward, please add the item related to the reward to
        your cart and then redeem the reward at checkout.
      </div>
    </div>
  </div>
  <div *ngIf="rewards.length === 0">
    <p>No rewards available.</p>
  </div>
  <div *ngIf="rewards.length > 0">
    <div class="d-flex flex-column gap-3">
      <ng-container *ngFor="let reward of rewards">
        <app-reward-card
          class="rounded overflow-hidden"
          [rewardCardStyle]="'large'"
          [viewDetails]="false"
          [isUnlocked]="reward.points_required_to_redeem <= pointBalances.redeemable_points"
          [reward]="reward">
        </app-reward-card>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onClose()">Close</button>
</div>
