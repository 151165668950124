import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideViewMenu',
})
export class HideViewMenuPipe implements PipeTransform {
  transform(fragment: string | null): boolean {
    return fragment !== 'menu';
  }
}
