<ng-container *ngIf="location">
  <button class="card location-card w-100" (click)="selectLocation(location)">
    <div class="card-body text-left p-0 d-flex flex-column gap-1 w-100">
      <div class="d-flex flex-nowrap">
        <h6 class="card-title flex-grow-1">{{ location.name }}</h6>
        <ng-container *ngIf="location">
          <div class="d-flex justify-content-end">
            <img
              class="w-100 h-auto cursor-pointer"
              [class.d-none]="!user"
              style="max-height: 24px; max-width: 24px; min-height: 24px; min-width: 24px;"
              [src]="
                  (location | favoriteLocation: favoriteLocations!)
                    ? 'assets/images/icons/SVG/heart-filled.svg'
                    : 'assets/images/icons/SVG/heart.svg'
                "
              (click)="toggleFavoriteLocation(location)"
              alt="Favorited Location"/>
          </div>
        </ng-container>
      </div>
      <div class="d-flex flex-column">
        <small class="card-subtitle w-100">
          {{ location.streetaddress }}
        </small>
        <small class="card-text w-100"> {{ location.city }}, {{ location.state }}
          {{ location.zip }}
        </small>
      </div>
      <div class="d-flex flex-nowrap gap-2">
        <ng-container *ngIf="location.iscurrentlyopen; else closed">
          <span class="badge badge-pill badge-success w-fit-content">Open</span>
        </ng-container>
        <ng-template #closed>
          <span class="badge badge-pill badge-danger w-fit-content">Closed</span>
        </ng-template>
        <ng-container *ngIf="!location.isavailable">
          <span class="badge badge-pill badge-warning w-fit-content">Ordering Unavailable</span>
        </ng-container>
        <ng-container *ngIf="location.content?.supports_catering">
          <span class="badge badge-pill badge-info w-fit-content">Catering</span>
        </ng-container>
        <ng-container *ngIf="location.supportsdrivethru">
          <span class="badge badge-pill badge-dark w-fit-content">Mobile Pickup Lane</span>
        </ng-container>
      </div>
    </div>
  </button>
</ng-container>

<ng-container *ngIf="favoriteLocation">
  <button class="card location-card-favorite w-100" (click)="selectLocation(favoriteLocation)">
    <div class="card-body text-left p-0 d-flex flex-column gap-1 w-100">
      <div class="d-flex flex-nowrap">
        <h6 class="card-title flex-grow-1">{{ favoriteLocation.name }}</h6>
      </div>
      <div class="d-flex flex-column">
        <small class="card-subtitle w-100">
          {{ favoriteLocation.streetaddress }}
        </small>
        <small class="card-text w-100"> {{ favoriteLocation.city }}, {{ favoriteLocation.state }}
          {{ favoriteLocation.zip }}
        </small>
      </div>
      <div class="d-flex flex-nowrap gap-2">
        <ng-container *ngIf="favoriteLocation.iscurrentlyopen; else closed">
          <span class="badge badge-pill badge-success w-fit-content">Open</span>
        </ng-container>
        <ng-template #closed>
          <span class="badge badge-pill badge-danger w-fit-content">Closed</span>
        </ng-template>
        <ng-container *ngIf="!favoriteLocation.isavailable">
          <span class="badge badge-pill badge-warning w-fit-content">Ordering Unavailable</span>
        </ng-container>
        <ng-container *ngIf="favoriteLocation.supportsdrivethru">
          <span class="badge badge-pill badge-dark w-fit-content">Mobile Pickup Lane</span>
        </ng-container>
      </div>
    </div>
  </button>
</ng-container>
