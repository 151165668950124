<div [ngClass]="section.featurette_theme!">
  <div class="container py-4 py-md-5">
    <div
      class="d-flex flex-column flex-lg-row gap-4 justify-content-center justify-content-lg-start align-items-center align-items-lg-start">
      <ng-container *ngFor="let featurette of section.featurette_sections">
        <div class="grow-1 basis-0 my-3 my-lg-0 align-self-stretch">
          <div class="d-flex flex-column align-items-center text-center h-100">
            <ng-container *ngIf="(featurette?.item)!.image"><img
              [src]="(featurette?.item)!.image! | cmsAsset"
              class="card-img-top mb-3 rounded"
              alt="{{ featurette!.item!.title! }}"/></ng-container>

            <ng-container *ngIf="featurette!.item!.title!">
              <h2>{{ featurette!.item!.title! }}</h2>
            </ng-container>
            <ng-container *ngIf="featurette!.item!.description!">
              <p>{{ featurette!.item!.description! }}</p>
            </ng-container>
            <ng-container *ngIf="featurette!.item!.cta_text! && featurette!.item!.cta_link!">
              <button
                type="button"
                class="btn btn-secondary mt-auto"
                (click)="linkClick(featurette!.item!.cta_link!)">
                {{ featurette!.item!.cta_text! }}
              </button>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
