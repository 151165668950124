import { Pipe, PipeTransform } from '@angular/core';
import { NgxTimelineEvent } from '@frxjs/ngx-timeline';
import { AccountActivity } from '@models/index';
import * as moment from 'moment';

@Pipe({
  name: 'accountActivityToNgxTimelineEvents',
})
export class AccountActivityToNgxTimelineEventsPipe implements PipeTransform {
  transform(activities: AccountActivity[]): NgxTimelineEvent[] {
    return activities.map((activity, index) => {
      return <NgxTimelineEvent>{
        id: index,
        title: activity.event_title,
        description: activity.description,
        timestamp: moment(activity.date).toDate(),
      };
    });
  }
}
