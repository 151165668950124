import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScheduledAnnouncement } from '@models/index';
import { Store } from '@ngxs/store';
import { CloseAnnouncementBar } from '@store/actions/content.actions';

@Component({
  selector: 'app-announcement-bar',
  templateUrl: './announcement-bar.component.html',
  styleUrls: ['announcement-bar.component.scss'],
})
export class AnnouncementBarComponent implements OnInit {
  @Input() announcement!: ScheduledAnnouncement;
  constructor(private router: Router, private store: Store) {}
  ngOnInit() {}

  announcementClicked(event: Event) {
    event.stopPropagation();
    if (!this.announcement.announcement_link) {
      return;
    }

    if (!this.announcement.open_in_new_tab) {
      if (this.announcement?.announcement_link?.includes('http')) {
        window.location.href = this.announcement.announcement_link;
      } else {
        this.router.navigate([this.announcement.announcement_link]);
      }
    } else {
      const path = this.announcement.announcement_link;

      if (this.announcement?.announcement_link?.includes('http')) {
        window.open(path, '_blank');
      } else {
        const tree = this.router.createUrlTree([path]);
        window.open(tree.toString(), '_blank');
      }
    }
  }

  onClose() {
    console.log(this.announcement);
    this.store.dispatch(new CloseAnnouncementBar());
  }
}
