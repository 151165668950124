import { Pipe, PipeTransform } from '@angular/core';
import { FeaturedAd } from '@models/index';
import * as moment from 'moment';

@Pipe({
  name: 'featuredAdsFilter',
})
export class FeaturedAdsFilterPipe implements PipeTransform {
  transform(value: Array<FeaturedAd | undefined> | undefined): FeaturedAd[] {
    if (!value) {
      return [];
    }
    console.log(value);
    return value
      .filter((i) => i !== undefined)
      .filter((item) => {
        if (
          !(
            window.location.hostname.includes('freebirds.com') &&
            item!.item!.status !== 'published'
          )
        ) {
          const today = moment();
          // console.log(today);
          // console.log(moment(item!.start_date));
          switch (item!.item!.schedule_type) {
            case 'recurring': // Recurring Message
              if (
                (item!.item!.scheduled_recurring_days ?? []).includes(
                  // @ts-ignore
                  moment().format('dddd').toLowerCase(),
                )
              ) {
                return true;
              }
              break;
            case 'single_day': // Single Day
              if (
                moment(today).isSame(
                  moment(item!.item!.scheduled_day, 'YYYY-MM-DD'),
                  'day',
                )
              ) {
                return true;
              }
              // if (item!.item!.scheduled_day === null) {
              //   return true;
              // }
              break;
            case 'time_period': // Time Period
              if (
                moment(today).isSameOrAfter(
                  moment(item!.item!.scheduled_start_date, 'YYYY-MM-DD'),
                ) &&
                moment(today).isSameOrBefore(
                  moment(item!.item!.scheduled_end_date, 'YYYY-MM-DD'),
                )
              ) {
                return true;
              }
              break;
          }
          return false;
        }
        return false;
      }) as FeaturedAd[];
  }
}
