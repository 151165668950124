import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { User } from '@models/index';
import {
  AvailableReward,
  Category,
  Ingredient,
  Order,
  OrderProduct,
  PastOrder,
  Product,
  Restaurant,
} from '@models/index';
import { BasketProduct, HandoffMode } from '@server/vendor/olo/interfaces';
import { EpsilonService } from '@services/analytics/epsilon.service';
import { EpsilonPromoTypes } from '@services/analytics/models/epsilon.promo-types';
import { filter } from 'rxjs/operators';

import {
  GTMImpression,
  GTMItem,
  GTMProduct,
  GTMPromotion,
} from './models/gtm.event';
import { SegmentProduct } from './models/segment.order-completed';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private previousURL = '';
  private currentURL = '';
  private timers: number[] = [];
  private useUA = false; // Switch this to use GA4 events instead
  private renderer: Renderer2;

  get dataLayer(): any[] {
    if (isPlatformBrowser(this._platformId)) {
      return (window as any).dataLayer || [];
    } else {
      return [];
    }
  }

  constructor(
    private router: Router,
    // private segment: SegmentService,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    private epsilon: EpsilonService, // private hotjar: NgxHotjarService,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    this.router.events
      .pipe(
        filter(
          (e) => e instanceof NavigationEnd || e instanceof NavigationStart,
        ),
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.timers.push(Date.now());
        } else {
          // @ts-ignore
          const start: number = this.timers.pop();
          const delta = (Date.now() - start) / 1000;
          this.previousURL = this.currentURL;
          // @ts-ignore
          this.currentURL = event.url;
          this.logNavigationTime(this.currentURL, this.previousURL, delta);
          this.dataLayer.push({ event: 'page_view' });
        }
      });
  }

  logIdentifyOnLogin(user: User): void {
    this.pushTag({
      event: 'sign_in',
    });
  }

  logContactUs(category: string, restaurant?: Restaurant) {
    // const properties: SegmentContactUs = {
    //   category,
    //   venue_id: restaurant ? restaurant.extref : undefined,
    //   venue_name: restaurant ? restaurant.name : undefined,
    // };
    // this.segment.track(SegmentEvents.CONTACT_US, properties);
  }

  logNavigationTime(
    currentURL: string,
    previousURL: string,
    delta: number,
  ): void {
    this.pushTag({
      event: this.useUA ? 'pageNavigation' : 'page_navigation',
      previousURL,
      currentURL,
      time: delta,
    });
  }

  logHomePageView(title: string, user?: User): void {
    this.epsilon.sendPageVisit(
      EpsilonPromoTypes.HOMEPAGE,
      user?.email,
      user ? String(user.user_id) : undefined,
      undefined,
      window.location.href,
    );
    this.pushTag({
      event: 'homepage_load',
      title,
    });
    this.pushTag({
      event: 'view_menu',
    });
  }

  logCustomizePageView(product: Product, user?: User): void {
    this.epsilon.sendPageVisit(
      EpsilonPromoTypes.PRODUCT_DETAIL,
      user?.email,
      user ? String(user.user_id) : undefined,
      this.epsilon.slug(product.name),
      window.location.href,
    );
  }

  logCartPageView(user?: User, product?: BasketProduct): void {
    this.epsilon.sendPageVisit(
      EpsilonPromoTypes.CART,
      user?.email,
      user ? String(user.user_id) : undefined,
      product ? this.epsilon.slug(product.name) : undefined,
      window.location.href,
    );

    // this.hotjar.hj('event', 'cartView');
  }

  logSearchResultsPageView(user?: User): void {
    this.epsilon.sendPageVisit(
      EpsilonPromoTypes.SEARCH_RESULTS,
      user?.email,
      user ? String(user.user_id) : undefined,
      undefined,
      window.location.href,
    );
    this.pushTag({
      event: 'view_store_list',
    });
    // this.hotjar.hj('event', 'startOrder');
  }

  logStoreLocatorPageView(user?: User): void {
    this.epsilon.sendPageVisit(
      EpsilonPromoTypes.STORE_LOCATOR,
      user?.email,
      user ? String(user.user_id) : undefined,
      undefined,
      window.location.href,
    );

    // this.hotjar.hj('event', 'storeLocator');
  }

  logLocationSelect(location: Restaurant) {
    this.resetEcommerce();
    this.pushTag({
      event: 'select_location',
      ecommerce: {
        location_name: location?.name,
        location_code: location?.extref,
      },
    });
  }

  logRewardsPageView(user?: User): void {
    this.epsilon.sendPageVisit(
      EpsilonPromoTypes.REWARDS_PAGE,
      user?.email,
      user ? String(user.user_id) : undefined,
      undefined,
      window.location.href,
    );
    this.pushTag({
      event: 'rewards_click',
    });

    // this.hotjar.hj('event', 'rewardsPageView');
  }

  logGiftCardPageView(user?: User): void {
    this.epsilon.sendPageVisit(
      EpsilonPromoTypes.GIFT_CARD_PAGE,
      user?.email,
      user ? String(user.user_id) : undefined,
      undefined,
      window.location.href,
    );
  }

  logNutritionPageView(user?: User): void {
    this.epsilon.sendPageVisit(
      EpsilonPromoTypes.NUTRITION_PAGE,
      user?.email,
      user ? String(user.user_id) : undefined,
      undefined,
      window.location.href,
    );

    // this.hotjar.hj('event', 'nutritionPageView');
  }

  /**
   * Only fire after a successful account signup
   * @param user
   */
  logAccountSignupPageView(user?: User): void {
    this.epsilon.sendPageVisit(
      EpsilonPromoTypes.ACCOUNT_SIGNUP,
      user?.email,
      user ? String(user.user_id) : undefined,
      undefined,
      window.location.href,
    );
    this.pushTag({
      event: 'sign_up',
    });

    // this.hotjar.hj('event', 'accountSignup');
  }

  logAccountCenterPagesPageView(user: User): void {
    this.epsilon.sendPageVisit(
      EpsilonPromoTypes.ACCOUNT_CENTER_PAGES,
      user.email,
      String(user.user_id),
      undefined,
      window.location.href,
    );
  }

  logLoyaltyJoinNowAction(user: User, order: PastOrder): void {
    this.epsilon.sendActionConversionTag(
      EpsilonPromoTypes.LOYALTY_JOIN_NOW,
      order,
      user.email,
      String(user.user_id),
      window.location.href,
    );
    this.pushTag({
      event: 'sign_up_checkout',
    });

    // this.hotjar.hj('event', 'loyaltyJoinNow');
  }

  logProductListView(products: Category[], location: Restaurant): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'viewItemList',
        ecommerce: {
          currencyCode: 'USD',
          impressions: products.map((cat, i) =>
            this.oloCategoryToGTMImpression(cat, i),
          ),
        },
      });
    } else {
      this.pushTag({
        event: 'view_item_list',
        ecommerce: {
          items: products.map((cat, index) =>
            this.oloCategoryToGTMItem(cat, index),
          ),
          location_name: location?.name,
          location_code: location?.extref,
        },
      });
    }

    // this.hotjar.hj('event', 'categoryView');
  }

  logProductClick(product: Category, location: Restaurant): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'productClick',
        ecommerce: {
          click: { list: 'Menu' },
          products: [this.oloCategoryToGTMImpression(product, 0)],
        },
      });
    } else {
      this.pushTag({
        event: 'select_item',
        ecommerce: {
          items: [this.oloCategoryToGTMItem(product, 0)],
          location_name: location?.name,
          location_code: location?.extref,
        },
      });
    }

    // this.hotjar.hj('event', 'productClick');
  }

  logProductDetailView(
    product: Product,
    category: Category,
    location: Restaurant,
  ): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'viewItem',
        ecommerce: {
          detail: {
            actionField: { list: category.name },
            products: [
              this.oloMenuProductToGTMProduct(product, category, false),
            ],
          },
        },
      });
    } else {
      this.pushTag({
        event: 'view_item',
        ecommerce: {
          items: [this.oloMenuProductToGTMItem(product, category)],
          location_name: location?.name,
          location_code: location?.extref,
        },
      });
    }

    // this.hotjar.hj('event', 'productView');
  }

  logAddToCart(
    products: OrderProduct[],
    category: Category,
    location: Restaurant,
  ): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'USD',
          add: {
            products: products.map((product) =>
              this.oloBasketProductToGTMProduct(product, category, false),
            ),
          },
        },
      });
    } else {
      this.pushTag({
        event: 'add_to_cart',
        ecommerce: {
          items: products.map((product) =>
            this.oloBasketProductToGTMItem(product),
          ),
          location_name: location?.name,
          location_code: location?.extref,
        },
      });
    }

    // this.hotjar.hj('event', 'addToCart');
  }

  logRemoveFromCart(
    product: BasketProduct,
    menu: Category[],
    location: Restaurant,
  ): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              this.oloBasketProductToGTMProduct(
                product,
                menu.find((cat) =>
                  cat.products.find((prod) => product.productId === prod.id),
                )!,
                false,
              ),
            ],
          },
        },
      });
    } else {
      this.pushTag({
        event: 'remove_from_cart',
        ecommerce: {
          items: [this.oloRemoveBasketProductToGTMItem(product)],
          location_name: location?.name,
          location_code: location?.extref,
        },
      });
    }

    // this.hotjar.hj('event', 'removeFromCart');
  }

  logPromotionListView(rewards: AvailableReward[], location: Restaurant): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'viewPromotion',
        ecommerce: {
          promoView: {
            promotions: rewards.map((reward, index) =>
              this.oloRewardToGTMPromotion(reward, index),
            ),
          },
        },
      });
    } else {
      this.pushTag({
        event: 'view_promotion',
        ecommerce: {
          items: rewards.map((reward, index) =>
            this.oloRewardToGTMItem(reward, index),
          ),
          location_name: location?.name,
          location_code: location?.extref,
        },
      });
    }
  }

  logPromotionClick(
    reward: AvailableReward,
    index: number,
    location: Restaurant,
  ): void {
    this.resetEcommerce();
    // const properties: SegmentCouponApplied = {
    //   promo_codes: reward.label,
    // };
    // this.segment.track(SegmentEvents.COUPON_APPLIED, properties);
    if (this.useUA) {
      this.pushTag({
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions: [this.oloRewardToGTMPromotion(reward, index)],
          },
        },
      });
    } else {
      this.pushTag({
        event: 'select_promotion',
        ecommerce: {
          items: [this.oloRewardToGTMItem(reward, index)],
          location_name: location?.name,
          location_code: location?.extref,
        },
      });
    }
    // this.hotjar.hj('event', 'rewardApplied');
  }

  logCheckoutDetail(
    products: BasketProduct[],
    menu: Category[],
    restaurant: Restaurant,
    handoffMode: HandoffMode,
  ): void {
    // const properties: SegmentCheckoutStarted = {
    //   venue_id: restaurant.extref,
    //   venue_name: restaurant.name,
    // };
    // this.segment.track(SegmentEvents.CHECKOUT_STARTED, properties);
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'checkoutDetails',
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: 'Checkout Details' },
            products: products.map((prod) =>
              this.oloBasketProductToGTMProduct(
                prod,
                menu.find((cat) =>
                  cat.products.find((product) => prod.productId === product.id),
                )!,
                false,
              ),
            ),
          },
        },
      });
    } else {
      this.pushTag({
        event: 'begin_checkout',
        ecommerce: {
          items: products.map((prod) =>
            this.oloRemoveBasketProductToGTMItem(prod),
          ),
          location: restaurant.name,
          location_code: restaurant.extref,
          order_type: handoffMode,
        },
      });
    }
    // this.hotjar.hj('event', 'checkoutStart');
  }

  logCheckoutReview(
    products: BasketProduct[],
    menu: Category[],
    restaurant: Restaurant,
    basketID: string,
  ): void {
    // const properties: SegmentPaymentInfoEntered = {
    //   checkout_id: basketID,
    //   step: 1,
    //   venue_id: restaurant.extref,
    //   venue_name: restaurant.name,
    // };
    // this.segment.track(SegmentEvents.PAYMENT_INFO_ENTERED, properties);
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'checkoutReview',
        ecommerce: {
          checkout: {
            actionField: { step: 2, option: 'Checkout Review' },
            products: products.map((prod) =>
              this.oloBasketProductToGTMProduct(
                prod,
                menu.find((cat) =>
                  cat.products.find((product) => prod.productId === product.id),
                )!,
                false,
              ),
            ),
          },
        },
      });
    }
  }

  logAddShippingInfo(basket: Order, location: Restaurant) {
    this.resetEcommerce();
    this.pushTag({
      event: 'add_shipping_info',
      ecommerce: {
        currency: 'USD',
        value: basket.subtotal,
        coupon: basket.coupon ? basket.coupon.couponcode : '',
        items: basket.products.map((prod) =>
          this.oloRemoveBasketProductToGTMItem(prod),
        ),
        order_type: basket.deliverymode,
        location_name: location?.name,
        location_code: location?.extref,
      },
    });
  }

  logAddPaymentInfo(basket: Order) {
    this.resetEcommerce();
    this.pushTag({
      event: 'add_payment_info',
      ecommerce: {
        currency: 'USD',
        value: basket.subtotal,
        coupon: basket.coupon ? basket.coupon.couponcode : '',
        payment_type: 'Credit Card',
        items: basket.products.map((prod) =>
          this.oloRemoveBasketProductToGTMItem(prod),
        ),
        order_type: basket.deliverymode,
      },
    });
  }

  logAddGiftCardInfo(basket: Order) {
    this.resetEcommerce();
    this.pushTag({
      event: 'add_gift_card_info',
      ecommerce: {
        currency: 'USD',
        value: basket.subtotal,
        coupon: basket.coupon ? basket.coupon.couponcode : '',
        payment_type: 'Gift Card',
        items: basket.products.map((prod) =>
          this.oloRemoveBasketProductToGTMItem(prod),
        ),
        order_type: basket.deliverymode,
      },
    });
  }

  logCheckoutError() {
    this.pushTag({
      event: 'checkout_error',
    });
  }

  logPurchase(
    basket: Order,
    menu: Category[],
    order: PastOrder,
    email?: string,
    user?: User,
  ): void {
    // const properties: SegmentOrderCompleted = {
    //   checkout_id: basket.id,
    //   currency: 'USD',
    //   order_id: order.oloid,
    //   order_revenue: basket.subtotal,
    //   order_total: basket.total,
    //   products: basket.products.map((prod) =>
    //     this.oloBasketProductToSegmentProduct(
    //       prod,
    //       menu.find((cat) =>
    //         cat.products.find((product) => prod.productId === product.id),
    //       )!,
    //     ),
    //   ),
    //   promo_codes:
    //     basket.appliedrewards && basket.appliedrewards[0]
    //       ? basket.appliedrewards[0].label
    //       : undefined,
    //   venue_id: order.vendorextref,
    //   venue_name: order.vendorname,
    // };
    // this.segment.track(SegmentEvents.ORDER_COMPLETED, properties);
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: order.oloid,
              affiliation:
                localStorage.getItem('punchhMobile') === 'true'
                  ? 'Mobile App'
                  : 'Online Ordering',
              revenue: basket.subtotal,
              tax: basket.salestax,
              shipping: basket.customerhandoffcharge,
              coupon: basket.coupon ? basket.coupon.couponcode : '',
            },
            products: basket.products.map((prod) =>
              this.oloBasketProductToGTMProduct(
                prod,
                menu.find((cat) =>
                  cat.products.find((product) => prod.productId === product.id),
                )!,
                false,
              ),
            ),
          },
        },
      });
    } else {
      this.pushTag({
        event: 'purchase',
        ecommerce: {
          transaction_id: order.oloid,
          affiliation:
            localStorage.getItem('punchhMobile') === 'true'
              ? 'Mobile App'
              : 'Online Ordering',
          value: basket.subtotal,
          tax: basket.salestax,
          shipping: basket.customerhandoffcharge,
          coupon: basket.coupon ? basket.coupon.couponcode.toUpperCase() : '',
          currency: 'USD',
          items: basket.products.map((prod) =>
            this.oloRemoveBasketProductToGTMItem(prod),
          ),
          location: order.vendorname,
          location_code: order.vendorextref,
          order_type: basket.deliverymode,
        },
      });
    }
    this.epsilon.sendConversionTag(
      EpsilonPromoTypes.TRANSACTION,
      basket,
      user ? user.email : email,
      user ? String(user.user_id) : undefined,
      order.vendorextref,
      window.location.href,
    );
    // this.hotjar.hj('event', 'purchase');
  }

  logSiteAbandoned(): void {
    this.pushTag({
      event: this.useUA ? 'siteAbandoned' : 'site_abandoned',
      currentURL: this.currentURL,
    });
  }

  private resetEcommerce(): void {
    this.pushTag({ ecommerce: null });
  }

  // GOOGLE ANALYTICS 4

  private oloCategoryToGTMItem(category: Category, index: number): GTMItem {
    return {
      item_name: category?.name,
      item_id: String(category?.id),
      index: index,
      quantity: 1,
    } as GTMItem;
  }

  private oloMenuProductToGTMItem(
    product: Product,
    category: Category,
  ): GTMItem {
    return {
      item_name: product.name,
      item_id: String(product.id),
      item_category: category.name,
      item_variant: product.name,
      quantity: 1,
    } as GTMItem;
  }

  private oloMenuProductToGTMItemWithPrice(
    product: Product,
    category: Category,
    price: number,
  ): GTMItem {
    return {
      item_name: product.name,
      item_id: String(product.id),
      item_category: category.name,
      item_variant: product.name,
      price,
      quantity: 1,
    } as GTMItem;
  }

  private oloMenuOptionToGTMItem(option: Ingredient): GTMItem {
    return {
      item_name: option.name,
      item_id: String(option.chainoptionid),
    } as GTMItem;
  }

  private oloBasketProductToGTMItem(product: BasketProduct): GTMItem {
    return {
      item_name: product.name,
      item_id: String(product.productId),
      item_variant: product.name,
      price: product.totalcost,
      quantity: product.quantity,
    } as GTMItem;
  }

  private oloRemoveBasketProductToGTMItem(product: BasketProduct): GTMItem {
    return {
      item_name: product.name,
      item_id: String(product.productId),
      item_variant: product.choices?.length ? product.choices[0].name : '',
      price: product.totalcost,
      quantity: product.quantity,
      currency: 'USD',
      item_modifiers: product.choices
        ? product.choices.map((opt) => opt.name)
        : [],
    } as GTMItem;
  }

  private oloRewardToGTMItem(reward: AvailableReward, index: number): GTMItem {
    return {
      item_name: reward.label,
      item_id: reward.reference,
      promotion_name: reward.label,
      promotion_id: reward.externalreference,
      creative_name: reward.label,
      creative_slot: String(index),
      location_id: 'checkout',
      index: index,
      quantity: reward.quantityavailable,
    } as GTMItem;
  }

  // UNIVERSAL ANALYTICS

  private oloCategoryToGTMImpression(
    category: Category,
    index: number,
  ): GTMImpression {
    return {
      id: String(category.id),
      name: category.name,
    } as GTMImpression;
  }

  private oloMenuProductToGTMProduct(
    product: Product,
    category: Category,
    useCategory: boolean,
  ): GTMProduct {
    return {
      id: String(category.id),
      name: `${category.name} - ${product.name}`,
      variant: product.name,
      position: category.products.indexOf(product),
    } as GTMProduct;
  }

  private oloMenuProductToGTMProductWithPrice(
    product: Product,
    category: Category,
    price: number,
    useCategory: boolean,
  ): GTMProduct {
    return {
      id: String(product.id),
      name: `${category.name} - ${product.name}`,
      variant: product.name,
      price,
      position: category.products.indexOf(product),
    } as GTMProduct;
  }

  private oloBasketProductToGTMProduct(
    product: BasketProduct,
    category: Category,
    useCategory: boolean,
  ): GTMProduct {
    return {
      id: String(product.productId),
      name: category ? `${category.name} - ${product.name}` : product.name,
      variant: product.name,
      price: product.totalcost,
      quantity: product.quantity,
    } as GTMProduct;
  }

  private oloRemoveBasketProductToGTMProduct(
    product: BasketProduct,
  ): GTMProduct {
    return {
      id: String(product.productId),
      name: product.name,
      variant: product.choices[0].name ? product.choices[0].name : '',
      price: product.totalcost,
      quantity: product.quantity,
    } as GTMProduct;
  }

  private oloRewardToGTMPromotion(
    reward: AvailableReward,
    index: number,
  ): GTMPromotion {
    return {
      id: String(reward.externalreference),
      name: reward.label,
      position: 'checkout_'.concat(String(index)),
    } as GTMPromotion;
  }

  private useCategory(category: Category): boolean {
    return (
      category.name.toUpperCase() !== 'Sides, Drinks and Desserts'.toUpperCase()
    );
  }

  // SEGMENT

  private oloBasketProductToSegmentProduct(
    product: BasketProduct,
    category: Category,
  ): SegmentProduct {
    return {
      brand: 'Freebirds',
      product_category: 'Food & Drink',
      product_id: String(product.productId),
      product_name: `${category.name} - ${product.name}`,
      product_subcategory: product.choices[0]
        ? product.choices[0]?.name
        : undefined,
      product_unit_value: product.totalcost,
      quantity: product.quantity,
    };
  }

  private punchhGenderToSegmentGender(gender: string): string | undefined {
    switch (gender) {
      case 'Male':
        return 'm';
      case 'Female':
        return 'f';
      default:
        return undefined;
    }
  }

  private pushTag(tag: object) {
    this.dataLayer.push(tag);
  }
}
