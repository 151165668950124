import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalStateModel, SavedAddress } from '@models/index';
import { Store } from '@ngxs/store';
import { HandoffMode } from '@server/vendor/olo/interfaces';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { FindDeliveryLocation } from '@store/actions/locations.actions';
import { SetMenu } from '@store/actions/menu.actions';
import { SetDeliveryAddress, StartOrder } from '@store/actions/order.actions';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { first, from } from 'rxjs';

@Component({
  selector: 'app-delivery-address-confirm-modal',
  templateUrl: './delivery-address-confirm-modal.component.html',
  styleUrls: ['delivery-address-confirm-modal.component.scss'],
})
export class DeliveryAddressConfirmModalComponent implements OnInit {
  @Input() address!: SavedAddress;
  @Input() isCheckout = false;

  madeChanges: boolean = false;

  addressForm = new FormGroup({
    streetAddress: new FormControl(null, [Validators.required]),
    building: new FormControl(null, []),
    city: new FormControl(null, [Validators.required]),
    zipcode: new FormControl(null, [Validators.required]),
    specialInstructions: new FormControl(null, [Validators.maxLength(128)]),
  });

  confirmLoading = false;

  constructor(
    public bsModalRef: BsModalRef,
    private store: Store,
    private router: Router,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
  ) {}

  ngOnInit() {
    this.addressForm.valueChanges.subscribe(() => (this.madeChanges = true));
    this.addressForm.disable();
    this.addressForm.setValue({
      streetAddress: this.address.streetaddress,
      building: this.address.building,
      city: this.address.city,
      zipcode: this.address.zipcode,
      specialInstructions: this.address.specialinstructions,
    });
  }

  editAddress() {
    this.addressForm.enable();
  }

  confirmAddress() {
    this.confirmLoading = true;
    this.store
      .dispatch(
        new FindDeliveryLocation(
          HandoffMode.DISPATCH,
          `${this.streetAddress.value}, ${this.city.value} ${this.zipcode.value}`,
        ),
      )
      .subscribe(
        () => {
          this.store
            .selectOnce(
              (state: GlobalStateModel) =>
                state.locations.closestDeliveryLocation,
            )
            .subscribe((closestLocation) => {
              this.store
                .dispatch(new StartOrder(closestLocation!.id))
                .subscribe(() => {
                  this.store
                    .selectOnce((state: GlobalStateModel) => state.order.order)
                    .subscribe((order) => {
                      if (
                        !this.addressForm.touched &&
                        !this.addressForm.dirty &&
                        this.address.id
                      ) {
                        this.store
                          .dispatch(
                            new SetDeliveryAddress(
                              order!.id,
                              HandoffMode.DISPATCH,
                              this.address.id,
                            ),
                          )
                          .subscribe(() => {
                            this.store
                              .dispatch(
                                new SetMenu(
                                  order!.vendorid,
                                  HandoffMode.DISPATCH,
                                ),
                              )
                              .subscribe(() => {
                                this.analytics.logAddShippingInfo(
                                  order!,
                                  closestLocation!,
                                );
                                from(
                                  this.router.navigate([''], {
                                    fragment: 'menu',
                                  }),
                                ).subscribe(() => {
                                  this.bsModalRef.hide();
                                });
                              });
                          });
                      } else {
                        this.store
                          .dispatch(
                            new SetDeliveryAddress(
                              order!.id,
                              HandoffMode.DISPATCH,
                              undefined,
                              this.building.value,
                              this.streetAddress.value,
                              this.city.value,
                              this.zipcode.value,
                              this.specialInstructions.value,
                            ),
                          )
                          .subscribe(() => {
                            this.store
                              .dispatch(
                                new SetMenu(
                                  order!.vendorid,
                                  HandoffMode.DISPATCH,
                                ),
                              )
                              .subscribe(() => {
                                if (this.isCheckout) {
                                  this.bsModalRef.hide();
                                } else {
                                  this.analytics.logAddShippingInfo(
                                    order!,
                                    closestLocation!,
                                  );
                                  from(
                                    this.router.navigate([''], {
                                      fragment: 'menu',
                                    }),
                                  ).subscribe(() => {
                                    this.bsModalRef.hide();
                                  });
                                }
                              });
                          });
                      }
                    });
                });
            });
        },
        (error) => {
          this.confirmLoading = false;
          this.toast.error(error);
        },
      );
  }

  get streetAddress(): AbstractControl {
    return this.addressForm.get('streetAddress')!;
  }
  get building(): AbstractControl {
    return this.addressForm.get('building')!;
  }
  get city(): AbstractControl {
    return this.addressForm.get('city')!;
  }
  get zipcode(): AbstractControl {
    return this.addressForm.get('zipcode')!;
  }
  get specialInstructions(): AbstractControl {
    return this.addressForm.get('specialInstructions')!;
  }
}
