import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContentLocation, GlobalStateModel } from '@models/index';
import { BrochureService } from '@modules/brochure/services';
import { Select } from '@ngxs/store';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  slideInRightOnEnterAnimation,
  slideOutRightOnLeaveAnimation,
} from 'angular-animations';
import * as moment from 'moment';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['community.component.scss'],
  animations: [
    slideInRightOnEnterAnimation({ duration: 300 }),
    slideOutRightOnLeaveAnimation({ duration: 300 }),
    fadeOutOnLeaveAnimation({ duration: 300 }),
    fadeInOnEnterAnimation({ duration: 300 }),
  ],
})
export class CommunityComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.content.contentLocations)
  contentLocations$!: Observable<ContentLocation[]>;

  @ViewChild('communityTabs', { static: false })
  communityTabs?: TabsetComponent;

  isAtTop: boolean = true;

  minDate = moment().add(3, 'weeks').startOf('day').format('YYYY-MM-DDThh:mm');

  donationForm: FormGroup = new FormGroup({
    firstName: new FormControl(null, Validators.required),
    lastName: new FormControl(null, Validators.required),
    organization: new FormControl(null, Validators.required),
    taxIDNumber: new FormControl(null),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null, Validators.required),
    donationDate: new FormControl(null, Validators.required),
    requestInfo: new FormControl(null, Validators.required),
    location: new FormControl(null),
    additionalInfo: new FormControl(null),
    donationType: new FormControl(null, Validators.required),
  });

  spiritNightForm: FormGroup = new FormGroup({
    firstName: new FormControl(null, Validators.required),
    lastName: new FormControl(null, Validators.required),
    organization: new FormControl(null, Validators.required),
    taxIDNumber: new FormControl(null, Validators.required),
    address: new FormControl(null, Validators.required),
    fundraisingPurpose: new FormControl(null, Validators.required),
    attendance: new FormControl(0),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null, Validators.required),
    eventDate1: new FormControl(null, Validators.required),
    eventDate2: new FormControl(null, Validators.required),
    location: new FormControl(null, Validators.required),
    additionalInfo: new FormControl(null, Validators.required),
  });

  donationSubmitting = false;
  spiritNightSubmitting = false;

  constructor(
    private elementRef: ElementRef,
    private brochure: BrochureService,
    private toastr: ToastrService,
  ) {}
  ngOnInit() {
    this.donationForm.valueChanges.subscribe((value) => {
      console.log(value);
      if (value.donationType === 'catering') {
        this.donationForm.controls['location'].setValidators([
          Validators.required,
        ]);
      } else {
        this.donationForm.controls['location'].clearValidators();
      }
      this.donationForm.controls['location'].updateValueAndValidity({
        emitEvent: false,
      });
    });
  }

  selectTab(tabId: number) {
    if (this.communityTabs?.tabs[tabId]) {
      this.communityTabs.tabs[tabId].active = true;
    }
    const element =
      this.elementRef.nativeElement.querySelector('#communityTabs');
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  submitDonationForm() {
    this.donationForm.markAllAsTouched();
    if (this.donationForm.invalid) {
      return;
    }
    console.log(this.donationForm.value);
    this.brochure
      .sendContactForm('donation', this.donationForm.value)
      .subscribe({
        next: () => {
          this.donationSubmitting = false;
          this.donationForm.reset();
          this.toastr.success('Your request has been submitted!', 'Success');
        },
        error: () => {
          this.donationSubmitting = false;
          this.toastr.error(
            'There was an error submitting your request. Please try again later',
            'Error',
          );
        },
      });
  }

  submitSpiritNightForm() {
    this.spiritNightForm.markAllAsTouched();
    if (this.spiritNightForm.invalid) {
      return;
    }
    this.spiritNightSubmitting = true;
    console.log(this.spiritNightForm.value);
    this.brochure
      .sendContactForm('spirit-night', this.spiritNightForm.value)
      .subscribe({
        next: () => {
          this.spiritNightSubmitting = false;
          this.spiritNightForm.reset();
          this.toastr.success('Your request has been submitted!', 'Success');
        },
        error: () => {
          this.spiritNightSubmitting = false;
          this.toastr.error(
            'There was an error submitting your request. Please try again later',
            'Error',
          );
        },
      });
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    this.isAtTop = window.pageYOffset === 0;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  get donationFirstName() {
    return this.donationForm.get('firstName');
  }

  get donationLastName() {
    return this.donationForm.get('lastName');
  }

  get donationOrganization() {
    return this.donationForm.get('organization');
  }

  get donationTaxIDNumber() {
    return this.donationForm.get('taxIDNumber');
  }

  get donationEmail() {
    return this.donationForm.get('email');
  }

  get donationPhone() {
    return this.donationForm.get('phone');
  }

  get donationDate() {
    return this.donationForm.get('donationDate');
  }

  get donationRequestInfo() {
    return this.donationForm.get('requestInfo');
  }

  get donationLocation() {
    return this.donationForm.get('location');
  }

  get donationAdditionalInfo() {
    return this.donationForm.get('additionalInfo');
  }
  get donationType() {
    return this.donationForm.get('donationType');
  }

  get spiritNightFirstName() {
    return this.spiritNightForm.get('firstName');
  }

  get spiritNightLastName() {
    return this.spiritNightForm.get('lastName');
  }

  get spiritNightOrganization() {
    return this.spiritNightForm.get('organization');
  }

  get spiritNightTaxIDNumber() {
    return this.spiritNightForm.get('taxIDNumber');
  }

  get spiritNightEmail() {
    return this.spiritNightForm.get('email');
  }

  get spiritNightPhone() {
    return this.spiritNightForm.get('phone');
  }

  get spiritNightEventDate1() {
    return this.spiritNightForm.get('eventDate1');
  }

  get spiritNightEventDate2() {
    return this.spiritNightForm.get('eventDate2');
  }

  get spiritNightLocation() {
    return this.spiritNightForm.get('location');
  }

  get spiritNightAdditionalInfo() {
    return this.spiritNightForm.get('additionalInfo');
  }
}
