<section class="highlight-section" [ngClass]="section.highlight_theme!">
  <div
    class="container d-flex g-0 overflow-hidden flex-lg-row flex-column justify-content-center position-relative py-4 py-md-5 gap-4">
    <div
      class="col-12 col-lg-6 d-flex flex-column position-static p-0 p-lg-3 order-2"
      [class.order-lg-1]="section.highlight_orientation === 'left'">
      <ng-container *ngIf="section.highlight_subheading">
        <h5 class="d-inline-block mb-2 text-primary">
          {{ section.highlight_subheading! }}
        </h5>
      </ng-container>
      <ng-container *ngIf="section.highlight_title">
        <h2 class="display-5">
          {{ section.highlight_title! }}
        </h2>
      </ng-container>
      <div>
        <ng-container *ngIf="section.highlight_description_text_1"
          ><p class="card-text">
            {{ section.highlight_description_text_1! }}
          </p></ng-container
        >

        <ng-container *ngIf="section.highlight_description_text_2"
          ><p>
            {{ section.highlight_description_text_2! }}
          </p></ng-container
        >
      </div>

      <ng-container
        *ngIf="
          section.highlight_cta_text &&
          section.highlight_cta_link &&
          section.highlight_theme
        ">
        <button
          (click)="linkClick(section!.highlight_cta_link!)"
          type="button"
          class="btn w-fit-content mt-3"
          [class.btn-style]="section.highlight_theme!">
          {{ section.highlight_cta_text! }}
        </button>
      </ng-container>
    </div>
    <div
      class="col-12 col-lg-6 rounded overflow-hidden p-0 p-lg-3"
      [class.order-1]="section.highlight_orientation === 'left'"
      [class.order-lg-2]="section.highlight_orientation === 'left'">
      <ng-container *ngIf="section.highlight_title"
        ><img
          class="highlight-image rounded"
          [src]="section?.highlight_image! | cmsAsset"
          alt="{{ section.highlight_title! }}"
      /></ng-container>
    </div>
  </div>
</section>
