<app-navbar></app-navbar>

<section class="nav-page-height d-flex">
  <div class="container flex-column grow-1 py-3">
    <div class="d-flex flex-nowrap gap-3 h-100">
      <div class="grow-1 basis-0 d-none d-md-block">
        <div class="profile-sidebar h-100">
          <div class="card text-white bg-dark h-100">
            <div
              class="card-header d-flex flex-row flex-nowrap align-items-center text-light gap-3">
              <img
                class="user-profile-icon"
                src="/assets/images/profile-pics/profile-sheriff.svg"
                alt="guest account button" />
              <ng-container *ngIf="user$ | async as user"
                ><h4 class="">
                  {{ user.first_name }} {{ user.last_name }}
                </h4></ng-container
              >
            </div>
            <div class="card-body d-flex flex-column gap-3">
              <button
                type="button"
                class="btn btn-link text-light text-left profile-link"
                routerLink="/profile"
                fragment="orderHistory">
                <h5>Order History</h5>
              </button>
              <button
                type="button"
                class="btn btn-link text-light text-left profile-link"
                routerLink="/profile"
                fragment="paymentMethods">
                <h5>Payment Methods</h5>
              </button>
              <button
                type="button"
                class="btn btn-link text-light text-left profile-link"
                routerLink="/profile"
                fragment="faveLocations">
                <h5>Favorite Locations</h5>
              </button>
              <ng-container *ngIf="isFullExperience$ | async">
                <button
                  type="button"
                  class="btn btn-link text-light text-left profile-link"
                  routerLink="/profile"
                  fragment="inbox">
                  <h5>Inbox</h5>
                </button>
                <button
                  type="button"
                  class="btn btn-link text-light text-left profile-link"
                  routerLink="/profile"
                  fragment="accountInfo">
                  <h5>Account Info</h5>
                </button>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="grow-2 basis-0">
        <app-profile-dashboard-cards
          [section]="selectedSection"
          [user]="(user$ | async)!"></app-profile-dashboard-cards>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>

<button
  *ngIf="!isAtTop"
  (click)="scrollToTop()"
  class="scroll-to-top-button"
  [@slideInRightOnEnter]
  [@slideOutRightOnLeave]
  [@fadeOutOnLeave]
  [@fadeInOnEnter]>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-arrow-up"
    viewBox="0 0 16 16">
    <path
      fill-rule="evenodd"
      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
  </svg>
</button>
