<div class="queso-container py-5">
  <div class="foreground">
    <div class="container">
      <div class="queso-img-container d-none d-md-flex">
        <img
          alt="Queso"
          animateOnScroll
          animationName="animate__animated animate__fadeIn animate__slow"
          class="queso-img hide-on-init"
          src="/assets/images/our-story/queso-min.png" />
      </div>
    </div>
  </div>

  <div class="midground">
    <div class="container d-flex flex-column gap-4">
      <img
        animateOnScroll
        animationName="animate__animated animate__fadeIn animate__slow"
        alt="Customizable, Craveable, & Queso"
        class="queso-title hide-on-init"
        src="/assets/images/our-story/crave-queso-title.svg" />
      <div class="hero-body os-body-copy">
        <span class="title">
          Keto, Paleo, Low-Carb, or vegan, we’ve got you covered.
        </span>
        <span>
          Oh, and you can cover everything in our Legendary Queso Blanco if you
          want, too.
        </span>
      </div>
    </div>
    <div class="ingredient-marquee d-flex flex-column gap-3">
      <div class="ingredient-marquee-wrapper d-flex flex-row gap-3">
        <ng-container *ngTemplateOutlet="ingredientTop"></ng-container>
        <ng-container *ngTemplateOutlet="ingredientTop"></ng-container>
        <ng-container *ngTemplateOutlet="ingredientTop"></ng-container>
      </div>
      <div class="ingredient-marquee-wrapper d-flex flex-row gap-3">
        <ng-container *ngTemplateOutlet="ingredientBottom"></ng-container>
        <ng-container *ngTemplateOutlet="ingredientBottom"></ng-container>
        <ng-container *ngTemplateOutlet="ingredientBottom"></ng-container>
      </div>
    </div>
  </div>

  <ng-template #ingredientTop>
    <div class="ingredients-top d-flex gap-3">
      <img
        alt="Black Beans"
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/black-beans.jpg" />
      <img
        alt="Cilantro Lime Rice"
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/cilantro-lime-rice.jpg" />
      <img
        alt="Corn Salsa"
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/corn-salsa.jpg" />
      <img
        alt="Guacamole"
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/guac.jpg" />
      <img
        alt="Pinto Beans"
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/pinto-beans.jpg" />
      <img
        alt="Queso"
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/queso.jpg" />
      <img
        alt="Refried Beans"
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/refried-beans.jpg" />
      <img
        alt="Salsa"
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/salsa.jpg" />
      <img
        alt="Spanish Rice"
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/spanish-rice.jpg" />
      <img
        alt="Tortilla Strips"
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/tortilla-strips.jpg" />
    </div>
  </ng-template>

  <ng-template #ingredientBottom>
    <div class="ingredients-middle d-flex gap-3">
      <img
        alt="Tortilla Strips"
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/tortilla-strips.jpg" />
      <img
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/spanish-rice.jpg" />
      <img
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/salsa.jpg" />

      <img
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/refried-beans.jpg" />
      <img
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/black-beans.jpg" />
      <img
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/cilantro-lime-rice.jpg" />
      <img
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/corn-salsa.jpg" />
      <img
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/guac.jpg" />
      <img
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/pinto-beans.jpg" />
      <img
        class="ingredient-img"
        src="/assets/images/our-story/ingredient-images/queso.jpg" />
    </div>
  </ng-template>
</div>
