<section [ngClass]="pageBlock.paragraph_theme!">
  <div class="container py-4 py-md-5">
    <p
      [class.text-center]="pageBlock.paragraph_orientation === 'center'"
      [class.text-left]="pageBlock.paragraph_orientation === 'left'"
      [class.text-right]="pageBlock.paragraph_orientation === 'right'">
      {{ pageBlock.paragraph_content }}
    </p>
  </div>
</section>
