export class NutritionInfo {
  private _calories: number = 0;
  private _caloriesFromFat: number = 0;
  private _totalFat: number = 0;
  private _saturatedFat: number = 0;
  private _transFat: number = 0;
  private _polyunsaturatedFat: number = 0;
  private _monounsaturatedFat: number = 0;
  private _cholesterol: number = 0;
  private _sodium: number = 0;
  private _potassium: number = 0;
  private _carbohydrates: number = 0;
  private _dietaryFiber: number = 0;
  private _sugars: number = 0;
  private _protein: number = 0;

  increase(
    value:
      | 'calories'
      | 'caloriesFromFat'
      | 'totalFat'
      | 'saturatedFat'
      | 'transFat'
      | 'polyunsaturatedFat'
      | 'monounsaturatedFat'
      | 'cholesterol'
      | 'sodium'
      | 'potassium'
      | 'carbohydrates'
      | 'dietaryFiber'
      | 'sugars'
      | 'protein',
    amount: number,
  ) {
    switch (value) {
      case 'calories':
        this._calories += amount;
        break;
      case 'caloriesFromFat':
        this._caloriesFromFat += amount;
        break;
      case 'totalFat':
        this._totalFat += amount;
        break;
      case 'saturatedFat':
        this._saturatedFat += amount;
        break;
      case 'transFat':
        this._transFat += amount;
        break;
      case 'polyunsaturatedFat':
        this._polyunsaturatedFat += amount;
        break;
      case 'monounsaturatedFat':
        this._monounsaturatedFat += amount;
        break;
      case 'cholesterol':
        this._cholesterol += amount;
        break;
      case 'sodium':
        this._sodium += amount;
        break;
      case 'potassium':
        this._potassium += amount;
        break;
      case 'carbohydrates':
        this._carbohydrates += amount;
        break;
      case 'dietaryFiber':
        this._dietaryFiber += amount;
        break;
      case 'sugars':
        this._sugars += amount;
        break;
      case 'protein':
        this._protein += amount;
        break;
    }
  }

  decrease(
    value:
      | 'calories'
      | 'caloriesFromFat'
      | 'totalFat'
      | 'saturatedFat'
      | 'transFat'
      | 'polyunsaturatedFat'
      | 'monounsaturatedFat'
      | 'cholesterol'
      | 'sodium'
      | 'potassium'
      | 'carbohydrates'
      | 'dietaryFiber'
      | 'sugars'
      | 'protein',
    amount: number,
  ) {
    switch (value) {
      case 'calories':
        this._calories -= amount;
        break;
      case 'caloriesFromFat':
        this._caloriesFromFat -= amount;
        break;
      case 'totalFat':
        this._totalFat -= amount;
        break;
      case 'saturatedFat':
        this._saturatedFat -= amount;
        break;
      case 'transFat':
        this._transFat -= amount;
        break;
      case 'polyunsaturatedFat':
        this._polyunsaturatedFat -= amount;
        break;
      case 'monounsaturatedFat':
        this._monounsaturatedFat -= amount;
        break;
      case 'cholesterol':
        this._cholesterol -= amount;
        break;
      case 'sodium':
        this._sodium -= amount;
        break;
      case 'potassium':
        this._potassium -= amount;
        break;
      case 'carbohydrates':
        this._carbohydrates -= amount;
        break;
      case 'dietaryFiber':
        this._dietaryFiber -= amount;
        break;
      case 'sugars':
        this._sugars -= amount;
        break;
      case 'protein':
        this._protein -= amount;
        break;
    }
  }

  get calories() {
    return this._calories;
  }

  get caloriesFromFat() {
    return this._caloriesFromFat;
  }
  get totalFat() {
    return this._totalFat;
  }
  get saturatedFat() {
    return this._saturatedFat;
  }
  get transFat() {
    return this._transFat;
  }
  get polyunsaturatedFat() {
    return this._polyunsaturatedFat;
  }
  get monounsaturatedFat() {
    return this._monounsaturatedFat;
  }
  get cholesterol() {
    return this._cholesterol;
  }
  get sodium() {
    return this._sodium;
  }
  get potassium() {
    return this._potassium;
  }
  get carbohydrates() {
    return this._carbohydrates;
  }
  get dietaryFiber() {
    return this._dietaryFiber;
  }
  get sugars() {
    return this._sugars;
  }
  get protein() {
    return this._protein;
  }
}
