import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { CancelGroupOrder, ClearOrder } from '@store/actions/order.actions';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Component({
  selector: 'app-existing-group-order-modal',
  templateUrl: './existing-group-order-modal.component.html',
  styleUrls: ['existing-group-order-modal.component.scss'],
})
export class ExistingGroupOrderModalComponent implements OnInit {
  cancelLoading = false;
  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private store: Store,
  ) {}
  ngOnInit() {}

  cancelOrder() {
    this.cancelLoading = true;
    this.store.dispatch(new CancelGroupOrder()).subscribe(() => {
      this.store.dispatch(new ClearOrder()).subscribe(() => {
        this.bsModalRef.hide();
      });
    });
  }

  closeClick() {
    this.router.navigate(['/']).then(() => this.bsModalRef.hide());
  }
}
