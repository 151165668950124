import { Injectable } from '@angular/core';
import { Ingredient } from '@models/index';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable()
export class ProductCustomizationService {
  private _currentlySelected: Ingredient[] = [];
  currentlySelectedSubject = new BehaviorSubject<Ingredient[]>([]);
  currentlySelected$: Observable<Ingredient[]> =
    this.currentlySelectedSubject.asObservable();
  constructor() {}

  get currentlySelected() {
    return this._currentlySelected;
  }

  clearSelections() {
    this._currentlySelected = [];
    this.updateSubject();
  }
  addToSelectedIngredients(ingredient: Ingredient) {
    this._currentlySelected.push(ingredient);
    this.updateSubject();
  }

  setCurrentlySelectedIngredients(ingredients: Ingredient[]) {
    this._currentlySelected = ingredients;
    this.updateSubject();
  }

  removeFromSelectedIngredients(ingredient: Ingredient) {
    this._currentlySelected = this._currentlySelected.filter(
      (item) => item.chainoptionid !== ingredient.chainoptionid,
    );
    this.updateSubject();
  }

  private updateSubject() {
    this.currentlySelectedSubject.next(this._currentlySelected);
  }
}
