import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GetFeaturedItemsResponse,
  GetMenuResponse,
  GetProductResponse,
  GetUpsellResponse,
} from '@server/menu/responses';
import { HandoffMode } from '@server/vendor/olo/interfaces';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private menuAPIPrefix = environment.apiBaseURL + '/api/menu';

  constructor(private http: HttpClient) {}

  getMenu(
    locationID: number,
    handoffMode?: HandoffMode,
  ): Observable<GetMenuResponse> {
    const resource = `${this.menuAPIPrefix}/${locationID}`;
    let queryParams = new HttpParams();
    if (handoffMode) {
      queryParams = new HttpParams({
        fromObject: {
          handoffMode,
        },
      });
    }
    return this.http.get<GetMenuResponse>(resource, { params: queryParams });
  }

  getProduct(
    locationID: number,
    chainProductID: number,
    withModifiers: boolean = true,
  ): Observable<GetProductResponse> {
    const resource = `${this.menuAPIPrefix}/${locationID}/product/${chainProductID}`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        withModifiers,
      },
    });
    return this.http.get<GetProductResponse>(resource, { params });
  }

  getProductByMenuID(
    locationID: number,
    menuProductID: number,
    withModifiers: boolean = true,
  ) {
    const resource = `${this.menuAPIPrefix}/${locationID}/product/byID/${menuProductID}`;
    console.log(resource);
    const params: HttpParams = new HttpParams({
      fromObject: {
        withModifiers,
      },
    });
    return this.http.get<GetProductResponse>(resource, { params });
  }

  getUpsells(
    locationID: number,
    handoffMode?: HandoffMode,
  ): Observable<GetUpsellResponse> {
    const resource = `${this.menuAPIPrefix}/${locationID}/upsells`;
    let queryParams = new HttpParams();
    if (handoffMode) {
      queryParams = new HttpParams({
        fromObject: {
          handoffMode,
        },
      });
    }
    return this.http.get<GetUpsellResponse>(resource, { params: queryParams });
  }

  getFeaturedItems(): Observable<GetFeaturedItemsResponse> {
    const resource = `${this.menuAPIPrefix}/featured`;
    return this.http.get<GetFeaturedItemsResponse>(resource);
  }
}
