import { HttpParams } from '@angular/common/http';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Order, OrderProduct, PastOrder } from '@models/index';
import { EpsilonActionConversionTag } from '@services/analytics/models/epsilon.action-conversion-tag';
import {
  EpsilonConversionTag,
  EpsilonConversionTagProduct,
} from '@services/analytics/models/epsilon.conversion-tag';
import { EpsilonPageVisit } from '@services/analytics/models/epsilon.page-visit';
import { EpsilonPromoTypes } from '@services/analytics/models/epsilon.promo-types';
import CryptoES from 'crypto-es';

@Injectable({
  providedIn: 'root',
})
export class EpsilonService {
  private renderer: Renderer2;
  private baseURL = 'https://www.freebirds.com/tag_path/profile/visit/js/1_0';

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  sendPageVisit(
    page: EpsilonPromoTypes,
    email?: string,
    userId?: string,
    productId?: string,
    url?: string,
  ) {
    if (!!document.getElementById('epsilon_page_visit')) {
      document.getElementById('epsilon_page_visit')!.remove();
    }
    const emailHash = email
      ? CryptoES.SHA256(email.trim().toLowerCase()).toString(CryptoES.enc.Hex)
      : undefined;
    const body: EpsilonPageVisit = {
      dtm_fid: 5552,
      dtm_promo_id: page,
      dtm_email_hash: emailHash,
      dtm_user_id: userId,
      dtmc_product_id: productId,
      dtmc_loc: url,
      cachebuster: Date.now(),
    };

    for (let key in body) {
      // @ts-ignore
      if (body[key] === undefined) {
        // @ts-ignore
        delete body[key];
      }
    }

    const queryParams: HttpParams = new HttpParams({
      fromObject: {
        dtm_cid: 82907,
        dtm_cmagic: '07ae77',
        ...body,
      },
    });
    const epsilonPageVisitNode = document.createElement('script');
    epsilonPageVisitNode.src = [this.baseURL, queryParams.toString()].join('?');
    epsilonPageVisitNode.id = 'epsilon_page_visit';
    document.body.appendChild(epsilonPageVisitNode);
  }

  sendConversionTag(
    page: EpsilonPromoTypes,
    order: Order,
    email?: string,
    userId?: string,
    storeLocation?: string,
    url?: string,
  ) {
    if (!!document.getElementById('epsilon_conversion_tag')) {
      document.getElementById('epsilon_conversion_tag')!.remove();
    }
    if (!!document.getElementById('dtm_config')) {
      document.getElementById('dtm_config')!.remove();
    }
    const emailHash = email
      ? CryptoES.SHA256(email.trim().toLowerCase()).toString(CryptoES.enc.Hex)
      : undefined;
    const body: EpsilonConversionTag = {
      dtm_fid: 5553,
      dtm_promo_id: page,
      dtm_email_hash: emailHash,
      dtm_user_id: userId,
      dtm_conv_val: order.subtotal,
      dtm_conv_curr: 'USD',
      dtmc_conv_type: order.deliverymode,
      dtmc_conv_store_location: storeLocation,
      dtmc_loc: url,
      dtmc_transaction_id: order.id,
      cachebuster: Date.now(),
    };

    for (let key in body) {
      // @ts-ignore
      if (body[key] === undefined) {
        // @ts-ignore
        delete body[key];
      }
    }

    const queryParams: HttpParams = new HttpParams({
      fromObject: {
        dtm_cid: 82907,
        dtm_cmagic: '07ae77',
        ...body,
      },
    });
    const dtmConfigNode = document.createElement('script');
    dtmConfigNode.type = 'text/javascript';
    dtmConfigNode.id = 'dtm_config';
    const scriptCode = this.renderer.createText(`
      var dtm_config = {
        dtm_items: ${JSON.stringify(
          order.products.map((product) =>
            this.orderProductToEpsilonConversionTagProduct(product),
          ),
        )},
      };
    `);
    this.renderer.appendChild(dtmConfigNode, scriptCode);
    document.body.appendChild(dtmConfigNode);
    const epsilonConversionTagNode = document.createElement('script');
    epsilonConversionTagNode.src = [this.baseURL, queryParams.toString()].join(
      '?',
    );
    epsilonConversionTagNode.id = 'epsilon_conversion_tag';
    document.body.appendChild(epsilonConversionTagNode);
  }

  sendActionConversionTag(
    page: EpsilonPromoTypes,
    order: PastOrder,
    email?: string,
    userId?: string,
    url?: string,
  ) {
    if (!!document.getElementById('epsilon_action_conversion_tag')) {
      document.getElementById('epsilon_action_conversion_tag')!.remove();
    }
    const emailHash = email
      ? CryptoES.SHA256(email.trim().toLowerCase()).toString(CryptoES.enc.Hex)
      : undefined;
    const body: EpsilonActionConversionTag = {
      dtm_fid: 5554,
      dtm_promo_id: page,
      dtm_email_hash: emailHash,
      dtm_user_id: userId,
      dtmc_transaction_id: order.id,
      dtmc_loc: url,
      cachebuster: Date.now(),
    };

    for (let key in body) {
      // @ts-ignore
      if (body[key] === undefined) {
        // @ts-ignore
        delete body[key];
      }
    }

    const queryParams: HttpParams = new HttpParams({
      fromObject: {
        dtm_cid: 82907,
        dtm_cmagic: '07ae77',
        ...body,
      },
    });
    const epsilonActionConversionTagNode = document.createElement('script');
    epsilonActionConversionTagNode.src = [
      this.baseURL,
      queryParams.toString(),
    ].join('?');
    epsilonActionConversionTagNode.id = 'epsilon_action_conversion_tag';
    document.body.appendChild(epsilonActionConversionTagNode);
  }

  private orderProductToEpsilonConversionTagProduct(
    product: OrderProduct,
  ): EpsilonConversionTagProduct {
    return {
      product_id: this.slug(product.name),
      item_amount: (product.totalcost / product.quantity).toString(),
      item_quantity: product.quantity.toString(),
    };
  }

  slug(name: string) {
    return name
      .toLowerCase()
      .trim()
      .replace(/[^a-z \-]/g, '')
      .replace(/ +/g, '-');
  }
}
