<ng-container [ngSwitch]="rewardCardStyle">
  <ng-container *ngSwitchCase="'small'">
    <div
      *ngIf="!isSelected; else rewardCardSelected"
      class="reward-card-shell text-nowrap"
      (click)="isSelected = !isSelected">
      <img
        class="perks-icon"
        src="assets/images/icons/Icons/fb-perks-solid.svg"
        alt="" />
      <div class="reward-title">Treat! (Cookie or Brownie) - 30 pts</div>
      <button type="button" class="add-button red"></button>
    </div>
    <ng-template #rewardCardSelected>
      <div
        class="reward-card-shell-selected text-nowrap"
        (click)="isSelected = !isSelected">
        <img
          class="perks-icon"
          src="assets/images/icons/Icons/fb-perks-solid.svg"
          alt="" />
        <div class="reward-title">Treat! (Cookie or Brownie) - 30 pts</div>
        <button type="button" class="add-button-selected"></button>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="'large'">
    <div
      *ngIf="isUnlocked; else locked"
      class="reward-card-large d-flex flex-column rounded border position-relative">
      <div
        class="reward-image-container rounded-top"
        [style.background-image]="reward.redeemable_image_url | cssURL"></div>
      <div
        class="d-flex flex-column p-3 gap-3 bg-white rounded-bottom grow-1 justify-content-between">
        <div>
          <h4>{{ reward.name }}</h4>
<!--          <div class="text-muted">-->
<!--            {{ reward.points_required_to_redeem }} Points-->
<!--          </div>-->
        </div>
        <div>
          <ng-container *ngIf="viewDetails else showDescription">
            <button
              type="button"
              class="rounded info-btn p-0"
              (click)="openRewardDetailsModal()">
              <div>View Details</div>
            </button>
          </ng-container>

          <ng-template #showDescription>
            <div>{{ reward.description }}</div>
          </ng-template>
        </div>
      </div>
    </div>

    <ng-template #locked>
      <div
        class="reward-card-large d-flex flex-column rounded border position-relative">
        <div
          class="reward-image-container-locked rounded-top"
          [style.background-image]="reward.redeemable_image_url | cssURL">
          <img
            class="lock-icon p-2 m-1 bg-white"
            src="/assets/images/icons/Icons/lock.svg"
            alt="" />
        </div>
        <div class="d-flex flex-column p-3 gap-3 bg-white rounded-bottom">
          <div>
            <h4>{{ reward.name }}</h4>
<!--            <div class="text-muted">-->
<!--              {{ reward.points_required_to_redeem }} Points-->
<!--            </div>-->
          </div>
          <div>
            <ng-container *ngIf="viewDetails else showDescription">
              <button
                type="button"
                class="rounded info-btn p-0"
                (click)="openRewardDetailsModal()">
                <div>View Details</div>
              </button>
            </ng-container>

            <ng-template #showDescription>
              <div>{{ reward.description }}</div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="'horizontal'">
    <div
      *ngIf="isUnlocked; else locked"
      class="reward-card-horizontal d-flex flex-row rounded border position-relative">
      <div
        class="reward-image-container rounded-start"
        [style.background-image]="reward.redeemable_image_url | cssURL"></div>
      <div
        class="d-flex flex-column p-3 gap-3 bg-white rounded-end grow-1 justify-content-between">
        <div>
          <h6>{{ reward.name }}</h6>
        </div>
        <div>
          <ng-container *ngIf="viewDetails else showDescription">
            <button
              type="button"
              class="rounded info-btn p-0"
              (click)="openRewardDetailsModal()">
              <div>View Details</div>
            </button>
          </ng-container>

          <ng-template #showDescription>
            <div>{{ reward.description }}</div>
          </ng-template>
        </div>
      </div>
    </div>

    <ng-template #locked>
      <div
        class="reward-card-horizontal d-flex flex-row rounded border position-relative">
        <div
          class="reward-image-container-locked rounded-start"
          [style.background-image]="reward.redeemable_image_url | cssURL">
          <img
            class="lock-icon p-2 m-1 bg-white"
            src="/assets/images/icons/Icons/lock.svg"
            alt="" />
        </div>
        <div class="d-flex flex-column p-3 gap-3 bg-white rounded-end">
          <div>
            <h6>{{ reward.name }}</h6>
          </div>
          <div>
            <ng-container *ngIf="viewDetails else showDescription">
              <button
                type="button"
                class="rounded info-btn p-0"
                (click)="openRewardDetailsModal()">
                <div>View Details</div>
              </button>
            </ng-container>

            <ng-template #showDescription>
              <div>{{ reward.description }}</div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
