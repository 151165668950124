<div class="profile-content-container bg-white h-100" [ngSwitch]="section">
  <app-order-history-section
    [isEmpty]="isEmpty"
    *ngSwitchCase="'orderHistory'"></app-order-history-section>
  <app-payment-methods-section
    *ngSwitchCase="'paymentMethods'"></app-payment-methods-section>
  <app-favorite-locations-section
    *ngSwitchCase="'faveLocations'"></app-favorite-locations-section>
  <app-inbox-section *ngSwitchCase="'inbox'"></app-inbox-section>
  <app-account-info-section
    *ngSwitchCase="'accountInfo'"></app-account-info-section>
</div>
