import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalStateModel, User } from '@models/index';
import { Select, Store } from '@ngxs/store';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { SetSavedCards } from '@store/actions/user.actions';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  slideInRightOnEnterAnimation,
  slideOutRightOnLeaveAnimation,
} from 'angular-animations';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile-dashboard',
  templateUrl: './profile-dashboard.component.html',
  styleUrls: ['profile-dashboard.component.scss'],
  animations: [
    slideInRightOnEnterAnimation({ duration: 300 }),
    slideOutRightOnLeaveAnimation({ duration: 300 }),
    fadeOutOnLeaveAnimation({ duration: 300 }),
    fadeInOnEnterAnimation({ duration: 300 }),
  ],
})
export class ProfileDashboardComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.user.user)
  user$!: Observable<User>;

  @Select((state: GlobalStateModel) => state.app.isFullExperience)
  isFullExperience$!: Observable<boolean>;
  isAtTop: boolean = true;

  selectedSection:
    | 'orderHistory'
    | 'paymentMethods'
    | 'faveLocations'
    | 'accountInfo'
    | 'inbox' = 'accountInfo';

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private analytics: AnalyticsService,
  ) {}
  ngOnInit() {
    this.store.dispatch(new SetSavedCards());
    this.route.fragment.subscribe((fragment) => {
      if (
        fragment === 'orderHistory' ||
        fragment === 'paymentMethods' ||
        fragment === 'faveLocations' ||
        fragment === 'accountInfo' ||
        fragment === 'inbox'
      ) {
        this.selectedSection = fragment;
      }
    });
    this.analytics.logAccountCenterPagesPageView(
      this.store.selectSnapshot((state: GlobalStateModel) => state.user.user)!,
    );
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    this.isAtTop = window.pageYOffset === 0;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
