import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ContentLocation,
  CustomPage,
  MetaData,
  ScheduledAnnouncement,
  StaticMenu,
  TipSettings,
} from '@models/index';
import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import {
  GetFAQResponse,
  GetFAQSectionsResponse,
  GetHomepageResponse,
  GetLegalResponse,
  GetMetadataResponse,
} from '@server/content/responses';
import { ContentService } from '@services/api/content.service';
import { SetInitialLoadComplete } from '@store/actions/app.actions';
import {
  CloseAnnouncementBar,
  InitializeAnnouncementBar,
  InitializeContent,
  InitializeCustomPages,
  InitializeStaticMenu,
  InitializeTipSettings,
} from '@store/actions/content.actions';
import * as moment from 'moment';
import { forkJoin, map, switchMap } from 'rxjs';

export interface ContentStateModel {
  homepage: GetHomepageResponse | null;
  homepageLayoutIndex: number;
  faq: GetFAQSectionsResponse | null;
  legal: GetLegalResponse | null;
  metadata: MetaData[] | null;
  staticMenu: StaticMenu | null;
  contentLocations: ContentLocation[] | null;
  customPages: CustomPage[] | null;
  scheduledAnnouncement: ScheduledAnnouncement[] | null;
  tipSettings: TipSettings | null;
}

@State<ContentStateModel>({
  name: 'content',
  defaults: {
    homepage: null,
    homepageLayoutIndex: 0,
    faq: null,
    legal: null,
    metadata: null,
    staticMenu: null,
    contentLocations: null,
    customPages: null,
    scheduledAnnouncement: null,
    tipSettings: null,
  },
})
@Injectable()
export class ContentState implements NgxsOnInit {
  constructor(
    private content: ContentService,
    @Inject(PLATFORM_ID) private platformID: Object,
  ) {}

  ngxsOnInit(ctx: StateContext<ContentStateModel>) {
    if (isPlatformBrowser(this.platformID)) {
      ctx.patchState({
        homepageLayoutIndex: sessionStorage.getItem('homepageLayoutIndex')
          ? JSON.parse(sessionStorage.getItem('homepageLayoutIndex')!)
          : 0,
      });
    }
    return ctx.dispatch(new InitializeContent());
  }

  @Action(InitializeContent)
  initializeContent(
    ctx: StateContext<ContentStateModel>,
    action: InitializeContent,
  ) {
    return forkJoin({
      homepage: this.content.getHomepage(),
      faq: this.content.getFAQSections(),
      legal: this.content.getLegal(),
      metadata: this.content.getMetadata(),
      contentLocations: this.content.getContentLocations(),
    }).pipe(
      map((result) => {
        ctx.dispatch([
          new InitializeAnnouncementBar(),
          new InitializeCustomPages(),
          new InitializeTipSettings(),
        ]);

        if (isPlatformBrowser(this.platformID)) {
          if (!sessionStorage.getItem('homepageLayoutIndex')) {
            const index = this.generateRandomInteger(
              0,
              result.homepage!.modular_layouts!.length - 1,
            );
            ctx.patchState({
              homepageLayoutIndex: index,
            });
            sessionStorage.setItem(
              'homepageLayoutIndex',
              JSON.stringify(index),
            );
          }
        }
        return ctx.patchState(result);
      }),
    );
  }

  @Action(InitializeAnnouncementBar)
  initializeAnnouncementBar(
    ctx: StateContext<ContentStateModel>,
    action: InitializeAnnouncementBar,
  ) {
    return this.content.getScheduledAnnouncements().pipe(
      map((res) => {
        const messageArray: ScheduledAnnouncement[] = [];
        res.forEach((message) => {
          if (
            !(
              window.location.hostname.includes('freebirds.com') &&
              message.status !== 'published'
            )
          ) {
            const today = moment();
            // console.log(today);
            // console.log(moment(message.start_date));
            switch (message.schedule_type) {
              case 'recurring': // Recurring Message
                if (
                  message.scheduled_recurring_days.includes(
                    // @ts-ignore
                    moment().format('dddd').toLowerCase(),
                  )
                ) {
                  messageArray.push(message);
                }
                break;
              case 'single_day': // Single Day
                if (
                  moment(today).isSame(
                    moment(message.scheduled_day, 'YYYY-MM-DD'),
                    'day',
                  )
                ) {
                  messageArray.push(message);
                }
                break;
              case 'time_period': // Time Period
                if (
                  moment(today).isSameOrAfter(
                    moment(message.scheduled_start_date, 'YYYY-MM-DD'),
                  ) &&
                  moment(today).isSameOrBefore(
                    moment(message.scheduled_end_date, 'YYYY-MM-DD'),
                  )
                ) {
                  messageArray.push(message);
                }
                break;
            }
          }
        });
        ctx.dispatch(new SetInitialLoadComplete());
        if (messageArray.length > 0) {
          return ctx.patchState({
            scheduledAnnouncement: messageArray,
          });
        } else {
          return ctx.patchState({
            scheduledAnnouncement: null,
          });
        }
      }),
    );
  }

  @Action(CloseAnnouncementBar)
  closeAnnouncementBar(
    ctx: StateContext<ContentStateModel>,
    action: CloseAnnouncementBar,
  ) {
    return ctx.patchState({
      scheduledAnnouncement: null,
    });
  }

  @Action(InitializeStaticMenu)
  initializeStaticMenu(
    ctx: StateContext<ContentStateModel>,
    action: InitializeStaticMenu,
  ) {
    return this.content.getStaticMenu().pipe(
      map((res) => {
        return ctx.patchState({
          staticMenu: res,
        });
      }),
    );
  }

  @Action(InitializeCustomPages)
  initializeCustomPages(
    ctx: StateContext<ContentStateModel>,
    action: InitializeCustomPages,
  ) {
    return this.content.getCustomPages().pipe(
      map((res) => {
        return ctx.patchState({
          customPages: res,
        });
      }),
    );
  }

  @Action(InitializeTipSettings)
  initializeTipSettings(
    ctx: StateContext<ContentStateModel>,
    action: InitializeTipSettings,
  ) {
    return this.content.getTipSettings().pipe(
      map((res) => {
        return ctx.patchState({
          tipSettings: res,
        });
      }),
    );
  }

  private generateRandomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
