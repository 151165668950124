<!-- navbar -->
<app-navbar navbarOrientation="minimal"></app-navbar>

<div class="d-flex flex-nowrap">
  <section class="page-width nav-page-height-fixed d-flex flex-row flex-nowrap">
    <div class="d-flex position-relative" style="width: min(400px, 100vw)">
      <app-selected-location
        style="z-index: 2"
        [selectedLocation]="selectedLocation"
        (locationClose)="closeLocation()"></app-selected-location>
      <app-location-list
        style="position: absolute; top: 0; left: 0; z-index: 1"
        [isLoading]="isLoading"
        [mapLocations]="(mapLocations$ | async)!"
        [favoriteLocations]="(favoriteLocations$ | async)!"
        (locationSelected)="selectLocation($event)"
        [geolocationAllowed]="geolocationAllowed"
        (geolocationClick)="triggerGeolocation()"
        (searchRan)="isLoading = true"></app-location-list>
    </div>
    <div
      class="w-auto h-100 flex-grow-1 position-relative"
      [class.overflow-hidden]="!!selectedLocation">
      <mgl-map
        class="w-auto h-100 flex-grow-1"
        [style]="'mapbox://styles/freebirdswb/cl9lp2hry002216mrj1j1fmfb'"
        id="locationsMap"
        (mapLoad)="map = $event; setupGeocoder()"
        [center]="{
          lng: -97.145646,
          lat: 31.550515
        }"
        [zoom]="[4]"
        #mapComponent>
        <!--      <ng-container>-->
        <!--        <mgl-marker #userIcon *ngIf="userLocation" [lngLat]="userLocation">-->
        <!--          <img-->
        <!--            class="user-pin"-->
        <!--            src="/assets/images/location-specific_illustrations/user-pin.svg"-->
        <!--          />-->
        <!--        </mgl-marker>-->
        <!--      </ng-container>-->
        <ng-container *ngFor="let location of favoriteLocations$ | async">
          <mgl-marker
            [lngLat]="[location.longitude, location.latitude]"
            anchor="center">
            <img
              class="location-pin cursor-pointer"
              src="assets/images/icons/Icons/fb-locations-pin-open-orange.svg"
              alt="{{ location.name }}"
              (click)="selectFavoriteLocation(location)"
              *ngIf="
                location.iscurrentlyopen && location.isavailable;
                else closed
              " />
            <ng-template #closed>
              <img
                class="location-pin"
                (click)="selectFavoriteLocation(location)"
                src="assets/images/icons/Icons/fb-location-pin-closed-orange.svg"
                alt="{{ location.name }} (Closed)" />
            </ng-template>
          </mgl-marker>
        </ng-container>
        <ng-container *ngIf="mapLocations$ | async as locations">
          <ng-container
            *ngFor="let location of locations; trackBy: mapMarkerTrackBy">
            <mgl-marker
              [lngLat]="[location.longitude, location.latitude]"
              anchor="center">
              <img
                class="location-pin cursor-pointer"
                src="assets/images/icons/Icons/fb-locations-pin-open-orange.svg"
                (click)="selectLocation(location)"
                alt="{{ location.name }}"
                *ngIf="
                  location.iscurrentlyopen && location.isavailable;
                  else closed
                " />
              <ng-template #closed>
                <img
                  class="location-pin"
                  src="assets/images/icons/Icons/fb-location-pin-closed-orange.svg"
                  (click)="selectLocation(location)"
                  alt="{{ location.name }} (Closed)" />
              </ng-template>
            </mgl-marker>
          </ng-container>
        </ng-container>
      </mgl-map>
    </div>
  </section>

  <!--  <section [ngClass]="''" class="cart-closed nav-page-height-fixed">-->
  <!--    <div>asdfasdf</div>-->
  <!--  </section>-->
</div>
