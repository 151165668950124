import { Pipe, PipeTransform } from '@angular/core';
import { PointsBalance, Reward } from '@models/index';

@Pipe({
  name: 'hasRewards',
})
export class HasRewardsPipe implements PipeTransform {
  transform(rewards: Reward[], balances: PointsBalance): boolean {
    return rewards.some(
      (reward) => reward.points_required_to_redeem < balances.redeemable_points,
    );
  }
}
