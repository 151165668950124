import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Ingredient } from '@models/index';
import { OptionGroupChange } from '@modules/order/containers';

@Component({
  selector: 'app-ingredient-submodifier-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ingredient-submodifier-button.component.html',
  styleUrls: ['ingredient-submodifier-button.component.scss'],
})
export class IngredientSubmodifierButtonComponent implements AfterViewInit {
  @Input() isSelected: boolean = false;
  @Input() ingredient!: Ingredient;
  @Output() ingredientSelected = new EventEmitter<Ingredient>();
  @Output() ingredientRemoved = new EventEmitter<Ingredient>();
  constructor() {}
  ngAfterViewInit() {
    if (this.ingredient.isdefault) {
      this.ingredientSelected.emit(this.ingredient);
    }
  }

  ingredientSelect(ingredient: Ingredient, event: Event) {
    event.stopPropagation();
    this.ingredientSelected.emit(ingredient);
  }

  ingredientRemove(ingredient: Ingredient, event: Event) {
    event.stopPropagation();
    this.ingredientRemoved.emit(ingredient);
  }
}
