import { Pipe, PipeTransform } from '@angular/core';
import { Ingredient, Product } from '@models/index';
import { NutritionInfo } from '@modules/order/models';

@Pipe({
  name: 'nutritionInfoCalculation',
})
export class NutritionInfoCalculationPipe implements PipeTransform {
  transform(
    product: Product | null,
    selections: Ingredient[] = [],
  ): NutritionInfo {
    const nutritionInfo: NutritionInfo = new NutritionInfo();
    if (product) {
      if (product.content) {
        if (product.content.calories) {
          nutritionInfo.increase('calories', product.content.calories);
        }
        if (product.content.calories_from_fat) {
          nutritionInfo.increase(
            'caloriesFromFat',
            product.content.calories_from_fat,
          );
        }
        if (product.content.total_fat) {
          nutritionInfo.increase('totalFat', product.content.total_fat);
        }
        if (product.content.saturated_fat) {
          nutritionInfo.increase('saturatedFat', product.content.saturated_fat);
        }
        if (product.content.trans_fat) {
          nutritionInfo.increase('transFat', product.content.trans_fat);
        }
        if (product.content.polyunsaturated_fat) {
          nutritionInfo.increase(
            'polyunsaturatedFat',
            product.content.polyunsaturated_fat,
          );
        }
        if (product.content.monounsaturated_fat) {
          nutritionInfo.increase(
            'monounsaturatedFat',
            product.content.monounsaturated_fat,
          );
        }
        if (product.content.cholesterol) {
          nutritionInfo.increase('cholesterol', product.content.cholesterol);
        }
        if (product.content.sodium) {
          nutritionInfo.increase('sodium', product.content.sodium);
        }
        if (product.content.potassium) {
          nutritionInfo.increase('potassium', product.content.potassium);
        }
        if (product.content.carbohydrates) {
          nutritionInfo.increase(
            'carbohydrates',
            product.content.carbohydrates,
          );
        }
        if (product.content.dietary_fiber) {
          nutritionInfo.increase('dietaryFiber', product.content.dietary_fiber);
        }
        if (product.content.sugars) {
          nutritionInfo.increase('sugars', product.content.sugars);
        }
        if (product.content.protein) {
          nutritionInfo.increase('protein', product.content.protein);
        }
      }

      selections.forEach((ingredient: Ingredient) => {
        if (ingredient.content) {
          if (ingredient.content.calories) {
            nutritionInfo.increase('calories', ingredient.content.calories);
          }
          if (ingredient.content.calories_from_fat) {
            nutritionInfo.increase(
              'caloriesFromFat',
              ingredient.content.calories_from_fat,
            );
          }
          if (ingredient.content.total_fat) {
            nutritionInfo.increase('totalFat', ingredient.content.total_fat);
          }
          if (ingredient.content.saturated_fat) {
            nutritionInfo.increase(
              'saturatedFat',
              ingredient.content.saturated_fat,
            );
          }
          if (ingredient.content.trans_fat) {
            nutritionInfo.increase('transFat', ingredient.content.trans_fat);
          }
          if (ingredient.content.polyunsaturated_fat) {
            nutritionInfo.increase(
              'polyunsaturatedFat',
              ingredient.content.polyunsaturated_fat,
            );
          }
          if (ingredient.content.monounsaturated_fat) {
            nutritionInfo.increase(
              'monounsaturatedFat',
              ingredient.content.monounsaturated_fat,
            );
          }
          if (ingredient.content.cholesterol) {
            nutritionInfo.increase(
              'cholesterol',
              ingredient.content.cholesterol,
            );
          }
          if (ingredient.content.sodium) {
            nutritionInfo.increase('sodium', ingredient.content.sodium);
          }
          if (ingredient.content.potassium) {
            nutritionInfo.increase('potassium', ingredient.content.potassium);
          }
          if (ingredient.content.carbohydrates) {
            nutritionInfo.increase(
              'carbohydrates',
              ingredient.content.carbohydrates,
            );
          }
          if (ingredient.content.dietary_fiber) {
            nutritionInfo.increase(
              'dietaryFiber',
              ingredient.content.dietary_fiber,
            );
          }
          if (ingredient.content.sugars) {
            nutritionInfo.increase('sugars', ingredient.content.sugars);
          }
          if (ingredient.content.protein) {
            nutritionInfo.increase('protein', ingredient.content.protein);
          }
        }
      });
    }
    return nutritionInfo;
  }
}
