import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GlobalStateModel, Order } from '@models/index';
import { Select } from '@ngxs/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-go-start',
  templateUrl: './go-start.component.html',
  styleUrls: ['go-start.component.scss'],
})
export class GoStartComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.order.order)
  order$!: Observable<Order>;

  @Input() availableDeadlineTimes: Date[] = [];
  @Output() startClicked = new EventEmitter<{
    deadline: Date;
    note?: string;
  }>();

  groupOrderForm: FormGroup = new FormGroup({
    deadline: new FormControl(null, [Validators.required]),
    note: new FormControl(null),
  });
  constructor() {}
  ngOnInit() {}

  startClick() {
    this.groupOrderForm.markAllAsTouched();
    if (this.groupOrderForm.valid) {
      this.startClicked.emit({
        deadline: this.deadline.value,
        note: this.note.value,
      });
    }
  }

  get deadline(): AbstractControl {
    return this.groupOrderForm.get('deadline')!;
  }

  get note(): AbstractControl {
    return this.groupOrderForm.get('note')!;
  }
}
