<div class="card">
  <h4 class="h4 card-header bg-light">{{ message.title }}</h4>
  <div
    class="card-img"
    *ngIf="message.hero_content && message.hero_content.url">
    <img
      [src]="message.hero_content.url"
      [alt]="message.hero_content.alt_text"
      [attr.type]="message.hero_content.type"
      class="w-100 h-auto" />
  </div>
  <div class="card-body bg-light">
    <div>{{ message.body }}</div>
  </div>
  <!--  <ng-container *ngIf="message.dismissable">-->
  <!--    <div class="card-footer bg-light">-->
  <!--      <app-button classes="btn btn-outline-danger" [isLoading]="isDismissing" (buttonClick)="dismissMessage()">Dismiss</app-button>-->
  <!--    </div>-->
  <!--  </ng-container>-->
</div>
