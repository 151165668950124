import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  ViewChild,
  Input, Output, EventEmitter,
} from '@angular/core';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { Store } from '@ngxs/store';
import { GetNearLocations } from '@store/actions/locations.actions';

import { environment } from '../../../../environments/environment';
import { collapseAnimation } from 'angular-animations';

@Component({
  selector: 'app-search-bar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './search-bar.component.html',
  styleUrls: ['search-bar.component.scss'],
  animations: [
    collapseAnimation()
  ]
})
export class SearchBarComponent implements AfterViewInit {
  @ViewChild('searchBar') searchBar!: ElementRef;
  @Input() isLocationsPage: boolean = false;
  @Input() geolocationAllowed: boolean = false;

  @Output() geolocationClick = new EventEmitter<void>();
  @Output() searchRan = new EventEmitter<void>();

  isLoading = false;

  mbGeocoder: MapboxGeocoder;
  constructor(
    private store: Store,
    @Inject(PLATFORM_ID) private _platformId: Object,
  ) {
    this.mbGeocoder = new MapboxGeocoder({
      accessToken: environment.mapboxToken,
    });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this._platformId)) {
      this.mbGeocoder.addTo(`#${this.searchBar.nativeElement.id}`);
      this.mbGeocoder.setPlaceholder('Enter your city, state or ZIP');
    }

    this.mbGeocoder.setTypes('postcode, place, locality');
    this.mbGeocoder.setCountries('us');
    this.mbGeocoder.on(
      'result',
      (result: { result: MapboxGeocoder.Result }) => {
        this.isLoading = true;
        this.searchRan.emit()
        this.store
          .dispatch(
            new GetNearLocations(
              result.result.center[0],
              result.result.center[1],
              25,
              20,
              true,
              true,
            ),
          )
          .subscribe(() => (this.isLoading = false));
      },
    );
  }
}
