<div class="d-flex flex-column gap-3 w-100">
  <ng-container *ngIf="showHeading"
    ><h3 class="text-center">{{ createTitle }}</h3></ng-container
  >
  <form class="d-flex flex-column gap-3 w-100" [formGroup]="parentForm">
    <div class="d-flex flex-nowrap gap-3">
      <div class="form-floating w-100">
        <input
          class="form-control form-control-sm"
          type="text"
          formControlName="firstName"
          id="firstName"
          placeholder="Name"
          autocomplete="given-name"
          autocapitalize="words" />
        <label
          class="col-form-label col-form-label-sm"
          for="firstName"
          id="name-label"
          >First Name*</label
        >
        <ng-container
          *ngIf="
            (parentForm | getFormControlFromParent : 'firstName').touched &&
            (parentForm | getFormControlFromParent : 'firstName').errors
          ">
          <small
            class="text-danger"
            *ngIf="
              (parentForm | getFormControlFromParent : 'firstName').errors![
                'required'
              ]
            "
            >First Name Required</small
          >
        </ng-container>
      </div>
      <div class="form-floating w-100">
        <input
          class="form-control form-control-sm"
          type="text"
          formControlName="lastName"
          id="lastName"
          placeholder="Name"
          autocomplete="family-name"
          autocapitalize="words" />
        <label class="col-form-label col-form-label-sm" for="lastName"
          >Last Name*</label
        >
        <ng-container
          *ngIf="
            (parentForm | getFormControlFromParent : 'lastName').touched &&
            (parentForm | getFormControlFromParent : 'lastName').errors
          ">
          <small
            class="text-danger"
            *ngIf="
              (parentForm | getFormControlFromParent : 'lastName').errors![
                'required'
              ]
            "
            >Last Name Required</small
          >
        </ng-container>
      </div>
    </div>
    <div class="d-flex flex-column gap-2">
      <div class="form-floating">
        <input
          type="email"
          class="form-control form-control-sm"
          id="email"
          aria-describedby="emailHelp"
          placeholder="Email"
          formControlName="email"
          autocapitalize="off"
          autocomplete="email" />
        <label class="col-form-label col-form-label-sm" for="email"
          >Email address*</label
        >
        <ng-container
          *ngIf="
            (parentForm | getFormControlFromParent : 'email').touched &&
            (parentForm | getFormControlFromParent : 'email').errors
          ">
          <small
            class="text-danger"
            *ngIf="
              (parentForm | getFormControlFromParent : 'email').errors![
                'required'
              ]
            "
            >Email Required</small
          >
          <small
            class="text-danger"
            *ngIf="
              (parentForm | getFormControlFromParent : 'email').errors!['email']
            "
            >Invalid Email</small
          >
        </ng-container>
      </div>
      <div class="form-check">
        <label
          class="form-check-label d-flex align-items-center cursor-pointer">
          <input
            class="form-check-input cursor-pointer"
            type="checkbox"
            formControlName="emailOptIn" />
          <small>Sign me up for news and offers</small>
        </label>
      </div>
    </div>

    <div class="d-flex flex-column gap-2 w-100">
      <div class="form-floating">
        <input
          class="form-control form-control-sm"
          type="tel"
          formControlName="phoneNumber"
          id="phonenumber"
          placeholder="Phone Number"
          autocomplete="tel-national"
          mask="(000) 000-0000" />
        <label
          class="col-form-label col-form-label-sm"
          for="phonenumber"
          id="phone-number-label"
          >Phone Number*</label
        >
        <ng-container
          *ngIf="
            (parentForm | getFormControlFromParent : 'phoneNumber').touched &&
            (parentForm | getFormControlFromParent : 'phoneNumber').errors
          ">
          <small
            class="text-danger"
            *ngIf="
              (parentForm | getFormControlFromParent : 'phoneNumber').errors![
                'required'
              ]
            "
            >Phone Number Required</small
          >
        </ng-container>
      </div>
    </div>

    <div class="d-flex flex-column gap-2 w-100">
      <div class="form-floating">
        <input
          class="form-control form-control-sm"
          type="text"
          formControlName="birthday"
          bsDatepicker
          container="body"
          [bsConfig]="{
            dateInputFormat: 'MM/DD/YYYY',
            isAnimated: true,
            containerClass: 'theme-default',
            showWeekNumbers: false,
            adaptivePosition: true
          }"
          [maxDate]="maxDate"
          id="birthday"
          placeholder="Birthday"
          autocomplete="bday" />
        <label
          class="col-form-label col-form-label-sm"
          for="birthday"
          id="birthday-label"
          >Birthday*</label
        >
        <ng-container
          *ngIf="
            (parentForm | getFormControlFromParent : 'birthday').touched &&
            (parentForm | getFormControlFromParent : 'birthday').errors
          ">
          <small
            class="text-danger"
            *ngIf="
              (parentForm | getFormControlFromParent : 'birthday').errors![
                'required'
              ]
            "
            >Birthday Required</small
          >
        </ng-container>
      </div>
    </div>

    <div class="d-flex flex-nowrap gap-3">
      <div class="form-floating w-100">
        <input
          type="password"
          class="form-control form-control-sm"
          id="password"
          placeholder="Password"
          formControlName="password"
          autocomplete="new-password" />
        <label class="col-form-label col-form-label-sm" for="password"
          >Password*</label
        >
        <ng-container
          *ngIf="
            (parentForm | getFormControlFromParent : 'password').touched &&
            (parentForm | getFormControlFromParent : 'password').errors
          ">
          <small
            class="text-danger"
            *ngIf="
              (parentForm | getFormControlFromParent : 'password').errors![
                'required'
              ]
            "
            >Password Required</small
          >
        </ng-container>
      </div>
      <div class="form-floating w-100">
        <input
          type="password"
          class="form-control form-control-sm"
          id="confirm-password"
          placeholder="Confirm Password"
          formControlName="passwordConfirmation"
          autocomplete="new-password" />
        <label
          for="confirm-password"
          class="col-form-label col-form-label-sm confirm-password text-nowrap line-clamp-1 w-100"
          >Confirm Password*</label
        >
        <ng-container
          *ngIf="
            (parentForm | getFormControlFromParent : 'passwordConfirmation')
              .touched &&
            (parentForm | getFormControlFromParent : 'passwordConfirmation')
              .errors
          ">
          <small
            class="text-danger"
            *ngIf="
              (parentForm | getFormControlFromParent : 'passwordConfirmation')
                .errors!['required']
            "
            >Password Confirmation Required</small
          >
        </ng-container>
        <ng-container
          *ngIf="
            (parentForm | getFormControlFromParent : 'passwordConfirmation')
              .touched &&
            (parentForm | getFormControlFromParent : 'password').touched &&
            (parentForm | getFormControlFromParent : 'passwordConfirmation')
              .value
          ">
          <small
            class="text-danger"
            *ngIf="
              (parentForm | getFormControlFromParent : 'passwordConfirmation')
                .value !==
              (parentForm | getFormControlFromParent : 'password').value
            "
            >Passwords do not match</small
          >
        </ng-container>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="form-check">
        <label
          class="form-check-label d-flex align-items-center cursor-pointer">
          <input
            class="form-check-input cursor-pointer"
            type="checkbox"
            formControlName="termsAndConditions" />
          <small>
            I agree to the &nbsp;
            <a href="/legal" target="_blank" class="btn-link">Terms & Conditions</a>
            &nbsp; and &nbsp;
            <a href="/legal" target="_blank" class="btn-link">Privacy Policy</a>.
          </small>
        </label>
      </div>
    </div>
    <ng-container *ngIf="errorMessage">
      <div class="alert alert-danger w-100">{{ errorMessage }}</div>
    </ng-container>
    <app-button
      [disabled]="parentForm.invalid"
      classes="btn btn-primary w-100"
      (buttonClick)="createClicked.emit()"
      [isLoading]="createLoading">
      Join Now
    </app-button>
  </form>
</div>
