import {
  AllergenTag,
  Category,
  Contains,
  Ingredient,
  Lifestyle,
  PlantBased,
} from '@models/index';

export class InitializeNutritionMenu {
  static readonly type = '[Nutrition] Initialize Nutrition Menu';
  constructor() {}
}

export class InitializeAllergens {
  static readonly type = '[Nutrition] Initialize Allergens';
  constructor() {}
}

export class AddAllergenTag {
  static readonly type = '[Nutrition] Add Allergen Tag';
  constructor(public readonly tag: AllergenTag) {}
}

export class RemoveAllergenTag {
  static readonly type = '[Nutrition] Remove Allergen Tag';
  constructor(public readonly tag: AllergenTag) {}
}

export class ClearNutritionPreferences {
  static readonly type = '[Nutrition] Clear Nutrition Preferences';
  constructor() {}
}

export class SetNutritionCategory {
  static readonly type = '[Nutrition] Set Nutrition Category';
  constructor(public category: Category) {}
}

export class ClearNutritionCategory {
  static readonly type = '[Nutrition] Clear Nutrition Category';
  constructor() {}
}

export class SetNutritionProduct {
  static readonly type = '[Nutrition] Set Nutrition Product';
  constructor(public readonly chainProductID: number) {}
}

export class ClearNutritionProduct {
  static readonly type = '[Nutrition] Clear Nutrition Product';
  constructor() {}
}

export class AddToSelectedItems {
  static readonly type = '[Nutrition] Add to Selected Items';

  constructor(public readonly item: Ingredient) {}
}

export class RemoveFromSelectedItems {
  static readonly type = '[Nutrition] Remove from Selected Items';

  constructor(public readonly item: Ingredient) {}
}
