import { AsyncPipe, CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@pipes/pipes.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

import { environment } from '../../environments/environment';
/* Modules */
import { AppCommonModule } from '../app-common/app-common.module';
// import { NavigationModule } from '@modules/navigation/navigation.module';
/* Components */
import * as locationsComponents from './components';
/* Containers */
import * as locationsContainers from './containers';
/* Guards */
import * as locationsGuards from './guards';
/* Services */
import * as locationsServices from './services';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AppCommonModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapboxToken,
    }),
    BsDropdownModule,
    PipesModule,
    AccordionModule,
    CollapseModule,
    TabsModule,
    // NavigationModule,
  ],
  providers: [
    ...locationsServices.services,
    ...locationsGuards.guards,
    AsyncPipe,
  ],
  declarations: [
    ...locationsContainers.containers,
    ...locationsComponents.components,
  ],
  exports: [
    ...locationsContainers.containers,
    ...locationsComponents.components,
  ],
})
export class LocationsModule {}
