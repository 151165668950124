import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  NgxDateFormat,
  NgxTimelineEvent,
  NgxTimelineEventChangeSideInGroup,
  NgxTimelineEventGroup,
} from '@frxjs/ngx-timeline';
import { AccountActivity, GlobalStateModel } from '@models/index';
import { Select } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-account-activity',
  templateUrl: './account-activity.component.html',
  styleUrls: ['account-activity.component.scss'],
})
export class AccountActivityComponent {
  @Select((state: GlobalStateModel) => state.user.activity)
  accountActivity$!: Observable<AccountActivity[]>;

  title = 'demo-app';
  events!: NgxTimelineEvent[];
  color = 0;

  constructor(private location: Location) {}

  back(): void {
    this.location.back();
  }
}
