<div
  class="d-flex flex-row align-items-center w-100 cursor-pointer pt-3"
  (click)="isCollapsed = !isCollapsed"
  [attr.aria-expanded]="!isCollapsed"
  aria-controls="collapseBasic">
  <h4 class="flex-grow-1">Restaurant Details</h4>
  <div class="d-flex align-items-center rotate" [class.down]="!isCollapsed">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-chevron-down"
      viewBox="0 0 16 16">
      <path
        fill-rule="evenodd"
        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
    </svg>
  </div>
</div>
<div
  class="pb-3 pt-2"
  id="collapseBasic"
  [collapse]="isCollapsed"
  [isAnimated]="true">
  <div class="d-flex flex-column gap-2">
    <div class="w-100">
      <a
        [href]="restaurant.telephone | hrefTel"
        class="btn-link p-0 text-success">
        {{ restaurant.telephone }}
      </a>
    </div>
    <ng-container
      *ngIf="
        restaurant.calendars
          | formattedCalendar
            : 'business'
            : restaurant.utcoffset as businessCalendars
      ">
      <div *ngIf="businessCalendars.length">
        <div class="font-weight-bold">Restaurant Hours</div>
        <div
          class="d-flex w-100 align-items-center"
          *ngFor="let calendar of businessCalendars">
          <small class="flex-grow-1">
            {{ calendar.startDay }}
            <ng-container *ngIf="calendar.endDay"
              >- {{ calendar.endDay }}</ng-container
            >
          </small>
          <small> {{ calendar.hours }} </small>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        restaurant.calendars
          | formattedCalendar
            : 'dispatch'
            : restaurant.utcoffset as dispatchCalendars
      ">
      <div *ngIf="dispatchCalendars.length">
        <div class="font-weight-bold">Delivery Hours</div>
        <div
          class="d-flex w-100 align-items-center"
          *ngFor="let calendar of dispatchCalendars">
          <small class="flex-grow-1">
            {{ calendar.startDay }}
            <ng-container *ngIf="calendar.endDay"
              >- {{ calendar.endDay }}</ng-container
            >
          </small>
          <small> {{ calendar.hours }} </small>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="restaurant.content">
      <div
        class="pb-3"
        *ngIf="
          restaurant.content?.amenities && restaurant.content?.amenities?.length
        ">
        <div class="font-weight-bold">Amenities at this Restaurant</div>
        <small class="d-flex w-100 align-items-center">
          {{ restaurant.content.amenities | stringJoin }}
        </small>
      </div>

      <div *ngIf="restaurant.content.description">
        <h5 class="font-weight-bold text-success">
          About Freebirds {{ restaurant.name }}
        </h5>
        <small class="d-flex w-100 align-items-center">
          {{ restaurant.content.description }}
        </small>
      </div>
    </ng-container>
  </div>
</div>
