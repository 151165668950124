<div
  *ngIf="!isApplied; else rewardCardSelected"
  class="reward-card-shell text-nowrap"
  (click)="applyReward(availableReward)">
  <img
    class="perks-icon"
    src="assets/images/icons/Icons/fb-perks-solid.svg"
    alt="" />
  <div class="reward-title">{{ availableReward.label }}</div>
  <button type="button" class="add-button red"></button>
</div>
<ng-template #rewardCardSelected>
  <div
    class="reward-card-shell-selected text-nowrap"
    (click)="removeReward(availableReward)">
    <img
      class="perks-icon"
      src="assets/images/icons/Icons/fb-perks-solid.svg"
      alt="" />
    <div class="reward-title">{{ availableReward.label }}</div>
    <button type="button" class="add-button-selected"></button>
  </div>
</ng-template>
