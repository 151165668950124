<app-navbar navbarOrientation="minimal"></app-navbar>
<div
  class="d-flex flex-row w-100 nav-page-height-fixed flex-nowrap"
  *ngIf="restaurant">
  <app-selected-location
    [selectedLocation]="restaurant" [individualLocation]="true"></app-selected-location>

  <div class="w-auto h-100 flex-grow-1 position-relative">
    <mgl-map
      *ngIf="platformId | isPlatformBrowser"
      class="w-auto h-100 flex-grow-1 d-block"
      [style]="'mapbox://styles/freebirdswb/cl9lp2hry002216mrj1j1fmfb'"
      id="locationsMap"
      (mapLoad)="onMapLoad($event)"
      [center]="{
        lng: restaurant.longitude,
        lat: restaurant.latitude
      }"
      [zoom]="[15]"
      #mapComponent>
      <mgl-marker
        *ngIf="restaurant"
        [lngLat]="[restaurant.longitude, restaurant.latitude]"
        anchor="center">
        <img
          class="location-pin cursor-pointer"
          src="assets/images/icons/Icons/fb-locations-pin-open-orange.svg"
          alt="{{ restaurant.name }}"
          *ngIf="
            restaurant.iscurrentlyopen && restaurant.isavailable;
            else closed
          " />
        <ng-template #closed>
          <img
            class="location-pin"
            src="assets/images/icons/Icons/fb-location-pin-closed-orange.svg"
            alt="{{ restaurant.name }} (Closed)" />
        </ng-template>
      </mgl-marker>
    </mgl-map>
  </div>
</div>
