import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SavedCard } from '@models/index';
import { Store } from '@ngxs/store';
import {
  DeleteSavedCard,
  SetDefaultSavedCard,
  SetSavedCards,
} from '@store/actions/user.actions';

@Component({
  selector: 'app-saved-payment-method-card',
  templateUrl: './saved-payment-method-card.component.html',
  styleUrls: ['saved-payment-method-card.component.scss'],
})
export class SavedPaymentMethodCardComponent {
  @Input() card!: SavedCard;

  constructor(private store: Store) {
  }

  deleteLoading: boolean = false;

  deleteClicked() {
    this.deleteLoading = true;
    this.store.dispatch(new DeleteSavedCard(this.card)).subscribe(() => {
      this.deleteLoading = false;
    });
  }

  defaultClicked() {
    this.store.dispatch(new SetDefaultSavedCard(this.card));
  }
}
