import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-rewards-how-to-block',
    templateUrl: './rewards-how-to-block.component.html',
    styleUrls: ['rewards-how-to-block.component.scss'],
})
export class RewardsHowToBlockComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
