import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MealNameModalComponent } from '@common/components';
import { GlobalStateModel, Ingredient, Order, Product } from '@models/index';
import { OptionGroupChange } from '@modules/order/containers';
import { Select, Store } from '@ngxs/store';
import { OpenCart } from '@store/actions/app.actions';
import { AddToOrder, UpdateOrderItem } from '@store/actions/order.actions';
import { CreateSavedProduct } from '@store/actions/user.actions';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-customize-add-cta',
  templateUrl: './customize-add-cta.component.html',
  styleUrls: ['customize-add-cta.component.scss'],
})
export class CustomizeAddCtaComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.order.order)
  order$!: Observable<Order>;

  bsModalRef?: BsModalRef;
  @Input() incompleteSections: string[] = [];

  @Input() product!: Product;
  @Input() isCustomizable = true;
  @Input() options!: Ingredient[];
  @Input() isEditMode: boolean = false;
  @Input() basketProductID?: number;
  basketID: string = '';

  constructor(
    private modalService: BsModalService,
    private store: Store,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.order$.subscribe((order: Order) => {
      if (order) {
        this.basketID = order.id;
      }
    });
  }

  openMealNameModal() {
    if (this.incompleteSections.length) {
      return;
    }
    const initialState: ModalOptions = {
      initialState: {
        product: this.product,
      },
    };
    this.bsModalRef = this.modalService.show(
      MealNameModalComponent,
      initialState,
    );
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.finishClicked.subscribe(
      (userInfo: {
        name: string;
        specialInstructions: string;
        saveAsFavorite: boolean;
        favoriteName: string;
      }) => {
        this.store
          .dispatch(
            new AddToOrder(
              this.basketID,
              this.product.id,
              1,
              this.options.map((option) => option.id.toString()),
              userInfo.specialInstructions,
              userInfo.name,
            ),
          )
          .subscribe({
            next: () => {
              if (userInfo.saveAsFavorite) {
                this.store.dispatch(
                  new CreateSavedProduct(
                    userInfo.favoriteName,
                    this.product,
                    this.options,
                    1,
                    userInfo.specialInstructions,
                    userInfo.name,
                  ),
                );
              }
              this.router
                .navigate([''], { fragment: 'menu' })
                .then(() => this.store.dispatch(new OpenCart()));
            },
            error: (error) => {
              this.toastr.error(error.error.message, 'Error');
            },
          });
      },
    );
  }

  updateItem() {
    const order = this.store.selectSnapshot(
      (state: GlobalStateModel) => state.order.order,
    );
    this.store
      .dispatch(
        new UpdateOrderItem(
          this.basketID,
          this.basketProductID!,
          this.product.id,
          1,
          this.options.map((option) => option.id.toString()),
          order?.products.find(
            (item) => item.id === this.basketProductID,
          )?.specialinstructions,
          order?.products.find(
            (item) => item.id === this.basketProductID,
          )?.recipient,
        ),
      )
      .subscribe({
        next: () => {
          this.router
            .navigate([''], { fragment: 'menu' })
            .then(() => this.store.dispatch(new OpenCart()));
        },
        error: (error) => {
          this.toastr.error(error.error.message, 'Error');
        },
      });
  }
}
