import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '@models/index';

@Pipe({
  name: 'upsellSort',
})
export class UpsellSortPipe implements PipeTransform {
  transform(products: Product[]): Product[] {
    return products.sort(
      (a, b) =>
        (b.content.upsell_priority ?? 0) - (a.content.upsell_priority ?? 0),
    );
  }
}
