import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DeliveryAddressConfirmModalComponent } from '@common/components';
import {
  Category, GlobalStateModel,
  GroupOrder,
  Menu,
  Order,
  Restaurant,
  SavedAddress,
  SavedProduct,
  User,
} from '@models/index';
import { BsModalService } from 'ngx-bootstrap/modal';
import {Select} from "@ngxs/store";
import {Observable} from "rxjs";

@Component({
  selector: 'app-categories-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './categories-container.component.html',
  styleUrls: ['categories-container.component.scss'],
})
export class CategoriesContainerComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.locations.orderLocation)
  orderLocation$!: Observable<Restaurant>;

  @Input() orderLocation!: Restaurant;
  @Input() menu!: Menu;
  @Input() savedProducts!: SavedProduct[] | null;

  errorMessage: string | null = null;

  constructor() {}
  ngOnInit() {
    this.orderLocation$.subscribe((location) => {
      this.orderLocation = location;
    });
  }

  renderTrackBy(index: number, item: Category) {
    return `${index}${item.name}${item.id}`;
  }
}
