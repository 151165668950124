<app-navbar navbarOrientation="customPage"></app-navbar>
<div class="w-100 nav-page-height">
  <ng-container *ngFor="let section of pageData.page_blocks">
    <ng-container [ngSwitch]="section!.item!.section_type!">
      <ng-container *ngSwitchCase="'jumbotron'">
        <app-jumbotron
          [jumbotronLayouts]="
            section!.item!.layouts | jumbotronTimeFilter
          "></app-jumbotron>
      </ng-container>

      <ng-container *ngSwitchCase="'featured'">
        <app-ad-card-container
          [featuredAds]="
            section!.item!.sections | featuredAdsFilter
          "></app-ad-card-container>
      </ng-container>

      <ng-container *ngSwitchCase="'marquee'">
        <app-block-marquee [section]="section!.item!"></app-block-marquee>
      </ng-container>

      <ng-container *ngSwitchCase="'highlight'">
        <app-block-highlight [section]="section!.item!"></app-block-highlight>
      </ng-container>

      <ng-container *ngSwitchCase="'featurette'">
        <app-block-featurettes
          [section]="section!.item!"></app-block-featurettes>
      </ng-container>

      <ng-container *ngSwitchCase="'wysiwyg'">
        <div
          class="w-100"
          [innerHTML]="section!.item!.wysiwyg_content | sanitizeHtml"></div>
      </ng-container>

      <ng-container *ngSwitchCase="'code'">
        <div
          class="w-100"
          [innerHTML]="section!.item!.page_code | sanitizeHtml"></div>
      </ng-container>

      <ng-container *ngSwitchCase="'paragraph'">
        <app-block-paragraph [pageBlock]="section!.item!"></app-block-paragraph>
      </ng-container>

      <ng-container *ngSwitchCase="'headline'">
        <app-block-headline [pageBlock]="section!.item!"></app-block-headline>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<app-footer footerStyle="full"></app-footer>
