<div class="d-flex flex-column gap-3 w-100">
  <ng-container *ngIf="showHeading">
    <h3 class="text-center">Sign In</h3>
  </ng-container>
  <form
    action=""
    class="d-flex flex-column gap-3 w-100"
    [formGroup]="parentForm">
    <div class="form-floating">
      <input
        type="email"
        class="form-control form-control-sm"
        id="email"
        aria-describedby="emailHelp"
        formControlName="email"
        placeholder="Email Address"
        autocomplete="username" />
      <label class="col-form-label col-form-label-sm" for="email"
        >Email address</label
      >
      <ng-container
        *ngIf="
          (parentForm | getFormControlFromParent : 'email').touched &&
          (parentForm | getFormControlFromParent : 'email').invalid &&
          (parentForm | getFormControlFromParent : 'email').errors
        ">
        <small
          class="text-danger"
          *ngIf="
            (parentForm | getFormControlFromParent : 'email').errors!['email']
          ">
          Invalid Email
        </small>
        <small
          class="text-danger"
          *ngIf="
            (parentForm | getFormControlFromParent : 'email').errors![
              'required'
            ]
          ">
          Email Required
        </small>
      </ng-container>
    </div>
    <div class="form-floating">
      <input
        type="password"
        class="form-control form-control-sm"
        id="password"
        formControlName="password"
        placeholder="Password"
        autocomplete="current-password" />
      <label class="col-form-label col-form-label-sm" for="password"
        >Password</label
      >
      <ng-container
        *ngIf="
          (parentForm | getFormControlFromParent : 'password').touched &&
          (parentForm | getFormControlFromParent : 'password').invalid &&
          (parentForm | getFormControlFromParent : 'password').errors
        ">
        <small
          class="text-danger"
          *ngIf="
            (parentForm | getFormControlFromParent : 'password').errors![
              'required'
            ]
          ">
          Password Required
        </small>
      </ng-container>
    </div>
    <div class="d-flex">
      <div class="form-check grow-1 basis-0">
        <label
          class="form-check-label d-flex align-items-center cursor-pointer">
          <input
            class="form-check-input cursor-pointer"
            type="checkbox"
            value=""
            checked />
          <small>Remember Me</small>
        </label>
      </div>
      <button
        routerLink="/user/forgot-password"
        type="button"
        class="forgot-password btn btn-link btn-sm p-0">
        Forgot Password?
      </button>
    </div>
    <ng-container *ngIf="errorMessage">
      <div class="alert alert-danger w-100">{{ errorMessage }}</div>
    </ng-container>
    <app-button
      classes="btn btn-primary w-100"
      (buttonClick)="loginClick.emit()"
      [isLoading]="loginLoading">
      Sign In
    </app-button>
    <app-button
      classes="btn btn-facebook align-items-center d-flex flex-nowrap gap-2 justify-content-center w-100"
      (buttonClick)="loginFacebookClick.emit()"
      [isLoading]="facebookLoading">
      <div class="d-flex align-items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          class="bi bi-facebook"
          viewBox="0 0 16 16">
          <path
            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
        </svg>
      </div>
      Sign In with Facebook
    </app-button>
  </form>
</div>
