import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@modules/auth/services';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-create-an-account',
  templateUrl: './create-an-account.component.html',
  styleUrls: ['create-an-account.component.scss'],
})
export class CreateAnAccountComponent {
  createAccountFrom = new FormGroup({
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    emailOptIn: new FormControl(true, []),
    phoneNumber: new FormControl(null, [Validators.required]),
    smsOptIn: new FormControl(true, []),
    birthday: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required]),
    passwordConfirmation: new FormControl(null, [Validators.required]),
    termsAndConditions: new FormControl(false, [Validators.requiredTrue]),
  });

  createLoading = false;

  errorMessage = '';

  constructor(private auth: AuthService, private router: Router) {}

  onCreateAccount() {
    this.createLoading = true;
    if (this.createAccountFrom.valid) {
      this.auth
        .createAccount(
          this.firstName.value,
          this.lastName.value,
          this.email.value,
          this.emailOptIn.value,
          this.phoneNumber.value,
          this.smsOptIn.value,
          moment(this.birthday.value).format('YYYY-MM-DD'),
          this.password.value,
          this.passwordConfirmation.value,
          this.termsAndConditions.value,
        )
        .subscribe({
          next: () => {
            this.createLoading = false;
            this.router.navigate([''], { fragment: 'orders' });
          },
          error: (error) => {
            this.createLoading = false;
            this.errorMessage =
              error?.error?.message ??
              error.message ??
              'Email or Password Incorrect';
          },
        });
    } else {
      this.createAccountFrom.markAllAsTouched();
      this.createLoading = false;
    }
  }

  private get firstName(): AbstractControl {
    return this.createAccountFrom.get('firstName')!;
  }
  private get lastName(): AbstractControl {
    return this.createAccountFrom.get('lastName')!;
  }
  private get email(): AbstractControl {
    return this.createAccountFrom.get('email')!;
  }
  private get emailOptIn(): AbstractControl {
    return this.createAccountFrom.get('emailOptIn')!;
  }
  private get phoneNumber(): AbstractControl {
    return this.createAccountFrom.get('phoneNumber')!;
  }
  private get smsOptIn(): AbstractControl {
    return this.createAccountFrom.get('smsOptIn')!;
  }
  private get birthday(): AbstractControl {
    return this.createAccountFrom.get('birthday')!;
  }
  private get password(): AbstractControl {
    return this.createAccountFrom.get('password')!;
  }
  private get passwordConfirmation(): AbstractControl {
    return this.createAccountFrom.get('passwordConfirmation')!;
  }
  private get termsAndConditions(): AbstractControl {
    return this.createAccountFrom.get('termsAndConditions')!;
  }
}
