<app-navbar></app-navbar>
<div
  style="
    background-image: url(/assets/images/backgrounds/alabaster-paper-texture-repeat-small.jpg);
  ">
  <section class="header py-5">
    <div class="container position-relative">
      <!--heading-->
      <div class="text-center py-5">
        <h4 class="subheading drop-shadow">Texas’ No. 1 Burrito</h4>
        <h1 class="display-4 text-primary drop-shadow">Community</h1>
        <p class="lead drop-shadow">
          Freebirds is dedicated to giving back to our local Texas communities.
          <br />
          We partner with local organizations and causes to make a difference,
          one burrito at a time!
        </p>
      </div>
      <!--CTAs-->
      <div class="d-flex flex-column flex-md-row gap-3 pb-5">
        <!--Donation-->
        <div class="section-cta p-4">
          <h4 class="text-info drop-shadow">Donation</h4>
          <div class="drop-shadow">
            We believe in good feeds for good deeds.
            <br />
            Request to donate Texas’ No. 1 Burrito to a local organization of
            your choice.
          </div>
          <button class="btn btn-primary w-fit-content" (click)="selectTab(0)">
            Request Donation
          </button>
        </div>
        <!--Spirit night-->
        <div class="section-cta p-4">
          <h4 class="text-info drop-shadow">Spirit Night</h4>
          <div class="drop-shadow">
            Join us for a fundraising Spirit Night event!
            <br />
            Enjoy a meal while supporting your organization.
          </div>
          <button
            class="btn btn-primary w-fit-content"
            (click)="selectTab(1)"
            href="">
            Request Spirit Night Event
          </button>
        </div>
      </div>
    </div>
  </section>

  <section class="community-tabs" id="communityTabs">
    <div class="container d-flex justify-content-center align-items-center">
      <div style="max-width: 40rem">
        <tabset [justified]="true" type="pills" #communityTabs>
          <tab heading="Donations">
            <ng-container *ngTemplateOutlet="donation"></ng-container>
          </tab>
          <tab heading="Spirit Nights">
            <ng-container *ngTemplateOutlet="spiritNight"></ng-container>
          </tab>
        </tabset>
      </div>
    </div>
  </section>
</div>
<app-footer></app-footer>

<ng-template #donation>
  <div class="pt-5">
    <div class="text-center pb-4">
      <h2 class="pb-3 text-primary">Donation Request</h2>
      <p>
        We believe in good feeds for good deeds. Request to donate Texas’ No. 1
        Burrito to a local organization of your choice.
      </p>
      <p class="fst-italic"><span class="text-primary">*</span> is required</p>
    </div>
    <form class="pb-5" [formGroup]="donationForm">
      <div class="d-flex flex-column gap-3">
        <div class="d-flex gap-3 flex-column flex-md-row">
          <div class="form-floating w-100">
            <input
              class="form-control"
              type="text"
              formControlName="firstName"
              id="donationFirstName"
              placeholder="First Name"
              [class.is-valid]="
                donationFirstName?.touched && donationFirstName?.valid
              "
              [class.is-invalid]="
                donationFirstName?.touched && donationFirstName?.invalid
              "
              required />
            <label for="donationFirstName" id="donation-first-name-label">
              First Name<span class="text-primary">*</span>
            </label>
          </div>
          <div class="form-floating w-100">
            <input
              class="form-control"
              type="text"
              formControlName="lastName"
              id="donationLastName"
              placeholder="Last Name"
              [class.is-valid]="
                donationLastName?.touched && donationLastName?.valid
              "
              [class.is-invalid]="
                donationLastName?.touched && donationLastName?.invalid
              "
              required />
            <label for="donationLastName" id="donation-last-name-label">
              Last Name<span class="text-primary">*</span>
            </label>
          </div>
        </div>
        <div class="d-flex gap-3 d-flex gap-3 flex-column flex-md-row">
          <div class="form-floating w-100">
            <input
              class="form-control"
              type="text"
              formControlName="organization"
              id="donationOrganization"
              placeholder="Organization"
              [class.is-valid]="
                donationOrganization?.touched && donationOrganization?.valid
              "
              [class.is-invalid]="
                donationOrganization?.touched && donationOrganization?.invalid
              "
              required />
            <label for="donationOrganization" id="donation-organization-label">
              Organization<span class="text-primary">*</span>
            </label>
          </div>
          <div class="form-floating w-100">
            <input
              class="form-control"
              type="text"
              formControlName="taxIDNumber"
              id="donationTax"
              [class.is-valid]="
                donationTaxIDNumber?.touched && donationTaxIDNumber?.valid
              "
              [class.is-invalid]="
                donationTaxIDNumber?.touched && donationTaxIDNumber?.invalid
              "
              placeholder="Tax ID Number" />
            <label for="donationTax" id="donation-tax-label">
              Tax ID Number&nbsp;<small>(not SS#)</small>
            </label>
          </div>
        </div>
        <div class="d-flex gap-3 flex-column flex-md-row">
          <div class="form-floating w-100">
            <input
              type="email"
              class="form-control"
              id="donationEmail1"
              placeholder="Enter email"
              formControlName="email"
              [class.is-valid]="donationEmail?.touched && donationEmail?.valid"
              [class.is-invalid]="
                donationEmail?.touched && donationEmail?.invalid
              "
              required />
            <label for="donationEmail1" id="donation-email1-label"
              >Email address<span class="text-primary">*</span></label
            >
          </div>
          <div class="form-floating w-100">
            <input
              class="form-control"
              type="tel"
              formControlName="phone"
              id="donationPhoneNumber"
              placeholder="Phone Number"
              mask="(000) 000-0000"
              [class.is-valid]="donationPhone?.touched && donationPhone?.valid"
              [class.is-invalid]="
                donationPhone?.touched && donationPhone?.invalid
              "
              required />
            <label for="donationPhoneNumber" id="donation-phone-number-label"
              >Phone Number<span class="text-primary">*</span></label
            >
          </div>
        </div>
        <div class="form-floating">
          <select
            class="form-control"
            id="donationType"
            required
            [class.is-valid]="donationType?.touched && donationType?.valid"
            [class.is-invalid]="donationType?.touched && donationType?.invalid"
            formControlName="donationType">
            <option disabled selected [value]="null">Donation Type</option>
            <option value="catering">Catering</option>
            <option value="monetary">Monetary</option>
            <option value="giftcards">Gift Cards</option>
            <option value="swag">Swag</option>
          </select>
          <label for="donationType" id="donation-type-label"
            >Select a Donation Type<span class="text-primary">*</span></label
          >
        </div>
        <div class="form-floating w-100">
          <input
            type="datetime-local"
            id="donationEventDate"
            class="form-control"
            formControlName="donationDate"
            min=""
            max=""
            [class.is-valid]="donationDate?.touched && donationDate?.valid"
            [class.is-invalid]="donationDate?.touched && donationDate?.invalid"
            placeholder="Donation Date" />
          <label for="donationEventDate" id="donation-event-date-label"
            >Donation Date<span class="text-primary">*</span></label
          >
        </div>
        <div class="form-floating">
          <textarea
            class="form-control"
            id="donationRequestInfo"
            formControlName="requestInfo"
            [class.is-valid]="
              donationRequestInfo?.touched && donationRequestInfo?.valid
            "
            [class.is-invalid]="
              donationRequestInfo?.touched && donationRequestInfo?.invalid
            "
            rows="3"></textarea>
          <label for="donationRequestInfo" id="donation-request-info-label"
            >Donation Request Information<span class="text-primary"
              >*</span
            ></label
          >
        </div>
        <div class="form-floating">
          <select
            class="form-control"
            id="donationLocation"
            required
            [class.is-valid]="
              donationLocation?.touched && donationLocation?.valid
            "
            [class.is-invalid]="
              donationLocation?.touched && donationLocation?.invalid
            "
            formControlName="location">
            <option disabled selected [value]="null">
              Please select a location
            </option>
            <ng-container *ngFor="let location of contentLocations$ | async">
              <ng-container
                *ngIf="
                  !(
                    donationType?.value === 'catering' &&
                    !location.can_donate_catering
                  )
                ">
                <option [value]="location.id">{{ location.name }}</option>
              </ng-container>
            </ng-container>
          </select>
          <label for="donationLocation" id="donation-location-label"
            >Select a Freebirds Pickup Location<span class="text-primary"
              >*</span
            ></label
          >
        </div>
        <div class="form-floating">
          <textarea
            class="form-control"
            id="donationAdditionalInfo"
            formControlName="additionalInfo"
            rows="3"
            [class.is-valid]="
              donationAdditionalInfo?.touched && donationAdditionalInfo?.valid
            "
            [class.is-invalid]="
              donationAdditionalInfo?.touched && donationAdditionalInfo?.invalid
            "
            required></textarea>
          <label
            for="donationAdditionalInfo"
            id="donation-additional-info-label"
            >Anything else you would like us to know?</label
          >
        </div>
        <app-button
          classes="btn btn-primary"
          (buttonClick)="submitDonationForm()"
          [isLoading]="donationSubmitting">
          Submit
        </app-button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #spiritNight>
  <div class="pt-5">
    <div class="text-center pb-4">
      <h2 class="pb-3 text-primary">Spirit Night Request</h2>
      <p>
        We'd love to host your organization for a fundraising Spirit Night
        event. Your local supporters join us for a meal, and Freebirds donates
        15% of net sales from your supporters back to your organization! Request
        a Spirit Night and we'll contact you to schedule your event.
      </p>
      <p class="fst-italic"><span class="text-primary">*</span> is required</p>
    </div>
    <form class="pb-5" [formGroup]="spiritNightForm">
      <div class="d-flex flex-column gap-3">
        <div class="d-flex gap-3 flex-column flex-md-row">
          <div class="form-floating w-100">
            <input
              class="form-control"
              type="text"
              formControlName="firstName"
              id="spiritFirstName"
              placeholder="First Name"
              required />
            <label for="spiritFirstName" id="spirit-first-name-label">
              First Name<span class="text-primary">*</span>
            </label>
          </div>
          <div class="form-floating w-100">
            <input
              class="form-control"
              type="text"
              formControlName="lastName"
              id="spiritLastName"
              placeholder="Last Name"
              required />
            <label for="spiritLastName" id="spirit-last-name-label">
              Last Name<span class="text-primary">*</span>
            </label>
          </div>
        </div>
        <div class="d-flex gap-3 d-flex gap-3 flex-column flex-md-row">
          <div class="form-floating w-100">
            <input
              class="form-control"
              type="text"
              formControlName="organization"
              id="spiritOrganization"
              placeholder="Non-Profit Organization (Must be a 5010-3)"
              required />
            <label for="spiritOrganization" id="spirit-organization-label">
              Organization Name<span class="text-primary">*</span>
            </label>
          </div>
          <div class="form-floating w-100">
            <input
              class="form-control"
              type="text"
              formControlName="taxIDNumber"
              id="spiritTax"
              placeholder="Tax ID Number" />
            <label for="spiritTax" id="spirit-tax-label"
              >Tax ID Number<span class="text-primary">*</span>&nbsp;<small
                >(not SS#)</small
              ></label
            >
          </div>
        </div>
        <div class="form-floating w-100">
          <input
            class="form-control"
            type="text"
            formControlName="address"
            id="spiritAddress"
            placeholder="Address"
            required />
          <label for="spiritAddress" id="spirit-address-label">
            Organization Address<span class="text-primary">*</span>
          </label>
        </div>
        <div class="d-flex gap-3 flex-column flex-md-row">
          <div class="form-floating w-100">
            <input
              type="email"
              class="form-control"
              id="spiritEmail1"
              formControlName="email"
              placeholder="Enter email"
              required />
            <label for="spiritEmail1"
              >Email address<span class="text-primary">*</span></label
            >
          </div>
          <div class="form-floating w-100">
            <input
              class="form-control"
              type="tel"
              formControlName="phone"
              id="spiritPhoneNumber"
              placeholder="Phone Number"
              required />
            <label for="spiritPhoneNumber" id="spirit-phone-number-label"
              >Phone Number<span class="text-primary">*</span></label
            >
          </div>
        </div>
        <div class="d-flex gap-3 d-flex gap-3 flex-column flex-md-row">
          <div class="form-floating w-100">
            <input
              class="form-control"
              type="text"
              formControlName="fundraisingPurpose"
              id="spiritPurpose"
              placeholder="Fundraising Purpose"
              required />
            <label for="spiritPurpose" id="spirit-purpose-label">
              Fundraising Purpose<span class="text-primary">*</span>
            </label>
          </div>
          <div class="form-floating w-100">
            <input
              class="form-control"
              type="number"
              formControlName="attendance"
              id="spiritAttendance"
              placeholder="Projected Attendance" />
            <label for="spiritAttendance" id="spirit-attendance-label"
              >Projected Attendance</label
            >
          </div>
        </div>
        <small
          >NOTE: Freebirds Community Events are held on Tuesdays, Wednesdays, or
          Thursdays from 5-8PM</small
        >
        <div class="form-floating w-100">
          <input
            type="datetime-local"
            id="spiritEventDate1"
            class="form-control"
            formControlName="eventDate1"
            [min]="minDate"
            placeholder="Event Date" />
          <label for="spiritEventDate1" id="spirit-event-date1-label"
            >Preferred Date<span class="text-primary">*</span></label
          >
        </div>
        <small>Minimum 3 weeks notice</small>
        <div class="form-floating w-100">
          <input
            type="datetime-local"
            id="spiritEventDate2"
            class="form-control"
            formControlName="eventDate2"
            [min]="minDate"
            placeholder="Event Date" />
          <label for="spiritEventDate2" id="spirit-event-date2-label"
            >2nd Choice Date<span class="text-primary">*</span></label
          >
        </div>
        <div class="form-floating">
          <select
            class="form-control"
            id="spiritDonationLocation"
            formControlName="location"
            required>
            <option value="" disabled selected>Please select a location</option>
            <ng-container *ngFor="let location of contentLocations$ | async">
              <option [value]="location.id">{{ location.name }}</option>
            </ng-container>
          </select>
          <label for="spiritDonationLocation"
            >Select a Freebirds Location<span class="text-primary"
              >*</span
            ></label
          >
        </div>

        <div class="form-floating">
          <textarea
            class="form-control"
            id="spiritAdditionalInfo"
            formControlName="additionalInfo"
            rows="3"
            required></textarea>
          <label for="spiritAdditionalInfo"
            >Anything else you would like us to know?</label
          >
        </div>
        <app-button
          classes="btn btn-primary"
          (buttonClick)="submitSpiritNightForm()"
          [isLoading]="spiritNightSubmitting">
          Submit
        </app-button>
      </div>
    </form>
  </div>
</ng-template>

<button
  *ngIf="!isAtTop"
  (click)="scrollToTop()"
  class="scroll-to-top-button"
  [@slideInRightOnEnter]
  [@slideOutRightOnLeave]
  [@fadeOutOnLeave]
  [@fadeInOnEnter]>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-arrow-up"
    viewBox="0 0 16 16">
    <path
      fill-rule="evenodd"
      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
  </svg>
</button>
