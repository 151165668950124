import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  GlobalStateModel,
  GroupOrder,
  Order,
  Restaurant,
  Reward,
  ScheduledAnnouncement,
  User,
} from '@models/index';
import { Select, Store } from '@ngxs/store';
import { CloseMenu, OpenCart, ToggleMenu } from '@store/actions/app.actions';
import { Logout } from '@store/actions/user.actions';
import { collapseHorizontallyAnimation } from 'angular-animations';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['navbar.component.scss'],
  animations: [collapseHorizontallyAnimation()],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Select((state: GlobalStateModel) => state.app.menuOpen)
  menuOpen$!: Observable<boolean>;
  @Select((state: GlobalStateModel) => state.app.cartOpen)
  cartOpen$!: Observable<boolean>;
  @Select((state: GlobalStateModel) => state.user.user)
  user$!: Observable<User>;
  @Select((state: GlobalStateModel) => state.order.order)
  order$!: Observable<Order>;
  @Select((state: GlobalStateModel) => state.user.rewards)
  rewards$!: Observable<Reward[]>;
  @Select((state: GlobalStateModel) => state.locations.orderLocation)
  orderLocation$!: Observable<Restaurant>;
  @Select((state: GlobalStateModel) => state.order.groupOrder)
  groupOrder$!: Observable<GroupOrder>;
  @Select((state: GlobalStateModel) => state.order.ownsGroupOrder)
  isGroupOrderOwner$!: Observable<boolean>;
  @Select((state: GlobalStateModel) => state.content.scheduledAnnouncement)
  scheduledAnnouncement$!: Observable<ScheduledAnnouncement[]>;

  availableRewards: Reward[] = [];

  @Input() navbarOrientation: 'light' | 'dark' | 'minimal' | 'customPage' =
    'light';
  @Input() minimalBGColor: string | undefined = '#EEEBE1';
  @Input() showAnnouncement = true;

  showIndicator = false;
  interval!: 0;

  constructor(
    private store: Store,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit() {
    this.scheduledAnnouncement$.subscribe((data) => {});
    this.rewards$.pipe(filter((r) => r !== null)).subscribe((rewards) => {
      // this.availableRewards = rewards.filter((reward) =)
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new CloseMenu());
  }

  toggleVisibility() {
    this.store.dispatch(new ToggleMenu());
  }

  menuHide() {
    this.store.dispatch(new CloseMenu());
  }

  openCart() {
    this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .subscribe((order) => {
        if (order) {
          this.store.dispatch(new OpenCart());
        } else {
          this.router.navigate(['start-order']);
        }
      });
  }

  back(): void {
    this.location.back();
  }

  logout(): void {
    this.store
      .dispatch(new Logout())
      .subscribe(() => this.router.navigate(['']));
  }
}
