import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { PartialItem } from '@directus/sdk';
import { HomePage, Jumbotron, JumbotronLayout } from '@models/index';
import { JumbotronTimeFilterPipe } from '@pipes/jumbotron-time-filter.pipe';
import * as moment from 'moment';

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['jumbotron.component.scss'],
})
export class JumbotronComponent {
  @Input() jumbotronLayouts!: JumbotronLayout[];

  constructor(private router: Router) {}

  jumbotronClick(jumbotron: JumbotronLayout) {
    if (jumbotron.link) {
      if (jumbotron.link.includes('http')) {
        window.open(jumbotron.link, '_blank');
        return;
      } else {
        this.router.navigateByUrl(jumbotron.link);
      }
    }
  }
}
