<div class="d-flex flex-column flex-md-row gap-3 py-3">
  <div
    class="d-flex justify-content-center justify-content-md-end just order-1 order-md-2 ml-0 ml-md-auto">
    <app-button
      class="d-flex align-items-center"
      [classes]="'btn btn-link btn-sm text-capitalize d-flex flex-nowrap gap-2'"
      (click)="openNutritionPreferencesModal()">
      Nutrition Preferences
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-sliders"
        viewBox="0 0 16 16">
        <path
          fill-rule="evenodd"
          d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
      </svg>
    </app-button>
  </div>

  <!--  only show when a filter has been selected-->
  <ng-container *ngIf="selectedAllergenTags$ | async as allergenTags">
    <ng-container *ngIf="allergenTags.length">
      <div
        class="d-flex flex-column gap-1 flex-grow-1 order-2 order-md-1 align-items-center align-items-md-start">
        <h6>Your Preferences</h6>
        <div
          class="d-flex gap-2 flex-row flex-wrap justify-content-center justify-content-md-start">
          <ng-container *ngFor="let category of allergenCategories$ | async">
            <ng-container *ngFor="let tag of allergenTags">
              <ng-container *ngIf="tag.allergen_category === category.id">
                <button
                  class="btn btn-sm"
                  [style.background-color]="category.category_color"
                  [style.color]="category.category_color! | textColor"
                  (click)="removeTag(tag)">
                  {{ tag.allergen_tag_name }}
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
