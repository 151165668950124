<div class="modal-header rounded-top align-items-center">
  <img
    class="h4-birds"
    src="../../../../assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="" />
  <h4 class="modal-title grow-1">
    {{ order.deliverymode | handoffModeName : true }} On:
    {{ order.timeplaced | oloDate | date : 'MM/dd/YYYY' }}
  </h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1">
  <div class="d-flex flex-column gap-3 grow-1">
    <ng-container *ngFor="let product of order.products">
      <div class="ordered-by d-flex flex-column gap-2 grow-1">
        <h5>{{ product.name }}</h5>
        <small class="card-text" *ngIf="product.choices.length"
          >{{ product.choices | choicesList }}
        </small>
        <small
          class="font-weight-bold text-muted text-uppercase"
          *ngIf="product.specialinstructions"
          >{{ product.specialinstructions }}</small
        >
      </div>
    </ng-container>
    <!--    <h6>Unavailable Items</h6>-->
    <!--    <ng-container *ngFor="let product of order.una">-->
    <!--      <div class="ordered-by d-flex flex-column gap-2 grow-1">-->
    <!--        <h5>{{ product.name }}</h5>-->
    <!--        <small class="card-text" *ngIf="product.choices.length"-->
    <!--        >{{ product.choices | choicesList }}-->
    <!--        </small>-->
    <!--        <small-->
    <!--          class="font-weight-bold text-muted text-uppercase"-->
    <!--          *ngIf="product.specialinstructions"-->
    <!--        >{{ product.specialinstructions }}</small-->
    <!--        >-->
    <!--      </div>-->
    <!--    </ng-container>-->
  </div>
</div>
<div class="modal-footer rounded-bottom">
  <div class="d-flex flex-nowrap gap-3 m-0">
    <app-button
      classes="btn btn-secondary"
      [isLoading]="reorderLoading"
      (buttonClick)="reorder()"
      >Reorder</app-button
    >
    <!--    <button type="button" class="btn btn-primary">Add to Bag</button>-->
  </div>
</div>
