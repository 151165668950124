import { Pipe, PipeTransform } from '@angular/core';
import { Option } from '@server/vendor/olo/interfaces';

@Pipe({
  name: 'isChildIngredient',
})
export class IsChildIngredientPipe implements PipeTransform {
  transform(option: Partial<Option>, parentOptions: Option[]): boolean {
    return !!parentOptions.find((pOption) => pOption.id === option.id);
  }
}
