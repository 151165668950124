// RESTAURANTS

import {
  CalendarType,
  DeliveryFeeType,
  DeliveryModeType,
  HandoffMode,
  LoginProviderType,
  QualificationCriteriaType,
  TimeModeType,
} from './common.enum';
import { ContextualPricing, MetadataItem } from './common.interface';

export interface RetrieveBrandLevelSettingsResponse {
  /**
   * For Advance (a.k.a. later) orders, this is the number of days in advance in which an order can be placed.
   */
  advanceorderdays: number;
  /**
   * The maximum number of characters the brand desires for delivery special instructions. Submitting more characters could truncate the message.
   */
  deliveryinstructionsmaxlength: number;
  deliverymodes: DeliveryMode[];
  loginproviders: LoginProvider[];
  pushnotifications: PushNotification[];
  timemodes: TimeMode[];
}

export interface DeliveryMode {
  /**
   * Whether or not a given deliverymode is the default deliverymode
   */
  isdefault: boolean;
  /**
   * The label to display to customers for a given deliverymode
   */
  label: string;
  /**
   * The timemodes supported for a given deliverymode. Individual restaurants may not support all timemodes.
   */
  timemodes: TimeMode[];
  /**
   * The deliverymode (a.k.a. handoff method) value to be used when submitting orders.
   */
  type: DeliveryModeType;
}

export interface TimeMode {
  /**
   * The label to display to customers for a given timemode
   */
  label: string;
  /**
   * The slug for a given timemode
   */
  type: TimeModeType;
}

export interface LoginProvider {
  /**
   * The name of the Login Provider configured by the brand in the Olo platform. If the brand is using the Olo login provider, this is typically their brand name.
   */
  name: string;
  /**
   * The slug for a given provider
   */
  provider: string;
  /**
   * The type of login provider.
   */
  type: LoginProviderType;
}

export interface PushNotification {
  /**
   * Currently will always be empty.
   */
  androidsenderid: string;
  /**
   * Currently will always be empty.
   */
  platforms: string[];
}

export interface FindNearbyParticipatingRestaurantsResponse {
  restaurants: ResponseRestaurant[];
}

export interface ResponseRestaurant {
  /**
   * Olo restaurant id. Also referred to as "vendor id". Unique across brands. This id is used in all routes that reference "vendorid" or "restaurantid".
   */
  id: number;
  /**
   * Olo's configured name for the restaurant, generally set with guidance from the brand.
   */
  name: string;
  /**
   * Olo's internal name of the brand associated with the restaurant. Usually comes across all lowercase with no punctuation and no spaces in between multiple words. (Ex. Bob's Coffee -> bobscoffee).
   */
  brand: string;
  /**
   * Standard name of the brand's restaurants. If none is provided, this will default to the name of the brand. More display friendly than the brand field.
   */
  storename: string;
  /**
   * The restaurant's phone number. Will be formatted like (xxx) xxx-xxxx.
   */
  telephone: string;
  /**
   * The restaurant's street address.
   */
  streetaddress: string;
  /**
   * Closest cross street to the restaurant.
   */
  crossstreet: string;
  /**
   * The restaurant's city.
   */
  city: string;
  contextualpricing: ContextualPricing;
  /**
   * The restaurant's state.
   */
  state: string;
  /**
   * The restaurant's zip code.
   */
  zip: string;
  /**
   * The restaurant's country (eg. US, CA).
   */
  country: string;
  /**
   * The restaurant's latitude.
   */
  latitude: number;
  /**
   * The restaurant's longitude.
   */
  longitude: number;
  /**
   * UTC offset of the timezone the restaurant is in. This factors in Daylight Savings time.
   */
  utcoffset: number;
  /**
   * If the API credentials are set to show URLs (enabled by default), this will return the URL to the desktop ordering site.
   */
  url: string;
  /**
   * If the API credentials are set to show URLs (enabled by default), this will return the URL to the mobile ordering site.
   */
  mobileurl: string;
  /**
   * Distance in miles from the set of coordinates provided, otherwise 0.
   */
  distance: number;
  /**
   * The restaurant's external reference (a.k.a. identifier). This field is not returned for projects that are associated with multiple brands or Rails enabled.
   */
  extref?: string;
  /**
   * Whether or not the restaurant only accepts Advance (a.k.a. later) orders.
   */
  advanceonly: boolean;
  /**
   * For Advance (a.k.a. later) orders, this is the number of days in advance in which an order can be placed.
   */
  advanceorderdays: number;
  /**
   * Whether or not the restaurant supports Olo coupon codes.
   */
  supportscoupons: boolean;
  /**
   * Whether or not the restaurant accepts any loyalty schemes.
   */
  supportsloyalty: boolean;
  /**
   * List of card types (full names) the restaurant supports, delimited by '/'.
   */
  supportedcardtypes: string;
  /**
   * Whether or not the restaurant supports manual fire orders. This must be true for both the restaurant and the brand in order for the restaurant to support them. Manual Fire is an order time mode that allows orders to be fired at a precise desired moment.
   */
  supportsmanualfire: boolean;
  /**
   * Whether or not the restaurant supports the handoff mode "Delivery" (ie. in-house delivery) for orders. Please note that this does not consider whether or not the restaurant supports the handoff mode "Dispatch".
   */
  candeliver: boolean;
  /**
   * Whether or not the restaurant supports the handoff mode "Counter Pickup" (a.k.a. Pickup).
   */
  canpickup: boolean;
  /**
   * Whether or not the restaurant supports the handoff mode "Curbside Pickup".
   */
  supportscurbside: boolean;
  /**
   * Whether or not the restaurant supports the handoff mode "Dispatch".
   */
  supportsdispatch: boolean;
  /**
   * Whether or not the restaurant supports the handoff mode "dinein".
   */
  supportsdinein: boolean;
  /**
   * This is a legacy property that should be ignored.
   */
  hasolopass: boolean;
  /**
   * Description of the area the restaurant delivers to. Please note that this does not consider "Dispatch", only in-house delivery.
   */
  deliveryarea: string;
  /**
   * Minimum total amount for an order to be eligible for delivery. Please note that this does not apply to "Dispatch", only in-house delivery.
   */
  minimumdeliveryorder: number;
  /**
   * Minimum total amount for an order to be eligible for pickup.
   */
  minimumpickuporder: number;
  /**
   * Maximum total amount for any Pay in Store (a.k.a. cash) order. Only relevant if the restaurant supports the billing scheme Pay In Store.
   */
  maximumpayinstoreorder: number;
  /**
   * Fee for delivery. This will return as 0 if the restaurant has multiple fee tiers. Please note that this does not apply to Dispatch, only in-house delivery.
   */
  deliveryfee: number;
  /**
   * List of fee tiers for delivery. Please note that this does not apply to Dispatch, only in-house delivery.
   */
  deliveryfeetiers: DeliveryFeeTier[];
  /**
   * Whether or not the restaurant allows tipping. Please note that a basket may still not allow tips if the associated handoff mode does not support tipping.
   */
  supportstip: boolean;
  /**
   * Whether or not the restaurant allows customers to enter product special instructions for orders. Does not apply to delivery or Dispatch special instructions.
   */
  supportsspecialinstructions: boolean;
  /**
   * Max character length of product special instructions for an order a customer is allowed to enter. Does not apply to delivery or Dispatch special instructions.
   */
  specialinstructionsmaxlength: number;
  /**
   * Whether or not the restaurant allows guests (customers with no user account) to place an order.
   */
  supportsguestordering: boolean;
  /**
   * Whether or not the restaurant requires a phone number to place an order. Please note that for orders placed via the Ordering API, this requirement is not enforced. However, if this field comes back as "true", please try to send a contact number if possible.
   */
  requiresphonenumber: boolean;
  /**
   * Whether or not the restaurant supports online ordering. If false, you will not be able to create an online order at this restaurant.
   */
  supportsonlineordering: boolean;
  /**
   * Whether or not a brand wants to surface their menu even when online ordering is disabled.
   */
  supportsnationalmenu: boolean;
  /**
   * Whether or not the restaurant allows user feedback.
   */
  supportsfeedback: boolean;
  /**
   * Whether or not the restaurant allows split (a.k.a. multiple) payments for orders.
   */
  supportssplitpayments: boolean;
  /**
   * Olo's configured name for the restaurant represented by a URL friendly slug.
   */
  slug: string;
  /**
   * Whether or not the restaurant is available to take orders. If the restaurant is temporarily unavailable or is unable to connect to its POS, this field will be "false". If this field is "false" at the time an order is placed, the order is guaranteed to fail. Please note that this field functions the same as the vendoronline field for baskets associated with the restaurant.
   */
  isavailable: boolean;
  /**
   * Whether or not the restaurant is open based on the business hours. If the restaurant is open, this field will be "true" else "false". This also respects business hours overrides.
   */
  iscurrentlyopen: boolean;
  /**
   * Whether or not the restaurant supports group orders. If this is "false", group orders cannot be created at the restaurant nor can a basket associated with the restaurant be used in a group order.
   */
  supportsgrouporders: boolean;
  /**
   * Whether or not the restaurant allows customers to set a recipient name for each product ordered. Some POS systems will recognize these names and allow them to be printed out on the receipt.
   */
  supportsproductreciepientnames: boolean;
  /**
   * Whether or not the restaurant supports basket transfers to another restaurant.
   */
  supportsbaskettransfers: boolean;
  /**
   * Whether or not an order's handoff mode can be changed at the time the order is to be fired down to the POS. For Manual Fire orders only.
   */
  allowhandoffchoiceatmanualfire: boolean;
  /**
   * Label used to describe the recipient name attached to a product. The label can be configured by each brand.
   */
  productrecipientnamelabel: boolean;
  /**
   * Customer facing message that the restaurant wishes to display. Typically used for greetings, deal notifications, and any extraordinary circumstances.
   */
  customerfacingmessage: string;
  /**
   * Customer facing message that indicates if the restaurant is temporarily closed or has online ordering temporarily disabled.
   */
  availabilitymessage: string;
  /**
   * Whether or not the restaurant supports the handoff mode "Drive-Thru".
   */
  supportsdrivethru: boolean;
  /**
   * Whether or not the restaurant wants to display calorie information for products. When false, calorie information is omitted from the menu and product endpoints.
   */
  showcalories?: boolean;
  /**
   * Whether or not the restaurant accepts orders until closing.
   */
  acceptsordersuntilclosing: boolean;
  /**
   * Whether or not the restaurant accepts orders before opening.
   */
  acceptsordersbeforeopening: boolean;
  /**
   * The suggested tip percentage as set by the brand.
   */
  suggestedtippercentage: number;
  /**
   * List of custom fields defined for the restaurant.
   */
  customfields: RestaurantCustomField[];
  /**
   * List of custom labels for handoff modes and thank you messages as determined by the restaurant's brand.
   */
  labels: RestaurantCustomLabel[];
  /**
   * Restaurant metadata (array of key/value pairs) that has a very limited use case.
   */
  metadata: MetadataItem[];
  /**
   * List of time modes supported by the restaurant.
   */
  supportedtimemodes: TimeModeType[];
  /**
   * List of location attributes for the restaurant. These are generally configured by the brand on a per-restaurant basis.
   */
  attributes: string[];
  /**
   * List of countries supported by the restaurant.
   */
  supportedcountries: string[];
  /**
   * List of handoff modes that support sending a message along with an arrival notification.
   */
  supportedarrivalmessagehandoffmodes: string[];
  /**
   * List of restaurant operating hours for multiple types of calendars over the date range specified in the request.
   */
  calendars: RestaurantCalendar[];
}

export interface DeliveryFeeTier {
  /**
   * Type of fee.
   */
  feetype: DeliveryFeeType;
  /**
   * Amount of the fee.
   */
  amount: number;
  /**
   * Minimum value of the order subtotal for the fee to be in effect.
   */
  subtotalminimum: number;
}

export interface RestaurantCustomField {
  /**
   * Unique id for the restaurant custom field.
   */
  id: number;
  /**
   * Label for the restaurant custom field.
   */
  label: string;
  /**
   * Whether or not the custom field is required to have a value set when placing an order at the restaurant.
   */
  required: boolean;
  /**
   * Regular expression used to validate the value. If the desired custom field value does not match the validationregex, the API will return an error.
   */
  validationregex: string;
  /**
   * Which types of orders the custom field applies to.
   */
  qualificationcriteria: QualificationCriteriaType;
}

export interface RestaurantCustomLabel {
  key: string;
  value: string;
}

export interface RestaurantCalendar {
  /**
   * Display label for the calendar.
   */
  label?: string;
  /**
   * Type of calendar.
   */
  type: CalendarType;
  /**
   * Time ranges the calendar applies to.
   */
  ranges: TimeRange[];
}

export interface TimeRange {
  /**
   * Start date time formatted "yyyymmdd hh:mm". This date time is local to the restaurant.
   */
  start: string;
  /**
   * End date time formatted "yyyymmdd hh:mm". This date time is local to the restaurant.
   */
  end: string;
  /**
   * Abbreviated day of the week.
   */
  weekday: string;
}

export interface FindNearbyParticipatingRestaurantsWithInHouseDeliveryResponse
  extends FindNearbyParticipatingRestaurantsResponse {}

export interface FindRestaurantsByTelephoneNumberResponse {
  restaurants: ResponseRestaurant[];
}

export interface RetrieveSingleRestaurantInformationResponse
  extends ResponseRestaurant {}

export interface RetrieveSingleRestaurantInformationByBrandSpecificStoreNumberResponse
  extends ResponseRestaurant {}

export interface RetrieveSingleRestaurantBySlugResponse
  extends ResponseRestaurant {}

export interface CheckRestaurantDeliveryRequest {
  handoffmode: HandoffMode;
  timewantedmode: TimeModeType;
  timewantedutc?: string;
  street: string;
  city: string;
  zipcode: string;
}

export interface CheckRestaurantDeliveryResponse {
  candeliver: boolean;
  message: string;
}

export interface RetrieveAllParticipatingRestaurantsResponse
  extends FindRestaurantsByTelephoneNumberResponse {}

export interface RetrieveRestaurantOperatingHoursResponse {
  calendar: RestaurantCalendar[];
}

export interface AvailableTimeWantedTime {
  time: string;
}

export interface RetrieveAvailableTimeWantedTimesResponse {
  times: AvailableTimeWantedTime[];
}
