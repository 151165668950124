import { Component, Input, OnInit } from '@angular/core';
import { Reward } from '@models/index';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { RewardDetailsModalComponent } from '../reward-details-modal/reward-details-modal.component';

@Component({
  selector: 'app-reward-card',
  templateUrl: './reward-card.component.html',
  styleUrls: ['reward-card.component.scss'],
})
export class RewardCardComponent implements OnInit {
  bsModalRef?: BsModalRef;
  @Input() isSelected = true;
  @Input() rewardCardStyle: 'small' | 'large' | 'horizontal' = 'small';
  @Input() isUnlocked = true;
  @Input() viewDetails = true;
  @Input() reward!: Reward;

  constructor(private modalService: BsModalService) {}
  ngOnInit() {}

  openRewardDetailsModal() {
    const initialState: ModalOptions = {
      initialState: {
        reward: this.reward,
      },
    };
    this.bsModalRef = this.modalService.show(
      RewardDetailsModalComponent,
      initialState,
    );
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
