import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'oloDate',
})
export class OloDatePipe implements PipeTransform {
  transform(value: string, utcOffset?: number): Date {
    const date = moment(value, 'YYYYMMDD HH:mm');
    if (utcOffset !== undefined) {
      date.utcOffset(utcOffset, false);
    }
    return date.toDate();
  }
}
