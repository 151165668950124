import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CustomPage, GlobalStateModel } from '@models/index';
import { Store } from '@ngxs/store';
import { map, Observable, of, switchMap, take } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomPageResolver implements Resolve<CustomPage> {
  constructor(private store: Store) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<CustomPage> {
    return this.store
      .select((state: GlobalStateModel) => state.content.customPages)
      .pipe(
        filter((customPages) => customPages !== null),
        take(1),
        map((customPages) => {
          return customPages!.find(
            (customPage) => customPage.slug === route.params['customPageID'],
          )!;
        }),
      );
  }
}
