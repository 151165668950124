<div
  class="location-transition location-wrapper d-flex flex-column align-items-center gap-3"
  [ngClass]="selectedLocation ? 'location-open  p-3' : 'location-closed'">
  <ng-container *ngIf="selectedLocation">
    <div style="display: grid; grid-template-columns: 1fr auto; gap: 0.5rem">
      <img
        src="assets/images/location-specific_illustrations/Icons/asset-1.svg"
        style="height: 80px; width: auto" alt="City skyline icon"/>
      <img
        class="w-100 h-auto cursor-pointer"
        [class.d-none]="!!!(user$ | async)"
        style="max-height: 24px"
        [src]="
          (selectedLocation | favoriteLocation : (favoriteLocations$ | async)!)
            ? 'assets/images/icons/SVG/heart-filled.svg'
            : 'assets/images/icons/SVG/heart.svg'
        "
        (click)="toggleFavoriteLocation(selectedLocation)"
        alt="Favorited Location" />
    </div>

    <div class="card">
      <h4 class="h4 card-header bg-light">{{ selectedLocation.name }}</h4>
      <div class="card-body">
        <div class="d-flex gap-1">
          <span
            class="badge text-white rounded-pill px-2"
            [ngClass]="
              selectedLocation.iscurrentlyopen
                ? 'badge-success'
                : 'badge-danger'
            ">
            {{ selectedLocation.iscurrentlyopen ? 'OPEN' : 'CLOSED' }}
          </span>
          <small class="text-success" *ngIf="closingTime && selectedLocation.iscurrentlyopen">
            Open Until
            {{
              closingTime
                | date
                  : 'shortTime'
                  : this.selectedLocation!.utcoffset.toString()
            }}
          </small>
        </div>
        <div>{{ selectedLocation.streetaddress }}</div>
        <div>
          {{ selectedLocation.city }}, {{ selectedLocation.state }}
          {{ selectedLocation.zip }}
        </div>
        <div>{{ selectedLocation.crossstreet }}</div>
        <div class="d-flex flex-row">
          <a
            [href]="selectedLocation | mapsLink"
            target="_blank"
            class="btn-link text-success p-0 mr-3"
            >Get Directions</a
          >
          <ng-container *ngIf="selectedLocation.content.supports_catering">
            <a class="btn-link text-primary p-0" target="_blank" [href]="selectedLocation.content.catering_link_override ? selectedLocation.content.catering_link_override : 'https://catering.freebirds.com'">
              Order Catering
            </a>
          </ng-container>
        </div>
        <div *ngIf="selectedLocation.supportsdrivethru">
          <span class="badge rounded-pill bg-dark text-light px-2"
            >Mobile Pickup Lane</span
          >
        </div>
      </div>
    </div>
    <ng-container *ngIf="selectedLocation.isavailable; else locationOffline">
      <div class="form-check" *ngIf="selectedLocation.supportsgrouporders">
        <label class="form-check-label" for="groupOrder">
          <input
            type="checkbox"
            id="groupOrder"
            value="groupOrder"
            class="form-check-input"
            [formControl]="groupOrder" />
          <div class="d-flex flex-column">
            <b>Create a Group Order</b>
            <small>
              Ordering for multiple people? Try out group ordering.
            </small>
          </div>
        </label>
      </div>
      <app-button
        classes="btn btn-secondary w-100"
        (buttonClick)="startOrder(selectedLocation.id)"
        [isLoading]="startOrderLoading">
        Start Order
      </app-button>
      <ng-container *ngIf="individualLocation">
        <button class="btn btn-outline-secondary w-100" routerLink="/our-menu">
          View Menu
        </button>
      </ng-container>
      <ng-container *ngIf="selectedLocation.content?.has_text_to_order">
        <app-button
          classes="btn btn-outline-secondary w-100"
          (buttonClick)="openTextToOrder()"
          >Text Your Order
        </app-button>
      </ng-container>
    </ng-container>
    <ng-template #locationOffline>
      <div class="text-danger font-weight-bold">
        Online Ordering Temporarily Unavailable
      </div>
    </ng-template>
    <ng-container *ngIf="selectedLocation.content.image">
      <img [src]="selectedLocation?.content?.image | cmsAsset" [alt]="selectedLocation.content.name"
           class="w-100 h-auto mt-3 location-img"/>
    </ng-container>
    <app-restaurant-details-accordion
      [restaurant]="selectedLocation"></app-restaurant-details-accordion>
  </ng-container>
  <ng-container *ngIf="!individualLocation">
    <button
      class="btn btn-outline-secondary mt-auto"
      (click)="locationClose.emit()">
      Close
    </button>
  </ng-container>
  <ng-container *ngIf="individualLocation">
    <button class="btn btn-outline-secondary mt-auto" routerLink="/locations">
      Find Nearby Freebirds
    </button>
  </ng-container>
</div>
