import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DeliveryAddressConfirmModalComponent } from '@common/components';
import {
  Category,
  GroupOrder,
  Menu,
  Order,
  Restaurant,
  SavedAddress,
  SavedProduct,
  User,
} from '@models/index';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-start-order',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './start-order.component.html',
  styleUrls: ['start-order.component.scss'],
})
export class StartOrderComponent implements OnInit {
  @Input() orderLocation!: Restaurant;
  @Input() order!: Order;
  @Input() savedProducts!: SavedProduct[] | null;
  @Input() groupOrder?: GroupOrder | null;
  @Input() isGroupOrderOwner?: boolean;
  @Input() user?: User;

  errorMessage: string | null = null;

  constructor(private bsModalService: BsModalService) {}
  ngOnInit() {}

  addressSelect(address: SavedAddress) {
    this.errorMessage = null;
    // eslint-disable-next-line max-len
    // this.store.dispatch(new FindDeliveryLocation(HandoffMode.DISPATCH, address.building, address.streetaddress, address.city, address.zipcode))
    this.bsModalService.show(DeliveryAddressConfirmModalComponent, {
      initialState: {
        address,
      },
    });
  }

  editDeliveryAddress(order: Order) {
    const address: SavedAddress = {
      id: 0,
      building: order.deliveryaddress.building,
      streetaddress: order.deliveryaddress.streetaddress,
      city: order.deliveryaddress.city,
      zipcode: order.deliveryaddress.zipcode,
      phonenumber: '',
      specialinstructions: order.deliveryaddress.specialinstructions,
      isdefault: false,
    };
    this.addressSelect(address);
  }
}
