import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DeliveryAddressConfirmModalComponent,
  MealNameModalComponent,
} from '@common/components';
import type {
  AvailableReward,
  Category,
  GlobalStateModel,
  GroupOrder,
  Order,
  Restaurant,
  SavedAddress,
  User,
} from '@models/index';
import { Select, Store } from '@ngxs/store';
import { BasketProduct, HandoffMode } from '@server/vendor/olo/interfaces';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { CloseCart } from '@store/actions/app.actions';
import {
  CompleteGroupOrder,
  GetCurrentGroupOrder,
  RemoveFromOrder,
  SetAvailableRewards,
  SetTip,
  UpdateOrderItem,
} from '@store/actions/order.actions';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-cart',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './cart.component.html',
  styleUrls: ['cart.component.scss'],
})
export class CartComponent implements OnInit, OnDestroy {
  @Input() hasItems = false;

  @Input() isUser = false;

  @Select((state: GlobalStateModel) => state.app.cartOpen)
  cartVisibility$!: Observable<boolean>;

  @Select((state: GlobalStateModel) => state.order.order)
  order$!: Observable<Order>;

  @Select((state: GlobalStateModel) => state.locations.orderLocation)
  orderLocation$!: Observable<Restaurant>;

  @Select((state: GlobalStateModel) => state.user.user)
  user$!: Observable<User>;

  @Select((state: GlobalStateModel) => state.order.availableRewards)
  availableRewards$!: Observable<AvailableReward[]>;

  @Select((state: GlobalStateModel) => state.order.groupOrder)
  groupOrder$!: Observable<GroupOrder>;
  @Select((state: GlobalStateModel) => state.order.ownsGroupOrder)
  isGroupOrderHost$!: Observable<boolean>;
  @Select((state: GlobalStateModel) => state.order.groupOrderName)
  groupOrderName$!: Observable<string>;

  recipients: string[] = [];

  viewAllRewards: boolean = false;

  private subs: Subscription[] = [];

  constructor(
    private store: Store,
    public route: ActivatedRoute,
    public location: Location,
    private router: Router,
    private toast: ToastrService,
    private modalService: BsModalService,
    private analytics: AnalyticsService,
  ) {}
  ngOnInit() {
    this.subs.push(
      this.order$
        .pipe(filter((o) => o && !!o.products.length))
        .subscribe((order) => {
          // this.recipients = [];
          // order.products.forEach((product) => {
          //   if (!this.recipients.includes(product.recipient)) {
          //     this.recipients.push(product.recipient);
          //   }
          // });
          this.store
            .selectOnce((state: GlobalStateModel) => state.user.tokens)
            .pipe(filter((t) => t !== null))
            .subscribe((tokens) => {
              this.store.dispatch(
                new SetAvailableRewards(
                  order.id,
                  tokens!.tokens.ordering.token,
                ),
              );
            });
        }),
      this.cartVisibility$.subscribe((visible) => {
        if (visible) {
          const order = this.store.selectSnapshot(
            (state: GlobalStateModel) => state.order.order,
          );
          this.analytics.logCartPageView(
            this.store.selectSnapshot(
              (state: GlobalStateModel) => state.user.user,
            )!,
            order?.products && order.products.length
              ? order.products[order.products.length - 1]
              : undefined,
          );
        }
        const groupOrder = this.store.selectSnapshot(
          (state: GlobalStateModel) => state.order.groupOrder,
        );
        if (groupOrder && visible) {
          this.store.dispatch(
            new GetCurrentGroupOrder(
              groupOrder.id,
              undefined,
              groupOrder.basket.id,
            ),
          );
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  closeCart() {
    this.store.dispatch(new CloseCart());
  }

  removeProduct(product: BasketProduct) {
    this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .subscribe(async (order) => {
        const state: GlobalStateModel = await this.store
          .dispatch(new RemoveFromOrder(order!.id, product.id))
          .toPromise();
        if (order?.allowstip) {
          const tipSettings = this.store.selectSnapshot(
            (state: GlobalStateModel) => state.content.tipSettings,
          );
          await this.store
            .dispatch(
              new SetTip(
                order!.id,
                order.subtotal *
                  (order.deliverymode === HandoffMode.DISPATCH
                    ? tipSettings?.default_delivery_tip ?? 0.2
                    : tipSettings?.default_pickup_tip ?? 0.2),
              ),
            )
            .toPromise();
        }
      });
  }

  editProduct(basketProduct: BasketProduct) {
    this.store
      .selectOnce((state: GlobalStateModel) => state.menu.menu)
      .subscribe((menu) => {
        const category: Category = menu!.categories.find((c) =>
          c.products.find((p) => p.id === basketProduct.productId),
        )!;
        if (!category) {
          this.toast.error('Cannot edit, Could not find product in menu.');
          return;
        }
        this.router.navigate(['order', 'customize', category.id], {
          queryParams: { edit: basketProduct.id },
        });
      });
  }

  updateRecipient(basketProduct: BasketProduct) {
    const modalRef = this.modalService.show(MealNameModalComponent, {
      initialState: {
        nameEditOnly: true,
        initialName: basketProduct.recipient,
      },
    });
    modalRef.content!.finishClicked.subscribe((output) => {
      this.store.dispatch(
        new UpdateOrderItem(
          this.store.selectSnapshot(
            (state: GlobalStateModel) => state.order.order,
          )!.id,
          basketProduct.id,
          basketProduct.productId,
          basketProduct.quantity,
          basketProduct.choices.map((c) => c.optionid.toString()),
          basketProduct.specialinstructions,
          output.name,
        ),
      );
      modalRef.hide();
    });
  }

  toggleViewAllRewards(): void {
    this.viewAllRewards = !this.viewAllRewards;
  }

  completeGroupOrder() {
    this.store
      .dispatch([new CloseCart(), new CompleteGroupOrder()])
      .subscribe(() => {
        this.toast.success(
          'Thank you for adding your items. Please let the host know you are done.',
        );
      });
  }

  editDeliveryAddress(order: Order) {
    const address: SavedAddress = {
      id: 0,
      building: order.deliveryaddress.building,
      streetaddress: order.deliveryaddress.streetaddress,
      city: order.deliveryaddress.city,
      zipcode: order.deliveryaddress.zipcode,
      phonenumber: '',
      specialinstructions: order.deliveryaddress.specialinstructions,
      isdefault: false,
    };
    this.addressSelect(address);
  }

  addressSelect(address: SavedAddress) {
    this.store.dispatch(new CloseCart());
    this.modalService.show(DeliveryAddressConfirmModalComponent, {
      initialState: {
        address,
        isCheckout: true,
      },
    });
  }
}
