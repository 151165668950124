<div class="page-not-found d-flex flex-column">
  <app-navbar></app-navbar>
  <section
    class="container py-5 h-100 d-flex flex-column justify-content-center grow-1">
    <div class="d-flex flex-column text-center justify-content-center">
      <h1 class="display-1">404</h1>
      <h2 class="h2">Page Not Found</h2>
      <p class="">
        Looks like you got lost.
        <span class="cursor-pointer text-primary" routerLink="/" fragment="menu"
          >Head this direction to get back to the burritos.</span
        >
      </p>
      <div class="d-flex justify-content-center">
        <img
          class="not-found-image"
          alt="Lost in the desert"
          src="assets/images/location-specific_illustrations/Icons/asset-2.svg" />
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
