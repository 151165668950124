<div class="monster-container">
  <div class="foreground">
    <div class="position-relative container">
      <p
        class="monster-body os-body-copy text-card-carbon hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeIn animate__slow">
        <b>More signature proteins and ingredients</b> than any other burritos
        joint. Real fanatics know about our secret off-menu size:
        <b>Super Monster</b>.
      </p>

      <div
        class="bowl-img-container hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__slideInLeft animate__slow">
        <img
          alt="Freebirds salad bowl with guacamole"
          class="bowl"
          src="/assets/images/our-story/os-bowl-angled-min.png" />
      </div>

      <div class="burrito-img-container">
        <img
          alt="Freebirds burrito"
          class="burrito hide-on-init"
          animateOnScroll
          animationName="animate__animated animate__slideInRight animate__slow"
          src="/assets/images/our-story/os-burrito-angled-min.png" />
      </div>
    </div>

    <div class="midground">
      <div
        class="container position-relative hide-on-init"
        animateOnScroll
        animationName="animate__animated animate__fadeIn animate__slow">
        <img
          alt="Monster sizes for Texas appetites"
          class="title-img"
          src="/assets/images/our-story/monster-sizes-title.svg" />
      </div>
    </div>

    <div
      class="background hide-on-init"
      animateOnScroll
      animationName="animate__animated animate__slideInUp animate__slow">
      <img
        alt="Wood texture background"
        class="wood-img"
        src="/assets/images/our-story/wood-floor.png" />
    </div>
  </div>
</div>
