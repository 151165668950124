import { Pipe, PipeTransform } from '@angular/core';
import { FAQ, FAQSectionsQuestions } from '@models/index';
import { GetFAQSectionsResponse } from '@server/content/responses';

import { environment } from '../environments/environment';

@Pipe({
  name: 'faqFilter',
})
export class FaqFilterPipe implements PipeTransform {
  transform(questions: FAQSectionsQuestions[]): FAQSectionsQuestions[] {
    return questions.filter(
      (q) =>
        !(
          q.item.status === 'draft' &&
          environment.apiBaseURL.includes('freebirds.com')
        ),
    ) as FAQSectionsQuestions[];
  }
}
