import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { FavoriteLocation } from '@models/index';
import { ProfileService } from '@modules/profile/services';

@Component({
  selector: 'app-fave-location-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './fave-location-card.component.html',
  styleUrls: ['fave-location-card.component.scss'],
})
export class FaveLocationCardComponent {
  @Input() location!: FavoriteLocation;

  removeLoading = false;
  startOrderLoading = false;

  constructor(private profile: ProfileService, private router: Router) {}

  removeFavorite(locationID: number): void {
    this.removeLoading = true;
    this.profile.removeFavorite(locationID).subscribe(() => {});
  }

  startOrder(locationID: number): void {
    this.startOrderLoading = true;
    this.profile.startOrder(locationID).subscribe(() => {
      this.router
        .navigate(['/'], { fragment: 'menu' })
        .then(() => (this.startOrderLoading = false));
    });
  }
}
