<div class="d-flex flex-column align-items-center bg-dark w-100 p-3">
  <ng-container *ngIf="groupOrderHost; else groupOrderGuest">
    <ng-container *ngIf="!groupOrder; else groupOrderInvite">
      <small class="font-weight-bold text-light mb-2">
        Ordering for Multiple People?
      </small>
      <app-button
        [classes]="'btn btn-outline-light btn-sm w-fit-content'"
        routerLink="/order/group-order">
        Create Group Order
      </app-button>
    </ng-container>
    <ng-template #groupOrderInvite>
      <div
        class="d-flex flex-column align-items-center gap-2 w-100 text-center">
        <div class="d-flex flex-row flex-nowrap gap-3 w-100">
          <div class="text-light grow-1 font-weight-bold text-left">
            <span>Order Deadline:</span>
            &nbsp;
            <span class="text-primary">{{
              groupOrder!.deadline! | oloDate | date: 'shortTime'
            }}</span>
          </div>
          <button
            type="button"
            class="btn btn-link btn-small p-0 text-capitalize text-end text-light"
            routerLink="/order/group-order"
            fragment="update">
            Edit Order
          </button>
        </div>
        <!--        <small class="font-weight-bold m-0 text-light">-->
        <!--          Invite others to your group by sharing this link:-->
        <!--        </small>-->
        <!--        <small class="px-2 py-2 bg-white w-100 rounded">-->
        <!--          {{ textToCopy }}-->
        <!--        </small>-->
        <div
          class="d-flex flex-row flex-nowrap justify-content-center w-100 gap-2">
          <app-button
            [classes]="'btn btn-outline-light btn-sm w-fit-content'"
            (click)="copyText()">
            Copy Invite Link
          </app-button>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #groupOrderGuest>
    <ng-container *ngIf="groupOrder">
      <div class="d-flex flex-column align-items-center gap-2 w-100">
        <div class="d-flex flex-row flex-nowrap gap-3 w-100">
          <div class="text-light grow-1 font-weight-bold">
            <span>Order Deadline:</span>
            &nbsp;
            <span class="text-primary">{{
              groupOrder!.deadline! | oloDate | date: 'shortTime'
            }}</span>
          </div>
          <button
            type="button"
            class="btn btn-link btn-small p-0 text-capitalize text-end text-light"
            (click)="openGOLeaveModal()">
            Leave Group
          </button>
        </div>
        <small
          class="d-flex flex-column gap-1 alert alert-info px-2 py-1 w-100"
          *ngIf="groupOrder!.note">
          <b>Message From the Host:</b>
          {{ groupOrder!.note }}
        </small>
      </div>
    </ng-container>
  </ng-template>
</div>
