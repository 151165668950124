import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Category, GlobalStateModel, Menu, Product } from '@models/index';
import { Store } from '@ngxs/store';
import { MenuService } from '@services/api/menu.service';
import { SetCategory, SetProduct } from '@store/actions/menu.actions';
import { Observable } from 'rxjs';

@Injectable()
export class AppCommonService {
  constructor(
    private store: Store,
    private router: Router,
    private menu: MenuService,
  ) {}

  getProductCategory(productID: number): Category | undefined {
    // get menu from state and find category that contains a product with the given productID
    const menu: Menu = this.store.selectSnapshot((state) => state.menu.menu);
    return menu.categories.find((category: Category) =>
      category.products.find((product) => product.id === productID),
    );
  }

  navigateToProduct(
    category: Category,
    product: Product,
    scrollToProduct = false,
  ): void {
    const orderLocation = this.store.selectSnapshot(
      (state: GlobalStateModel) => state.locations.orderLocation,
    )!;
    this.store
      .dispatch([
        new SetCategory(orderLocation.id, category.id),
        new SetProduct(orderLocation.id, product.chainproductid, true),
      ])
      .subscribe(() =>
        this.router.navigate(['order', 'customize', category.id]).then(() => {
          if (scrollToProduct) {
            setTimeout(() => {
              let count = 0;
              let interval = setInterval(() => {
                if (document.getElementById('ingredient-start')) {
                  if (count > 0) {
                    document
                      .getElementById('ingredient-start')!
                      .scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      });
                    clearInterval(interval);
                  }
                  count++;
                } else {
                  count = 0;
                }
              }, 200);
            }, 500);
          }
        }),
      );
  }

  getProduct(chainID: number): Observable<Product> {
    const orderLocation = this.store.selectSnapshot(
      (state: GlobalStateModel) => state.locations.orderLocation,
    )!;
    return this.menu.getProduct(orderLocation.id, chainID, true);
  }
}
