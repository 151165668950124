<app-navbar></app-navbar>

<!-- points header -->
<ng-container *ngIf="user$ | async as user; else guestRewards">
  <ng-container *ngIf="pointBalance$ | async as pointBalance">
    <section class="bg-light my-rewards-container">
      <div
        class="container py-5 d-flex flex-column flex-md-row flex-nowrap gap-3 position-relative">
        <div
          class="d-flex align-items-center grow-1 gap-3 justify-content-center justify-content-md-start pb-3">
          <div
            class="d-flex flex-column flex-md-row align-items-center justify-content-start gap-3">
            <img
              class="points-icon"
              src="/assets/images/icons/Icons/fb-ribbon.svg"
              alt="" />
            <div
              class="d-flex flex-column align-items-center align-items-md-start">
              <h3 class="text-uppercase">Available Points:</h3>
              <h1
                class="display-4 font-mpi-gothic points-title d-flex text-nowrap">
                {{ pointBalance.redeemable_points }} Points
              </h1>
              <button
                routerLink="/rewards/account-activity"
                type="button"
                class="rounded points-history-btn p-0">
                <div>Points Transaction History</div>
              </button>
            </div>
          </div>
        </div>

        <div
          class="d-flex justify-content-center justify-content-md-end grow-1">
          <div
            class="member-card border d-flex flex-column gap-3 bg-white p-3 rounded w-100 position-relative">
            <small class="text-muted">Member ID: {{ user.user_id }}</small>
            <div>
              <div class="text-uppercase text-muted">Current Level:</div>
              <div class="d-flex flex-nowrap align-items-center gap-1">
                <h3>{{ pointBalance.current_membership_level_name }}</h3>
                <span
                  class="d-flex text-primary"
                  [tooltip]="loyaltyLevels"
                  placement="auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-patch-question"
                    viewBox="0 0 16 16">
                    <path
                      d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.71 1.71 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745z" />
                    <path
                      d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                    <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" />
                  </svg>
                </span>
              </div>
              <div class="text-uppercase text-muted">
                Lifetime Points Earned:
                <b>{{ pointBalance.lifetime_points }}</b>
              </div>
            </div>
            <img
              class="member-card-icon"
              src="assets/images/icons/Icons/fb-perks-solid.svg"
              alt="" />

            <ng-container
              *ngIf="pointBalance.current_membership_level_name !== 'LEGEND'">
              <div class="d-flex flex-column gap-1">
                <div class="d-flex flex-column">
                  <h5>
                    Next Level:
                    {{
                      pointBalance.lifetime_points < 150
                        ? 'HEADLINER'
                        : pointBalance.lifetime_points < 500
                        ? 'ROCKSTAR'
                        : pointBalance.lifetime_points < 800
                        ? 'LEGEND'
                        : 'LEGEND'
                    }}
                  </h5>
                  <div>
                    Earn
                    {{
                      pointBalance.lifetime_points < 150
                        ? 150 - pointBalance.lifetime_points
                        : pointBalance.lifetime_points < 500
                        ? 500 - pointBalance.lifetime_points
                        : pointBalance.lifetime_points < 800
                        ? 800 - pointBalance.lifetime_points
                        : 0
                    }}
                    more points to become a
                    {{
                      pointBalance.lifetime_points < 150
                        ? 'headliner'
                        : pointBalance.lifetime_points < 500
                        ? 'rockstar'
                        : pointBalance.lifetime_points < 800
                        ? 'legend'
                        : 'legend'
                    }}
                  </div>
                </div>
                <progressbar
                  class="rounded"
                  [value]="
                    pointBalance.lifetime_points < 150
                      ? (pointBalance.lifetime_points / 150) * 100
                      : pointBalance.lifetime_points < 500
                      ? (pointBalance.lifetime_points / 500) * 100
                      : pointBalance.lifetime_points < 800
                      ? (pointBalance.lifetime_points / 800) * 100
                      : 0
                  "
                  [striped]="true"
                  [animate]="true"></progressbar>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
  <app-my-rewards-container
    [rewards]="(rewards$ | async)!"
    [pointBalances]="(pointBalance$ | async)!">
  </app-my-rewards-container>
</ng-container>

<app-footer></app-footer>

<ng-template #loyaltyLevels>
  <div>INDIE – 0-150</div>
  <div>HEADLINER – 151-500</div>
  <div>ROCKSTAR – 501-800</div>
  <div>LEGEND – 801+</div>
</ng-template>

<ng-template #guestRewards>
  <app-guest-rewards-jumbotron></app-guest-rewards-jumbotron>
  <app-download-app-bar></app-download-app-bar>
  <app-rewards-perks-container></app-rewards-perks-container>
  <app-rewards-how-to-block></app-rewards-how-to-block>
  <app-rewards-faq-bar></app-rewards-faq-bar>
</ng-template>

<button
  *ngIf="!isAtTop"
  (click)="scrollToTop()"
  class="scroll-to-top-button"
  [@slideInRightOnEnter]
  [@slideOutRightOnLeave]
  [@fadeOutOnLeave]
  [@fadeInOnEnter]>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-arrow-up"
    viewBox="0 0 16 16">
    <path
      fill-rule="evenodd"
      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
  </svg>
</button>
