<ng-container [ngSwitch]="footerStyle">
  <footer *ngSwitchCase="'simplified'" class="footer-shell flex-column flex-md-row flex-nowrap py-4 py-md-2">
    <div class="container flex-column d-flex flex-md-row flex-nowrap gap-3">
      <div class="d-flex flex-column flex-md-row flex-nowrap gap-3 grow-1">
        <a href="https://careers.freebirds.com/" target="_blank" class="footer-links">
          <h6 class="footer-links-text">Careers</h6>
        </a>
        <a routerLink="/our-story" class="footer-links">
          <h6 class="footer-links-text">Our Story</h6>
        </a>
        <a routerLink="/our-menu" class="footer-links">
          <h6 class="footer-links-text">Menu</h6>
        </a>
        <a href="https://shop.freebirds.com/freebirds-merchandise/gift-cards.html" target="_blank" class="">
          <h6 class="footer-links-text">Gift Cards</h6>
        </a>
        <a routerLink="/community" class="footer-links">
          <h6 class="footer-links-text">Community</h6>
        </a>
        <a routerLink="/contact-us" class="footer-links">
          <h6 class="footer-links-text">Contact</h6>
        </a>
        <a routerLink="/faq" class="footer-links">
          <h6 class="footer-links-text">FAQ</h6>
        </a>
      </div>
      <div class="d-flex justify-content-center">
        <a routerLink="/legal" class="footer-links">
          <h6 class="footer-links-text">Legal</h6>
        </a>
      </div>
      <div class="d-flex justify-content-center">
        <a routerLink="/legal" class="footer-links">
          <h6 class="footer-links-text">Privacy Policy</h6>
        </a>
      </div>
    </div>
  </footer>

  <footer *ngSwitchCase="'full'" class="footer-shell flex-column py-4">
    <div class="container">
      <div class="d-flex flex-column flex-md-row gap-4 text-center text-md-left pb-3">
        <a href="https://careers.freebirds.com/" target="_blank" (mouseenter)="careersContent()" (mouseleave)="aboutContent()">
          <h4>Careers</h4>
        </a>
        <a routerLink="/our-story" class="footer-links" (mouseenter)="ourStoryContent()" (mouseleave)="aboutContent()">
          <h4>Our Story</h4>
        </a>
        <a routerLink="/our-menu" (mouseenter)="menuContent()" (mouseleave)="aboutContent()">
          <h4>Menu</h4>
        </a>
        <a href="https://shop.freebirds.com/freebirds-merchandise/gift-cards.html" target="_blank" (mouseenter)="giftCardsContent()" (mouseleave)="aboutContent()">
          <h4>Gift Cards</h4>
        </a>
      </div>
    </div>
    <div class="container">
      <div class="d-flex flex-column flex-md-row gap-4">
        <div class="d-flex flex-column gap-1 grow-2 basis-0 text-center text-md-left">
          <h6>About</h6>
          <div [textContent]="content" style="min-height: 60px">
            Freebirds World Burrito - Texas fast-casual burrito joint with craveable proteins grilled in-house by master grillers. Texas’ No. 1 Burrito.
          </div>
          <div class="mt-auto">
            <img class="full-footer-logo pt-3" src="/assets/images/logos/Freebirds_Logos_Digital_PrimaryLogo_Alabaster_RGB.svg" alt="" />
          </div>
        </div>
        <div class="d-flex flex-column grow-1 basis-0 gap-4 text-center text-md-left">
          <div class="d-flex flex-column flex-md-row grow-1 basis-0 gap-4 text-center text-md-left">
            <div class="d-flex flex-column gap-1 grow-1 basis-0">
              <h6>Categories</h6>
              <ul class="footer-links d-flex flex-column gap-1">
                <li><a href="#menu">Order Now</a></li>
                <li><a href="/locations">Find a Location</a></li>
                <li>
                  <a
                    href="https://catering.freebirds.com/index.cfm?fuseaction=order&action=menu-categories&channel-id=2"
                    target="_blank">Catering</a>
                </li>
              </ul>
            </div>
            <div class="d-flex flex-column gap-1 grow-1 basis-0">
              <h6>Quick Links</h6>
              <ul class="footer-links d-flex flex-column gap-1">
                <li><a routerLink="/community">Community</a></li>
                <li><a routerLink="/contact-us">Contact Us</a></li>
                <li><a routerLink="/legal">Legal</a></li>
                <li><a routerLink="/legal" fragment="privacy">Privacy Policy</a></li>
                <li><a routerLink="/faq">FAQ</a></li>
              </ul>
            </div>
          </div>
          <div class="footer-links d-block d-md-none">
            <a routerLink="/legal/data-request">Do not sell or share my info</a>
          </div>
        </div>
      </div>
      <hr class="mt-4" />
    </div>
    <div class="container">
      <div class="d-flex flex-column flex-md-row gap-3">
        <div class="grow-1 basis-0 d-flex flex-column">

          <p class="copyright-text m-0 mb-2">
            Copyright &copy; {{ currentYear }} All Rights Reserved by
            <a href="#">Freebirds World Burrito</a>.
          </p>
          <div class="footer-links text-center text-md-left d-none d-md-block">
            <i class="bi bi-info-circle"></i>
            <a routerLink="/legal/data-request">Do not sell or share my info</a>
          </div>
        </div>
        <div class="d-flex flex-column flex-md-row flex-nowrap gap-3 justify-content-end">
          <div class="d-flex flex-column flex-sm-row justify-content-center justify-content-md-end align-items-center gap-3">
            <a href="https://apps.apple.com/us/app/freebirds-restaurant/id1079328303" class="d-block mb-2">
              <img class="h-auto" src="/assets/images/icons/Icons/appstore.svg" width="160" role="img" aria-label="Apple app store" alt="Apple app store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.punchh.freebirds&hl=en_US&gl=US" class="d-block mb-2">
              <img class="h-auto" src="/assets/images/icons/Icons/googleplay.svg" width="160" role="img" aria-label="Google play store" alt="Google play store" />
            </a>
          </div>
          <ul class="social-icons">
            <li>
              <a href="https://www.tiktok.com/@freebirds_wb?lang=en" target="_blank" class="tiktok d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16">
                  <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/freebirdsworldburrito/" target="_blank" class="facebook d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/FREEBIRDS_WB?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank" class="twitter d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/freebirdsworldburrito/?hl=en" target="_blank" class="instagram d-flex align-items-center justify-content-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</ng-container>
