import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileService } from '@modules/profile/services';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['unsubscribe.component.scss'],
})
export class UnsubscribeComponent implements OnInit {
  @ViewChild('captchaElem', { static: false })
  captchaElem!: InvisibleReCaptchaComponent;
  captchaForm = new FormGroup({
    captcha: new FormControl(null, Validators.requiredTrue),
  });

  email?: string;
  private userID?: string;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private profileService: ProfileService,
    @Inject(PLATFORM_ID)
    public platformId: Object,
  ) {}
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['email']) {
        this.email = params['email'];
      }
      if (params['userID']) {
        this.userID = params['userID'];
      }
      if (!this.email || !this.userID) {
        this.router.navigate(['/']);
      }
    });
  }

  captchaSuccess(token: string) {
    this.profileService.verifyToken(token).subscribe((res) => {
      this.captchaForm.controls['captcha'].setValue(res.success);
      this.captchaForm.updateValueAndValidity();
    });
  }

  captchaExpired() {
    this.captchaElem.reloadCaptcha();
  }

  captchaReady() {
    this.captchaElem.execute();
  }

  captchaReload() {
    this.captchaElem.execute();
  }

  unsubscribe() {
    if (this.captchaForm.valid) {
      this.profileService
        .unsubscribe(this.email!, this.userID!)
        .subscribe(() => {
          this.router.navigate(['/']);
        });
    }
  }
}
