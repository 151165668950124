import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import {
  BasketChoice,
  BasketProduct,
  OrderStatusProductChoice,
} from '@server/vendor/olo/interfaces';

@Pipe({
  name: 'choicesList',
})
export class ChoicesListPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(choices: OrderStatusProductChoice[] | BasketChoice[]): string {
    return choices
      .map((choice) =>
        'cost' in choice && choice.cost
          ? `${choice.name} (${this.currencyPipe.transform(choice.cost)})`
          : choice.name,
      )
      .join(', ');
  }
}
