import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AllergenCategory, AllergenTag } from '@models/index';

@Component({
  selector: 'app-filter-icon',
  templateUrl: './filter-icon.component.html',
  styleUrls: ['filter-icon.component.scss'],
})
export class FilterIconComponent {
  @Input() allergenCategory!: AllergenCategory;
  @Input() itemAllergenTags: AllergenTag[] = [];
}
