import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'app-os-hero',
  templateUrl: './os-hero.component.html',
  styleUrls: ['os-hero.component.scss'],
})
export class OsHeroComponent implements AfterViewInit {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.listen(document, 'mousemove', (event) => {
        this.parallax(event);
      });
    }
  }

  parallax(event: { pageX: number; pageY: number }) {
    const shifts = this.el.nativeElement.querySelectorAll(
      '.parallax-wrap span',
    );
    shifts.forEach((shift: { getAttribute: (arg0: string) => any }) => {
      const position = shift.getAttribute('value');
      const x = (window.innerWidth - event.pageX * position) / 90;
      const y = (window.innerHeight - event.pageY * position) / 90;

      this.renderer.setStyle(
        shift,
        'transform',
        `translateX(${x}px) translateY(${y}px)`,
      );
    });
  }
}
