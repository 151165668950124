import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomPageResolver } from '@common/resolvers/custom-page.resolver';
import { PipesModule } from '@pipes/pipes.module';
import { ServicesModule } from '@services/services.module';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMaskModule } from 'ngx-mask';

/* Modules */
// import { NavigationModule } from '@modules/navigation/navigation.module';
/* Components */
import * as appCommonComponents from './components';
import { CartComponent, HamburgerMenuComponent } from './components';
/* Containers */
import * as appCommonContainers from './containers';
/* Directives */
import * as appCommonDirectives from './directives';
import { SpyOnDirective } from './directives/spy-on.directive';
import { SpyTargetDirective } from './directives/spy-target.directive';
/* Guards */
import * as appCommonGuards from './guards';
/* Services */
import * as appCommonServices from './services';
import { BadgeComponent } from './components/badge/badge.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    PipesModule,
    NgxMaskModule.forRoot(),
    ServicesModule,
    CarouselModule,
    AlertModule,
    BsDatepickerModule,
    CollapseModule,
    ButtonsModule,
  ],
  providers: [
    ...appCommonServices.services,
    ...appCommonGuards.guards,
    CustomPageResolver,
  ],
  declarations: [
    ...appCommonContainers.containers,
    ...appCommonComponents.components,
    ...appCommonDirectives.directives,
    CartComponent,
    SpyOnDirective,
    SpyTargetDirective,
    BadgeComponent,
  ],
    exports: [
        ...appCommonContainers.containers,
        ...appCommonComponents.components,
        ...appCommonDirectives.directives,
        CartComponent,
        BadgeComponent,
    ],
})
export class AppCommonModule {}
