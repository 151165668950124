<app-navbar></app-navbar>

<section>
  <div class="d-flex flex-column" style="overflow-x: hidden">
    <app-os-hero></app-os-hero>
    <app-os-taste-of-texas></app-os-taste-of-texas>
    <app-os-crave-queso></app-os-crave-queso>
    <app-os-hospitality></app-os-hospitality>
    <app-os-monster-sizes></app-os-monster-sizes>
    <app-os-texas-growth></app-os-texas-growth>
  </div>
</section>

<app-footer></app-footer>

<button
  *ngIf="!isAtTop"
  (click)="scrollToTop()"
  class="scroll-to-top-button"
  [@slideInRightOnEnter]
  [@slideOutRightOnLeave]
  [@fadeOutOnLeave]
  [@fadeInOnEnter]>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-arrow-up"
    viewBox="0 0 16 16">
    <path
      fill-rule="evenodd"
      d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" />
  </svg>
</button>
