<div class="text-center pb-5">
  <h1 class="section-title display-5 text-success">
    {{ collectionGroup.item?.name }}
  </h1>

  <p class="section-description" *ngIf="collectionGroup.item?.subtitle">
    {{ collectionGroup.item!.subtitle! }}
  </p>
</div>

<div class="shell">
  <ngx-masonry [ordered]="true" [options]="{ gutter: 16 }">
    <ng-container
      *ngFor="
        let collectionSubgroup of collectionGroup.item?.subgroups;
        first as first;
        last as last
      ">
      <app-sm-ingredient-group
        ngxMasonryItem
        class="column-width"
        [collectionSubgroup]="collectionSubgroup"
        [first]="first"
        [last]="last"
        *ngIf="collectionSubgroup"></app-sm-ingredient-group>
    </ng-container>
  </ngx-masonry>
</div>
