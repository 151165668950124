import { Pipe, PipeTransform } from '@angular/core';
import { GlobalStateModel } from '@models/index';
import { Store } from '@ngxs/store';
import { BasketProduct } from '@server/vendor/olo/interfaces';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'canEdit',
})
export class CanEditPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(product: BasketProduct): Observable<boolean> {
    return this.getProductCategory(product).pipe(
      map((category) => {
        if (category) {
          return (
            category.content.display_as_product && product.choices.length > 0
          );
        } else {
          return product.choices.length > 0;
        }
      }),
    );
  }

  getProductCategory(product: BasketProduct) {
    return this.store
      .select((state: GlobalStateModel) => state.menu.menu)
      .pipe(
        map((menu) => {
          if (!menu) {
            return null;
          }
          return menu.categories.find((category) =>
            category.products.find((p) => p.id === product.productId),
          );
        }),
      );
  }
}
