<ng-container *ngIf="!isSavedProduct; else savedProductsHeader">
  <div
    class="customize-product-header d-none d-lg-flex"
    [style.background-color]="category?.content?.background_color">
    <div
      class="container d-flex flex-column flex-md-row gap-2 align-items-center px-3 py-4 py-md-3 text-center text-md-left justify-content-between">
      <div class="product-image-shell d-flex justify-content-center">
        <img
          class="product-card"
          alt=""
          [src]="category.content.image | cmsAsset" />
      </div>
      <div class="product-info-shell d-flex flex-column gap-2">

        <ng-container *ngIf="category.content.subtitle">
          <h5 class="mb-2 product-subtitle">
            {{category.content.subtitle}}
          </h5>
        </ng-container>

        <h1 class="font-filmotype">{{ category.name }}</h1>

        <ng-container *ngIf="category.content.category_description && category.content.category_description !== '' else providerDescription">
          <div>
            {{ category.content.category_description }}
          </div>
        </ng-container>

        <ng-template #providerDescription>
          <ng-container *ngIf="category.description">
            <div>
              {{ category.description }}
            </div>
          </ng-container>
        </ng-template>
        <div
          *ngIf="selectedIngredients && selectedIngredients.length"
          class="d-flex flex-row flex-wrap gap-2 py-3">
          <ng-container *ngFor="let ingredient of selectedIngredients">
            <app-ingredient-pill
              [ingredient]="ingredient"
              (pillRemoved)="onOptionRemoved(ingredient)"></app-ingredient-pill>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #savedProductsHeader>
  <ng-container *ngIf="homepage$ | async as homepage">
  <div
    class="customize-product-header customize-product-header d-none d-lg-flex" [style.background-color]="homepage?.my_favorites_background_color ?? '#8bc4d1'">
    <div
      class="container d-flex flex-column flex-md-row gap-2 align-items-center px-3 py-4 py-md-3 text-center text-md-left justify-content-between">
      <div class="product-image-shell d-flex justify-content-center">
        <img
          class="product-card"
          alt=""
          src="assets/images/categories/saved-favorites-option-2.png" />
      </div>
      <div class="product-info-shell d-flex flex-column gap-2">
        <h1 class="font-filmotype">My Favorites</h1>
      </div>
    </div>
  </div>
  </ng-container>
</ng-template>
