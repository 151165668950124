import { Injectable } from '@angular/core';
import {
  AccountActivity,
  FavoriteLocation,
  GlobalStateModel,
  Message,
  NewSavedProduct,
  Offer,
  PastOrder,
  PointsBalance,
  Reward,
  SavedAddress,
  SavedCard,
  SavedProduct,
  Tokens,
  User,
} from '@models/index';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { CreateAccountDto, UpdateUserInfoDto } from '@server/user/dto';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { LoyaltyService } from '@services/api/loyalty.service';
import { UserService } from '@services/api/user.service';
import { SetFullExperience } from '@store/actions/app.actions';
import { ClearOrder } from '@store/actions/order.actions';
import {
  ClearUser,
  CreateAccount,
  CreateSavedProduct,
  DeleteFavoriteLocation,
  DeleteSavedCard,
  DeleteSavedProduct,
  DismissMessage,
  InitializeUser,
  Login,
  LoginWithCode,
  LoginWithFacebook,
  Logout,
  RefreshMessages,
  SaveFavoriteLocation,
  SetDefaultSavedCard,
  SetSavedCards,
  SetSavedProducts,
  UpdateUserInfo,
} from '@store/actions/user.actions';
import {
  catchError,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  throwError,
  toArray,
} from 'rxjs';

export interface UserStateModel {
  tokens: Tokens | null;
  user: User | null;
  pointBalance: PointsBalance | null;
  offers: Offer[] | null;
  activity: AccountActivity[] | null;
  rewards: Reward[] | null;
  messages: Message[] | null;
  savedAddresses: SavedAddress[] | null;
  pastOrders: PastOrder[] | null;
  savedCards: SavedCard[] | null;
  favoriteLocations: FavoriteLocation[] | null;
  savedProducts: SavedProduct[] | null;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    tokens: null,
    user: null,
    pointBalance: null,
    offers: null,
    activity: null,
    rewards: null,
    messages: null,
    savedAddresses: null,
    pastOrders: null,
    savedCards: null,
    favoriteLocations: null,
    savedProducts: null,
  },
})
@Injectable()
export class UserState implements NgxsOnInit {
  constructor(
    private user: UserService,
    private loyalty: LoyaltyService,
    private analytics: AnalyticsService,
    private store: Store,
  ) {}

  ngxsOnInit(ctx: StateContext<UserStateModel>) {
    if (ctx.getState().tokens) {
      ctx.dispatch(new InitializeUser());
    }
  }

  @Action(InitializeUser)
  initializeUser(ctx: StateContext<UserStateModel>, action: InitializeUser) {
    return forkJoin({
      userInfo: this.user.getUserInfo(ctx.getState().tokens!.tokens.user.token),
      pointBalance: this.loyalty.getPointsBalance(
        ctx.getState().tokens!.tokens.user.token,
      ),
      offers: this.loyalty.getOffers(ctx.getState().tokens!.tokens.user.token),
      activity: this.loyalty.getLoyaltyActivity(
        ctx.getState().tokens!.tokens.user.token,
      ),
      rewards: this.loyalty.getRewards(
        ctx.getState().tokens!.tokens.user.token,
      ),
      messages: this.loyalty.getMessages(
        ctx.getState().tokens!.tokens.user.token,
      ),
      pastOrders: this.user.getPastOrders(
        ctx.getState().tokens!.tokens.ordering.token,
      ),
      savedCards: this.user.getSavedCards(
        ctx.getState().tokens!.tokens.ordering.token,
      ),
      favoriteLocations: this.user.getFavoriteLocations(
        ctx.getState().tokens!.tokens.ordering.token,
      ),
      savedAddresses: this.user.getSavedAddresses(
        ctx.getState().tokens!.tokens.ordering.token,
      ),
      savedProducts: this.user.getSavedProducts(
        ctx.getState().tokens!.tokens.ordering.token,
      ),
    }).pipe(
      map((results) => {
        this.analytics.logIdentifyOnLogin(results.userInfo);
        return ctx.patchState({
          user: results.userInfo,
          pointBalance: results.pointBalance,
          offers: results.offers,
          activity: results.activity,
          rewards: results.rewards,
          messages: results.messages,
          pastOrders: results.pastOrders.orders,
          savedCards: results.savedCards,
          favoriteLocations: results.favoriteLocations,
          savedAddresses: results.savedAddresses.deliveryaddresses,
          savedProducts: results.savedProducts.savedProducts,
        });
      }),
      catchError((error) => {
        ctx.dispatch(new Logout());
        return throwError(error);
      }),
    );
  }

  @Action(CreateAccount)
  createAccount(ctx: StateContext<UserStateModel>, action: CreateAccount) {
    const body: CreateAccountDto = {
      first_name: action.firstName,
      last_name: action.lastName,
      email: action.email,
      phone: action.phoneNumber,
      marketing_email_subscription: action.emailOptIn,
      send_compliance_sms: action.smsOptIn,
      birthday: action.birthday,
      password: action.password,
      password_confirmation: action.passwordConfirmation,
      terms_and_conditions: action.termsAndConditions,
      signup_channel: 'OnlineOrder',
    };
    return this.user.createAccount(body).pipe(
      switchMap((tokens) => {
        return ctx.dispatch(new Login(action.email, action.password));
      }),
    );
  }

  @Action(Login)
  login(ctx: StateContext<UserStateModel>, action: Login) {
    return this.user
      .login(
        action.email,
        action.password,
        this.store.selectSnapshot(
          (state: GlobalStateModel) => state.order.order,
        )?.id,
      )
      .pipe(
        switchMap((tokens) => {
          ctx.patchState({
            tokens: tokens,
          });
          return ctx.dispatch(new InitializeUser());
        }),
      );
  }

  @Action(LoginWithFacebook)
  loginWithFacebook(
    ctx: StateContext<UserStateModel>,
    action: LoginWithFacebook,
  ) {
    return this.user
      .loginWithFacebook(
        action.accessToken,
        this.store.selectSnapshot(
          (state: GlobalStateModel) => state.order.order,
        )?.id,
      )
      .pipe(
        switchMap((tokens) => {
          ctx.patchState({
            tokens: tokens,
          });
          return ctx.dispatch(new InitializeUser());
        }),
      );
  }

  @Action(LoginWithCode)
  loginWithCode(ctx: StateContext<UserStateModel>, action: LoginWithCode) {
    return this.user.loginWithToken(action.accessToken).pipe(
      switchMap((tokens) => {
        ctx.patchState({
          tokens,
        });
        ctx.dispatch(new SetFullExperience(false));
        return ctx.dispatch(new InitializeUser());
      }),
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<UserStateModel>, action: Logout) {
    ctx.dispatch(new ClearOrder());
    return this.user.logout(ctx.getState().tokens!.tokens.user.token).pipe(
      map(() => {
        return ctx.patchState({
          tokens: null,
          user: null,
          pointBalance: null,
          offers: null,
          activity: null,
          rewards: null,
          messages: null,
          savedAddresses: null,
          pastOrders: null,
          savedCards: null,
          favoriteLocations: null,
          savedProducts: null,
        });
      }),
      catchError(() => {
        return of(
          ctx.patchState({
            tokens: null,
            user: null,
            pointBalance: null,
            offers: null,
            activity: null,
            rewards: null,
            messages: null,
            savedAddresses: null,
            pastOrders: null,
            savedCards: null,
            favoriteLocations: null,
            savedProducts: null,
          }),
        );
      }),
    );
  }

  @Action(UpdateUserInfo)
  updateUserInfo(ctx: StateContext<UserStateModel>, action: UpdateUserInfo) {
    const body: UpdateUserInfoDto = {
      first_name: action.firstName,
      last_name: action.lastName,
      email: action.email,
      send_compliance_sms: action.smsOpt,
      marketing_email_subscription: action.emailOpt,
      birthday: action.birthday,
    };
    return this.user
      .updateUserInfo(ctx.getState().tokens!.tokens.user.token, body)
      .pipe(
        switchMap(() => {
          return ctx.dispatch(new InitializeUser());
        }),
      );
  }

  @Action(SaveFavoriteLocation)
  saveFavoriteLocation(
    ctx: StateContext<UserStateModel>,
    action: SaveFavoriteLocation,
  ) {
    return this.user
      .saveFavoriteLocation(
        ctx.getState().tokens!.tokens!.ordering!.token!,
        action.locationID,
      )
      .pipe(
        map((response) => {
          return ctx.patchState({
            favoriteLocations: response,
          });
        }),
      );
  }

  @Action(DeleteFavoriteLocation)
  deleteFavoriteLocation(
    ctx: StateContext<UserStateModel>,
    action: DeleteFavoriteLocation,
  ) {
    return this.user
      .deleteFavoriteLocation(
        ctx.getState().tokens!.tokens!.ordering!.token!,
        action.locationID,
      )
      .pipe(
        map((response) => {
          return ctx.patchState({
            favoriteLocations: response,
          });
        }),
      );
  }

  @Action(SetSavedCards)
  setSavedCards(ctx: StateContext<UserStateModel>, action: SetSavedCards) {
    return this.user
      .getSavedCards(
        ctx.getState().tokens!.tokens!.ordering!.token!,
        action.basketID,
      )
      .pipe(
        map((savedCards) => {
          return ctx.patchState({
            savedCards,
          });
        }),
      );
  }

  @Action(DeleteSavedCard)
  deleteSavedCard(ctx: StateContext<UserStateModel>, action: DeleteSavedCard) {
    const matchingCards = ctx
      .getState()
      .savedCards!.filter(
        (card) =>
          card.description === action.card.description &&
          card.expiration === action.card.expiration,
      );
    return from(matchingCards)
      .pipe(
        mergeMap((card) => {
          return this.user.deleteSavedCard(
            ctx.getState().tokens!.tokens.ordering.token,
            card.accountid,
          );
        }),
        toArray(),
      )
      .pipe(
        switchMap(() => {
          return ctx.dispatch(new SetSavedCards());
        }),
      );
  }

  @Action(SetDefaultSavedCard)
  setDefaultSavedCard(
    ctx: StateContext<UserStateModel>,
    action: SetDefaultSavedCard,
  ) {
    return this.user
      .setDefaultSavedCard(
        ctx.getState().tokens!.tokens.ordering.token,
        action.card.accountid,
      )
      .pipe(
        switchMap(() => {
          return ctx.dispatch(new SetSavedCards());
        }),
      );
  }

  @Action(RefreshMessages)
  refreshMessages(ctx: StateContext<UserStateModel>, action: RefreshMessages) {
    return this.loyalty
      .getMessages(ctx.getState().tokens!.tokens!.user.token!)
      .pipe(
        map((messages) => {
          return ctx.patchState({
            messages,
          });
        }),
      );
  }

  @Action(DismissMessage)
  dismissMessage(ctx: StateContext<UserStateModel>, action: DismissMessage) {
    return this.loyalty
      .dismissMessage(
        ctx.getState().tokens!.tokens!.user.token!,
        action.messageID,
      )
      .pipe(
        switchMap(() => {
          return ctx.dispatch(new RefreshMessages());
        }),
      );
  }

  @Action(SetSavedProducts)
  setSavedProducts(
    ctx: StateContext<UserStateModel>,
    action: SetSavedProducts,
  ) {
    if (ctx.getState().tokens?.tokens.ordering.token) {
      return this.user
        .getSavedProducts(ctx.getState().tokens!.tokens.ordering.token)
        .pipe(
          map((response) => {
            return ctx.patchState({
              savedProducts: response.savedProducts,
            });
          }),
        );
    } else {
      return;
    }
  }

  @Action(CreateSavedProduct)
  createSavedProduct(
    ctx: StateContext<UserStateModel>,
    action: CreateSavedProduct,
  ) {
    const newSavedProduct: NewSavedProduct = {
      chainproductid: action.product.chainproductid.toString(),
      quantity: action.quantity,
      specialinstructions: action.specialInstructions,
      recipient: action.recipientName,
      choices: action.options.map((option) => {
        return {
          chainchoiceid: option.chainoptionid.toString(),
          quantity: 1,
        };
      }),
    };
    return this.user
      .createSavedProducts(
        ctx.getState().tokens!.tokens.ordering.token,
        action.name,
        newSavedProduct,
      )
      .pipe(
        switchMap((response) => {
          return ctx.dispatch(new SetSavedProducts());
        }),
      );
  }

  @Action(DeleteSavedProduct)
  deleteSavedProduct(
    ctx: StateContext<UserStateModel>,
    action: DeleteSavedProduct,
  ) {
    return this.user
      .deleteSavedProducts(
        ctx.getState().tokens!.tokens.ordering.token,
        action.savedProductID,
      )
      .pipe(switchMap(() => ctx.dispatch(new SetSavedProducts())));
  }

  @Action(ClearUser)
  clearUser(ctx: StateContext<UserStateModel>, action: ClearUser) {
    return ctx.patchState({
      tokens: null,
      user: null,
      pointBalance: null,
      offers: null,
      activity: null,
      rewards: null,
      messages: null,
      savedAddresses: null,
      pastOrders: null,
      savedCards: null,
      favoriteLocations: null,
      savedProducts: null,
    });
  }
}
