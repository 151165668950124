<div
  class="cart-transition cart-wrapper bg-white"
  [ngClass]="(cartVisibility$ | async) ? 'cart-open' : 'cart-closed'">
  <ng-container *ngIf="order$ | async as order">
    <ng-container *ngIf="orderLocation$ | async as orderLocation">
      <div
        *ngIf="order.products.length > 0; else emptyCart"
        class="d-flex flex-column h-100">
        <!-- header -->
        <section class="cart-header py-2 px-3 border-bottom bg-light">
          <div class="d-flex flex-column flex-nowrap text-nowrap">
            <ng-container *ngIf="!order.deliveryaddress; else deliveryTitle">
              <small class="text-uppercase"
                >{{ order.deliverymode | handoffModeName }} From</small
              >
              <h5 class="cart-address">{{ orderLocation.name }}</h5>
            </ng-container>
            <ng-template #deliveryTitle>
              <small class="text-uppercase"
                >{{ order.deliverymode | handoffModeName }} To</small
              >
              <h5 class="cart-address">
                {{ order.deliveryaddress.streetaddress }}
              </h5>
            </ng-template>
            <ng-container
              *ngIf="
                !(
                  (groupOrder$ | async) && (isGroupOrderHost$ | async) === false
                )
              ">
              <div class="d-flex gap-2 align-items-center location-links">
                <ng-container *ngIf="!order.deliveryaddress">
                  <button
                    routerLink="/start-order"
                    type="button"
                    class="change-location-btn btn btn-link text-muted">
                    Change Location
                  </button>
                  <div class="text-muted location-links-divider">|</div>
                  <button
                    routerLink="/start-order"
                    fragment="delivery"
                    type="button"
                    class="change-location-btn btn btn-link p-0 text-muted">
                    Switch to Delivery
                  </button>
                </ng-container>

                <ng-container *ngIf="order.deliveryaddress">
                  <button
                    (click)="editDeliveryAddress(order)"
                    type="button"
                    class="change-location-btn btn btn-link text-muted">
                    Edit Delivery Address
                  </button>
                  <div class="text-muted">|</div>
                  <button
                    routerLink="/start-order"
                    fragment="pickup"
                    type="button"
                    class="change-location-btn btn btn-link p-0 text-muted">
                    Switch to Pickup
                  </button>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <button
            type="button"
            class="btn d-flex m-0 ml-auto p-0"
            aria-label="Close"
            (click)="closeCart()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-x-lg"
              viewBox="0 0 16 16">
              <path
                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
            </svg>
          </button>
        </section>
        <ng-container *ngIf="groupOrder$ | async as groupOrder">
          <app-go-details
            [groupOrder]="groupOrder"
            [groupOrderHost]="!!(isGroupOrderHost$ | async)"></app-go-details>
        </ng-container>
        <!-- body -->
        <div class="cart-body d-flex flex-column">
          <!-- products -->
          <section class="d-flex flex-column gap-3 bg-white">
            <ng-container
              *ngIf="(groupOrder$ | async) === null; else groupOrderCartBody">
              <ng-container *ngFor="let product of order.products">
                <div
                  class="ordered-by d-flex flex-column gap-2 grow-1 px-3 pb-3">
                  <div class="d-flex flex-nowrap gap-2">
                    <div
                      class="font-weight-bold text-muted text-uppercase grow-1"
                      *ngIf="product.recipient">
                      Ordered By: {{ product.recipient }}
                    </div>
                  </div>
                  <div class="d-flex flex-column gap-1">
                    <div class="d-flex flex-nowrap gap-2">
                      <div class="line-clamp-1 grow-1 font-weight-bold">
                        <ng-container *ngIf="product.quantity > 1"
                          >x{{ product.quantity }}&nbsp;</ng-container
                        >{{ product.name }}
                      </div>
                      <div class="text-muted">
                        {{ product.totalcost | currency }}
                      </div>
                    </div>
                    <small class="text-muted"
                      >{{ product.choices | choicesList }}
                    </small>
                    <div
                      class="d-flex flex-row flex-wrap gap-2 justify-content-start">
                      <button
                        *ngIf="product | canEdit | async"
                        type="button"
                        class="edit-remove-btn btn btn-link"
                        (click)="editProduct(product)">
                        Edit
                      </button>
                      <button
                        (click)="removeProduct(product)"
                        type="button"
                        class="edit-remove-btn btn btn-link">
                        Remove
                      </button>
                      <button
                        class="edit-remove-btn btn btn-link"
                        (click)="updateRecipient(product)">
                        Change Recipient Name
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </section>
        </div>
        <div class="p-3" *ngIf="order.subtotal < 300">
          <button
            class="btn btn-outline-secondary w-100"
            routerLink="/"
            fragment="menu">
            Add More Items
          </button>
        </div>
        <!-- footer -->
        <section
          class="cart-footer p-3 bg-dark"
          *ngIf="order.subtotal < 300; else totalExceeded">
          <!-- rewards -->
          <ng-container *ngIf="(user$ | async) === null">
            <section class="mt-auto">
              <div
                *ngIf="
                  (groupOrder$ | async) === null;
                  else guestGroupOrderComplete
                "
                class="rewards-section p-3 d-flex flex-column gap-2 bg-light">
                <h5 class="">Sign In for Rewards</h5>
                <div class="d-flex flex-row flex-nowrap gap-2">
                  <button
                    routerLink="/user/create-an-account"
                    type="button"
                    class="btn btn-outline-secondary text-nowrap grow-1 basis-0">
                    Join Now
                  </button>
                  <button
                    routerLink="/user/sign-in"
                    type="button"
                    class="btn btn-secondary grow-1 basis-0">
                    Sign In
                  </button>
                </div>
              </div>
            </section>
          </ng-container>
          <!-- totals -->
          <ng-container *ngIf="groupOrder$ | async; else orderTotals">
            <ng-container *ngIf="isGroupOrderHost$ | async">
              <ng-container *ngTemplateOutlet="orderTotals"></ng-container>
            </ng-container>
          </ng-container>
        </section>

        <ng-template #totalExceeded>
          <div class="p-3">
            <div class="alert alert-danger" role="alert">
              Unfortunately, your current order exceeds the maximum amount of
              $300 and cannot be fulfilled right now. For large orders, we ask
              that you place it with our Catering Team by phone at
              <a href="tel:+18888112473">888-811-BIRD</a> (2473) or email
              <a href="mailto:cater@freebirds.com">cater@freebirds.com</a> to
              assist you better.
            </div>
          </div>
        </ng-template>
      </div>
      <ng-template #emptyCart>
        <div class="d-flex flex-column h-100">
          <!-- header -->
          <section class="cart-header py-2 px-3 border-bottom bg-light">
            <div class="d-flex flex-column flex-nowrap text-nowrap">
              <ng-container *ngIf="!order.deliveryaddress; else deliveryTitle">
                <small class="text-uppercase"
                  >{{ order.deliverymode | handoffModeName }} From</small
                >
                <h5 class="cart-address">{{ orderLocation.name }}</h5>
              </ng-container>
              <ng-template #deliveryTitle>
                <small class="text-uppercase"
                  >{{ order.deliverymode | handoffModeName }} To</small
                >
                <h5 class="cart-address">
                  {{ order.deliveryaddress.streetaddress }}
                </h5>
              </ng-template>
              <ng-container
                *ngIf="
                  !(
                    (groupOrder$ | async) &&
                    (isGroupOrderHost$ | async) === false
                  )
                ">
                <div class="d-flex gap-2 align-items-center location-links">
                  <ng-container *ngIf="!order.deliveryaddress">
                    <button
                      routerLink="/start-order"
                      type="button"
                      class="change-location-btn btn btn-link text-muted">
                      Change Location
                    </button>
                    <div class="text-muted location-links-divider">|</div>
                    <button
                      routerLink="/start-order"
                      fragment="delivery"
                      type="button"
                      class="change-location-btn btn btn-link p-0 text-muted">
                      Switch to Delivery
                    </button>
                  </ng-container>

                  <ng-container *ngIf="order.deliveryaddress">
                    <button
                      (click)="editDeliveryAddress(order)"
                      type="button"
                      class="change-location-btn btn btn-link text-muted">
                      Edit Delivery Address
                    </button>
                    <div class="text-muted">|</div>
                    <button
                      routerLink="/start-order"
                      fragment="pickup"
                      type="button"
                      class="change-location-btn btn btn-link p-0 text-muted">
                      Switch to Pickup
                    </button>
                  </ng-container>
                </div>
              </ng-container>
            </div>
            <button
              type="button"
              class="btn d-flex m-0 ml-auto p-0"
              aria-label="Close"
              (click)="closeCart()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                class="bi bi-x-lg"
                viewBox="0 0 16 16">
                <path
                  d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </button>
          </section>

          <ng-container *ngIf="groupOrder$ | async as groupOrder">
            <app-go-details
              [groupOrder]="groupOrder"
              [groupOrderHost]="!!(isGroupOrderHost$ | async)"></app-go-details>
          </ng-container>
          <section
            class="cart-body d-flex align-items-center justify-content-center w-100 h-100 text-nowrap">
            <div class="d-flex flex-column gap-3 text-center">
              <h3>Cart Empty</h3>
              <div class="d-flex justify-content-center">
                <img
                  class="w-50"
                  src="/assets/images/icons/Icons/fb-garlic-sad.svg"
                  alt="" />
              </div>
              <div>Start by adding items to your cart.</div>
              <button
                *ngIf="route.fragment | async | hideViewMenu"
                type="button"
                class="btn btn-primary"
                routerLink="/"
                fragment="menu">
                View Menu
              </button>
              <!--              <ng-container *ngIf="user$ | async">-->
              <ng-container *ngIf="orderLocation.supportsgrouporders">
                <ng-container *ngIf="(groupOrder$ | async) === null">
                  <button
                    type="button"
                    class="btn btn-outline-secondary"
                    routerLink="/order/group-order">
                    Create Group Order
                  </button>
                </ng-container>
              </ng-container>
              <!--              </ng-container>-->
            </div>
          </section>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>

<ng-template #orderTotals>
  <ng-container *ngIf="order$ | async as order">
    <section class="d-flex flex-column pb-3 gap-1 bg-dark text-white">
      <div class="d-flex flex-nowrap gap-3">
        <div class="line-clamp-1 grow-1 font-weight-bold">Subtotal</div>
        <div class="text-right font-weight-bold">
          {{ order.subtotal | currency }}
        </div>
      </div>
      <div class="d-flex flex-row w-100 justify-content-end">
        <small>Max Order Total: $300</small>
      </div>
    </section>
    <button
      *ngIf="location.path() !== '/order/checkout'"
      type="button"
      class="btn btn-primary w-100"
      routerLink="/order/checkout">
      Checkout
    </button>
  </ng-container>
</ng-template>

<ng-template #groupOrderCartBody>
  <ng-container *ngIf="order$ | async as order">
    <ng-container *ngIf="groupOrderName$ | async as groupOrderName">
      <ng-container *ngFor="let recipient of order.products | orderRecipient">
        <ng-container *ngIf="recipient.name === groupOrderName">
          <ng-container
            *ngFor="let product of recipient.products; first as first">
            <div class="ordered-by d-flex flex-column gap-2 grow-1 px-3 pb-3">
              <div class="d-flex flex-nowrap gap-2" *ngIf="first">
                <div
                  class="font-weight-bold text-muted text-uppercase grow-1"
                  *ngIf="product.recipient">
                  Ordered By: {{ product.recipient }}
                </div>
                <div class="text-muted font-weight-bold">
                  {{ recipient.total | currency }}
                </div>
              </div>
              <div class="d-flex flex-column gap-1">
                <div class="d-flex flex-nowrap gap-2">
                  <div class="line-clamp-1 grow-1 font-weight-bold">
                    {{ product.name }}
                    <ng-container *ngIf="product.quantity > 1"
                      >&nbsp; x{{ product.quantity }}
                    </ng-container>
                  </div>
                  <div class="text-muted">
                    {{ product.totalcost | currency }}
                  </div>
                </div>
                <small class="text-muted"
                  >{{ product.choices | choicesList }}
                </small>
                <div class="d-flex flex-nowrap gap-2 justify-content-start">
                  <button
                    *ngIf="product.choices.length > 0"
                    type="button"
                    class="edit-remove-btn btn btn-link"
                    (click)="editProduct(product)">
                    Edit
                  </button>
                  <button
                    (click)="removeProduct(product)"
                    type="button"
                    class="edit-remove-btn btn btn-link">
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let recipient of order.products | orderRecipient">
        <ng-container *ngIf="recipient.name !== groupOrderName">
          <ng-container
            *ngFor="let product of recipient.products; first as first">
            <div class="ordered-by d-flex flex-column gap-2 grow-1 px-3 pb-3">
              <div class="d-flex flex-nowrap gap-2" *ngIf="first">
                <div
                  class="font-weight-bold text-muted text-uppercase grow-1"
                  *ngIf="product.recipient">
                  Ordered By: {{ product.recipient }}
                </div>
                <div class="text-muted font-weight-bold">
                  {{ recipient.total | currency }}
                </div>
              </div>
              <div class="d-flex flex-column gap-1">
                <div class="d-flex flex-nowrap gap-2">
                  <div class="line-clamp-1 grow-1 font-weight-bold">
                    {{ product.name }}
                    <ng-container *ngIf="product.quantity > 1"
                      >&nbsp; x{{ product.quantity }}
                    </ng-container>
                  </div>
                  <div class="text-muted">
                    {{ product.totalcost | currency }}
                  </div>
                </div>
                <small class="text-muted"
                  >{{ product.choices | choicesList }}
                </small>
                <ng-container *ngIf="isGroupOrderHost$ | async">
                  <div class="d-flex flex-nowrap gap-2 justify-content-start">
                    <button
                      (click)="removeProduct(product)"
                      type="button"
                      class="edit-remove-btn btn btn-link">
                      Remove
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #guestGroupOrderComplete>
  <button
    (click)="completeGroupOrder()"
    routerLink="/"
    type="button"
    class="btn btn-primary w-100">
    I'm Finished
  </button>
</ng-template>

<!--<ng-template #rewardsContainer>-->
<!--  <ng-container *ngIf="(groupOrder$ | async) === null">-->
<!--    <ng-container-->
<!--      *ngIf="availableRewards$ | async as availableRewards">-->
<!--      <ng-container *ngIf="availableRewards.length">-->
<!--        <div-->
<!--          class="rewards-section p-3 d-flex flex-column gap-2 bg-light">-->
<!--          <div class="d-flex flex-nowrap gap-2">-->
<!--            <h5 class="grow-1">Your Rewards</h5>-->
<!--            <button-->
<!--              *ngIf="availableRewards.length > 2"-->
<!--              (click)="toggleViewAllRewards()"-->
<!--              type="button"-->
<!--              class="view-all-btn btn btn-link text-muted p-0 text-right">-->
<!--              {{ viewAllRewards ? 'Collapse' : 'View All' }}-->
<!--            </button>-->
<!--          </div>-->
<!--          <div class="d-flex flex-column gap-2">-->
<!--            <ng-container-->
<!--              *ngFor="let reward of availableRewards; index as index">-->
<!--              <ng-container-->
<!--                *ngIf="viewAllRewards ? true : index < 2">-->
<!--                <app-order-reward-card-->
<!--                  [availableReward]="reward"-->
<!--                  [isApplied]="reward | isRewardApplied : order.appliedrewards ?? []"></app-order-reward-card>-->
<!--              </ng-container>-->
<!--            </ng-container>-->
<!--          </div>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--    </ng-container>-->
<!--  </ng-container>-->
<!--</ng-template>-->
