import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { GlobalStateModel, SavedCard } from '@models/index';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-payment-methods-section',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './payment-methods-section.component.html',
  styleUrls: ['payment-methods-section.component.scss'],
})
export class PaymentMethodsSectionComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.user.savedCards)
  savedCards$!: Observable<SavedCard[]>;

  constructor() {}
  ngOnInit() {}
}
