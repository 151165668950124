import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Order } from '@models/index';
import { CheckoutService } from '@modules/checkout/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-coupon-code',
  templateUrl: './coupon-code.component.html',
  styleUrls: ['coupon-code.component.scss'],
})
export class CouponCodeComponent implements OnInit, OnChanges {
  @Input() order!: Order;

  couponForm: FormGroup = new FormGroup({
    couponCode: new FormControl(null, []),
  });

  applyLoading: boolean = false;

  constructor(
    private checkout: CheckoutService,
    private toastr: ToastrService,
  ) {}
  ngOnInit() {
    this.checkIfCoupon();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkIfCoupon();
  }

  applyCoupon() {
    this.applyLoading = true;
    this.checkout.applyCoupon(this.order.id, this.couponCode.value!).subscribe({
      next: () => (this.applyLoading = false),
      error: (error) => {
        this.toastr.error(error.error.message, 'Error');
        this.applyLoading = false;
      },
    });
  }

  removeCoupon() {
    this.applyLoading = true;
    this.checkout.removeCoupon(this.order.id).subscribe(() => {
      this.applyLoading = false;
      this.couponCode.reset();
    });
  }

  private checkIfCoupon() {
    if (this.order.coupon) {
      this.couponForm.setValue({
        couponCode: this.order.coupon.couponcode,
      });
      this.couponCode.disable();
    } else {
      this.couponCode.enable();
    }
  }

  get couponCode(): AbstractControl {
    return this.couponForm.get('couponCode')!;
  }
}
