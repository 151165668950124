import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { StaticMenuCollectionGroup } from '@models/index';

@Component({
  selector: 'app-sm-ingredients-section',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sm-ingredients-section.component.html',
  styleUrls: ['sm-ingredients-section.component.scss'],
})
export class SmIngredientsSectionComponent implements OnInit {
  @Input() collectionGroup!: StaticMenuCollectionGroup;
  constructor() {}
  ngOnInit() {}
}
