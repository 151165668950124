import { Component, Input, OnInit } from '@angular/core';
import { PointsBalance, Reward } from '@models/index';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-rewards-preview-modal',
  templateUrl: './rewards-preview-modal.component.html',
  styleUrls: ['./rewards-preview-modal.component.scss'],
})
export class RewardsPreviewModalComponent implements OnInit {
  @Input() rewards!: Reward[];
  @Input() pointBalances!: PointsBalance;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}

  onClose() {
    this.bsModalRef.hide();
  }
}
