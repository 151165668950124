import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { StaticMenuFeaturedGroup } from '@models/index';

@Component({
  selector: 'app-sm-products-section',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './sm-products-section.component.html',
  styleUrls: ['sm-products-section.component.scss'],
})
export class SmProductsSectionComponent implements OnInit {
  @Input() featuredGroup!: StaticMenuFeaturedGroup;

  constructor() {}
  ngOnInit() {}
}
