import { Pipe, PipeTransform } from '@angular/core';
import {
  IngredientGroup,
  OrderProduct,
  Product,
  Subcategory,
} from '@models/index';
import { Products } from '@server/vendor/directus/interfaces/directus-collection.interface';

@Pipe({
  name: 'upsellSubcategories',
})
export class UpsellSubcategoriesPipe implements PipeTransform {
  transform(
    products: Product[],
    isEntree: boolean = true,
    basketProducts?: OrderProduct[],
  ): Subcategory[] {
    const subcategories: Subcategory[] = [];
    products.forEach((product) => {
      if (
        product.metadata &&
        product.metadata.find((md) => md.key === 'subcategories')
      ) {
        const subcategory = subcategories.find(
          (sub) =>
            sub.name ===
            product.metadata.find((md) => md.key === 'subcategories')!.value,
        );
        if (subcategory) {
          const index = subcategories.indexOf(subcategory);
          subcategory.products.push(
            <
              Product & { sort: number; content: Products } & {
                optiongroups?: IngredientGroup[];
              }
            >product,
          );
          subcategories[index] = subcategory;
        } else {
          subcategories.push(<Subcategory>{
            name: product.metadata.find((md) => md.key === 'subcategories')!
              .value,
            products: [product],
          });
        }
      }
    });
    subcategories.forEach((subcategory) => {
      subcategory.products.sort(
        (a, b) =>
          (b.content.upsell_priority ?? 0) - (a.content.upsell_priority ?? 0),
      );
    });
    if (basketProducts) {
      // remove any items already in basket
      subcategories.forEach((subcategory) => {
        subcategory.products = subcategory.products.filter(
          (product) =>
            !basketProducts.find(
              (basketProduct) => basketProduct.productId === product.id,
            ),
        );
      });
    }
    return subcategories
      .filter((s) => s.products.length > 0)
      .filter(
        (subcategory) => !(!isEntree && subcategory.name === 'sidesauces'),
      );
  }
}
