<button
  (click)="
    isSelected
      ? ingredientRemove(ingredient, $event)
      : ingredientSelect(ingredient, $event)
  "
  class="btn text-capitalize btn-sm grow-1 basis-0"
  [ngClass]="isSelected ? 'btn-secondary' : 'btn-outline-secondary'"
  tabindex="0"
  role="button">
  {{ ingredient.name
  }}<ng-container *ngIf="ingredient.cost > 0"
    >&nbsp;{{ ingredient.cost | currency }}</ng-container
  >
</button>
