<section>
  <div class="navbar-anchor"></div>
  <div class="py-4 py-md-5">
    <ng-container *ngIf="menu">
      <div class="container">
        <div class="menu-grid">
          <ng-container *ngIf="savedProducts && savedProducts.length">
            <app-category-card
              [menuLocation]="orderLocation"
              [isSavedProducts]="true"></app-category-card>
          </ng-container>
          <ng-container
            *ngFor="let category of menu.categories; trackBy: renderTrackBy">
            <ng-container *ngIf="orderLocation || !category.content.is_hidden_from_home">
              <app-category-card
                [category]="category"
                [menuLocation]="orderLocation"></app-category-card>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</section>
