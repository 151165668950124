<section
  *ngIf="rewards | hasRewards: pointBalances; else emptyRewards"
  class="position-relative border-bottom">
  <div class="container py-5">
    <div class="pb-4 d-flex flex-column gap-3">
      <h1>My Rewards</h1>
      <div class="alert-info alert rounded">
        <div class="d-flex flex-nowrap gap-3 align-items-center">
          <img
            class="alert-perks-icon d-none d-md-flex"
            src="/assets/images/icons/Icons/fb-perks-solid.svg"
            alt="" />
          <div class="d-flex flex-column">
            <h5>How to Use Your Rewards:</h5>
            To redeem a reward, please add the item related to the reward to
            your cart and then redeem the reward at checkout.
          </div>
        </div>
      </div>
    </div>
    <div class="card-grid">
      <ng-container *ngFor="let reward of rewards">
        <ng-container
          *ngIf="
            reward.points_required_to_redeem <= pointBalances.redeemable_points
          ">
          <app-reward-card
            [rewardCardStyle]="'large'"
            [isUnlocked]="
              reward.points_required_to_redeem <=
              pointBalances.redeemable_points
            "
            [reward]="reward"></app-reward-card>
        </ng-container>
      </ng-container>
    </div>
  </div>
</section>

<ng-template #emptyRewards>
  <div class="border-bottom">
    <div class="container py-5">
      <div class="d-flex flex-column gap-3">
        <h1>My Rewards</h1>
        <div class="d-flex flex-column gap-3 text-center py-4">
          <div class="d-flex justify-content-center">
            <img
              class="present-icon"
              src="/assets/images/icons/Icons/fb-present.svg"
              alt="" />
          </div>
          <div>Earn rewards by accumulating points through each order.</div>
          <div class="d-flex justify-content-center">
            <button
              routerLink="/"
              [fragment]="'order'"
              type="button"
              class="btn btn-primary">
              Start Order
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<section class="position-relative">
  <div class="container py-5">
    <div class="pb-4 d-flex flex-column gap-3">
      <h1>Unlockable Rewards</h1>
      <div
        class="alert-info alert rounded d-flex flex-nowrap gap-3 align-items-center">
        <div class="d-flex flex-nowrap gap-3 align-items-center">
          <img
            class="alert-perks-icon d-none d-md-flex"
            src="/assets/images/icons/Icons/fb-present.svg"
            alt="" />
          <div class="d-flex flex-column">
            <h5>How to Earn Rewards:</h5>
            To earn these rewards, accumulate points by enjoying more Freebirds.
          </div>
        </div>
      </div>
    </div>
    <div class="card-grid">
      <ng-container *ngFor="let reward of rewards">
        <ng-container
          *ngIf="
            !(
              reward.points_required_to_redeem <=
              pointBalances.redeemable_points
            )
          ">
          <app-reward-card
            [reward]="reward"
            [rewardCardStyle]="'large'"
            [isUnlocked]="false"></app-reward-card>
        </ng-container>
      </ng-container>
    </div>
  </div>
</section>
