<div class="modal-header rounded-top align-items-center">
  <img
    class="h4-birds"
    src="../../../../assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="" />
  <h4 class="modal-title grow-1">Who is This Meal for?</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1">
  <form [formGroup]="mealNameForm" class="w-100">
    <div class="d-flex flex-column gap-3 grow-1">
      <div class="form-floating">
        <input
          #nameInput
          autofocus
          class="form-control"
          type="text"
          name="name"
          id="name"
          formControlName="name"
          [maxlength]="32"
          placeholder="Name" />
        <label for="name" id="name-label">Enter First Name</label>
        <ng-container *ngIf="name.touched && name.invalid">
          <small class="text-danger" *ngIf="name!.errors!['required'!]!"
            >Name Required</small
          >
        </ng-container>
      </div>
      <ng-container *ngIf="!nameEditOnly">
        <!--      <ng-container *ngIf="orderLocation$ | async as orderLocation">-->
        <!--        <div class="form-floating">-->
        <!--          <textarea-->
        <!--            class="form-control"-->
        <!--            id="specialInstructions"-->
        <!--            formControlName="instructions"-->
        <!--            [maxlength]="-->
        <!--              orderLocation.specialinstructionsmaxlength - -->
        <!--              (name?.value?.length + 1)-->
        <!--            "-->
        <!--            rows="3"></textarea>-->
        <!--          <label for="specialInstructions">Special Instructions</label>-->
        <!--        </div>-->
        <!--      </ng-container>-->
        <ng-container *ngIf="user$ | async">
          <div class="form-check">
            <label
              class="form-check-label d-flex align-items-center cursor-pointer">
              <input
                class="form-check-input cursor-pointer"
                type="checkbox"
                formControlName="saveAsFavorite" />
              <div>Save this Item as a Favorite</div>
            </label>
          </div>
          <div class="form-floating" [@collapse]="!saveAsFavorite.value">
            <input
              class="form-control"
              id="favoriteName"
              formControlName="favoriteName"
              type="text"
              [maxlength]="128" />
            <label for="favoriteName">Favorite Name</label>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </form>
</div>
<div class="modal-footer rounded-bottom">
  <div class="d-flex flex-nowrap gap-3 m-0">
    <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">
      Cancel
    </button>
    <button type="button" class="btn btn-success" (click)="finish()">
      Confirm
    </button>
  </div>
</div>
