import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-create-account-form',
  templateUrl: './create-account-form.component.html',
  styleUrls: ['create-account-form.component.scss'],
})
export class CreateAccountFormComponent {
  @Input() parentForm!: FormGroup;
  @Input() createLoading: boolean = false;
  @Input() errorMessage = '';
  @Input() createTitle: string = 'Join Now';
  @Input() showHeading = true;
  @Output() createClicked = new EventEmitter<void>();

  maxDate = moment().startOf('day').subtract(13, 'years').toDate();
}
