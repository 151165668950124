import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  GlobalStateModel,
  Order,
  Product,
  Restaurant,
  Subcategory,
} from '@models/index';
import { Select, Store } from '@ngxs/store';
import { SideSaucesFilterPipe } from '@pipes/side-sauces-filter.pipe';
import { HandoffMode } from '@server/vendor/olo/interfaces';
import { SetTip } from '@store/actions/order.actions';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-suggested-products-modal',
  templateUrl: './suggested-products-modal.component.html',
  styleUrls: ['suggested-products-modal.component.scss'],
  providers: [SideSaucesFilterPipe],
})
export class SuggestedProductsModalComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.menu.upsellProducts)
  upsellProducts$!: Observable<Product[]>;
  @Select((state: GlobalStateModel) => state.order.order)
  order$!: Observable<Order>;
  @Select((state: GlobalStateModel) => state.locations.orderLocation)
  orderLocation$!: Observable<Restaurant>;

  @ViewChild('menuGrid') menuGrid!: ElementRef<HTMLDivElement>;

  constructor(
    public bsModalRef: BsModalRef,
    private asyncPipe: AsyncPipe,
    private sideSaucesPipe: SideSaucesFilterPipe,
    private store: Store,
  ) {}

  ngOnInit() {}

  trackBySubcategoryProductName(index: number, product: Product) {
    return `${index}-${product.name}-${product.id}`;
  }

  scrollLeft() {
    const products = this.sideSaucesPipe.transform(
      this.asyncPipe.transform(this.upsellProducts$)!,
    );
    if (this.menuGrid && this.menuGrid.nativeElement) {
      this.menuGrid.nativeElement.scroll({
        left:
          this.menuGrid.nativeElement.scrollLeft -
          this.menuGrid.nativeElement.scrollWidth / products.length,
        behavior: 'smooth',
      });
    }
  }

  scrollRight() {
    const products = this.sideSaucesPipe.transform(
      this.asyncPipe.transform(this.upsellProducts$)!,
    );
    if (this.menuGrid && this.menuGrid.nativeElement) {
      this.menuGrid.nativeElement.scroll({
        left:
          this.menuGrid.nativeElement.scrollLeft +
          this.menuGrid.nativeElement.scrollWidth / products.length,
        behavior: 'smooth',
      });
    }
  }

  close() {
    const order = this.asyncPipe.transform(this.order$);
    const tipSettings = this.store.selectSnapshot(
      (state: GlobalStateModel) => state.content.tipSettings,
    );
    if (order?.allowstip) {
      this.store.dispatch(
        new SetTip(
          order!.id!,
          order.subtotal *
            (order.deliverymode === HandoffMode.DISPATCH
              ? tipSettings?.default_delivery_tip ?? 0.2
              : tipSettings?.default_pickup_tip ?? 0.2),
        ),
      );
    }
    this.bsModalRef.hide();
  }
}
