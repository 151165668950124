/* eslint-disable simple-import-sort/imports */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

/* Modules */
import { AppCommonModule } from '@common/app-common.module';
// import { NavigationModule } from '@modules/navigation/navigation.module';

/* Components */
import * as checkoutComponents from './components';

/* Containers */
import * as checkoutContainers from './containers';

/* Guards */
import * as checkoutGuards from './guards';

/* Services */
import * as checkoutServices from './services';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from '../../environments/environment';
import { OrderModule } from '@modules/order/order.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PipesModule } from '@pipes/pipes.module';
import { NgxMaskModule } from 'ngx-mask';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AppCommonModule,
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapboxToken,
    }),
    OrderModule,
    ModalModule,
    PipesModule,
    NgxMaskModule,
    TabsModule,
    BsDatepickerModule,
  ],
  providers: [...checkoutServices.services, ...checkoutGuards.guards],
  declarations: [
    ...checkoutContainers.containers,
    ...checkoutComponents.components,
  ],
  exports: [...checkoutContainers.containers, ...checkoutComponents.components],
})
export class CheckoutModule {}
