import { Injectable } from '@angular/core';
import { GlobalStateModel, PaymentMethod } from '@models/index';
import { Store } from '@ngxs/store';
import { HandoffMode, ReceivingUser } from '@server/vendor/olo/interfaces';
import { AnalyticsService } from '@services/analytics/analytics.service';
import {
  RemoveCoupon,
  SetAvailableOrderTimes,
  SetCoupon,
  SetCustomField,
  SetHandoffMode,
  SetTimeWanted,
  SetTimeWantedToASAP,
  SetTip,
  SubmitBasket,
  ValidateOrder,
} from '@store/actions/order.actions';
import { forkJoin, map, Observable, of, switchMap, take } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class CheckoutService {
  constructor(private store: Store, private analytics: AnalyticsService) {}

  checkoutPageLoad(): Observable<void> {
    return forkJoin({
      order: this.store.selectOnce(
        (state: GlobalStateModel) => state.order.order,
      ),
      menu: this.store
        .select((state: GlobalStateModel) => state.menu.menu)
        .pipe(
          filter((menu) => !!menu),
          take(1),
        ),
      location: this.store.selectOnce(
        (state: GlobalStateModel) => state.locations.orderLocation,
      ),
    }).pipe(
      map((results) => {
        return this.analytics.logCheckoutDetail(
          results.order!.products,
          results.menu!.categories,
          results.location!,
          results.order!.deliverymode,
        );
      }),
    );
  }

  applyTip(orderID: string, amount: number): Observable<void> {
    return this.store.dispatch(new SetTip(orderID, amount));
  }

  applyCoupon(orderID: string, code: string): Observable<void> {
    return this.store.dispatch(new SetCoupon(orderID, code));
  }

  removeCoupon(orderID: string): Observable<void> {
    return this.store.dispatch(new RemoveCoupon(orderID));
  }

  setAvailableTimes(day: Date): Observable<void> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        switchMap((order) => {
          return this.store.dispatch(
            new SetAvailableOrderTimes(order!.id, day),
          );
        }),
      );
  }

  setAdvancedOrderTime(date: Date): Observable<void> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        switchMap((order) => {
          return this.store.dispatch(new SetTimeWanted(order!.id, date));
        }),
      );
  }

  setTimeToASAP(): Observable<void> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        switchMap((order) => {
          return this.store.dispatch(new SetTimeWantedToASAP(order!.id));
        }),
      );
  }

  setHandoffMode(handoffMode: HandoffMode): Observable<void> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        switchMap((order) => {
          return this.store.dispatch(
            new SetHandoffMode(order!.id, handoffMode),
          );
        }),
      );
  }

  validateOrder(): Observable<void> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        switchMap((order) => {
          return this.store.dispatch(new ValidateOrder(order!.id));
        }),
      );
  }

  setCustomField(customFieldID: number, value: string): Observable<void> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        switchMap((order) => {
          return this.store.dispatch(
            new SetCustomField(order!.id, customFieldID, value),
          );
        }),
      );
  }

  submitOrder(
    billingAccounts: PaymentMethod[],
    authToken?: string,
    user?: ReceivingUser,
    guestOptIn?: boolean,
    createdNewAccount?: boolean,
  ): Observable<void> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        switchMap((order) => {
          return this.store.dispatch(
            new SubmitBasket(
              order!.id,
              billingAccounts,
              authToken,
              user,
              guestOptIn,
              createdNewAccount,
            ),
          );
        }),
      );
  }
}
