import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GetLoyaltyActivityResponse,
  GetMessagesResponse,
  GetOffersResponse,
  GetPointsBalanceResponse,
  GetRewardsResponse,
} from '@server/loyalty/responses';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoyaltyService {
  private loyaltyAPIPrefix = environment.apiBaseURL + '/api/loyalty';

  constructor(private http: HttpClient) {}

  getPointsBalance(accessToken: string): Observable<GetPointsBalanceResponse> {
    const resource = `${this.loyaltyAPIPrefix}/balance`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        accessToken,
      },
    });
    return this.http.get<GetPointsBalanceResponse>(resource, { params });
  }

  getOffers(accessToken: string): Observable<GetOffersResponse> {
    const resource = `${this.loyaltyAPIPrefix}/offers`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        accessToken,
      },
    });
    return this.http.get<GetOffersResponse>(resource, { params });
  }

  getLoyaltyActivity(
    accessToken: string,
  ): Observable<GetLoyaltyActivityResponse> {
    const resource = `${this.loyaltyAPIPrefix}/activity`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        accessToken,
      },
    });
    return this.http.get<GetLoyaltyActivityResponse>(resource, { params });
  }

  getRewards(accessToken: string): Observable<GetRewardsResponse> {
    const resource = `${this.loyaltyAPIPrefix}/rewards`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        accessToken,
      },
    });
    return this.http.get<GetRewardsResponse>(resource, { params });
  }

  getMessages(accessToken: string): Observable<GetMessagesResponse> {
    const resource = `${this.loyaltyAPIPrefix}/messages`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        accessToken,
      },
    });
    return this.http.get<GetMessagesResponse>(resource, { params });
  }

  dismissMessage(accessToken: string, messageID: string): Observable<{}> {
    const resource = `${this.loyaltyAPIPrefix}/messages/${messageID}`;
    const params: HttpParams = new HttpParams({
      fromObject: {
        accessToken,
      },
    });
    return this.http.delete(resource, { params });
  }
}
