import { Component, Input, OnInit } from '@angular/core';
import { GlobalStateModel } from '@models/index';
import { Select } from '@ngxs/store';
import { GetHomepageResponse } from '@server/content/responses';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.content.homepage)
  homepage$!: Observable<GetHomepageResponse>;
  @Input() footerStyle: 'full' | 'simplified' = 'full';

  content: string =
    'Freebirds World Burrito - Texas fast-casual burrito joint with craveable proteins grilled in-house by master grillers. Texas’ No. 1 Burrito.';

  currentYear = new Date().getFullYear();

  constructor() {}
  ngOnInit() {}

  careersContent() {
    this.content = 'Learn more about our culture & career opportunities.';
  }

  ourStoryContent() {
    this.content = 'Get to know more about Texas’ #1 burrito.';
  }

  menuContent() {
    this.content = 'Browse the most craveable eats in Texas.';
  }

  giftCardsContent() {
    this.content = 'Better than shipping a burrito.';
  }

  aboutContent() {
    this.content =
      'Freebirds World Burrito - Texas fast-casual burrito joint with craveable proteins grilled in-house by master grillers. Texas No. 1 Burrito.';
  }
}
