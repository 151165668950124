import { Pipe, PipeTransform } from '@angular/core';
import { Ingredient, IngredientGroup, Product } from '@models/index';

@Pipe({
  name: 'productCustomizationCompletion',
  pure: false,
})
export class ProductCustomizationCompletionPipe implements PipeTransform {
  private incompleteSections: string[] = [];

  transform(
    product: Product,
    selectedModifiers: Ingredient[],
  ): { complete: boolean; incompleteSections: string[] } {
    this.incompleteSections = [];
    const complete = product?.optiongroups
      ? product.optiongroups.every((optionGroup) =>
          this.optionGroupValid(optionGroup, selectedModifiers),
        )
      : true;
    return {
      complete,
      incompleteSections: this.incompleteSections,
    };
  }

  private optionGroupValid(
    ingredientGroup: IngredientGroup,
    selectedOptions: Ingredient[],
  ): boolean {
    const selectedChildren = selectedOptions.filter((option) =>
      ingredientGroup.options.find(
        (gOption) => gOption.chainoptionid === option.chainoptionid,
      ),
    );
    if (
      !!(
        (!ingredientGroup.mandatory && !ingredientGroup.minselects) ||
        (ingredientGroup.mandatory &&
          !ingredientGroup.minselects &&
          selectedChildren.length === 1) ||
        (ingredientGroup.minselects &&
          selectedChildren.length >= Number(ingredientGroup.minselects))
      )
    ) {
      return selectedChildren.every((child) =>
        child.modifiers.every((mod) =>
          this.optionGroupValid(mod as IngredientGroup, selectedOptions),
        ),
      );
    } else {
      this.incompleteSections.push(ingredientGroup.description);
      return false;
    }
  }
}
