/* eslint-disable simple-import-sort/imports */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

/* Modules */
import { AppCommonModule } from '../app-common/app-common.module';
// import { NavigationModule } from '@modules/navigation/navigation.module';

/* Components */
import * as rewardsComponents from './components';

/* Containers */
import * as rewardsContainers from './containers';

/* Guards */
import * as rewardsGuards from './guards';

/* Services */
import * as rewardsServices from './services';
import { NgxTimelineModule } from '@frxjs/ngx-timeline';
import { AuthModule } from '@modules/auth/auth.module';
import { PipesModule } from '@pipes/pipes.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AppCommonModule,
    NgxTimelineModule,
    AuthModule,
    PipesModule,
    ProgressbarModule,
    TooltipModule,
  ],
  providers: [...rewardsServices.services, ...rewardsGuards.guards],
  declarations: [
    ...rewardsContainers.containers,
    ...rewardsComponents.components,
  ],
  exports: [...rewardsContainers.containers, ...rewardsComponents.components],
})
export class RewardsModule {}
