import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-os-crave-queso',
  templateUrl: './os-crave-queso.component.html',
  styleUrls: ['os-crave-queso.component.scss'],
})
export class OsCraveQuesoComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
