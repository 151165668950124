import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { PastOrder } from '@models/index';
import { OrderService } from '@modules/order/services';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reorder-modal',
  templateUrl: './reorder-modal.component.html',
  styleUrls: ['reorder-modal.component.scss'],
})
export class ReorderModalComponent {
  order!: PastOrder;
  reorderLoading: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private orderService: OrderService,
    private router: Router,
  ) {}

  reorder(): void {
    this.reorderLoading = true;
    this.orderService.startOrderFromPastOrder(this.order).subscribe(() => {
      this.router.navigate(['order', 'checkout']).then(() => {
        this.reorderLoading = false;
        this.bsModalRef.hide();
      });
    });
  }
}
