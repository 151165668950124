<div class="container">
  <div class="py-5 gap-4 d-flex flex-column position-relative shell">
    <div class="hero-copy">
      <img
        alt="Texas' No. 1 Burrito"
        class="hero-title animate__slow animate__animated animate__fadeInLeft"
        src="/assets/images/our-story/hero-title.svg" />
      <div
        class="hero-body os-body-copy animate__slow animate__animated animate__fadeInLeft">
        For over 30+ years, we’ve served Texas-inspired Monster sized burritos,
        salads and bowls and Texas inspired recipes with “It’s Your World
        Service,” which is why we are known as Texas’ No. 1 Burrito™.
      </div>
    </div>

    <img
      alt="Freebirds employees holding burritos'"
      class="cheers-image animate__slower animate__animated animate__fadeIn"
      src="/assets/images/our-story/our-story-cheers-burrito.png" />
    <div class="texas-graphic">
      <!-- placeholder -->
      <img
        alt="Shape of texas with cities and icons on top"
        class="texas-placeholder animate__slower animate__animated animate__fadeIn"
        src="/assets/images/our-story/texas-full.svg" />
    </div>
    <img
      alt="Freebirds' sheriff icon"
      class="sheriff-icon d-none d-sm-flex"
      src="/assets/images/ingredient_illustrations/Icons/fb-mens.svg" />

    <!--    <img class="bg-text" src="/assets/images/our-story/locations-number.svg" />-->
  </div>
</div>
