<div class="modal-header rounded-top align-items-center">
  <img
    class="h4-birds"
    src="assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="" />
  <h4 class="modal-title grow-1">{{ product.name }}</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1">
  <div
    class="d-flex flex-row flex-nowrap justify-content-between align-items-center">
    <h5>{{ product!.optiongroups![0].description }}</h5>
    <ng-container *ngIf="optionControl.invalid; else complete">
      <ng-container *ngIf="product!.optiongroups![0].mandatory; else optional">
        <small class="badge rounded-pill badge-danger"
        >Required</small
        ></ng-container
      >
      <ng-template #optional>
        <small class="badge rounded-pill badge-secondary">Optional</small>
      </ng-template>
    </ng-container>
    <ng-template #complete>
      <small class="badge rounded-pill badge-success">Complete</small>
    </ng-template>
  </div>
  <form class="py-2">
    <ng-container *ngFor="let option of product!.optiongroups![0].options">
      <div class="form-check py-2">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          [id]="option.id"
          [formControl]="optionControl"
          [value]="option.id" />
        <label
          class="form-check-label d-flex flex-row flex-nowrap justify-content-between w-100"
          [for]="option.id">
          <div class="d-flex flex-column">
            <div class="font-weight-bold">{{ option.name }}</div>
            <ng-container *ngIf="option.content.calories">
              <small>{{ option.content.calories }} cals</small>
            </ng-container>
            <ng-container *ngIf="option.content.description">
              <small>{{ option.content?.description }}</small>
            </ng-container>
          </div>
          <div *ngIf="option.cost">+{{ option.cost | currency }}</div>
        </label>
      </div>
    </ng-container>
  </form>
</div>
<div class="modal-footer rounded-bottom">
  <div class="d-flex flex-nowrap gap-3 m-0 w-100">
    <app-button
      [disabled]="optionControl.invalid"
      [isLoading]="addLoading"
      classes="btn btn-primary w-100"
      class="w-100"
      (buttonClick)="onAdd()">
      Add
    </app-button>
  </div>
</div>
