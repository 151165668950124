<div class="container hospitality-shell my-0 my-md-5">
  <div class="foreground">
    <img
      alt="Texan Hospitality"
      class="title-img hide-on-init animate__slower"
      animateOnScroll
      animationName="animate__animated animate__fadeInDown"
      src="/assets/images/our-story/texas-hospitality-title.svg" />
  </div>

  <div class="midground">
    <div
      class="text-card-orange os-body-copy hide-on-init animate__slow"
      animateOnScroll
      animationName="animate__animated animate__fadeInLeft">
      Texans have always done things their own way.
    </div>
    <div
      class="text-card-sky-blue os-body-copy hide-on-init animate__slow"
      animateOnScroll
      animationName="animate__animated animate__fadeInRight">
      <b>"It’s Your World"</b> at Freebirds, we’re just cooking in it.
    </div>
  </div>

  <div class="background d-none d-md-flex flex-row flex-nowrap gap-3">
    <img
      alt="Freebirds employees serving customers"
      animateOnScroll
      animationName="animate__animated animate__fadeInUp"
      class="background-left animate__slow hide-on-init"
      src="/assets/images/our-story/texas-hospitality-left-min.jpg" />
    <img
      alt="Customers ordering food in line at Freebirds"
      animateOnScroll
      animationName="animate__animated animate__fadeInDown"
      class="background-right animate__slow hide-on-init"
      src="/assets/images/our-story/texas-hospitality-right-min.jpg" />
  </div>

  <div class="background d-flex d-md-none">
    <img
      alt="Freebirds employees serving customers"
      class="background-full"
      src="/assets/images/our-story/texas-hospitality-bg.jpg" />
  </div>
</div>
