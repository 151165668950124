import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { ScrollSpyService } from '@common/services';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[spyOn]',
})
export class SpyOnDirective implements OnInit {
  @Input() activeClass!: string;
  @Input() spyOn!: string;
  private isActive = false;
  private renderer: Renderer2;

  constructor(
    private el: ElementRef,
    private rendererFactory: RendererFactory2,
    private spyService: ScrollSpyService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit() {
    this.spyService.activeSpyTarget.subscribe((targetName) => {
      if (!this.isActive && targetName === this.spyOn) {
        this.setActive();
      } else if (this.isActive && targetName !== this.spyOn) {
        this.setInActive();
      }
    });
  }

  private get htmlElement() {
    return this.el.nativeElement;
  }

  setActive() {
    this.isActive = true;
    if (this.activeClass) {
      this.renderer.addClass(this.htmlElement, this.activeClass);
    }
  }

  setInActive() {
    this.isActive = false;
    if (this.activeClass) {
      this.renderer.removeClass(this.htmlElement, this.activeClass);
    }
  }
}
