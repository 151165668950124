import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Category,
  GlobalStateModel, HomePage,
  Menu,
  Restaurant,
  SavedProduct,
} from '@models/index';
import { Select, Store } from '@ngxs/store';
import { ClearProduct, SetCategory } from '@store/actions/menu.actions';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-categories-list-bar',
  templateUrl: './categories-list-bar.component.html',
  styleUrls: ['categories-list-bar.component.scss'],
})
export class CategoriesListBarComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.content.homepage)
  homepage$!: Observable<HomePage>;
  @Input() menu!: Menu;
  @Input() category!: Category;
  @Input() orderLocation!: Restaurant;
  @Input() menuLocation?: Restaurant;
  @Input() currentCategoryID!: string | null;
  @Input() savedProducts!: SavedProduct[] | null;

  constructor(
    private store: Store,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {}
  ngOnInit() {}

  renderTrackBy(index: number, item: Category) {
    return `${index}${item.name}${item.id}`;
  }

  onClick(categoryID: number): void {
    this.spinner.show('walking-burrito');
    if (this.menuLocation) {
      this.store.dispatch(new ClearProduct());
      if (categoryID !== 0) {
        this.store
          .dispatch(new SetCategory(this.menuLocation.id, categoryID))
          .subscribe(() => {
            this.router.navigate(['order', 'customize', categoryID]);
          });
      } else {
        this.router.navigate(['order', 'customize', 'saved-products']);
      }
    } else {
      this.router.navigate(['start-order']);
    }
  }
}
