import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ScrollSpyService } from '@common/services';
import {
  GlobalStateModel,
  ScheduledAnnouncement,
  StaticMenu,
} from '@models/index';
import { Select } from '@ngxs/store';
import { SlugPipe } from '@pipes/slug.pipe';
import { GetHomepageResponse } from '@server/content/responses';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  slideInRightOnEnterAnimation,
  slideOutRightOnLeaveAnimation,
} from 'angular-animations';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'app-static-menu',
  templateUrl: './static-menu.component.html',
  styleUrls: ['static-menu.component.scss'],
  animations: [
    slideInRightOnEnterAnimation({ duration: 300 }),
    slideOutRightOnLeaveAnimation({ duration: 300 }),
    fadeOutOnLeaveAnimation({ duration: 300 }),
    fadeInOnEnterAnimation({ duration: 300 }),
  ],
})
export class StaticMenuComponent implements OnInit, AfterViewInit {
  @Select((state: GlobalStateModel) => state.content.homepage)
  homepage$!: Observable<GetHomepageResponse>;

  @Select((state: GlobalStateModel) => state.content.staticMenu)
  staticMenu$!: Observable<StaticMenu>;

  @Select((state: GlobalStateModel) => state.content.scheduledAnnouncement)
  scheduledAnnouncement$!: Observable<ScheduledAnnouncement[]>;

  isAtTop: boolean = true;
  pathURL: string = '';

  constructor(
    private elementRef: ElementRef,
    private slugPipe: SlugPipe,
    private router: Router,
    private spy: ScrollSpyService,
  ) {}
  ngOnInit() {
    this.pathURL = this.router.url.split('#')[0];
  }

  ngAfterViewInit() {
    this.spy.spy({ thresholdBottom: window.innerHeight / 2 });
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    this.isAtTop = window.pageYOffset === 0;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  normalizeId(input: string): string {
    return input.replace(/\s+/g, '').toLowerCase();
  }

  async scrollToSection(sectionName: string): Promise<void> {
    const element = document.getElementById(
      this.slugPipe.transform(sectionName),
    );

    let offset = 154;

    if (this.scheduledAnnouncement$) {
      const announcements = await firstValueFrom(this.scheduledAnnouncement$);

      // Check if announcements is not null or undefined
      if (announcements) {
        offset = 206;
      }
    }

    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      window.scroll({ top: elementPosition - offset, behavior: 'smooth' });
    }
  }
}
