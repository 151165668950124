<app-navbar></app-navbar>

<div
  class="nav-page-height-md w-100 d-flex flex-column flex-md-row flex-nowrap grow-1">
  <!-- image -->
  <div class="d-none d-md-flex grow-1 basis-0">
    <div class="auth-background-image"></div>
  </div>

  <!-- form -->
  <div class="d-flex justify-content-center grow-1 basis-0">
    <div class="w-100">
      <div
        class="auth-shell w-100 d-flex flex-column gap-5 px-3 px-md-5 py-4 py-md-5 align-items-center mx-auto">
        <img
          alt="Rewards Logo"
          class="auth-rewards-logo"
          src="/assets/images/marketing/rewards-as-big-as-texas.svg" />
        <app-create-account-form
          [parentForm]="createAccountFrom"
          [errorMessage]="errorMessage"
          [createLoading]="createLoading"
          (createClicked)="onCreateAccount()"></app-create-account-form>
        <div class="d-flex flex-column w-100 align-items-center gap-3">
          <div class="d-flex flex-column gap-1 w-100 align-items-center">
            <h5 class="auth-switch-page-heading w-75 text-center">
              Have an Account?
            </h5>
            <div>Sign In to Redeem Your Rewards.</div>
          </div>
          <button
            routerLink="/user/sign-in"
            [preserveFragment]="true"
            type="button"
            class="btn btn-outline-secondary">
            Sign In
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
