import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subcategoryName',
})
export class SubcategoryNamePipe implements PipeTransform {
  transform(name: string): string {
    if (name === 'sidesauces') {
      return 'sauce on side';
    }
    return name;
  }
}
