<div
  class="vh-100 vw-100 bg-light d-flex flex-column align-items-center justify-content-center gap-3 position-fixed top-0 left-0 z-top">
  <img
    class="w-50 h-auto"
    src="assets/images/logos/Freebirds_Logos_Digital_PrimaryLogo_Carbon_RGB.svg"
    alt="Freebirds World Burrito" />
  <img
    style="height: 15vh; width: auto"
    src="/assets/images/icons/Icons/freebirds-walking-burrito-filled.svg"
    alt="Loading Site" />
</div>
