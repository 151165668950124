<div class="d-flex flex-column gap-3 align-items-center justify-content-center text-center">
  <div>
    <img src="{{ perkImage }}" style="height: 88px; width: 88px; " alt="">
  </div>
  <h4>
    {{ perkTitle }}
  </h4>
  <p class="m-0">
    {{ perkDescription }}
  </p>
</div>
