import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  GlobalStateModel,
  LayoutSection,
  Order,
  PlantBased,
  Product,
  Restaurant,
} from '@models/index';
import { HomeService } from '@modules/home/services';
import { Select, Store } from '@ngxs/store';
import { concatMap, from, Observable, switchMap, tap, toArray } from 'rxjs';

@Component({
  selector: 'app-featured-products',
  templateUrl: './featured-products.component.html',
  styleUrls: ['featured-products.component.scss'],
  animations: [
    trigger('scrollAnimation', [
      transition('void => *', [
        style({ transform: 'translateX(300px)' }),
        animate(500),
      ]),
    ]),
  ],
})
export class FeaturedProductsComponent implements OnInit {
  @Input() section!: LayoutSection;

  @Output() productSelect = new EventEmitter<Product>();

  @ViewChild('menuGrid') menuGrid!: ElementRef<HTMLDivElement>;

  scrollAmount = 300;
  leftButtonOpacity = 1;
  rightButtonOpacity = 1;

  featuredProducts: Product[] = [];

  constructor(private store: Store, private home: HomeService) {}

  ngOnInit() {
    this.store
      .select((state: GlobalStateModel) => state.locations.orderLocation)
      .pipe(
        concatMap((orderLocation) => {
          return from(this.section.claims_to_fame_products!).pipe(
            concatMap((product) => {
              if (orderLocation) {
                return this.home.getProductInfo(
                  product!.item?.olo_chain_product_id!,
                  orderLocation.id,
                );
              } else {
                return this.home.getProductInfo(
                  product!.item?.olo_chain_product_id!,
                );
              }
            }),
          );
        }),
      )
      .subscribe((products) => {
        if (this.featuredProducts.length === 3) {
          this.featuredProducts.shift();
        }
        this.featuredProducts = [...this.featuredProducts, products];
      });
  }

  scrollLeft() {
    this.menuGrid.nativeElement.scrollTo({
      left: this.menuGrid.nativeElement.scrollLeft - this.scrollAmount,
      behavior: 'smooth',
    });
  }

  scrollRight() {
    this.menuGrid.nativeElement.scrollTo({
      left: this.menuGrid.nativeElement.scrollLeft + this.scrollAmount,
      behavior: 'smooth',
    });
  }

  onScroll() {
    const maxScrollLeft =
      this.menuGrid.nativeElement.scrollWidth -
      this.menuGrid.nativeElement.clientWidth;

    if (this.menuGrid.nativeElement.scrollLeft === 0) {
      this.leftButtonOpacity = 0.5;
    } else {
      this.leftButtonOpacity = 1;
    }

    if (this.menuGrid.nativeElement.scrollLeft === maxScrollLeft) {
      this.rightButtonOpacity = 0.5;
    } else {
      this.rightButtonOpacity = 1;
    }
  }
}
