import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-download-app-bar',
    templateUrl: './download-app-bar.component.html',
    styleUrls: ['download-app-bar.component.scss'],
})
export class DownloadAppBarComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
