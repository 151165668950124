// ENUMS

export enum TimeModeType {
  ASAP = 'asap',
  ADVANCE = 'advance',
  MANUAL_FIRE = 'manualfire',
}

export enum DeliveryModeType {
  CURBSIDE = 'curbside',
  PICkUP = 'pickup',
  DELIVERY = 'delivery',
  DISPATCH = 'dispatch',
  DRIVE_THRU = 'drivethru',
  DINE_IN = 'dinein',
}

export enum LoginProviderType {
  OLO = 'olo',
  EXTERNAL = 'external',
}

export enum DeliveryFeeType {
  SUBTOTAL_PERCENT = 'SubtotalPercent',
  DISPATCH_FEE = 'DispatchFee',
  FIXED_FEE = 'FixedFee',
}

export enum QualificationCriteriaType {
  ALL_ORDERS = 'AllOrders',
  DELIVERY_ORDERS_ONLY = 'DeliveryOrdersOnly',
  CASH_ORDERS_ONLY = 'CashOrdersOnly',
  CURBSIDE_PICKUP_ORDERS_ONLY = 'CurbsidePickupOrdersOnly',
  DRIVE_THRU_ONLY = 'DriveThruOnly',
  DINE_IN_ORDERS_ONLY = 'DineInOrdersOnly',
  CARRY_OUT_ONLY = 'CarryOutOnly',
  CALL_CENTER_TIME_WANTED_THRESHOLD = 'CallCenterTimeWantedThreshold',
  CALL_CENTER_ORDER_AMOUNT_THRESHOLD = 'CallCenterOrderAmountThreshold',
  CALL_CENTER_PRODUCT_QUANTITY_THRESHOLD = 'CallCenterProductQuantityThreshold',
  CALL_CENTER_TIME_WANTED_AND_PRODUCT_QUANTITY_THRESHOLD = 'CallCenterTimeWantedAndProductQuantityThreshold',
}

export enum CalendarType {
  BUSINESS = 'business',
  DELIVERY = 'delivery',
  CARRYOUT = 'carryout',
  PICKUP_WINDOW = 'pickupwindow',
  DINE_IN = 'dinein',
  CURBSIDE_PICKUP = 'curbsidepickup',
  DRIVE_THRU = 'drivethru',
  DISPATCH = 'dispatch',
}

export enum HandoffMode {
  DELIVERY = 'delivery',
  DISPATCH = 'dispatch',
  CURBSIDE = 'curbside',
  PICKUP = 'pickup',
  DINE_IN = 'dinein',
  DRIVE_THRU = 'drivethru',
}

export enum ImageGroupName {
  DESKTOP_MENU = 'desktop-menu',
  DESKTOP_CUSTOMIZE = 'desktop-customize',
  MOBILE_APP = 'mobile-app',
  MOBILE_APP_LARGE = 'mobile-app-large',
  MARKETPLACE_PRODUCT = 'marketplace-product',
  MOBILE_WEB_APP_MENU = 'mobile-webapp-menu',
  MOBILE_WEB_APP_CUSTOMIZE = 'mobile-webapp-customize',
}

export enum AlcoholStatus {
  NONE = 'None',
  ALCOHOL = 'Alcohol',
  FOOD_AND_ALCOHOL = 'FoodAndAlcohol',
}

export enum ValidationMessageCategory {
  ERROR = 'Error',
  WARNING = 'Warning',
}

export enum PromotionType {
  COUPON = 'coupon',
  COMP_CODE = 'compcode',
}

export enum BillingSchemeType {
  GIFT_CARD = 'giftcard',
  PAY_IN_STORE = 'payinstore',
  CREDIT_CARD = 'creditcard',
  EXTERNAL = 'external',
  PREPAID = 'prepaid',
}

export enum BillingFieldInputName {
  NUMBER = 'number',
  PIN = 'pin',
}

export enum BillingFieldInputType {
  NUMBER = 'number',
  PASSWORD = 'password',
}

export enum CreditCardType {
  AMERICAN_EXPRESS = 'Amex',
  VISA = 'Visa',
  DISCOVER = 'Discover',
  MASTERCARD = 'Mastercard',
}

export enum UserType {
  GUEST = 'guest',
  USER = 'user',
}

export enum BillingMethod {
  CREDIT_CARD = 'creditcard',
  CREDIT_CARD_ON_FILE = 'creditcardonfile',
  CREDIT_CARD_TOKEN = 'creditcardtoken',
  BILLING_ACCOUNT = 'billingaccount',
  CASH = 'cash',
  PAY_IN_STORE = 'payinstore',
  STORED_VALUE = 'storedvalue',
  PREPAID = 'prepaid',
}

export enum BooleanString {
  TRUE = 'true',
  FALSE = 'false',
}

export enum OrderStatus {
  COMPLETED = 'Completed',
  CANCELED = 'Canceled',
  TRANSMITTING = 'Transmitting',
  SCHEDULED = 'Scheduled',
  PENDING_MANUAL_FIRE = 'Pending Manual Fire',
  IN_PROGRESS = 'In Progress',
}

export enum ArrivalStatus {
  ORDER_PLACED = 'Order Placed',
  ARRIVED = 'Arrived',
  PICKED_UP = 'Picked Up',
}

export enum DeliveryStatus {
  PENDING = 'Pending',
  PICKUP_IN_PROCESS = 'PickupInProgress',
  DELIVERY_IN_PROGRESS = 'DeliveryInProgress',
  DELIVERED = 'Delivered',
  CANCELED = 'Canceled',
  SCHEDULED = 'Scheduled',
}
