<div class="w-100">
  <app-navbar navbarOrientation="minimal"></app-navbar>
  <div
    class="go-shell bg-white d-flex border-top border-nav grow-1 position-relative d-flex flex-column">
    <div class="container d-flex justify-content-center py-5 position-relative">
      <div class="component-width">
        <ng-container [ngSwitch]="section">
          <app-go-start
            *ngSwitchCase="'create'"
            (startClicked)="startGroupOrder($event)"
            [availableDeadlineTimes]="availableDeadlineTimes"></app-go-start>
          <app-go-edit
            *ngSwitchCase="'update'"
            [groupOrder]="(groupOrder$ | async)!"
            (updateClicked)="updateGroupOrder($event)"
            [availableDeadlineTimes]="availableDeadlineTimes"></app-go-edit>
          <app-go-join
            *ngSwitchCase="'join'"
            [groupOrder]="(groupOrder$ | async)!"
            (joinClicked)="joinGroupOrder($event)"></app-go-join>
          <app-go-guest *ngSwitchCase="'guest'"></app-go-guest>
        </ng-container>
      </div>
    </div>
  </div>
  <!--  <app-footer [footerStyle]="'simplified'"></app-footer>-->
</div>
