import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import {
  CloseCart,
  CloseMenu,
  OpenCart,
  OpenMenu,
  SetFullExperience,
  SetInitialLoadComplete,
  ToggleMenu,
} from '../actions/app.actions';

export interface AppStateModel {
  cartOpen: boolean;
  menuOpen: boolean;
  isFullExperience: boolean;
  initialLoadComplete: boolean;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    cartOpen: false,
    menuOpen: false,
    isFullExperience: true,
    initialLoadComplete: false,
  },
})
@Injectable()
export class AppState {
  @Action(OpenCart)
  openCart(ctx: StateContext<AppStateModel>, action: OpenCart) {
    return ctx.patchState({
      cartOpen: true,
    });
  }

  @Action(CloseCart)
  closeCart(ctx: StateContext<AppStateModel>, action: CloseCart) {
    return ctx.patchState({
      cartOpen: false,
    });
  }

  @Action(OpenMenu)
  openMenu(ctx: StateContext<AppStateModel>, action: OpenMenu) {
    return ctx.patchState({
      menuOpen: true,
    });
  }

  @Action(CloseMenu)
  closeMenu(ctx: StateContext<AppStateModel>, action: CloseMenu) {
    return ctx.patchState({
      menuOpen: false,
    });
  }

  @Action(ToggleMenu)
  toggleMenu(ctx: StateContext<AppStateModel>, action: ToggleMenu) {
    return ctx.patchState({
      menuOpen: !ctx.getState().menuOpen,
    });
  }

  @Action(SetFullExperience)
  setFullExperience(
    ctx: StateContext<AppStateModel>,
    action: SetFullExperience,
  ) {
    return ctx.patchState({
      isFullExperience: action.isFullExperience,
    });
  }

  @Action(SetInitialLoadComplete)
  setInitialLoadComplete(
    ctx: StateContext<AppStateModel>,
    action: SetInitialLoadComplete,
  ) {
    return ctx.patchState({
      initialLoadComplete: true,
    });
  }
}
