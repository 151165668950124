import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@modules/auth/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { from } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['sign-in.component.scss'],
})
export class SignInComponent implements AfterViewInit {
  loginForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.email, Validators.required]),
    password: new FormControl(null, [Validators.required]),
  });

  loginLoading = false;
  facebookLoginLoading = false;

  errorMessage = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
  ) {}

  ngAfterViewInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['security_token']) {
        this.spinner.show('walking-burrito');
        this.auth.loginWithCode(params['security_token']).subscribe({
          next: () => this.router.navigate([''], { fragment: 'orders' }),
          error: () => this.spinner.hide('walking-burrito'),
        });
      }
    });
  }

  login() {
    this.loginLoading = true;
    if (this.loginForm.valid) {
      this.spinner.show('walking-burrito');
      this.auth.login(this.email!.value, this.password!.value).subscribe({
        next: () => {
          this.loginLoading = false;
          if (this.auth.shouldRouteBackToGroupOrder()) {
            this.router.navigate(['order', 'group-order'], {
              fragment: 'create',
            });
          } else {
            this.router.navigate([''], { fragment: 'orders' });
          }
        },
        error: (error) => {
          this.loginLoading = false;
          this.spinner.hide('walking-burrito');
          this.errorMessage =
            error?.error?.message ??
            error.message ??
            'Email or Password Incorrect';
        },
      });
    } else {
      this.loginForm.markAllAsTouched();
      this.loginLoading = false;
    }
  }

  loginWithFacebook() {
    this.facebookLoginLoading = true;
    this.auth.loginWithFacebook().subscribe({
      next: () => {
        this.facebookLoginLoading = false;
        this.router.navigate([''], { fragment: 'orders' });
      },
      error: (err) => {
        this.facebookLoginLoading = false;
        this.errorMessage = err;
      },
    });
  }

  get email(): AbstractControl {
    return this.loginForm.get('email')!;
  }
  get password(): AbstractControl {
    return this.loginForm.get('password')!;
  }
}
