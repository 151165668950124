<div class="text-center pb-4 d-flex flex-column align-items-center">
  <h1 class="section-title display-5 text-primary w-fit-content px-4 pt-3">
    {{ featuredGroup.item?.name }}
  </h1>

  <p class="section-description lead" *ngIf="featuredGroup.item?.subtitle">
    {{ featuredGroup.item!.subtitle }}
  </p>
</div>

<div class="shell">
  <ng-container *ngFor="let featuredItem of featuredGroup.item?.items">
    <app-sm-product-card
      [featuredItem]="featuredItem"
      *ngIf="featuredItem"></app-sm-product-card>
  </ng-container>
</div>

<div class="pt-4 pb-5 w-100">
  <hr class="w-100 my-0" />
</div>
