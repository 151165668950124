import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['sign-in-form.component.scss'],
})
export class SignInFormComponent {
  @Input() parentForm!: FormGroup;
  @Input() loginLoading = false;
  @Input() facebookLoading = false;
  @Input() errorMessage = '';
  @Input() showHeading = true;

  @Output() loginClick = new EventEmitter<void>();
  @Output() loginFacebookClick = new EventEmitter<void>();
}
