import { Component, Input, OnInit } from '@angular/core';
import { SavedCard, User } from '@models/index';
import { CreditCardType } from '@server/vendor/olo/interfaces';

@Component({
  selector: 'app-profile-dashboard-cards',
  templateUrl: './profile-dashboard-cards.component.html',
  styleUrls: ['profile-dashboard-cards.component.scss'],
})
export class ProfileDashboardCardsComponent implements OnInit {
  isEmpty = false;

  @Input() section!:
    | 'orderHistory'
    | 'paymentMethods'
    | 'faveLocations'
    | 'accountInfo'
    | 'inbox';

  @Input() user!: User;

  constructor() {}
  ngOnInit() {}
}
