import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '@modules/auth/services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  showSubmit = false;

  @Input() forgotPassword:
    | 'forgot-password-default'
    | 'forgot-password-submitted' = 'forgot-password-default';

  forgotPasswordForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
  });

  constructor(private auth: AuthService) {}

  onForgotPassword() {
    this.auth.forgotPassword(this.email.value!).subscribe(() => {
      this.showSubmit = true;
    });
  }

  reset() {
    this.forgotPasswordForm.reset();
    this.showSubmit = false;
  }

  get email(): AbstractControl {
    return this.forgotPasswordForm.get('email')!;
  }
}
