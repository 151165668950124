<!--<div-->
<!--  class="backdrop"-->
<!--  (click)="toggleVisibility()"-->
<!--  [style.display]="(menuOpen$ | async) ? 'block' : 'none'"-->
<!--&gt;</div>-->
<!-- announcement bar -->
<div class="navbar-position">
  <ng-container [ngSwitch]="navbarOrientation">
    <ng-container *ngIf="showAnnouncement">
      <ng-container *ngIf="scheduledAnnouncement$ | async as announcement">
        <carousel class="announcement-carousel" [showIndicators]="showIndicator">
          <ng-container *ngFor="let item of announcement">
            <slide>
              <app-announcement-bar [announcement]="item"></app-announcement-bar>
            </slide>
          </ng-container>
        </carousel>
      </ng-container>
    </ng-container>
    <nav *ngSwitchDefault class="navbar-shell">
      <div class="hamburger-logo-shell">
        <button
          type="button"
          class="navbar-icon-shell d-flex d-xl-none"
          (click)="toggleVisibility()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="navbar-hamburger-icon"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </button>
        <ng-container *ngIf="(menuOpen$ | async)!">
          <app-hamburger-menu
            [visible]="(menuOpen$ | async)!"
            header="Freebirds"
            (hideMenu)="menuHide()"
            [user]="(user$ | async)!">
          </app-hamburger-menu>
        </ng-container>

        <a
          routerLink="/"
          class="d-none d-md-flex align-items-center text-decoration-none h-auto">
          <img
            class="navbar-logo"
            src="/assets/images/logos/Freebirds_Logos_Digital_PrimaryLogo_Carbon_RGB.svg"
            alt="Freebirds Logo"/>
        </a>
        <ng-container
          *ngIf="
            !((groupOrder$ | async) && (isGroupOrderOwner$ | async) === false)
          ">
          <!-- sign in/account -->
          <ng-container
            *ngIf="user$ | async as user; else noUserSignInDropdown">
            <div
              class="d-none d-sm-flex flex-row align-items-center gap-2"
              dropdown
              container="body">
              <button
                type="button"
                class="border-0 p-0 bg-transparent d-flex flex-row flex-nowrap align-items-center"
                aria-controls="user-dropdown"
                dropdownToggle>
                <div class="position-relative">
                  <img
                    class="navbar-guest-icon"
                    src="assets/images/icons/Icons/fb-walking-burrito.svg"
                    alt="guest account button"/>
                </div>
                <a
                  class="d-flex align-items-center text-decoration-none navbar-links">
                  <div class="user-link-text d-none d-md-flex">
                    Hi, {{ user.first_name }}
                  </div>
                </a>
              </button>
              <ul
                id="user-dropdown"
                *dropdownMenu
                class="dropdown-menu d-flex flex-column gap-1"
                role="menu"
                aria-labelledby="button-basic">
                <li role="menuitem">
                  <a
                    class="dropdown-item position-relative"
                    routerLink="/rewards"
                    [routerLinkActive]="['active']">
                    Rewards&nbsp;
                    <!-- <span class="badge bg-danger">
                      98
                      <span class="visually-hidden">
                        available rewards
                      </span>
                    </span> -->
                  </a>
                </li>
                <li role="menuitem">
                  <a
                    class="dropdown-item"
                    routerLink="/profile"
                    fragment="orderHistory"
                    [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{
                      fragment: 'exact',
                      matrixParams: 'ignored',
                      queryParams: 'ignored',
                      paths: 'exact'
                    }"
                  >Past Orders</a
                  >
                </li>
                <li role="menuitem">
                  <a
                    class="dropdown-item"
                    routerLink="/profile"
                    fragment="accountInfo"
                    [routerLinkActiveOptions]="{
                      fragment: 'exact',
                      matrixParams: 'ignored',
                      queryParams: 'ignored',
                      paths: 'exact'
                    }"
                    [routerLinkActive]="['active']"
                  >Profile</a
                  >
                </li>
                <!--            <li class="divider dropdown-divider"></li>-->
                <!--            <li role="menuitem" (click)="logout()">-->
                <!--              &lt;!&ndash;              <a class="dropdown-item"><small>Sign Out</small></a>&ndash;&gt;-->
                <!--              <a-->
                <!--                routerLink=""-->
                <!--                class="dropdown-item"-->
                <!--                [routerLinkActiveOptions]="{-->
                <!--                  fragment: 'exact',-->
                <!--                  matrixParams: 'ignored',-->
                <!--                  queryParams: 'ignored',-->
                <!--                  paths: 'exact'-->
                <!--                }"-->
                <!--                [routerLinkActive]="['active']"-->
                <!--                >Sign Out</a-->
                <!--              >-->
                <!--            </li>-->
              </ul>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #noUserSignInDropdown>
          <div
            routerLink="/user/sign-in"
            class="d-none d-sm-flex flex-row align-items-center gap-2 cursor-pointer"
            container="body">
            <button type="button" class="border-0 p-0 bg-transparent">
              <img
                class="navbar-guest-icon"
                src="/assets/images/icons/Icons/fb-walking-burrito-empty.svg"
                alt="guest account button"/>
            </button>
            <a
              class="d-flex align-items-center text-decoration-none navbar-links">
              <div class="user-link-text d-none d-md-flex">Sign In</div>
            </a>
          </div>
        </ng-template>
      </div>
      <!-- links/logo -->
      <div
        class="d-none d-xl-flex justify-content-center align-items-center flex-nowrap gap-3">
        <div
          class="d-flex align-items-center justify-content-center flex-nowrap gap-3 grow-1 basis-0">
          <a routerLink="/" fragment="menu" class="navbar-links">
            <h5 class="navbar-links-text">Order</h5>
          </a>
          <a
            href="https://catering.freebirds.com"
            target="_blank"
            class="navbar-links">
            <h5 class="navbar-links-text">Catering</h5>
          </a>
          <a routerLink="/our-menu" class="navbar-links">
            <h5 class="navbar-links-text">Menu</h5>
          </a>
          <ng-container *ngIf="user$ | async as user; else noUserRewards">
            <a
              routerLink="/rewards"
              class="navbar-links"
              [routerLinkActive]="['active']">
              <h5 class="navbar-links-text">Rewards</h5>
            </a>
          </ng-container>
          <ng-template #noUserRewards>
            <a
              routerLink="/rewards"
              class="navbar-links"
              [routerLinkActive]="['active']">
              <h5 class="navbar-links-text">Rewards</h5>
            </a>
          </ng-template>
          <!-- <a href="https://shop.freebirds.com" class="navbar-links">
            <h5 class="navbar-links-text">Our Story</h5>
          </a> -->
          <a routerLink="/nutrition-calculator" class="navbar-links">
            <h5 class="navbar-links-text">Nutrition</h5>
          </a>
          <a
            routerLink="/locations"
            target="_blank"
            class="navbar-links">
            <h5 class="navbar-links-text">Locations</h5>
          </a>
          <!--          <a-->
          <!--            href="https://careers.freebirds.com/"-->
          <!--            target="_blank"-->
          <!--            class="navbar-links">-->
          <!--            <h5 class="navbar-links-text">Careers</h5>-->
          <!--          </a>-->
        </div>
      </div>

      <!-- order now -->
      <div class="d-flex justify-content-end gap-3 grow-1 basis-0">
        <ng-container
          *ngIf="
            !((groupOrder$ | async) && (isGroupOrderOwner$ | async) === false)
          ">
          <ng-container *ngIf="order$ | async as order; else startOrder">
            <button
              type="button"
              class="navbar-location-btn"
              routerLink="/start-order">
              <ng-container
                *ngIf="order.deliverymode !== 'dispatch'; else isDelivery">
                <div class="d-flex">
                  <img
                    class="order-type-icon"
                    src="/assets/images/logos/fb-logo-mark-carbon.svg"
                    alt="freebirds logo"/>
                </div>
                <div class="d-flex flex-column text-start">
                  <small class="text-uppercase">
                    {{ order.deliverymode | handoffModeName }}
                    <ng-container *ngIf="order.deliverymode !== 'drivethru'"
                    >&nbsp;From
                    </ng-container>
                  </small>
                  <div
                    *ngIf="orderLocation$ | async as orderLocation"
                    class="line-clamp-1">
                    {{ orderLocation.name }}
                  </div>
                </div>
              </ng-container>

              <ng-template #isDelivery>
                <div class="d-flex">
                  <img
                    class="order-type-icon"
                    src="/assets/images/icons/SVG/fb-deliver.svg"
                    alt="delivery icon"/>
                </div>
                <div class="d-flex flex-column text-start">
                  <small class="text-uppercase"> Delivering To </small>
                  <div class="line-clamp-1">
                    {{ order.deliveryaddress.streetaddress }}
                  </div>
                </div>
              </ng-template>
            </button>
          </ng-container>

          <ng-template #startOrder>
            <button
              type="button"
              class="navbar-location-btn"
              routerLink="/start-order">
              <div class="d-flex">
                <img
                  class="order-type-icon"
                  src="/assets/images/logos/fb-logo-mark-carbon.svg"
                  alt="freebirds logo"/>
              </div>
              <div class="d-flex flex-column text-start">
                <small class="text-uppercase">Find Your</small>
                <div class="text-uppercase">Freebirds</div>
              </div>
            </button>
          </ng-template>
        </ng-container>

        <button
          type="button"
          class="navbar-icon-shell position-relative"
          (click)="openCart()">
          <ng-container *ngIf="order$ | async as order">
            <ng-container *ngIf="order.products.length">
              <span
                class="position-absolute top-0 end-0 badge rounded-pill bg-secondary">
                {{ order.products.length }}
                <span class="visually-hidden">items in cart</span>
              </span>
            </ng-container>
          </ng-container>
          <img
            class="navbar-bag-icon"
            [src]="
              (order$ | async)?.products?.length
                ? 'assets/images/icons/Icons/fb-cart-full.svg'
                : 'assets/images/icons/Icons/fb-cart-empty.svg'
            "
            alt="Bag"/>
        </button>
      </div>
    </nav>

    <nav *ngSwitchCase="'customPage'" class="navbar-shell">
      <div class="hamburger-logo-shell">
        <button
          type="button"
          class="navbar-icon-shell d-flex d-xl-none"
          (click)="toggleVisibility()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="navbar-hamburger-icon"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </button>
        <ng-container *ngIf="(menuOpen$ | async)!">
          <app-hamburger-menu
            [visible]="(menuOpen$ | async)!"
            header="Freebirds"
            (hideMenu)="menuHide()"
            [user]="(user$ | async)!">
          </app-hamburger-menu>
        </ng-container>

        <a
          href="https://www.freebirds.com/"
          target="_blank"
          class="d-none d-md-flex align-items-center text-decoration-none h-auto">
          <img
            class="navbar-logo"
            src="/assets/images/logos/Freebirds_Logos_Digital_PrimaryLogo_Carbon_RGB.svg"
            alt="Freebirds Logo"/>
        </a>
        <ng-container
          *ngIf="
            !((groupOrder$ | async) && (isGroupOrderOwner$ | async) === false)
          ">
          <!-- sign in/account -->
          <ng-container
            *ngIf="user$ | async as user; else noUserSignInDropdown">
            <div
              class="d-none d-sm-flex flex-row align-items-center gap-2"
              dropdown
              container="body">
              <button
                type="button"
                class="border-0 p-0 bg-transparent d-flex flex-row flex-nowrap align-items-center"
                aria-controls="user-dropdown"
                dropdownToggle>
                <div class="position-relative">
                  <img
                    class="navbar-guest-icon"
                    src="assets/images/icons/Icons/fb-walking-burrito.svg"
                    alt="guest account button"/>
                </div>
                <a
                  class="d-flex align-items-center text-decoration-none navbar-links">
                  <div class="user-link-text d-none d-md-flex">
                    Hi, {{ user.first_name }}
                  </div>
                </a>
              </button>
              <ul
                id="user-dropdown"
                *dropdownMenu
                class="dropdown-menu d-flex flex-column gap-1"
                role="menu"
                aria-labelledby="button-basic">
                <li role="menuitem">
                  <a
                    class="dropdown-item position-relative"
                    routerLink="/rewards"
                    [routerLinkActive]="['active']">
                    Rewards&nbsp;
                    <!-- <span class="badge bg-danger">
                      98
                      <span class="visually-hidden">
                        available rewards
                      </span>
                    </span> -->
                  </a>
                </li>
                <li role="menuitem">
                  <a
                    class="dropdown-item"
                    routerLink="/profile"
                    fragment="orderHistory"
                    [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{
                      fragment: 'exact',
                      matrixParams: 'ignored',
                      queryParams: 'ignored',
                      paths: 'exact'
                    }"
                  >Past Orders</a
                  >
                </li>
                <li role="menuitem">
                  <a
                    class="dropdown-item"
                    routerLink="/profile"
                    fragment="accountInfo"
                    [routerLinkActiveOptions]="{
                      fragment: 'exact',
                      matrixParams: 'ignored',
                      queryParams: 'ignored',
                      paths: 'exact'
                    }"
                    [routerLinkActive]="['active']"
                  >Profile</a
                  >
                </li>
                <!--            <li class="divider dropdown-divider"></li>-->
                <!--            <li role="menuitem" (click)="logout()">-->
                <!--              &lt;!&ndash;              <a class="dropdown-item"><small>Sign Out</small></a>&ndash;&gt;-->
                <!--              <a-->
                <!--                routerLink=""-->
                <!--                class="dropdown-item"-->
                <!--                [routerLinkActiveOptions]="{-->
                <!--                  fragment: 'exact',-->
                <!--                  matrixParams: 'ignored',-->
                <!--                  queryParams: 'ignored',-->
                <!--                  paths: 'exact'-->
                <!--                }"-->
                <!--                [routerLinkActive]="['active']"-->
                <!--                >Sign Out</a-->
                <!--              >-->
                <!--            </li>-->
              </ul>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #noUserSignInDropdown>
          <a
            href="https://www.freebirds.com/user/sign-in"
            target="_blank"
            class="d-none d-sm-flex flex-row align-items-center gap-2 cursor-pointer text-decoration-none"
            container="body">
            <button type="button" class="border-0 p-0 bg-transparent">
              <img
                class="navbar-guest-icon"
                src="/assets/images/icons/Icons/fb-walking-burrito-empty.svg"
                alt="guest account button"/>
            </button>
            <a
              class="d-flex align-items-center text-decoration-none navbar-links">
              <div class="user-link-text d-none d-md-flex">Sign In</div>
            </a>
          </a>
        </ng-template>
      </div>
      <!-- links/logo -->
      <div
        class="d-none d-xl-flex justify-content-center align-items-center flex-nowrap gap-3 container-sm">
        <div
          class="d-flex align-items-center justify-content-center flex-nowrap gap-3 grow-1 basis-0">
          <a
            href="https://www.freebirds.com/"
            target="_blank"
            fragment="menu"
            class="navbar-links">
            <h5 class="navbar-links-text">Order</h5>
          </a>
          <a
            href="https://catering.freebirds.com"
            target="_blank"
            class="navbar-links">
            <h5 class="navbar-links-text">Catering</h5>
          </a>
          <a
            href="https://www.freebirds.com/our-menu"
            target="_blank"
            class="navbar-links">
            <h5 class="navbar-links-text">Menu</h5>
          </a>
          <ng-container *ngIf="user$ | async as user; else noUserRewards">
            <a
              href="https://www.freebirds.com/rewards"
              target="_blank"
              class="navbar-links">
              <h5 class="navbar-links-text">Rewards</h5>
            </a>
          </ng-container>
          <ng-template #noUserRewards>
            <a
              href="https://www.freebirds.com/rewards"
              target="_blank"
              class="navbar-links">
              <h5 class="navbar-links-text">Rewards</h5>
            </a>
          </ng-template>
          <!-- <a href="https://shop.freebirds.com" class="navbar-links">
            <h5 class="navbar-links-text">Our Story</h5>
          </a> -->
          <a
            href="https://www.freebirds.com/nutrition-calculator"
            target="_blank"
            class="navbar-links">
            <h5 class="navbar-links-text">Nutrition</h5>
          </a>
          <a
            href="https://www.freebirds.com/locations"
            target="_blank"
            class="navbar-links">
            <h5 class="navbar-links-text">Locations</h5>
          </a>
          <!--          <a-->
          <!--            href="https://careers.freebirds.com/"-->
          <!--            target="_blank"-->
          <!--            class="navbar-links">-->
          <!--            <h5 class="navbar-links-text">Careers</h5>-->
          <!--          </a>-->
        </div>
      </div>

      <!-- order now -->
      <div class="d-flex grow-1 basis-0 justify-content-end gap-3">
        <ng-container
          *ngIf="
            !((groupOrder$ | async) && (isGroupOrderOwner$ | async) === false)
          ">
          <ng-container *ngIf="order$ | async as order; else startOrder">
            <a
              href="https://www.freebirds.com/start-order"
              target="_blank"
              class="navbar-location-btn text-decoration-none">
              <ng-container
                *ngIf="order.deliverymode !== 'dispatch'; else isDelivery">
                <div class="d-flex">
                  <img
                    class="order-type-icon"
                    src="/assets/images/logos/fb-logo-mark-carbon.svg"
                    alt="freebirds logo"/>
                </div>
                <div class="d-flex flex-column text-start">
                  <small class="text-uppercase">
                    {{ order.deliverymode | handoffModeName }}
                    <ng-container *ngIf="order.deliverymode !== 'drivethru'"
                    >&nbsp;From
                    </ng-container>
                  </small>
                  <div
                    *ngIf="orderLocation$ | async as orderLocation"
                    class="line-clamp-1">
                    {{ orderLocation.name }}
                  </div>
                </div>
              </ng-container>

              <ng-template #isDelivery>
                <div class="d-flex">
                  <img
                    class="order-type-icon"
                    src="/assets/images/icons/SVG/fb-deliver.svg"
                    alt="delivery icon"/>
                </div>
                <div class="d-flex flex-column text-start">
                  <small class="text-uppercase"> Delivering To </small>
                  <div class="line-clamp-1">
                    {{ order.deliveryaddress.streetaddress }}
                  </div>
                </div>
              </ng-template>
            </a>
          </ng-container>

          <ng-template #startOrder>
            <button
              type="button"
              class="navbar-location-btn"
              routerLink="/start-order">
              <div class="d-flex">
                <img
                  class="order-type-icon"
                  src="/assets/images/logos/fb-logo-mark-carbon.svg"
                  alt="freebirds logo"/>
              </div>
              <div class="d-flex flex-column text-start">
                <small class="text-uppercase">Find Your</small>
                <div class="text-uppercase">Freebirds</div>
              </div>
            </button>
          </ng-template>
        </ng-container>

        <button
          type="button"
          class="navbar-icon-shell position-relative"
          (click)="openCart()">
          <ng-container *ngIf="order$ | async as order">
            <ng-container *ngIf="order.products.length">
              <span
                class="position-absolute top-0 end-0 badge rounded-pill bg-secondary">
                {{ order.products.length }}
                <span class="visually-hidden">items in cart</span>
              </span>
            </ng-container>
          </ng-container>
          <img
            class="navbar-bag-icon"
            [src]="
              (order$ | async)?.products?.length
                ? 'assets/images/icons/Icons/fb-cart-full.svg'
                : 'assets/images/icons/Icons/fb-cart-empty.svg'
            "
            alt="Bag"/>
        </button>
      </div>
    </nav>

    <nav *ngSwitchCase="'dark'" class="dark navbar-shell">
      <div class="d-flex align-items-center grow-1 basis-0 gap-3">
        <button
          type="button"
          class="navbar-icon-shell d-flex d-xl-none"
          (click)="toggleVisibility()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="navbar-hamburger-icon"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
        </button>
        <ng-container *ngIf="(menuOpen$ | async)!">
          <app-hamburger-menu
            [visible]="(menuOpen$ | async)!"
            position="left"
            header="Freebirds"
            (hideMenu)="menuHide()">
          </app-hamburger-menu>
          <!-- sign in/account -->
        </ng-container>

        <ng-container *ngIf="user$ | async; else noUserSignInDropdown">
          <div
            class="d-none d-sm-flex flex-row align-items-center gap-2"
            dropdown
            container="body">
            <button
              type="button"
              class="border-0 p-0 bg-transparent"
              aria-controls="user-dropdown"
              dropdownToggle>
              <img
                class="navbar-account-icon"
                src="assets/images/icons/Icons/fb-walking-burrito.svg"
                alt="guest account button"/>
            </button>
            <ul
              id="user-dropdown"
              *dropdownMenu
              class="dropdown-menu"
              role="menu"
              aria-labelledby="button-basic">
              <li role="menuitem">
                <a class="dropdown-item" href="#">Rewards</a>
              </li>
              <li role="menuitem">
                <a class="dropdown-item" href="#">Past Orders</a>
              </li>
              <li role="menuitem">
                <a class="dropdown-item" routerLink="/profile">Profile</a>
              </li>
              <!--            <li class="divider dropdown-divider"></li>-->
              <!--            <li role="menuitem">-->
              <!--              &lt;!&ndash;              <a class="dropdown-item" href="#"><small>Sign Out</small></a>&ndash;&gt;-->
              <!--              <a class="dropdown-item" href="#">Sign Out</a>-->
              <!--            </li>-->
            </ul>
            <a
              href="#"
              class="d-flex align-items-center text-decoration-none navbar-links">
              <div class="user-link-text d-none d-md-flex">Hi Elliott</div>
            </a>
          </div>
        </ng-container>
        <ng-template #noUserSignInDropdown>
          <div
            routerLink="/user/sign-in"
            class="d-none d-sm-flex flex-row align-items-center gap-2 cursor-pointer"
            container="body">
            <button type="button" class="border-0 p-0 bg-transparent">
              <img
                class="navbar-guest-icon"
                src="/assets/images/icons/Icons/fb-walking-burrito-empty.svg"
                alt="guest account button"/>
            </button>
            <a
              class="d-flex align-items-center text-decoration-none navbar-links">
              <div class="user-link-text d-none d-md-flex">Sign In</div>
            </a>
          </div>
        </ng-template>
      </div>
      <!-- links/logo -->
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap gap-3 container-sm">
        <div
          class="d-none d-xl-flex align-items-center justify-content-left flex-nowrap gap-4 grow-1 basis-0">
          <a href="#" class="navbar-links grow-1">
            <h5 class="navbar-links-text">Order</h5>
          </a>
          <a routerLink="/locations" class="navbar-links grow-1">
            <h5 class="navbar-links-text">Locations</h5>
          </a>
          <a href="#" class="navbar-links grow-1">
            <h5 class="navbar-links-text">Catering</h5>
          </a>
        </div>
        <a
          [routerLink]="''"
          class="d-flex align-items-center text-decoration-none h-auto">
          <img
            class="navbar-logo"
            src="/assets/images/logos/Freebirds_Logos_Digital_PrimaryLogo_Carbon_RGB.svg"
            alt=""/>
        </a>
        <div
          class="d-none d-xl-flex align-items-center justify-content-right flex-nowrap gap-4 grow-1 basis-0 text-right">
          <a href="#" class="navbar-links grow-1">
            <h5 class="navbar-links-text">Rewards</h5>
          </a>
          <a href="#" class="navbar-links grow-1">
            <h5 class="navbar-links-text">Gift Cards</h5>
          </a>
          <a href="#" class="navbar-links grow-1">
            <h5 class="navbar-links-text">Merch</h5>
          </a>
        </div>
      </div>

      <!-- order now -->
      <div class="d-flex grow-1 basis-0 justify-content-end gap-3">
        <!-- <button type="button" class="navbar-location-button text-left d-flex flex-nowrap align-items-center gap-2">
          <div class="find-location-image d-none d-md-flex">
            <img style="height: 100%; width: 100%; min-width: 0;"
              src="/assets/images/location-specific_illustrations/Icons/asset-15.svg" alt="">
          </div>
          <div class="font-weight-bold text-uppercase">Start Order</div>
        </button> -->
        <ng-container *ngIf="order$ | async as order; else noOrder">
          <button
            type="button"
            class="navbar-icon-shell position-relative"
            (click)="openCart()">
            <ng-container *ngIf="order$ | async as order">
              <ng-container *ngIf="order.products.length">
                <span
                  class="position-absolute top-0 end-0 badge rounded-pill bg-secondary">
                  {{ order.products.length }}
                  <span class="visually-hidden">items in cart</span>
                </span>
              </ng-container>
            </ng-container>
            <img
              class="navbar-bag-icon"
              [src]="
                order.products.length
                  ? 'assets/images/icons/Icons/fb-cart-full.svg'
                  : 'assets/images/icons/Icons/fb-cart-empty.svg'
              "
              alt="Bag"/>
          </button>
        </ng-container>
        <ng-template #noOrder>
          <button
            type="button"
            class="btn btn-primary text-nowrap d-flex flex-nowrap gap-1"
            routerLink="/start-order">
            Order <span class="d-none d-md-block">Now</span>
          </button>
        </ng-template>
      </div>
    </nav>

    <nav
      *ngSwitchCase="'minimal'"
      class="minimal-navbar navbar-shell px-3 py-3"
      [style.background-color]="minimalBGColor"
      [style.border-bottom]="'1px solid ' + minimalBGColor">
      <div class="d-flex flex-nowrap justify-content-between w-100 gap-3">
        <div class="basis-0 d-flex justify-content-center align-items-center">
          <a
            [routerLink]="''"
            class="d-flex align-items-center text-decoration-none h-auto">
            <img
              class="navbar-logo"
              src="/assets/images/logos/Freebirds_Logos_Digital_PrimaryLogo_Carbon_RGB.svg"
              alt=""/>
          </a>
        </div>
        <div
          class="grow-1 basis-0 d-flex justify-content-start align-items-center">
          <button
            routerLink="/"
            fragment="menu"
            type="button"
            class="back-button">
            <!--          <svg-->
            <!--            xmlns="http://www.w3.org/2000/svg"-->
            <!--            width="16"-->
            <!--            height="16"-->
            <!--            fill="currentColor"-->
            <!--            class="bi bi-chevron-left"-->
            <!--            viewBox="0 0 16 16">-->
            <!--            <path-->
            <!--              fill-rule="evenodd"-->
            <!--              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />-->
            <!--          </svg>-->
            <!--          Back-->
            Return To Menu
          </button>
        </div>

        <div
          class="basis-0 d-flex justify-content-end gap-3 align-items-center">
          <button
            type="button"
            class="navbar-icon-shell position-relative"
            (click)="openCart()"
            [@collapseHorizontally]="cartOpen$ | async">
            <ng-container *ngIf="order$ | async as order">
              <ng-container *ngIf="order.products.length">
                <span
                  class="position-absolute top-0 end-0 badge rounded-pill bg-secondary z-fixed">
                  {{ order.products.length }}
                  <span class="visually-hidden">items in cart</span>
                </span>
              </ng-container>
            </ng-container>
            <img
              class="navbar-bag-icon"
              [src]="
                (order$ | async)?.products?.length
                  ? 'assets/images/icons/Icons/fb-cart-full.svg'
                  : 'assets/images/icons/Icons/fb-cart-empty.svg'
              "
              alt="Bag"/>
          </button>
          <!--        <ng-template #noOrder>-->
          <!--          <button-->
          <!--            type="button"-->
          <!--            class="btn btn-primary text-nowrap d-flex flex-nowrap gap-1"-->
          <!--            routerLink="/start-order">-->
          <!--            Order <span class="d-none d-md-block">Now</span>-->
          <!--          </button>-->
          <!--        </ng-template>-->
        </div>
      </div>
    </nav>
  </ng-container>
</div>

<!--<div-->
<!--  class="backdrop"-->
<!--  (click)="toggleVisibility()"-->
<!--  [ngStyle]="{ display: (menuOpen$ | async) ? 'block' : 'none' }"-->
<!--&gt;</div>-->
