import {
  AddSavedProductChoice,
  NewSavedProduct,
  PastOrder,
  PaymentMethod,
} from '@models/index';
import { HandoffMode, ReceivingUser } from '@server/vendor/olo/interfaces';

export class StartOrder {
  static readonly type = '[Order] Start Order';

  constructor(public locationID: number, public skipTransfer = false) {}
}

export class AddReferralToBasket {
  static readonly type = '[Order] Add Referral to Basket';

  constructor() {}
}

export class ClearOrder {
  static readonly type = '[Order] Clear Order';
}

export class GetCurrentOrder {
  static readonly type = '[Order] Get Current Order';
  constructor(public basketID: string) {}
}

export class TransferOrder {
  static readonly type = '[Order] Transfer Order';
  constructor(public basketID: string, public locationID: number) {}
}

export class ValidateOrder {
  static readonly type = '[Order] Validate Order';
  constructor(public basketID: string) {}
}

export class AddToOrder {
  static readonly type = '[Order] Add To Order';
  constructor(
    public basketID: string,
    public productID: number,
    public quantity: number,
    public options: string[],
    public specialInstructions?: string,
    public recipient?: string,
  ) {}
}

export class AddToOrderByChainID {
  static readonly type = '[Order] Add To Order by Chain ID';
  constructor(
    public basketID: string,
    public chainProductID: number,
    public quantity: number,
    public choices: AddSavedProductChoice[],
    public specialInstructions?: string,
    public recipient?: string,
  ) {}
}

export class RemoveFromOrder {
  static readonly type = '[Order]';
  constructor(public basketID: string, public basketProductID: number) {}
}

export class UpdateOrderItem {
  static readonly type = '[Order] Update Order Item';
  constructor(
    public basketID: string,
    public basketProductID: number,
    public productID: number,
    public quantity: number,
    public options: string[],
    public specialInstructions?: string,
    public recipient?: string,
  ) {}
}

export class SetTimeWanted {
  static readonly type = '[Order] Set Time Wanted';

  constructor(public basketID: string, public timeWanted: Date) {}
}

export class SetTimeWantedToASAP {
  static readonly type = '[Order] Set Time Wanted to ASAP';

  constructor(public basketID: string) {}
}

export class SetHandoffMode {
  static readonly type = '[Order] Set Handoff Mode';

  constructor(public basketID: string, public handoffMode: HandoffMode) {}
}

export class SetCustomField {
  static readonly type = '[Order] Set Custom Field';

  constructor(
    public basketID: string,
    public customFieldID: number,
    public value: string,
  ) {}
}

export class SetDeliveryAddress {
  static readonly type = '[Order] Set Delivery Address';

  constructor(
    public basketID: string,
    public handoffMode: HandoffMode.DELIVERY | HandoffMode.DISPATCH,
    public addressID?: number,
    public building?: string,
    public streetAddress?: string,
    public city?: string,
    public zipCode?: string,
    public specialInstructions?: string,
    public isDefault?: boolean,
  ) {}
}

export class SetTip {
  static readonly type = '[Order] Set Tip';

  constructor(public basketID: string, public amount: number) {}
}

export class SubmitBasket {
  static readonly type = '[Order] Submit Basket';
  constructor(
    public basketID: string,
    public billingAccounts: PaymentMethod[],
    public authToken?: string,
    public user?: ReceivingUser,
    public guestOptIn?: boolean,
    public createdNewAccount?: boolean,
  ) {}
}

export class SetCoupon {
  static readonly type = '[Order] Set Coupon';

  constructor(public basketID: string, public code: string) {}
}

export class RemoveCoupon {
  static readonly type = '[Order] Remove Coupon';

  constructor(public basketID: string) {}
}

export class SetPreviousOrder {
  static readonly type = '[Order] Set Previous Order';
  constructor(public orderID: string) {}
}

export class SetDeliveryStatus {
  static readonly type = '[Order] Set Delivery Status';
  constructor(public orderID: string) {}
}

export class StartOrderFromPastOrder {
  static readonly type = '[Order] Start Order From Previous Order';
  constructor(public pastOrder: PastOrder) {}
}

export class SetAvailableOrderTimes {
  static readonly type = '[Order] Set Available Order Times';

  constructor(public basketID: string, public date: Date) {}
}

/**
 * Olo specific rewards
 */
export class SetAvailableRewards {
  static readonly type = '[Order] Set Available Rewards';

  constructor(public basketID: string, public orderingToken: string) {}
}

export class ApplyReward {
  static readonly type = '[Order] Apply Reward';

  constructor(
    public basketID: string,
    public rewardReference: string,
    public orderingToken: string,
  ) {}
}

export class RemoveReward {
  static readonly type = '[Order] Remove Reward';

  constructor(public basketID: string, public rewardID: number) {}
}

export class StageNutritionProduct {
  static readonly type = '[Order] Stage Nutrition Product';

  constructor(
    public chainProductID: number,
    public quantity: number,
    public choices: AddSavedProductChoice[],
    public specialInstructions?: string,
    public recipient?: string,
  ) {}
}

export class StartGroupOrder {
  static readonly type = '[Order] Start Group Order';
  constructor(public deadline: Date, public note?: string) {}
}

export class GetCurrentGroupOrder {
  static readonly type = '[Order] Get Current Group Order';
  constructor(
    public groupOrderID: string,
    public orderingToken?: string,
    public basketID?: string,
  ) {}
}

export class UpdateGroupOrder {
  static readonly type = '[Order] Update Group Order';
  constructor(
    public groupOrderID: string,
    public deadline: Date,
    public note?: string,
  ) {}
}

export class JoinGroupOrder {
  static readonly type = '[Order] Join Group Order';
  constructor(public name: string) {}
}

export class LeaveGroupOrder {
  static readonly type = '[Order] Leave Group Order';
}

export class CancelGroupOrder {
  static readonly type = '[Order] Cancel Group Order';
}

export class CompleteGroupOrder {
  static readonly type = '[Order] Complete Group Order';
}
