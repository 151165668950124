<div class="modal-header align-items-center rounded-top">
  <img
    class="h4-birds"
    src="assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="" />
  <h4 class="modal-title grow-1">Share Link</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1">
  <h6>We tried to copy the link for you, but it didn't work...</h6>
  <div class="font-weight-bold m-0 text-light">
    Invite others to your group by sharing this link:
  </div>
  <div class="px-2 py-2 bg-white w-100 rounded">
    {{ textToCopy }}
  </div>
</div>

<div class="modal-footer rounded-bottom">
  <div class="d-flex justify-content-end w-100">
    <app-button
      [classes]="'btn btn-secondary'"
      (buttonClick)="bsModalRef.hide()"
      >Close
    </app-button>
  </div>
</div>
