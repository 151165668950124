<ng-container *ngIf="product">
  <div class="add-cta py-3 bg-light">
    <div class="container d-flex">
      <!--      <div class="d-flex flex-column flex-wrap align-items-start">-->

      <div
        class="d-none d-sm-flex flex-column flex-wrap align-items-start justify-content-center">
        <h5>
          {{ product.name }} -
          {{ options | selectedOptionsTotal:product | currency }}
        </h5>
        <div *ngIf="incompleteSections.length; else productComplete">
          Select {{ incompleteSections | stringJoin }}
        </div>
        <ng-template #productComplete>
          <div>Add to bag if you’re ready, don’t forget chips & dip!</div>
        </ng-template>
      </div>
      <div
        class="d-flex flex-column flex-wrap align-items-start justify-content-center d-sm-none">
        <h6>
          {{ product.name }} -
          {{ options | selectedOptionsTotal | currency }}
        </h6>
        <!--        <small *ngIf="incompleteSections.length; else productCompleteMobile">-->
        <!--          Select {{ incompleteSections | stringJoin }}-->
        <!--        </small>-->
        <!--        <ng-template #productCompleteMobile>-->
        <!--          <small>Your {{ product.name }} is ready!</small>-->
        <!--        </ng-template>-->
      </div>
      <!--      </div>-->
      <div
        class="ml-auto d-flex flex-column justify-content-center align-items-center">
        <ng-container *ngIf="isEditMode; else addToBag">
          <button
            type="button"
            [popover]="incompleteSections.length > 0 ? incomplete : undefined"
            [adaptivePosition]="true"
            class="btn btn-primary text-nowrap"
            [outsideClick]="true"
            containerClass="bg-primary font-weight-bold"
            (click)="updateItem()">
            Update Item
          </button>
        </ng-container>
        <ng-template #addToBag>
          <button
            type="button"
            [popover]="incompleteSections.length > 0 ? incomplete : undefined"
            [adaptivePosition]="true"
            class="btn btn-primary text-nowrap"
            [outsideClick]="true"
            containerClass="bg-primary font-weight-bold"
            (click)="openMealNameModal()">
            Add to Bag
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #incomplete>
  You are so close! You need to select
  {{ incompleteSections | stringJoin }}
</ng-template>
