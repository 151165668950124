import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { PageBlock } from '@models/index';

@Component({
  selector: 'app-block-paragraph',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './block-paragraph.component.html',
  styleUrls: ['block-paragraph.component.scss'],
})
export class BlockParagraphComponent {
  @Input() pageBlock!: PageBlock;
}
