import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-pin',
  templateUrl: './location-pin.component.html',
  styleUrls: ['location-pin.component.scss'],
})
export class LocationPinComponent implements OnInit {
  isAvailable = true;

  constructor() {}
  ngOnInit() {}
}
