import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { AvailableReward, GlobalStateModel } from '@models/index';
import { Store } from '@ngxs/store';
import { ApplyReward, RemoveReward } from '@store/actions/order.actions';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-reward-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './order-reward-card.component.html',
  styleUrls: ['order-reward-card.component.scss'],
})
export class OrderRewardCardComponent {
  @Input() isApplied: boolean = false;
  @Input() availableReward!: AvailableReward;

  constructor(private store: Store, private toast: ToastrService) {}

  applyReward(reward: AvailableReward) {
    this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .subscribe((order) => {
        this.store
          .selectOnce((state: GlobalStateModel) => state.user.tokens)
          .subscribe((tokens) => {
            this.store
              .dispatch(
                new ApplyReward(
                  order!.id,
                  reward.reference,
                  tokens!.tokens.ordering.token,
                ),
              )
              .subscribe({
                error: (error) => {
                  if (
                    error.error?.message?.includes(
                      'selected reward is not valid for this order.',
                    )
                  ) {
                    this.toast.warning(
                      'This reward is not valid for this order. Please add the correct items.',
                    );
                  } else {
                    this.toast.error(error.error.message);
                  }
                },
              });
          });
      });
  }

  removeReward(reward: AvailableReward) {
    this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .subscribe((order) => {
        this.store.dispatch(new RemoveReward(order!.id, reward.rewardid));
      });
  }
}
