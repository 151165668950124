import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartialItem } from '@directus/sdk';
import { Featured } from '@server/vendor/directus/interfaces/directus-collection.interface';

@Component({
  selector: 'app-ad-card',
  templateUrl: './ad-card.component.html',
  styleUrls: ['ad-card.component.scss'],
})
export class AdCardComponent implements OnInit {
  @Input() adCard!: PartialItem<Featured>;

  constructor(private router: Router) {}
  ngOnInit() {}

  featuredButtonClick() {
    if (!this.adCard.open_in_new_tab) {
      if (this.adCard?.cta_link?.includes('http')) {
        window.location.href = this.adCard.cta_link;
      } else {
        this.router.navigate([this.adCard.cta_link || '/start-order']);
      }
    } else {
      const path = this.adCard.cta_link || '/start-order';

      if (path.includes('http')) {
        window.open(path, '_blank');
      } else {
        const tree = this.router.createUrlTree([path]);
        window.open(tree.toString(), '_blank');
      }
    }
  }
}
