import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Ingredient } from '@models/index';

@Component({
  selector: 'app-ingredient-pill',
  templateUrl: './ingredient-pill.component.html',
  styleUrls: ['ingredient-pill.component.scss'],
})
export class IngredientPillComponent implements OnInit {
  @Input() ingredient!: Ingredient;
  @Output() pillRemoved = new EventEmitter<any>();
  constructor() {}
  ngOnInit() {}
  removePill() {
    this.pillRemoved.emit();
  }
}
