<div
  class="w-100 p-3 d-flex flex-column flex-nowrap gap-3 rounded"
  [class.visa]="card.cardtype === 'Visa'"
  [class.mastercard]="card.cardtype === 'Mastercard'"
  [class.discover]="card.cardtype === 'Discover'"
  [class.amex]="card.cardtype === 'Amex'">
  <div
    class="d-flex flex-row flex-nowrap justify-content-between align-items-center"
    style="max-height: 60px">
    <img
      class="h-100 w-auto"
      (click)="defaultClicked()"
      [src]="
        card.isdefault
          ? 'assets/images/icons/Icons/fb-star-circle.svg'
          : 'assets/images/icons/Icons/fb-star-circle-empty.svg'
      "
      style="max-height: 60px"
      [alt]="card.isdefault ? 'Default Card' : 'Not your default card'" />
    <ng-container [ngSwitch]="card.cardtype">
      <ng-container *ngSwitchCase="'Visa'">
        <img
          class="h-100 w-auto"
          style="max-height: 60px"
          src="assets/images/cards/visa.svg"
          alt="Visa" />
      </ng-container>
      <ng-container *ngSwitchCase="'Mastercard'">
        <img
          class="h-100 w-auto"
          style="max-height: 60px"
          src="assets/images/cards/mastercard.svg"
          alt="Mastercard" />
      </ng-container>
      <ng-container *ngSwitchCase="'Discover'">
        <img
          class="h-100 w-auto"
          style="max-height: 60px"
          src="assets/images/cards/discover.svg"
          alt="Discover" />
      </ng-container>
      <ng-container *ngSwitchCase="'Amex'">
        <img
          class="h-100 w-auto"
          style="max-height: 60px"
          src="assets/images/cards/amex.svg"
          alt="American Express" />
      </ng-container>
    </ng-container>
  </div>
  <div
    style="
      font-size: 1.25rem;
      font-family: 'courier-prime', monospace;
      text-align: right;
      height: 100%;
      display: grid;
      place-items: center end;
    ">
    **** **** **** {{ card.cardsuffix }}
  </div>
  <div
    class="d-flex flex-row flex-nowrap justify-content-between align-items-center"
    style="max-height: 60px">
    <app-button
      [classes]="
        card.cardtype === 'Visa' ? 'btn btn-danger' : 'btn btn-outline-danger'
      "
      (buttonClick)="deleteClicked()"
      >Delete</app-button
    >
    <div
      class="d-flex flex-column flex-nowrap justify-content-end align-items-end">
      <small style="font-family: 'courier-prime', monospace">EXPIRES</small>
      <div>
        {{ card.expiration | slice: 5 }}/{{ card.expiration | slice: 2:4 }}
      </div>
    </div>
  </div>
</div>
