import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AppCommonService } from '@common/services';
import { GlobalStateModel, Ingredient, Product, User } from '@models/index';
import { Select, Store } from '@ngxs/store';
import { AddToOrder, AddToOrderByChainID } from '@store/actions/order.actions';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { first, forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-quick-add-modal',
  templateUrl: './quick-add-modal.component.html',
  styleUrls: ['quick-add-modal.component.scss'],
})
export class QuickAddModalComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.user.user)
  user$!: Observable<User>;

  @Select((state: GlobalStateModel) => state.order.groupOrderName)
  groupOrderName$!: Observable<string | null>;

  @Input() product!: Product;

  mealNameForm: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    instructions: new FormControl(null, []),
    saveAsFavorite: new FormControl(false, []),
    favoriteName: new FormControl(null, []),
  });

  inputnumber = 1;
  status: boolean = false;
  selectedIngredients: Partial<Ingredient>[] = [];
  addingToBag: boolean = false;
  upsellProduct?: Product;
  addUpsell: '0' | '1' = '0';

  constructor(
    public bsModalRef: BsModalRef,
    private store: Store,
    private appCommon: AppCommonService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {

    if (this.product && this.product.content.upsell_automatically_selected) {
      this.addUpsell = '1'; // Automatically select upsell
    } else {
      this.addUpsell = '0'; // Do not select upsell
    }

    console.log(this.addUpsell);

    if (this.product.content.upsell_recommendation) {
      this.appCommon
        .getProduct(
          this.product.content.upsell_recommendation.olo_chain_product_id,
        )
        .subscribe((product) => {
          this.upsellProduct = product;
        });
    }
    if (this.product.optiongroups?.length) {
      this.product.optiongroups[0].options.forEach((option) => {
        if (option.isdefault) {
          this.ingredientSelect(option as Partial<Ingredient>);
        }
      });
    }
    forkJoin([
      this.user$.pipe(first()),
      this.groupOrderName$.pipe(first()),
    ]).subscribe(([user, groupOrderName]) => {
      if (groupOrderName && !user) {
        this.mealNameForm.patchValue({
          name: groupOrderName,
        });
        this.name.disable();
      } else {
        if (user) {
          this.mealNameForm.patchValue({
            name: groupOrderName ?? `${user.first_name} ${user.last_name[0]}.`,
            favoriteName: `${user.first_name}'s ${this.product.name}`,
          });
        }
        if (groupOrderName) {
          this.name.disable();
        }
      }
    });
  }

  minus(event: Event) {
    event.stopPropagation();
    if (this.inputnumber > 1) {
      this.inputnumber = this.inputnumber - 1;
    } else {
      this.showSuccess();
    }
  }

  plus(event: Event) {
    event.stopPropagation();
    this.inputnumber = this.inputnumber + 1;
  }

  showSuccess() {
    this.status = !this.status;
  }

  ingredientSelect(ingredient: Partial<Ingredient>) {
    this.selectedIngredients = [];
    this.addIngredientAndDefaultChildren(ingredient);
  }

  addToBag() {
    if (this.mealNameForm.invalid) {
      this.mealNameForm.markAllAsTouched();
      return;
    }
    this.addingToBag = true;
    const orderID = this.store.selectSnapshot(
      (state: GlobalStateModel) => state.order.order,
    )!.id;
    this.store
      .dispatch(
        new AddToOrder(
          orderID,
          this.product.id,
          this.inputnumber,
          this.selectedIngredients.map((i) => String(i.id)),
          undefined,
          this.name.value,
        ),
      )
      .subscribe({
        next: () => {
          this.toastr.success(
            `${this.inputnumber}x ${this.product.name} added to bag`,
          );
        },
        error: (err) => {
          console.error(err)
          this.toastr.error(`We're having trouble adding to your bag. Please try again.`);
          this.addingToBag = false;
        },
        complete: () => {
          this.bsModalRef.hide();
          this.addingToBag = false;
        },
      });
    if (Number(this.addUpsell) && this.upsellProduct) {
      this.store
        .dispatch(
          new AddToOrderByChainID(
            orderID,
            this.upsellProduct!.chainproductid,
            this.inputnumber,
            [],
            undefined,
            this.name.value,
          ),
        )
        .subscribe(() =>
          this.toastr.success(
            `${this.inputnumber}x ${this.upsellProduct!.name} added to bag`,
          ),
        );
    }
  }

  private addIngredientAndDefaultChildren(ingredient: Partial<Ingredient>) {
    this.selectedIngredients.push(ingredient);
    if (ingredient.children) {
      ingredient.modifiers!.forEach((child) => {
        child.options.forEach((option) => {
          if (option.isdefault) {
            this.addIngredientAndDefaultChildren(option as Partial<Ingredient>);
          }
        });
      });
    }
  }

  get name(): AbstractControl {
    return this.mealNameForm.get('name')!;
  }
}
