<div class="card bg-white grow-1 h-100">
  <h2 class="card-header bg-light">Inbox</h2>
  <div
    *ngIf="messages$ | async as messages"
    class="card-body d-flex flex-row flex-wrap grow-1 align-items-center gap-3">
    <ng-container *ngIf="messages.length; else noMessages">
      <ng-container *ngFor="let message of messages">
        <app-inbox-message [message]="message"></app-inbox-message>
      </ng-container>
    </ng-container>
    <ng-template #noMessages>
      <div class="d-flex w-100 justify-content-center">
        <img
          class="empty-icon"
          src="assets/images/icons/Icons/fb-garlic-sad.svg"
          alt="" />
      </div>

      <div class="lead">You currently do not have any messages.</div>

      <button type="button" class="btn btn-primary" routerLink="/start-order">
        Start Order
      </button>
    </ng-template>
  </div>
</div>
