import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Category, GlobalStateModel, Menu, Product } from '@models/index';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { LocationsService } from '@services/api/locations.service';
import { MenuService } from '@services/api/menu.service';
import {
  ClearProduct,
  InitializeMenu,
  SetCategory,
  SetFeaturedItems,
  SetMenu,
  SetProduct,
  SetUpsells,
} from '@store/actions/menu.actions';
import {
  concatMap,
  forkJoin,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchAll,
  switchMap,
  take,
  toArray,
  withLatestFrom,
} from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../environments/environment';

export interface MenuStateModel {
  menu: Menu | null;
  category: Category | null;
  product: Product | null;
  upsellProducts: Product[] | null;
  featuredItems: Product[] | null;
}

@State<MenuStateModel>({
  name: 'menu',
  defaults: {
    menu: null,
    category: null,
    product: null,
    upsellProducts: [],
    featuredItems: [],
  },
})
@Injectable()
export class MenuState implements NgxsOnInit {
  constructor(
    private menu: MenuService,
    private locations: LocationsService,
    private analytics: AnalyticsService,
    private store: Store,
  ) {}

  ngxsOnInit(ctx: StateContext<MenuStateModel>) {
    ctx.dispatch(new SetFeaturedItems());
  }

  @Action(InitializeMenu)
  initializeMenu(ctx: StateContext<MenuStateModel>, action: MenuStateModel) {
    return this.locations.getAllLocations(false, false).pipe(
      switchMap((locations) => {
        const globalMenuLocation = locations.restaurants.find(
          (location) => location.content.is_global_menu,
        );
        if (globalMenuLocation) {
          return this.menu
            .getMenu(
              environment.useDemoVendorAsGlobal
                ? 170621
                : globalMenuLocation.id,
            )
            .pipe(
              map((menu) => {
                return ctx.patchState({
                  menu,
                });
              }),
            );
        } else {
          return of();
        }
      }),
    );
  }

  @Action(SetMenu)
  setMenu(
    ctx: StateContext<MenuStateModel>,
    action: SetMenu,
  ): Observable<MenuStateModel> {
    ctx.patchState({
      menu: null,
    });
    return forkJoin({
      menu: this.menu.getMenu(action.locationID, action.handoffMode),
      location: this.locations.getLocationByID(action.locationID),
    }).pipe(
      map(({ menu, location }) => {
        this.analytics.logProductListView(menu.categories, location!);

        ctx.dispatch(new SetUpsells(action.locationID, action.handoffMode));
        return ctx.patchState({
          menu,
        });
      }),
    );
  }

  @Action(SetCategory)
  setCategory(ctx: StateContext<MenuStateModel>, action: SetCategory) {
    const order = this.store.selectSnapshot(
      (state: GlobalStateModel) => state.order.order,
    );
    return forkJoin({
      menu: this.menu.getMenu(action.locationID, order?.deliverymode),
      location: this.locations.getLocationByID(action.locationID),
    }).pipe(
      map(({ menu, location }) => {
        const category = menu.categories.find(
          (category) => action.categoryID === category.id,
        )!;
        this.analytics.logProductClick(category, location!);
        from(category.products)
          .pipe(
            mergeMap((product) =>
              this.menu.getProduct(
                action.locationID,
                product.chainproductid,
                true,
              ),
            ),
            toArray(),
          )
          .subscribe(() => {});
        return ctx.patchState({
          category: category,
        });
      }),
    );
  }

  @Action(SetProduct)
  setProduct(ctx: StateContext<MenuStateModel>, action: SetProduct) {
    ctx.dispatch(new ClearProduct());
    return this.menu
      .getProduct(
        action.locationID,
        action.chainProductID,
        action.withModifiers,
      )
      .pipe(
        withLatestFrom(
          this.store.select(
            (state: GlobalStateModel) => state.locations.orderLocation,
          ),
        ),
        map(([product, location]) => {
          this.analytics.logProductDetailView(
            product,
            ctx.getState().category!,
            location!,
          );
          return ctx.patchState({
            product,
          });
        }),
      );
  }

  @Action(ClearProduct)
  clearProduct(ctx: StateContext<MenuStateModel>, action: ClearProduct) {
    return ctx.patchState({
      product: null,
    });
  }

  @Action(SetUpsells)
  setUpsells(ctx: StateContext<MenuStateModel>, action: SetUpsells) {
    return this.menu.getUpsells(action.locationID, action.handoffMode).pipe(
      map((upsells) => {
        return ctx.patchState({
          upsellProducts: upsells,
        });
      }),
    );
  }

  @Action(SetFeaturedItems)
  setFeaturedItems(
    ctx: StateContext<MenuStateModel>,
    action: SetFeaturedItems,
  ) {
    return this.menu.getFeaturedItems().pipe(
      map((featuredItems) => {
        return ctx.patchState({
          featuredItems,
        });
      }),
    );
  }
}
