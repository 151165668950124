import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryAddressConfirmModalComponent } from '@common/components';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {
  FavoriteLocation,
  GlobalStateModel,
  Restaurant,
  SavedAddress,
  User,
} from '@models/index';
import { ExistingGroupOrderModalComponent } from '@modules/locations/components';
import { Select, Store } from '@ngxs/store';
import { HandoffMode } from '@server/vendor/olo/interfaces';
import { AnalyticsService } from '@services/analytics/analytics.service';
import {
  ClearMapLocations,
  GetNearLocations,
} from '@store/actions/locations.actions';
import { SetMenu } from '@store/actions/menu.actions';
import { SetHandoffMode, StartOrder } from '@store/actions/order.actions';
import { collapseAnimation } from 'angular-animations';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { Observable, Subscription } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-order-type-selection',
  templateUrl: './order-type-selection.component.html',
  styleUrls: ['order-type-selection.component.scss'],
  animations: [collapseAnimation()],
})
export class OrderTypeSelectionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Select((state: GlobalStateModel) => state.user.favoriteLocations)
  favoriteLocations$!: Observable<FavoriteLocation[]>;
  @Select((state: GlobalStateModel) => state.user.user)
  user$!: Observable<User>;
  @Select((state: GlobalStateModel) => state.locations.closestDeliveryLocation)
  closestDeliveryLocation$!: Observable<Restaurant>;
  @Select((state: GlobalStateModel) => state.user.savedAddresses)
  savedAddresses$!: Observable<SavedAddress[]>;

  @ViewChild('orderTypeTabs', { static: false })
  orderTypeTabs?: TabsetComponent;

  mbPickupGeocoder = new MapboxGeocoder({
    accessToken: environment.mapboxToken,
  });

  mbDeliveryGeocoder = new MapboxGeocoder({
    accessToken: environment.mapboxToken,
  });

  geolocationLoading = false;
  geolocationAllowed = false;
  errorMessage: string | null = null;
  private deliveryAdded = false;

  private subs: Subscription[] = [];

  constructor(
    private bsModalService: BsModalService,
    private store: Store,
    private router: Router,
    @Inject(PLATFORM_ID) private _platformId: Object,
    private ngZone: NgZone,
    public route: ActivatedRoute,
    private analytics: AnalyticsService,
  ) {}

  ngOnInit() {
    this.closestDeliveryLocation$.pipe();
    if (
      this.store.selectSnapshot(
        (state: GlobalStateModel) => state.order.groupOrder,
      )
    ) {
      this.bsModalService.show(ExistingGroupOrderModalComponent);
    }
    this.analytics.logStoreLocatorPageView(
      this.store.selectSnapshot((state: GlobalStateModel) => state.user.user)!,
    );
  }

  ngAfterViewInit() {
    this.subs.push(
      this.route.fragment.subscribe((fragment) => {
        if (fragment && this.orderTypeTabs) {
          const handoffTab = this.orderTypeTabs.tabs.find(
            (tab) => tab.id === fragment,
          );
          if (handoffTab) {
            handoffTab.active = true;
          }
        }
      }),
    );
    if (isPlatformBrowser(this._platformId)) {
      this.mbPickupGeocoder.addTo('#mapboxlocationinput');
      this.mbPickupGeocoder.setPlaceholder('Enter your city, state or ZIP');

      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          result.onchange = () =>
            this.ngZone.run(
              () => (this.geolocationAllowed = result.state === 'granted'),
            );
          this.geolocationAllowed = result.state === 'granted';
          if (!this.geolocationAllowed) {
            navigator.geolocation.getCurrentPosition(
              () => (this.geolocationAllowed = true),
            );
          }
        });
      } else {
        navigator.geolocation.getCurrentPosition(
          () => (this.geolocationAllowed = true),
        );
      }
    }

    this.mbPickupGeocoder.setTypes('address, place, postcode');
    this.mbPickupGeocoder.setCountries('us');
    this.mbPickupGeocoder.on(
      'result',
      (result: { result: MapboxGeocoder.Result }) => {
        this.store
          .dispatch(
            new GetNearLocations(
              result.result.center[0],
              result.result.center[1],
              25,
              20,
              true,
              true,
            ),
          )
          .subscribe(() => this.router.navigate(['locations']));
      },
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  addressSelect(address: SavedAddress) {
    this.errorMessage = null;
    // eslint-disable-next-line max-len
    // this.store.dispatch(new FindDeliveryLocation(HandoffMode.DISPATCH, address.building, address.streetaddress, address.city, address.zipcode))
    this.bsModalService.show(DeliveryAddressConfirmModalComponent, {
      initialState: {
        address,
      },
    });
  }

  pickupSelected() {
    this.errorMessage = null;
    // this.mbGeocoder.addTo('#mapboxlocationinput');
    this.mbPickupGeocoder.setTypes('postcode, place, locality');
    this.mbPickupGeocoder.setPlaceholder('Enter your city, state or ZIP');
    this.mbPickupGeocoder.setCountries('us');
  }

  searchByGeolocation() {
    this.errorMessage = null;
    if (this.geolocationLoading) {
      return;
    }
    this.geolocationLoading = true;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.geolocationLoading = false;
        this.store.dispatch(new ClearMapLocations());
        this.router.navigate(['locations'], { fragment: 'geolocate' });
        // this.store
        //   .dispatch(
        //     new GetNearLocations(
        //       position.coords.longitude,
        //       position.coords.latitude,
        //       25,
        //       20,
        //       true,
        //       false,
        //     ),
        //   )
        //   .subscribe(() => {
        //     this.geolocationLoading = false;
        //     this.router.navigate(['locations'], { fragment: 'geolocate' });
        //   });
      },
      (error) => {
        if (error.message === 'User denied Geolocation') {
          this.errorMessage =
            'Please enable location sharing to help us find your nearest location';
        }
        this.geolocationLoading = false;
      },
    );
  }

  deliverySelected() {
    this.errorMessage = null;
    if (!this.deliveryAdded && isPlatformBrowser(this._platformId)) {
      this.mbDeliveryGeocoder.addTo('#mapboxdeliveryinput');
      this.mbDeliveryGeocoder.setPlaceholder('Enter your address');
      this.deliveryAdded = true;
      this.mbDeliveryGeocoder.on(
        'result',
        (result: { result: MapboxGeocoder.Result }) => {
          const address: SavedAddress = {
            id: 0,
            building: '',
            streetaddress: `${result.result.address} ${result.result.text}`,
            city: result.result.context.find((context) =>
              context.id.includes('place'),
            )!.text,
            zipcode: result.result.context.find((context) =>
              context.id.includes('postcode'),
            )!.text,
            phonenumber: '',
            specialinstructions: '',
            isdefault: false,
          };
          this.addressSelect(address);
        },
      );
    }
    this.mbDeliveryGeocoder.setTypes('address');
    this.mbDeliveryGeocoder.setCountries('us');
  }

  startOrderFromFavorite(locationID: number) {
    this.errorMessage = null;
    this.store.dispatch(new StartOrder(locationID)).subscribe(() => {
      const supportsDriveThru = this.store.selectSnapshot(
        (state: GlobalStateModel) => state.locations.orderLocation,
      )?.supportsdrivethru;
      this.store.dispatch([
        new SetHandoffMode(
          this.store.selectSnapshot(
            (state: GlobalStateModel) => state.order.order,
          )!.id,
          supportsDriveThru ? HandoffMode.DRIVE_THRU : HandoffMode.PICKUP,
        ),
        new SetMenu(locationID),
      ]);
      this.router.navigate([''], { fragment: 'menu' });
    });
  }
}
