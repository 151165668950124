<div
  id="collapseBasic"
  [collapse]="!isSelected"
  [isAnimated]="true"
  class="w-100">
  <div class="edit-shell d-flex flex-nowrap w-100 p-2">
    <div class="edit-group btn-group flex-grow-1 w-100">
      <ng-container *ngFor="let option of ingredientGroup.options">
        <button
          (click)="submodifierClick(option, $event)"
          class="btn text-capitalize btn-sm grow-1 basis-0"
          [ngClass]="
            (option | selectedIngredient: selectedIngredients)
              ? 'btn-secondary'
              : 'btn-outline-secondary'
          "
          tabindex="0"
          role="button">
          {{ option.name
          }}<ng-container *ngIf="option.cost > 0"
            >&nbsp;{{ option.cost | currency }}</ng-container
          >
        </button>
        <!--        <app-ingredient-submodifier-button-->
        <!--          [isSelected]="option | selectedIngredient: selectedIngredients"-->
        <!--          [ingredient]="option"-->
        <!--          (ingredientSelected)="selectIngredient($event)"-->
        <!--        ></app-ingredient-submodifier-button>-->
      </ng-container>
    </div>
  </div>
</div>
