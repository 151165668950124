import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Category, GlobalStateModel, HomePage, Restaurant} from '@models/index';
import {Select, Store} from '@ngxs/store';
import { ClearProduct, SetCategory } from '@store/actions/menu.actions';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { NgxSpinnerService } from 'ngx-spinner';

import { environment } from '../../../../environments/environment';
import {Observable} from "rxjs";

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['category-card.component.scss'],
  animations: [fadeInOnEnterAnimation()],
})
export class CategoryCardComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.content.homepage)
  homepage$!: Observable<HomePage>;

  @Input() menuLocation?: Restaurant;
  @Input() category!: Category;
  @Input() isSavedProducts: boolean = false;

  constructor(
    private store: Store,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {}
  ngOnInit() {}

  onClick(categoryID: number): void {
    this.spinner.show('walking-burrito');
    if (this.menuLocation) {
      this.store.dispatch(new ClearProduct());
      if (!this.isSavedProducts) {
        this.store
          .dispatch(new SetCategory(this.menuLocation.id, categoryID))
          .subscribe(() => {
            this.router.navigate(['order', 'customize', categoryID]);
          });
      } else {
        this.router.navigate(['order', 'customize', 'saved-products']);
      }
    } else {
      this.router.navigate(['start-order']);
    }
  }
}
