import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { countries, usStatesAndTerritories } from '@models/constants';
import { GlobalStateModel } from '@models/index';
import { BrochureService } from '@modules/brochure/services';
import { Select } from '@ngxs/store';
import { GetLegalResponse } from '@server/content/responses';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-data-request',
  templateUrl: './data-request.component.html',
  styleUrls: ['data-request.component.scss'],
})
export class DataRequestComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.content.legal)
  legal$!: Observable<GetLegalResponse>;
  submitLoading: boolean = false;

  content?: string;
  title?: string;

  dataRequestForm = new FormGroup({
    type: new FormControl(null, [Validators.required]),
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    phone: new FormControl(null, [Validators.required]),
    last4: new FormControl(null, [Validators.minLength(4), Validators.maxLength(4)]),
    userType: new FormControl(null, [Validators.required]),
    address1: new FormControl(null, [Validators.required]),
    address2: new FormControl(null),
    city: new FormControl(null, [Validators.required]),
    state: new FormControl(null, [Validators.required]),
    country: new FormControl(null, [Validators.required]),
    zip: new FormControl(null, [Validators.required]),
    authorizedAgent: new FormControl(false, [Validators.requiredTrue]),
    emailAcknowledge: new FormControl(false, [Validators.requiredTrue]),
  });

  requestTypes = [
    { value: 'right-to-know', label: 'Right to Know' },
    { value: 'access', label: 'Access your data' },
    { value: 'delete', label: 'Delete your data' },
    {
      value: 'opt-out',
      label: 'Opt-Out of Sale, Sharing, or Targeted Advertising',
    },
    { value: 'correction', label: 'Correct your data' },
  ];

  userTypes = [
    { value: 'contractor', label: 'Contractor' },
    { value: 'customer', label: 'Customer' },
    { value: 'employee', label: 'Employee' },
    { value: 'former-employee', label: 'Former Employee' },
    { value: 'applicant', label: 'Job Applicant' },
    { value: 'other', label: 'Other' },
    { value: 'survey-participant', label: 'Survey Participant' },
  ];

  constructor(
    private toastr: ToastrService,
    private brochure: BrochureService,
  ) {}
  ngOnInit() {
    this.legal$.subscribe((legal) => {
      if (legal) {
        const dataRequest = legal.find(
          (page) => page.URL_fragment === 'data-request',
        );
        if (dataRequest) {
          this.content = dataRequest.content;
          this.title = dataRequest.title;
        } else {
          this.content = `
          <p>Freebirds values your privacy. If you would like to submit a request pertaining to your personal information / personal data please complete one of the forms below. This form allows you to submit requests to <strong>know</strong>, <strong>access</strong>, <strong>delete</strong>, or <strong>correct</strong> your personal information / personal data.</p>
          <p>This form also allows you to submit a request to <strong>opt out</strong> of our <strong>sale</strong>, <strong>share</strong>, and/or <strong>processing</strong> of your personal information for targeted advertising. While we do not “sell” your data in the traditional sense, our use of advertising services may involve a “sale”, “share”, or “targeted advertising” under certain laws. To make an <strong>opt-out</strong> request related to our sharing of your personal information directly with third parties who provide us with advertising services, please complete the form below.</p>
          <p>We will handle your request based on the information you provide. If the information you provide does not match the information we have on file, we may be unable to complete your request. Please note you must submit a separate request for each email address, credit/debit card, or phone number that you have used with us.</p>
          `;
          this.title = 'Consumer Data Request Form';
        }
      }
    });
  }

  fourDigitValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value && (!/^\d{4}$/.test(value))) {
      return { 'fourDigitInvalid': true };
    }
    return null;
  }

  submitForm(
    group: FormGroup,
    category:
      | 'feedback'
      | 'media'
      | 'catering'
      | 'spirit-night'
      | 'donation'
      | 'data-request',
  ) {
    this.submitLoading = true;
    if (group.invalid) {
      group.markAllAsTouched();
      this.toastr.error('Please fill out all info');
      this.submitLoading = false;
      return;
    }
    let message = {};
    Object.keys(group.controls).forEach((key) => {
      // @ts-ignore
      message[key] = group.controls[key].value;
    });
    this.brochure.sendContactForm(category, message).subscribe({
      next: () => this.toastr.success('Message Sent', 'Success'),
      error: () => {
        this.toastr.error(
          'Message could not be sent. Please try again later',
          'Error',
        );
        this.submitLoading = false;
      },
      complete: () => {
        this.submitLoading = false;
        this.dataRequestForm.reset();
      },
    });
  }

  get requestType() {
    return this.dataRequestForm.get('type')! as FormControl;
  }

  get firstName() {
    return this.dataRequestForm.get('firstName')! as FormControl;
  }

  get lastName() {
    return this.dataRequestForm.get('lastName')! as FormControl;
  }

  get email() {
    return this.dataRequestForm.get('email')! as FormControl;
  }

  get phone() {
    return this.dataRequestForm.get('phone')! as FormControl;
  }

  get last4() {
    return this.dataRequestForm.get('last4')! as FormControl;
  }

  get userType() {
    return this.dataRequestForm.get('userType')! as FormControl;
  }

  get address1() {
    return this.dataRequestForm.get('address1')! as FormControl;
  }

  get address2() {
    return this.dataRequestForm.get('address2')! as FormControl;
  }

  get city() {
    return this.dataRequestForm.get('city')! as FormControl;
  }

  get state() {
    return this.dataRequestForm.get('state')! as FormControl;
  }

  get country() {
    return this.dataRequestForm.get('country')! as FormControl;
  }

  get zip() {
    return this.dataRequestForm.get('zip')! as FormControl;
  }

  get authorizedAgent() {
    return this.dataRequestForm.get('authorizedAgent')! as FormControl;
  }

  get emailAcknowledge() {
    return this.dataRequestForm.get('emailAcknowledge')! as FormControl;
  }

  public readonly usStatesAndTerritories = usStatesAndTerritories;
  public readonly countries = countries;

  gotoNextField(nextElement: HTMLInputElement) {
    nextElement.focus();
  }
}
