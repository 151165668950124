import { Injectable } from '@angular/core';
import {
  AddSavedProductChoice,
  GlobalStateModel,
  GroupOrder,
  Ingredient,
  PastOrder,
  Product,
} from '@models/index';
import { Store } from '@ngxs/store';
import { OloDatePipe } from '@pipes/olo-date.pipe';
import { GetAvailableTimesResponse } from '@server/order/responses';
import {
  BasketChoice,
  BasketProduct,
  Option,
  OptionGroup,
} from '@server/vendor/olo/interfaces';
import { OrderService as OrderAPIService } from '@services/api/order.service';
import { SetProduct } from '@store/actions/menu.actions';
import {
  AddToOrder,
  AddToOrderByChainID,
  GetCurrentGroupOrder,
  JoinGroupOrder,
  StartGroupOrder,
  StartOrderFromPastOrder,
  UpdateGroupOrder,
} from '@store/actions/order.actions';
import { DeleteSavedProduct } from '@store/actions/user.actions';
import * as moment from 'moment';
import { map, Observable, of, switchMap } from 'rxjs';
import { MenuService } from '@services/api/menu.service';

@Injectable()
export class OrderService {
  constructor(
    private store: Store,
    private order: OrderAPIService,
    private oloDatePipe: OloDatePipe,
    private menu: MenuService,
  ) {}

  startOrderFromPastOrder(order: PastOrder): Observable<void> {
    return this.store.dispatch(new StartOrderFromPastOrder(order));
  }

  addToOrder(
    productID: number,
    quantity: number,
    options: Array<Option | Ingredient>,
    specialInstructions?: string,
    recipient?: string,
  ): Observable<void> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        switchMap((order) => {
          return this.store.dispatch(
            new AddToOrder(
              order!.id,
              productID,
              quantity,
              options.map((option) => String(option.id)),
              specialInstructions,
              recipient,
            ),
          );
        }),
      );
  }

  addToOrderByChainID(
    chainProductID: number,
    quantity: number,
    choices: AddSavedProductChoice[],
    specialInstructions?: string,
    recipient?: string,
  ) {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        switchMap((order) => {
          return this.store.dispatch(
            new AddToOrderByChainID(
              order!.id,
              chainProductID,
              quantity,
              choices,
              specialInstructions,
              recipient,
            ),
          );
        }),
      );
  }

  getProductWithOptionGroups(productID: number): Observable<Product> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.locations.orderLocation)
      .pipe(
        switchMap((location) => {
          return this.menu.getProduct(location!.id, productID, true);
        }),
      );
  }

  deleteSavedProduct(savedProductID: string) {
    return this.store.dispatch(new DeleteSavedProduct(savedProductID));
  }

  retrieveBasketItemFromBasketID(productBasketID: string) {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.order)
      .pipe(
        map((order) => {
          const product = order!.products.find(
            (product) => product.id === Number(productBasketID),
          );
          if (product) {
            return product;
          } else {
            throw new Error('Basket item not found');
          }
        }),
      );
  }

  retrieveProductFromBasketID(productBasketID: string) {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.menu.category)
      .pipe(
        map((category) => {
          const product = category!.products.find(
            (product) => product.id === Number(productBasketID),
          );
          if (product) {
            return product;
          } else {
            throw new Error('Product not found');
          }
        }),
      );
  }

  getSelectedOptionsFromBasketProduct(
    basketProduct: BasketProduct,
  ): Observable<Option[]> {
    let choices: Option[] = [];
    if (basketProduct.choices.length === 0) {
      return of(choices);
    } else {
      return this.store
        .selectOnce((state: GlobalStateModel) => state.menu.category)
        .pipe(
          switchMap((category) => {
            const product = category!.products.find(
              (product) => product.id === basketProduct.productId,
            );
            return this.store
              .selectOnce(
                (state: GlobalStateModel) => state.locations.orderLocation,
              )
              .pipe(
                switchMap((location) => {
                  return this.store
                    .dispatch(
                      new SetProduct(
                        location!.id,
                        product!.chainproductid,
                        true,
                      ),
                    )
                    .pipe(
                      map(() => {
                        return this.findSelectedIngredients(
                          this.store.selectSnapshot(
                            (state: GlobalStateModel) => state.menu.product,
                          )!,
                          basketProduct.choices,
                        );
                      }),
                    );
                }),
              );
          }),
        );
    }
  }

  findSelectedIngredients(product: Product, choices: BasketChoice[]): Option[] {
    let selectedIngredients: Option[] = [];
    if (product!.optiongroups?.length) {
      product.optiongroups?.forEach((optionGroup) => {
        selectedIngredients = selectedIngredients.concat(
          this.findSelectedOptionsInChildOptionGroups(optionGroup, choices),
        );
      });
    }
    return selectedIngredients;
  }

  findSelectedOptionsInChildOptionGroups(
    optionGroup: OptionGroup,
    choices: BasketChoice[],
  ): Option[] {
    let selectedOptions: Option[] = [];
    optionGroup.options.forEach((option) => {
      if (choices.find((choice) => choice.optionid === option.id)) {
        selectedOptions.push(option);
      }
      if (option.children) {
        option.modifiers.forEach((childOptionGroup) => {
          selectedOptions = selectedOptions.concat(
            this.findSelectedOptionsInChildOptionGroups(
              childOptionGroup,
              choices,
            ),
          );
        });
      }
    });
    return selectedOptions;
  }

  startGroupOrder(deadline: Date, note?: string): Observable<void> {
    return this.store.dispatch(new StartGroupOrder(deadline, note));
  }

  updateGroupOrder(deadline: Date, note?: string): Observable<void> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.groupOrder)
      .pipe(
        switchMap((groupOrder) => {
          return this.store.dispatch(
            new UpdateGroupOrder(groupOrder!.id, deadline, note),
          );
        }),
      );
  }

  joinGroupOrder(name: string): Observable<void> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.groupOrder)
      .pipe(
        switchMap((groupOrder) => {
          return this.store.dispatch(new JoinGroupOrder(name)).pipe(
            switchMap(() => {
              return this.store.dispatch(
                new GetCurrentGroupOrder(
                  groupOrder!.id,
                  undefined,
                  groupOrder!.basket.id,
                ),
              );
            }),
          );
        }),
      );
  }

  setGroupOrder(groupOrderID: string, basketID: string): Observable<void> {
    return this.store.dispatch(
      new GetCurrentGroupOrder(groupOrderID, undefined, basketID),
    );
  }

  getAvailableGroupOrderDeadlineTimes(): Observable<GetAvailableTimesResponse> {
    return this.store
      .selectOnce((state: GlobalStateModel) => state.order.groupOrder)
      .pipe(
        switchMap((groupOrder) => {
          if (groupOrder) {
            const startTime = moment(
              this.oloDatePipe.transform(groupOrder!.basket.earliestreadytime),
            ).isAfter(moment())
              ? moment(
                  this.oloDatePipe.transform(
                    groupOrder!.basket.earliestreadytime,
                  ),
                )
              : moment();
            const token = this.store.selectSnapshot(
              (state: GlobalStateModel) => state.user.tokens,
            )?.tokens?.ordering.token;
            return this.store
              .dispatch(
                new GetCurrentGroupOrder(
                  groupOrder.id,
                  token,
                  token ? undefined : groupOrder.basket.id,
                ),
              )
              .pipe(
                switchMap(() => {
                  return this.order.getAvailableOrderTimes(
                    groupOrder.basket.id,
                    startTime.add(5, 'minutes').format('X'),
                    moment().endOf('day').format('X'),
                  );
                }),
              );
          } else {
            return this.store
              .selectOnce((state: GlobalStateModel) => state.order.order)
              .pipe(
                switchMap((order) => {
                  const startTime = moment(
                    this.oloDatePipe.transform(order!.earliestreadytime),
                  ).isAfter(moment())
                    ? moment(
                        this.oloDatePipe.transform(order!.earliestreadytime),
                      )
                    : moment();
                  return this.order.getAvailableOrderTimes(
                    order!.id,
                    startTime.add(1, 'minutes').format('X'),
                    moment().endOf('day').format('X'),
                  );
                }),
              );
          }
        }),
      );
  }

  isGroupOrderOpen(groupOrder: GroupOrder): boolean {
    return !(
      !groupOrder.isopen ||
      moment().isAfter(this.oloDatePipe.transform(groupOrder.deadline)) ||
      groupOrder.note?.toLowerCase() === 'cancelled'
    );
  }
}
