<ng-container *ngIf="jumbotronLayouts">
  <carousel
    [isAnimated]="true"
    class="carousel slide w-100 h-100"
    [interval]="5000"
    [noPause]="false"
    [noWrap]="false">
    <ng-container *ngFor="let section of jumbotronLayouts">
      <slide>
        <ng-container *ngIf="section!.content">
          <div
            (click)="jumbotronClick(section)"
            [class.cursor-pointer]="section!.link"
            class="jumbotron w-100 h-100 position-relative"
            [style.background-color]="section!.background_color!">
            <div
              class="jumbotron-content-shell d-flex flex-column flex-md-row flex-nowrap w-100 h-100 position-relative">
              <div
                class="jumbotron-contents d-flex flex-column gap-3 grow-1 basis-0 my-auto h-100" [style.color]="section.text_color"
                [innerHTML]="section!.content! | sanitizeHtml"></div>
            </div>
            <!-- background image -->
            <div
              class="position-absolute top-0 w-100 h-100 d-flex flex-column grow-1 p-0"
              [class.container]="section!.image_sizing! === 'contain'">
              <ng-container
                [ngTemplateOutlet]="bgVideo"
                [ngTemplateOutletContext]="{ section: section }"></ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!section!.content!">
          <div
            (click)="jumbotronClick(section)"
            [class.cursor-pointer]="section!.link"
            class="jumbotron w-100 h-100 position-relative"
            [style.background-color]="section!.background_color!">
            <div
              class="position-relative w-100 h-100 d-flex flex-column grow-1 p-0"
              [class.container]="section!.image_sizing! === 'contain'">
              <ng-container
                [ngTemplateOutlet]="bgVideo"
                [ngTemplateOutletContext]="{ section: section }"></ng-container>
            </div>
          </div>
        </ng-container>
      </slide>
    </ng-container>
  </carousel>

  <ng-template #bgVideo let-section="section">
    <ng-container *ngIf="section?.is_video">
      <video
        class="w-100 h-100 d-md-block d-none"
        autoplay
        loop
        muted
        playsinline
        webkit-playsinline>
        <source [src]="section?.background_image! | cmsAsset" />
      </video>

      <video
        class="w-100 h-100 d-md-none d-block"
        autoplay
        loop
        muted
        playsinline
        webkit-playsinline>
        <source [src]="section?.background_image_mobile! | cmsAsset" />
      </video>
    </ng-container>
    <ng-container
      *ngIf="!section?.is_video"
      [ngTemplateOutlet]="bgImage"
      [ngTemplateOutletContext]="{ section: section }">
    </ng-container>
  </ng-template>

  <ng-template #bgImage let-section="section">
    <img
      style="min-width: 0px; min-height: 30vh"
      class="h-100 d-md-block d-none"
      [ngClass]="section?.background_image_mobile ? 'd-none' : 'd-block'"
      [style.object-fit]="section?.image_sizing!"
      [src]="section?.background_image! | cmsAsset"
      [alt]="section?.title" />

    <ng-container *ngIf="section?.background_image_mobile!">
      <img
        style="min-width: 0px"
        class="h-100 d-md-none d-block"
        [style.object-fit]="section?.image_sizing_mobile!"
        [src]="section?.background_image_mobile! | cmsAsset"
        [alt]="section?.title"
    /></ng-container>
  </ng-template>
</ng-container>
