import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomPage, GlobalStateModel } from '@models/index';
import { Store } from '@ngxs/store';
import { MetaDataService } from '@services/meta-data/meta-data.service';

@Component({
  selector: 'app-custom-page',
  templateUrl: './custom-page.component.html',
  styleUrls: ['custom-page.component.scss'],
})
export class CustomPageComponent implements OnInit, OnDestroy {
  pageData: CustomPage;

  constructor(
    private route: ActivatedRoute,
    private meta: MetaDataService,
    private store: Store,
  ) {
    this.pageData = this.route.snapshot.data['pageData'];
  }
  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (this.pageData && params['customPageID'] !== this.pageData.slug) {
        const newPage = this.store
          .selectSnapshot(
            (state: GlobalStateModel) => state.content.customPages,
          )
          ?.find((page) => page.slug === params['customPageID']);
        if (newPage) {
          this.pageData = newPage;
          this.setupSEO();
        }
      }
    });
    this.setupSEO();
  }

  setupSEO() {
    this.meta.manualUpdate({
      title: this.pageData.seo_title,
      description: this.pageData.seo_description,
      keywords: this.pageData.seo_keywords
        ? this.pageData
            .seo_keywords!.filter((keyword) => !!keyword)
            .map((keyword) => String(keyword!))
        : [],
    });
    if (this.pageData.crawlable) {
      this.meta.checkAndUpdate({
        property: 'robots',
        content: 'index, follow',
      });
    } else {
      this.meta.checkAndUpdate({
        property: 'robots',
        content: 'noindex, nofollow',
      });
    }
  }

  ngOnDestroy() {
    this.meta.checkAndUpdate({ property: 'robots', content: 'index, follow' });
  }
}
