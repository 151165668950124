import { Pipe, PipeTransform } from '@angular/core';
import { AllergenTag, Ingredient } from '@models/index';

@Pipe({
  name: 'ingredientAllergenTags',
  pure: false,
})
export class IngredientAllergenTagsPipe implements PipeTransform {
  transform(
    itemTags: Ingredient['content']['allergen_tags'],
    allTags: AllergenTag[],
  ): AllergenTag[] {
    const tags: AllergenTag[] = [];
    itemTags.forEach((tag) => {
      const foundTag = allTags.find(
        (allTag) => allTag.id === tag.allergen_tags_id,
      );
      if (foundTag) {
        tags.push(foundTag);
      }
    });
    return tags;
  }
}
