<app-navbar></app-navbar>

<div
  class="nav-page-height-fixed nav-page-height w-100 d-flex flex-column flex-md-row flex-nowrap grow-1">
  <!-- image -->
  <div class="d-none d-md-flex grow-1 basis-0">
    <div class="auth-background-image"></div>
  </div>

  <!-- form -->
  <ng-container *ngIf="!showSubmit">
    <div
      class="d-flex justify-content-center grow-1 basis-0"
      style="overflow-y: auto">
      <div class="w-100">
        <div
          class="auth-shell w-100 d-flex flex-column gap-5 px-3 px-md-5 py-4 py-md-5 align-items-center mx-auto">
          <img
            class="auth-rewards-logo"
            src="/assets/images/marketing/rewards-as-big-as-texas.svg" />
          <div class="d-flex flex-column gap-3 w-100">
            <h3 class="text-center">Forgot Password</h3>
            <form
              action=""
              class="d-flex flex-column gap-3 w-100"
              [formGroup]="forgotPasswordForm">
              <div class="form-floating">
                <input
                  type="email"
                  class="form-control form-control-sm"
                  id="email"
                  [class.is-valid]="email.valid && email.touched"
                  [class.is-invalid]="email.invalid && email.touched"
                  formControlName="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email" />
                <label class="col-form-label col-form-label-sm" for="email"
                  >Email address</label
                >
              </div>
              <app-button
                [disabled]="email.invalid"
                classes="btn btn-primary w-100"
                (click)="onForgotPassword()">
                Submit
              </app-button>
            </form>
          </div>
          <div class="d-flex flex-column w-100 align-items-center gap-3">
            <div class="d-flex flex-column gap-1 w-100 align-items-center">
              <h5 class="auth-switch-page-heading w-75 text-center">
                Return to Sign In
              </h5>
            </div>
            <button
              routerLink="/user/sign-in"
              type="button"
              class="btn btn-outline-secondary">
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showSubmit">
    <div
      class="d-flex justify-content-center grow-1 basis-0"
      style="overflow-y: auto">
      <div class="w-100">
        <div
          class="auth-shell w-100 d-flex flex-column gap-5 px-3 px-md-5 py-4 py-md-5 align-items-center mx-auto">
          <img
            style="height: auto; width: 100%; min-width: 0px; max-width: 200px"
            src="/assets/images/marketing/rewards-as-big-as-texas.svg" />
          <div class="d-flex flex-column gap-3 w-100 text-center">
            <h3 class="text-center">Forgot Password</h3>
            <div>
              If your account is in our system, an email will be sent to
              <b>{{ email.value }}</b> with a link to reset your password.
            </div>
            <div>
              If you don't see a password reset email from us it may be in your
              spam folder.
            </div>
            <app-button classes="btn btn-primary w-100" (buttonClick)="reset()">
              Done
            </app-button>
          </div>
          <div class="d-flex flex-column w-100 align-items-center gap-3">
            <div class="d-flex flex-column gap-1 w-100 align-items-center">
              <h5 class="auth-switch-page-heading w-75 text-center">
                Return to Sign In
              </h5>
            </div>
            <button
              routerLink="/user/sign-in"
              type="button"
              class="btn btn-outline-secondary">
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<app-footer></app-footer>
