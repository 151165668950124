import {
  AfterViewInit,
  Component,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { FavoriteLocation, GlobalStateModel, Restaurant } from '@models/index';
import { ExistingGroupOrderModalComponent } from '@modules/locations/components';
import { LocationsService } from '@modules/locations/services';
import { Select, Store } from '@ngxs/store';
import { AnalyticsService } from '@services/analytics/analytics.service';
import {
  ClearMapLocations,
  GetNearLocations,
  SetAllLocations,
} from '@store/actions/locations.actions';
import * as turf from '@turf/turf';
import * as mapboxgl from 'mapbox-gl';
import { GeolocateControl } from 'mapbox-gl';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  bindCallback,
  combineLatest,
  first,
  Observable,
  skip,
  throttle,
  throttleTime,
} from 'rxjs';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['locations.component.scss'],
})
export class LocationsComponent implements AfterViewInit, OnInit {
  @Select((state: GlobalStateModel) => state.locations.mapLocations)
  mapLocations$!: Observable<Restaurant[]>;
  @Select((state: GlobalStateModel) => state.user.favoriteLocations)
  favoriteLocations$!: Observable<FavoriteLocation[]>;
  @Select((state: GlobalStateModel) => state.locations.searchRan)
  searchRan$!: Observable<boolean>;

  @ViewChild('userIcon') userIcon!: mapboxgl.Marker;
  @ViewChild('mapComponent') mapRef!: mapboxgl.Map;

  geolocationTrigger = new GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    showAccuracyCircle: false,
    trackUserLocation: true,
    showUserLocation: true,
  });

  radioModel = 'pickup';

  myForm?: FormGroup;

  mbGeocoder?: MapboxGeocoder;

  selectedLocation?: Restaurant;
  userLocation?: mapboxgl.LngLatLike;
  userLocationArray: number[] = [];
  isLoading: boolean = true;

  map!: mapboxgl.Map;

  geolocationAllowed: boolean = false;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private locations: LocationsService,
    private bsModalService: BsModalService,
    private analytics: AnalyticsService,
  ) {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        result.onchange = () =>
          this.ngZone.run(
            () => (this.geolocationAllowed = result.state === 'granted'),
          );
        this.geolocationAllowed = result.state === 'granted';
      });
    } else {
      navigator.geolocation.getCurrentPosition(
        () => (this.geolocationAllowed = true),
      );
    }
    navigator.geolocation.getCurrentPosition((result) => {
      this.userLocation = [result.coords.longitude, result.coords.latitude];
      this.userLocationArray = [
        result.coords.longitude,
        result.coords.latitude,
      ];
    });
  }

  ngOnInit() {
    if (
      this.store.selectSnapshot(
        (state: GlobalStateModel) => state.order.groupOrder,
      )
    ) {
      this.bsModalService.show(ExistingGroupOrderModalComponent);
    }
    this.mapLocations$.pipe(first()).subscribe((locations) => {
      if (!locations || locations.length === 0) {
        this.route.fragment.subscribe((frag) => {
          if (frag !== 'geolocate') {
            this.store.dispatch(new SetAllLocations(true, false));
          } else {
            this.store.dispatch(new ClearMapLocations());
          }
        });
      } else {
        this.isLoading = false;
      }
    });
    this.mapLocations$.pipe(skip(1)).subscribe((locations) => {
      if (!locations || locations.length === 0) {
        this.store.dispatch(new SetAllLocations(true, false));
      } else {
        this.isLoading = false;
      }
    });
    this.analytics.logSearchResultsPageView(
      this.store.selectSnapshot((state: GlobalStateModel) => state.user.user)!,
    );
  }

  ngAfterViewInit() {}

  setupGeocoder() {
    combineLatest([this.mapLocations$, this.searchRan$]).subscribe(
      ([locations, searchRan]) => {
        if (locations && locations[0]) {
          let centerLatBounds = 0;
          let centerLngBounds = 0;
          const bounds: any = [];
          locations.forEach((location) => {
            bounds.push([location.longitude, location.latitude]);
            centerLatBounds += location.latitude;
            centerLngBounds += location.longitude;
          });
          let centerLat = centerLatBounds / locations.length;
          let centerLng = centerLngBounds / locations.length;
          let notClose = true;
          if (
            !searchRan &&
            this.userLocationArray[0] &&
            this.userLocationArray[1]
          ) {
            bounds.forEach((bound: any[]) => {
              if (notClose) {
                const from = turf.point([
                  this.userLocationArray[0],
                  this.userLocationArray[1],
                ]);
                const to = turf.point(bound);
                const options = { units: 'miles' };
                // @ts-ignore
                const distance = turf.distance(from, to, options);
                if (distance < 25) {
                  notClose = false;
                }
              }
            });
          }
          if (searchRan && centerLat && centerLng) {
            bounds.forEach((bound: any[]) => {
              if (notClose) {
                const from = turf.point([centerLng, centerLat]);
                const to = turf.point(bound);
                const options = { units: 'miles' };
                // @ts-ignore
                const distance = turf.distance(from, to, options);
                if (distance < 25) {
                  notClose = false;
                }
              }
            });
          }
          if (notClose) {
            this.map.fitBounds(bounds);
            this.map.zoomTo(5);
          } else {
            this.map.flyTo({
              center: {
                lat: centerLat,
                lng: centerLng,
              },
              zoom: 10,
            });
          }
        }
      },
    );
    this.map.addControl(this.geolocationTrigger, 'top-right');
    const eventFactory = bindCallback(
      this.geolocationTrigger.on.bind(this.geolocationTrigger),
    );
    const onGeolocation$ = eventFactory('geolocate');
    onGeolocation$.pipe(throttleTime(5 * 60 * 1000)).subscribe((data) => {
      this.map.zoomTo(5);
      const coordinates = (data as unknown as GeolocationPosition).coords;
      this.isLoading = true;
      this.store.dispatch(
        new GetNearLocations(
          coordinates.longitude,
          coordinates.latitude,
          25,
          10,
          true,
          false,
        ),
      );
    });
    // this.geolocationTrigger.on('geolocate', (data) => {
    //   this.map.zoomTo(2.5);
    //   const coordinates = (data as GeolocationPosition).coords;
    //   this.store.dispatch(
    //     new GetNearLocations(
    //       coordinates.longitude,
    //       coordinates.latitude,
    //       25,
    //       10,
    //       true,
    //       false,
    //     ),
    //   );
    // });
    setTimeout(() => {
      this.route.fragment.subscribe((frag) => {
        if (frag === 'geolocate') {
          this.geolocationTrigger.trigger();
        }
      });
    }, 50);
  }

  selectLocation(location: Restaurant): void {
    if (location === this.selectedLocation) {
      return;
    } else {
      this.selectedLocation = location;

      setTimeout(() => {
        this.map.flyTo({
          center: {
            lat: location.latitude,
            lng: location.longitude,
          },
          zoom: 10,
        });
      }, 310);
    }
    this.analytics.logLocationSelect(location);
    // if (this.selectedLocation) {
    //   this.selectedLocation = undefined;
    //   setTimeout(() => {
    //     this.selectedLocation = location;
    //     setTimeout(() => {
    //       this.map.flyTo({
    //         center: {
    //           lat: location.latitude,
    //           lng: location.longitude,
    //         },
    //         zoom: 10,
    //       });
    //     }, 310);
    //   }, 310);
    // } else {
    //   this.selectedLocation = location;
    //   setTimeout(() => {
    //     this.map.flyTo({
    //       center: {
    //         lat: location.latitude,
    //         lng: location.longitude,
    //       },
    //       zoom: 10,
    //     });
    //   }, 310);
    // }
  }

  selectFavoriteLocation(location: FavoriteLocation) {
    this.selectLocation(this.locations.favoriteLocationToRestaurant(location));
  }

  triggerGeolocation() {
    this.geolocationTrigger.trigger();
  }

  closeLocation() {
    this.map.flyTo({
      center: {
        lat: this.selectedLocation!.latitude,
        lng: this.selectedLocation!.longitude,
      },
      zoom: 8,
    });
    this.selectedLocation = undefined;
  }

  mapMarkerTrackBy(index: number, item: Restaurant): string {
    return `${index}${item.name}${item.id}`;
  }
}
