import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/* Containers */
import * as styleGuideContainers from './containers';
/* Guards */
import * as styleGuideGuards from './guards';
/* Module */
import { StyleGuideModule } from './style-guide.module';

/* Routes */
export const ROUTES: Routes = [
  {
    path: 'style-guide',
    canActivate: [],
    component: styleGuideContainers.StaticComponent,
  },
];

@NgModule({
  imports: [StyleGuideModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class StyleGuideRoutingModule {}
