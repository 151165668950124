<section
  class="featured-products-section py-4 py-md-5 px-0"
  [ngClass]="section.claims_to_fame_theme!">
  <div class="header container position-relative">
    <h1>{{ section.claims_to_fame_title }}</h1>
    <ng-container *ngIf="section.claims_to_fame_subheading">
      <p>{{ section.claims_to_fame_subheading }}</p>
    </ng-container>
  </div>
  <div class="container position-relative">
    <div
      #menuGrid
      (scroll)="onScroll()"
      class="menu-grid flex-nowrap pb-2 pb-lg-0">
      <ng-container *ngFor="let product of featuredProducts">
        <app-product-card
          style="flex: 0 1 300px; min-width: 250px"
          [isEditMode]="false"
          [productCardType]="'size'"
          [product]="product"
          [isSelected]="false"
          [isFeatured]="true"
          (productSelect)="productSelect.emit(product)"></app-product-card>
      </ng-container>
    </div>

    <div class="d-lg-none gap-2 ml-auto d-flex justify-content-between mt-2">
      <button
        type="button"
        class="arrow-button"
        (click)="scrollLeft()"
        [style.opacity]="leftButtonOpacity">
        <div class="d-flex bg-white rounded-circle text-dark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-arrow-left-circle"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
          </svg>
        </div>
      </button>

      <button
        type="button"
        class="arrow-button"
        (click)="scrollRight()"
        [style.opacity]="rightButtonOpacity">
        <div class="d-flex bg-white rounded-circle text-dark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-arrow-right-circle"
            viewBox="0 0 16 16">
            <path
              fill-rule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
          </svg>
        </div>
      </button>
    </div>
  </div>
</section>
