<div class="modal-header rounded-top align-items-center gap-1">
  <img
    class="h4-birds"
    src="assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="" />
  <h4 class="modal-title grow-1">Reward Details</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1">
  <div class="d-flex flex-column gap-3">
    <h3>{{ reward.name }}</h3>
    <div class="text-muted">{{ reward.points_required_to_redeem }} Points</div>
    <div>
      {{ reward.description }}
    </div>
  </div>
</div>
<div class="modal-footer rounded-bottom">
  <button
    type="button"
    class="btn btn-outline-secondary w-100"
    aria-label="Close"
    (click)="bsModalRef.hide()">
    Close
  </button>
</div>
