<div class="modal-header rounded-top align-items-center gap-1">
  <img
    class="h4-birds"
    src="assets/images/birds/Freebirds_BrandGraphics_Digital_Birds_Carbon_RGB.svg"
    alt="" />
  <h4 class="modal-title grow-1">{{ name }}</h4>
  <button
    type="button"
    class="btn-close close m-0"
    aria-label="Close"
    (click)="closeModal()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body grow-1 h-100">
  <div class="d-flex flex-column gap-2 pb-2 align-items-center">
    <img [alt]="name" class="product-popover-image" [src]="image | cmsAsset" />
    <div *ngIf="badge">
      <div class="badge badge-pill badge-primary tag">
        {{ badge | uppercase }}
      </div>
    </div>
    <div class="text-center" *ngIf="description">
      {{ description }}
    </div>
  </div>
  <div class="d-flex w-100 justify-content-center pt-3">
    <button
      class="btn btn-primary"
      routerLink="/start-order"
      (click)="closeModal()">
      Start Order
    </button>
  </div>
</div>
<!--<div class="modal-footer rounded-bottom">-->
<!--  <button-->
<!--    class="btn btn-primary"-->
<!--    routerLink="/start-order"-->
<!--    (click)="closeModal()">-->
<!--    Start Order-->
<!--  </button>-->
<!--</div>-->
