<ng-container *ngIf="groupOrder">
  <div class="d-flex flex-column gap-4">
    <div class="d-flex flex-column gap-3 w-100 align-items-center">
      <img
        class="go-icon"
        src="/assets/images/ingredient_illustrations/Icons/fb-dancing.svg"
        alt="icon of a pepper and tomato dancing to music" />
      <h1 class="text-center">Join {{ groupOrder.ownername }} Group Order</h1>
    </div>

    <div class="d-flex flex-column gap-2 w-100 align-items-center py-4">
      <h4>Order Deadline:</h4>
      <h2 class="h1 text-primary">
        {{groupOrder.deadline | oloDate | date: 'shortTime'}}
      </h2>
    </div>

    <div class="alert alert-info" *ngIf="groupOrder.note">
      <b>Message From the Host:</b>
      {{ groupOrder.note }}
    </div>

    <div class="d-flex flex-column gap-4 p-3 bg-light rounded">
      <div class="d-flex flex-column gap-2">
        <h3>Group Order Instructions</h3>

        <ol class="m-0">
          <li>Please add all of your items prior to the order deadline.</li>
          <li>
            When you’ve finished adding your items, let your host know that your
            order is in.
          </li>
        </ol>
      </div>
      <form [formGroup]="joinGroupOrderForm">
        <div class="d-flex flex-column gap-3">
          <div class="d-flex flex-column">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                placeholder="Enter Your First Name"
                formControlName="name"
                id="goJoinName"
                required />
              <label class="col-form-label" for="goJoinName">
                Enter Your First Name
              </label>
            </div>
          </div>
          <!--      Show this after the user has tried pressing the join group button without entering a name first-->
          <ng-container
            *ngIf="joinGroupOrderForm.touched && joinGroupOrderForm.invalid">
            <div class="alert alert-danger d-flex align-items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-exclamation-triangle"
                viewBox="0 0 16 16">
                <path
                  d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                <path
                  d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
              </svg>
              You must enter your first name to join the group order.
            </div>
          </ng-container>

          <small class="m-0 fst-italic">
            <b>Important Note:</b> The maximum total order cost is $300 dollars.
          </small>

          <div class="d-flex flex-row flex-wrap gap-3 w-100">
            <div
              class="d-flex flex-row flex-nowrap gap-3 justify-content-end grow-1">
              <app-button
                classes="btn btn-primary w-fit-content"
                (buttonClick)="joinClick()"
                >Join Group
              </app-button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
