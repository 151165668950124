import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { GlobalStateModel, PastOrder } from '@models/index';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-order-history-section',
  templateUrl: './order-history-section.component.html',
  styleUrls: ['order-history-section.component.scss'],
})
export class OrderHistorySectionComponent implements OnInit {
  @Input() isEmpty!: boolean;
  @Select((state: GlobalStateModel) => state.user.pastOrders)
  pastOrders$!: Observable<PastOrder[]>;

  constructor() {}
  ngOnInit() {}
}
