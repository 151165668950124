import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Restaurant } from '@models/index';
import { LocationsService } from '@modules/locations/services';
import { SchemaMarkupService } from '@services/schema-markup/schema-markup.service';
import * as mapboxgl from 'mapbox-gl';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['location-details.component.scss'],
})
export class LocationDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('mapComponent') mapRef!: mapboxgl.Map;
  map!: mapboxgl.Map;

  restaurant?: Restaurant;

  startOrderLoading = false;

  private subs: Subscription[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly locationService: LocationsService,
    private readonly router: Router,
    @Inject(PLATFORM_ID)
    public readonly platformId: Object,
    private schemaMarkupService: SchemaMarkupService,
  ) {}

  ngOnInit() {
    this.subs.push(
      this.route.params.subscribe((params) => {
        if (!params['slug']) {
          this.router.navigate(['/']);
          return;
        }
        this.locationService
          .retrieveLocationBySlug(params['slug'])
          .subscribe((restaurant) => {
            this.restaurant = restaurant;
            if (this.map) {
              this.map.flyTo({
                center: [restaurant.longitude, restaurant.latitude],
                zoom: 15,
              });
            }
            this.locationService
              .retrieveLocationMenu(restaurant.id)
              .subscribe((menu) => {
                const schemaMarkup = document.createElement('script');
                schemaMarkup.type = 'application/ld+json';
                schemaMarkup.text = JSON.stringify(
                  this.schemaMarkupService.restaurantToSchemaRestaurant(
                    restaurant,
                    menu,
                  ),
                );
                schemaMarkup.id = 'schemaMarkup';
                document.head.appendChild(schemaMarkup);
              });
          });
      }),
    );
  }

  ngOnDestroy() {
    document.getElementById('schemaMarkup')?.remove();
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  onMapLoad(map: mapboxgl.Map) {
    this.map = map;
    this.map.resize();
    if (this.restaurant) {
      this.map.flyTo({
        center: [this.restaurant.longitude, this.restaurant.latitude],
        zoom: 15,
      });
    }
  }

  startOrder(locationID: number) {
    this.startOrderLoading = true;
    this.locationService.startOrder(locationID).subscribe(() => {
      this.locationService
        .finishAndGoToMenu(false)
        .subscribe(() => (this.startOrderLoading = false));
    });
  }
}
