import { Injectable } from '@angular/core';
import { GlobalStateModel, User } from '@models/index';
import { Store } from '@ngxs/store';
import { ContentService } from '@services/api/content.service';
import { UserService } from '@services/api/user.service';
import { StartOrder } from '@store/actions/order.actions';
import {
  DeleteFavoriteLocation,
  DismissMessage,
  Logout,
  UpdateUserInfo,
} from '@store/actions/user.actions';
import { Observable, of } from 'rxjs';

@Injectable()
export class ProfileService {
  constructor(
    private store: Store,
    private userService: UserService,
    private content: ContentService,
  ) {}

  // Order History

  // Payment Methods

  // Favorite Locations

  removeFavorite(locationID: number): Observable<void> {
    return this.store.dispatch(new DeleteFavoriteLocation(locationID));
  }

  startOrder(locationID: number): Observable<void> {
    return this.store.dispatch(new StartOrder(locationID));
  }

  // Inbox

  dismissMessage(messageID: string): Observable<void> {
    return this.store.dispatch(new DismissMessage(messageID));
  }

  // Account Info

  updateAccountInfo(
    firstName: string,
    lastName: string,
    email: string,
    birthday: string,
    emailOptIn: boolean,
    smsOptIn: boolean,
  ): Observable<void> {
    return this.store.dispatch(
      new UpdateUserInfo(
        firstName,
        lastName,
        email,
        birthday,
        emailOptIn,
        smsOptIn,
      ),
    );
  }

  logout(): Observable<void> {
    return this.store.dispatch(new Logout());
  }

  unsubscribe(email: string, userID: string): Observable<User> {
    return this.userService.unsubscribeFromEmails(userID, email);
  }

  verifyToken(token: string): Observable<{ success: boolean }> {
    return this.content.verifyToken(token);
  }
}
